/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import pickingService from '@services/picking';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { totalPickingEditColumns } from '../enhance';

const EditTotalPickingList = ({ open, setOpen, itemCode, title, onAction }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    open: false,
    isValid: false,
    action: ''
  });

  const redirect = items.length === 1;
  const getData = async (pickingCode) => {
    if (!pickingCode) return;

    const rs = await pickingService.getTotalPickingItemList(pickingCode);

    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      setItems(rs?.data?.data || []);
    }
  };

  const isValid = async (code) => {
    let valid = true;
    const rs = await pickingService.checkIsDeletable(code);
    if (rs.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      valid = rs?.data?.isDeletable.toLowerCase() === 'y';
    }
    return valid;
  };

  const handleDeletePicking = async () => {
    // if (!isValid(code)) {
    //   setWarning({
    //     ...warning,
    //     open: true,
    //     subTitle: t('pages.picking.deleteFailed')
    //   });
    // }
    const rs = await pickingService.deleteTotalPickingItem(
      itemCode,
      selectedItem?.pickingCode
    );
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      setSelectedItem(null);
    } else {
      setSelectedItem(null);
      if (redirect) {
        setOpen(false);
        navigate('/inventory-management/picking/total');
      } else {
        getData(itemCode);
        setWarning({
          ...warning,
          open: false
        });
        dispatch(setSuccess({ message: t('info.delete.content') }));
        onAction();
      }
    }
  };
  useEffect(() => {
    getData(itemCode);
  }, [open]);

  const getActionButtons = (item, actionType) => {
    return (
      <IconButton
        onClick={() => {
          setWarning({ ...warning, open: true });
          setSelectedItem(item);
        }}
        disabled={item?.isDeletable !== 'Y'}
      >
        <DeleteIcon />
      </IconButton>
    );
  };
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      onClose={setOpen}
      title={title || `${t('pages.picking.totalList')} ${t('button.modify')}`}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        mr={1}
      >
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {items?.length || 0}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        columns={totalPickingEditColumns}
        data={items}
        getActionButtons={getActionButtons}
      />
      <MyDialog
        isAlert
        open={warning?.open}
        title={t('common.confirm-delete-title', {
          item:
            selectedItem?.totalPickingNickName ||
            selectedItem?.pickingNickName ||
            t('pageTitle.picking')
        })}
        setOpen={() => setWarning({ ...warning, open: false })}
        onClose={() => setWarning({ ...warning, open: false })}
        subTitle={warning?.subTitle}
        onOk={() => {
          handleDeletePicking();
        }}
        hasCancelButton
      />
    </MyDialog>
  );
};

export default EditTotalPickingList;
