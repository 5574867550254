import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';
import InputMultipleFile from '@components/InputMultipleFile';
import AttchmentFile from '@components/AttchmentFile';
import MyTextField from '@components/MyTextField';

import developmentServices from '@services/development';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

import { initStatusList, tableBodyStyle } from '../enhance';

import ChangeStatusDialog from './ChangeStatusDialog';
import SubmitArea from './SubmitArea';
import ReviewedArea from './ReviewedArea';
import CanceledArea from './CanceledArea';
import ProcessedArea from './ProcessedArea';
import CompletedArea from './CompletedArea';
import EditArea from './EditArea';

export default function DevelopmentDetail() {
  const { pathname: location } = useLocation();
  const [fileCode, setFileCode] = useState('');
  const [t] = useTranslation(null, {
    keyPrefix: 'pages.development-request'
  });
  const [g] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [statusData, setStatusData] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [fileList, setFileList] = useState();
  const [isEditting, setisEditting] = useState();
  const [content, setContent] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);

  const isSubmit = data?.status === initStatusList[1].value;
  const isReviewed = data?.status === initStatusList[2].value;
  const isProcessed = data?.status === initStatusList[3].value;
  const isCompleted = data?.status === initStatusList[4].value;
  const isCanceled = data?.status === initStatusList[5].value;

  const getData = async () => {
    if (fileCode) {
      const result = await developmentServices.getDetail(fileCode);
      if (result?.data) setData(result.data?.data || []);
    }
  };

  const handleDelete = async () => {
    const result = await developmentServices.delete(fileCode);
    if (result.error) dispatch(setError({ title: 'Something when wrong' }));
    else handleNavigateBack();
  };

  const handleChangeStatus = (data) => {
    setOpenChangeStatusDialog(true);
    setStatusData(data);
  };

  const handleEdit = () => {
    setisEditting(true);
  };

  const handleUpdateDevelopmentRequest = async () => {
    setUpdateLoading(true);
    const reuslt = await developmentServices.update(fileCode, {
      files: fileList,
      reqContent: content
    });
    setUpdateLoading(false);
    if (reuslt.error) dispatch(setError(error2Text(reuslt.error)));
    else setisEditting(false);
  };

  const handleUploadFiles = (e) => setFileList(e.target.files);

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDialog(true);

  const handleNavigateBack = () => navigate('/manager/development-request');

  const handleDeleteFile = async (id) => {
    const result = await developmentServices.deleteFile(fileCode, id);
    if (result.error) dispatch(setError({ title: 'Something when wrong' }));
    else getData();
  };

  useEffect(() => {
    if (fileCode || !openChangeStatusDialog || !isEditting) getData();
  }, [fileCode, openChangeStatusDialog, isEditting]);

  useEffect(() => {
    if (data) setContent(data.reqContent);
  }, [data]);

  useEffect(() => {
    if (isEditting && data) {
      setContent(data.reqContent);
      setFileList();
    }
  }, [isEditting, data]);

  useEffect(() => {
    const locationArr = location?.split('/');
    setFileCode(locationArr[locationArr.length - 1]);
  }, [location]);

  return (
    <HelmetContainer
      title={isEditting ? g('pageTitle.editDevRq') : g('pageTitle.detailDevRq')}
      content="Development Request"
    >
      <TableContainer
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          mb: '30px'
        }}
      >
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell role="row" component="th">
                {t('title')}
              </TableCell>
              <TableCell>{data.reqSubject}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('writer')}
              </TableCell>
              <TableCell>{data.reqUsername}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('register-date')}
              </TableCell>
              <TableCell>
                {data?.regDate &&
                  new Date(data.regDate).toLocaleString('en-CA', {
                    hour12: false,
                    timeZone: 'Asia/Seoul'
                  })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('status')}
              </TableCell>
              <TableCell>
                {g(`enum.dev-request-status.${data.status}`)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('content')}
              </TableCell>
              <TableCell>
                {!isEditting ? (
                  data.reqContent
                ) : (
                  <MyTextField
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ my: 1.5, width: '100%' }}
                  />
                )}
              </TableCell>
            </TableRow>
            {data?.files?.map((file, index) => {
              if (index === 0)
                return (
                  <TableRow key={file.id}>
                    <TableCell
                      role="row"
                      component="th"
                      rowSpan={data.files.length || 1}
                    >
                      {t('attachments')}
                    </TableCell>
                    <TableCell>
                      <AttchmentFile
                        {...file}
                        handleDelete={isEditting && handleDeleteFile}
                      />
                    </TableCell>
                  </TableRow>
                );
              return (
                <TableRow key={file.id}>
                  <TableCell>
                    <AttchmentFile
                      {...file}
                      handleDelete={isEditting && handleDeleteFile}
                    />
                  </TableCell>
                </TableRow>
              );
            })}

            {isEditting && (
              <TableRow>
                <TableCell role="row" component="th">
                  {t('add-attachments')}
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <InputMultipleFile
                    fileList={fileList}
                    onChange={handleUploadFiles}
                    title={t('add')}
                    buttonProps={{ variant: 'contained' }}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEditting ? (
        <Box>
          {isSubmit && (
            <SubmitArea
              onChangeStatus={handleChangeStatus}
              onEdit={handleEdit}
              onDeleteClick={handleOpenConfirmDeleteDialog}
              onNavigateBack={handleNavigateBack}
              statusList={initStatusList}
            />
          )}
          {!isSubmit && (
            <ReviewedArea
              onChangeStatus={handleChangeStatus}
              statusList={initStatusList}
              isReviewed={isReviewed}
              data={data}
              onNavigateBack={handleNavigateBack}
            />
          )}
          {isCanceled && (
            <CanceledArea data={data} onNavigateBack={handleNavigateBack} />
          )}
          {(isProcessed || isCompleted) && (
            <ProcessedArea
              data={data}
              onNavigateBack={handleNavigateBack}
              onChangeStatus={handleChangeStatus}
              statusList={initStatusList}
              isProcessed={isProcessed}
            />
          )}
          {isCompleted && (
            <CompletedArea data={data} onNavigateBack={handleNavigateBack} />
          )}
        </Box>
      ) : (
        <EditArea
          onNavigateBack={handleNavigateBack}
          onUpdateClick={handleUpdateDevelopmentRequest}
          loading={updateLoading}
        />
      )}
      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onOk={handleDelete}
        isAlert
        title={g('dialog.title.areYouSureDeleteThisRecord')}
        hasCancelButton
      />
      {data?.fileCode && (
        <ChangeStatusDialog
          {...statusData}
          open={openChangeStatusDialog}
          setOpen={setOpenChangeStatusDialog}
          fileCode={data.fileCode}
          key={openChangeStatusDialog}
        />
      )}
    </HelmetContainer>
  );
}
