import { tableTypes } from '@constants/utils';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import i18n from '@utils/i18n';

// for table
const t = i18n.getFixedT(null, null, '');

export const columns = [
  {
    name: 'name',
    label: t('pages.auth.name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'userId',
    label: t('pages.auth.userId'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'isSuper',
    label: t('pages.auth.Super'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    name: 'isAdmin',
    label: t('pages.auth.Admin'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    name: 'isProductMaster',
    label: t('pages.auth.isProductMaster'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    name: 'isStockMaster',
    label: t('pages.auth.isStockMaster'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    name: 'isWarehouseMaster',
    label: t('pages.auth.isWarehouseMaster'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    name: 'isDisplayManager',
    label: t('pages.auth.isDisplayManager'),
    minWidth: 140,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  },
  {
    label: t('common.action'),
    minWidth: 90,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const createModalRows = [
  { name: 'regName', key: 'regName', label: 'regName' },
  { name: 'userId', key: 'userId', label: 'userId' },
  { name: 'password', key: 'password', label: 'password', type: 'password' },
  {
    name: 'confirmPassword',
    key: 'confirmPassword',
    label: 'confirmPassword',
    type: 'password'
  }
];

export const updateModalRole = [
  { label: 'Super', value: 'isSuper' },
  { label: 'Admin', value: 'isAdmin' },
  { label: 'isProductMaster', value: 'isProductMaster' },
  { label: 'isStockMaster', value: 'isStockMaster' },
  { label: 'isWarehouseMaster', value: 'isWarehouseMaster' },
  { label: 'isDisplayManager', value: 'isDisplayManager' }
];

export const maxLengthOf = {
  regName: 20,
  userId: 20,
  password: 128,
  confirmPassword: 128
};
