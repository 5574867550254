import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import { setError } from '@store/reducers/appReducer';
import { toast } from 'react-toastify';
import useResponsive from '@hooks/useResponsive';
import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import minibarOrderService from '@services/minibarOrderService';
// import { Formatter } from '@utils/formatter';
import { MAX_INT } from '@constants/utils';
import { minibarOrderListTableBodyStyle } from '../enhance';

const EditManagementActions = ({
  open,
  setOpen,
  data,
  actionType,
  title,
  getData
}) => {
  const recalltypes = {
    withdrawal: 'recall',
    loss: 'loss',
    discard: 'discard'
  };
  const isSm = useResponsive('down', 900);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({
    recalledAmount: '0',
    totalAmount: data?.stockQuantity
  });
  const [displayInfo, setDisplayInfo] = useState({
    displayAmount: '0',
    totalAmount: data?.stockQuantity
  });

  const handleUpdateRecallInfo = async (action) => {
    setLoading(true);
    if (!data.minibarStockCode) return;
    let rs;
    let msg;
    switch (action) {
      case 'display':
        rs = await minibarOrderService.updateDisplayInfo(
          data.minibarStockCode,
          displayInfo
        );
        msg = t('pages.minibarOrder.messages.success.display');
        break;
      case 'withdrawal':
        rs = await minibarOrderService.updateRecallInfo(
          data.minibarStockCode,
          updateInfo,
          recalltypes.withdrawal
        );
        msg = t('pages.minibarOrder.messages.success.display');
        break;
      case 'dispose':
        rs = await minibarOrderService.updateRecallInfo(
          data?.minibarStockCode,
          updateInfo,
          recalltypes.discard
        );
        msg = t('pages.minibarOrder.messages.success.dispose');
        break;
      case 'loss':
        rs = await minibarOrderService.updateRecallInfo(
          data.minibarStockCode,
          updateInfo,
          recalltypes.loss
        );
        msg = t('pages.minibarOrder.messages.success.loss');
        break;
      default:
        break;
    }
    // if (!data.minibarStockCode) return;

    // const result = await minibarOrderService.updatePriceInfo(
    //   data.minibarStockCode,
    //   priceInfo
    // );

    setLoading(false);
    if (rs.error) dispatch(setError({ title: 'api 요청 실패' }));
    else {
      getData();
      setOpen(false);
      toast.success(msg, { autoClose: 1000 });
    }
  };

  const handleChangeInput = (e, action) => {
    // to do check and fix validation
    const inputAmount = parseInt(e.target.value?.replaceAll(',', '') || 0, 10);
    let lossAmount;

    switch (action) {
      case 'display':
        setDisplayInfo({
          ...displayInfo,
          totalAmount:
            parseInt(updateInfo.totalAmount, 10) +
            Math.min(inputAmount, MAX_INT),
          displayAmount: Math.min(inputAmount, MAX_INT)
        });

        break;
      case 'withdrawal':
        setUpdateInfo({
          ...updateInfo,
          totalAmount:
            parseInt(data.stockQuantity, 10) -
            Math.min(inputAmount, data.stockQuantity),
          recalledAmount: Math.min(inputAmount, data.stockQuantity)
        });
        break;
      case 'dispose':
        lossAmount = data?.branchPrice || data?.masterPrice;
        setUpdateInfo({
          ...updateInfo,
          totalAmount:
            parseInt(data.stockQuantity, 10) -
            Math.min(inputAmount, data.stockQuantity),
          recalledAmount: Math.min(inputAmount, data.stockQuantity),
          recalledCost: Math.min(inputAmount, data.stockQuantity) * lossAmount
        });
        break;
      case 'loss':
        lossAmount = data?.branchPrice || data?.masterPrice;

        setUpdateInfo({
          ...updateInfo,
          totalAmount:
            parseInt(data.stockQuantity, 10) -
            Math.min(inputAmount, data.stockQuantity),
          recalledAmount: Math.min(inputAmount, data.stockQuantity),
          recalledCost: Math.min(inputAmount, data.stockQuantity) * lossAmount
        });
        break;
      default:
        break;
    }
  };

  //   const handleChangePriceInput = (e) => {
  //     const price = parseInt(e.target.value?.replaceAll(',', '') || 0, 10);
  //     setPriceInfo({
  //       ...priceInfo,
  //       [e.target.name]: Math.min(price, MAX_INT)
  //     });
  //   };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={title}
      okTitle={t('button.edit')}
      cancelTitle={t('button.close')}
      hasCloseButton
      hasCancelButton
      onOk={() => handleUpdateRecallInfo(actionType)}
      loading={loading}
      sx={{
        maxWidth: isSm ? 460 : 650,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableBody
            sx={{
              ...minibarOrderListTableBodyStyle,
              th: {
                borderRight: '1px solid #EEEEEE',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                textAlign: 'center',
                color: '#6F869C',
                bgcolor: '#FCFCFC',
                height: '64px',
                maxWidth: ['dispose', 'loss'].includes(actionType) ? 120 : 150
              }
            }}
          >
            <RenderFields
              actionType={actionType}
              t={t}
              item={data}
              values={actionType === 'display' ? displayInfo : updateInfo}
              handleChangeInput={handleChangeInput}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};
const RenderFields = ({ actionType, item, handleChangeInput, values }) => {
  const { t } = useTranslation();
  const isSm = useResponsive('down', 900);
  switch (actionType) {
    case 'dispose':
    case 'loss':
      return (
        <>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('pages.minibarOrder.product-name')}
            </TableCell>
            <TableCell>
              <MyTextField
                disabled
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: '#f2f2f2'
                  }
                }}
                value={item?.productName}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {t('pages.product.minibar-stock')}
            </TableCell>
            <TableCell>
              <MyTextField
                disabled
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: '#f2f2f2'
                  }
                }}
                value={values?.totalAmount}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {actionType === 'dispose'
                ? t('pages.minibarOrder.disposeQuantity')
                : t('pages.minibarOrder.lossQuantity')}
              {`( 0 ~ ${item?.stockQuantity})`}
            </TableCell>
            <TableCell>
              <MyTextField
                value={values?.recalledAmount}
                onChange={(e) => handleChangeInput(e, actionType)}
              />
            </TableCell>
          </TableRow>
          {actionType === 'loss' && (
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.lossAmount')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={values.recalledCost || 0}
                />
              </TableCell>
            </TableRow>
          )}
        </>
      );

    default:
      if (isSm) {
        return (
          <>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.product-name')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={item?.productName}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" align="center">
                {actionType === 'display'
                  ? t('pages.minibarOrder.displayAmount')
                  : t('pages.minibarOrder.withdrawalAmount')}
              </TableCell>
              <TableCell align="center">
                <MyTextField
                  value={values?.displayAmount || values?.recalledAmount}
                  onChange={(e) => handleChangeInput(e, actionType)}
                  sx={{ width: '120px', my: 1 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                scope="row"
                sx={{
                  minWidth: '120px',
                  width: 'auto !important',
                  my: 2,
                  whiteSpace: 'nowrap !important'
                }}
              >
                {actionType === 'display' ? '(+) ' : '(-) '}
                {t('pages.product.minibar-stock')}
              </TableCell>
              <TableCell align="center">
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2',

                      width: '120px',
                      my: 2
                    }
                  }}
                  value={values?.totalAmount || 0}
                />
              </TableCell>
            </TableRow>
          </>
        );
      }
      return (
        <>
          <TableRow sx={{ my: 2 }}>
            <TableCell sx={{ width: '100px' }} align="center">
              {t('pages.minibarOrder.product-name')}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              <MyTextField
                disabled
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: '#f2f2f2',
                    width: '300px',
                    my: 2
                  }
                }}
                value={item?.productName}
              />
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              width: '200px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TableCell scope="row" align="center">
              {actionType === 'display'
                ? t('pages.minibarOrder.displayAmount')
                : t('pages.minibarOrder.withdrawalAmount')}
            </TableCell>
            <TableCell align="center">
              <MyTextField
                value={values?.displayAmount || values?.recalledAmount}
                onChange={(e) => handleChangeInput(e, actionType)}
                sx={{ width: '120px' }}
              />
            </TableCell>
            <TableCell
              scope="row"
              sx={{ minWidth: '100px', whiteSpace: 'nowrap', my: 2 }}
            >
              {actionType === 'display' ? '(+) ' : '(-) '}
              {t('pages.product.minibar-stock')}
            </TableCell>
            <TableCell>
              <MyTextField
                disabled
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: '#f2f2f2',

                    width: '120px',
                    my: 2
                  }
                }}
                value={values?.totalAmount || 0}
              />
            </TableCell>
          </TableRow>
        </>
      );
  }
};
export default EditManagementActions;
