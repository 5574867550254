import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';

import homepageService from '@services/homepage';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { toast } from 'react-toastify';
import { tableStyle } from './enhance';

const MediaActions = ({ open, setOpen, item, action }) => {
  const { t: pageT } = useTranslation(null, {
    keyPrefix: 'pages.homepage'
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({
    url: item?.url || '',
    title: item?.title || ''
  });
  const [scrapeStatus, setScrapeStatus] = useState({
    isScraped: false,
    success: false
  });
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({
    url: '',
    title: ''
  });

  useEffect(() => {
    if (open) {
      setFormValue({
        url: item?.url || '',
        title: item?.title || ''
      });
      setScrapeStatus({
        isScraped: false,
        success: false
      });
      setLoading(false);
      setErrMsg({
        url: '',
        title: ''
      });
    }
  }, [open, item]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });

    setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
  };

  const validateForm = () => {
    let check = true;
    const msg = { ...errMsg };
    if (!formValue.url) {
      msg.url = t('common.required');
      check = false;
    }
    if (!formValue.title) {
      msg.reqContent = t('common.required');
      check = false;
    }
    if (formValue.url > 1500) {
      msg.reqSubject = t('validation.dataTooLong', { max: 1500 });
      check = false;
    }
    if (!formValue.title > 1500) {
      msg.reqContent = t('validation.dataTooLong', { max: 1500 });
      check = false;
    }
    setErrMsg({ ...msg });
    return check;
  };

  const handleCreateScrapeRequest = async () => {
    if (!formValue?.url) {
      return;
    }
    setLoading(true);
    const result = await toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          homepageService
            .createScrapeRequest({ url: formValue?.url })
            .then(resolve)
            .catch(reject);
        }, 1000); // delay for 1 second
      }),
      {
        pending: t('info.fetch.loading'),
        success: `${t('info.scrape.success')}`,
        error: `${t('info.fetch.success')}`
      }
    );

    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setScrapeStatus({
        ...scrapeStatus,
        isScraped: true,
        success: Boolean(result?.data.title)
      });
      if (result?.data?.data?.title) {
        setFormValue({
          ...formValue,
          ...result?.data?.data,
          url: result?.data?.data?.url || formValue.url
        });
        setErrMsg({ ...errMsg, title: '' });
      } else {
        setErrMsg({
          ...errMsg,
          title:
            '기사 제목을 가져오지 못했습니다. 기사 제목을 직접 입력해주세요.'
        });
      }
    }
  };

  const onAction = async () => {
    if (!validateForm()) return;
    let rs;
    let sendData = {};
    let successMsg;
    switch (action) {
      case 'register':
        sendData = { ...formValue };
        rs = await homepageService.registerNewMedia(sendData);
        successMsg = t('info.register.content');
        break;
      case 'update':
        sendData = { title: formValue?.title };
        rs = await homepageService.updateMedia(item?.newsCode, sendData);
        successMsg = t('info.success.content');
        break;
      default:
        break;
    }
    if (rs.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      setOpen(false);
      dispatch(setSuccess({ message: successMsg }));
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={
        action === 'register' ? pageT('register-news') : pageT('edit-news')
      }
      okTitle={action === 'register' ? t('button.register') : t('button.save')}
      hasCloseButton
      onOk={() => {
        if (validateForm()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => onAction()
            })
          );
        }
      }}
      loading={loading}
      disabled={loading || !formValue.url || !formValue?.title}
      sx={{
        maxWidth: 1000,
        minWidth: 800,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {pageT('link')}
              </TableCell>
              <TableCell>
                <MyTextField
                  errMg={errMsg?.url}
                  name="url"
                  value={formValue.url}
                  onChange={handleChangeInput}
                  fullWidth
                  sx={{ width: '100%' }}
                  textFieldStyle={{
                    py: 1,

                    ...((loading ||
                      (scrapeStatus.isScraped && scrapeStatus.success) ||
                      action === 'update') && {
                      backgroundColor: '#D9D9D9',

                      color: '#111111 !important'
                    })
                  }}
                  disabled={
                    loading ||
                    (scrapeStatus.isScraped && scrapeStatus.success) ||
                    action === 'update'
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="outlined"
                          disabled={
                            !formValue.url ||
                            !formValue?.url.startsWith('http') ||
                            loading ||
                            (scrapeStatus?.isScraped && scrapeStatus.success) ||
                            action === 'update'
                          }
                          onClick={handleCreateScrapeRequest}
                          size="small"
                          sx={{ height: 35, boxShadow: 0 }}
                        >
                          {t('pages.curation.apply')}
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {pageT('news-subject')}
              </TableCell>
              <TableCell>
                <MyTextField
                  errMg={errMsg?.title}
                  name="title"
                  value={formValue.title}
                  onChange={handleChangeInput}
                  textFieldStyle={{
                    ...(action === 'register' &&
                      !scrapeStatus?.isScraped && {
                        backgroundColor: '#F2F2F2'
                      })
                  }}
                  fullWidth
                  disabled={
                    (action === 'register' && !scrapeStatus?.isScraped) ||
                    loading
                  }
                  sx={{ width: '100%' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default MediaActions;
