import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sub } from 'date-fns';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { Button, FormControl, FormControlLabel, Stack } from '@mui/material';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';
import MyTable from '@components/MyTable';
import Calendar from '@components/Calendar';
import SearchBar from '@components/SearchBar';
import MyLoadingButton from '@components/MyLoadingButton';
import HelmetContainer from '@components/HelmetContainer';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { setError } from '@store/reducers/appReducer';
import statisticsMemberService from '@services/statisticsMember';
import { activedColumns, growthColumns, registeredColumns } from './enhance';

const Member = () => {
  const [tab, setTab] = useState('1');
  const { formatTime } = Formatter;
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeTab = (event, newValue) => {
    const query = objToParams(
      ignoreEmpty({
        ...param,
        tab: newValue
      })
    );
    navigate(query);
    setTab(newValue);
  };
  const [client, setClient] = useState(null);
  const [param, setParam] = useState({
    baseMonth: formatTime(sub(new Date(), { months: 1 }), 'YYYY-MM'),
    clientCode: null
  });
  const [state, setState] = useState(null);
  const [date, setDate] = useState(sub(new Date(), { months: 1 }));

  const getMemberAttribute = async (param) => {
    const rs = await statisticsMemberService.getMemberAttribute(param);
    if (!rs?.error) {
      setState({
        ...state,
        ...rs?.data
      });
    }
  };
  const getMemberGrowth = async (param) => {
    const rs = await statisticsMemberService.getMemberGrowth(param);
    if (!rs?.error) {
      setState(rs?.data);
    }
  };
  const exportMemberAttribute = async (param) => {
    const rs = await statisticsMemberService.exportMemberAttribute(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const exportMemberGrowth = async (param) => {
    const rs = await statisticsMemberService.exportMemberGrowth(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setParam({
      ...param,
      baseMonth: formatTime(
        query?.baseMonth || sub(new Date(), { months: 1 }),
        'YYYY-MM'
      ),
      clientCode: query?.clientCode || null
    });
    setDate(
      query?.baseMonth
        ? new Date(query?.baseMonth)
        : sub(new Date(), { months: 1 })
    );
    setTab(query?.tab || '1');
    setClient({ ...client, clientCode: query?.clientCode });
    if (query?.tab === '1' || !query?.tab) {
      getMemberAttribute({
        baseMonth: formatTime(
          query?.baseMonth || sub(new Date(), { months: 1 }),
          'YYYY-MM'
        ),
        clientCode: query?.clientCode || null
      });
    } else {
      getMemberGrowth({
        baseMonth: formatTime(
          query?.baseMonth || sub(new Date(), { months: 1 }),
          'YYYY-MM'
        ),
        clientCode: query?.clientCode || null
      });
    }
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(
      ignoreEmpty({
        baseMonth: param?.baseMonth || '',
        clientCode: param?.clientCode || '',
        tab
      })
    );
    navigate(query);
    if (tab === '1') {
      getMemberAttribute({
        baseMonth: formatTime(param?.baseMonth, 'YYYY-MM'),
        clientCode: param?.clientCode
      });
    } else {
      getMemberGrowth({
        baseMonth: formatTime(param?.baseMonth, 'YYYY-MM'),
        clientCode: param?.clientCode
      });
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.memberStatistics')}
      content="memberStatistics page"
    >
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
          <Box sx={{ borderColor: 'divider', mb: 2 }}>
            <TabList onChange={handleChangeTab}>
              <Tab label={t('pages.statistics.member-attribute')} value="1" />
              <Tab label={t('pages.statistics.member-growth')} value="2" />
            </TabList>
          </Box>
          <SearchBar>
            <FormControl>
              <FormControlLabel
                label={t('pages.statistics.month')}
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    mr: 1.5
                  }
                }}
                control={
                  <Calendar
                    monthYear
                    condition="monthCurrent"
                    date={date}
                    setDate={(e) => {
                      setDate(e);
                      setParam({
                        ...param,
                        baseMonth: formatTime(e, 'YYYY-MM')
                      });
                    }}
                  />
                }
              />
            </FormControl>
            <ClientAutoCompleteV2
              client={client}
              channel="B2C"
              setClient={(e) => {
                setClient(e);
                setParam({ ...param, clientCode: e?.clientCode });
              }}
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
            />
            <Button
              onClick={handleSearch}
              variant="contained"
              sx={{ ml: 1.5, width: '80px' }}
            >
              {t('button.search')}
            </Button>
          </SearchBar>
          <Stack flexDirection="row" mt={2}>
            <MyLoadingButton
              variant="outlined"
              onClick={() =>
                tab === '1'
                  ? exportMemberAttribute(param)
                  : exportMemberGrowth(param)
              }
            >
              {t('button.saveExcel')}
            </MyLoadingButton>
          </Stack>
          <TabPanel sx={{ m: 0, p: 0, mt: 2 }} value="1">
            <MemberAttribute data={state} />
          </TabPanel>
          <TabPanel sx={{ m: 0, p: 0 }} value="2">
            <MemberGrowth data={state} />
          </TabPanel>
        </TabContext>
      </Box>
    </HelmetContainer>
  );
};

const MemberAttribute = ({ data }) => {
  return (
    <Box>
      <GroupMultiHeaderTable
        columns={registeredColumns}
        data={[data?.registeredMember]}
        minusHeight={500}
      />
      <GroupMultiHeaderTable
        columns={activedColumns}
        data={[data?.activeMember]}
        minusHeight={500}
      />
    </Box>
  );
};
const MemberGrowth = ({ data }) => {
  return (
    <MyTable rerender columns={growthColumns} data={data} minusHeight={300} />
  );
};

export default Member;
