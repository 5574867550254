/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  Stack
} from '@mui/material';
import { sub } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import warehouseService from '@services/warehouse';
import MyTextField from '@components/MyTextField';
import { dateFormat } from '@constants/utils';
import SearchBar from '@components/SearchBar';
import MySelect from '@components/MySelect';
import {
  compareDates,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import DateRangePicker from '@components/DateRangePicker';

import { Formatter } from '@utils/formatter';
import MyTable from '../Warehouse/components/NewTable/GroupTableCustomize';
import { inventorySearchSelectOptions, stockingColumns } from './enhance';

const Stocking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [param, setParam] = useState({
    startDate: Formatter.formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: Formatter.formatTime(new Date(), dateFormat),
    productName: null,
    barcode: null,
    page: 0
  });
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState({
    excelDownload: false,
    registerAllItems: false
  });
  const [condition, setCondition] = useState('productName');

  const { pagination } = state;

  const onChangePage = (value) => {
    setParam({ ...param, page: value });
    const query = objToParams(ignoreEmpty({ ...param, page: value + 1 }));
    navigate(query);
  };

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    navigate(query);
    getStockingList({ ...param, page: 1 });
  };

  const handleChangeInputfield = (e) => {
    setParam({ ...param, [condition]: e?.target?.value });
  };

  const configColumns = stockingColumns();

  const getStockingList = async (receivedParams) => {
    const rs = await warehouseService.getWarehousingList(receivedParams);
    if (!rs.error) {
      setItems(rs?.data?.items || []);
      setState({
        ...state,
        items: rs?.data?.items,
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  const handleChangeDate = (e) => {
    setParam({
      ...param,
      startDate: Formatter.formatTime(e.startDate, dateFormat),
      endDate: Formatter.formatTime(e.endDate, dateFormat)
    });
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { startDate, endDate, productName, barcode, page } = query;
    setCondition((barcode && 'barcode') || 'productName');

    const firstDayOfMonth = `${Formatter.formatTime(new Date(), 'YYYY-MM')}-01`;
    const dataParams = {
      ...param,
      startDate: Formatter.formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: Formatter.formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      barcode: barcode || null,
      page: page - 1 || 0
    };
    setParam(dataParams);
    getStockingList(dataParams);
  }, [location?.search]);

  const exportWarehousingItems = async () => {
    const query = paramsToObj(location?.search);
    const { startDate, endDate, productName, barcode } = query;
    const dataSubmit = {
      startDate: startDate || param?.startDate,
      endDate: endDate || param?.endDate,
      productName,
      barcode
    };
    setLoading({ ...loading, excelDownload: true });
    const rs = await warehouseService.exportWarehouseingItems(dataSubmit);
    if (rs?.error) {
      console.log(rs?.error);
    }
    setLoading({ ...loading, excelDownload: false });
  };
  return (
    <HelmetContainer title={t('pageTitle.stocking')} content="Stocking Page">
      <SearchBar>
        <MySelect
          label={t('pages.product.condition')}
          data={inventorySearchSelectOptions}
          value={condition}
          onChange={(e) => {
            setParam({ ...param, productName: '', barcode: '' });
            setCondition(e?.target?.value);
          }}
          sx={{
            mr: 1
          }}
        />
        <MyTextField
          value={param?.[condition]}
          onChange={handleChangeInputfield}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Stack flexDirection="row" alignItems="center">
          <FormControl>
            <FormControlLabel
              label={t('labels.period')}
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  whiteSpace: 'nowrap',
                  mr: 1
                }
              }}
              control={
                <DateRangePicker
                  state={{
                    startDate: new Date(param.startDate),
                    endDate: new Date(param.endDate),
                    key: 'selection'
                  }}
                  setState={handleChangeDate}
                />
              }
            />
          </FormControl>
          <FormControlLabel
            label={t('pages.inventory.today')}
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap'
              },
              ml: 2
            }}
            control={
              <Radio
                checked={
                  !compareDates(param.startDate, param.endDate) &&
                  !compareDates(
                    param.startDate,
                    Formatter.formatTime(new Date(), dateFormat)
                  )
                }
                onChange={() =>
                  setParam({
                    ...param,
                    startDate: Formatter.formatTime(new Date(), dateFormat),
                    endDate: Formatter.formatTime(new Date(), dateFormat)
                  })
                }
                size="small"
              />
            }
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        mt={1}
      >
        <Button
          variant="outlined"
          startIcon={loading.excelDownload && <CircularProgress size="20px" />}
          onClick={exportWarehousingItems}
          disabled={loading.excelDownload}
        >
          {t('button.saveExcel')}
        </Button>
      </Stack>
      <MyTable
        hover
        columns={configColumns}
        data={items}
        pagination={pagination}
        onChangePage={(e, value) => onChangePage(value)}
        minusHeight={230}
      />
    </HelmetContainer>
  );
};

export default Stocking;
