import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MyDialog from '@components/MyDialog';
import displayService from '@services/display';
import warehouseService from '@services/warehouse';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { columns } from './enhance';
import PicForm from './components/PicForm';

const PIC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configColumns = columns;
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const [selected, setSelected] = useState(null);
  const [picForm, setPicForm] = useState({
    employeeNumber: '',
    employeeName: '',
    employeeArea: '',
    employeeZone: '',
    employeePhone: ''
  });
  const [errMg, setErrMg] = useState({
    employeeNumber: '',
    employeeName: '',
    employeePhone: '',
    employeeArea: '',
    employeeZone: ''
  });
  const getActionButtons = (item) => (
    <Stack direction="row" justifyContent="center">
      <IconButton
        size="small"
        onClick={() => {
          setPicForm({
            ...picForm,
            employeeNumber: item?.pbNum,
            employeeName: item?.managerName,
            employeeArea: item?.managerArea,
            employeeZone: item?.managerZone,
            employeePhone: item?.managerPhone
          });
          setSelected({
            ...selected,
            title: t('button.edit'),
            open: true,
            action: 'edit',
            item
          });
        }}
      >
        <EditIcon color="primary" />
      </IconButton>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setNotifyDialog({
            open: true,
            title: t('common.confirm-delete-title', {
              item: item?.managerName
            }),
            onOk: () => {
              deletePic(item);
            },
            setOpen: () => {
              setNotifyDialog({ ...notifyDialog, open: false });
            }
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
  const [param, setParam] = useState({
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => setParam({ ...param, page });

  const getPics = async (param) => {
    const rs = await warehouseService.getPic(param);
    setState({
      ...state,
      items: rs.data?.items || [],
      pagination: {
        ...state?.pagination,
        page: rs.data?.page || 0,
        totalRow: rs.data?.totalRow || 0,
        count: rs.data?.totalPage || 0
      }
    });
  };

  useEffect(() => {
    getPics(param);
  }, [param?.page]);
  const checkValidate = (data) => {
    let isValidate = true;
    const vldErr = { ...errMg };

    if (!data?.employeeNumber) {
      vldErr.employeeNumber = t('common.required');
      isValidate = false;
    }
    if (!data?.employeeName) {
      vldErr.employeeName = t('common.required');
      isValidate = false;
    }
    // eslint-disable-next-line	no-restricted-syntax
    for (const property in vldErr) {
      if (vldErr[property]) {
        isValidate = false;
      }
    }
    setErrMg(vldErr);
    return isValidate;
  };
  const dialogOk = async () => {
    let rs = null;
    const { employeeNumber, employeeName } = picForm;
    if (employeeNumber && employeeName) {
      if (selected?.action === 'register') {
        rs = await displayService.createPic(picForm);
      }
      if (selected?.action === 'edit') {
        rs = await displayService.editPic(selected?.item?.managerCode, picForm);
      }
    }
    if (!rs?.error) {
      getPics({ page: 0 });
      setSelected({ ...selected, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    return true;
  };
  const deletePic = async (item) => {
    const rs = await displayService.deletePic(item?.managerCode);
    if (!rs?.error) {
      getPics({ page: 0 });
      setNotifyDialog({ ...notifyDialog, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer title={t('pageTitle.PIC')} content="PIC page">
      <Stack
        mb={2}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="outlined"
          onClick={() => {
            setPicForm({
              employeeNumber: '',
              employeeName: '',
              employeeArea: '',
              employeeZone: '',
              employeePhone: ''
            });
            setSelected({
              ...selected,
              open: true,
              title: t('pages.inventory.register'),
              action: 'register'
            });
          }}
        >
          {t('pages.inventory.register')}
        </Button>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {state?.pagination?.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        data={state?.items}
        columns={configColumns}
        getActionButtons={getActionButtons}
        pagination={state?.pagination}
        minusHeight={150}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <MyDialog
        open={notifyDialog?.open}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
      <MyDialog
        okTitle={t('common.pic-ok')}
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          if (checkValidate(picForm)) {
            dispatch(
              onOpenConfirm({
                open: true,
                type: selected?.action === 'register' ? 'save' : 'modify',
                action: () => dialogOk()
              })
            );
          }
        }}
        onClose={() =>
          setErrMg({
            employeeNumber: '',
            employeeName: ''
          })
        }
      >
        <PicForm
          value={picForm}
          setValue={setPicForm}
          errMg={errMg}
          setErrMg={setErrMg}
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default PIC;
