import Calendar from '@components/Calendar';
import MyTable from '@components/MyTable';
import MyTextField from '@components/MyTextField';
import moment, { now } from 'moment';
import { dateFormat, tableTypes } from '@constants/utils';
import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const configColumn = [
  {
    name: 'division',
    label: 'Division',
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'content',
    label: 'Content',
    minWidth: 160,
    align: 'center',
    type: tableTypes.RERENDER
  },
  {
    name: 'note',
    label: 'Note',
    minWidth: 160,
    align: 'center'
  }
];

export default function RenewalForm({
  item,
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const { t } = useTranslation();
  const { clientCode, recontractPeriod, recontractDate, clientRecontractId } =
    item;

  const [renewal, setRenewal] = useState({
    clientCode,
    recontractPeriod: '',
    recontractDate: new Date(now()),
    clientRecontractId
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setRenewal({
      ...renewal,
      [name]: value
    });
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(renewal);
    } else {
      onClose();
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        mt: '30px',
        p: 0,
        maxWidth: '690px',
        maxHeight: '80vh'
      }}
    >
      <MyTable
        hover
        columns={configColumn}
        data={[
          {
            division: t('labels.period'),
            content: (
              <RenderInput
                value={renewal.recontractPeriod}
                onChange={handleChangeInput}
              />
            ),
            note: recontractPeriod
          },
          {
            division: t('labels.renewal-contract-date'),
            content: (
              <ReRenderDatePicker
                value={renewal.recontractDate}
                onChange={handleChangeInput}
              />
            ),
            note: recontractDate
              ? moment(recontractDate).format(dateFormat)
              : ''
          }
        ]}
      />
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
const ReRenderDatePicker = ({ value, onChange }) => {
  const handleChange = (e) => {
    onChange({ target: { value: e, name: 'recontractDate' } });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        height: '100%',
        flexGrow: 1
      }}
    >
      <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Calendar
          date={new Date(value)}
          sx={{
            borderRadius: '8px'
          }}
          setDate={handleChange}
        />
      </Stack>
    </Stack>
  );
};
const RenderInput = ({ value, onChange }) => {
  const handleChange = (e) => {
    const { value, name } = e.target;
    onChange({ target: { value, name } });
  };
  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1
      }}
    >
      <MyTextField
        size="small"
        name="recontractPeriod"
        value={value}
        onChange={handleChange}
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          margin: '10px 0px'
        }}
      />
    </Stack>
  );
};
