import RestService from '@services/RestService';

const registerPerformService = {
  async register(data) {
    try {
      const res = await new RestService()
        .setPath('/performance/client')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default registerPerformService;
