import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useMergeState from '@hooks/useMergeState';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { IconButton, Stack, Typography } from '@mui/material';

import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import HelmetContainer from '@components/HelmetContainer';

import error2Text from '@utils/error2Text';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import categoryService from '@services/category';
import CategoryForm from './components/CategoryForm';
import { columnCategory } from './enhance';

const maxLengthOf = {
  categoryName: 30
};

const CategoryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPlusButton1, setShowPlusButton1] = useState(false);

  const [category1List, setCategory1List] = useState([]);
  const [category2List, setCategory2List] = useState([]);
  const [category3List, setCategory3List] = useState([]);
  const [currentCategories, setCurrrentCategories] = useState({
    category1Code: '',
    category2Code: ''
  });

  const columnConfig = (categorytype) => {
    const label = categorytype;
    const result = [
      columnCategory[0],
      {
        ...columnCategory[1],
        label:
          (label === '1st' && t('pages.product.first-category')) ||
          (label === '2nd' && t('pages.product.second-category')) ||
          (label === '3rd' && t('pages.product.third-category'))
      },
      columnCategory[2]
    ];
    return result;
  };
  const [selected, setSelected] = useState({
    open: false,
    title: '',
    action: '',
    path: '',
    items: {}
  });
  const [errMg, setErrMg] = useMergeState({
    categoryName: ''
  });

  const getCategoryList = async () => {
    const result = await categoryService.getCategory();
    if (!result.error) {
      const categoryList = [];
      result?.data.map((category1) => categoryList.push(category1));
      setCategory1List(categoryList);

      if (currentCategories.category1Code) {
        const categoryToFilter = categoryList?.find(
          (item) => item.categoryCode === currentCategories.category1Code
        );

        setCategory2List(categoryToFilter?.categories2List);
        const currentCategory2 = currentCategories.category2Code
          ? categoryToFilter?.categories2List.find(
              (item) => item.categoryCode === currentCategories.category2Code
            )
          : [];
        setCategory3List(currentCategory2?.categories3List || []);
      }
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  const filterCategories = (category) => {
    console.log(category.categoryCode);
    if (['First', '1st', 'first'].includes(category?.categoryType)) {
      setCurrrentCategories({
        ...currentCategories,
        category1Code: category?.categoryCode,
        category2Code: ''
      });

      setShowPlusButton1(true);
      setCategory2List(category?.categories2List);

      setCategory3List([]);
    }
    if (['Second', '2nd', 'second'].includes(category?.categoryType)) {
      setCategory3List(category?.categories3List);

      setCurrrentCategories({
        ...currentCategories,
        category1Code: category?.category1Code,
        category2Code: category?.categoryCode
      });
    }
  };
  const handleChangeModification = (name, { target: { value } }) => {
    console.log();
    setSelected({
      ...selected,
      items: {
        ...selected.items,
        [name]: value
      }
    });
    let msg = '';
    if (!value) {
      msg = t('common.required');
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  const checkValidate = (data) => {
    let isValidate = true;
    const vldErr = { ...errMg };

    // Additional validation for input duplicates based on categoryType
    if (data.categoryType === '2nd') {
      const duplicateCategory2 = category2List.find(
        (category) => category.categoryName === data.categoryName
      );
      if (duplicateCategory2) {
        vldErr.categoryName = t('validation.duplicateCategory2');
        isValidate = false;
      }
    } else if (data.categoryType === '3rd') {
      const duplicateCategory3 = category3List.find(
        (category) => category.categoryName === data.categoryName
      );
      if (duplicateCategory3) {
        vldErr.categoryName = t('validation.duplicateCategory3');
        isValidate = false;
      }
    }

    // Existing validation logic
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in vldErr) {
      if (!data[property]) {
        vldErr[property] = t('common.required');
        isValidate = false;
      }
    }

    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });

    setErrMg(vldErr);
    return isValidate;
  };

  const onAction = (action, value) => {
    if (action === 'registration') {
      setSelected({
        ...selected,
        open: true,
        action: 'registration',
        title: t('pages.product.category-register'),
        items: { ...value }
      });
    }
    if (action === 'editCategory') {
      const categoryTypes =
        (value.categoryType === 'first' && 'First') ||
        (value.categoryType === 'second' && 'Second') ||
        (value.categoryType === 'third' && 'Third');
      setSelected({
        ...selected,
        open: true,
        action: 'editCategory',
        title: t('pages.product.category-modify'),
        items: {
          ...value,
          categoryType: categoryTypes
        }
      });
    }

    if (action === 'selectCategory') {
      console.log('value', value);
      filterCategories(value);
    }
  };

  const getActionButtons = (item) => (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        // TODO : config category types for textfield
        onAction('editCategory', {
          ...item,
          categoryType: item?.categoryType
        });
      }}
    >
      <EditIcon />
    </IconButton>
  );
  const onSubmit = async (action) => {
    if (action === 'registration' || action === 'editCategory') {
      const dataSubmit = selected?.items;
      if (selected?.items?.categoryName?.length > 300) {
        dispatch(setError(error2Text({ keyError: 'LENGTH-LESS-20' })));
        return false;
      }

      let rs;

      // Category 1st create and update logic
      if (['First', '1st', 'first'].includes(selected?.items?.categoryType)) {
        rs =
          action === 'registration'
            ? await categoryService.createCategory1(dataSubmit)
            : await categoryService.editCategory1(dataSubmit);
        getCategoryList();
      }

      // Category 2nd create and update logic
      else if (
        ['Second', '2nd', 'second'].includes(selected?.items?.categoryType)
      ) {
        rs =
          action === 'registration'
            ? await categoryService.createCategory2(
                dataSubmit,
                currentCategories.category1Code
              )
            : await categoryService.editCategory2(dataSubmit);
        getCategoryList();
      }

      // Category 3rd create and update logic
      else if (
        ['Third', '3rd', 'third'].includes(selected?.items?.categoryType)
      ) {
        rs =
          action === 'registration'
            ? await categoryService.createCategory3(
                dataSubmit,
                currentCategories.category1Code,
                currentCategories.category2Code
              )
            : await categoryService.editCategory3(dataSubmit);
        getCategoryList();
      }

      if (!rs.error) {
        setSelected({
          ...selected,
          open: false
        });
      } else {
        dispatch(setError(error2Text(rs.error)));
      }

      return true;
    }
    return false;
  };

  return (
    <HelmetContainer title={t('pageTitle.category')} content="Category page">
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={4}>
          <MyTable
            minusHeight={100}
            getActionButtons={getActionButtons}
            onRowClick={(vl) =>
              onAction('selectCategory', {
                ...vl
              })
            }
            columns={columnConfig('1st')}
            data={category1List}
            isCategory
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: '-20px',
              border: '1px solid #D3D3D3',
              borderRadius: '0 0 12px 12px',
              overflowY: 'hidden'
            }}
          >
            <IconButton
              onClick={() =>
                onAction('registration', { categoryType: 'First' })
              }
            >
              <AddIcon />
              <Typography
                sx={{
                  width: 160,
                  fontSize: 13,
                  color: '#6F869C'
                }}
              >
                {t('pages.product.select-category')}
              </Typography>
            </IconButton>
          </Stack>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={4}>
          <MyTable
            minusHeight={100}
            getActionButtons={getActionButtons}
            onRowClick={(vl) =>
              onAction('selectCategory', {
                ...vl
              })
            }
            columns={columnConfig('2nd')}
            data={category2List}
            isCategory
          />

          {(category2List.length !== 0 || showPlusButton1) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: '-20px',
                border: '1px solid #D3D3D3',
                borderRadius: '0 0 12px 12px'
              }}
            >
              <IconButton
                onClick={() =>
                  onAction('registration', { categoryType: 'Second' })
                }
              >
                <AddIcon />
                <Typography
                  sx={{
                    width: 160,
                    fontSize: 13,
                    color: '#6F869C'
                  }}
                >
                  {t('pages.product.select-category')}
                </Typography>
              </IconButton>
            </Stack>
          )}
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={4}>
          <MyTable
            minusHeight={100}
            getActionButtons={getActionButtons}
            columns={columnConfig('3rd')}
            data={category3List}
            isCategory
          />

          {currentCategories.category2Code && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: '-20px',
                border: '1px solid #D3D3D3',
                borderRadius: '0 0 12px 12px'
              }}
            >
              <IconButton
                onClick={() =>
                  onAction('registration', { categoryType: 'Third' })
                }
              >
                <AddIcon />
                <Typography
                  sx={{
                    width: 160,
                    fontSize: 13,
                    color: '#6F869C'
                  }}
                >
                  {t('pages.product.select-category')}
                </Typography>
              </IconButton>
            </Stack>
          )}
        </Grid>
      </Grid>

      <MyDialog
        title={selected?.title}
        hasCloseButton
        hasCancelButton
        open={selected.open}
        onClose={() =>
          setErrMg({
            categoryName: ''
          })
        }
        setOpen={() => setSelected({ ...selected, open: false })}
        onOk={() => {
          if (checkValidate(selected?.items)) {
            dispatch(
              onOpenConfirm({
                open: true,
                type: selected.action === 'registration' ? 'save' : 'modify',
                action: () => onSubmit(selected.action)
              })
            );
          }
        }}
      >
        <CategoryForm
          action={selected.action}
          onChange={(name, e) => handleChangeModification(name, e)}
          items={selected.items}
          errMg={errMg}
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default CategoryPage;
