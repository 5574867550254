import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const statisticsMemberService = {
  async getMemberAttribute(params = {}) {
    try {
      const res = await new RestService()
        .setPath('member-statistics/attribute')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getMemberGrowth(params = {}) {
    try {
      const res = await new RestService()
        .setPath('member-statistics/growth')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportMemberAttribute(params = {}) {
    try {
      const res = await new RestService()
        .setPath('member-statistics/attribute/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportMemberGrowth(params = {}) {
    try {
      const res = await new RestService()
        .setPath('member-statistics/growth/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default statisticsMemberService;
