import { tableTypes } from '@constants/utils';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');

export const statusRadios = [
  { value: 'total', label: t('total') },
  { value: 'ReadyToShip', label: t('ready-shipping') },
  { value: 'OnShipping', label: t('on-shipping') },
  { value: 'OnDisplay', label: t('on-display') },
  { value: 'Completed', label: t('completion') }
];

export const shippingStates = {
  ReadyToShip: t('ready-shipping'),
  OnShipping: t('on-shipping'),
  OnDisplay: t('on-display'),
  Completed: t('completion')
};

export const columns = [
  {
    name: 'regDt',
    label: t('date'),
    minWidth: 160,
    align: 'center',
    render: ({ regDt }) => {
      return new Date(regDt).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      });
    }
  },
  {
    name: 'clientName',
    label: p('client'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientChannel',
    label: t('channel'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'picName',
    label: t('pic'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'status',
    label: t('status'),
    minWidth: 160,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const displayColumns = [
  {
    name: 'productCode',
    label: p('product-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'stockQuantity',
    label: p('minibar-stock'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'pickingQuantity',
    label: t('picking-quantity'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'displayQuantity',
    label: t('display-quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) =>
      formatMoney(
        Number(item?.pickingQuantity) -
          Number(item?.lossQuantity) -
          Number(item?.disposalQuantity)
      )
  },
  {
    label: t('managements'),
    minWidth: 160,
    align: 'center',
    type: tableTypes.ACTION
  }
];
