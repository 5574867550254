import MyTextField from '@components/MyTextField';
import { Box, Container, Stack, Typography, styled } from '@mui/material';
import releaseService from '@services/release';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { formatMoney } from '@utils/helpers';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};

const LossForm = ({ action, item, lossValue, setLossValue, idShip }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const getProducts = async () => {
    const rs = await releaseService.getDetailShippingProduct(
      idShip,
      item?.shippingProductId
    );
    if (!rs?.error && rs?.data) {
      setLossValue(rs?.data);
      setState(rs?.data);
    }
  };
  useEffect(() => {
    setState({ ...state, ...item });
    getProducts();
  }, []);
  return (
    <Container component={BorderBox}>
      <FieldContainer label={t('pages.product.product-name')}>
        <Typography sx={{ width: '100%' }}>{state?.productName}</Typography>
      </FieldContainer>
      <FieldContainer label={t('pages.product.minibar-stock')}>
        <Typography sx={{ width: '100%' }}>{state?.stockQuantity}</Typography>
      </FieldContainer>
      <FieldContainer
        label={
          action === 'loss'
            ? `${t('pages.inventory.loss')} ${t('pages.inventory.quantity')}`
            : `${t('pages.inventory.dispose')} ${t('pages.inventory.quantity')}`
        }
      >
        <MyTextField
          sx={{ width: '100%' }}
          value={formatMoney(lossValue?.lossCount || 0)}
          onChange={(e) =>
            setLossValue({
              ...lossValue,
              lossCount: Number(e.target.value?.replace(/,/g, ''))
            })
          }
        />
      </FieldContainer>
      <FieldContainer
        label={
          action === 'loss'
            ? `${t('pages.inventory.loss')} ${t('common.price')}`
            : `${t('pages.inventory.dispose')} ${t('common.price')}`
        }
      >
        <Typography sx={{ width: '100%' }}>
          {formatMoney(lossValue.lossCount * state.pricePolicyType || 0)}
        </Typography>
      </FieldContainer>
    </Container>
  );
};

const FieldContainer = ({ children, label, required }) => {
  return (
    <Stack direction="row" alignItems="center" mr={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 60,
          mr: 2,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 140,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>{children}</Stack>
    </Stack>
  );
};

export default LossForm;
