import MyTextField from '@components/MyTextField';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import memberService from '@services/member';
import { setError } from '@store/reducers/appReducer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import { BorderBox, inputStyle, tableStyle } from '../enhance';

export default function RFIDFrom({
  item = '',
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const [valueRFID, setValueRFID] = useState({
    clientCode: '',
    clientName: '',
    idCardNo: '',
    memberCode: '',
    memberName: '',
    minibarCode: '',
    minibarNickname: ''
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const handleChangeInput = (e) => {
    const { name, value, field } = e.target;
    setValueRFID({
      ...valueRFID,
      [name]: value || '',
      [field]: e.target[field] || ''
    });
  };
  const handleSubmit = () => {
    const { clientCode, minibarCode, idCardNo } = valueRFID;
    const { id } = item || { memberCode: '', id: '' };
    if (clientCode && minibarCode && idCardNo) {
      onSubmit({ clientCode, minibarCode, idCardNo, rfidId: id });
    } else {
      dispatch(
        setError({
          title: t('dialog.title.pleaseEnterTheRequestedInformation')
        })
      );
    }
  };

  const getRFID = async () => {
    const { memberCode, id } = item || { memberCode: '', id: '' };
    const result = await memberService.getRFID(memberCode, id);
    if (!result?.error) {
      setValueRFID({ ...result.data, idCardNo: '' });
    }
  };

  useEffect(() => {
    if (item) {
      getRFID();
    }
  }, [item]);

  return (
    <Stack>
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.member.labels.clientName')} *
              </TableCell>
              <TableCell>
                <ClientAutoCompleteV2
                  label={null}
                  client={client}
                  setClient={(e) => {
                    setClient(e);
                    setValueRFID({
                      ...valueRFID,
                      clientCode: e?.clientCode,
                      clientName: e?.clientName
                    });
                  }}
                  disabled={item !== null}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.member.labels.minibarNickname')} *
              </TableCell>
              <TableCell>
                <MinibarAutoCompleteV2
                  disabled={!client}
                  label={null}
                  client={client}
                  minibar={minibar}
                  setMinibar={(e) => {
                    setMinibar(e);
                    setValueRFID({
                      ...valueRFID,
                      minibarCode: e?.minibarCode,
                      minibarNickname: e?.minibarNickname
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.member.labels.RFID')} *
              </TableCell>
              <TableCell>
                <MyTextField
                  size="small"
                  value={valueRFID.idCardNo || ''}
                  placeholder={t('pages.member.labels.RFID')}
                  name="idCardNo"
                  onChange={(e) => handleChangeInput(e)}
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
