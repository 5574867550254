import HelmetContainer from '@components/HelmetContainer';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MyTable from '@components/MyTable';
import { setError, setSuccess } from '@store/reducers/appReducer';
import AlertDialog from '@components/MyDialog/AlertDialog';
import MyTextField from '@components/MyTextField';
import MyDialog from '@components/MyDialog/new';
import categoryService from '@services/pre-order/category';
import error2Text from '@utils/error2Text';
import { actions, categoryColumns, storeColumns } from './enhance';
import OptionsManagementDialog from './components/OptionsManagementDialog';

const Category = () => {
  const [storeList, setStoreList] = useState([]);
  const [storeProductList, setStoreProductList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStoreType, setSelectedStoreType] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    action: null,
    onOk: null
  });
  const [alertDialog, setAlert] = useState({
    open: false,
    action: null,
    onOk: null,
    textFieldName: '',
    textFieldValue: ''
  });

  const textFieldRef = useRef();
  const [errMsg, setErrMsg] = useState({
    typeName: ''
  });

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getStorelist = async () => {
    const rs = await categoryService.getStoreList();
    if (rs?.data) setStoreList(rs?.data || []);
  };

  const getStorepProductList = async (storeTypeCode) => {
    const rs = await categoryService.getStoreProductList(storeTypeCode);
    setStoreProductList(rs?.data || []);
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({
                ...dialog,
                open: true,
                title: `${t('pages.pre-order.industry')} ${t('button.edit')}`,
                action: actions.UPDATE_STORE,
                textFieldName: 'typeName',
                okTitle: t('button.edit'),
                onOk: handleUpdateStore
              });
            }}
          >
            <EditIcon />
          </IconButton>
        );
      case 2:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item); // Make sure the selected item is set here
              setAlert({
                ...alertDialog,
                open: true,
                action: actions.DELETE_STORE
              });
            }}
          >
            <Delete />
          </IconButton>
        );
      case 3:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({
                ...dialog,
                open: true,
                title: `${t('pages.inventory.product')} ${t(
                  'pages.product.category'
                )} ${t('button.edit')}`,
                action: actions.UPDATE_CATEGORY,
                textFieldName: 'categoryName',
                okTitle: t('button.edit')
              });
            }}
          >
            <EditIcon />
          </IconButton>
        );
      case 4:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item); // Make sure the selected item is set here
              setAlert({
                ...alertDialog,
                open: true,
                action: actions.DELETE_CATEGORY
              });
            }}
          >
            <Delete />
          </IconButton>
        );

      default:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item); // Make sure the selected item is set here
              setAlert({
                ...alertDialog,
                open: true,
                onOk: () => handleDeleteStore(item) // Pass item to handleDeleteStore
              });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };

  //   Actions
  const onChangeStore = (e) => {
    const { name, value } = e.target;
    setSelectedItem((prev) => ({ ...prev, [name]: value }));

    // Debugging log

    let err = '';
    if (!value) err = t('common.required');
    else if (value.length > 10) err = t('validation.dataTooLong', { max: 10 });
    setErrMsg({ ...errMsg, [name]: err });
  };
  const handleAddStore = async () => {
    console.log('value', dialog.textFieldValue);
    const rs = await categoryService.createStoreType({
      typeName: textFieldRef?.current?.value
    });
    if (rs?.error) {
      if (rs.error.keyError === 'PREORDER_STORE_TYPE_ALREADY_EXIST')
        setErrMsg({
          ...errMsg,
          typeName: t(`error.${rs.error.keyError}.content`)
        });
      else dispatch(setError(error2Text(rs?.error)));
    } else {
      getStorelist();
      dispatch(setSuccess({ message: t('info.register.content') }));
      setDialog({
        open: false,
        action: null,
        type: null,
        okTitle: null,
        title: null,
        textFieldName: '',
        textFieldValue: ''
      });
    }
  };
  const handleUpdateStore = async () => {
    if (!selectedItem?.storeTypeCode) {
      return;
    }
    setLoading(true);
    const rs = await categoryService.updateStoreType(
      selectedItem.storeTypeCode,
      { typeName: textFieldRef.current.value }
    );
    if (rs?.error) {
      if (rs.error.keyError === 'PREORDER_PRODUCT_CATEGORY_NAME_ALREADY_EXIST')
        setErrMsg({
          ...errMsg,
          typeName: t(`error.${rs.error.keyError}.content`)
        });
      else dispatch(setError(error2Text(rs?.error)));
    } else {
      getStorelist();
      dispatch(setSuccess({ message: t('info.success.content') }));
      setDialog({
        ...dialog,
        open: false,
        action: null,
        type: null,
        okTitle: null,
        title: null
      });
      setSelectedItem(null);
    }

    setLoading(false);
  };

  const handleDeleteStore = async (item) => {
    if (!item) {
      return;
    }

    setLoading(true);

    const rs = await categoryService.deleteStoreType(item?.storeTypeCode);
    if (rs?.error) {
      dispatch(setError(error2Text(rs.error)));
    } else {
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getStorelist();
    }

    setAlert({ ...alertDialog, open: false, onOk: null });
    setSelectedItem(null);
    setLoading(false);
  };

  useEffect(() => {
    getStorelist();
  }, []);

  useEffect(() => {
    if (selectedStoreType)
      getStorepProductList(selectedStoreType?.storeTypeCode);
  }, [selectedStoreType]);

  const onSubmit = async (actionType) => {
    let rs;

    switch (actionType) {
      case actions.CREATE_STORE:
        rs = await categoryService.createStoreType({
          typeName: textFieldRef?.current?.value
        });
        if (rs?.error) {
          if (rs.error.keyError === 'PREORDER_STORE_TYPE_ALREADY_EXIST')
            setErrMsg({
              ...errMsg,
              typeName: t(`error.${rs.error.keyError}.content`)
            });
          else dispatch(setError(error2Text(rs?.error)));
        } else {
          getStorelist();
          dispatch(setSuccess({ message: t('info.register.content') }));
          setSelectedItem(null);
          setDialog({
            open: false,
            action: null,
            type: null,
            okTitle: null,
            title: null,
            textFieldName: '',
            textFieldValue: ''
          });
        }

        break;
      case actions.UPDATE_STORE:
        if (!selectedItem?.storeTypeCode) {
          return;
        }
        setLoading(true);
        rs = await categoryService.updateStoreType(selectedItem.storeTypeCode, {
          typeName: textFieldRef.current.value
        });
        if (rs?.error) {
          if (
            rs.error.keyError === 'PREORDER_PRODUCT_CATEGORY_NAME_ALREADY_EXIST'
          )
            setErrMsg({
              ...errMsg,
              typeName: t(`error.${rs.error.keyError}.content`)
            });
          else dispatch(setError(error2Text(rs?.error)));
        } else {
          getStorelist();
          dispatch(setSuccess({ message: t('info.success.content') }));
          setDialog({
            ...dialog,
            open: false,
            action: null,
            type: null,
            okTitle: null,
            title: null
          });
          setSelectedItem(null);
        }

        setLoading(false);
        break;
      case actions.DELETE_STORE:
        if (!selectedItem?.storeTypeCode) {
          return;
        }

        setLoading(true);

        rs = await categoryService.deleteStoreType(selectedItem?.storeTypeCode);
        if (rs?.error) {
          dispatch(setError(error2Text(rs.error)));
        } else {
          dispatch(setSuccess({ message: t('info.delete.content') }));
          getStorelist();
        }

        setAlert({ ...alertDialog, open: false, onOk: null });
        setSelectedItem(null);
        setLoading(false);
        break;

      case actions.CREATE_CATEGORY:
        if (!selectedStoreType?.storeTypeCode) {
          return;
        }
        rs = await categoryService.createStoreProductCategory(
          selectedStoreType.storeTypeCode,
          {
            productCategoryName: textFieldRef?.current?.value
          }
        );
        if (rs?.error) {
          if (rs.error.keyError === 'PREORDER_PRODUCT_CATEGORY_ALREADY_EXIST')
            setErrMsg({
              ...errMsg,
              productCategoryName: t(`error.${rs.error.keyError}.content`)
            });
          else dispatch(setError(error2Text(rs?.error)));
        } else {
          getStorepProductList(selectedStoreType?.storeTypeCode);
          dispatch(setSuccess({ message: t('info.register.content') }));
          setSelectedItem(null);
          setDialog({
            open: false,
            action: null,
            type: null,
            okTitle: null,
            title: null,
            textFieldName: '',
            textFieldValue: ''
          });
        }

        break;
      case actions.UPDATE_CATEGORY:
        if (
          !selectedStoreType?.storeTypeCode &&
          !selectedItem?.productCategoryCode
        ) {
          return;
        }
        setLoading(true);
        rs = await categoryService.updateStoreProductCategory(
          selectedStoreType?.storeTypeCode,
          selectedItem?.productCategoryCode,
          {
            productCategoryName: textFieldRef.current.value
          }
        );
        if (rs?.error) {
          if (
            rs.error.keyError === 'PREORDER_PRODUCT_CATEGORY_NAME_ALREADY_EXIST'
          )
            setErrMsg({
              ...errMsg,
              categoryName: t(`error.${rs.error.keyError}.content`)
            });
          else dispatch(setError(error2Text(rs?.error)));
        } else {
          getStorepProductList(selectedStoreType.storeTypeCode);
          dispatch(setSuccess({ message: t('info.success.content') }));
          setDialog({
            ...dialog,
            open: false,
            action: null,
            type: null,
            okTitle: null,
            title: null
          });
          setSelectedItem(null);
        }

        setLoading(false);
        break;
      case actions.DELETE_CATEGORY:
        if (
          !selectedStoreType?.storeTypeCode &&
          !selectedItem?.productCategoryCode
        ) {
          return;
        }

        setLoading(true);

        rs = await categoryService.deleteStoreProductCategory(
          selectedStoreType.storeTypeCode,
          selectedItem?.productCategoryCode
        );
        if (rs?.error) {
          dispatch(setError(error2Text(rs.error)));
        } else {
          dispatch(setSuccess({ message: t('info.delete.content') }));
          getStorepProductList(selectedStoreType.storeTypeCode);
        }

        setAlert({ ...alertDialog, open: false, onOk: null, action: null });
        setSelectedItem(null);
        setLoading(false);
        break;

      default:
        break;
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.category')}
      content="Pre-order Category page"
      sx={{ width: '100%' }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%', // Ensure the parent has a defined height
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'stretch' // Add this to make sure children stretch to full height
        }}
      >
        <Stack
          sx={{
            width: '50%',
            height: '100%',
            border: '1px solid #D3D3D3',
            borderRadius: 1,
            mr: 2,
            backgroundColor: '#F8F8F8',
            overflow: 'hidden',
            p: 2,
            gap: 2
          }}
        >
          {/* buttons */}
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setDialog({
                    ...dialog,
                    open: true,
                    textFieldName: 'typeName',
                    textFieldValue: '', // Reset the text field value
                    action: actions.CREATE_STORE,
                    title: `${t('pages.pre-order.industry')} ${t(
                      'button.register'
                    )}`,
                    onOk: handleAddStore
                  });
                }}
              >
                {`${t('pages.pre-order.industry')} ${t('button.register')}`}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setDialog({ ...dialog, open: true, action: actions.OPTIONS });
                }}
              >
                {t('pages.pre-order.buttons.options')}
              </Button>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 'fontWeightSemiMedium',
                  fontSize: 15,
                  color: 'text.primary'
                }}
                component="span"
              >
                {t('common.number-of-results')}{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
                >
                  {storeList?.length || 0}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}
          >
            <MyTable
              hover
              minusHeight={100}
              getActionButtons={getActionButtons}
              onRowClick={(item) => setSelectedStoreType(item)}
              columns={storeColumns}
              data={storeList}
              sx={{ my: 0 }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: '50%',
            height: '100%', // Ensures this component takes the full height
            // Allow this Stack to grow within the parent
            border: '1px solid #D3D3D3',
            borderRadius: 1,
            mr: 2,
            backgroundColor: '#F8F8F8',
            overflow: 'hidden',
            p: 2,
            gap: 2
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'space-between'
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setDialog({
                  ...dialog,
                  open: true,
                  textFieldName: 'productCategoryName',
                  action: actions.CREATE_CATEGORY,
                  title: `${t('pages.inventory.product')} ${t(
                    'pages.product.category'
                  )} ${t('button.register')}`
                });
              }}
              disabled={!selectedStoreType}
            >
              {`${t('pages.inventory.product')} ${t(
                'pages.product.category'
              )} ${t('button.register')}`}
            </Button>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 'fontWeightSemiMedium',
                  fontSize: 15,
                  color: 'text.primary'
                }}
                component="span"
              >
                {t('common.number-of-results')}{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
                >
                  {storeProductList?.length || 0}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
            <MyTable
              hover
              minusHeight={100}
              getActionButtons={getActionButtons}
              columns={categoryColumns}
              data={storeProductList}
              sx={{ my: 0 }}
            />
          </Stack>
        </Stack>
        {selectedItem && (
          <AlertDialog
            open={alertDialog.open}
            setOpen={() => {
              setAlert({
                ...alertDialog,
                open: false,
                onOk: null
              });
              setSelectedItem(null);
            }}
            onOk={() => onSubmit(alertDialog.action)}
            okTitle={t('button.delete')}
            isAlert
            title={t('common.confirm-delete-new-title')}
            subTitle={t('common.confirm-delete-new-subtitle')}
            hasCancelButton
          />
        )}

        <MyDialog
          open={dialog.open && dialog?.action !== actions.OPTIONS}
          setOpen={() => {
            setDialog({
              ...dialog,
              open: false
            });

            setTimeout(() => {
              setSelectedItem(null);
              setErrMsg({
                ...errMsg,
                typeName: '',
                categoryName: '',
                productCategoryName: ''
              });
            }, 150);
          }}
          onOk={() => onSubmit(dialog?.action)}
          okTitle={dialog?.okTitle || t('button.register')}
          title={dialog?.title}
          hasCloseButton
          sx={{ minWidth: '450px' }}
          hasCancelButton
          disabled={
            !selectedItem?.[dialog.textFieldName] ||
            errMsg[dialog.textFieldName]
          }
        >
          <Stack>
            <MyTextField
              fullWidth
              ref={textFieldRef}
              placeholder={
                dialog?.textFieldName === 'productCategoryName'
                  ? '상품 카테고리명을 입력해 주세요.'
                  : '업종명을 입력해 주세요.'
              }
              errMg={errMsg?.[dialog.textFieldName]}
              value={selectedItem?.[dialog.textFieldName]}
              name={dialog.textFieldName}
              onChange={onChangeStore}
            />
          </Stack>
        </MyDialog>

        {dialog?.action === actions.OPTIONS && (
          <OptionsManagementDialog
            open={dialog.open && dialog?.action === actions.OPTIONS}
            setOpen={() =>
              setDialog({
                ...dialog,
                open: false,
                type: null,
                action: null,
                okTitle: null,
                title: null
              })
            }
            onSuccess={() => getStorelist()}
          />
        )}
      </Stack>
    </HelmetContainer>
  );
};

export default Category;
