import { convertMonth, formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const s = i18n.getFixedT(null, null, 'pages.statistics');

export const registeredColumns = [
  {
    name: 'membersRegistered',
    label: s('number-of-registered-members'),
    align: 'center',
    minWidth: 160,
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'num',
    label: s('registered-members-sex'),
    align: 'center',
    children: [
      {
        name: 'genderMale',
        label: s('male'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'genderFemale',
        label: s('female'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'genderNone',
        label: s('none'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'num',
    label: s('registered-members-age'),
    align: 'center',
    children: [
      {
        name: 'groupTeens',
        label: `10${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupTwenties',
        label: `20${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupThirties',
        label: `30${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupForties',
        label: `40${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupFifties',
        label: `50${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupSixties',
        label: `60${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupSeventies',
        label: `70${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupNone',
        label: s('none'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      }
    ]
  }
];

export const activedColumns = [
  {
    name: 'membersRegistered',
    label: s('number-of-actived-members'),
    align: 'center',
    minWidth: 160,
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'num',
    label: s('actived-member-sex'),
    align: 'center',
    children: [
      {
        name: 'genderMale',
        label: s('male'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'genderFemale',
        label: s('female'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'genderNone',
        label: s('none'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'num',
    label: s('actived-member-age'),
    align: 'center',
    children: [
      {
        name: 'groupTeens',
        label: `10${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupTwenties',
        label: `20${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupThirties',
        label: `30${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupForties',
        label: `40${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupFifties',
        label: `50${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupSixties',
        label: `60${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupSeventies',
        label: `70${s('s')}`,
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'groupNone',
        label: s('none'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      }
    ]
  }
];

export const growthColumns = [
  {
    name: 'monthName',
    label: s('month'),
    align: 'center',
    minWidth: 60,
    render: ({ monthName }) => convertMonth(monthName)
  },
  {
    name: 'membersCumulative',
    label: s('cumulative-number-of-members'),
    align: 'center',
    minWidth: 60,
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'membersNew',
    label: s('new-member'),
    align: 'center',
    minWidth: 60,
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'membersRepurchase',
    label: s('re-purchase-member'),
    align: 'center',
    minWidth: 60,
    formatter: (item) => formatMoney(item)
  }
];
