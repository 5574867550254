import { tableTypes } from '@constants/utils';

export const getPerformanceYearList = () => {
  const currentYear = new Date().getFullYear();
  const periodList = [{ label: currentYear, value: currentYear }];
  let previousYear = currentYear - 1;
  while (previousYear >= 2019) {
    periodList.push({ label: previousYear, value: previousYear });
    previousYear -= 1;
  }
  return periodList;
};

export const goalPerformanceCols = (t) => [
  {
    name: 'title',
    label: t('pages.performance-management.goal.labels.performance'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'jan',
    label: t('pages.performance-management.goal.labels.jan'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'feb',
    label: t('pages.performance-management.goal.labels.feb'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'mar',
    label: t('pages.performance-management.goal.labels.mar'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'apr',
    label: t('pages.performance-management.goal.labels.apr'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'may',
    label: t('pages.performance-management.goal.labels.may'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'jun',
    label: t('pages.performance-management.goal.labels.jun'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'jul',
    label: t('pages.performance-management.goal.labels.jul'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'aug',
    label: t('pages.performance-management.goal.labels.aug'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'sep',
    label: t('pages.performance-management.goal.labels.sep'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'oct',
    label: t('pages.performance-management.goal.labels.oct'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'nov',
    label: t('pages.performance-management.goal.labels.nov'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'dcb',
    label: t('pages.performance-management.goal.labels.dcb'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => {
      return new Intl.NumberFormat('en-CA').format(value);
    }
  },
  {
    name: 'note',
    label: t('pages.performance-management.goal.labels.note'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const getPerformanceItemRow = (t, type, performance) => {
  return {
    id: performance?.performanceCode,
    performanceCode: performance?.performanceCode,
    performanceType: type,
    clientChannel: performance?.clientChannel,
    clientName: performance?.clientName,
    division: performance?.division,
    title: t(`pages.performance-management.goal.labels.${type}`),
    jan: performance?.jan,
    feb: performance?.feb,
    mar: performance?.mar,
    apr: performance?.apr,
    may: performance?.may,
    jun: performance?.jun,
    jul: performance?.jul,
    aug: performance?.aug,
    sep: performance?.sep,
    oct: performance?.oct,
    nov: performance?.nov,
    dcb: performance?.dcb
  };
};
