/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formatter } from '@utils/formatter';
import {
  Box,
  Button,
  IconButton,
  Chip,
  Typography,
  FormControl,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { dateFormat } from '@constants/utils';
import { sub } from 'date-fns';
import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import { downloadFileFromBinary } from '@utils/downloadFile';
import { equipmentServices, userServices } from '@services/index';
import DateRangePicker from '@components/DateRangePicker';
import { objToParams, paramsToObj, ignoreEmpty } from '@utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import KioskDialog from '@pages/Manager/kiosk/components/KioskDialog';
import UploadFile from './Components/UploadFile';
import { historyColumns } from '../Equipment/enhance';

const ByMinibar = () => {
  const [state, setState] = React.useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const navigate = useNavigate();
  const { formatTime } = Formatter;
  const location = useLocation();

  const { t } = useTranslation();
  const [dialog, setDialog] = useState({
    openConfirmdialog: false,
    uploadDialog: { open: false, action: '' },
    openShippingStatusDialog: false
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [params, setParams] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    page: 0
  });
  const getData = async () => {
    const rs = await equipmentServices.getBuyEqmHistory();
    if (!rs.error) {
      setState({ ...state, items: rs?.data?.items || [] });
    }
  };
  const getActionButtons = (item, actionType) => {
    let status;
    switch (actionType) {
      case 1:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setDialog({ ...dialog, openConfirmdialog: true });
              setSelectedItem(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      case 2:
        status =
          item?.invoiceFileName && item?.invoiceFileLink
            ? 'download'
            : 'upload';
        return (
          <Button
            sx={{ whiteSpace: 'nowrap', height: 30 }}
            variant={status === 'download' ? 'contained' : 'outlined'}
            onClick={(e) => {
              e.stopPropagation();
              // eslint-disable-next-line no-unused-expressions
              status === 'download'
                ? handleDownloadFile(
                    item?.invoiceFileName,
                    item?.invoiceFileLink
                  )
                : setDialog({
                    ...dialog,
                    uploadDialog: {
                      ...dialog.uploadDialog,
                      open: true,
                      action: 'invoiceFile',
                      item
                    }
                  });
            }}
          >
            {status === 'download' ? t('button.download') : t('button.upload')}
          </Button>
        );
      case 3:
        status =
          item?.quotationFileName && item?.quotationFileLink
            ? 'download'
            : 'upload';
        return (
          <Button
            sx={{ whiteSpace: 'nowrap', height: 30 }}
            variant={status === 'download' ? 'contained' : 'outlined'}
            onClick={(e) => {
              e.stopPropagation();
              // eslint-disable-next-line no-unused-expressions
              status === 'download'
                ? handleDownloadFile(
                    item?.quotationFileName,
                    item?.quotationFileLink
                  )
                : setDialog({
                    ...dialog,
                    uploadDialog: {
                      ...dialog.uploadDialog,
                      open: true,
                      action: 'quotationFile',
                      item
                    }
                  });
            }}
          >
            {item?.quotationFileName && item?.quotationFileLink
              ? t('button.download')
              : t('button.upload')}
          </Button>
        );
      default:
        status = item?.purchaseStatus.toLowerCase() === 'shipping';
        // eslint-disable-next-line no-case-declarations
        const variant = status ? 'outlined' : 'filled';
        return (
          <Chip
            label={
              status
                ? t('pages.equipment.shipping')
                : t('pages.equipment.arrived')
            }
            variant={variant}
            sx={{
              color: status ? 'primary.main' : '#11111',
              borderColor: status ? 'primary.main' : 'none'
            }}
            clickable={status}
            onClick={() => {
              setSelectedItem(item);
              // eslint-disable-next-line no-unused-expressions
              status &&
                setDialog({ ...dialog, openShippingStatusDialog: true });
            }}
          />
        );
    }
  };
  const handleDeleteEquipment = async () => {
    if (!selectedItem) return;
    const rs = await equipmentServices.deleteBuyEqmHistoryItem(
      selectedItem.purchaseCode
    );
    if (!rs.error) {
      getData();
      setDialog({ ...dialog, openConfirmdialog: false });
    }
  };

  const onAction = async (action, value) => {
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParams({
        ...params,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }

    if (action === 'changePage') {
      setParams({ ...params, page: value });
      const query = objToParams(
        ignoreEmpty({
          ...params,
          page: value + 1
        })
      );
      navigate(query);
    }
    if (action === 'success') {
      getData();
    }
  };

  const handleDownloadFile = (fileName, fileLink) => {
    userServices.getFile({ fileName, fileLink }).then(({ data }) => {
      if (data) downloadFileFromBinary(data, fileName);
    });
  };
  const handleUpdateShippingStatus = async () => {
    if (!selectedItem?.purchaseCode) return;
    const rs = await equipmentServices.updateEqmPurchaseShipping(
      selectedItem.purchaseCode
    );
    if (!rs.error) {
      getData();
      setDialog({ ...dialog, openShippingStatusDialog: false });
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      startDate: formatTime(query?.startDate || firstDayOfMonth),
      endDate: formatTime(query?.endDate || formatTime(new Date(), dateFormat)),
      page: query?.page - 1 || 0
    };
    setParams(dataParams);
    getData(dataParams);
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams({
      startDate: params.startDate || '',
      endDate: params?.endDate || '',
      page: 1
    });
    getData({
      startDate: params.startDate || '',
      endDate: params?.endDate || '',
      page: query?.page - 1 || 0
    });
    navigate(query);
  };

  return (
    <HelmetContainer
      title={t('pageTitle.equipment-by-minibar')}
      content="Equipment by minibar page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1,
                width: 60
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(params.startDate),
                  endDate: new Date(params.endDate),
                  key: 'selection'
                }}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Box sx={{ my: '30px' }}>
        {/* table */}
        <MyTable
          rerender
          columns={historyColumns}
          data={state?.items || []}
          getActionButtons={getActionButtons}
          minusHeight={150}
        />
        {/* table */}
      </Box>
      {/* dialogs */}
      {selectedItem && (
        <MyDialog
          open={dialog.openConfirmdialog}
          setOpen={() => setDialog({ ...dialog, openConfirmdialog: false })}
          onOk={handleDeleteEquipment}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.equipmentName || ''
          })}
          hasCancelButton
        />
      )}
      {dialog.uploadDialog?.item && (
        <UploadFile
          open={dialog.uploadDialog?.open}
          setOpen={() =>
            setDialog({
              ...dialog,
              uploadDialog: { ...dialog.uploadDialog, open: false }
            })
          }
          dialogItem={dialog.uploadDialog}
          onAction={getData}
        />
      )}
      {dialog.openShippingStatusDialog && (
        <KioskDialog
          open={dialog.openShippingStatusDialog}
          setOpen={() =>
            setDialog({ ...dialog, openShippingStatusDialog: false })
          }
          title={t('pages.equipment.orderStatus')}
          onOk={handleUpdateShippingStatus}
          hasCancelButton
          onAction={getData}
        >
          {' '}
          <Typography>
            {t('pages.equipment.modal.shippingUpdateContent')}
          </Typography>{' '}
        </KioskDialog>
      )}
    </HelmetContainer>
  );
};

export default ByMinibar;
