import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';
import GroupTableCustomize from '@components/MyTable/GroupTableCustomize';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import adServices from '@services/ad';
import { advertisementColumns } from './enhance';
import AdvertisementForm from './components/AdvertisementForm';
import ViewAdvertiseArea from './components/ViewAdvertiseArea';

export default function AdComponent() {
  const dispatch = useDispatch();
  const [advertisement, setAdvertisement] = useState(undefined);
  const [adView, setAdView] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [items, setItems] = useState([]);
  const [param, setParam] = useState({ page: 0 });
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    totalRow: 0
  });

  const handleOnChangePage = (page) => {
    setParam({ ...param, page });
  };

  const getAdvertisements = async () => {
    const result = await adServices.getListAdVersion(param);
    if (!result?.error) {
      const { page, items, totalPage, totalRow } = result.data;
      setPagination({
        ...pagination,
        page,
        totalRow,
        count: totalPage || 0
      });
      setItems(items || []);
    }
  };

  const { t } = useTranslation();

  const editAd = (adCode) => {
    setAdvertisement(adCode);
  };

  const viewVideo = (item) => {
    setAdView(item);
  };

  const handleCloseView = () => {
    setAdView(undefined);
  };

  const handleCloseForm = () => {
    setAdvertisement(undefined);
    setIsSubmitting(false);
  };

  const handleSubmitForm = async (e) => {
    setIsSubmitting(true);
    const result =
      advertisement != null
        ? await adServices.updateAd(advertisement, e)
        : await adServices.createAd(e);
    if (!result?.error) {
      getAdvertisements();
      setAdvertisement(undefined);
    } else {
      dispatch(setError(error2Text(result.error)));
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    getAdvertisements();
  }, [param?.page]);

  return (
    <HelmetContainer title={t('pageTitle.advertisements')}>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => setAdvertisement(null)}>
          {t('common.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <GroupTableCustomize
        hover
        rerender
        columns={advertisementColumns(editAd, viewVideo)}
        data={items}
        pagination={pagination}
        minusHeight={150}
        onChangePage={(e, page) => handleOnChangePage(page)}
      />
      <MyDialog
        open={advertisement !== undefined}
        setOpen={!isSubmitting ? handleCloseForm : () => {}}
        title={advertisement == null ? t('common.register') : t('common.edit')}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <AdvertisementForm
          item={advertisement}
          onClose={handleCloseForm}
          onSubmit={(data) =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: advertisement == null ? 'save' : `modify`,
                action: () => handleSubmitForm(data)
              })
            )
          }
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: advertisement == null ? 'Register' : `Edit`
          }}
        />
      </MyDialog>
      <MyDialog
        open={adView !== undefined}
        setOpen={handleCloseView}
        title=""
        hideDialogButton
        hasCloseButton
        sx={{
          width: '100vw',
          height: '100vh',
          '.MuiDialogContent-root': {
            overflow: 'hidden',
            display: 'flex'
          }
        }}
      >
        <ViewAdvertiseArea item={adView} />
      </MyDialog>
    </HelmetContainer>
  );
}
