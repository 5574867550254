import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const vendorService = {
  async getVendorList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/vendor')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getVendorDetail(vendorCode) {
    try {
      const res = await new RestService()
        .setPath(`/vendor/${vendorCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createVendor(data) {
    try {
      const res = await new RestService()
        .setPath('/vendor')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editVendor(vendorCode, data) {
    try {
      const res = await new RestService()
        .setPath(`vendor/${vendorCode}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportVendor() {
    try {
      const res = await new RestService()
        .setPath('vendor/export')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadTaxFile(vendorCode, data) {
    try {
      const res = await new RestService()
        .setPath(`vendor/${vendorCode}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteTaxBill(vendorCode, taxBillId) {
    try {
      const res = await new RestService()
        .setPath(`vendor/${vendorCode}/tax-bill/${taxBillId}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default vendorService;
