import i18n from '@utils/i18n';
import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import { Chip } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages.consultationInquiry');
const g = i18n.getFixedT(null, null, '');
const { formatTime } = Formatter;

export const statusList = [
  { value: 'all', label: t('total') },
  { value: 'INPROGRESS', label: t('in-progress') },
  { value: 'REGISTERED', label: t('receipt') },
  { value: 'COMPLETED', label: t('complete') }
];

const statusObject = {
  INPROGRESS: t('in-progress'),
  REGISTERED: t('receipt'),
  COMPLETED: t('complete')
};

export const columns = [
  { name: '#', label: 'No', align: 'center', minWidth: 60 },
  {
    name: 'regDt',
    label: t('registration-date'),
    align: 'center',
    minWidth: 120,
    render: ({ regDt }) => {
      return formatTime(regDt);
    }
  },
  {
    name: 'service',
    label: t('service'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'companyName',
    label: t('companyName'),
    align: 'center',
    minWidth: 120
  },

  {
    name: 'province',
    label: t('province'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'district',
    label: t('district'),
    align: 'center',
    minWidth: 60
  },
  {
    name: 'budget',
    label: t('monthlyBudget'),
    align: 'center',
    minWidth: 80,
    render: (item) => formatMoney(item?.budget)
  },
  {
    name: 'teamSize',
    label: t('teamSize'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'confirmName',
    label: t('confirm-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'confirmDt',
    label: t('confirm-date'),
    align: 'center',
    minWidth: 100,
    render: ({ confirmDt }) => confirmDt && formatTime(confirmDt)
  },
  {
    name: 'contactYn',
    label: t('inquiry-status'),
    align: 'center',
    minWidth: 100,
    render: ({ inquiryStatus }) => {
      let variant = 'info';
      if (inquiryStatus === 'INPROGRESS') {
        variant = 'primary';
      } else if (inquiryStatus === 'REGISTERED') variant = 'error';
      return (
        <Chip
          color={variant}
          sx={{
            minWidth: '60px',
            '&.MuiChip-colorInfo': {
              backgroundColor: '#808388',
              color: 'white'
            }
          }}
          label={statusObject[inquiryStatus] || inquiryStatus}
        />
      );
    }
  }
];

export const conditionList = [
  { label: i18n.t('common.companyName'), value: 'companyName' }
];

export const inquirDetailSchema = [
  {
    name: 'regDt',
    type: 'date',
    label: t('registration-date'),
    disabled: true,
    grid: 6,
    formatter: (value) => (value ? formatTime(value) : ''),
    border: { borderLeft: 0 }
  },
  {
    name: 'service',
    type: 'text',
    label: t('service'),
    disabled: true,
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'companyName',
    type: 'text',
    label: t('companyName'),
    disabled: true,
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'picName',
    type: 'text',
    label: t('pic'),
    border: {
      borderLeft: '1px solid #D3D3D3'
    },
    grid: 6
  },
  {
    name: 'phone',
    type: 'text',
    label: t('phone'),
    grid: 6,
    border: { borderLeft: 0 }
  },

  {
    name: 'email',
    type: 'textarea',
    label: t('email'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'enqPurpose',
    type: 'text',
    label: t('enquiryPurpose'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'inflowPath',
    type: 'text',
    label: t('whereFindUs'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'province',
    type: 'text',
    label: t('province'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'district',
    type: 'text',
    label: t('district'),
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'budget',
    type: 'text',
    label: t('monthlyBudget'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'teamSize',
    type: 'text',
    label: t('teamSize'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'storageYn',
    type: 'text',
    label: t('storageYn'),
    grid: 6,
    formatter: (value) =>
      value === 'Y'
        ? g('pages.picking.possible')
        : g('pages.picking.impossible'),
    border: { borderLeft: 0 }
  },
  {
    name: 'inquiryStatus',
    type: 'text',
    label: g('common.status'),
    formatter: (value) => statusObject[value] || value,
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'confirmDt',
    type: 'date',
    label: t('reply-date'),
    grid: 6,
    formatter: (value) => (value ? formatTime(value) : ''),
    border: { borderLeft: 0 }
  },
  {
    name: 'confirmName',
    type: 'text',
    label: t('reply-admin'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
