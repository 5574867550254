import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import SearchBar from '@components/SearchBar';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MyTextField from '@components/MyTextField';
import MyTable from '@components/MyTable';
import warehouseService from '@services/warehouse';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import MyLoadingButton from '@components/MyLoadingButton';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import { scopes } from '@constants/utils';
import error2Text from '@utils/error2Text';
import useAuthorization from '@hooks/useAuthorization';
import { stockTakingColumns, stockTakingStatus } from './enhance';

const Stocktaking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [param, setParam] = useState({
    productName: null,
    stocktakingYn: '',
    page: 0
  });
  const [selectedItem, setSelectedItem] = useState(['test']);
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const { pagination } = state;

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getStockTaking({ ...param, page });
    navigate(query);
  };
  const handleListenEvent = async (action, e, item) => {
    const itemIdx = selectedItem?.findIndex((i) => i?.id === item?.id);
    if (action === 'adjust') {
      const { reason, surveyQty, id } = selectedItem[itemIdx];
      const dataSubmit = {
        reason: surveyQty ? reason : null,
        surveyQty: surveyQty || 0
      };
      const rs = await warehouseService.adjustStatus(id, dataSubmit);
      if (rs?.error) {
        dispatch(setError(error2Text(rs?.error)));
      }
      getStockTaking(param);
    } else {
      setSelectedItem((oldItem) => [
        ...oldItem.slice(0, itemIdx),
        {
          ...oldItem.slice(itemIdx, itemIdx + 1)?.[0],
          [action]: e.target.value ? e.target.value?.replace(/,/g, '') : 0
        },
        ...oldItem.slice(itemIdx + 1)
      ]);
    }
  };
  const configColumns = useMemo(
    () => stockTakingColumns(handleListenEvent),
    [selectedItem]
  );
  const handleUpdateStatus = async (item) => {
    const rs = await warehouseService.updateStatus(item?.id);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    getStockTaking(param);
  };

  const getStockTaking = async (param) => {
    const rs = await warehouseService.getStockTakingList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
      setSelectedItem(rs?.data?.items);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { stocktakingYn, productName, page } = query;
    setParam({
      ...param,
      stocktakingYn: stocktakingYn || '',
      productName,
      page: page - 1 || 0
    });
    getStockTaking({
      ...param,
      stocktakingYn: stocktakingYn || '',
      productName,
      page: page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getStockTaking({ ...param, page: 0 });
    navigate(query);
  };

  const getActionButtons = (item) => (
    <MyLoadingButton
      onClick={() => {
        dispatch(
          onOpenConfirm({
            open: true,
            type: 'modify',
            action: () => handleUpdateStatus(item)
          })
        );
      }}
      variant="outlined"
      sx={{ height: 35, fontSize: 13 }}
      disabled={!isAuthorized || item?.stocktakingYn === 'Y'}
    >
      OK
    </MyLoadingButton>
  );
  return (
    <HelmetContainer title={t('pageTitle.stockTaking')} content="Stocking Page">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {stockTakingStatus.map(({ value, label }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({
                            ...param,
                            stocktakingYn: e.target.value
                          })
                        }
                        checked={param?.stocktakingYn === value}
                        size="small"
                      />
                    }
                    label={label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <MyTextField
          label={t('pages.product.product-name')}
          placeholder={t('button.search')}
          onChange={(e) => setParam({ ...param, productName: e.target.value })}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={() => handleSearch()}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack flexDirection="row" justifyContent="space-between" mt={2}>
        <Stack flexDirection="row" gap={2}>
          <Button variant="outlined" onClick={() => getStockTaking(param)}>
            {t('common.reload')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/inventory-management/warehouse')}
          >
            {t('pages.inventory.list')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        columns={configColumns}
        data={selectedItem}
        getActionButtons={getActionButtons}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default Stocktaking;
