import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const statisticsByProductService = {
  async getStatisticsByProduct(data) {
    try {
      const res = await new RestService()
        .setPath('statistics-by-product')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportStatisticsByProduct(data) {
    try {
      const res = await new RestService()
        .setPath('statistics-by-product/export')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPaymentHistory(id, data) {
    try {
      const res = await new RestService()
        .setPath(`statistics-by-product/${id}`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default statisticsByProductService;
