/* eslint-disable prettier/prettier */
export const filters = {
    searchItems(array, name, keyword) {
        if (!keyword) return array;
        if (!name) return array;
        const filteredItems = array.filter((item) => item[name].toLowerCase().includes(keyword.toLowerCase()));
        return filteredItems;
    },
    filterWithBoolean(array, name) {
      if (!name) return array;
      const filteredItems = array.filter((item) => item?.[name] === true);
      return filteredItems;
  },
    filterItemsByDateRange(items, dateProperty, startDate, endDate) {
        if (!startDate || !endDate) {
          console.error('Please provide both start and end dates.');
          return items;
        }
        if(!dateProperty) return items;
        const filteredItems = items.filter(item => {
          // Assuming each item has a 'date' property representing the date of the item
          const itemDate = new Date(item[dateProperty]);
      
          return itemDate >= startDate && itemDate <= endDate;
        });
      
        return filteredItems;
      }
}