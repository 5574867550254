import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import CustomImage from '@components/CustomImage';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import MyTextField from '@components/MyTextField';
import { formatMoney } from '@utils/helpers';

const RQRow = ({ item, handleRemoveItem, isEnglish, priceType, onChange }) => {
  const handleRowChange = (action, e) => {
    if (action === 'quantity') {
      const recommendedQuantity = e.target?.value
        ? parseInt(e.target.value.replace(/,/g, ''), 10)
        : 0;
      onChange({ ...item, recommendedQuantity });
    }
    if (action === 'location') {
      const minibarLocationNote = e.target?.value;
      onChange({ ...item, minibarLocationNote });
    }
  };
  return (
    <TableRow>
      <TableCell>{item.productCode}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleRemoveItem(item)}>
          <IndeterminateCheckBoxOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <CustomImage fileName={item.imgUrl} fileLink={item.imgUrl} />
      </TableCell>
      <TableCell>{item.productName}</TableCell>
      <TableCell>
        {isEnglish ? item?.category1NameEng : item?.category1Name}
      </TableCell>
      <TableCell>
        {isEnglish ? item?.category2NameEng : item?.category2Name}
      </TableCell>
      <TableCell>
        {isEnglish ? item?.category3NameEng : item?.category3Name}
      </TableCell>
      <TableCell>
        {isEnglish ? item?.categoryNameEngSpecial : item?.categoryNameSpecial}
      </TableCell>
      <TableCell>
        <MyTextField
          onBlur={() => {
            if (!item?.recommendedQuantity) {
              onChange({ ...item, recommendedQuantity: 1 });
            }
          }}
          value={formatMoney(item?.recommendedQuantity)}
          onChange={(e) => handleRowChange('quantity', e)}
          typeValue="onlyNumber"
        />
      </TableCell>
      <TableCell>{formatMoney(item?.cost)}</TableCell>
      <TableCell>{item?.box}</TableCell>
      <TableCell>{priceType || '--'}</TableCell>
      <TableCell>{item?.unitPrice ? formatMoney(item.unitPrice) : 0}</TableCell>
      <TableCell>
        {item?.stockQuantity ? formatMoney(item.stockQuantity) : 0}
      </TableCell>
      <TableCell>
        <MyTextField
          value={item?.minibarLocationNote || ''}
          onChange={(e) => handleRowChange('location', e)}
        />
      </TableCell>
      <TableCell>
        {item?.properStock ? formatMoney(item.properStock) : 0}
      </TableCell>
      <TableCell>
        {item?.recommendedQuantity && item?.unitPrice
          ? formatMoney(item.recommendedQuantity * item.unitPrice)
          : 0}
      </TableCell>
    </TableRow>
  );
};

export default RQRow;
