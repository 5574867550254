import { DrawerIcon8, DrawerIcon9 } from '@svg/drawer';

import i18n from './i18n';

const warehouseSidebarConfig = [
  {
    title: i18n.t('menusidebar.inventory-management.label'),
    path: '/inventory-management',
    Icon: DrawerIcon8,
    children: [
      {
        title: i18n.t('menusidebar.inventory-management.vendor'),
        path: '/inventory-management/vendor'
      },
      {
        title: i18n.t('menusidebar.inventory-management.location'),
        path: '/inventory-management/location'
      },
      {
        title: i18n.t('menusidebar.inventory-management.warehouse'),
        path: '/inventory-management/warehouse'
      },
      {
        title: i18n.t('menusidebar.inventory-management.picking'),
        path: '/inventory-management/picking'
      },
      {
        title: i18n.t('menusidebar.inventory-management.release'),
        path: '/inventory-management/release'
      },
      {
        title: i18n.t('menusidebar.inventory-management.display'),
        path: '/inventory-management/display'
      },
      {
        title: i18n.t('menusidebar.inventory-management.restock'),
        path: '/inventory-management/restock'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.display-management.label'),
    path: '/display-management',
    Icon: DrawerIcon9,
    children: [
      {
        title: i18n.t('menusidebar.display-management.PIC'),
        path: '/display-management/PIC'
      },
      {
        title: i18n.t('menusidebar.display-management.schedule'),
        path: '/display-management/schedule'
      }
    ]
  }
];

export default warehouseSidebarConfig;
