import env from '@constants/env';
import fallbackImg from '@svg/fallback-img.svg';
import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyDialog from '@components/MyDialog';
import productService from '@services/product';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import ProductDetailForm from './components/ProductDetailForm';
import NotificationForm from './components/NotificationForm';
import NutritionalInformation from './components/NutritionalInformation';

const ProductDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const productCode = pathname[pathname.length - 1];
  const [vendors, setVendors] = useState({
    vendors: []
  });
  const [selected, setSelected] = useState({
    open: false,
    title: '',
    action: ''
  });
  const [formValue, setFormValue] = useState({
    dataFile: {
      src: '',
      name: '',
      file: null
    },
    productName: '',
    category1Code: '',
    category2Code: '',
    category3Code: '',
    maker: '',
    capacity: '',
    box: '',
    capacityType: '',
    barcode: location?.state?.itemObj?.barcode || '',
    makeCost: 0,
    cost: 0,
    sellCount: 0,
    vendorCode: '',
    vendorName: ''
  });
  const [nutritional, setNutritional] = useState([]);
  const getProductDetail = async () => {
    const rsVendor = await productService.getVendors();
    if (!location?.pathname?.includes('register')) {
      const rs = await productService.getProductDetail(productCode);
      const getVendorName = rsVendor?.data?.items?.filter(
        (i) => i?.vendorCode === rs?.data?.vendorCode
      )[0]?.vendorName;
      setFormValue({
        ...formValue,
        ...rs?.data,
        categorySpecialCode: rs?.data?.categorySpecialCode?.split(','),
        dataFile: {
          name: t('pages.product.image'),
          src: rs?.data?.imgUrl
            ? `${env.s3BaseUrl}/${rs?.data?.imgUrl}`
            : fallbackImg,
          file: null
        },
        vendorName: getVendorName || ''
      });
    }
    setVendors({
      ...vendors,
      vendors: rsVendor?.data?.items?.map((item) => {
        return { value: item.vendorCode, label: item.vendorName };
      })
    });
  };
  const clearWearingId = () => {
    if (!location?.pathname?.includes('inventory-management/warehouse')) {
      localStorage.removeItem('wearingId');
    }
  };
  useEffect(() => {
    getProductDetail();
    clearWearingId();
  }, []);
  const onAction = async (action) => {
    if (action === 'cancel') navigate(-1);
    if (action === 'register' || action === 'modification') {
      let isValidate = false;
      const dataSubmit = new FormData();
      const wearingId = localStorage.getItem('wearingId');
      if (
        wearingId &&
        location?.pathname?.includes('inventory-management/warehouse')
      ) {
        dataSubmit.append('wearingId', wearingId);
      }
      const keys = Object.keys(formValue);
      const values = Object.values(formValue);
      keys.forEach((item, index) => {
        if (item === 'cost' || item === 'makeCost' || item === 'sellCount') {
          dataSubmit.append([item], values[index] || 0);
        } else if (values[index] || values[index]?.length > 0) {
          if (item === 'dataFile') {
            if (values[index]?.file) {
              dataSubmit.append('file', values[index]?.file);
            }
          } else {
            dataSubmit.append([item], values[index]);
          }
        } else if (item === 'productName') {
          isValidate = true;
          dispatch(
            setError(
              error2Text({ keyError: 'INVALID_DATA', message: 'invalid data' })
            )
          );
        } else {
          dataSubmit.append([item], '');
        }
      });
      if (!isValidate) {
        let rs = null;
        if (action === 'register') {
          rs = await productService.createProduct(dataSubmit);
        } else {
          rs = await productService.editProduct(
            formValue?.productCode,
            dataSubmit
          );
        }
        if (!rs?.error) {
          localStorage.removeItem('wearingId');
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          dispatch(setError(error2Text(rs.error)));
        }
      }
    }
    if (action === 'notification' || action === 'nutritional') {
      setSelected({
        open: true,
        title:
          action === 'notification'
            ? t('pages.product.notify-info')
            : t('pages.product.nutritional-info-modify'),
        action
      });
    }
  };
  const onOkDialog = async (action) => {
    if (action === 'nutritional') {
      const dataSubmit = [];
      let isValidate = false;
      if (
        nutritional.length !== 1 ||
        nutritional[0].position !== 1 ||
        nutritional[0].type ||
        nutritional[0].value ||
        nutritional[0].per
      ) {
        nutritional.forEach((i) => {
          if (i.position && i.type && i.value) {
            if (i?.type?.length > 10 || i?.value?.length > 10) {
              isValidate = true;
              dispatch(setError(error2Text({ keyError: 'LENGTH-LESS-10' })));
            }
            dataSubmit.push({
              position: i.position,
              type: i.type,
              value: i.value,
              per: i.per
            });
          } else {
            isValidate = true;
            dispatch(setError(error2Text({ keyError: 'INVALID_DATA' })));
          }
        });
      }

      if (!isValidate) {
        const rs = await productService.updateNutritional(productCode, {
          productInfo: dataSubmit
        });
        if (!rs?.error) {
          setSelected({
            ...selected,
            open: false
          });
        }
      }
    } else {
      const dataSubmit = {};
      const keys = Object.keys(formValue);
      const values = Object.values(formValue);
      keys.forEach((item, index) => {
        if (item?.includes('announce') && values[index]) {
          dataSubmit[item] = values[index];
        }
      });
      const rs = await productService.updateNotification(
        formValue?.productCode,
        dataSubmit
      );
      if (!rs?.error) {
        setTimeout(() => {
          setSelected({
            ...selected,
            open: false
          });
        }, 500);
      }
    }
  };
  const handleChangeNotification = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  };
  return (
    <HelmetContainer
      title={
        productCode === 'register'
          ? t('pages.inventory.register')
          : t('pageTitle.productDetail')
      }
      content="Product detail page"
    >
      <ProductDetailForm
        onAction={onAction}
        value={formValue}
        setValue={setFormValue}
        vendors={vendors}
      />
      <MyDialog
        sx={{
          width: selected.action === 'notification' ? '50%' : 'auto'
        }}
        open={selected.open}
        title={selected.title}
        onClose={() => {
          getProductDetail();
          clearWearingId();
        }}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() =>
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => onOkDialog(selected.action)
            })
          )
        }
      >
        {selected.action === 'nutritional' ? (
          <NutritionalInformation
            value={nutritional}
            setValue={setNutritional}
          />
        ) : (
          <NotificationForm
            data={formValue}
            onChange={handleChangeNotification}
          />
        )}
      </MyDialog>
    </HelmetContainer>
  );
};

export default ProductDetail;
