import { tableTypes } from '@constants/utils';
import { Button, Stack } from '@mui/material';
import { Formatter } from '@utils/formatter';
import {
  dayOfWeek,
  formatMoney,
  secondsToTime,
  space2Date
} from '@utils/helpers';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const d = i18n.getFixedT(null, null, 'pages.display');
const g = i18n.getFixedT(null, null, '');

const { formatTime } = Formatter;

export const columns = [
  { name: 'managerName', label: t('pic'), minWidth: 160, align: 'center' },
  {
    name: 'shippingDate',
    label: t('date'),
    minWidth: 160,
    align: 'center',
    render: ({ shippingDate }) => formatTime(shippingDate, 'YYYY-MM-DD')
  },
  {
    name: 'shippingCnt',
    label: d('number-of-shipping'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'dayOfWeek',
    label: g('pages.display.dayOfWeek'),
    minWidth: 160,
    align: 'center',
    render: ({ shippingDate }) => dayOfWeek(shippingDate)
  },
  {
    name: 'status',
    label: d('status'),
    minWidth: 160,
    align: 'center',
    render: ({ status }) => d(`${status}`)
  },
  {
    name: 'numOfComplete',
    label: d('number-of-completion'),
    minWidth: 160,
    align: 'center'
  }
];

export const timeRadios = [
  { value: 'today', label: t('today') },
  { value: 'currentWeek', label: t('current-week') },
  { value: 'currentMonth', label: t('current-month') }
];

export const scheduleDetailColumns = (onClick) => [
  {
    name: 'schedule',
    label: d('schedule'),
    minWidth: 100,
    align: 'center',
    render: (item, index) => `${d('schedule')} ${index + 1}`
  },
  { name: 'managerName', label: t('pic'), minWidth: 160, align: 'center' },
  {
    name: 'clientChannel',
    label: t('channel'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'picName',
    label: `${p('client')} / ${p('minibar')}`,
    minWidth: 100,
    align: 'center',
    render: (item) => (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          {`${item?.clientName || ''} ${
            item?.minibarNickname ? `/ ${item?.minibarNickname}` : ''
          }`}
        </div>
        <Button
          sx={{ fontSize: 12, height: 35, ml: 1.5 }}
          onClick={() => onClick(item)}
        >
          {d('shipping-info')}
        </Button>
      </Stack>
    )
  },
  {
    name: 'managerArea',
    label: d('area'),
    minWidth: 100,
    align: 'center'
  },
  { name: 'managerZone', label: d('section'), minWidth: 160, align: 'center' },
  {
    name: 'previousMonthSales',
    label: d('previous-month-sales'),
    minWidth: 100,
    align: 'center',
    render: ({ previousMonthSales }) => formatMoney(previousMonthSales)
  },
  {
    name: 'managements',
    label: t('managements'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];
export const scheduleDailyColumns = () => [
  {
    name: 'schedule',
    label: d('schedule'),
    minWidth: 100,
    align: 'center',
    render: (item, index) => `${d('schedule')} ${index + 1}`
  },
  {
    name: 'shippingState',
    label: d('status'),
    minWidth: 160,
    align: 'center',
    render: ({ shippingState }) => (shippingState ? d(`${shippingState}`) : '')
  },
  { name: 'managerName', label: d('pic'), minWidth: 160, align: 'center' },
  {
    name: 'clientChannel',
    label: d('channel'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'picName',
    label: `${p('client')} / ${p('minibar')}`,
    minWidth: 100,
    align: 'center',
    render: (item) =>
      `${item?.clientName || ''} ${
        item?.minibarNickname ? `/ ${item?.minibarNickname}` : ''
      }`
  },
  {
    name: 'display-start',
    label: d('display-start'),
    minWidth: 160,
    align: 'center',
    render: ({ beforeDisplayingDt }) =>
      beforeDisplayingDt ? formatTime(beforeDisplayingDt, 'HH:mm') : null
  },
  {
    name: 'display-end',
    label: d('display-end'),
    minWidth: 160,
    align: 'center',
    render: ({ afterDisplayingCompleteDt }) =>
      afterDisplayingCompleteDt
        ? formatTime(afterDisplayingCompleteDt, 'HH:mm')
        : null
  },
  {
    name: 'picName',
    label: d('display-required-time'),
    minWidth: 100,
    align: 'center',
    render: ({ beforeDisplayingDt, afterDisplayingCompleteDt }) =>
      afterDisplayingCompleteDt &&
      beforeDisplayingDt &&
      secondsToTime(
        space2Date(beforeDisplayingDt, afterDisplayingCompleteDt) / 1000
      )
  },
  { name: 'note', label: d('note'), minWidth: 160, align: 'center' }
];
export const scheduleMonthlyColumns = [
  {
    name: 'picName',
    label: d('term'),
    minWidth: 160,
    align: 'center',
    render: (item, index) => {
      if (index === 1) return d('1m-ago');
      if (index === 2) return d('2m-ago');
      return t('current-month');
    }
  },
  {
    name: 'visits',
    label: d('client-number-of-visit'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'picName',
    label: `${d('display-required-time')}`,
    minWidth: 160,
    align: 'center',
    render: ({ displayTimeTotal }) =>
      displayTimeTotal && secondsToTime(displayTimeTotal)
  },
  {
    name: 'picName',
    label: `${d('average')} ${d('display-required-time')}`,
    minWidth: 160,
    align: 'center',
    render: ({ displayTimeAvg }) =>
      displayTimeAvg && secondsToTime(displayTimeAvg)
  },
  {
    name: 'picName',
    label: d('transit-time'),
    minWidth: 160,
    align: 'center',
    render: ({ shippingTimeTotal }) =>
      shippingTimeTotal && secondsToTime(shippingTimeTotal)
  },
  {
    name: 'picName',
    label: `${d('average')} ${d('transit-time')}`,
    minWidth: 160,
    align: 'center',
    render: ({ shippingTimeAvg }) =>
      shippingTimeAvg && secondsToTime(shippingTimeAvg)
  },
  {
    name: 'visitsB2C',
    label: 'B2C',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'visitsB2B',
    label: 'B2B',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'visitsB2B2C',
    label: 'B2B2C',
    minWidth: 60,
    align: 'center'
  }
];

export const shippingInfo = [
  { name: 'client-minibar', label: `${p('client')} / ${p('minibar')}` },
  { name: 'shippingDistrict', label: d('area') },
  { name: 'shippingCycle', label: d('shipping-cycle') },
  { name: 'shipping-address', label: d('shipping-address') },
  { name: 'shippingLocation', label: t('location') },
  { name: 'accessMethod', label: d('address-method') },
  { name: 'parkingType', label: d('parking-type') },
  { name: 'note', label: d('note') }
];
