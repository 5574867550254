/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import MyDialog from '@components/MyDialog';
import HelmetContainer from '@components/HelmetContainer';
import equipmentServices from '@services/equipment';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { filters } from '@utils/filters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tableTypes } from '@constants/utils';
import MenuItem from '@mui/material/MenuItem';
import { nanoid } from 'nanoid';
import GroupMultiHeaderTable from './Components/Table/EquipmentTable';
import RegisterEqmDialog from './Components/RegisterEqmDialog';
import { columns } from './enhance';

import UpdateEqmDialog from './Components/UpdateEqmDialog';
import EditEqmUsageCols from './Components/EditEqmUsageCols';
import EqmQuantityModifierdialog from './Components/EqmQuantityModifierDialog';
import BuyEqmDialog from './Components/BuyEqmDialog';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],

    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

const Equipment = () => {
  const [t] = useTranslation();

  const [data, setData] = useState();
  const [originalData, setOriginalData] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [param, setParam] = useState({ keyword: '', page: 0 });
  const searchRef = useRef();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selected, setSelected] = useState({
    open: false,
    title: '',
    action: '',
    item: {}
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [cols, setcols] = useState(columns);
  const [filteredCols, setFilteredCols] = useState(columns);
  const getData = async () => {
    const rs = await equipmentServices.getListEquipmentWithStatus();

    if (!rs.error) {
      const modifiedData = rs?.data.map((eqm) => {
        const remaining =
          parseInt(eqm.equipmentStock, 10) -
          eqm.stateOfEquipments.reduce(
            (sum, item) => sum + parseInt(item?.equipmentStateQuantity, 10),
            0
          );

        return { ...eqm, remainingQuantity: remaining };
      });
      setData(modifiedData || []);
      setOriginalData(modifiedData || []);
    }
  };

  const getEqmUsageCols = async () => {
    const rs = await equipmentServices.getEquipmentStatusList();

    const childColumns = rs?.data.map((item) => {
      return {
        ...item,
        id: nanoid(),
        label: item?.equipmentStatusName,
        minWidth: 60,
        align: 'center',
        type: tableTypes.DYNAMIC_COLUMN
      };
    });
    const formattedGroupCols = columns.map((item) => {
      if (item?.name === 'usedY') {
        return { ...item, children: childColumns, isOpen: true };
      }
      return { ...item, isOpen: true, id: nanoid() };
    });

    if (!rs.error) {
      setcols(formattedGroupCols || []);
      setFilteredCols(formattedGroupCols || []);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getDetailEquipment = async (item) => {
    const { data: equipmentDetail } = await equipmentServices.getEquipment(
      item?.equipmentCode
    );

    setSelectedItem(equipmentDetail?.data);
  };

  useEffect(() => {
    getData();
    getEqmUsageCols();
  }, []);

  const handleSearch = (value) => {
    const filteredItems = filters.searchItems(
      originalData,
      'equipmentName',
      value
    );
    setData(filteredItems);
  };
  const handleSwitch = (id) => {
    setcols((prevcols) => {
      const updatedCols = prevcols.map((item) => {
        if (item.id === id) {
          return { ...item, isOpen: !item?.isOpen };
        }
        return item;
      });

      const newCols = updatedCols.filter((item) => item?.isOpen === true);
      setFilteredCols(newCols);

      return updatedCols;
    });
  };

  const handleDeleteEquipment = async () => {
    setOpenConfirmDialog(false);
    if (!selectedItem) return;
    const { error } = await equipmentServices.deleteEquipment(
      selectedItem.equipmentCode
    );
    if (!error) {
      onAction('success');
    }
  };

  const handleUpdateEquipment = async (item) => {
    getDetailEquipment(item);
    setOpenUpdate(true);
  };
  const onAction = (action, val) => {
    switch (action) {
      case 'editUnit':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.equipment.editQuantity'),
          action: 'editUnit',
          item: val
        });

        break;
      case 'buyEquip':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.equipment.buyEquipment'),
          action: 'buyEqm',
          item: val
        });
        break;
      case 'success':
        getData();
        break;
      case 'colsUpdate':
        getData();
        getEqmUsageCols();
        break;
      default:
        break;
    }

    // if (action === 'upload-success') getMinibarOrderList(param);
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenConfirmDialog(true);
              setSelectedItem(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      case 2:
        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
              sx={{ whiteSpace: 'nowrap', height: 30, fontSize: 10 }}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateEquipment(item);
              }}
            >
              {t('pages.equipment.updateEqm')}
            </Button>
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 30, fontSize: 10 }}
              onClick={async (e) => {
                e.stopPropagation();
                onAction('editUnit', item);
              }}
            >
              {t('pages.equipment.editQuantity')}
            </Button>
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 30, fontSize: 10 }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('buyEquip', item);
              }}
            >
              {t('pages.equipment.buyEquipment')}
            </Button>
          </Stack>
        );
      default:
        break;
    }
    return actionType;
  };

  return (
    <HelmetContainer
      title={t('pageTitle.equipment')}
      content="Full equipment page"
    >
      <SearchBar>
        <MyTextField
          label={t('pages.equipment.equipmentName')}
          value={param?.keyword}
          onChange={(e) => {
            setParam({ ...param, keyword: e.target.value });
            handleSearch(e.target.value);
          }}
          placeholder={t('pages.equipment.coldStorage')}
          autoFocus
          ref={searchRef}
          inputProps={{
            onKeyDown: (e) => {
              if (e.isComposing || e.keyCode === 13) {
                handleSearch();
              }
            }
          }}
        />
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" spacing={2}>
          {' '}
          <Button variant="outlined" onClick={() => setOpen(true)}>
            {t('pages.equipment.registerEqm')}
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setSelected({ ...selected, open: true, action: 'modifyUsage' })
            }
          >
            {t('pages.equipment.modal.modifyUsageTitle')}
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Stack>
            <Button
              id="demo-customized-button"
              sx={{
                borderRadius: 1,
                p: 1,
                fontSize: 12,
                height: 30,
                mr: 3
              }}
              aria-controls={anchorEl ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? 'true' : undefined}
              variant="outlinedInherit"
              onClick={handleClick}
              disableElevation
              endIcon={
                anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {t('pages.equipment.filter')}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button'
              }}
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={handleClose}
            >
              {cols.map((column) => (
                <MenuItem disableTouchRipple disableRipple key={nanoid()}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={column?.isOpen}
                        onClick={() => handleSwitch(column?.id)}
                      />
                    }
                    label={column.label}
                  />
                </MenuItem>
              ))}
            </StyledMenu>
          </Stack>
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <GroupMultiHeaderTable
        hover
        rerender
        getActionButtons={getActionButtons}
        columns={filteredCols}
        data={data || []}
        minusHeight={235}
      />
      {/* Dialogs */}
      {selectedItem && (
        <MyDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onOk={handleDeleteEquipment}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.equipmentName || ''
          })}
          hasCancelButton
        />
      )}
      {selected.open && selected.action === 'modifyUsage' && (
        <EditEqmUsageCols
          open={selected.open}
          setOpen={() => setSelected({ ...selected, open: false, action: '' })}
          onAction={onAction}
          getEqmUsageCols={getEqmUsageCols}
        />
      )}
      {selected.open && selected.action === 'editUnit' && (
        <EqmQuantityModifierdialog
          open={selected.open}
          setOpen={() => setSelected({ ...selected, open: false, action: '' })}
          equipmentCode={selected.item?.equipmentCode}
          onAction={onAction}
        />
      )}
      {selected.open && selected.action === 'buyEqm' && (
        <BuyEqmDialog
          open={selected.open}
          setOpen={() => setSelected({ ...selected, open: false, action: '' })}
          data={selected.item}
          onAction={onAction}
        />
      )}
      <RegisterEqmDialog open={open} setOpen={setOpen} onAction={onAction} />
      {selectedItem && (
        <UpdateEqmDialog
          open={openUpdate}
          setOpen={setOpenUpdate}
          data={selectedItem}
          onAction={onAction}
        />
      )}
    </HelmetContainer>
  );
};

export default Equipment;
