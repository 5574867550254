import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import homepageService from '@services/homepage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setError,
  setOpenConfirm,
  setSuccess
} from '@store/reducers/appReducer';

// import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import NoticeDetailForm from './NoticeDetailForm';

const ProductDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const noticeCode = pathname[pathname.length - 1];
  const [formValue, setFormValue] = useState({
    createdBy: '',
    updatedBy: '',
    createdDate: '',
    updatedDate: '',
    title: '',
    content: ''
  });

  const getNoticeDetail = async () => {
    if (!location?.pathname?.includes('register')) {
      const rs = await homepageService.getNoticeDetail(noticeCode);
      setFormValue({
        ...formValue,
        ...rs?.data?.data
      });
    }
  };

  useEffect(() => {
    getNoticeDetail();
  }, []);
  const onAction = async (action) => {
    if (action === 'cancel') navigate(-1);
    if (action === 'register' || action === 'modification') {
      let isValidate = false;
      if (!formValue.title || !formValue.content) isValidate = true;

      const dataSubmit = {
        title: formValue?.title,
        content: formValue?.content
      };

      if (!isValidate) {
        let rs = null;
        if (action === 'register') {
          rs = await homepageService.registerNewNotice(dataSubmit);
        } else {
          rs = await homepageService.updateNotice(
            formValue?.noticeCode,
            dataSubmit
          );
        }
        if (!rs?.error) {
          dispatch(setSuccess({ message: t('info.success.content') }));

          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          dispatch(setError(error2Text(rs.error)));
        }
        dispatch(
          setOpenConfirm({
            okTitle: null,
            title: null,
            action: null,
            open: false,
            type: null,
            msg: null
          })
        );
      }
    }
  };

  return (
    <HelmetContainer
      title={
        noticeCode === 'register'
          ? `${t('pageTitle.notices')} ${t('button.register')}`
          : `${t('pageTitle.notices')} ${t('button.modify')}`
      }
      content="Notice detail page"
    >
      <NoticeDetailForm
        onAction={onAction}
        value={formValue}
        setValue={setFormValue}
      />
    </HelmetContainer>
  );
};

export default ProductDetail;
