/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Stack, Typography } from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import MyTable from '@components/MyTable';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';

import { equipmentServices } from '@services/index';

import { objToParams, paramsToObj } from '@utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { minibarColumns } from './Equipment/enhance';

const ByMinibar = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { t: pageT } = useTranslation(null, {
    keyPrefix: 'pages.equipment-by-minibar'
  });
  const { t } = useTranslation();

  const [client, setClient] = useState(null);

  const [minibar, setMinibar] = useState(null);

  const getData = async (param) => {
    const { data } = await equipmentServices.getListEquipmentByMinibar(param);
    setData(data.data);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setClient({
      ...client,
      clientCode: query?.clientCode || ''
    });
    setMinibar({
      ...minibar,
      minibarCode: query?.minibarCode || ''
    });
    getData({
      minibarCode: query?.minibarCode || '',
      clientCode: query?.clientCode || '',
      page: query?.page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams({
      minibarCode: minibar?.minibarCode || '',
      clientCode: client?.clientCode || '',
      page: 1
    });
    getData({
      minibarCode: minibar?.minibarCode || '',
      clientCode: client?.clientCode || '',
      page: query?.page - 1 || 0
    });
    navigate(query);
  };

  return (
    <HelmetContainer
      title={t('pageTitle.equipment-by-minibar')}
      content="Equipment by minibar page"
    >
      <SearchBar>
        <ClientAutoCompleteV2
          client={client}
          setClient={(e) => {
            setClient(e);
            setMinibar(null);
          }}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MinibarAutoCompleteV2
          client={client}
          disabled={!client}
          minibar={minibar}
          setMinibar={setMinibar}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          disabled={!client?.clientCode || !minibar?.minibarCode}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Box sx={{ my: '30px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            border: '1px solid #D3D3D3',
            borderRadius: '8px',
            overflow: 'hidden',
            flexWrap: 'wrap'
          }}
        >
          {[
            { label: pageT('channel'), value: data?.channelName },
            { label: pageT('client-name'), value: data?.clientName },
            { label: pageT('minibar-name'), value: data?.minibarName },
            {
              label: pageT('investment-amount'),
              value:
                data?.investmentMoney &&
                new Intl.NumberFormat('en-CA').format(data?.investmentMoney)
            }
          ].map(({ label, value }) => (
            <Stack
              key={label}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '50px',
                flex: 1,
                '&:not(:first-of-type)': {
                  borderLeft: '1px solid #EEEEEE'
                },
                borderBottom: '1px solid #EEEEEE'
              }}
            >
              <Stack
                sx={{
                  bgcolor: '#FCFCFC',
                  px: '12px',
                  height: '100%',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  sx={{
                    minWidth: '96px',
                    textAlign: 'center',
                    fontWeight: 'fontWeightBold',
                    whiteSpace: 'nowrap',
                    fontSize: 13,
                    color: '#6F869C'
                  }}
                >
                  {label}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ px: '12px', height: '100%', flex: 1 }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    color: 'text.primary'
                  }}
                  noWrap
                >
                  {value}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        {/* table */}
        <MyTable
          columns={minibarColumns}
          data={data?.listEquipment?.items || []}
          pagination={{
            count: data?.listEquipment?.totalPage,
            page: data?.listEquipment?.page
          }}
          onChangePage={(e, page) => {
            const query = objToParams({
              minibarCode: minibar?.minibarCode || '',
              clientCode: client?.clientCode || '',
              page: page + 1
            });
            getData({
              minibarCode: minibar?.minibarCode || '',
              clientCode: client?.clientCode || '',
              page: page || 0
            });
            navigate(query);
          }}
          emptyDescription={
            !client || !minibar || data === undefined
              ? 'Please select client/minibar'
              : 'No data'
          }
          minusHeight={280}
        />
        {/* table */}
      </Box>
    </HelmetContainer>
  );
};

export default ByMinibar;
