import React from 'react';
import {
  Button,
  Drawer,
  Hidden,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import useResponsive from '@hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import WorkerHigh from '@img/workers-high-white.png';
import { ReactComponent as ArrowLeftLight } from '@svg/arrow-left-light.svg';
import { useNavigate } from 'react-router-dom';
import sidebarConfig, { displayManageConfig } from '@utils/sidebarConfig';
import warehouseSidebarConfig from '@utils/warehouseSidebarConfig';
import jwtDecode from 'jwt-decode';
import { scopes } from '@constants/utils';
import SidebarMenu from './SidebarMenu';
import Footer from '../Footer';

const Sidebar = ({
  handleDrawerClose,
  handleDrawerOpen,
  open,

  handleLogout,
  drawerWidth,
  headerHeight
}) => {
  const isSm = useResponsive('down', 1000);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = localStorage.getItem('token');
  const { scope: roleArray } = jwtDecode(token);

  const getSidebarItems = (roles = []) => {
    if (roles.includes(scopes.ADMIN_WAREHOUSE_MASTER) && roles?.length === 2)
      return warehouseSidebarConfig;

    if (roles.includes(scopes.ADMIN_DISPLAY_MANAGER))
      return displayManageConfig;

    return sidebarConfig;
  };
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      variant={isSm ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      <Toolbar
        sx={{
          height: headerHeight,
          bgcolor: 'primary.darker'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <input
            type="image"
            src={WorkerHigh}
            alt="workers high logo"
            onClick={() => navigate('/dashboard')}
          />
          <IconButton
            aria-label="hide"
            component="label"
            onClick={handleDrawerClose}
          >
            <ArrowLeftLight width={20} height={20} />
          </IconButton>
        </Stack>
      </Toolbar>
      <Toolbar sx={{ height: 92, bgcolor: 'primary.lighter' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <div>
            <Typography variant="h5">{t('common.nickname')}</Typography>
            <Typography
              variant="p"
              sx={{ color: 'text.secondary', fontSize: 12 }}
            >
              {profile?.name}
            </Typography>
          </div>
          <Button
            onClick={handleLogout}
            sx={{
              color: 'primary.darker',
              ':hover': {
                background: 'none'
              }
            }}
            type="text"
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '20px',
                borderBottom: '2px solid'
              }}
            >
              {t('login.button.logOut')}
            </Typography>
          </Button>
        </Stack>
      </Toolbar>
      <SidebarMenu
        navConfig={getSidebarItems(roleArray)}
        isSm={isSm}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        isDrawerOpen={open}
        headerHeight={headerHeight}
      />
      <Hidden mdUp>
        {' '}
        <Footer drawerWidth={drawerWidth} isSm={isSm} />
      </Hidden>
    </Drawer>
  );
};

export default Sidebar;
