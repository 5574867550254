import { useEffect, useState } from 'react';
import { matchPath, useLocation, NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Stack
} from '@mui/material';

import { ReactComponent as PlusIcon } from '@svg/plus.svg';
import { ReactComponent as MinusIcon } from '@svg/minus.svg';
import { useDispatch } from 'react-redux';
import { setRerender } from '@store/reducers/appReducer';

const NavItem = ({ item, active, pathname, handleDrawerClose, isSm }) => {
  const { title, path, Icon, children } = item;
  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!pathname.startsWith(path)) setOpen(false);
  }, [pathname, path]);

  const hoverStyle = {
    ':hover': { color: 'primary.dark' }
  };

  const rootItemStyle = {
    px: '25px',
    py: '15px',
    fontSize: 15,
    ...(isActiveRoot
      ? {
          color: 'primary.darker',
          bgcolor: 'secondary.lighter',
          fontWeight: 'fontWeightBold'
        }
      : hoverStyle)
  };

  const itemStyle = {
    px: '25px',
    py: '15px',
    fontSize: 15,
    ...(open || pathname.startsWith(path)
      ? {
          color: 'primary.darker',
          fontWeight: 'fontWeightBold'
        }
      : hoverStyle)
  };

  const getChildItemStyle = (isActiveSub) => ({
    pl: '54px',
    color: 'text.primary',
    fontSize: 13,
    fontWeight: 'fontWeightSemiMedium',
    ...(isActiveSub
      ? { color: 'primary.darker', fontWeight: 'fontWeightBold' }
      : hoverStyle)
  });
  if (children) {
    return (
      <Box
        sx={{
          bgcolor:
            open || pathname.startsWith(path) ? 'secondary.lighter' : 'unset'
        }}
      >
        <ListItemButton onClick={() => setOpen(!open)} sx={itemStyle}>
          <ListItemIcon>
            <Icon fill="currentColor" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={title}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mr: 1.5
            }}
          />
          {open ? (
            <MinusIcon fill="currentColor" />
          ) : (
            <PlusIcon fill="currentColor" />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, Icon } = item;
              const isActiveSub = active(path);
              return (
                <ListItemButton
                  key={path}
                  component={NavLink}
                  to={path}
                  sx={getChildItemStyle(isActiveSub)}
                  onClick={() => {
                    dispatch(setRerender(true));
                    if (isActiveSub) {
                      setTimeout(() => {
                        window.location.reload();
                      }, 0);
                    }
                    if (isSm) {
                      handleDrawerClose();
                    }
                  }}
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={title} disableTypography />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </Box>
    );
  }

  return (
    <ListItemButton component={NavLink} to={path} sx={rootItemStyle}>
      <ListItemIcon>
        <Icon fill="currentColor" />
      </ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemButton>
  );
};

export default function SidebarMenu({
  navConfig,
  headerHeight,
  handleDrawerClose,
  isDrawerOpen,
  isSm
}) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <SimpleBar
      forceVisible="y"
      autoHide
      style={{ maxHeight: `calc(100% - ${headerHeight + 92}px)` }}
    >
      <List disablePadding sx={{ py: 2 }}>
        {navConfig?.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            active={match}
            handleDrawerClose={handleDrawerClose}
            isDrawerOpen={isDrawerOpen}
            isSm={isSm}
            pathname={pathname}
          />
        ))}
      </List>
      <Stack sx={{ visibility: 'hidden', minHeight: '150px' }}>
        Sidebar Footer
      </Stack>
    </SimpleBar>
  );
}
