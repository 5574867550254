import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import MyDialog from '@components/MyDialog';

const RejectReasonDialog = ({ open, setOpen, pageT, data }) => {
  const [t] = useTranslation();
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCloseButton
      title={pageT('reasonForReject')}
      okTitle={t('button.close')}
      sx={{ width: 400 }}
    >
      <Box
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: 1,
          p: 1,
          height: 200,
          overflowY: 'scroll'
        }}
      >
        <Typography>{data?.rejectReason}</Typography>
      </Box>
    </MyDialog>
  );
};

export default RejectReasonDialog;
