import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const d = i18n.getFixedT(null, null, '');

const columns = [
  { id: 'client', label: d('pages.dashboard.account'), minWidth: 113 },
  {
    id: 'monthlySalesPerCustomer',
    label: d('pages.dashboard.monthlySalesPerCustomer'),
    minWidth: 120
  },
  {
    id: 'compared2PreviousMonth',
    label: d('pages.dashboard.compared2PreviousMonth'),
    minWidth: 200,
    align: 'right',
    format: (value) =>
      value.toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  }
];

const PercentCell = ({ children, percent = 0 }) => {
  return (
    <TableCell sx={{ borderRight: '1px solid #F2F2F2', p: '6px 0' }}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: '30px'
        }}
        title={`${percent}%`}
      >
        <Box
          sx={{
            position: 'absolute',
            lineHeight: '30px',
            px: 2
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            maxWidth: `${percent}%`,
            bgcolor: '#E7F3FF',
            height: '100%'
          }}
        />
      </Box>
    </TableCell>
  );
};

// const

const AnalysisTable = ({ data }) => {
  const [t] = useTranslation(null, { keyPrefix: 'pages.dashboard' });
  // eslint-disable-next-line no-unsafe-optional-chaining
  const income = data?.reduce(
    (sum, item) => (sum > item?.totalSales ? sum : item?.totalSales),
    0
  );
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        textAlign: 'center',
        border: '1px solid #EEEEEE',
        borderRadius: '12px',
        height: '100%'
      }}
    >
      <Typography
        sx={{
          fontWeight: 'fontWeightBold',
          color: 'text.primary',
          fontSize: 16,
          my: '20px'
        }}
      >
        {t('monthlySalesByClient')}
      </Typography>
      {/* table */}
      <TableContainer sx={{ maxHeight: 650, overflowX: 'hidden' }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    textAlign: 'center',
                    color: '#6F869C',
                    fontSize: 12,
                    fontWeight: 'fontWeightBold',
                    borderTop: '1px solid #EEEEEE',
                    borderRight: '1px solid #EEEEEE',
                    height: '35px',
                    p: 0
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => {
              const color =
                (row?.indicator === 'rise' && '#0B1293') ||
                (row?.indicator === 'fall' && '#FF5530') ||
                'green';
              return (
                <TableRow tabIndex={-1} key={row.id} sx={{ height: '42px' }}>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #F2F2F2' }}
                  >
                    {row?.clientName}
                  </TableCell>

                  <PercentCell
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    percent={parseFloat(
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      (row?.totalSales / income) * 100
                    ).toFixed(2)}
                  >
                    {`${formatMoney(row?.totalSales)}${t('K')}${t('won')}`}
                  </PercentCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #F2F2F2' }}
                  >
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={1}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        {(row?.indicator === 'rise' && (
                          <ArrowDropUpIcon sx={{ fontSize: 30, color }} />
                        )) ||
                          (row?.indicator === 'fall' && (
                            <ArrowDropDownIcon sx={{ fontSize: 30, color }} />
                          )) || (
                            <RemoveIcon
                              sx={{
                                fontSize: 30,
                                width: 20,
                                ml: 0.5,
                                mr: 0.7,
                                color
                              }}
                            />
                          )}
                        <Typography
                          sx={{ fontSize: 13, color, whiteSpace: 'nowrap' }}
                        >
                          {`${formatMoney(Math.abs(row?.changeSales))}${t(
                            'K'
                          )}${t('won')}`}
                        </Typography>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {(row?.indicator === 'rise' && (
                          <ArrowDropUpIcon sx={{ fontSize: 25, color }} />
                        )) ||
                          (row?.indicator === 'fall' && (
                            <ArrowDropDownIcon sx={{ fontSize: 25, color }} />
                          )) || (
                            <RemoveIcon
                              sx={{
                                fontSize: 25,
                                width: 20,
                                ml: 0.5,
                                mr: 0.5,
                                color
                              }}
                            />
                          )}
                        <Typography sx={{ fontSize: 13, color }}>
                          {row?.changeSalesRate || '0%'}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AnalysisTable;
