import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.log');

export const columnUsageHistory = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: t('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'memberName',
    label: t('member'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'regDate',
    label: t('release-date'),
    minWidth: 160,
    align: 'center',
    formatter: (date) => {
      return new Date(date).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      });
    }
  }
];
