import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import MyTable from '@components/MyTable';
import { useLocation } from 'react-router-dom';
import pickingService from '@services/picking';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import Backbutton from '@components/BackButton';
import { nanoid } from 'nanoid';
import {
  detailColumns,
  pickingDetailHeader,
  pickingDetailStats
} from './enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const PickingDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formatTime } = Formatter;
  const configColumns = detailColumns;
  const location = useLocation();
  const pathnameArr = location?.pathname?.split('/');
  const pickingCode = pathnameArr[pathnameArr.length - 1];

  const [state, setState] = useState({
    info: {},
    items: []
  });
  const getPickingDetail = async () => {
    const rs = await pickingService.getPickingDetail(pickingCode);
    if (!rs?.error) {
      const { listPickingProduct, ...rest } = rs.data.data;
      setState({
        ...state,
        items: listPickingProduct,
        info: rest
      });
    }
  };

  useEffect(() => {
    getPickingDetail();
  }, []);

  const [loading, setLoading] = useState(false);
  const exportPicking = async () => {
    setLoading(true);
    const rs = await pickingService.exportPicking(pickingCode);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };
  return (
    <HelmetContainer
      title={t('pageTitle.picking-detail')}
      content="Picking Detail page"
    >
      <Backbutton
        title={t('pageTitle.picking-detail')}
        route="inventory-management/picking"
        sx={{ mt: -2 }}
      />
      <Container component={BorderBox}>
        <Table sx={{ borderBottom: '0.5px solid #D3D3D3 !important' }}>
          <TableRow>
            {pickingDetailHeader.map((row) => {
              return (
                <FieldContainer
                  key={nanoid()}
                  label={row?.label}
                  content={
                    row.name !== 'regDt'
                      ? state?.info?.[row?.name]
                      : formatTime(
                          state?.info?.pickedDt || state?.info?.regDt,
                          'YYYY-MM-DD hh:mm:ss'
                        )
                  }
                  // sx={{ borderLeft: index === 0 && 0 }}
                />
              );
            })}
            {/* <FieldContainer
              label={t('labels.group.channel')}
              content={state?.info?.clientChannel}
              sx={{ borderLeft: 0 }}
            />
            <FieldContainer
              label={t('pages.display.client')}
              content={state?.info?.clientName}
            />
            <FieldContainer
              label={t('pages.product.minibar')}
              content={state?.info?.minibarNickname}
            />
              <FieldContainer
              label={t('pages.picking.productNumber')}
              content={state?.info?.productNumber}
            />
            <FieldContainer
              label={t('pages.picking.totalOrderQuantity')}
              content={state?.info?.totalOrderQuantity}
            />
           
            <FieldContainer
              label={t('pages.picking.totalQuantity')}
              content={state?.info?.totalPickingQuantity}
            />

<FieldContainer
              label={t('pages.picking.totalShortageQuantity')}
              content={state?.info?.totalShortageQuantity}
            />
            <FieldContainer
              label={ }
              content={state?.info?.pickedAdminName}
            />
            <FieldContainer
              label={t('pages.inventory.picking-date')}
              content={formatTime(
                state?.info?.pickedDt || state?.info?.regDt,
                'YYYY-MM-DD hh:mm:ss'
              )}
            /> */}
          </TableRow>
        </Table>
        <Table sx={{ border: '0px !important' }}>
          <TableRow>
            {pickingDetailStats.map((row) => {
              return (
                <FieldContainer
                  key={nanoid()}
                  label={row?.label}
                  content={
                    row.name !== 'regDt'
                      ? state?.info?.[row?.name] || 0
                      : formatTime(
                          state?.info?.pickedDt || state?.info?.regDt,
                          'YYYY-MM-DD hh:mm:ss'
                        )
                  }
                  // sx={{ borderLeft: index === 0 && 0 }}
                />
              );
            })}
          </TableRow>
        </Table>
      </Container>
      <Button
        sx={{ mt: 2 }}
        onClick={() => exportPicking()}
        variant="outlined"
        startIcon={loading && <CircularProgress size="20px" />}
        disabled={loading}
      >
        {t('button.saveExcel')}
      </Button>
      <MyTable columns={configColumns} data={state?.items} minusHeight={235} />
    </HelmetContainer>
  );
};

const FieldContainer = ({ label, content, sx }) => {
  return (
    <TableCell sx={{ padding: 0, border: 0 }}>
      <Stack flexDirection="row" alignItems="center">
        <Stack
          color="#6F869C"
          bgcolor="#FCFCFC"
          p="12px 26px"
          borderLeft="1px solid #D3D3D3"
          sx={{
            width: {
              sm: 150,
              lg: 'auto',
              height: 50,
              flexDirection: 'row',
              alignItems: 'center'
            },
            ...sx
          }}
        >
          <Typography
            fontWeight={500}
            fontSize={12}
            textAlign="center"
            whiteSpace="nowrap"
          >
            {label}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: {
              sm: 180,
              lg: 'auto'
            }
          }}
        >
          <Typography fontSize={12} p="0 12px" minWidth="auto">
            {content}
          </Typography>
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default PickingDetailPage;
