import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import MyLoadingButton from '@components/MyLoadingButton';

import error2Text from '@utils/error2Text';
import { setError } from '@store/reducers/appReducer';
import welfarePointsServices from '@services/welfarePoints';

const ExcelRegisterDialog = ({ open, setOpen, setSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const handleRegisterByExcel = async () => {
    setSuccess(false);
    setLoading(true);
    const result = await welfarePointsServices.importMember({ file });
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setOpen(false);
      setSuccess(true);
    }
    setLoading(false);
  };

  const handleDownloadExampleExcelFile = async () => {
    const result = await welfarePointsServices.getMemberSampleFile();
    if (result.error) dispatch(setError(error2Text(result.error)));
  };

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.welfare-points.wp-member-register')}
      okTitle={t('button.add')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onOk={handleRegisterByExcel}
      sx={{ width: 460 }}
      disabled={!file}
      loading={loading}
      setLoading={setLoading}
    >
      <MyLoadingButton
        variant="text"
        onClick={handleDownloadExampleExcelFile}
        sx={{ textTransform: 'lowercase', height: 36, mb: 2.5 }}
      >
        {t('button.example-file-label')}
      </MyLoadingButton>
      <Box sx={{ px: 1.5, py: 1.5, border: '1px solid #ccc', borderRadius: 1 }}>
        <InputFile
          title={t('button.attachment')}
          onChange={handleFileUpload}
          file={file}
          disabled={loading}
          sx={{
            width: '100%',
            label: {
              maxWidth: 280
            }
          }}
          accept=".xlsx"
        />
      </Box>
    </MyDialog>
  );
};

export default ExcelRegisterDialog;
