import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { Box, Container, Paper } from '@mui/material';
import { styled } from '@mui/system';
import Tools from '../components/Tools';

const extensions = [
  StarterKit,
  Underline,
  Link.configure({
    openOnClick: false,
    autolink: false,
    linkOnPaste: true,
    HTMLAttributes: {
      target: ''
    }
  }),
  Image.configure({
    HTMLAttributes: {
      class: 'rich-product-image',
      style:
        'width: 100%; height: 100; max-width: 100%; max-height: 100px; object-fit: contain; margin-left: auto; margin-right: auto;'
    }
  }),
  TextAlign.configure({
    types: ['paragraph']
  }),
  Placeholder.configure({
    placeholder: 'Write something...'
  })
];

const StyledEditorContent = styled(EditorContent)(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  minHeight: '300px',
  '& .ProseMirror': {
    outline: 'none',
    flexGrow: 1,
    ...theme.typography.body1,
    '& h1': theme.typography.h1,
    '& h2': theme.typography.h2,
    '& h3': theme.typography.h3,
    '& h4': theme.typography.h4,
    '& h5': theme.typography.h5,
    '& h6': theme.typography.h6
  }
}));

const RichEditor = ({ value, setEditorValue, storeCode, productCode }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [showImageGallery, setShowImageGallery] = useState(false);

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      if (setEditorValue) setEditorValue(html);
    }
  });

  useEffect(() => {
    if (editor && value !== undefined) {
      // Only update if the value is different from the current content
      if (editor.getHTML() !== value) {
        editor.commands.setContent(value);
      }
    }
  }, [editor, value]);

  return (
    <Container
      sx={{
        width: '100%',
        mt: 2,
        px: 1,
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Paper
        elevation={5}
        sx={{ position: 'sticky', top: 0, zIndex: 50, mb: 2 }}
      >
        <Tools
          editor={editor}
          onImageSelection={() => setShowImageGallery(true)}
          storeCode={storeCode}
          productCode={productCode}
        />
      </Paper>
      <Box flexGrow={1} mb={2}>
        {editor && <StyledEditorContent editor={editor} />}
      </Box>
    </Container>
  );
};

export default RichEditor;
