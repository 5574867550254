// import MyDialog from '@components/MyDialog';

import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  //   TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import minibarOrderService from '@services/minibarOrderService';
import { Formatter } from '@utils/formatter';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import moment from 'moment';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  minWidth: '420px',
  overflowY: 'auto'
});

export default function ErrorHistoryForm({
  minibarCode,
  actionType,
  setOpen,
  data,
  setRegisterClose,
  type
}) {
  const [params, setParams] = useState({ registrationCode: '' });
  const [errorHistoryList, setErrorHistoryList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorName, setErrorName] = useState('');

  const getHistroyList = async () => {
    // if (!minibarCode) return;
    console.log('error history: ', data);

    const rs =
      type === 'shelf'
        ? await minibarOrderService.getMinibarExcelErrorHistoryList(minibarCode)
        : await minibarOrderService.getProductExcelErrorHistoryList(
            minibarCode
          );
    if (!rs.error) {
      console.log('rs', rs);
      const historyList =
        actionType === 'error'
          ? rs.data?.items
              ?.filter((item) => item.hasDownloaded === 'Y')
              .slice(0, 4)
          : rs.data?.items;
      setErrorHistoryList(sortDataByRegDt(historyList));
    } else {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  const sortDataByRegDt = (items) => {
    const sortedItems = items?.sort((a, b) => {
      const dateA = new Date(a.regDt);
      const dateB = new Date(b.regDt);
      return dateB - dateA;
    });
    return sortedItems;
  };
  const exportProductErrorData = async (registerationCode) => {
    // TODO export sample based on type
    // alert(registerationCode);
    const dataParams = { registrationCode: registerationCode };
    const rs2 = await minibarOrderService.exportProductErrorHistory(
      minibarCode,
      dataParams
    );
    if (!rs2.error) {
      if (actionType === 'error') {
        if (registerationCode === data.registrationCode) {
          setOpen(false);
          setRegisterClose(false);
        }
      }
      const undownloadedItems = errorHistoryList.filter(
        (item) => item.registrationCode !== registerationCode
      );
      setErrorHistoryList(undownloadedItems);
    } else {
      dispatch(setError(error2Text(rs2.error)));
    }
  };

  const exportShelfErrorData = async (modificationCode, action = 'N') => {
    // TODO export sample based on type

    const dataParams = { modificationCode };
    const rs2 = await minibarOrderService.exportShelfErrorHistory(
      minibarCode,
      dataParams
    );
    if (!rs2.error) {
      if (action === 'Y') {
        setOpen(false);
        setRegisterClose(false);
      }
      const undownloadedItems = errorHistoryList.filter(
        (item) => item.modificationCode !== modificationCode
      );
      setErrorHistoryList(undownloadedItems);
    } else {
      dispatch(setError(error2Text(rs2.error)));
    }
  };
  useEffect(() => {
    if (actionType === 'error') {
      setErrorName(Formatter.formatTime(moment().format()));
    }
    getHistroyList();
  }, []);
  return (
    <Stack sx={{ width: '100%', justifyContent: 'center' }}>
      {actionType === 'error' && (
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ marginBottom: 2 }}
          >
            <Typography>
              {' '}
              {t('pages.minibarOrder.dialog.content.errorContent', {
                total: data?.total,
                failed: data?.fail
              })}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            my={2}
            sx={{ p: 1, bgcolor: '#F8F8F8', borderBottom: '2px solid #EB5656' }}
          >
            <Typography>{`${errorName}.xls`}</Typography>
            <RemoveCircleOutlineOutlinedIcon
              sx={{ size: 'small', color: '#EB5656' }}
            />
          </Stack>
        </Stack>
      )}
      <Container component={BorderBox}>
        <Stack
          justifyContent="space-between"
          sx={{ width: '100%', padding: 1 }}
        >
          <TableContainer component={Box}>
            <Table>
              <TableBody
                sx={{
                  th: {
                    borderBottom: '2px solid #EEEEEE',
                    fontWeight: 'fontWeightBold',
                    fontSize: 11,
                    textAlign: 'center',
                    color: '#6F869C',
                    bgcolor: '#FCFCFC',
                    width: 200
                  }
                }}
              >
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('pages.consultationInquiry.registration-date')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t('button.download')}
                  </TableCell>
                </TableRow>
                {actionType === 'error' && (
                  <TableRow key={`key${errorName}`}>
                    <TableCell component="th" scope="row">
                      {`${errorName}.xls`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        disabled={!minibarCode}
                        variant="text"
                        sx={{
                          height: 30,
                          border: '1px solid #D3D3D3',
                          borderRadius: '8px',
                          fontSize: 11
                        }}
                        onClick={() => {
                          // setParams({
                          //   ...params,
                          //   registrationCode: item?.registrationCode
                          // });
                          // eslint-disable-next-line no-unused-expressions
                          type === 'shelf'
                            ? exportShelfErrorData(data?.modificationcode)
                            : exportProductErrorData(data?.registrationCode);
                        }}
                      >
                        {t('button.download')}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                {errorHistoryList?.map((item) => {
                  console.log(errorHistoryList);
                  return (
                    <TableRow key={`key${item?.registrationCode}`}>
                      <TableCell component="th" scope="row">
                        {`${Formatter.formatTime(item.regDt)}.xls`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          disabled={!minibarCode}
                          variant="text"
                          sx={{
                            height: 30,
                            border: '1px solid #D3D3D3',
                            borderRadius: '8px',
                            fontSize: 11
                          }}
                          onClick={() => {
                            setParams({
                              ...params,
                              registrationCode: item?.registrationCode
                            });
                            // eslint-disable-next-line no-unused-expressions
                            type === 'shelf'
                              ? exportShelfErrorData(item?.modificationCode)
                              : exportProductErrorData(item?.registrationCode);
                          }}
                        >
                          {t('button.download')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {actionType === 'history' && (
          <Button
            autoFocus
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={{
              height: '44px',
              width: '110px',
              mt: '30px'
            }}
          >
            {t('button.close')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
