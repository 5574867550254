/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMergeState from '@hooks/useMergeState';

import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControlLabel
} from '@mui/material';
import { styled } from '@mui/system';
import Calendar from '@components/Calendar';
import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';
import { MAX_INT } from '@constants/utils';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import equipmentServices from '@services/equipment';
import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import error2Text from '@utils/error2Text';
import { buyEquipmentRows as rows, tableStyle } from '../enhance';

const { formatTime } = Formatter;

const numberFields = ['purchaseQuantity'];
const fileFields = ['quotationFile', 'invoiceFile'];
const moneyCalculationFields = [
  'purchasePrice',
  'purchaseQuantity',
  'shippingFee'
];
const toggleFields = ['vendorName', 'contactName', 'contactNumber'];
const maxLengthOf = {
  equipmentName: 200,
  supplier: 40,
  manager: 40,
  managerContact: 40
};

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

const BuyEqmDialog = ({ open, setOpen, data }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useMergeState({
    equipmentName: '',
    purchaseQuantity: '',
    supplier: '',
    manager: '',
    managerContact: ''
  });

  const handleChange = (name, event) => {
    if (fileFields.includes(name)) {
      setState({ ...state, [name]: event.target.files[0] });
    } else if (moneyCalculationFields.includes(name)) {
      const { value } = event.target;
      setState({ ...state, [name]: value.replaceAll(',', '') });
    } else if (toggleFields.includes(name)) {
      setState({
        ...state,
        [name]: { ...state?.[name], value: event?.target?.value }
      });
    } else {
      const { value } = event.target;
      const checkValue = numberFields.includes(name)
        ? value.replaceAll(',', '')
        : value;
      setState({ ...state, [name]: checkValue });
    }

    const value = event?.target?.value;
    let msg = '';
    if (name === 'equipmentName' && !value) {
      msg = t('common.required');
    } else if (
      numberFields.includes(name) &&
      value?.replaceAll(',', '') > MAX_INT
    ) {
      msg = t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) });
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };
  const calculatePrices = () => {
    if (!state?.purchaseQuantity && !state?.purchasePrice) return;
    const { purchaseQuantity, purchasePrice, shippingFee, vatIncluded } = state;

    // Typecast purchasePrice to float
    const parsedPurchasePrice = parseInt(purchasePrice, 10) || 0;
    const parsedPurchaseQuantity = parseInt(purchaseQuantity, 10) || 0;
    const parsedShippingFee = parseFloat(shippingFee) || 0;

    // Perform calculations
    let totalPrice = parsedPurchaseQuantity * parsedPurchasePrice;

    // If totalPrice is a float, floor it and round up to 0 decimal places
    if (!Number.isInteger(totalPrice)) {
      totalPrice = Math.floor(totalPrice);
    }

    // Calculate tax and floor it while rounding up to 0 decimal places
    const taxAmount = vatIncluded
      ? Math.ceil(totalPrice / 11)
      : Math.ceil(totalPrice / 10);

    const sum = vatIncluded
      ? totalPrice + parsedShippingFee
      : totalPrice + parsedShippingFee + taxAmount;

    // Update the state with calculated values
    setState({
      ...state,
      sum: vatIncluded ? totalPrice - taxAmount : totalPrice,
      purchaseTax: taxAmount,
      purchaseTotalPrice: sum
    });
  };

  const validationOnSubmit = () => {
    let isPassed = true;
    const tempErr = { ...errMsg };
    if (!state?.equipmentName) {
      tempErr.equipmentName = t('common.required');
      isPassed = false;
    }
    Object.values(tempErr).map((err) => {
      if (err) {
        isPassed = false;
      }
      return 0;
    });
    setErrMsg(tempErr);
    return isPassed;
  };

  const handleUpdate = async () => {
    setLoading(true);

    const { error } = await equipmentServices.createBuyEqm({
      equipmentCode: state.equipmentCode,
      equipmentName: state.equipmentName,
      supplier: state.supplier,
      additionalInfo: state?.additionalInfo,
      contactName: state.contactName?.value,
      contactNumber: state.contactNumber?.value,
      purchaseSupplyPrice: state?.purchaseTotalPrice,
      purchaseTax: state.purchaseTax,
      shippingFee: state.shippingFee,
      purchaseQuantity: state.purchaseQuantity,
      purchasePrice: state.purchasePrice,
      purchaseTotalPrice: state.sum,
      vendorName: state.vendorName?.value,
      quotationFile: state.quotationFile,
      invoiceFile: state.invoiceFile,
      purchaseDate: formatTime(state.purchaseDate, 'YYYY-M-D')
    });
    if (error && error?.fieldErrors) dispatch(setError(error2Text(error)));
    else {
      dispatch(
        setSuccess({ message: t('pages.equipment.messages.success.edit') })
      );
      setOpen(false);
    }
    setLoading(false);
  };

  const resetState = () => {
    setState(
      rows.reduce((prev, { name }) => {
        return {
          ...prev,
          [name]: null
        };
      }, {})
    );
    setErrMsg({
      equipmentName: '',
      supplier: '',
      manager: '',
      managerContact: ''
    });
  };
  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  useEffect(() => {
    setState({
      equipmentCode: data.equipmentCode,
      equipmentName: data.equipmentName,
      supplier: data.supplier,
      additionalInfo: data?.additionalInfo,
      purchaseTax: data.taxAmount || 0,
      shippingFee: data.shippingFee || 0,
      purchaseQuantity: data?.purchaseQuantity || 0,
      purchasePrice: data.purchasePrice || 0,
      purchaseTotalPrice: data.purchaseTotalPrice || 0,
      contactName: { value: data?.contactName, editMode: false },
      contactNumber: { value: data?.contactPhone, editMode: false },
      vendorName: { value: data?.vendor, editMode: false },
      purchaseDate: data.purchaseDate || new Date(),
      vatIncluded: false
    });
  }, [data]);
  useEffect(() => {
    calculatePrices();
  }, [
    state?.purchaseQuantity,
    state?.purchasePrice,
    state?.vatIncluded,
    state?.shippingFee
  ]);
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.equipment.buyEquipment')}
      hasCloseButton
      hasCancelButton
      okTitle={t('button.save')}
      onOk={() => {
        if (validationOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleUpdate()
            })
          );
        }
      }}
      loading={loading}
      setLoading={setLoading}
      sx={{ maxWidth: 460 }}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            {rows.map(({ label, name, props }) => {
              let cellContent;

              switch (name) {
                // file fields
                case 'quotationFile':
                case 'invoiceFile':
                  cellContent = (
                    <InputFile
                      title={label}
                      file={state?.[name]}
                      urlData={{ url: state?.linkFile, name: state?.fileName }}
                      onChange={(event) => handleChange(name, event)}
                      disabled={loading}
                    />
                  );
                  break;
                //   disabled fields
                case 'equipmentName':
                case 'additionalInfo':
                  cellContent = (
                    <MyTextField
                      value={state?.[name] || ''}
                      onChange={(event) => handleChange(name, event)}
                      disabled
                      {...props}
                    />
                  );
                  break;
                case 'purchaseDate':
                  cellContent = (
                    <Stack
                      sx={{ paddingLeft: 2, paddingRight: 2, width: '100%' }}
                    >
                      <Calendar
                        date={state?.purchaseDate ? new Date() : null}
                        sx={{
                          inputStyle: {
                            width: '100%'
                          }
                        }}
                        setDate={(e) =>
                          handleChange(name, {
                            target: { value: formatTime(e, 'YYYY-M-D') }
                          })
                        }
                      />
                    </Stack>
                  );
                  break;
                // quantity number only
                case 'purchaseQuantity':
                  cellContent = (
                    <MyTextField
                      value={formatMoney(state?.[name])}
                      onChange={(event) => handleChange(name, event)}
                      errMg={errMsg[name]}
                      typeValue="onlyNumber"
                      disabled={loading}
                      {...props}
                    />
                  );
                  break;

                case 'purchasePrice':
                case 'purchaseTax':
                case 'shippingFee':
                case 'purchaseTotalPrice':
                  cellContent = (
                    <MyTextField
                      value={formatMoney(state?.[name])}
                      onChange={(event) => handleChange(name, event)}
                      typeValue="onlyNumber"
                      errMg={errMsg[name]}
                      disabled={['purchaseTax', 'purchaseTotalPrice'].includes(
                        name
                      )}
                      {...props}
                    />
                  );
                  break;
                case 'sum':
                  cellContent = (
                    <Stack direction="row" alignItems="center">
                      <MyTextField
                        value={formatMoney(state?.[name])}
                        onChange={(event) => handleChange(name, event)}
                        typeValue="onlyNumber"
                        errMg={errMsg[name]}
                        disabled
                        {...props}
                      />
                      <Stack>
                        <FormControlLabel
                          value="bottom"
                          control={
                            <Checkbox
                              checked={state?.vatIncluded}
                              onClick={() =>
                                setState({
                                  ...state,
                                  vatIncluded: !state?.vatIncluded
                                })
                              }
                            />
                          }
                          label={t('pages.equipment.vatIncluded')}
                          labelPlacement="bottom"
                          sx={{
                            '.MuiFormControlLabel-label': {
                              fontSize: 10,
                              whiteSpace: 'nowrap',
                              mt: '-10px',
                              color: '#979797'
                            }
                          }}
                        />
                      </Stack>
                    </Stack>
                  );
                  break;
                case 'contactNumber':
                case 'contactName':
                case 'vendorName':
                  cellContent = (
                    <Stack direction="row">
                      <MyTextField
                        value={state?.[name]?.value}
                        onChange={(event) => handleChange(name, event)}
                        errMg={errMsg[name]}
                        disabled={!state?.[name]?.editMode}
                        {...(!state?.[name]?.editMode && props)}
                      />
                      <IconButton
                        onClick={() =>
                          setState({
                            ...state,
                            [name]: {
                              ...state[name],
                              editMode: !state[name]?.editMode
                            }
                          })
                        }
                      >
                        {state?.[name].editMode ? (
                          <CheckCircleIcon />
                        ) : (
                          <ModeEditIcon />
                        )}
                      </IconButton>
                    </Stack>
                  );
                  break;
                default:
                  cellContent = (
                    <MyTextField
                      value={state?.[name]}
                      onChange={(event) => handleChange(name, event)}
                      errMg={errMsg[name]}
                      disabled={loading}
                      {...props}
                    />
                  );
              }

              return (
                <TableRow key={name}>
                  <TableCell component="th" scope="row">
                    {label}
                    {[
                      'purchaseDate',
                      'purchaseQuantity',
                      'purchasePrice'
                    ].includes(name) && (
                      <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                    )}
                  </TableCell>
                  <TableCell>{cellContent}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default BuyEqmDialog;
