import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import MyTable from '@components/MyTable';
import SearchBar from '@components/SearchBar';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import { sub } from 'date-fns';

import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import restockService from '@services/restock';
import DateRangePicker from '@components/DateRangePicker';
import FixDialog from './Components/FixDialog';
import {
  historyColumns,
  reasonSelectOptions,
  returningSelectOptions
} from './enhance';

const RestockHistory = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;

  const navigate = useNavigate();
  const location = useLocation();
  const [condition, setCondition] = useState('productName');
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    productName: null,
    barcode: null,
    clientName: null,
    stockingReason: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [dialog, setDialog] = useState({
    open: false,
    item: ''
  });
  const { items, pagination } = state;
  const configColumns = historyColumns;

  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };

  const getRestockHistory = async (param) => {
    const rs = await restockService.getRestockHistory(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      productName,
      barcode,
      clientName,
      stockingReason
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = `${formatTime(new Date(), 'YYYY-MM')}-01`;
    setParam({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      barcode: barcode || null,
      clientName: clientName || null,
      stockingReason: stockingReason || 'all'
    });
    getRestockHistory({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      barcode: barcode || null,
      clientName: clientName || null,
      stockingReason: stockingReason !== 'all' ? stockingReason : ''
    });
  }, [location?.search]);

  const handleChangeField = (e) => {
    setParam({ ...param, [e.target.name]: e.target.value });
  };

  const handleClickTable = (item) => {
    setDialog((prevDialog) => ({ ...prevDialog, open: true, item }));
  };
  const handleChangeReason = (e) => {
    setParam({ ...param, stockingReason: e.target.value });
  };
  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getRestockHistory({ ...param, page: 0 });
    navigate(query);
  };
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getRestockHistory({ ...param, page });
    navigate(query);
  };

  return (
    <HelmetContainer
      title={t('pageTitle.restockHistory')}
      content="Restock History Page"
    >
      <SearchBar>
        <MySelect
          label={t('pages.product.condition')}
          data={returningSelectOptions}
          value={condition}
          onChange={(e) => {
            setParam({
              ...param,
              productName: null,
              barcode: null,
              clientName: null
            });
            setCondition(e?.target?.value);
          }}
          sx={{
            mr: 1
          }}
        />
        <MyTextField
          value={param?.[condition]}
          name={condition}
          placeholder={t('button.search')}
          onChange={handleChangeField}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <FormControl>
          <FormControlLabel
            label={t('pages.product.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1.5
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>

        <MySelect
          label={t('pages.inventory.restock-reason')}
          value={param?.stockingReason}
          sx={{ mr: 1.5, width: 180 }}
          data={reasonSelectOptions}
          onChange={handleChangeReason}
        />

        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack flexDirection="row" justifyContent="flex-end" mt={2}>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination?.totalRow || 0}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        columns={configColumns}
        onRowClick={handleClickTable}
        data={items}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />

      <FixDialog
        open={dialog.open}
        setOpen={() => setDialog({ ...dialog, open: false, item: '' })}
        selectedItem={dialog?.item}
        type="history"
      />
    </HelmetContainer>
  );
};

export default RestockHistory;
