/* eslint-disable unused-imports/no-unused-vars */
// import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import {
  Box,
  // Button,
  Container,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import MySelect from '@components/MySelect';
import { propertyOptions } from '../enhance';
// import ProductSearch from './ProductSearch';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '60%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};
const LocationRegister = (props) => {
  const { onChange, value, setValue, errMg, setErrMg, removeProduct } = props;
  const { t } = useTranslation();
  const {
    locationName,
    locationCenterCode,
    locationZone,
    locationBay,
    locationBin,
    locationProperty
  } = value;
  const [selected, setSelected] = useState(null);
  const handleInputProduct = () => {
    setSelected({
      ...selected,
      open: true,
      title: t('pages.inventory.product-search')
    });
  };
  // const onSelectProduct = (item) => {
  //   setValue({
  //     ...value,
  //     ...item
  //   });
  //   setErrMg({ ...errMg, productName: '' });
  //   setSelected({ ...selected, open: false });
  // };
  return (
    <Container component={BorderBox}>
      <FieldContainer label={t('pages.inventory.location')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.floor')}
          value={locationName}
          name="locationName"
          disabled
          sx={{
            ...inputStyle,
            mb: 1,
            '.MuiInputBase-root': {
              bgcolor: '#EEE'
            }
          }}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.statusCode')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.placeholder.statusCode')}
          value={locationCenterCode}
          name="locationCenterCode"
          onChange={onChange}
          errMg={errMg?.locationCenterCode}
          sx={{ ...inputStyle, mb: 1 }}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.zone')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.placeholder.zone')}
          value={locationZone}
          name="locationZone"
          onChange={onChange}
          errMg={errMg?.locationZone}
          sx={{ ...inputStyle, mb: 1 }}
        />
      </FieldContainer>
      {/* <FieldContainer label={t('pages.inventory.path')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.placeholder.path')}
          value={path}
          name="path"
          onChange={onChange}
          errMg={errMg?.path}
          sx={{ ...inputStyle, mb: 1 }}
        />
      </FieldContainer> */}
      <FieldContainer label={t('pages.inventory.bay')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.placeholder.bay')}
          value={locationBay}
          name="locationBay"
          onChange={onChange}
          errMg={errMg?.locationBay}
          sx={inputStyle}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.bin')} required>
        <MyTextField
          size="small"
          placeholder={t('pages.inventory.placeholder.bin')}
          value={locationBin}
          name="locationBin"
          onChange={onChange}
          errMg={errMg?.locationBin}
          sx={{ ...inputStyle, mb: 1 }}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.property')} required>
        <MySelect
          sx={{ ...inputStyle, mb: 1 }}
          name="property"
          data={propertyOptions}
          value={locationProperty}
          onChange={(e) =>
            onChange({
              target: { name: 'locationProperty', value: e.target.value }
            })
          }
        />
      </FieldContainer>
      {/* <FieldContainer label={t('pages.product.category')}>
        <Stack
          sx={{ ...inputStyle, height: 30 }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ pb: 1 }}>
            {value?.category2Name
              ? `${value.category1Name}  >  `
              : value.category1Name}
            {value?.category3Name
              ? `${value.category2Name}  >  `
              : value.category2Name}
            {value?.category3Name}
          </Typography>
          <Button
            sx={{ fontSize: 13, height: '40px' }}
            variant="contained"
            onClick={handleInputProduct}
          >
            {t('button.productInput')}
          </Button>
        </Stack>
      </FieldContainer> */}
      {/* <FieldContainer label={t('pages.product.product-name')}>
        <Stack
          sx={{ ...inputStyle, height: 45, overflow: 'hidden', width: '100%' }}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack width="60%">
            <Typography
              sx={{
                pb: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {productName}
            </Typography>
          </Stack>
          <Stack width="40%" flexDirection="row" justifyContent="flex-end">
            {value?.productCode && (
              <Button
                sx={{ fontSize: 13, height: '40px' }}
                onClick={() => {
                  if (value?.initProduct) return removeProduct(value);
                  setValue({ ...value, productCode: null, productName: null });
                  return true;
                }}
              >
                {t('common.remove-product')}
              </Button>
            )}
          </Stack>
        </Stack>
      </FieldContainer> */}
      {/* <MyDialog
        sx={{
          width: {
            sm: '100%',
            md: '80%',
            lg: '50%'
          }
        }}
        open={selected?.open}
        title={selected?.title}
        hideDialogButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
      >
        <ProductSearch onSelectProduct={onSelectProduct} />
      </MyDialog> */}
    </Container>
  );
};

const FieldContainer = ({ label, required, children }) => {
  return (
    <Stack direction="row">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          pb: 1,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 160,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}> *</span>}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default LocationRegister;
