import { Formatter } from '@utils/formatter';
import { PICKING_STATUSES, tableTypes } from '@constants/utils';
import i18n from '@utils/i18n';
import { Typography } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const c = i18n.getFixedT(null, null, 'pages.client-order');
const p = i18n.getFixedT(null, null, 'pages.product');
const picking = i18n.getFixedT(null, null, 'pages.picking');
const curationT = i18n.getFixedT(null, null, 'pages.curation');
const g = i18n.getFixedT(null, null, '');

const { formatTime } = Formatter;

export const sizeSelect = (t) => [
  {
    value: 20,
    label: t('pages.picking.rowSelect', { row: 20 })
  },
  {
    value: 40,
    label: t('pages.picking.rowSelect', { row: 40 })
  },
  {
    value: 60,
    label: t('pages.picking.rowSelect', { row: 60 })
  },
  {
    value: 80,
    label: t('pages.picking.rowSelect', { row: 80 })
  },
  {
    value: 100,
    label: t('pages.picking.rowSelect', { row: 100 })
  }
];

export const dateTypeSelectOptions = [
  {
    value: 'regDt',
    label: picking('registerDate')
  },
  {
    value: 'shippingDt',
    label: g('labels.shippingDate')
  }
];
export const statusRadios = [
  { value: null, label: t('total') },
  {
    value: PICKING_STATUSES.REQUESTED,
    label: picking('pickingStatus.requested')
  },
  { value: PICKING_STATUSES.COMPLETED, label: t('completion') }
  // { value: PICKING_STATUSES.REJECTED, label: c('reject') }
];

export const columns = [
  {
    name: 'select',
    label: t('select'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  },
  {
    label: picking('registerDate'),
    minWidth: 160,
    align: 'center',
    render: ({ status, regDt, pickedDt }) =>
      formatTime(
        new Date(status !== PICKING_STATUSES.COMPLETED ? regDt : pickedDt)
      )
  },
  {
    name: 'clientChannel',
    label: t('channel'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'clientName',
    label: p('client'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: p('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productNumber',
    label: p('product-type'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'managerName',
    label: t('pic'),
    minWidth: 120,
    align: 'center'
  },
  {
    label: g('labels.shippingDate'),
    minWidth: 100,
    align: 'center',
    render: ({ shippingDt }) =>
      shippingDt ? formatTime(shippingDt, 'YYYY-MM-DD') : ''
  },
  {
    label: t('status'),
    minWidth: 100,
    align: 'center',
    render: ({ status }) =>
      (status === PICKING_STATUSES.ONGOING &&
        picking('pickingStatus.ongoing')) ||
      (status === PICKING_STATUSES.REJECTED && c('reject')) ||
      (status === PICKING_STATUSES.REQUESTED &&
        picking('pickingStatus.requested')) ||
      (status === PICKING_STATUSES.REGISTERED &&
        picking('pickingStatus.registered')) ||
      t('completion')
  }
];

export const totalPickingColumns = [
  {
    name: 'select',
    label: picking('nickname'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    name: 'totalSelectedPicking',
    label: picking('totalSelectedPickings'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'totalPickingQuantity',
    label: picking('totalQuantity'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'registerAdminName',
    label: picking('registerAdmin'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'regDt',
    label: picking('registerDate'),
    minWidth: 160,
    align: 'center',
    render: ({ regDt }) => Formatter.formatTime(regDt)
  },
  {
    label: t('status'),
    minWidth: 100,
    align: 'center',
    render: ({ status }) =>
      (status === PICKING_STATUSES.ONGOING &&
        picking('pickingStatus.ongoing')) ||
      (status === PICKING_STATUSES.REGISTERED &&
        picking('pickingStatus.registered')) ||
      (status === PICKING_STATUSES.REQUESTED &&
        picking('pickingStatus.confirmRequest')) ||
      status
  },
  {
    label: picking('delete'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];

export const totalPickingEditColumns = [
  {
    name: 'pickingCode',
    label: t('picking-code'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'regDt',
    label: picking('registerDate'),
    minWidth: 120,
    align: 'center',
    render: ({ regDt }) => Formatter.formatTime(regDt)
  },
  {
    name: 'clientChannel',
    label: t('channel'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'clientName',
    label: p('client'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: p('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productNumber',
    label: p('product-type'),
    minWidth: 80,
    align: 'center'
  },
  {
    label: picking('delete'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];

export const indiPickingColumns = [
  {
    name: 'clientChannel',
    label: t('channel'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'clientName',
    label: c('orderClient'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'totalPickingQuantity',
    label: picking('totalQuantity'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'registerAdminName',
    label: picking('createAdmin'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'regDt',
    label: picking('registerDate'),
    minWidth: 160,
    align: 'center',
    render: (item) => Formatter.formatTime(item?.regDt)
  },
  {
    label: t('status'),
    minWidth: 100,
    align: 'center',
    render: ({ status }) =>
      (status === PICKING_STATUSES.ONGOING &&
        picking('pickingStatus.ongoing')) ||
      (status === PICKING_STATUSES.REGISTERED &&
        picking('pickingStatus.registered')) ||
      (status === PICKING_STATUSES.REQUESTED &&
        picking('pickingStatus.confirmRequest')) ||
      status
  },
  {
    name: 'select',
    label: picking('delete'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];

export const detailColumns = [
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'category1Name',
    label: curationT('1st-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: curationT('2nd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: curationT('3rd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'orderQuantity',
    label: p('order-quantity'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'pickingQuantity',
    label: t('picking-quantity'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'insufficientQuantity',
    label: picking('lossQuantity'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 160,
    align: 'center'
  }
  // {
  //   label: t('status'),
  //   minWidth: 100,
  //   align: 'center',
  //   render: ({ status }) =>
  //     (status === PICKING_STATUSES.ONGOING && t('stand-by')) ||
  //     (status === PICKING_STATUSES.REJECTED && c('reject')) ||
  //     (status === PICKING_STATUSES.REGISTERED && picking('pickingStatus.registered')) ||
  //     (status === PICKING_STATUSES.REQUESTED && picking('pickingStatus.requested')) ||
  //     t('completion')
  // }
];

export const pickingTypeDetailColumns = [
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'category1Name',
    label: curationT('1st-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: curationT('2nd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: curationT('3rd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) =>
      expiryDate && formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'orderQuantity',
    label: p('order-quantity'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'pickingQuantity',
    label: t('picking-quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => {
      return <Typography>{item?.pickingQuantity}</Typography>;
    }
  },
  {
    name: 'shortageQuantity',
    label: picking('lossQuantity'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'pickedAdminName',
    label: t('pic'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'reason',
    label: picking('reason'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 6
  }
];

export const totalPickingDetailHeader = [
  {
    name: 'pickingNickname',
    label: picking('nickname')
  },
  {
    name: 'totalSelectedPicking',
    label: picking('totalSelectedPickings')
  },
  {
    name: 'registerAdminName',
    label: picking('createAdmin')
  },
  {
    name: 'regDt',
    label: picking('registerDate')
  }
];

export const IndiPickingDetailHeader = [
  {
    name: 'clientName',
    label: c('orderClient')
  },
  {
    name: 'registerAdminName',
    label: picking('createAdmin')
  },
  {
    name: 'regDt',
    label: picking('registerDate')
  }
];

export const pickingDetailHeader = [
  {
    name: 'clientName',
    label: c('orderClient')
  },
  {
    name: 'clientChannel',
    label: t('channel')
  },
  {
    name: 'minibarNickname',
    label: p('minibar')
  },
  {
    name: 'pickedAdminName',
    label: t('pic')
  },
  {
    name: 'regDt',
    label: picking('registerDate')
  }
];
export const pickingDetailStats = [
  {
    name: 'productNumber',
    label: picking('productNumber')
  },
  {
    name: 'totalOrderQuantity',
    label: picking('totalOrderQuantity')
  },
  {
    name: 'totalPickingQuantity',
    label: picking('totalQuantity')
  },
  {
    name: 'totalShortageQuantity',
    label: picking('totalShortageQuantity')
  }
];

export const indiPickingStats = [
  {
    name: 'productNumber',
    label: picking('productNumber')
  },
  {
    name: 'totalOrderQuantity',
    label: picking('totalOrderQuantity')
  },
  {
    name: 'totalPickingQuantity',
    label: picking('totalQuantity')
  },
  {
    name: 'totalInsufficientQuantity',
    label: picking('totalShortageQuantity')
  }
];
export const totalPickingStats = [
  {
    name: 'totalProductNumber',
    label: picking('productNumber')
  },
  {
    name: 'totalOrderQuantity',
    label: picking('totalOrderQuantity')
  },
  {
    name: 'totalPickingQuantity',
    label: picking('totalQuantity')
  },
  {
    name: 'totalInsufficientQuantity',
    label: picking('totalShortageQuantity')
  }
];
export const reasonUpdateColumns = [
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) =>
      expiryDate && formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    label: picking('lossQuantity'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },

  {
    label: picking('reason'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  },
  {
    label: p('client'),
    minWidth: 120,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 5
  },
  {
    label: p('minibar'),
    minWidth: 160,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },
  {
    name: 'registerAdminName',
    label: t('pic'),
    minWidth: 100,
    align: 'center'
  },
  {
    label: picking('delete'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 4
  }
];

export const reasonUpdateColumnsIndi = [
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) =>
      expiryDate && formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    label: picking('lossQuantity'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },

  {
    label: picking('reason'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  },
  {
    name: 'registerAdminName',
    label: t('pic'),
    minWidth: 100,
    align: 'center'
  },
  {
    label: picking('delete'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 4
  }
];

export const reasonSelectOptions = [
  {
    value: '재고 부족',
    label: '재고 부족'
  },
  {
    value: '진열 파손',
    label: '진열 파손'
  },
  {
    value: '피킹 중 파손',
    label: '피킹 중 파손'
  },
  {
    value: '유통기한 도래',
    label: '유통기한 도래'
  },
  { value: '유통기한 경과', label: '유통기한 경과' },
  { value: '기타', label: '기타' }
];
