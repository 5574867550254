import RestService from '@services/RestService';

const systemLogService = {
  async getSystemLog(data) {
    try {
      const res = await new RestService().setPath('system-log/list').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default systemLogService;
