/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Stack, styled, Typography } from '@mui/material';

import {
  AnalysisBlock,
  AnalysisChart,
  AnalysisTable
} from '@components/Dashboard';

import { useSelector } from 'react-redux';
import HelmetContainer from '@components/HelmetContainer';
import { useNavigate } from 'react-router-dom';
import dashboardService from '@services/dashboard';
import { format100, formatMoney } from '@utils/helpers';

const Item = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '20px',
  textAlign: 'center',
  border: '1px solid #EEEEEE',
  borderRadius: 12,
  height: '100%'
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const roles = useSelector((state) => state.auth.roles);

  const [g] = useTranslation(null, { keyPrefix: '' });
  const [t] = useTranslation(null, { keyPrefix: 'pages.dashboard' });
  const [salesStatistics, setSalesStatistics] = useState({
    purchases: [],
    members: []
  });
  const [monthlySalesByChannel, setMonthlySalesByChannel] = useState({
    B2C: [],
    B2B2C: [],
    B2B: []
  });
  const [salesByMonth, setSalesByMonth] = useState({
    targetRevenuePerMonth: 0,
    totalSalesByMonth: 107,
    achievementRateByMonth: 0.0
  });
  const [salesByYear, setSalesByYear] = useState({
    targetRevenue: 0,
    totalSalesByYear: 109,
    achievementRateByYear: 0.0
  });
  const [partners, setPartners] = useState({
    numberClientChange: null,
    numberClientOfCurrent: null,
    numberClientOfPrevious: null
  });
  const [salesByClient, setSalesByClient] = useState([]);
  const getDashboard = async () => {
    const { data } = await dashboardService.getDashboard();
    setSalesStatistics({
      purchases: data?.data?.salesStatistics?.purchasesPerMonth,
      members: data?.data?.salesStatistics?.activeMembersPerMonth
    });
    setMonthlySalesByChannel({
      B2C: data?.data?.monthlySalesByChannel?.b2c?.map((i) => {
        return {
          ...i,
          totalSales: parseFloat(i?.totalSales / 1000000)
        };
      }),
      B2B2C: data?.data?.monthlySalesByChannel?.b2b2c?.map((i) => {
        return {
          ...i,
          totalSales: parseFloat(i?.totalSales / 1000000)
        };
      }),
      B2B: data?.data?.monthlySalesByChannel?.b2b?.map((i) => {
        return {
          ...i,
          totalSales: parseFloat(i?.totalSales / 1000000)
        };
      })
    });
    setSalesByMonth({
      targetRevenuePerMonth: format100(data?.data?.targetRevenuePerMonth),
      totalSalesByMonth: formatMoney(data?.data?.totalSalesByMonth),
      achievementRateByMonth: data?.data?.achievementRateByMonth
    });
    setSalesByYear({
      targetRevenue: format100(data?.data?.targetRevenue),
      totalSalesByYear: formatMoney(data?.data?.totalSalesByYear),
      achievementRateByYear: data?.data?.achievementRateByYear
    });
    setPartners({
      numberClientChange: data?.data?.numberClientChange,
      numberClientOfCurrent: data?.data?.numberClientOfCurrent,
      numberClientOfPrevious: data?.data?.numberClientOfPrevious
    });
    setSalesByClient(
      data?.data?.monthlySalesByAggregatedClient?.map((i) => {
        return {
          ...i,
          changeSales: parseFloat(i?.changeSales / 1000),
          totalSales: parseFloat(i?.totalSales / 1000)
        };
      })
    );
  };
  useEffect(() => {
    if (roles?.includes('ADMIN_WAREHOUSE_MASTER') && roles?.length === 2) {
      navigate('/inventory-management/vendor');
    }
    getDashboard();
  }, []);
  return (
    <HelmetContainer
      title={g('menusidebar.dashboard.label')}
      content="Dashboard page"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={7.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <AnalysisBlock
                    sx={{ width: '100%' }}
                    isSales
                    data={{
                      salesThisYear: salesByYear?.totalSalesByYear || 0,
                      rate: salesByYear?.achievementRateByYear || '0',
                      target: salesByYear?.targetRevenue || 0
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <AnalysisBlock
                    title={t('salesThisMonth')}
                    sx={{ width: '100%' }}
                    isSales
                    data={{
                      salesThisYear: salesByMonth?.totalSalesByMonth || 0,
                      rate: salesByMonth?.achievementRateByMonth || '0',
                      target: salesByMonth?.targetRevenuePerMonth || 0
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Item>
                <Typography
                  sx={{
                    fontWeight: 'fontWeightBold',
                    fontSize: 16,
                    color: 'text.primary',
                    mb: 2
                  }}
                >
                  {t('monthlySales')} ({t('1mW')})
                </Typography>
                <AnalysisChart.B2Chart data={monthlySalesByChannel} />
              </Item>
            </Grid>

            <Grid item xs={12} md={8}>
              <Item sx={{ height: '230px' }}>
                <AnalysisChart.MixedChart data={salesStatistics} />
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <AnalysisBlock
                title={t('numberOfBusinessPartners')}
                secondTitle={t('previousMonth')}
                summaryTitle={t('previousMonthChange')}
                sx={{ width: '100%' }}
                data={{
                  total: partners?.numberClientOfCurrent || 0,
                  prev: partners?.numberClientOfPrevious || '0',
                  monthChange: partners?.numberClientChange || '0'
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={4.5}>
          <AnalysisTable data={salesByClient} />
        </Grid>
      </Grid>
    </HelmetContainer>
  );
};

export default Dashboard;
