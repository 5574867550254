import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const l = i18n.getFixedT(null, null, 'labels');

export const columns = [
  {
    name: 'vendorName',
    label: t('vendor-name'),
    minWidth: 120,
    align: 'center'
  },
  { name: 'vendorType', label: t('type'), minWidth: 100, align: 'center' },
  {
    name: 'vendingProduct',
    label: t('vending-product'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'pic',
    label: t('pic'),
    minWidth: 160,
    align: 'center',
    children: [
      { name: 'picName', label: t('name'), minWidth: 80, align: 'center' },
      { name: 'picContact', label: t('contact'), minWidth: 80, align: 'center' }
    ]
  },
  {
    name: 'paymentInfo',
    label: t('payment-info'),
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'bank',
        label: t('bank-name'),
        minWidth: 100,
        align: 'center'
      },
      {
        name: 'bankAccountNumber',
        label: t('account-number'),
        minWidth: 100,
        align: 'center'
      }
    ]
  },
  {
    name: 'taxBill',
    label: t('tax-bill'),
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'picEmail',
        label: t('email'),
        minWidth: 100,
        align: 'center'
      },
      {
        name: 'businessNumber',
        label: t('business-number'),
        minWidth: 100,
        align: 'center'
      },
      { name: 'noOfIssuance', label: t('no'), minWidth: 80, align: 'center' }
    ]
  },
  {
    name: 'useYn',
    label: t('available'),
    minWidth: 60,
    align: 'center'
  }
];

export const vendorModifySchema = [
  {
    name: 'vendorCode',
    type: 'text',
    label: t('vendor-code'),
    grid: 6,
    border: { borderLeft: 0 },
    disabled: true
  },
  {
    name: 'useYn',
    type: 'select',
    label: t('check-valid'),
    sx: {
      height: 100
    },
    grid: 6,
    data: [
      { value: 'Y', label: l('valid') },
      { value: 'N', label: l('invalid') }
    ]
  },
  {
    name: 'vendorName',
    type: 'text',
    label: t('vendor-name'),
    grid: 12,
    border: { borderLeft: 0 },
    required: true
  },
  {
    name: 'vendorType',
    type: 'text',
    label: t('type'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'vendingProduct',
    type: 'text',
    label: t('vending-product'),
    grid: 6
  },
  {
    name: 'picName',
    type: 'text',
    label: t('pic-name'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'picContact',
    type: 'text',
    label: t('pic-contact'),
    grid: 6
  },
  {
    name: 'bank',
    type: 'text',
    label: t('bank-name'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'bankAccountNumber',
    type: 'text',
    label: t('account-number'),
    grid: 6
  },
  {
    name: 'businessFileName',
    type: 'upload',
    label: t('business-registration'),
    grid: 12,
    border: { borderLeft: 0 }
  },
  {
    label: t('tax-bill'),
    border: { borderLeft: 0, borderBottom: 0 },
    children: [
      {
        name: 'picEmail',
        type: 'text',
        label: t('email'),
        grid: 6
      },
      {
        name: 'businessNumber',
        type: 'text',
        label: t('business-number'),
        grid: 6
      },
      {
        name: 'file',
        type: 'upload',
        label: t('file'),
        grid: 12
      }
    ]
  }
];
