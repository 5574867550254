import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MyTextField from '@components/MyTextField';
import { setError } from '@store/reducers/appReducer';

import { Formatter } from '@utils/formatter';
import { MAX_INT } from '@constants/utils';
import { BorderBox, inputStyle, tableStyle } from '../enhance';

export default function CouponForm({
  coupon,
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [point, setPoint] = useState('');
  const handleSubmit = () => {
    if (!point || Number(point) <= 0) {
      dispatch(
        setError({
          title: t('pages.member.dialog.title.invalidNumberOfPoints')
        })
      );
    } else {
      const { memberCode } = coupon;
      onSubmit({ memberCode, point });
    }
  };
  const handleChangeInput = (e) => {
    const point = parseInt(e.target.value?.replaceAll(',', ''), 10);
    setPoint(Math.min(point, MAX_INT));
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.member.labels.phoneNumber')}
              </TableCell>
              <TableCell>
                <MyTextField
                  size="small"
                  value={Formatter.fKrPhone(coupon?.phone || '')}
                  placeholder={t('pages.member.labels.phoneNumber')}
                  name="phoneNumber"
                  sx={inputStyle}
                  disabled
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.member.labels.point')}
              </TableCell>
              <TableCell>
                <MyTextField
                  size="small"
                  name="point"
                  value={Formatter.fNumber(point)}
                  placeholder={t('pages.member.labels.point')}
                  onChange={(e) => handleChangeInput(e)}
                  typeValue="onlyNumber"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
