/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import homepageService from '@services/homepage';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import MyDialog from '@components/MyDialog';
import { Delete } from '@mui/icons-material';
import HelmetContainer from '@components/HelmetContainer';
import RowDND from '@components/MyTable/MaterialReactTable';
import {
  setError,
  setOpenConfirm,
  setSuccess
} from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { columns, faqCategoryList } from './enhance';

export default function FAQ() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialog, setDialog] = useState({
    openConfirm: false,
    update: false,
    register: false,
    action: 'register'
  });
  const [param, setParam] = useState({
    faqStatus: 'PRODUCT'
  });
  const [loading, setLoading] = useState(false);

  const [draggingRows, setDraggingRows] = useState({
    draggingRow: null,
    hoveredRow: null
  });
  const handleChangeCheckbox = (val) => {
    setParam((prevParam) => ({
      ...prevParam,
      faqStatus: prevParam.faqStatus === val ? null : val
    }));
  };

  const getData = async (params) => {
    setLoading(true);
    const result = await homepageService.getFAQList(
      params || { category: param?.faqStatus }
    );
    if (result.error) dispatch(setError(error2Text(result.error)));
    else
      setData({
        ...data,
        items: result.data?.data?.items || [],
        totalDisplayedFaq: result.data?.data?.totalDisplayedFaq || 0
      });
    setLoading(false);
  };

  const handleSearch = () => {
    const query = { faqStatus: param?.faqStatus };

    navigate(objToParams(ignoreEmpty(query)));
    getData({ category: param?.faqStatus || 'PRODUCT' });
  };

  const handleRowClick = (row) => {
    console.log('row', row);
    navigate(row?.faqCode);
  };

  const handleChangeDisplayStatus = async (item) => {
    // change from the list
    console.log('status', item?.faqCode);
    const rs = await homepageService.updateFaqDisplaystatus(item?.faqCode, {
      isDisplayed: item?.isDisplayed === 'Y' ? 'N' : 'Y'
    });

    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      getData();
    } else {
      setData((prev) => {
        const updatedList = prev?.items.map((faq) => {
          if (faq.faqCode === item?.faqCode) {
            return {
              ...faq,
              isDisplayed: item?.isDisplayed === 'Y' ? 'N' : 'Y'
            };
          }
          return faq;
        });
        const totalDisplayedItems =
          item?.isDisplayed === 'Y'
            ? Number(prev?.totalDisplayedFaq) - 1
            : Number(prev?.totalDisplayedFaq) + 1;
        return {
          ...prev,
          items: updatedList,
          totalDisplayedFaq: totalDisplayedItems
        };
      });
      dispatch(setSuccess({ message: t('info.success.content') }));
    }
    dispatch(
      setOpenConfirm({
        okTitle: null,
        title: null,
        action: null,
        open: false,
        type: null,
        msg: null
      })
    );
  };

  const deleteFaq = async (item) => {
    // change from the list
    const rs = await homepageService.deleteFaq(item?.faqCode);
    if (rs?.error) {
      getData();
    } else {
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getData();
    }
    dispatch(
      setOpenConfirm({
        okTitle: null,
        title: null,
        action: null,
        open: false,
        type: null,
        msg: null
      })
    );
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Checkbox
            disableRipple
            checked={item?.isDisplayed === 'Y'}
            onChange={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              dispatch(
                setOpenConfirm({
                  open: true,
                  title:
                    item?.isDisplayed === 'Y'
                      ? t('pages.homepage.exclude-in-landing')
                      : t('pages.homepage.include-in-landing'),
                  msg:
                    item?.isDisplayed === 'Y'
                      ? t('pages.homepage.exclude-in-landing-subtitle')
                      : t('pages.homepage.include-in-landing-subtitle'),
                  type: item?.isDisplayed !== 'Y' && 'edit',
                  action: () => handleChangeDisplayStatus(item),
                  okTitle:
                    item?.isDisplayed === 'Y'
                      ? t('pages.homepage.exclude')
                      : t('button.check')
                })
              );
            }}
          />
        );

      default:
        return (
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              dispatch(
                setOpenConfirm({
                  open: true,
                  action: () => deleteFaq(item),
                  okTitle: t('button.delete')
                })
              );
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };

  const handleRowDrag = async () => {
    if (draggingRows.draggingRow && draggingRows.hoveredRow) {
      const dataSubmit = {
        category: param.faqStatus,
        minFaqCode: draggingRows?.draggingRow.original.faqCode,
        maxFaqCode: draggingRows?.hoveredRow.original.faqCode
      };
      const rs = await homepageService.updateFaqDisplayOrder(dataSubmit);
      if (rs?.error) {
        dispatch(setError(error2Text(rs?.error)));
      } else {
        getData();
        dispatch(setSuccess({ message: t('info.success.content') }));
        setDialog({ ...dialog, drag: false });
      }
      dispatch(
        setOpenConfirm({
          okTitle: null,
          title: null,
          action: null,
          open: false,
          type: null,
          msg: null
        })
      );
    }
  };

  const opendragConfirmModal = (draggingRow, hoveredRow) => {
    setDraggingRows({ ...draggingRows, hoveredRow, draggingRow });
    dispatch(
      setOpenConfirm({
        open: true,
        title: t('pages.homepage.faq-change-title'),
        msg: t('pages.homepage.confirm-change-faq'),
        type: 'edit',
        action: () => handleRowDrag(),
        okTitle: t('button.check')
      })
    );
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { faqStatus } = query;
    const dataParams = { category: faqStatus || 'PRODUCT' };
    setParam({ ...param, faqStatus: faqStatus || 'PRODUCT' });
    getData(dataParams);
  }, [location?.search]);

  return (
    <HelmetContainer title={t('pageTitle.faq')} content="homepage faq">
      <SearchBar>
        <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            label={t('pages.product.category')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 2
              }
            }}
            control={
              <Stack flexDirection="row">
                {faqCategoryList.map(({ value, label }) => (
                  <FormControlLabel
                    key={`${value}-${label}`}
                    control={
                      <Checkbox
                        disableRipple
                        checked={param.faqStatus === value}
                        onChange={() => handleChangeCheckbox(value)}
                      />
                    }
                    label={label}
                  />
                ))}
              </Stack>
            }
          />
        </FormControl>
        <Button variant="contained" onClick={handleSearch}>
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: '30px', mb: 2 }}
      >
        <Button variant="outlined" onClick={() => navigate('register')}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.length || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <RowDND
        columns={columns(data)}
        data={data?.items || []}
        getActionButtons={getActionButtons}
        onRowClick={handleRowClick}
        onRowDrag={opendragConfirmModal}
        setData={setData}
      />

      <MyDialog
        open={dialog.drag}
        setOpen={() => setDialog({ ...dialog, drag: false })}
        onOk={handleRowDrag}
        isAlert
        title={t('pages.homepage.faq-change-title')}
        subTitle={t('pages.homepage.confirm-change-faq')}
        hasCancelButton
      />
    </HelmetContainer>
  );
}
