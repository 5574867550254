import React from 'react';
import { Stack, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const Backbutton = ({ title, route, sx }) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="flex-start" mx={1} sx={{ ...sx }}>
      <IconButton
        onClick={() =>
          navigate(typeof route === 'number' ? route : `/${route}`)
        }
        sx={{
          display: 'flex',
          gap: 0.5,
          ':hover': {
            backgroundColor: 'transparent'
          }
        }}
        disableRipple
        disableTouchRipple
      >
        <ArrowBackIosNewIcon
          sx={{ height: '20px', color: 'black', fontWeight: 500 }}
        />
        <Typography sx={{ fontSize: 20, color: 'black', fontWeight: 500 }}>
          {title}
        </Typography>
      </IconButton>

      {/* <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {items?.length || 0}
          </Typography>
        </Typography>
      </Stack> */}
    </Stack>
  );
};

export default Backbutton;
