/* eslint-disable consistent-return */
import React, { useRef } from 'react';
import { Box, Select, MenuItem, IconButton } from '@mui/material';
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  Code,
  Image,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  StrikethroughS,
  FormatUnderlined
} from '@mui/icons-material';
import storeService from '@services/pre-order/store';
import env from '@constants/env';

const tools = [
  { task: 'bold', icon: <FormatBold /> },
  { task: 'italic', icon: <FormatItalic /> },
  { task: 'underline', icon: <FormatUnderlined /> },
  { task: 'strike', icon: <StrikethroughS /> },
  { task: 'codeBlock', icon: <Code /> },
  { task: 'left', icon: <FormatAlignLeft /> },
  { task: 'center', icon: <FormatAlignCenter /> },
  { task: 'right', icon: <FormatAlignRight /> },
  { task: 'bulletList', icon: <FormatListBulleted /> },
  { task: 'orderedList', icon: <FormatListNumbered /> },
  { task: 'image', icon: <Image /> }
];

const headingOptions = [
  { task: 'p', value: 'Paragraph' },
  { task: 'h1', value: 'Heading 1' },
  { task: 'h2', value: 'Heading 2' },
  { task: 'h3', value: 'Heading 3' }
];

const chainMethods = (editor, command) => {
  if (!editor) return;
  command(editor.chain().focus()).run();
};

function Tools({ editor, storeCode, productCode }) {
  const fileInputRef = useRef(null);

  const handleOnClick = async (task) => {
    switch (task) {
      case 'bold':
        return chainMethods(editor, (chain) => chain.toggleBold());
      case 'italic':
        return chainMethods(editor, (chain) => chain.toggleItalic());
      case 'underline':
        return chainMethods(editor, (chain) => chain.toggleUnderline());
      case 'strike':
        return chainMethods(editor, (chain) => chain.toggleStrike());
      case 'code':
        return chainMethods(editor, (chain) => chain.toggleCode());
      case 'codeBlock':
        return chainMethods(editor, (chain) => chain.toggleCodeBlock());
      case 'orderedList':
        return chainMethods(editor, (chain) => chain.toggleOrderedList());
      case 'bulletList':
        return chainMethods(editor, (chain) => chain.toggleBulletList());
      case 'left':
        return chainMethods(editor, (chain) => chain.setTextAlign('left'));
      case 'center':
        return chainMethods(editor, (chain) => chain.setTextAlign('center'));
      case 'right':
        return chainMethods(editor, (chain) => chain.setTextAlign('right'));
      case 'image':
        return handleImageUpload();
      default:
        break;
    }
  };

  const handleImageUpload = () => {
    // Open file input dialog when the image button is clicked
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('photoFile', file);

      const { location, error } = await storeService.uploadProductImages(
        storeCode,
        productCode,
        formData
      );

      if (location && !error) {
        editor
          .chain()
          .focus()
          .setImage({ src: `${env.s3BaseUrl}/${location}` })
          .run();
      } else {
        console.error('Image upload failed', error);
      }
    }
  };

  const handleHeadingSelection = (event) => {
    const { value } = event.target;
    switch (value) {
      case 'p':
        return chainMethods(editor, (chain) => chain.setParagraph());
      case 'h1':
        return chainMethods(editor, (chain) =>
          chain.toggleHeading({ level: 1 })
        );
      case 'h2':
        return chainMethods(editor, (chain) =>
          chain.toggleHeading({ level: 2 })
        );
      case 'h3':
        return chainMethods(editor, (chain) =>
          chain.toggleHeading({ level: 3 })
        );
      default:
        break;
    }
  };

  const getSelectedHeading = () => {
    let result = 'p';
    if (editor?.isActive('heading', { level: 1 })) result = 'h1';
    if (editor?.isActive('heading', { level: 2 })) result = 'h2';
    if (editor?.isActive('heading', { level: 3 })) result = 'h3';
    return result;
  };

  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <Select
        value={getSelectedHeading()}
        onChange={handleHeadingSelection}
        size="small"
      >
        {headingOptions.map((item) => (
          <MenuItem key={item.task} value={item.task}>
            {item.value}
          </MenuItem>
        ))}
      </Select>

      {/* Hidden file input for image upload */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {tools.map(({ icon, task }) => (
        <IconButton
          key={task}
          onClick={() => handleOnClick(task)}
          color={
            editor?.isActive(task) || editor?.isActive({ textAlign: task })
              ? 'primary'
              : 'default'
          }
          size="small"
        >
          {icon}
        </IconButton>
      ))}
    </Box>
  );
}

export default Tools;
