import { tableTypes } from '@constants/utils';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const d = i18n.getFixedT(null, null, 'pages.display');

export const columns = [
  {
    name: 'pbNum',
    label: d('employNum'),
    minWidth: 160,
    align: 'center'
  },
  { name: 'managerName', label: t('name'), minWidth: 160, align: 'center' },
  { name: 'managerPhone', label: t('contact'), minWidth: 160, align: 'center' },
  { name: 'managerArea', label: d('area'), minWidth: 160, align: 'center' },
  { name: 'managerZone', label: d('section'), minWidth: 160, align: 'center' },
  {
    name: 'manage',
    label: t('managements'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];
