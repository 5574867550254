import {
  getFilenameFromHeader,
  downloadFileFromBinary
} from '@utils/downloadFile';

import RestService from './RestService';

const clientOrderServices = {
  /**
   * @params {clientCode, minibarCode, requester, requestType, orderingVendor, startDate, endDate, status}
   */
  async getList(params) {
    try {
      const res = await new RestService()
        .setPath('client-order')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getDetail(code) {
    try {
      const res = await new RestService().setPath(`client-order/${code}`).get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSample() {
    try {
      const res = await new RestService()
        .setPath('client-order/export-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * @props (clientOrderCode, { productCode, quantity })
   */
  async addProduct(clientOrderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`client-order/${clientOrderCode}`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createNewOrder(data) {
    try {
      const res = await new RestService().setPath(`client-order`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createClientOrdersByExcel(data) {
    try {
      const res = await new RestService()
        .setPath(`client-order/import`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //
  /**
   * @props (clientOrderCode, productCode, { quantity })
   */
  async updateOrderProduct(clientOrderCode, productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`client-order/${clientOrderCode}/product/${productCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * @props (clientOrderCode, {shippingDate})
   */
  async pickingOrder(clientOrderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`client-order/${clientOrderCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * @props (clientOrderCode)
   */
  async deleteOrder(clientOrderCode) {
    try {
      const res = await new RestService()
        .setPath(`client-order/${clientOrderCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * @props (clientOrderCode, productCode)
   */
  async deleteOrderProduct(clientOrderCode, productCode) {
    try {
      const res = await new RestService()
        .setPath(`client-order/${clientOrderCode}/product/${productCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * @prop: clientOrderCode
   */
  async downloadOrderSheet(params) {
    try {
      const res = await new RestService()
        .setPath(`client-order/export-transaction-statement`)
        .setResponseType('blob')
        .setConfig({ params })
        .get();
      const fileName = getFilenameFromHeader(res.headers);
      if (res?.data) {
        downloadFileFromBinary(res.data, fileName);
      }
      return {
        data: res?.data,
        error: null
      };
    } catch (err) {
      return {
        error: err?.response?.data || err,
        data: null
      };
    }
  }
};

export default clientOrderServices;
