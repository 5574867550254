import fallbackImg from '@svg/fallback-img.svg';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { userServices } from '@services/index';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, '');

const InputFile = ({
  title = t('button.upload'),
  file,
  onChange,
  disabled,
  urlData,
  sx,
  accept = '*',
  dataFile,
  loading,
  ...props
}) => {
  const [blob, setBlob] = useState();
  useEffect(() => {
    const fileUrl = file ? URL.createObjectURL(file) : urlData?.url;
    if (fileUrl) setBlob(fileUrl);
    return () => URL.revokeObjectURL(fileUrl);
  }, [file]);

  const propsCustom = useMemo(
    () =>
      blob?.startsWith('blob:') || blob?.startsWith('http://')
        ? {
            component: 'a',
            target: '_blank',
            rel: 'noopener noreferrer',
            href: blob
          }
        : {
            onClick: (e) => {
              e.stopPropagation();
              userServices.getFile({
                fileName: urlData.name,
                fileLink: urlData?.url
              });
            }
          },
    [blob, urlData]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ ...sx?.root, height: 'auto' }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ ...sx }}
        {...props}
      >
        {dataFile?.src && (
          <img
            src={dataFile?.src}
            alt="product_img"
            style={{
              margin: '0 20px',
              height: '100%',
              width: '150px',
              maxHeight: '120px',
              objectFit: 'contain',
              ...sx?.image
            }}
            onError={(e) => {
              e.target.onError = null;
              e.target.src = fallbackImg;
            }}
          />
        )}
        <Typography
          sx={{
            color: 'primary.darker',
            fontWeight: 'fontWeightBold',
            fontSize: 13,
            textDecoration: 'underline',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '161px',
            paddingRight: '20px',
            ...sx?.label
          }}
          {...propsCustom}
        >
          {file?.name || urlData?.name || dataFile?.name || ''}
        </Typography>
        <Button
          variant="outlined"
          component="label"
          sx={{
            height: { md: '36px', xs: 26 },
            p: '14px 15px',
            whiteSpace: 'nowrap'
          }}
          disabled={loading || disabled}
        >
          {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
          {title}
          <input hidden accept={accept} type="file" onChange={onChange} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default InputFile;
