import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { capitalize } from '@utils/helpers';
import MyLoadingButton from '@components/MyLoadingButton';
import releaseService from '@services/release';
import PictureForm from './componenets/PictureForm';
import { displayColumns } from './enhance';
import LossForm from './componenets/LossForm';

const DisplayDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [photoForm, setPhotoForm] = useState({
    latitude: null,
    longitude: null
  });
  const [lossValue, setLossValue] = useState({});
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleLoss = (action, item) => {
    setSelected({
      ...selected,
      open: true,
      action,
      item,
      title:
        action === 'loss'
          ? t('pages.inventory.loss')
          : t('pages.inventory.dispose')
    });
  };
  const configColumns = displayColumns;

  const getDetailShipping = async () => {
    const rs = await releaseService.getDetailShipping(
      location?.state?.shippingCode
    );
    if (!rs?.error) {
      setState({
        ...state,
        ...rs?.data,
        items: rs?.data?.listShippingProduct || [],
        pagination: {
          ...state?.pagination,
          page: rs?.data?.page || 0,
          totalRow: rs?.data?.totalRow || 0,
          count: rs?.data?.totalPage || 0
        }
      });
      setPhotoForm(rs?.data);
    }
  };
  useEffect(() => {
    getDetailShipping();
  }, []);
  const getActionButtons = (item) => (
    <Stack flexDirection="row" gap={2}>
      <Button
        onClick={() => handleLoss('loss', item)}
        variant="outlined"
        sx={{ height: 35, fontSize: 13 }}
      >
        {t('pages.inventory.loss')}
      </Button>
      <Button
        onClick={() => handleLoss('dispose', item)}
        variant="outlined"
        sx={{ height: 35, fontSize: 13 }}
      >
        {t('pages.inventory.dispose')}
      </Button>
    </Stack>
  );
  const handlePhoto = (action) => {
    if (action === 'before') {
      setSelected({
        ...selected,
        open: true,
        title: t('pages.inventory.photo-before-display'),
        action
      });
    }
    if (action === 'after') {
      setSelected({
        ...selected,
        open: true,
        title: t('pages.inventory.photo-after-display'),
        action
      });
    }
  };
  const dialogOk = async () => {
    let rs = null;
    if (selected?.action === 'before' || selected?.action === 'after') {
      const dataSubmit = new FormData();
      const keys = Object.keys(photoForm);
      const values = Object.values(photoForm);
      keys.forEach((key, idx) => {
        dataSubmit.append(key, values[idx]);
      });
      if (selected?.action === 'before') {
        dataSubmit.append('isPictureBeforeShipping', true);
        rs = await releaseService.updateShippingSchedule(
          state?.shippingCode,
          dataSubmit
        );
      }
      if (selected?.action === 'after') {
        dataSubmit.append('isPictureBeforeShipping', false);
        dataSubmit.append('managerCode', selected?.item?.managerCode);
        dataSubmit.append('schDetailId', selected?.item?.schDetailId);
        rs = await releaseService.updateShippingSchedule(
          state.shippingCode,
          dataSubmit
        );
      }
    }
    if (selected?.action === 'loss' || selected?.action === 'dispose') {
      const { shippingCode, clientCode, clientName } = state;
      const {
        shippingProductId,
        locationCode,
        barcode,
        capacity,
        capacityType,
        centerCode,
        maker,
        minibarCode,
        minibarName,
        productCode,
        productName
      } = selected.item;
      const dataSubmit = {
        shippingCode,
        shippingProductId,
        locationCode,
        lossDivision: capitalize(selected?.action),
        barcode,
        capacity,
        capacityType,
        centerCode,
        maker,
        minibarCode,
        minibarName,
        productCode,
        productName,
        clientCode,
        clientName,
        ...lossValue
      };
      rs = await releaseService.createLoss(dataSubmit);
    }
    if (!rs?.error) {
      getDetailShipping();
      setSelected({ ...selected, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const handleCompleted = async () => {
    const rs = await releaseService.updateShippingStatus(state?.shippingCode, {
      status: 'Completed'
    });
    if (!rs?.error) {
      navigate(-1);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.displayDetail')}
      content="Display Detail Page"
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" gap={2}>
          <Button
            disabled={state?.schDetailId}
            variant="outlined"
            onClick={() => handlePhoto('before')}
          >
            {t('pages.inventory.photo-before-display')}
          </Button>
          <Button
            variant="outlined"
            disabled={!state?.schDetailId || state?.isAllowComplete}
            onClick={() => handlePhoto('after')}
          >
            {t('pages.inventory.photo-after-display')}
          </Button>
        </Stack>
        <MyLoadingButton
          variant="outlined"
          onClick={() => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'modify',
                action: () => handleCompleted()
              })
            );
          }}
          disabled={!state?.isAllowComplete}
        >
          {t('common.completion')}
        </MyLoadingButton>
      </Stack>
      <MyTable
        rerender
        columns={configColumns}
        data={state?.items}
        getActionButtons={getActionButtons}
      />
      <MyDialog
        sx={{ minWidth: 500 }}
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => dialogOk()
            })
          );
        }}
        disabled={
          selected?.action !== 'loss' &&
          selected?.action !== 'dispose' &&
          !photoForm?.latitude &&
          !photoForm?.longitude
        }
        onClose={() => {
          setTimeout(() => {
            setSelected(null);
            setLossValue({});
          }, 200);
        }}
      >
        {selected?.action === 'loss' || selected?.action === 'dispose' ? (
          <LossForm
            item={selected?.item}
            idShip={state?.shippingCode}
            lossValue={lossValue}
            setLossValue={setLossValue}
            action={selected?.action}
          />
        ) : (
          <PictureForm
            value={photoForm}
            setValue={setPhotoForm}
            action={selected?.action}
          />
        )}
      </MyDialog>
    </HelmetContainer>
  );
};

export default DisplayDetail;
