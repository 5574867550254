import React, { useEffect, useState } from 'react';
import useMergeState from '@hooks/useMergeState';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';

import adminServices from '@services/admin';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { createModalRows, maxLengthOf } from './enhance';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

// [IMPROVE] this component rerender many times caused by [useMergeState], [form > table] cant not get values when submited ??
const CreateAdminDialog = ({ open, setOpen, dispatch, getData, title }) => {
  const { t } = useTranslation();

  const [state, setState] = useMergeState();
  const [showPassword, setShowPassword] = useState(false);

  const [errMsg, setErrMsg] = useState({
    regName: '',
    userId: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setState(
      createModalRows.reduce((prev, { name }) => {
        return {
          ...prev,
          [name]: null
        };
      }, {})
    );
    setErrMsg({
      regName: '',
      userId: '',
      password: '',
      confirmPassword: ''
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (name, event) => {
    const { value } = event.target;
    setState({ [name]: value });
    if (value) {
      setErrMsg({
        ...errMsg,
        [name]:
          value.length > maxLengthOf[name]
            ? t('validation.dataTooLong', { max: maxLengthOf[name] })
            : ''
      });
    } else {
      setErrMsg({
        ...errMsg,
        [name]: t('common.required')
      });
    }
  };

  const checkConfirmPassword = () => {
    if (state.confirmPassword) {
      setErrMsg({
        ...errMsg,
        confirmPassword:
          state.confirmPassword !== state.password
            ? t('validation.confirmPasswordNotMatch')
            : ''
      });
    }
  };

  const validateOnSubmit = () => {
    const tempMsg = { ...errMsg };
    let isValid = true;
    // eslint-disable-next-line	no-restricted-syntax, guard-for-in
    for (const key in tempMsg) {
      if (!state[key]) {
        tempMsg[key] = t('common.required');
        isValid = false;
      }
      if (tempMsg[key]) {
        isValid = false;
      }
    }

    setErrMsg(tempMsg);
    return isValid;
  };

  const createAdminHandler = async () => {
    const res = await adminServices.createAdmin(state);
    if (res.error) {
      dispatch(setError(error2Text(res.error)));
    }
    setOpen(false);
    getData();
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={title}
      okTitle={t('button.register')}
      hasCloseButton
      hasCancelButton
      onOk={() => {
        if (validateOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => createAdminHandler()
            })
          );
        }
      }}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody>
            {createModalRows.map(({ label, name, props, type }) => (
              <TableRow key={name}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'background.light'
                  }}
                >
                  {t(`admin.create.${label}`)}
                  <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                </TableCell>
                <TableCell>
                  {type === 'password' ? (
                    <MyTextField
                      size="small"
                      name={name}
                      type={showPassword ? 'text' : 'password'}
                      onChange={(event) => handleChange(name, event)}
                      onBlur={() => checkConfirmPassword()}
                      errMg={errMsg[label]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  ) : (
                    <MyTextField
                      name={name}
                      value={state[name] || ''}
                      onChange={(event) => handleChange(name, event)}
                      errMg={errMsg[label]}
                      {...props}
                      sx={{ width: '100%' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default CreateAdminDialog;
