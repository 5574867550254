import RestService from '@services/RestService';

const consultationInquiryService = {
  // Media

  async getMediaList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/media/list')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createScrapeRequest(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/media')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async registerNewMedia(data = {}) {
    try {
      const res = await new RestService().setPath('/media').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMedia(mediaCode, data = {}) {
    try {
      const res = await new RestService()
        .setPath(`/media/${mediaCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMediaDisplayStatus(mediaCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/media/${mediaCode}/displayed`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteNews(mediaCode) {
    try {
      const res = await new RestService()
        .setPath(`/media/${mediaCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // FAQ
  async getFAQList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/faq/list')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getFAQdetail(faqCode) {
    try {
      const res = await new RestService().setPath(`/faq/${faqCode}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async registerNewFAq(data = {}) {
    try {
      const res = await new RestService().setPath('/faq').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateFaq(faqCode, data = {}) {
    try {
      const res = await new RestService().setPath(`/faq/${faqCode}`).put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateFaqDisplaystatus(faqCode, data = {}) {
    try {
      const res = await new RestService()
        .setPath(`/faq/${faqCode}/displayed`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateFaqDisplayOrder(data = {}) {
    try {
      const res = await new RestService().setPath(`/faq`).put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteFaq(faqCode) {
    try {
      const res = await new RestService().setPath(`/faq/${faqCode}`).delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // Notices

  async getNoticeList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/notice')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getNoticeDetail(noticeCode) {
    try {
      const res = await new RestService()
        .setPath(`/notice/${noticeCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async registerNewNotice(data = {}) {
    try {
      const res = await new RestService().setPath('/notice').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateNotice(noticeCode, data = {}) {
    try {
      const res = await new RestService()
        .setPath(`/notice/${noticeCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateNoticeStatus(noticeCode, data = {}) {
    try {
      const res = await new RestService()
        .setPath(`/notice/${noticeCode}/display`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteNotice(noticeCode) {
    try {
      const res = await new RestService()
        .setPath(`/notice/${noticeCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // Careers
  async getCareerList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/recruit')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getLocationList() {
    try {
      const res = await new RestService().setPath('/recruit/region').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getJobAnnouncementDetail(jobCode) {
    try {
      const res = await new RestService().setPath(`/recruit/${jobCode}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createJobPost(data = {}) {
    try {
      const res = await new RestService().setPath('/recruit').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateJobPost(jobCode, data = {}) {
    try {
      const res = await new RestService()
        .setPath(`/recruit/${jobCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteJobPost(jobCode) {
    try {
      const res = await new RestService()
        .setPath(`/recruit/${jobCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default consultationInquiryService;
