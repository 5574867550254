import { sub } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Stack
} from '@mui/material';

import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import SearchBar from '@components/SearchBar';

import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';

import { channelServices, curationServices } from '@services/index';

import RecommendedQuantityArea from './RecommendedQuantityArea';
import { conditionList } from '../../enhance';
import BudgetArea from './BudgetArea';
import CategoryArea from './CategoryArea';
import ProductArea from './ProductArea';

const CurationRegister = ({ t: pageT }) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
    key: 'selection'
  });
  const [stCat, setStCat] = useState('');
  const [ndCat, setNdCat] = useState('');
  const [rdCat, setRdCat] = useState('');
  const [specCat, setSpecCat] = useState([]);
  const listCtgState = {
    stCat,
    setStCat,
    ndCat,
    setNdCat,
    rdCat,
    setRdCat,
    specCat,
    setSpecCat
  };
  const [channelList, setChannelList] = useState([]);
  const [channel, setChannel] = useState(null);
  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const [condition, setCondition] = useState(conditionList[0].value);
  const keywordRef = useRef('');

  const [data, setData] = useState();

  const [selectedProductList, setSelectedProductList] = useState([]);
  const [recommenedData, setRecommenedData] = useState();

  const getChannels = async () => {
    const result = await channelServices.getChannels();
    if (!result.error) {
      const a = result.data.data.map((cn) => ({
        value: cn.channelName,
        label: cn.channelName
      }));

      setChannelList(a);
    }
  };

  const getData = async (more) => {
    const result = await curationServices.getCurationProduct({
      startDate: Formatter.formatTime(dateRange.startDate, dateFormat),
      endDate: Formatter.formatTime(dateRange.endDate, dateFormat),
      clientChannel: channel,
      clientCode: client?.clientCode || null,
      minibarCode: minibar?.minibarCode || null,
      condition: condition || null,
      productName: keywordRef.current?.value || '',
      ...more
    });
    setData(result.data.data);
  };

  const clearAdditionalData = () => {
    setSelectedProductList([]);
    setRecommenedData();
  };

  const handleSearch = () => {
    clearAdditionalData();
    getData();
  };

  const handleApplyCategoryFilter = async () => {
    // clearAdditionalData();
    setRecommenedData();
    await getData({
      category1Code: stCat || null,
      category2Code: ndCat || null,
      category3Code: rdCat || null,
      categorySpecialCode: specCat?.join(',') || null
    });
  };

  const handleAddProduct = (dataParam) => {
    const leftProducts = [];
    dataParam.forEach((item) => {
      const existItem = selectedProductList.filter(
        (i) => i.productCode === item.productCode
      );
      if (!existItem.length) leftProducts.push(item);
    });
    setSelectedProductList(selectedProductList.concat(leftProducts));
  };

  const handleRemoveProduct = (_item) => {
    const newData = [
      ...selectedProductList.filter(
        (item) => item.productCode !== _item.productCode
      )
    ];
    setSelectedProductList(newData);
  };

  const handleApplyBudget = async (data) => {
    setRecommenedData(data);
  };

  useEffect(() => {
    setMinibar(null);
  }, [client]);

  useEffect(() => {
    setClient(null);
  }, [channel]);

  useEffect(() => {
    if (channelList.length > 0) {
      setChannel(channelList[0]?.value);
      getData({ clientChannel: channelList[0]?.value });
    }
  }, [channelList]);

  useEffect(() => {
    getChannels();
  }, []);

  return (
    <HelmetContainer
      title={`${t('pageTitle.curation')} ${t('common.register')}`}
      content="Register Curation"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={pageT('period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker state={dateRange} setState={setDateRange} />
            }
          />
        </FormControl>
        <MySelect
          label={pageT('channel')}
          value={channel || ''}
          data={channelList}
          onChange={(e) => setChannel(e.target.value)}
          sx={{ mr: 1.5 }}
        />
        <ClientAutoCompleteV2
          disabled={!channel}
          setClient={setClient}
          client={client}
          channel={channel}
        />
        <MinibarAutoCompleteV2
          onKeyDown={(e) => {
            if (e.isComposing || e.keyCode === 13) {
              handleSearch();
            }
          }}
          disabled={!client}
          setMinibar={setMinibar}
          minibar={minibar}
          client={client}
        />
        <Stack flexDirection="row" gap={1}>
          <MySelect
            label={pageT('condition')}
            value={condition}
            data={conditionList}
            onChange={(e) => setCondition(e.target.value)}
            sx={{ mr: 1.5 }}
          />
          <Box sx={{ flex: 1, minWidth: 200 }}>
            <MyTextField
              placeholder={t('button.search')}
              ref={keywordRef}
              fullWidth
              inputProps={{
                onKeyDown: (e) => {
                  if (e.isComposing || e.keyCode === 13) {
                    handleSearch();
                  }
                }
              }}
            />
          </Box>
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <CategoryArea
        listCtgState={listCtgState}
        onApply={handleApplyCategoryFilter}
        pageT={pageT}
        data={data}
      />
      <ProductArea onAdd={handleAddProduct} data={data} />
      <BudgetArea
        onApply={handleApplyBudget}
        selectedProductList={selectedProductList}
      />
      <RecommendedQuantityArea
        selectedProductList={selectedProductList}
        handleRemoveProduct={handleRemoveProduct}
        recommenedData={recommenedData}
      />
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.curation' })(
  CurationRegister
);
