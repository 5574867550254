import RestService from '@services/RestService';

const releaseService = {
  async getReleaseList(data) {
    try {
      const res = await new RestService().setPath('/shipping/list').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateShippingStatus(id, data) {
    try {
      const res = await new RestService()
        .setPath(`shipping/${id}/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getScheduleList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`shipping/schedule-list`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateShippingSchedule(id, data) {
    try {
      const res = await new RestService()
        .setPath(`shipping/${id}/schedule`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createLoss(data) {
    try {
      const res = await new RestService()
        .setPath(
          `shipping/${data?.shippingCode}/product/${data?.shippingProductId}/loss`
        )
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getDetailShipping(id) {
    try {
      const res = await new RestService().setPath(`shipping/${id}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getDetailShippingProduct(idShip, idProd) {
    try {
      const res = await new RestService()
        .setPath(`shipping/${idShip}/product/${idProd}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default releaseService;
