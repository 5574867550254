/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import storeService from '@services/pre-order/store';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import SearchBar from '@components/SearchBar';
import AlertDialog from '@components/MyDialog/AlertDialog';
import { Delete } from '@mui/icons-material';
import HelmetContainer from '@components/HelmetContainer';
// import MyTable from '@components/MyTable';
import MyTable from '@components/MyTable/GroupMultiHeaderTable';
import MySelect from '@components/MySelect';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import MyTextField from '@components/MyTextField';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { columns, localeOptions } from './enhance';

export default function Store() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,

    action: null
  });
  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const [param, setParam] = useState({
    storeName: '',
    locale: 'all'
  });
  const [loading, setLoading] = useState(false);

  const getData = async (params) => {
    setLoading(true);
    const result = await storeService.getStoreList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRows: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleSearch = () => {
    const query = {
      storeName: param?.storeName,
      locale: param?.locale,
      page: pagination.page + 1
    };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      preorderLocale: param?.locale === 'all' ? null : param?.locale,
      storeName: param?.storeName,
      page: 0
    });
  };

  const handleRowClick = (row) => {
    navigate(row?.storeCode, {
      state: {
        locale: param?.locale || 'all'
      }
    });
  };

  const deleteStore = async () => {
    // change from the list
    if (!selectedItem?.storeCode) {
      return;
    }
    const rs = await storeService.deleteStore(selectedItem?.storeCode);
    if (rs?.error) {
      setSelectedItem(null);
      setDialog({ ...dialog, open: false });
      dispatch(setError(error2Text(rs?.error)));
      getData({ ...param, page: pagination.page });
    } else {
      setSelectedItem(null);
      setDialog({ ...dialog, open: false });
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getData({ ...param, page: pagination.page });
    }
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      default:
        return (
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({ ...dialog, open: true });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { storeName, locale, page } = query;
    const dataParams = {
      storeName,
      preorderLocale: locale === 'all' ? null : locale,
      page: page - 1 || 0
    };
    setParam({ ...param, storeName, locale: locale || 'all' });
    setPagination({ ...pagination, page: page - 1 || 0 });
    getData(dataParams);
  }, [location?.search]);

  return (
    <HelmetContainer
      title={t('pageTitle.preorder-store')}
      content="preorder store"
      sx={{ width: '100%' }}
    >
      <SearchBar>
        <MySelect
          label={t('pages.pre-order.locales.label')}
          value={param?.locale}
          data={localeOptions}
          onChange={(e) => setParam({ ...param, locale: e?.target?.value })}
          sx={{ width: '120px', mr: 2 }}
        />
        <MyTextField
          label={t('pages.pre-order.store')}
          value={param?.storeName}
          onChange={(e) => setParam({ ...param, storeName: e?.target?.value })}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleSearch}>
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: '30px', mb: 2 }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate('register', {
              state: {
                locale: param?.locale || 'all'
              }
            })
          }
        >
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRows || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <MyTable
        hover
        columns={columns}
        data={data || []}
        getActionButtons={getActionButtons}
        onRowClick={handleRowClick}
        pagination={pagination}
        onChangePage={(e, page) => {
          const query = objToParams(ignoreEmpty({ page: page + 1 }));
          setPagination({ ...pagination, page });
          getData({ page, size: 20 });
          navigate(query);
        }}
        minusHeight={235}
      />
      {selectedItem && (
        <AlertDialog
          open={dialog.open}
          setOpen={() => setDialog({ ...dialog, open: false })}
          onOk={deleteStore}
          title={t('common.confirm-delete-new-title')}
          subTitle={t('common.confirm-delete-new-subtitle')}
          isAlert
          hasCancelButton
        />
      )}
    </HelmetContainer>
  );
}
