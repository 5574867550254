import MyAutocomplete from '@components/MyAutocomplete';

import adServices from '@services/ad';
// import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

export default function AdVersionComplete({
  ad,
  setAd,
  sx,

  textFieldProps
}) {
  const [options, setOptions] = useState([]);
  const [adItem, setAdItem] = useState(null);

  const getItems = async () => {
    const result = await adServices.getListAdVersion({
      page: 0,
      size: 100
    });
    if (!result.error) {
      if (ad?.adNickname) {
        const currentAd = result.data?.items.find((item) =>
          item?.adNickname.toLowerCase().includes(ad?.adNickname.toLowerCase())
        );
        setAd(currentAd);
        setAdItem(currentAd);
      }
      setOptions(result.data?.items || []);
    }
  };

  useEffect(() => {
    setOptions([]);
    getItems();
  }, []);

  // const handleInputChange = debounce(async (e, v, reason) => {
  //   if (reason === 'input') {
  //     const result = await adServices.getListAdVersion({
  //       keyword: v,
  //       page: 0,
  //       size: 100
  //     });
  //     if (!result.error) {
  //       setOptions(result.data?.items || []);
  //     }
  //   }
  // }, 100);

  const handleChangeValue = (val) => {
    if (val?.adCode) {
      setAdItem(val);
      setAd(val);
    }
  };

  return (
    <MyAutocomplete
      sx={sx}
      isOptionEqualToValue={(option, value) => option.adCode === value?.adCode}
      textFieldProps={textFieldProps}
      getOptionLabel={(option) =>
        option?.adNickname ? option.adNickname : ' '
      }
      onChange={(e, val) => handleChangeValue(val)}
      value={ad || (adItem?.adCode && adItem?.adNickname ? adItem : null)}
      options={options}
    />
  );
}
