import { tableTypes } from '@constants/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { formatMoney } from '@utils/helpers';

export const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  overflowY: 'auto'
});
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120
    },
    td: {
      p: '10px 12px',
      width: 270
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const inputStyle = {
  width: '100%',
  margin: '10px 0px'
};

export const memberConfigColumns = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'maskedPhone',
    label: t('pages.member.labels.phoneNumber'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'name',
    label: t('pages.member.labels.memberName'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'email',
    label: t('pages.member.labels.email'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'numberOfPurchases',
    label: t('pages.member.labels.numberOfPurchases'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'couponQuantity',
    label: t('pages.member.labels.couponQuantity'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'totalPaymentAmount',
    label: t('pages.member.labels.totalPaymentAmount'),
    minWidth: 120,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'regDt',
    label: t('pages.member.labels.regDt'),
    minWidth: 130,
    align: 'center',
    formatter: (value) =>
      value
        ? new Date(value).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'lastPurchaseDate',
    label: t('pages.member.labels.lastPurchasedDate'),
    minWidth: 130,
    align: 'center',
    formatter: (value) =>
      value
        ? new Date(value).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'sex',
    label: t('pages.member.labels.sex'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'age',
    label: t('pages.member.labels.age'),
    minWidth: 60,
    align: 'center'
  },
  {
    label: t('pages.member.labels.Management'),
    minWidth: 50,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  }
];

export const conditions = (t) => [
  { value: 'phone', label: t('pages.member.labels.phoneNumber') },
  { value: 'name', label: t('pages.member.labels.memberName') },
  { value: 'sex', label: t('pages.member.labels.sex') },
  { value: 'age', label: t('pages.member.labels.age') },
  { value: 'email', label: t('pages.member.labels.email') }
];

export const memberRFIDConfigColumn = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'clientName',
    label: t('pages.member.labels.clientName'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: t('pages.member.labels.minibarNickname'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'idCardNo',
    label: t('pages.member.labels.RFID'),
    minWidth: 60,
    align: 'center'
  },
  {
    label: t('pages.member.labels.Management'),
    minWidth: 50,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  }
];

export const paymentConfigColumns = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'regDate',
    label: t('pages.member.labels.paymentTime'),
    minWidth: 60,
    align: 'center',
    formatter: (value) =>
      value
        ? new Date(value).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'orderNumber',
    label: t('pages.member.labels.orderNumber'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'clientName',
    label: t('pages.member.labels.clientName'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'kioskLocation',
    label: t('pages.member.labels.kioskLocation'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'productName',
    label: t('pages.member.labels.productName'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'price',
    label: t('pages.member.labels.price'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'quantity',
    label: t('pages.member.labels.quantity'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'totalAmount',
    label: t('pages.member.labels.totalAmount'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'selectedPrice',
    label: t('pages.member.labels.productTotalAmount'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'discount',
    label: t('pages.member.labels.discountAmount'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'totalAmount',
    label: t('pages.member.labels.totalPaymentAmount'),
    minWidth: 60,
    align: 'center',
    formatter: (item) => formatMoney(item)
  }
];
