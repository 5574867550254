import RestService from '@services/RestService';

const objectMapper = (fieldsToKeep, originalObject) => {
  return Object.fromEntries(
    Object.entries(originalObject).filter(([key]) => fieldsToKeep.includes(key))
  );
};
const categoryService = {
  async getCategory() {
    try {
      const res = await new RestService().setPath('/category').get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getCategory2(categoryCode1) {
    try {
      const res = await new RestService()
        .setPath(`category/1/${categoryCode1}/2`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getCategory3(category1Code, category2Code) {
    try {
      const res = await new RestService()
        .setPath(`category/1/${category1Code}/2/${category2Code}/3`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createCategory1(data) {
    try {
      const fieldsToKeep = ['categoryName'];

      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService().setPath(`/category/1`).post(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createCategory2(data, category1Code) {
    try {
      const fieldsToKeep = ['categoryName'];

      console.log('received data: ', data);

      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService()
        .setPath(`/category/1/${category1Code}/2`)
        .post(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createCategory3(data, category1Code, category2Code) {
    try {
      const fieldsToKeep = ['categoryName'];
      console.log('received data: ', data);
      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService()
        .setPath(`/category/1/${category1Code}/2/${category2Code}/3`)
        .post(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editCategory1(data) {
    try {
      const fieldsToKeep = ['categoryName'];

      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService()
        .setPath(`/category/1/${data?.categoryCode}`)
        .put(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editCategory2(data) {
    try {
      const fieldsToKeep = ['categoryName'];
      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService()
        .setPath(`/category/1/${data?.category1Code}/2/${data?.categoryCode}`)
        .put(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editCategory3(data) {
    try {
      const fieldsToKeep = ['categoryName'];
      const sendData = objectMapper(fieldsToKeep, data);
      const res = await new RestService()
        .setPath(
          `/category/1/${data?.category1Code}/2/${data?.category2Code}/3/${data?.categoryCode}`
        )
        .put(sendData);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default categoryService;
