/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, FormControlLabel, Stack } from '@mui/material';
import { sub } from 'date-fns';
import MyTable from '@components/MyTable';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import HelmetContainer from '@components/HelmetContainer';
import DateRangePicker from '@components/DateRangePicker';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import salesPerformServices from '@services/salesPerform';
import { setError } from '@store/reducers/appReducer';
import { columns } from './enhance';

const SalesStatus = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const location = useLocation();
  const dispath = useDispatch();
  const navigate = useNavigate();
  const configColumns = columns;
  const [client, setClient] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    clientCode: null,
    aggregationCode: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(
      ignoreEmpty({
        ...param,
        page: page + 1
      })
    );
    navigate(query);
  };

  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };

  const getSales = async (param) => {
    const rs = await salesPerformServices.getSales(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setClient({ ...client, clientCode: query?.clientCode || null });

    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';

    setParam({
      startDate: formatTime(query?.startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(query?.endDate || new Date(), dateFormat),
      clientCode: query?.clientCode || null,
      aggregationCode: query?.aggregationCode || '',
      page: query?.page - 1 || 0
    });

    getSales({
      startDate: formatTime(query?.startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(query?.endDate || new Date(), dateFormat),
      clientCode: query?.clientCode || null,
      aggregationCode: query?.aggregationCode || null,
      page: query?.page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(
      ignoreEmpty({
        ...param,
        clientCode: param?.clientCode || '',
        aggregationCode: param?.aggregationCode || '',
        page: 1
      })
    );
    getSales({
      ...param,
      clientCode: param?.clientCode || null,
      aggregationCode: param?.aggregationCode || null,
      page: 0
    });
    navigate(query);
  };

  const handleClickRow = (item) => {
    navigate('detail', {
      state: {
        startDate: param?.startDate,
        endDate: param?.endDate,
        clientCode: item?.clientCode
      }
    });
  };
  const handleExport = async () => {
    setLoadingBtn(true);
    const rs = await salesPerformServices.exportSales(param);
    setLoadingBtn(false);
    if (rs?.error) {
      dispath(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer title={t('pageTitle.salesPerform')} content="Sales page">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('pages.product.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                width: 70,
                textAlign: 'center'
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>
        <ClientAutoCompleteV2
          client={client}
          setClient={(value) => {
            setClient(value);
            setParam({ ...param, clientCode: value?.clientCode });
          }}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MyTextField
          label={t('pages.perform.aggregate-client-code')}
          value={param?.aggregationCode}
          onChange={(e) =>
            setParam({ ...param, aggregationCode: e.target.value || null })
          }
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        mb={2}
        mt={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <LoadingButton
          variant="outlined"
          onClick={handleExport}
          loading={loadingBtn}
        >
          {t('button.saveExcel')}
        </LoadingButton>
      </Stack>
      <MyTable
        hover
        rerender
        onRowClick={handleClickRow}
        data={state?.items}
        columns={configColumns}
        pagination={state?.pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default SalesStatus;
