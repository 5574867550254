import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

const TextLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darker,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 13,
  textDecoration: 'underline',
  cursor: 'pointer',
  whiteSpace: 'nowrap'
}));

const InputMultipleFile = ({
  title = 'Upload',
  fileList,
  onChange,
  disabled,
  buttonProps,
  simpleBarProps,
  sx,
  size = 'small',
  ...rest
}) => {
  const buttonRef = useRef(null);
  const [buttonDimension, setButtonDimension] = useState({});
  const [data, setData] = useState();

  useEffect(() => {
    const files = fileList ? [...fileList] : [];
    const newFiles = files?.map((file) => ({
      file,
      url: URL.createObjectURL(file)
    }));
    setData(newFiles);
    return () => newFiles?.map(({ url }) => URL.revokeObjectURL(url));
  }, [fileList]);

  useLayoutEffect(() => {
    setButtonDimension({
      width: buttonRef.current.offsetWidth,
      height: buttonRef.current.offsetHeight
    });
  }, []);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '.simplebar-placeholder': {
          width: 'auto !important'
        },
        ...sx
      }}
      {...rest}
    >
      <SimpleBar
        autoHide
        style={{ width: `calc(100% - ${buttonDimension?.width}px)` }}
        {...simpleBarProps}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: size === 'small' && 52 }}
          spacing={1}
        >
          {data?.length > 0 &&
            data.map(({ file, url }, index) => (
              <TextLink
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                key={url}
              >
                {file.name}
                {index !== data.length - 1 && ','}
              </TextLink>
            ))}
        </Stack>
      </SimpleBar>
      <Button
        variant="outlined"
        component="label"
        sx={
          size === 'small' && {
            height: '36px',
            p: '14px 15px',
            whiteSpace: 'nowrap'
          }
        }
        disabled={disabled}
        ref={buttonRef}
        {...buttonProps}
      >
        {title}
        <input hidden accept="*" type="file" onChange={onChange} multiple />
      </Button>
    </Stack>
  );
};

export default InputMultipleFile;
