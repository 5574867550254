/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMergeState from '@hooks/useMergeState';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';

import { MAX_INT } from '@constants/utils';
import { Formatter } from '@utils/formatter';

import equipmentServices from '@services/equipment';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { rows, tableStyle } from '../enhance';

const numberFields = ['quantity', 'price'];

const maxLengthOf = {
  equipmentName: 200,
  supplier: 40,
  manager: 40,
  managerContact: 40
};

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

const RegisterEqmDialog = ({ open, setOpen, onAction }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useMergeState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useMergeState({
    equipmentName: '',
    quantity: '',
    price: '',
    supplier: '',
    manager: '',
    managerContact: ''
  });

  const handleChange = (name, event) => {
    if (name === 'file') setState({ ...state, [name]: event.target.files[0] });
    else {
      const { value } = event.target;
      const checkValue = numberFields.includes(name)
        ? value.replaceAll(',', '')
        : value;
      setState({ ...state, [name]: checkValue });
    }

    const value = event?.target?.value;
    let msg = '';
    if (name === 'equipmentName' && !value) {
      msg = t('common.required');
    } else if (name === 'additionalInfo' && !value) {
      msg = t('common.required');
    } else if (
      numberFields.includes(name) &&
      value?.replaceAll(',', '') > MAX_INT
    ) {
      msg = t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) });
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };

  const validationOnSubmit = () => {
    let isPassed = true;
    const tempErr = { ...errMsg };
    if (!state?.equipmentName) {
      tempErr.equipmentName = t('common.required');
      isPassed = false;
    }
    Object.values(tempErr).map((err) => {
      if (err) {
        isPassed = false;
      }
      return 0;
    });
    setErrMsg(tempErr);
    return isPassed;
  };

  const handleRegister = async () => {
    setLoading(true);
    const { error } = await equipmentServices.createEquipment({
      ...state,
      quantity: state.quantity || 0,
      price: state.price || 0
    });
    if (error) dispatch(setError(error2Text(error)));
    else {
      onAction('success');
      setOpen(false);
    }

    setLoading(false);
  };

  const resetState = () => {
    setState(
      rows.reduce((prev, { name }) => {
        return {
          ...prev,
          [name]: null
        };
      }, {})
    );
    setErrMsg({
      equipmentName: '',
      quantity: '',
      price: '',
      supplier: '',
      manager: '',
      managerContact: ''
    });
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.equipment.registerEqm')}
      hasCloseButton
      hasCancelButton
      onOk={() => {
        if (validationOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => handleRegister()
            })
          );
        }
      }}
      loading={loading}
      setLoading={setLoading}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            {rows.map(({ label, name, props }) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row">
                  {label}
                  {(name === 'equipmentName' ||
                    name === 'additionalInfo' ||
                    name === 'price') && (
                    <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                  )}
                </TableCell>
                <TableCell>
                  {name === 'file' ? (
                    <InputFile
                      title={label}
                      file={state?.[name]}
                      urlData={{ url: state?.linkFile, name: state?.fileName }}
                      onChange={(event) => handleChange(name, event)}
                      disabled={loading}
                    />
                  ) : name === 'equipmentCode' ? (
                    <MyTextField
                      value={state?.[name] || ''}
                      onChange={(event) => handleChange(name, event)}
                      disabled
                      {...props}
                    />
                  ) : name === 'quantity' || name === 'price' ? (
                    <MyTextField
                      value={Formatter.fNumber(state?.[name])}
                      onChange={(event) => handleChange(name, event)}
                      typeValue="onlyNumber"
                      errMg={errMsg[name]}
                      disabled={loading}
                      {...props}
                    />
                  ) : (
                    <MyTextField
                      value={state?.[name]}
                      onChange={(event) => handleChange(name, event)}
                      errMg={errMsg[name]}
                      disabled={loading}
                      {...props}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default RegisterEqmDialog;
