import i18n from '@utils/i18n';
import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import { Checkbox } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages.consultationInquiry');
const { formatTime } = Formatter;

export const statusRadios = [
  { value: null, label: t('total') },
  { value: 'N', label: t('receipt') },
  { value: 'Y', label: t('complete') }
];

export const columns = [
  { name: '#', label: 'No', align: 'center', minWidth: 60 },
  {
    name: 'companyName',
    label: t('companyName'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'picName',
    label: t('pic'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'regDt',
    label: t('registration-date'),
    align: 'center',
    minWidth: 120,
    render: ({ regDt }) => {
      return formatTime(regDt);
    }
  },
  {
    name: 'enqPurpose',
    label: t('enquiryPurpose'),
    align: 'center',
    minWidth: 100
  },

  {
    name: 'inflowPath',
    label: t('whereFindUs'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'country',
    label: t('country'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'province',
    label: t('province'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'district',
    label: t('district'),
    align: 'center',
    minWidth: 60
  },
  {
    name: 'teamSize',
    label: t('teamSize'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'budget',
    label: t('monthlyBudget'),
    align: 'center',
    minWidth: 80,
    render: (item) => formatMoney(item?.budget)
  },

  {
    name: 'currency',
    label: t('currency'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'languagePreference',
    label: t('languagePreference'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'storageYn',
    label: t('storageYn'),
    align: 'center',
    minWidth: 80,
    render: (item) => (
      <Checkbox checked={item?.storageYn === 'Y'} disableRipple />
    )
  },

  {
    name: 'confirmName',
    label: t('confirm-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'confirmDt',
    label: t('confirm-date'),
    align: 'center',
    minWidth: 100,
    render: ({ confirmDt }) => confirmDt && formatTime(confirmDt)
  },
  {
    name: 'contactYn',
    label: t('inquiry-status'),
    align: 'center',
    minWidth: 100,
    render: ({ contactYn }) =>
      contactYn === 'N' ? t('receipt') : t('complete')
  }
];

export const conditionList = [
  { label: i18n.t('common.companyName'), value: 'companyName' }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
