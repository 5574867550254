/* eslint-disable unused-imports/no-unused-vars */
import { Box, Container, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MySelect from '@components/MySelect';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MyDialog from '@components/MyDialog';
import React, { useState } from 'react';
// import Calendar from '@components/Calendar';
import { clientChannelList } from '@pages/ProductManagement/enhance';
import clientOrderServices from '@services/clientOrder';
import { setError, setSuccess } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import error2Text from '@utils/error2Text';
import { useNavigate } from 'react-router-dom';
// import { Formatter } from '@utils/formatter';
// import moment from 'moment/moment';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const DATE_FORMAT = 'YYYY-MM-DD';
const SUNDAY = 0; // moment day index
const SATURDAY = 6; // moment day index
const WEEKENDS = [SATURDAY, SUNDAY];

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '60%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};
const LocationRegister = ({ open, setOpen, onSuccess }) => {
  const requiredFields = ['channel', 'client'];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({
    channel: '',
    client: '',
    minibar: ''
    // shippingDate: new Date()
  });

  //   function addBusinessDays(stringDate, numberOfDays, dateFormat = DATE_FORMAT) {
  //     const date = moment(stringDate, dateFormat);

  //     let count = 0;

  //     while (count < numberOfDays) {
  //       date.add(1, 'day');

  //       // Skip weekends
  //       if (!WEEKENDS.includes(date.day())) {
  //         count+=1;
  //       }
  //     }

  //     return date.format(dateFormat);
  //   }
  const [errMessage, setErrMessage] = useState({
    channel: '',
    client: ''
    // shippingDate: ''
  });
  const { t } = useTranslation();

  const onDataChange = (name, e) => {
    const { value } = e.target;
    if (name === 'channel') {
      setOrderData({ ...orderData, [name]: value, client: '', minibar: '' });
    } else if (name === 'client') {
      setOrderData({ ...orderData, [name]: value, minibar: '' });
    } else {
      setOrderData({ ...orderData, [name]: value });
    }

    if (requiredFields.includes(name)) {
      setErrMessage({
        ...errMessage,
        [name]: value ? '' : t('common.required')
      });
    }
  };
  const isValidData = () => {
    let isValid = true;

    Object.keys(orderData).forEach((k) => {
      if (requiredFields.includes(k) && !orderData[k]) {
        isValid = false;
      }
    });
    if (orderData?.channel === 'B2C' && !orderData?.minibar?.minibarCode) {
      isValid = false;
    }
    return isValid;
  };
  const handleRegisterOrder = async () => {
    //    data validation
    const dataSubmit = {
      clientChannel: orderData?.channel,
      clientCode: orderData?.client?.clientCode,
      minibarCode: orderData?.minibar?.minibarCode
    };
    const rs = await clientOrderServices.createNewOrder(dataSubmit);
    if (!rs?.error) {
      dispatch(setSuccess({ message: t('info.register.content') }));
      navigate(rs?.data?.orderCode);
      setOpen(false);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCloseButton
      hasCancelButton
      title={t('button.register')}
      okTitle={t('button.register')}
      onOk={handleRegisterOrder}
      disabled={!isValidData()}
      sx={{ width: 480 }}
    >
      <Container component={BorderBox}>
        <FieldContainer label={t('labels.clientChannel')} required>
          <MySelect
            value={orderData?.channel || ''}
            data={clientChannelList}
            onChange={(e) => {
              onDataChange('channel', e);
            }}
            sx={{
              ...inputStyle,
              mb: 1
            }}
          />
        </FieldContainer>
        <FieldContainer label={t('common.client')} required>
          <ClientAutoCompleteV2
            label={false}
            client={orderData?.client}
            setClient={(value) => onDataChange('client', { target: { value } })}
            channel={orderData?.channel}
            //   onKeyPress={({ charCode }) => charCode === 13 && onDataChange('client', )}
            sx={{ ...inputStyle, mb: 1 }}
            disabled={!orderData?.channel}
            // ListboxProps={{ style: { maxHeight: 220 } }}
          />
        </FieldContainer>
        <FieldContainer label={t('common.minibar')}>
          <MinibarAutoCompleteV2
            label={false}
            minibar={orderData?.minibar}
            setMinibar={(value) =>
              onDataChange('minibar', { target: { value } })
            }
            client={orderData?.client}
            disabled={
              !orderData?.client?.clientCode || orderData?.channel !== 'B2C'
            }
            ListboxProps={{ style: { maxHeight: 200 } }}
            sx={{ ...inputStyle, mb: 1 }}
            //   onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
        </FieldContainer>
        {/* <FieldContainer label={t('labels.shippingDate')} required>
        <Calendar
            // eslint-disable-next-line no-unneeded-ternary
            date={orderData?.shippingDate ? new Date(orderData?.shippingDate) : new Date()}
            sx={{
              inputStyle: {
                width: '100%',
              },
             root: { ...inputStyle, mb: 1 }
            }}
            setDate={(e) =>
              onDataChange('shippingDate',{
                target: { value: Formatter.formatTime(e, 'YYYY-MM-DD') }
              })
            }
          />
      </FieldContainer> */}
        {/* <FieldContainer label={t('pages.inventory.bay')} required>
          <MyTextField
            size="small"
            placeholder={t('pages.inventory.placeholder.bay')}
            name="locationBay"
            sx={inputStyle}
          />
        </FieldContainer>
        <FieldContainer label={t('pages.inventory.bin')} required>
          <MyTextField
            size="small"
            placeholder={t('pages.inventory.placeholder.bin')}
            name="locationBin"
            sx={{ ...inputStyle, mb: 1 }}
          />
        </FieldContainer> */}
        {/* <FieldContainer label={t('pages.inventory.property')} required>
        <MySelect
          sx={{ ...inputStyle, mb: 1 }}
          name="property"
          data={propertyOptions}
          value={locationProperty}
          onChange={(e) =>
            onChange({
              target: { name: 'locationProperty', value: e.target.value }
            })
          }
        />
      </FieldContainer> */}
      </Container>
    </MyDialog>
  );
};

const FieldContainer = ({ label, required, children }) => {
  return (
    <Stack direction="row">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          pb: 1,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 160,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && (
            <span
              style={{
                color: 'red',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {' '}
              *
            </span>
          )}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default LocationRegister;
