/* eslint-disable no-restricted-syntax */
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  IconButton
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import React, { useEffect, useState } from 'react';
import { tableTypes } from '@constants/utils';
import { userServices } from '@services/index';
import { downloadFileFromBinary } from '@utils/downloadFile';
import NoData from '@components/NoData';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});
export const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1A1F27',
    color: '#FFFFFF',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'pre-line',
    border: '1px solid #dadde9'
  }
}));

const LinkCell = ({ fileLink, fileName, children, sx, ...rest }) => {
  return (
    <TableCell
      sx={{
        color: 'primary.darker',
        fontWeight: 'fontWeightBold',
        fontSize: 13,
        textDecoration: 'underline',
        ...sx
      }}
      {...rest}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
          userServices.getFile({ fileName, fileLink }).then(({ data }) => {
            if (data) downloadFileFromBinary(data, fileName);
          });
        }}
        variant="text"
      >
        {children}
      </Box>
    </TableCell>
  );
};
export const ToolTipContent = ({ title, content }) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap={0.5}
      >
        <ErrorOutlineIcon sx={{ width: '16px', height: '16px' }} />
        <Typography fontSize="13px" fontWeight="bold">
          {' '}
          {title}
        </Typography>
      </Box>

      <Typography fontSize="10px" my={1}>
        {content}
      </Typography>
    </Box>
  );
};
export const TooltipContainer = ({ title, content }) => {
  return (
    <CustomToolTip
      title={<ToolTipContent title={title} content={content} />}
      placement="top"
      arrow
    >
      <IconButton>
        <HelpOutlineOutlinedIcon sx={{ width: '18px', height: '18px' }} />
      </IconButton>
    </CustomToolTip>
  );
};

const TableCellsContainer = ({
  index,
  columns,
  item,
  getActionButtons,
  getModalButton,
  sx
}) => {
  return columns.map(({ name, type, actionType, formatter, icon, label }) => {
    const format = formatter ? formatter(item?.[name]) : item?.[name];
    const nameFormat =
      item?.[name] !== undefined && item?.[name] !== null ? format : '';
    switch (type) {
      case tableTypes.FILE:
        return (
          <LinkCell
            fileLink={item?.linkFile}
            key={`${item?.id}${name}`}
            fileName={item?.fileName}
          >
            {item?.fileName}
          </LinkCell>
        );
      case tableTypes.ACTION:
        return (
          <TableCell key={`${item?.id}action${actionType}`} sx={{ px: 1 }}>
            {getActionButtons(item, actionType, index)}
          </TableCell>
        );
      case tableTypes.MODAL:
        return (
          <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
            {getModalButton(item)}
          </TableCell>
        );
      case tableTypes.CHECK_MARK:
        return (
          <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
            {item?.[name]?.toUpperCase() === 'Y' ? icon : ''}
          </TableCell>
        );
      default: {
        const render = columns.filter(({ name: n }) => n === name)[0]?.render;
        return (
          <TableCell
            key={`${item?.id}${name}${label}`}
            sx={{
              color: 'text.primary',
              fontSize: 13,
              padding: '10px !important',
              ...sx?.font
            }}
          >
            {name === '#' && index + 1}
            {render ? render(item) : nameFormat}
          </TableCell>
        );
      }
    }
  });
};

const GroupMultiHeaderTable = ({
  hover,
  onRowClick,
  getActionButtons,
  getModalButton,
  columns,
  data,
  pagination,
  emptyDescription,
  totalRow,
  maxHeight,
  minusHeight = 350,
  onChangePage
}) => {
  const handleRowClick = (item) => {
    if (onRowClick && !window.getSelection().toString()) onRowClick(item);
  };
  const newCols = [];
  columns.forEach((col) => {
    if (col?.children) {
      col?.children?.forEach((child) => {
        newCols.push(child);
      });
    } else {
      newCols.push(col);
    }
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const scrollToRow = () => {
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        id="table-container"
        component={BorderBox}
        sx={{ mt: '20px', mb: '20px' }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
        }}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '35px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow sx={{ position: 'sticky', top: 0 }}>
              {columns.map((col) => (
                <TableCell
                  key={`${col?.name}${col?.label}`}
                  rowSpan={!col?.children && '2'}
                  colSpan={col?.children && col?.children?.length}
                  align={col.align}
                  sx={{
                    minWidth: col.minWidth,
                    whiteSpace: 'pre-line',
                    paddingTop: col.children && '5px !important'
                  }}
                >
                  {col.label}
                  {col.hasToolTip && (
                    <TooltipContainer
                      title={col.toolTipTitle}
                      content={col.toolTipContent}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ position: 'sticky !important', top: 35 }}>
              {columns.map((col) => {
                return col?.children
                  ? col?.children?.map((child) => (
                      <TableCell
                        key={`${child?.name}${child?.label}`}
                        align={child.align}
                        sx={{
                          minWidth: child.minWidth,
                          whiteSpace: 'pre-line',
                          paddingTop: col.children && '5px !important',
                          fontSize: '12px !important',
                          '&:last-child': {
                            borderRight: '1px solid #EEEEEE'
                          }
                        }}
                      >
                        {child.label}{' '}
                        {child.hasToolTip && (
                          <TooltipContainer
                            title={child.toolTipTitle}
                            content={child.toolTipContent}
                          />
                        )}
                      </TableCell>
                    ))
                  : null;
              })}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                height: 100,
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: 1.5,
                  height: '54px',
                  lineHeight: '22px'
                },
                position: 'relative',
                zIndex: 0
              }}
            >
              {data?.map((item, index) => (
                <TableRow
                  key={item?.id || `${item?.id}${index}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    'td:not(:last-child), th:not(:last-child)': {
                      borderRight: '1px solid #EEEEEE'
                    },
                    ':hover': hover && {
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => handleRowClick(item)}
                >
                  <TableCellsContainer
                    index={index}
                    item={item}
                    columns={newCols}
                    getActionButtons={getActionButtons}
                    getModalButton={getModalButton}
                  />
                </TableRow>
              ))}
              {totalRow &&
                [totalRow].map((item, index) => (
                  <TableRow
                    key={item?.id || `${item?.id}${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: 'pointer',
                      zIndex: 10,
                      position: 'sticky',
                      bottom: 0
                    }}
                    onClick={() => handleRowClick(item)}
                  >
                    <TableCellsContainer
                      sx={{
                        font: {
                          fontWeight: 'bold',
                          color: 'black !important'
                        }
                      }}
                      index={index}
                      item={item}
                      columns={newCols}
                      getActionButtons={getActionButtons}
                      getModalButton={getModalButton}
                    />
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data?.length > 0 && pagination && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {loading && (
            <CircularProgress
              size={22}
              sx={{ position: 'absolute', left: '50%', right: '50%' }}
            />
          )}
          <Pagination
            disabled={loading}
            count={pagination?.count || 1}
            variant="outlined"
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            page={pagination.page + 1}
            onChange={(e, page) => {
              if (page !== pagination.page + 1) {
                setLoading(true);
                setTimeout(() => {
                  scrollToRow();
                }, 500);
                onChangePage(e, page - 1);
              }
            }}
          />
        </Stack>
      )}
    </Box>
  );
};

export default GroupMultiHeaderTable;
