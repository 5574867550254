import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow, Typography } from '@mui/material';
import { Formatter } from '@utils/formatter';
import GeoMapDialog from '@components/GeoMapDialog';
import LightBoxImage from '@components/LightBoxImage';
import { daySpace, dayOfWeek } from '@utils/helpers';

const imageStyle = { width: '80px', height: '80px', marginTop: '4px' };

const DisplayTableRow = ({ item }) => {
  const [t] = useTranslation();
  const { formatTime } = Formatter;
  return (
    <TableRow>
      <TableCell>{item?.picName}</TableCell>
      <TableCell>{item?.clientName}</TableCell>
      <TableCell>{dayOfWeek(item?.displayDay)}</TableCell>
      <TableCell>
        <GeoMapDialog
          item={{
            ...item,
            latitude:
              item?.afterDisplayingLatitude || item?.beforeDisplayingLatitude,
            longitude:
              item?.afterDisplayingLongitude || item?.beforeDisplayingLongitude
          }}
          dialogTitle={t('pages.display.displayLocationInfomation')}
        >
          {(item?.afterDisplayingCompleteDt && (
            <>
              <Typography>{item?.afterDisplayingLatitude}</Typography>
              <Typography>{item?.afterDisplayingLongitude}</Typography>
            </>
          )) ||
            (item?.beforeDisplayingDt && (
              <>
                <Typography>{item?.beforeDisplayingLatitude}</Typography>
                <Typography>{item?.beforeDisplayingLongitude}</Typography>
              </>
            )) ||
            null}
        </GeoMapDialog>
      </TableCell>
      <TableCell>
        {item?.beforeDisplayingDt && formatTime(item?.beforeDisplayingDt)}
      </TableCell>
      <TableCell>
        {item?.afterDisplayingCompleteDt &&
          formatTime(item?.afterDisplayingCompleteDt)}
      </TableCell>
      <TableCell>
        {item?.beforeDisplayingDt &&
          item?.afterDisplayingCompleteDt &&
          daySpace(
            item?.beforeDisplayingDt,
            item?.afterDisplayingCompleteDt
              ? item?.afterDisplayingCompleteDt
              : new Date()
          )}
      </TableCell>
      <TableCell>
        <LightBoxImage
          fileLink={item?.beforeImageUrl[0]}
          fileName={item?.beforeImageUrl[0]}
          index={0}
          apiImages={item?.beforeImageUrl}
          sx={imageStyle}
        />
      </TableCell>
      <TableCell>
        <LightBoxImage
          fileLink={item?.afterImageUrl[0]}
          fileName={item?.afterImageUrl[0]}
          index={0}
          apiImages={item?.afterImageUrl}
          sx={imageStyle}
        />
      </TableCell>
    </TableRow>
  );
};

export default DisplayTableRow;
