/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
import MyAutocomplete from '@components/MyAutocomplete';
import MyDialog from '@components/MyDialog/new';
import SearchBar from '@components/SearchBar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import MyTextField from '@components/MyTextField';
import categoryService from '@services/pre-order/category';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { BorderBox, tableStyle } from '../enhance';

const OptionsManagementDialog = ({ open, setOpen, onSuccess, ...rest }) => {
  const [store, setStore] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({ optionName: '', selectValue: '' });
  const [optionList, setOptionList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [actionsDisplayStatus, setActionDisplayStatus] = useState({
    optionName: false,
    selectValue: false
  });
  const [errMsg, setErrMsg] = useState({
    optionName: ''
  });
  const isValidToSubmit = () => {
    let isValid = true;
    if (!store) isValid = false;

    return isValid;
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
    setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
  };

  const handleAddNewOption = (fieldType) => {
    if (!fieldType) return;
    if (!inputs?.[fieldType]) {
      setErrMsg({ ...errMsg, [fieldType]: t('common.required') });
      return;
    }
    if (
      fieldType === 'optionName' &&
      optionList?.filter((i) => i?.fieldName === inputs.optionName).length > 0
    ) {
      setErrMsg({
        ...errMsg,
        optionName: t('pages.pre-order.error-msg.duplicate-options')
      });
      return;
    }

    if (
      fieldType === 'selectValue' &&
      selectedOption?.options?.filter(
        (i) => i?.optionName === inputs.selectValue
      ).length > 0
    ) {
      setErrMsg({
        ...errMsg,
        selectValue: t('pages.pre-order.error-msg.duplicate-options')
      });
      return;
    }
    if (fieldType === 'optionName')
      setOptionList((prev) => [
        {
          productInfoFieldCode: prev.length,
          fieldName: inputs.optionName,
          isSelectable: false,
          isEditable: true
        },
        ...prev
      ]);
    else {
      setSelectedOption((prev) => {
        const newOptionsList = prev?.options
          ? [{ optionName: inputs.selectValue }, ...prev.options]
          : [{ optionName: inputs.selectValue }];

        return { ...prev, options: newOptionsList };
      });
      setOptionList((prev) => {
        const newList = prev?.map((item) => {
          if (
            item?.productInfoFieldCode === selectedOption.productInfoFieldCode
          ) {
            const newOptionsList = item?.options
              ? [inputs.selectValue, ...item.options]
              : [inputs.selectValue];
            return { ...item, options: newOptionsList };
          }
          return item;
        });
        return newList;
      });
    }
    setActionDisplayStatus({ ...actionsDisplayStatus, [fieldType]: false });
    setInputs({ ...inputs, [fieldType]: '' });
  };

  const handleChangeRadio = (e) => {
    if (!selectedOption) return;

    const value = e.target.value === 'true';
    setSelectedOption({
      ...selectedOption,
      isSelectable: value,
      options: !value ? null : selectedOption?.options
    });

    setOptionList((prev) => {
      const updatedList = prev.map((i) => {
        if (i?.productInfoFieldCode === selectedOption.productInfoFieldCode) {
          return {
            ...i,
            isSelectable: value,
            options: !value ? null : i.options
          };
        }
        return i;
      });
      return updatedList;
    });
    if (!value) {
      setInputs({ ...inputs, selectValue: '' });
    }
  };

  const handleChangeSelectedOption = (item) => {
    if (
      selectedOption &&
      selectedOption?.isSelectable &&
      selectedOption?.options?.length > 0
    ) {
      setOptionList((prev) => {
        const updatedList = prev.map((i) => {
          if (i.productInfoFieldCode === selectedOption?.productInfoFieldCode)
            return { ...i, isSelectable: false, options: [] };
          return i;
        });
        return updatedList;
      });
      setInputs({ ...inputs, selectValue: '' });
    }
    setSelectedOption(item);
  };

  const getStorelist = async () => {
    const rs = await categoryService.getStoreList();
    if (rs?.data) setStoreList(rs?.data || []);
  };

  const getStoreOptions = async (storeCode) => {
    if (!storeCode) return;
    const rs = await categoryService.getStoreOptions(storeCode);
    if (!rs?.error) {
      const updatedOptionsList = rs?.data?.map((i) => ({
        ...i,
        isEditable: false
      }));
      setOptionList(updatedOptionsList || []);
    }
  };
  const handleSubmitOptions = async () => {
    if (!store) {
      return;
    }
    const dataSubmit = {
      infoFields: optionList?.map((i) => {
        if (i.isEditable) {
          return {
            fieldName: i.fieldName,
            isSelectable: i.isSelectable,
            ...(i.isSelectable && { options: i.options })
          };
        }
        return {
          productInfoFieldCode: i.productInfoFieldCode,
          fieldName: i.fieldName,
          isSelectable: i.isSelectable,
          ...(i.isSelectable && {
            options: i.options?.map((i) => i?.optionName)
          })
        };
      })
    };
    const rs = await categoryService.createStoreOptions(
      store.storeTypeCode,
      dataSubmit
    );
    if (rs.error) {
      dispatch(setError(error2Text(rs.error)));
    } else {
      dispatch(setSuccess({ message: t('info.success.content') }));
      onSuccess();
      setOpen();
    }
  };
  // Reset state when component mounts
  useEffect(() => {
    setStore(null);
    setStoreList([]);
    setInputs({ optionName: '', selectValue: '' });
    setOptionList([]);
    setSelectedOption(null);
    setErrMsg({ optionName: '' });
    setActionDisplayStatus({
      optionName: false,
      selectValue: false
    });
    setErrMsg({});
  }, [open]);

  useEffect(() => {
    getStorelist();
  }, []);

  useEffect(() => {
    if (store) getStoreOptions(store?.storeTypeCode);
  }, [store]);
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      onOk={handleSubmitOptions}
      okTitle={t('button.register')}
      onCancel={() => setOpen(false)}
      title={t('pages.pre-order.buttons.options')}
      hasCancelButton
      hasCloseButton
      sx={{ minWidth: '1042px', height: '100%', display: 'flex' }}
      disabled={!isValidToSubmit()}
      {...rest}
    >
      <SearchBar sx={{ borderRadius: 0, width: '100%' }}>
        <MyAutocomplete
          required
          label={t('pages.pre-order.industry')}
          isOptionEqualToValue={(option, value) =>
            option.storeTypeCode === value?.storeTypeCode
          }
          getOptionLabel={(option) =>
            option?.typeName ? option.typeName : ' '
          }
          ListboxProps={{ style: { maxHeight: 200 } }}
          onChange={(e, val) => setStore(val)}
          value={store}
          options={storeList || []}
        />
      </SearchBar>
      <Stack sx={{ width: '100%', display: 'flex', mt: 2.5, flexGrow: 1 }}>
        <TableContainer
          component={BorderBox}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Table sx={{ transition: 'all 300ms ease-in-out' }}>
            <TableBody
              sx={{ ...tableStyle, transition: 'all 300ms ease-in-out' }}
            >
              <TableRow sx={{ transition: 'all 300ms ease-in-out' }}>
                <TableCell
                  component="th"
                  scope="row"
                  colSpan={2}
                  sx={{ textAlign: 'left' }}
                >
                  <Button
                    variant="text"
                    disabled={!store}
                    sx={{
                      backgroundColor: '#FFFFFF',

                      fontSize: 13,
                      borderRadius: 1,
                      boxShadow: 0,
                      border: '1px solid #E5E7EB !important',
                      color: '#353D4A',
                      ':hover': {
                        boxShadow: 0,
                        backgroundColor: '#e9e9e9',
                        color: '#353D4A'
                      },
                      height: { md: '40px', xs: '22px' },
                      minWidth: { md: '110px', xs: 'auto' }
                    }}
                    onClick={() =>
                      setActionDisplayStatus({
                        ...actionsDisplayStatus,
                        optionName: true
                      })
                    }
                  >
                    <ControlPointIcon
                      sx={{ mr: 0.5, width: '18px', height: '18px' }}
                    />

                    {t('pages.pre-order.buttons.options-create')}
                  </Button>
                </TableCell>
                {/* <TableCell>
                <MyTextField
                //   name="kioskNickname"
                //   value={kiosk?.kioskNickname}
                //   placeholder={t('pages.kiosk.labels.nickname')}
                //   onChange={(e) => handleChangeInput(e)}
                //   errMg={errMsg.kioskNickname}
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell> */}
              </TableRow>

              {/* input row */}
              {actionsDisplayStatus.optionName && (
                <TableRow>
                  <TableCell
                    component="td"
                    scope="row"
                    sx={{
                      textAlign: 'left',
                      width: '50% !important',
                      borderRight: '1px solid #EEEEEE'
                    }}
                    colSpan={2}
                  >
                    {actionsDisplayStatus?.optionName && (
                      <MyTextField
                        errMg={errMsg?.optionName}
                        name="optionName"
                        value={inputs.optionName}
                        onChange={handleChangeInput}
                        sx={{ width: '450px' }}
                        textFieldStyle={{
                          ...(!store && {
                            backgroundColor: '#D9D9D9',

                            color: '#111111 !important'
                          })
                        }}
                        disabled={!store}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant="contained"
                                disabled={!store || !inputs.optionName}
                                onClick={() => handleAddNewOption('optionName')}
                                size="small"
                                sx={{ height: 25, boxShadow: 0 }}
                              >
                                {t('pages.curation.apply')}
                              </Button>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Stack
            sx={{
              width: '100%',

              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row'
            }}
          >
            {/* options list */}
            <Stack
              sx={{
                height: '100%',
                flexGrow: 1,
                width: '50%',

                borderTop: '1px solid #EEEEEE',
                borderRight: '1px solid #EEEEEE'
              }}
            >
              {optionList?.length > 0 &&
                optionList?.map((item, index) => (
                  <Stack
                    key={`${item?.id}-${item?.fieldName}`}
                    sx={{
                      py: 1,
                      px: 1.5,
                      height: '59px',
                      cursor: 'pointer',
                      justifyContent: 'center',
                      backgroundColor:
                        item?.productInfoFieldCode ===
                        selectedOption?.productInfoFieldCode
                          ? 'primary.lighter'
                          : '',
                      ':hover': {
                        backgroundColor: 'secondary.lighter'
                      },
                      borderBottom:
                        index !== optionList.length - 1 && '1px solid #EEEEEE'
                    }}
                    onClick={() => handleChangeSelectedOption(item)}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        color:
                          item?.productInfoFieldCode ===
                          selectedOption?.productInfoFieldCode
                            ? 'primary.main'
                            : '#212121',
                        fontWeight:
                          item?.productInfoFieldCode ===
                          selectedOption?.productInfoFieldCode
                            ? 600
                            : 400
                      }}
                    >
                      {item?.fieldName}
                    </Typography>
                  </Stack>
                ))}
            </Stack>

            {/* radio select tab */}

            <Stack
              sx={{
                width: '50%',

                borderTop: '1px solid #EEEEEE'
                // borderRight: '1px solid #EEEEEE'
              }}
            >
              {selectedOption && (
                <Stack
                  sx={{
                    py: 1,
                    px: 1.5,

                    borderBottom: '1px solid #EEEEEE'
                  }}
                >
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      sx={{
                        mr: 4,
                        color: '#212121 !important',
                        fontWeight: 500
                      }}
                    >
                      {t('pages.pre-order.modal.feature')}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="options-radio-buttons-group-label"
                      row
                      value={String(selectedOption?.isSelectable)} // Use string representation for the value
                      onChange={(e) => handleChangeRadio(e)}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        disabled={
                          !selectedOption || !selectedOption?.isEditable
                        }
                        value={String(false)} // Use string "false"
                        control={<Radio />}
                        label={t('pages.pre-order.modal.custom-input')}
                      />
                      <FormControlLabel
                        disabled={
                          !selectedOption || !selectedOption?.isEditable
                        }
                        value={String(true)} // Use string "true"
                        control={<Radio />}
                        label={t('pages.pre-order.modal.select-option')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              )}
              {selectedOption && selectedOption?.isSelectable && (
                <>
                  {selectedOption?.isEditable &&
                    selectedOption?.isSelectable && (
                      <Stack
                        sx={{
                          py: 1,
                          px: 1.5,

                          borderBottom: '1px solid #EEEEEE'
                        }}
                      >
                        <MyTextField
                          errMg={errMsg?.selectValue}
                          name="selectValue"
                          value={inputs.selectValue}
                          onChange={handleChangeInput}
                          fullWidth
                          sx={{ width: '100%' }}
                          textFieldStyle={{
                            ...(!store && {
                              backgroundColor: '#D9D9D9',

                              color: '#111111 !important'
                            })
                          }}
                          disabled={!store}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  disabled={!store || !inputs.selectValue}
                                  onClick={() =>
                                    handleAddNewOption('selectValue')
                                  }
                                  size="small"
                                  sx={{ height: 25, boxShadow: 0 }}
                                >
                                  {t('pages.curation.apply')}
                                </Button>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Stack>
                    )}
                  {selectedOption?.options?.length > 0 &&
                    selectedOption?.options.map((i, index) => (
                      <Stack
                        key={`${i?.id}-${index}`}
                        sx={{
                          py: 1,
                          px: 2.5,

                          borderBottom:
                            index !== selectedOption.options.length - 1 &&
                            '1px solid #EEEEEE'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 15,
                            color: '#212121',
                            fontWeight: 400
                          }}
                        >
                          {i?.optionName}
                        </Typography>
                      </Stack>
                    ))}
                </>
              )}
            </Stack>
          </Stack>
        </TableContainer>
      </Stack>

      {/* Table */}
    </MyDialog>
  );
};

export default OptionsManagementDialog;
