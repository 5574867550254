import { formatMoney } from '@utils/helpers';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const s = i18n.getFixedT(null, null, 'pages.statistics');

export const clientDatacolumns = [
  {
    name: 'minibarNickname',
    label: s('minibar'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'sales',
    label: s('sales-amount'),
    align: 'center',
    children: [
      {
        name: 'currentSaleTotal',
        label: s('total-for-the-current-month'),
        align: 'center',
        minWidth: 100,

        formatter: (item) => formatMoney(item)
      },
      {
        name: 'periodPreMonthSaleTotal',
        label: s('same-period-of-the-previous-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'compareSale',
        label: s('compared-to-the-previous-month'),
        align: 'center',
        minWidth: 100
      },
      {
        name: 'preMonthSaleTotal',
        label: s('total-from-the-previous-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'purchase',
    label: s('purchase-count'),
    align: 'center',
    children: [
      {
        name: 'currentPurchaseTotal',
        label: s('total-for-the-current-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'periodPreMonthPurchaseTotal',
        label: s('same-period-of-the-previous-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'comparePurchase',
        label: s('compared-to-the-previous-month'),
        align: 'center',
        minWidth: 100
      },
      {
        name: 'preMonthPurchaseTotal',
        label: s('total-from-the-previous-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  }
];

export const monthlyDataColumns = [
  {
    name: 'sales',
    label: s('date'),
    align: 'center',
    children: [
      {
        name: 'year',
        label: s('year'),
        align: 'center',
        width: 200,
        formatter: (item) => Formatter.formatTime(item, 'YYYY')
      },
      {
        name: 'month',
        label: s('month'),
        align: 'center',
        Width: 200,
        formatter: (item) => Formatter.formatTime(item, 'MM')
      }
    ]
  },
  {
    name: 'memberCost',
    label: s('salePrice'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('salePrice'),
    toolTipContent: s('toolTip.content.memberSalesAmount'),
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'memberTransactionCount',
    label: s('salesQuantity'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('salesQuantity'),
    toolTipContent: s('toolTip.content.memberSalesQuantity'),
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'totalCost',
    label: s('totalSalesAmount'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('totalSalesAmount'),
    toolTipContent: s('toolTip.content.memberSalesTotal'),
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'totalTransactionCount',
    label: s('totalSalesNumber'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('totalSalesNumber'),
    toolTipContent: s('toolTip.content.memberSalesTotalNumber'),
    formatter: (item) => formatMoney(item)
  }
];

export const memberColumns = [
  {
    name: 'date',
    label: s('date'),
    align: 'center',
    children: [
      {
        name: 'month',
        label: s('year'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => Formatter.formatTime(item, 'YYYY')
      },
      {
        name: 'month',
        label: s('month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => Formatter.formatTime(item, 'MM')
      }
    ]
  },
  {
    name: 'membersMembers',
    label: s('totalMembers'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('totalMembers'),
    toolTipContent: s('toolTip.content.totalMembersRegistered'),
    children: [
      {
        name: 'accumulatedMemberOffresh',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'accumulatedMemberSpot',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'accumulatedSum',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseInt(item?.accumulatedMemberOffresh, 10) +
              parseInt(item?.accumulatedMemberSpot, 10)
          )
      }
    ]
  },
  {
    name: 'nonMembersMemebers',
    label: s('members'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('members'),
    toolTipContent: s('toolTip.content.membersRegisteredLastMonth'),
    children: [
      {
        name: 'newMemberOffresh',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'newMemberSpot',

        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'newMemberSum',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseInt(item?.newMemberOffresh, 10) +
              parseInt(item?.newMemberSpot, 10)
          )
      }
    ]
  },
  {
    name: 'totalRatio',
    label: s('usageAmount'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('usageAmount'),
    toolTipContent: s('toolTip.content.memberSalesAmount'),
    children: [
      {
        name: 'totalPurchaseOffresh',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'totalPurchaseSpot',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'totalRatioSum',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseFloat(item?.totalPurchaseOffresh) +
              parseFloat(item?.totalPurchaseSpot)
          )
      }
    ]
  },
  {
    name: 'totalCount',
    label: s('usageAmountByPerson'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('usageAmountByPerson'),
    toolTipContent: s('toolTip.content.memberSalesRatio'),
    children: [
      {
        name: 'averagePurchaseOffresh',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'averagePurchaseSpot',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'averageSum',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseFloat(item?.totalPurchaseOffresh) +
              parseFloat(item?.totalPurchaseSpot) /
                (parseInt(item?.accumulatedMemberOffresh, 10) +
                  parseInt(item?.accumulatedMemberSpot, 10))
          )
      }
    ]
  }
];

export const purchasesColumns = [
  {
    name: 'dates',
    label: s('date'),
    align: 'center',
    children: [
      {
        name: 'year',
        label: s('year'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => Formatter.formatTime(item, 'YYYY')
      },
      {
        name: 'month',
        label: s('month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => Formatter.formatTime(item, 'MM')
      }
    ]
  },
  {
    name: 'members',
    label: s('memberSalesQuantity'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('memberSalesQuantity'),
    toolTipContent: s('toolTip.content.memberSalesQuantity'),
    children: [
      {
        name: 'memberOffreshCount',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'memberSpotCount',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'memberCount',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'nonmemberss',
    label: s('nonMemberSalesAmount'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('nonMemberSalesAmount'),
    toolTipContent: s('toolTip.content.nonMemberSalesQuantity'),
    children: [
      {
        name: 'nonPurchaseOffreshCount',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'nonMemberSpotCount',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'nonMemberCount',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'totalRatiosss',
    label: s('sum'),

    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('sum'),
    toolTipContent: s('toolTip.content.totalAllMembersSales'),

    children: [
      {
        name: 'currentSaleTotal',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseInt(item?.memberOffreshCount, 10) +
              parseInt(item?.nonPurchaseOffreshCount, 10)
          )
      },
      {
        name: 'periodPreMonthSaleTotal',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseInt(item?.memberSpotCount, 10) +
              parseInt(item?.nonMemberSpotCount, 10)
          )
      },
      {
        name: 'compareSalesssss',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        render: (item) =>
          formatMoney(
            parseInt(item?.memberCount, 10) + parseInt(item?.nonMemberCount, 10)
          )
      }
    ]
  },
  {
    name: 'totalQuantitysss',
    label: s('perPerson'),
    align: 'center',
    hasToolTip: true,
    toolTipTitle: s('perPerson'),
    toolTipContent: s('toolTip.content.allMembersSalesQuantityRatio'),
    children: [
      {
        name: 'memberOffreshCountMonthRatio',
        label: 'Offresh',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'memberSpotCountMonthRatio',
        label: 'Spot',
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'memberCountMonthRatio',
        label: s('sum'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  }
];
