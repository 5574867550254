import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SearchBar from '@components/SearchBar';
import MyTable from '@components/MyTable';
import storeService from '@services/pre-order/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { setError, setSuccess } from '@store/reducers/appReducer';
import AlertDialog from '@components/MyDialog/AlertDialog';
import categoryService from '@services/pre-order/category';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { categoryColumns, productColumns } from '../enhance';

const StoreProductsPage = () => {
  const [storeList, setStoreList] = useState([]);
  const [storeProductList, setStoreProductList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [storeData, setStoreData] = useState({
    storeType: null,
    store: null
  });
  const [categoryList, setCategoryList] = useState([]);
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const storeCode = pathname[pathname.length - 1];
  const [alertDialog, setAlert] = useState({
    open: false,
    action: null,
    onOk: null,
    textFieldName: '',
    textFieldValue: ''
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getStoreTypelist = async () => {
    const rs = await storeService.getStoreTypeList();
    if (rs?.data) {
      setStoreList(rs?.data || []);
    }
  };

  const getStoreCategoryList = async (code, storeCode) => {
    const rs = await categoryService.getStoreProductList(code, { storeCode });
    if (rs?.data) {
      setCategoryList(rs?.data || []);
    }
  };
  const getStoreDetail = async () => {
    if (!location?.pathname?.includes('register')) {
      const rs = await storeService.getStoreDetail(storeCode);

      setStoreData({
        ...storeData,
        ...rs?.data
      });
      if (rs?.data?.storeTypeCode)
        getStoreCategoryList(rs?.data?.storeTypeCode, rs?.data?.storeCode);
    }
  };

  const getStorepProductList = async (storeCode, categoryCode) => {
    const rs = await storeService.getStoreProductList(storeCode, {
      productCategoryCode: categoryCode
    });
    setStoreProductList(rs?.data || []);
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();

              navigate(`product/${item?.productCode}`, {
                state: {
                  action: 'modification',
                  storeTypeName: storeList?.find(
                    (i) => i?.storeTypeCode === storeData?.storeTypeCode
                  )?.typeName,
                  storeName: storeData?.storeName,
                  ...item
                }
              });
            }}
          >
            <EditIcon />
          </IconButton>
        );

      default:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item); // Make sure the selected item is set here
              setAlert({
                ...alertDialog,
                open: true
                // Pass item to handleDeleteStore
              });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { productCategoryCode } = query;

    setSelectedCategoryType({ productCategoryCode });
    getStoreDetail();
    if (productCategoryCode)
      getStorepProductList(storeCode, productCategoryCode);
  }, [location?.search]);

  useEffect(() => {
    getStoreTypelist();
  }, []);

  const handleSelectCategory = (item) => {
    setSelectedCategoryType(item);
    const query = paramsToObj(location?.search);

    const searchQuery = objToParams(
      ignoreEmpty({ ...query, productCategoryCode: item?.productCategoryCode })
    );
    getStorepProductList(storeCode, item?.productCategoryCode);
    navigate(searchQuery);
  };

  const onSubmit = async (actionType) => {
    if (!selectedItem) return;
    if (actionType === 'delete') {
      const rs = await storeService?.deleteStoreProduct(
        storeCode,
        selectedItem?.productCode
      );
      if (!rs?.error) {
        getStoreCategoryList(storeData?.storeTypeCode, storeCode);
        getStorepProductList(
          storeCode,
          selectedCategoryType?.productCategoryCode
        );
        dispatch(setSuccess({ message: t('info.delete.content') }));
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
      setAlert({
        open: false,
        action: null,
        onOk: null,
        textFieldName: '',
        textFieldValue: ''
      });
    }
  };

  const handleCreateTemporaryProduct = async () => {
    if (!storeData?.storeCode) return;
    const rs = await storeService.createTemporaryProduct(storeData?.storeCode, {
      storeTypeCode: storeData?.storeTypeCode,
      productCategoryCode: selectedCategoryType?.productCategoryCode
    });
    if (!rs?.error) {
      const path = rs?.location?.split('/');
      const newProductCode = path[path.length - 1];
      navigate(`product/${newProductCode}`, {
        state: {
          ...storeData,
          storeTypeName: storeList?.find(
            (i) => i?.storeTypeCode === storeData?.storeTypeCode
          )?.typeName,
          productCategoryCode: selectedCategoryType?.productCategoryCode,
          action: 'register'
        }
      });
    }
  };
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%', // Ensure the parent has a defined height
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
      }}
    >
      <SearchBar sx={{ px: 5, gap: 20 }}>
        <Typography>
          {t('pages.pre-order.industry')}:{' '}
          <span style={{ marginLeft: '4px', fontWeight: 500 }}>
            {
              storeList?.find(
                (i) => i?.storeTypeCode === storeData?.storeTypeCode
              )?.typeName
            }
          </span>
        </Typography>
        <Typography>
          {t('pages.pre-order.store')}:{' '}
          <span style={{ marginLeft: '4px', fontWeight: 500 }}>
            {' '}
            {storeData?.storeName}
          </span>
        </Typography>
      </SearchBar>
      <Stack
        sx={{
          width: '100%',
          height: '100%', // Ensure the parent has a defined height
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'stretch' // Add this to make sure children stretch to full height
        }}
      >
        {' '}
        <Stack
          sx={{
            width: 'auto',
            minWidth: '30%',
            height: '100%',
            border: '1px solid #D3D3D3',
            borderRadius: 1,
            mr: 2,
            backgroundColor: '#F8F8F8',
            overflow: 'hidden',
            p: 2,
            gap: 2
          }}
        >
          <Stack
            sx={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}
          >
            <MyTable
              hover
              minusHeight={250}
              getActionButtons={getActionButtons}
              onRowClick={(item) => {
                handleSelectCategory(item);
              }}
              columns={categoryColumns}
              data={categoryList || []}
              sx={{ my: 0 }}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            minWidth: '50%',
            flexGrow: 1,
            // Ensures this component takes the full height
            // Allow this Stack to grow within the parent
            border: '1px solid #D3D3D3',
            borderRadius: 1,
            mr: 2,
            backgroundColor: '#F8F8F8',
            overflow: 'hidden',
            p: 2,
            gap: 2
          }}
        >
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <Button
              variant="outlined"
              disabled={!selectedCategoryType?.productCategoryCode}
              onClick={() => handleCreateTemporaryProduct()}
            >
              {`${t('pages.inventory.product')} ${t('button.add')}`}
            </Button>
          </Stack>
          <Stack sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
            <MyTable
              hover
              minusHeight={180}
              selectedItem={selectedCategoryType}
              getActionButtons={getActionButtons}
              columns={productColumns}
              data={storeProductList}
              sx={{ my: 0 }}
            />
          </Stack>
        </Stack>
      </Stack>

      {selectedItem && (
        <AlertDialog
          open={alertDialog.open}
          setOpen={() => {
            setAlert({
              ...alertDialog,
              open: false,
              onOk: null
            });
            setSelectedItem(null);
          }}
          onOk={() => onSubmit('delete')}
          okTitle={t('button.delete')}
          isAlert
          title={t('common.confirm-delete-new-title')}
          subTitle={t('common.confirm-delete-new-subtitle')}
          hasCancelButton
        />
      )}
    </Stack>
  );
};

export default StoreProductsPage;
