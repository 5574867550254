import RestService from './RestService';

const equipmentServices = {
  // ?keyword=&page=0&size=20
  async getListEquipment(params) {
    try {
      const res = await new RestService()
        .setPath('equipment')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getListEquipmentWithStatus(params) {
    try {
      const res = await new RestService()
        .setPath('equipment/statuses')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getEquipmentStatusList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('equipment/status')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getEquipment(id) {
    try {
      const res = await new RestService().setPath(`equipment/${id}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getBuyEqmDetails(id) {
    try {
      const res = await new RestService().setPath(`equipment/${id}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getBuyEqmHistory() {
    try {
      const res = await new RestService().setPath(`equipment/purchases`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getEquipmentStatusDetail(id) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${id}/status`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getEquipmenDetail(id) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${id}/status`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createEquipment(data) {
    try {
      const res = await new RestService()
        .setPath('equipment')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createEquipmentStatusName(data) {
    try {
      const res = await new RestService()
        .setPath('equipment/status')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createBuyEqm(data) {
    try {
      const res = await new RestService()
        .setPath('equipment/purchase')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateEquipment(id, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${id}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateEquipmentStatus(id, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/status/${id}`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateEqmPurchaseShipping(purchaseCode) {
    try {
      const res = await new RestService()
        .setPath(`equipment/purchase/${purchaseCode}/shipping`)
        .put();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadQuotationFile(purchaseCode, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/purchase/${purchaseCode}/quotation`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadInvoiceFile(purchaseCode, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/purchase/${purchaseCode}/invoice`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async equipmentQuantityStatusChange({ equipmentCode, fromStatusCode }, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${equipmentCode}/status/${fromStatusCode}`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async UpdateEqmStatusQuantityFromRemaining({ equipmentCode }, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${equipmentCode}/status`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async UpdateEqmStatusQuantityToRemaining({ equipmentCode }, data) {
    try {
      const res = await new RestService()
        .setPath(`equipment/${equipmentCode}/status`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteEquipment(id) {
    try {
      const res = await new RestService().setPath(`equipment/${id}`).delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteEquipmentStatus(id) {
    try {
      const res = await new RestService()
        .setPath(`equipment/status/${id}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteBuyEqmHistoryItem(purchaseCode) {
    try {
      const res = await new RestService()
        .setPath(`equipment/purchase/${purchaseCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // == By Minibar ==
  // params: String clientCode; String minibarCode; Integer page = 0; Integer size = 20
  async getListEquipmentByMinibar(params) {
    try {
      const res = await new RestService()
        .setPath('equipment/by-minibar')
        .setConfig({ params })
        .get();

      // channelName, clientName, minibarName, investmentMoney, listEquipment[]
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default equipmentServices;
