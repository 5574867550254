import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import restockService from '@services/restock';
import MyTable from '@components/MyTable';
import { useDispatch } from 'react-redux';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import { scopes } from '@constants/utils';
import error2Text from '@utils/error2Text';
import useAuthorization from '@hooks/useAuthorization';
import FixDialog from './Components/FixDialog';
import { columns } from './enhance';

const Restock = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const [param, setParam] = useState({ page: 0 });
  const [dialog, setDialog] = useState({
    open: false,
    item: ''
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const { items, pagination } = state;
  const onSelectCheckbox = (item, e) => {
    if (e.target.checked) {
      setSelectedItems(selectedItems?.concat([item]));
    } else {
      setSelectedItems(
        selectedItems?.filter((i) => i?.returningCode !== item?.returningCode)
      );
    }
  };
  const configColumns = columns(onSelectCheckbox, selectedItems);
  const handleClickTable = (item) => {
    setDialog((prevDialog) => ({ ...prevDialog, open: true, item }));
  };
  const getRetocks = async () => {
    const rs = await restockService.getRestockList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    getRetocks();
  }, [param?.page]);

  const handleChangePage = (page) => {
    setParam({ ...param, page });
  };
  const handleRegister = async () => {
    const listReturningCode = selectedItems?.reduce((rs, item) => {
      return rs.concat([item.returningCode]);
    }, []);
    if (listReturningCode?.length > 0) {
      const rs = await restockService.stockRegister({ listReturningCode });
      if (rs?.error) {
        dispatch(setError(error2Text(rs?.error)));
      } else {
        dispatch(setSuccess({ message: t('info.register.content') }));
        getRetocks();
      }
    }
  };
  return (
    <HelmetContainer title={t('pageTitle.restock')} content="Restock Page">
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" gap={2}>
          <Button variant="outlined" onClick={() => navigate('history')}>
            {t('pages.inventory.restocking-history')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'save',
                  action: () => handleRegister()
                })
              );
            }}
            disabled={selectedItems?.length <= 0 || !isAuthorized}
          >
            {t('pages.inventory.stocking-register')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination?.totalRow || 0}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        columns={configColumns}
        onRowClick={handleClickTable}
        data={items}
        pagination={pagination}
        minusHeight={150}
        onChangePage={(e, page) => handleChangePage(page)}
      />

      <FixDialog
        open={dialog.open}
        setOpen={() => setDialog({ ...dialog, open: false, item: '' })}
        selectedItem={dialog?.item}
        onSuccess={() => getRetocks(param)}
      />
    </HelmetContainer>
  );
};

export default Restock;
