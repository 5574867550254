import React, { useState } from 'react';

import Lightbox from 'yet-another-react-lightbox';
import {
  Counter,
  Fullscreen,
  Slideshow,
  Thumbnails,
  Zoom
} from 'yet-another-react-lightbox/plugins';

import { Box } from '@mui/material';

import CustomImage from '@components/CustomImage';

import env from '@constants/env';

const LightBoxImage = ({
  index,
  fileLink,
  fileName,
  slides,
  apiImages,
  sx
}) => {
  const [open, setOpen] = useState(false);

  const slidesFromApi = apiImages?.map((_fileLink) => {
    const src = `${env.s3BaseUrl}/${_fileLink}`;
    return { src };
  });
  return (
    <Box>
      <CustomImage
        fileLink={fileLink}
        fileName={fileName}
        sx={sx}
        onClick={() => setOpen(true)}
      />
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides || slidesFromApi}
        index={index}
        counter={{ style: { top: 'unset', bottom: 0 } }}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Counter]}
        carousel={{ finite: true }}
        styles={{ root: { '--yarl__color_backdrop': 'rgba(52, 52, 52, 0.6)' } }}
      />
    </Box>
  );
};

export default LightBoxImage;
