import MyAutocomplete from './MyAutocomplete';

export default MyAutocomplete;
// ===========CLIENT==========
export { default as ClientAutoComplete } from './ClientAutoComplete';
// V2 client side search and filter
export { default as ClientAutoCompleteV2 } from './ClientAutoComplete/V2.1';
// V2 async fetching clients
export { default as ClientAutoCompleteV2Async } from './ClientAutoComplete/V2';

// =========MINIBAR========
export { default as MinibarAutoComplete } from './MinibarAutoComplete';
// Main Autocomplete to be used
export { default as MinibarAutoCompleteV2 } from './MinibarAutoComplete/V2.1';
// V2 async search from api  => reserved for now
export { default as MinibarAutoCompleteV2Async } from './MinibarAutoComplete/V2';
