import MyDialog from '@components/MyDialog';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const leftLabelStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: '700',
  fontSize: 13,
  borderBottom: '0.5px solid #D3D3D3',
  color: '#6F869C',
  backgroundColor: '#FCFCFC'
};
const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  minWidth: '420px',
  overflowY: 'auto'
});

export default function ErrorHistoryForm({
  item,
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const [message, setMessage] = useState('');
  const [notifyDialog, setNotifyDialog] = useState(undefined);

  const { t } = useTranslation();
  const handleChangeInput = (e) => {
    const { value } = e.target;
    setMessage(value);
  };
  const handleSubmit = () => {
    if (message) {
      onSubmit(message);
    } else {
      const content = t(
        'pages.kiosk.dialog.title.pleaseEnterYourProblemsAndTryAgain'
      );
      setNotifyDialog({
        title: content,
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Container component={BorderBox}>
        <Stack sx={{ ...leftLabelStyle }}>
          <Typography sx={{ padding: 1 }}>
            {t(
              `pages.kiosk.labels.${
                item !== null ? 'processingMessage' : 'receiveMessage'
              }`
            )}
          </Typography>
        </Stack>
        <Stack
          justifyContent="space-between"
          sx={{ width: '100%', padding: 1 }}
        >
          <TextField
            value={message}
            name="message"
            onChange={handleChangeInput}
            multiline
            placeholder={t(`pages.kiosk.labels.message`)}
            rows={5}
            variant="outlined"
            inputProps={{
              className: {
                resize: 'both'
              }
            }}
          />
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            disabled={!message}
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
      <MyDialog
        open={notifyDialog !== undefined}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
    </Stack>
  );
}
