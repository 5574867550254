import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const curationServices = {
  /**
   * data: {"channel": "B2B", "startDate": "2023-03-14", "endDate": "2023-06-14", "clientCode": "clientCode1", "minibarCode": null, "status": null, "page": 0, "size": 20}
   *
   * status: "Y" ==> completed, null ==> total, "N" ===> stand by
   */
  async getListCuration(data) {
    try {
      const res = await new RestService().setPath('curation/list').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * curationId: minicura642bccc600327
   */
  async getCuration(curationId) {
    try {
      const res = await new RestService()
        .setPath(`curation/${curationId}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * data: { startDate, endDate, clientChannel, clientCode, minibarCode, productName. category1Code, category2Code, category3Code, categorySpecialCode, condition }
   */
  async getCurationProduct(data) {
    try {
      const res = await new RestService()
        .setPath('curation/product-filter')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * data: { clientCode, minibarCode, budget, pricePolicyType, listProduct[{ productCode, salesQuantity }]}
   */
  async getRecommenedQuantity(data) {
    try {
      const res = await new RestService()
        .setPath('curation/recommended-quantity')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createCuration(payload) {
    try {
      const res = await new RestService().setPath('curation').post(payload);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * curationId: minicura642bccc600327,
   */
  async createCurationOrder(curationId) {
    try {
      const res = await new RestService()
        .setPath(`curation/${curationId}/order`)
        .post();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * curationId: minicura642bccc600327,
   *
   * data: { curationName, listCurationProduct: [{ productCode, orderQuantity, totalPrice }]}
   */
  async updateCuration(curationId, data) {
    try {
      const res = await new RestService()
        .setPath(`curation/${curationId}`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createCurationProduct(curationId, productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`curation/${curationId}/product/${productCode}`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   *  { curationId: minicura231231231, productCode: productCode3}
   */
  async deleteCurationProduct(curationId, productCode) {
    try {
      const res = await new RestService()
        .setPath(`curation/${curationId}/product/${productCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * params: { curationCode: minicura231231231 }
   */
  async exportFile(params) {
    try {
      const res = await new RestService()
        .setPath('curation/export')
        .setResponseType('blob')
        .setConfig({ params })
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default curationServices;
