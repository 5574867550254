import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import { Formatter } from '@utils/formatter';

import performanceService from '@services/performance';
import { MAX_INT } from '@constants/utils';
import { useDispatch } from 'react-redux';
import { onOpenConfirm } from '@store/reducers/appReducer';

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dcb'
];

export default function ModifyPerformanceDialog({
  open,
  setOpen,
  performance,
  handleAfterUpdating
}) {
  const [t] = useTranslation();
  const [performanceList, setPerformanceList] = useState(null);
  const dispatch = useDispatch();
  const handleChange = (e, index) => {
    const { name: label, value: val } = e.target;
    const value = val.replace(/[,]/g, '');
    if (value <= MAX_INT) {
      const newList = [...performanceList];
      newList[index] = { label, value };
      setPerformanceList(newList);
    }
  };

  const loadPerformance = () => {
    setPerformanceList(
      months.map((month) => {
        return { label: month, value: performance[month] || 0 };
      })
    );
  };

  const handleUpdatePerformance = async () => {
    const updateData = {};
    performanceList.forEach((element) => {
      updateData[element.label] = element.value || 0;
    });

    const res = await performanceService.updatePerformance(
      performance?.performanceCode,
      updateData
    );
    if (res) {
      setOpen(false);
      handleAfterUpdating(
        performance?.performanceType,
        performance?.performanceCode
      );
    }
  };

  useEffect(() => {
    if (performance !== null) loadPerformance();
  }, [performance]);

  return (
    <MyDialog
      title={t(
        `pages.performance-management.goal.labels.modify${performance?.division}`
      )}
      open={open}
      setOpen={setOpen}
      hasCloseButton
      hasCancelButton
      onOk={() =>
        dispatch(
          onOpenConfirm({
            open: true,
            type: 'modify',
            action: () => handleUpdatePerformance()
          })
        )
      }
      sx={{ width: 1050 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          my: '30px',
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden',
          flexWrap: 'wrap'
        }}
      >
        {[
          {
            label: t('pages.performance-management.goal.labels.channel'),
            value: performance?.clientChannel
          },
          {
            label: t('pages.performance-management.goal.labels.clientName'),
            value: performance?.clientName
          }
        ].map(({ label, value }) => (
          <Stack
            key={label}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              flex: 1,
              height: '50px',
              borderBottom: '1px solid #EEEEEE',
              '&:not(:first-of-type)': {
                borderLeft: '1px solid #EEEEEE'
              }
            }}
          >
            <Stack
              sx={{
                height: '100%',
                bgcolor: '#FCFCFC',
                textAlign: 'center',
                justifyContent: 'center',
                px: '12px',
                fontSize: 13,
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                whiteSpace: 'nowrap'
              }}
            >
              {label}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                flex: 1,
                height: '100%',
                px: '12px',
                fontSize: 15,
                color: 'text.primary',
                whiteSpace: 'nowrap'
              }}
            >
              {value}
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Grid container sx={{ borderRadius: '8px', border: '1px solid #D3D3D3' }}>
        {performanceList?.map((performance, index) => (
          <Grid
            item
            xs={2}
            sx={{
              '&:nth-child(n+1):nth-child(-n+5)': {
                borderRight: '1px solid #EEEEEE',
                borderBottom: '1px solid #EEEEEE'
              },
              '&:nth-child(n+7):nth-child(-n+11)': {
                borderRight: '1px solid #EEEEEE'
              },
              '&:nth-child(6)': {
                borderBottom: '1px solid #EEEEEE'
              }
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Stack
                sx={{
                  width: '100%',
                  height: '2.5rem',
                  bgcolor: '#FCFCFC',
                  pt: '.4rem',
                  textAlign: 'center',
                  justifyContent: 'center',
                  fontSize: 13,
                  color: '#6F869C',
                  whiteSpace: 'nowrap',
                  fontWeight: 'fontWeightBold',
                  borderRadius: '8px'
                }}
              >
                {t(
                  `pages.performance-management.goal.labels.${performance?.label}`
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  height: '2.5rem',
                  px: '1rem',
                  my: '.3rem',
                  fontSize: 15,
                  color: 'text.primary',
                  whiteSpace: 'nowrap'
                }}
              >
                <MyTextField
                  name={performance?.label}
                  value={Formatter.fNumber(performance?.value) || 0}
                  onChange={(e) => handleChange(e, index)}
                  typeValue="onlyNumber"
                />
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </MyDialog>
  );
}
