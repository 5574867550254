import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const pickingService = {
  async getPickingList(data) {
    try {
      const res = await new RestService().setPath('/picking').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // total pickings
  async getTotalPickingList() {
    try {
      const res = await new RestService().setPath('/picking/total').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getTotalPickingDetail(totalPickingCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/products`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // this is picking list that included in single totalpicking
  async getTotalPickingItemList(totalPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/items`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getTotalPickingNoteDetail(totalPickingCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/shortage`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createReasonForInsufficientPicking(data) {
    try {
      const res = await new RestService().setPath(`/picking/reason`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createTotalPickings(data) {
    try {
      const res = await new RestService().setPath(`/picking/total`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createShippingForTotalPickings(totalPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/shipping`)
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateTotalPickingNickName(totalPickingCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/name`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateTotalPickingNote(totalPickingCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/shortage`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async checkIsDeletable(pickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${pickingCode}/deleteYn`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteTotalPicking(totalPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteTotalPickingItem(totalPickingCode, pickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/total/${totalPickingCode}/item/${pickingCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportTotalPickingProducts(totalPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`picking/total/${totalPickingCode}/export`)
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // individual pickings
  async createIndividualPicking(data) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createShippingForIndiPickings(indiPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual/${indiPickingCode}/shipping`)
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getIndividualPickingList() {
    try {
      const res = await new RestService().setPath('/picking/individual').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getIndividualPickingDetail(indiPickingCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual/${indiPickingCode}/products`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getIndividualPickingNoteDetail(indiPickingCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual/${indiPickingCode}/shortage`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateIndiPickingNote(indiPickingCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual/${indiPickingCode}/shortage`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getPickingDetail(pickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/${pickingCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteIndividualPicking(indiPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`/picking/individual/${indiPickingCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // export excels
  async exportPicking(pickingCode) {
    try {
      const res = await new RestService()
        .setPath(`picking/${pickingCode}/export`)
        .setResponseType('blob')
        .post();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportTotalPicking(totalPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`picking/total/${totalPickingCode}/export`)
        .setResponseType('blob')
        .post();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportIndividualPicking(indiPickingCode) {
    try {
      const res = await new RestService()
        .setPath(`picking/individual/${indiPickingCode}/export`)
        .setResponseType('blob')
        .post();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default pickingService;
