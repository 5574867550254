import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import homepageService from '@services/homepage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setError,
  setOpenConfirm,
  setSuccess
} from '@store/reducers/appReducer';

import error2Text from '@utils/error2Text';
import FAQDetailForm from './FAQdetailForm';

const ProductDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const faqCode = pathname[pathname.length - 1];

  const [formValue, setFormValue] = useState({
    createdBy: '',
    updatedBy: '',
    createdDate: '',
    updatedDate: '',
    question: '',
    answer: '',
    questionEng: '',
    answerEng: '',
    category: ''
  });

  const getFaqDetail = async () => {
    if (!location?.pathname?.includes('register')) {
      const rs = await homepageService.getFAQdetail(faqCode);
      setFormValue({
        ...formValue,
        ...rs?.data?.data
      });
    }
  };

  useEffect(() => {
    getFaqDetail();
  }, []);
  const onAction = async (action) => {
    if (action === 'cancel') navigate(-1);
    if (action === 'register' || action === 'modification') {
      let isValidate = false;
      if (
        !formValue.question ||
        !formValue.answer ||
        !formValue.questionEng ||
        !formValue.answerEng ||
        !formValue.category
      )
        isValidate = true;

      const dataSubmit = {
        question: formValue?.question,
        answer: formValue?.answer,
        questionEng: formValue?.questionEng,
        answerEng: formValue?.answerEng,
        category: formValue?.category
      };
      if (!isValidate) {
        let rs = null;
        if (action === 'register') {
          rs = await homepageService.registerNewFAq(dataSubmit);
        } else {
          rs = await homepageService.updateFaq(formValue?.faqCode, dataSubmit);
        }
        if (!rs?.error) {
          dispatch(setSuccess({ message: t('info.success.content') }));
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          dispatch(setError(error2Text(rs.error)));
        }
        dispatch(
          setOpenConfirm({
            okTitle: null,
            title: null,
            action: null,
            open: false,
            type: null,
            msg: null
          })
        );
      }
    }
  };

  return (
    <HelmetContainer
      title={
        faqCode === 'register'
          ? `${t('pages.homepage.faq')} ${t('button.register')}`
          : `${t('pages.homepage.faq')} ${t('button.modify')}`
      }
      content="FAQ detail page"
    >
      <FAQDetailForm
        onAction={onAction}
        value={formValue}
        setValue={setFormValue}
      />
    </HelmetContainer>
  );
};

export default ProductDetail;
