import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import profitAndLossService from '@services/profit&loss';
import SearchBar from '@components/SearchBar';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { objToParams, paramsToObj } from '@utils/helpers';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { columnsInvestment, columnsProfit } from './enhance';

const ProfitAndLoss = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const configColumns = columnsProfit;
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [param, setParam] = useState({
    clientCode: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });

  const getProfitAndLoss = async (param) => {
    const rs = await profitAndLossService.getProfitAndLoss(param?.clientCode);
    if (!rs?.error) {
      setState({
        ...state,
        ...rs?.data,
        items: rs?.data?.profitStatusOfMonth || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setClient({
      ...client,
      clientCode: query?.clientCode || ''
    });
    if (query?.clientCode) {
      getProfitAndLoss({
        clientCode: query?.clientCode
      });
    } else {
      setState({
        items: [],
        pagination: {
          page: 0,
          count: 0,
          totalRow: 0
        }
      });
    }
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams({
      clientCode: client?.clientCode || ''
    });
    navigate(query);
    if (client?.clientCode) {
      getProfitAndLoss({
        clientCode: client?.clientCode || ''
      });
    } else
      setState({
        items: [],
        pagination: {
          page: 0,
          count: 0,
          totalRow: 0
        }
      });
  };
  return (
    <HelmetContainer title={t('pageTitle.profit&loss')} content="Sales page">
      <SearchBar>
        <ClientAutoCompleteV2
          client={client}
          setClient={(e) => {
            setClient(e);
            setParam({ ...param, clientCode: e?.clientCode });
          }}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Grid
        container
        sx={{
          border: '0.5px solid #D3D3D3',
          borderRadius: '8px',
          padding: '0px !important',
          height: '100%',
          overflowY: 'auto',
          mt: 2.5
        }}
      >
        {columnsInvestment.map(({ name, label, render }, index) => (
          <Grid xs={4} md={4} lg={4}>
            <FieldContainer
              label={label}
              value={render ? render(state[name]) : state[name]}
              sx={{
                borderBottom: index < 3 && '1px solid #D3D3D3',
                borderLeft: index !== 0 && index !== 3 && '1px solid #D3D3D3'
              }}
            />
          </Grid>
        ))}
      </Grid>
      <MyTable
        hover
        rerender
        data={state?.items}
        columns={configColumns}
        minusHeight={285}
      />
    </HelmetContainer>
  );
};

const FieldContainer = ({ label, value, sx }) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ ...sx }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 60,
          mr: 2,
          backgroundColor: '#FCFCFC'
        }}
      >
        <Typography
          sx={{
            width: 160,
            fontSize: 13,
            color: '#6F869C',
            fontWeight: '500'
          }}
        >
          {label}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Typography
          sx={{
            fontSize: 13,
            color: '#111111'
          }}
        >
          {value || ''}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProfitAndLoss;
