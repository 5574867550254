import RestService from '@services/RestService';

const logService = {
  async getLog(data) {
    try {
      const res = await new RestService().setPath('log/list').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default logService;
