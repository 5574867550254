/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  IconButton,
  Stack,
  Typography,
  Checkbox,
  styled,
  Switch
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';

import minibarServices from '@services/minibar';
import { setError } from '@store/reducers/appReducer';
import { objToParams, paramsToObj } from '@utils/helpers';
import { MINIBAR_OWNERSHIP_TYPES } from '@constants/utils';
import RegisterMinibarDialog from './RegisterMinibarDialog';
import EditListEquipmentDialog from './EditListEquipmentDialog';
import { minibarColumns, minibarActiontypes } from './enhance';
import EditCompanyRateDialog from './EditCompanyRateDialog';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));
const Minibar = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const [param, setParam] = useState({ keyword: '', page: 0 });
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [openListEquipment, setOpenListEquipment] = useState(false);
  const [listEquipment, setListEquipment] = useState();
  const [openRateCompanay, setOpenRateCompanay] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [minibarV2, setMinibarV2] = useState(null);
  const [success, setSuccess] = useState(false);
  const getData = async (param) => {
    const { data } = await minibarServices.getListMinibar(param);
    setData(data);
    setSuccess(false);
  };

  const handleDeleteMinibar = async () => {
    const result = await minibarServices.deleteMinibar(
      selectedItem.minibarCode
    );
    if (result?.error) dispatch(setError({ title: 'Something went wrong' }));
    else {
      handleSearch();
    }
    setOpenConfirmDialog(false);
  };

  const handleRowClick = (row) => {
    navigate(row.minibarCode);
  };

  useEffect(() => {
    if (success) getData(param);
  }, [success]);

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setMinibarV2({
      ...minibarV2,
      minibarNickname: query?.keyword || '',
      minibarCode: ' '
    });
    setParam({
      ...param,
      keyword: query?.keyword || '',
      page: query?.page - 1 || 0
    });
    getData({
      // eslint-disable-next-line no-nested-ternary
      keyword: query?.keyword
        ? query?.keyword?.includes(']')
          ? query?.keyword?.split(']')?.[1]
          : query?.keyword
        : '',
      page: query?.page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = async () => {
    const query = objToParams({
      keyword: minibarV2?.minibarNickname || param?.keyword || '',
      page: 1
    });
    navigate(query);
    getData({
      // eslint-disable-next-line no-nested-ternary
      keyword: minibarV2?.minibarNickname
        ? minibarV2?.minibarNickname?.includes(']')
          ? minibarV2?.minibarNickname?.split(']')?.[1]
          : minibarV2?.minibarNickname
        : param?.keyword || '',
      page: 0
    });
  };
  const handleChangeIssueCheckbox = async (item, e, actionType) => {
    e.stopPropagation();
    const sendValue = e.target.checked ? 'Y' : 'N';
    let dataSubmit;

    switch (actionType) {
      case minibarActiontypes.monitoring:
        // format data  for p monitoring
        dataSubmit = { isCheckingError: sendValue };

        break;
      case minibarActiontypes.connectionStatus:
        // format data  for pconnectionStatus
        dataSubmit = { hasError: sendValue };
        break;
      case minibarActiontypes.testStatus:
        // format data  for p testStatus
        dataSubmit = { isTestingDevice: sendValue };
        break;
      case minibarActiontypes.promnotionStatus:
        // format data  for promotionStatus
        dataSubmit = { isPromotion: sendValue };
        break;

      default:
        console.log(
          'action not found received parameters: ',
          item,
          e,
          actionType
        );
    }
    setData((prevData) => {
      const updatedData = prevData?.items.map((prevItem) =>
        prevItem.minibarCode === item.minibarCode
          ? { ...prevItem, ...dataSubmit }
          : prevItem
      );

      return {
        ...prevData,
        items: updatedData
      };
    });

    try {
      const rs = await minibarServices.updateMinibarStatus(
        item.minibarCode,
        dataSubmit
      );

      if (rs.error) {
        dispatch(
          setError({ title: 'Error happened while updating in server' })
        );
        setData((prevData) => {
          const rolledBackData = prevData?.items.map((prevItem) =>
            prevItem.minibarCode === item.minibarCode ? prevItem : prevItem
          );

          return {
            ...prevData,
            items: rolledBackData
          };
        });
      }
    } catch (error) {
      // Handle errors from the API call (e.g., network issues)
      dispatch(setError({ title: 'Error happened while updating in server' }));

      // If the API call fails, roll back the local state
      setData((prevData) => {
        const rolledBackData = prevData?.items.map((prevItem) =>
          prevItem.minibarCode === item.minibarCode ? prevItem : prevItem
        );

        return {
          ...prevData,
          items: rolledBackData
        };
      });
    }
  };

  const handleChangeOwnershipType = async (item, e) => {
    e.stopPropagation();

    const dataSubmit = {
      ownershipType:
        item?.ownershipType === MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED
          ? MINIBAR_OWNERSHIP_TYPES.CLIENT_OWNED
          : MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED
    };

    setData((prevData) => {
      const updatedData = prevData?.items.map((prevItem) =>
        prevItem.minibarCode === item.minibarCode
          ? { ...prevItem, ...dataSubmit }
          : prevItem
      );

      return {
        ...prevData,
        items: updatedData
      };
    });

    const rs = await minibarServices.updateMinibarOwnershipType(
      item.minibarCode,
      dataSubmit
    );

    if (rs.error) {
      dispatch(setError({ title: 'Error happened while updating in server' }));
      setData((prevData) => {
        const rolledBackData = prevData?.items.map((prevItem) =>
          prevItem.minibarCode === item.minibarCode ? item : prevItem
        );

        return {
          ...prevData,
          items: rolledBackData
        };
      });
    } else {
      dispatch(setSuccess({ message: t('info.update.content') }));
    }
  };

  const handleGetListEquipementByMinibar = async (item) => {
    setListEquipment();
    const result = await minibarServices.getListEquipmentByMinibar({
      minibarCode: item?.minibarCode
    });
    if (result?.error) dispatch(setError({ title: 'Something went wrong' }));
    else setListEquipment(result.data?.data || []);
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 2:
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              handleGetListEquipementByMinibar(item);
              setOpenListEquipment(true);
            }}
            size="small"
            sx={{
              fontSize: 13,
              height: '36px',
              px: '10px'
            }}
          >
            {t('pages.minibar.equipment-status')}
          </Button>
        );
      case 3:
        return (
          <Checkbox
            checked={item?.isCheckingError === 'Y'}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeIssueCheckbox(item, e, minibarActiontypes.monitoring);
            }}
          />
        );
      case 4:
        return (
          <Checkbox
            checked={item?.hasError === 'Y'}
            onClick={(e) =>
              handleChangeIssueCheckbox(
                item,
                e,
                minibarActiontypes.connectionStatus
              )
            }
          />
        );
      case 5:
        return (
          <Checkbox
            checked={item?.isTestingDevice === 'Y'}
            onClick={(e) =>
              handleChangeIssueCheckbox(item, e, minibarActiontypes.testStatus)
            }
          />
        );
      case 6:
        return (
          <Checkbox
            checked={item?.isPromotion === 'Y'}
            onClick={(e) =>
              handleChangeIssueCheckbox(
                item,
                e,
                minibarActiontypes.promnotionStatus
              )
            }
          />
        );
      case 7:
        if (item?.isTestingDevice !== 'Y') {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {t('pages.minibar.ownershipTypeList.clientOwned')}
              </Typography>
              <AntSwitch
                checked={
                  item?.ownershipType === MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED
                }
                onClick={(e) => handleChangeOwnershipType(item, e)}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              <Typography>
                {t('pages.minibar.ownershipTypeList.companyOwned')}
              </Typography>
            </Stack>
          );
        }
        return '';
      default:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenConfirmDialog(true);
              setSelectedItem(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
    }
  };

  const getModalButton = (item) => {
    return (
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          setSelectedItem(item);
          setOpenRateCompanay(true);
        }}
        sx={{
          fontSize: 13,
          textDecoration: 'underline'
        }}
      >
        {item.rateCompany || 0}%
      </Typography>
    );
  };

  useEffect(() => {
    if (!openListEquipment) setListEquipment();
  }, [openListEquipment]);

  return (
    <HelmetContainer
      title={t('pages.statistics.minibar')}
      content="minibar's client"
    >
      <SearchBar>
        <MinibarAutoCompleteV2
          setMinibar={setMinibarV2}
          freeSolo
          setKeyword={(value) => setParam({ ...param, keyword: value })}
          minibar={minibarV2}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        rerender
        onRowClick={handleRowClick}
        getActionButtons={getActionButtons}
        getModalButton={getModalButton}
        columns={minibarColumns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page: data?.page
        }}
        onChangePage={(e, page) => {
          setParam({ ...param, page });
          const query = objToParams({
            keyword: minibarV2?.minibarNickname,
            page: page + 1
          });
          navigate(query);
        }}
        minusHeight={235}
      />
      {/* Dialogs */}
      {open && <RegisterMinibarDialog open={open} setOpen={setOpen} />}
      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onOk={handleDeleteMinibar}
        isAlert
        hasCancelButton
        title={t('common.confirm-delete-title', {
          item: selectedItem?.minibarNickname
        })}
      />
      {selectedItem && (
        <EditListEquipmentDialog
          open={openListEquipment}
          setOpen={setOpenListEquipment}
          data={listEquipment}
          minibarCode={selectedItem?.minibarCode}
          key={listEquipment}
        />
      )}
      {selectedItem && openRateCompanay && (
        <EditCompanyRateDialog
          open={openRateCompanay}
          setOpen={setOpenRateCompanay}
          data={selectedItem}
          setSuccess={setSuccess}
        />
      )}
    </HelmetContainer>
  );
};

export default Minibar;
