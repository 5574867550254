import HelmetContainer from '@components/HelmetContainer';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import VendorOrderServices from '@services/vendorOrder';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sub } from 'date-fns';
import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import error2Text from '@utils/error2Text';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import { columns, radioOptions } from './enhance';

const Vendor = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    page: 0,
    status: null,
    productName: null
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getVendorOrders({ ...param, page });
    navigate(query);
  };
  const { items, pagination } = state;
  const purchaseOn = async (id) => {
    const rs = await VendorOrderServices.purchaseOn(id);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getVendorOrders(param);
    }
  };
  const getActionButtons = (item) => {
    return (
      <Button
        variant="outlined"
        disabled={item?.orderStatus === '1'}
        sx={{ height: 35, zIndex: 0 }}
        onClick={() => {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => purchaseOn(item?.id)
            })
          );
        }}
      >
        {t('pages.vendorOrder.purchase')}
      </Button>
    );
  };

  const getVendorOrders = async (param) => {
    const rs = await VendorOrderServices.getVendorOrders({
      day: formatTime(sub(new Date(), { days: 1 }), dateFormat),
      ...param
    });
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { status, page, productName } = query;
    setParam({ ...param, status, page: page - 1 || 0, productName });
    getVendorOrders({ ...param, status, page: page - 1 || 0, productName });
  }, [location?.search]);
  const [loading, setLoading] = useState(false);
  const onExport = async () => {
    setLoading(true);
    const rs = await VendorOrderServices.exportOrders({
      day: formatTime(sub(new Date(), { days: 1 }), dateFormat)
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const handleChangeCheckbox = (val, e) => {
    if (e.target.checked) {
      setParam({
        ...param,
        status: val
      });
    } else {
      setParam({
        ...param,
        status: null
      });
    }
  };

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getVendorOrders({ ...param, page: 0 });
    navigate(query);
  };

  return (
    <HelmetContainer title={t('pageTitle.vendor-orders')} content="Sales page">
      <SearchBar>
        <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 2
              }
            }}
            control={
              <Stack flexDirection="row">
                {radioOptions.map(({ value, label }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value === param?.status}
                        onChange={(e) => handleChangeCheckbox(value, e)}
                      />
                    }
                    label={label}
                  />
                ))}
              </Stack>
            }
          />
        </FormControl>
        <MyTextField
          label={t('pages.product.product-name')}
          onChange={(e) => setParam({ ...param, productName: e.target.value })}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack flexDirection="row" justifyContent="space-between" mt={2}>
        <Stack flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            onClick={() => onExport()}
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>
          <Button variant="outlined" onClick={() => navigate('history')}>
            {t('pages.vendorOrder.history')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <GroupMultiHeaderTable
        hover
        columns={columns}
        data={items || []}
        getActionButtons={getActionButtons}
        pagination={pagination}
        minusHeight={220}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default Vendor;
