import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const memberService = {
  async getListMember(data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath('member/list')
        .setConfig({ params })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getMemberDetails(memberCode) {
    try {
      const res = await new RestService().setPath(`member/${memberCode}`).get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getListRFID(memberCode = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/rfid`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getRFID(memberCode = '', rfidId = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/rfid/${rfidId}`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createRFID(memberCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/rfid`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateRFID(memberCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/rfid/${data?.rfidId}`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteRFID(memberCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/rfid/${data?.rfidId}`)
        .setConfig({ params })
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getPaymentHistory(memberCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/payment-history`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createCoupon(data = {}, params = {}) {
    const { memberCode } = data;
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}/coupon`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteMember(memberCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`member/${memberCode}`)
        .setConfig({ params })
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderHistory(data = {}) {
    try {
      const res = await new RestService()
        .setPath('prepurchase/list')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportOrderHistory(data) {
    try {
      const res = await new RestService()
        .setPath('prepurchase/export')
        .setResponseType('blob')
        .post(data);

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async sendLink(data = {}) {
    try {
      const res = await new RestService()
        .setPath('member/send-link')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default memberService;
