/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import homepageService from '@services/homepage';
import SearchBar from '@components/SearchBar';
import MyDialog from '@components/MyDialog/AlertDialog';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { Delete } from '@mui/icons-material';
import { columns, displayStatuslist } from './enhance';

export default function Notices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [param, setParam] = useState({
    displayStatus: 'all'
  });
  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const [dialog, setDialog] = useState({
    openConfirm: false,
    update: false,
    register: false,
    action: 'register'
  });
  const getData = async (params) => {
    setLoading(true);
    const result = await homepageService.getNoticeList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result?.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRow: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleRowClick = (row) => {
    navigate(row.noticeCode);
  };

  const handleChangeCheckbox = (val) => {
    setParam((prevParam) => ({
      ...prevParam,
      displayStatus: prevParam.displayStatus === val ? null : val
    }));
  };
  const handleSearch = () => {
    const query = { displayStatus: param?.displayStatus };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      isDisplayed: param?.displayStatus === 'all' ? null : param?.displayStatus,
      page: 0,
      size: 20
    });
  };
  const handleChangeDisplayStatus = async () => {
    // change from the list
    const rs = await homepageService.updateNoticeStatus(
      selectedItem?.noticeCode,
      {
        isDisplayed: selectedItem?.isDisplayed === 'Y' ? 'N' : 'Y'
      }
    );

    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      getData({
        ...param,
        isDisplayed:
          param?.displayStatus === 'all' ? null : param.displayStatus,
        page: pagination.page || 0
      });
    } else {
      setData((prev) => {
        const updatedList = prev.map((news) => {
          if (news.noticeCode === selectedItem?.noticeCode) {
            return {
              ...news,
              isDisplayed: selectedItem?.isDisplayed === 'Y' ? 'N' : 'Y'
            };
          }
          return news;
        });
        return updatedList;
      });
      dispatch(setSuccess({ message: t('info.success.content') }));
    }
    setSelectedItem(null);
    setDialog({
      ...dialog,
      openConfirm: false,
      type: null,
      okTitle: null,
      title: null,
      subtitle: null,
      buttonVariant: null
    });
  };

  const deleteNews = async () => {
    // change from the list
    const rs = await homepageService.deleteNotice(selectedItem?.noticeCode);
    if (rs?.error) {
      getData({ page: pagination?.page, pageSize: 20 });
    } else {
      setSelectedItem(null);
      setDialog({ ...dialog, openConfirm: false });
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getData({ page: pagination?.page, pageSize: 20 });
    }
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Checkbox
            disableRipple
            checked={item?.isDisplayed === 'Y'}
            onChange={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              const isDisplayed = item?.isDisplayed === 'Y';
              const dialogContent = {
                okTitle: isDisplayed
                  ? t('pages.homepage.exclude')
                  : t('button.check'),
                title: isDisplayed
                  ? t('pages.homepage.exclude-notice-homepage')
                  : t('pages.homepage.include-notice-homepage'),
                subtitle: isDisplayed
                  ? t('pages.homepage.exclude-notice-homepage-subtitle')
                  : t('pages.homepage.include-notice-homepage-subtitle'),
                type: 'edit',
                buttonVariant: !isDisplayed && 'edit'
              };
              setDialog({
                ...dialog,
                openConfirm: true,
                ...dialogContent
              });
            }}
          />
        );

      default:
        return (
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({ ...dialog, openConfirm: true });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { page, displayStatus } = query;

    setPagination({ ...pagination, page: page - 1 || 0 });
    setParam({ ...param, displayStatus: displayStatus || 'all' });
    getData({
      displayStatus: displayStatus === 'all' ? null : displayStatus,
      page: page - 1 || 0,
      size: 20
    });
  }, [location?.search]);

  return (
    <HelmetContainer title={t('pageTitle.notices')} content="homepage notices">
      <SearchBar sx={{ justifyContent: 'space-between' }}>
        <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            label={t('pages.homepage.display-status')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 2
              }
            }}
            control={
              <Stack flexDirection="row">
                {displayStatuslist.map(({ value, label }) => (
                  <FormControlLabel
                    key={`${value}-${label}`}
                    control={
                      <Checkbox
                        disableRipple
                        checked={param.displayStatus === value}
                        onChange={() => handleChangeCheckbox(value)}
                      />
                    }
                    label={label}
                  />
                ))}
              </Stack>
            }
          />
        </FormControl>
        <Button variant="contained" onClick={handleSearch}>
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => navigate('register')}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        onRowClick={handleRowClick}
        columns={columns}
        data={data || []}
        pagination={pagination}
        onChangePage={(e, page) => {
          const query = objToParams(ignoreEmpty({ page: page + 1 }));
          setPagination({ ...pagination, page });
          getData({ page, size: 20 });
          navigate(query);
        }}
        getActionButtons={getActionButtons}
        minusHeight={235}
        rerender
      />
      {selectedItem && (
        <MyDialog
          open={dialog.openConfirm}
          setOpen={() =>
            setDialog({
              ...dialog,
              openConfirm: false,
              type: null,
              okTitle: null,
              title: null,
              subtitle: null,
              buttonVariant: null
            })
          }
          onCancel={() =>
            setDialog({
              ...dialog,
              openConfirm: false,
              type: null,
              okTitle: null,
              title: null,
              subtitle: null,
              buttonVariant: null
            })
          }
          onOk={
            dialog?.type === 'edit' ? handleChangeDisplayStatus : deleteNews
          }
          okTitle={dialog?.okTitle || t('button.delete')}
          isAlert
          title={dialog?.title || t('common.confirm-delete-new-title')}
          subTitle={dialog?.subtitle || t('common.confirm-delete-new-subtitle')}
          type={dialog?.buttonVariant}
          hasCancelButton
        />
      )}
    </HelmetContainer>
  );
}
