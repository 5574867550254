import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useRef } from 'react';

export const CustomTableBody = styled(TableBody)(({ theme }) => ({
  '.MuiTableCell-root': {
    textAlign: 'center',
    padding: '0 12px',
    height: '54px',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    fontSize: 13
  },
  '.MuiTableRow-root': {
    '&:last-child td, &:last-child th': { border: 0 },
    'td:not(:last-child), th:not(:last-child)': {
      borderRight: '1px solid #EEEEEE'
    },
    position: 'relative',
    zIndex: 0
  }
}));

const ZombieTable = ({
  columns,
  sx,
  tableCellHeadSx,
  children,
  stickyHeader = true,
  position = 'unset',
  maxHeight,
  minusHeight = 350,
  fetchMore
}) => {
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const containerRef = useRef(null);
  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      // Fetch more data here
      debounce(fetchMore());
    }
  };

  React.useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return (
    <Box sx={{ position }}>
      <TableContainer
        ref={containerRef}
        sx={{
          bgcolor: 'common.white',
          textAlign: 'center',
          border: '1px solid #D3D3D3',
          borderRadius: '12px',
          ...sx
        }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
        }}
      >
        <Table stickyHeader={stickyHeader}>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '50px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                },
                ...tableCellHeadSx
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.label}
                  align={col.align}
                  sx={{
                    width: col.width,
                    minWidth: col.minWidth,
                    whiteSpace: 'pre-line',
                    padding: '0 8px !important'
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* TableBody */}
          {children}
          {/* TableBody */}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ZombieTable;
