import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import equipmentServices from '@services/equipment';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

const ExcelRegisterDialog = ({ open, setOpen, onAction, dialogItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFile, setDataFile] = useState({
    name: '',
    file: '',
    src: ''
  });

  const handleRegisterByExcel = async () => {
    const dataSubmit = new FormData();
    dataSubmit.append(dialogItem.action, dataFile.file);
    if (!dialogItem.item?.purchaseCode) return;
    const rs =
      dialogItem.action === 'invoiceFile'
        ? await equipmentServices.uploadInvoiceFile(
            dialogItem.item?.purchaseCode,
            dataSubmit
          )
        : await equipmentServices.uploadQuotationFile(
            dialogItem.item?.purchaseCode,
            dataSubmit
          );
    if (!rs.error) {
      onAction();
      setOpen(false);
    } else {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setDataFile({ ...dataFile, name: file.name, file });
  };
  useEffect(() => {
    setDataFile({ name: '', file: '', src: '' });
  }, [open]);
  return (
    <MyDialog
      open={open}
      disabled={!dataFile.file}
      setOpen={setOpen}
      title={t('button.upload')}
      okTitle={t('button.add')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onClose={() => setDataFile({ name: '', file: '', src: '' })}
      onOk={handleRegisterByExcel}
      sx={{ width: 460 }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 2.5 }}
      >
        <Typography
          sx={{ color: '#6F869C', fontWeight: 'fontWeightBold', fontSize: 13 }}
        >
          {t('pages.product.example-excel-notes')}
        </Typography>
      </Stack>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          p: '10px 20px'
        }}
      >
        <Tooltip title={dataFile.name}>
          <Typography
            sx={{
              color: 'primary.darker',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              textDecoration: 'underline',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '10px',
              marginTop: 1
            }}
          >
            {dataFile.name}
          </Typography>
        </Tooltip>

        <InputFile title={t('button.attachment')} onChange={handleFileUpload} />
      </Box>
    </MyDialog>
  );
};
export default ExcelRegisterDialog;
