import RestService from './RestService';

const apkServices = {
  async getListApk(params = {}) {
    try {
      const res = await new RestService()
        .setPath('apk')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getApk(apkCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`apk/${apkCode}`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createApk(data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`apk`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateApk(apkCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`apk/${apkCode}`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default apkServices;
