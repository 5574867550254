/* eslint-disable no-restricted-syntax */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from '@mui/material';
import { nanoid } from 'nanoid';
import React from 'react';
import { tableTypes } from '@constants/utils';

import NoData from '@components/NoData';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});

const TableCellsContainer = ({
  index,
  columns,
  item,
  getActionButtons,
  getModalButton,
  sx
}) => {
  return columns.map(
    ({
      name,
      type,
      actionType,
      formatter,
      icon,
      label,
      equipmentStatusCode
    }) => {
      const format = formatter ? formatter(item?.[name]) : item?.[name];

      const nameFormat =
        item?.[name] !== undefined && item?.[name] !== null ? format : '';
      let formatItem;
      switch (type) {
        case tableTypes.ACTION:
          return (
            <TableCell key={`${item?.id}action${actionType}`}>
              {getActionButtons(item, actionType)}
            </TableCell>
          );
        case tableTypes.MODAL:
          return (
            <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
              {getModalButton(item)}
            </TableCell>
          );
        case tableTypes.CHECK_MARK:
          return (
            <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
              {item?.[name]?.toUpperCase() === 'Y' ? icon : ''}
            </TableCell>
          );
        case tableTypes.DYNAMIC_COLUMN:
          formatItem = item?.stateOfEquipments?.find(
            (eqm) => eqm.equipmentStatusCode === equipmentStatusCode
          );
          return (
            <TableCell key={`${item?.id}${nanoid()}`} sx={{ px: 1 }}>
              {formatItem?.equipmentStateQuantity || 0}
            </TableCell>
          );
        default: {
          const render = columns.filter(({ name: n }) => n === name)[0]?.render;
          return (
            <TableCell
              key={`${nanoid()}${name}${label}`}
              sx={{
                color: 'text.primary',
                fontSize: 13,
                padding: '10px !important',
                ...sx?.font
              }}
            >
              {name === '#' && index + 1}
              {render ? render(item) : nameFormat}
            </TableCell>
          );
        }
      }
    }
  );
};

const GroupMultiHeaderTable = ({
  hover,
  onRowClick,
  getActionButtons,
  getModalButton,
  columns,
  data,
  emptyDescription,
  totalRow,
  maxHeight,
  minusHeight = 350
}) => {
  const handleRowClick = (item) => {
    if (onRowClick && !window.getSelection().toString()) onRowClick(item);
  };
  const newCols = [];
  columns.forEach((col) => {
    if (col?.children) {
      col?.children?.forEach((child) => {
        newCols.push(child);
      });
    } else {
      newCols.push(col);
    }
  });
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        id="table-container"
        component={BorderBox}
        sx={{ mt: '20px', mb: '20px' }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
        }}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '35px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow sx={{ position: 'sticky', top: 0 }}>
              {columns.map((col) => (
                <TableCell
                  key={`${col?.name}${col?.label}`}
                  rowSpan={!col?.children ? '2' : '1'}
                  colSpan={col?.children && col?.children?.length}
                  align={col.align}
                  sx={{
                    minWidth: col.minWidth,
                    whiteSpace: 'pre-line',
                    paddingTop: col.children && '5px !important'
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ position: 'sticky !important', top: 35 }}>
              {columns.map((col) => {
                return col?.children
                  ? col?.children?.map((child) => (
                      <TableCell
                        key={`${child?.name}${child?.label}`}
                        align={child.align}
                        sx={{
                          minWidth: child.minWidth,
                          whiteSpace: 'pre-line',
                          paddingTop: col.children && '5px !important',
                          fontSize: '12px !important',
                          '&:last-child': {
                            borderRight: '1px solid #EEEEEE'
                          }
                        }}
                      >
                        {child.label}
                      </TableCell>
                    ))
                  : null;
              })}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                height: 100,
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: 1.5,
                  height: '54px',
                  lineHeight: '22px'
                },
                position: 'relative',
                zIndex: 0
              }}
            >
              {data?.map((item, index) => (
                <TableRow
                  key={item?.id || `${item?.id}${index}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    'td:not(:last-child), th:not(:last-child)': {
                      borderRight: '1px solid #EEEEEE'
                    },
                    ':hover': hover && {
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => handleRowClick(item)}
                >
                  <TableCellsContainer
                    index={index}
                    item={item}
                    columns={newCols}
                    getActionButtons={getActionButtons}
                    getModalButton={getModalButton}
                  />
                </TableRow>
              ))}
              {totalRow &&
                [totalRow].map((item, index) => (
                  <TableRow
                    key={item?.id || `${item?.id}${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: 'pointer',
                      zIndex: 10,
                      position: 'sticky',
                      bottom: 0
                    }}
                    onClick={() => handleRowClick(item)}
                  >
                    <TableCellsContainer
                      sx={{
                        font: {
                          fontWeight: 'bold',
                          color: 'black !important'
                        }
                      }}
                      index={index}
                      item={item}
                      columns={newCols}
                      getActionButtons={getActionButtons}
                      getModalButton={getModalButton}
                    />
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GroupMultiHeaderTable;
