/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';
import { nanoid } from 'nanoid';
import NoData from '@components/NoData';

import { tableTypes } from '@constants/utils';
import { userServices } from '@services/index';
import React, { useEffect, useState, useRef } from 'react';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});

const CategoryBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderBottom: 'none',
  borderRadius: '12px 12px 0px 0px'
});

const LinkCell = ({ fileLink, fileName, children, sx, ...rest }) => {
  return (
    <TableCell
      sx={{
        color: 'primary.darker',
        fontWeight: 'fontWeightBold',
        fontSize: 13,
        textDecoration: 'underline',
        ...sx
      }}
      {...rest}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
          userServices.getFile({ fileName, fileLink });
        }}
        variant="text"
      >
        {children}
      </Box>
    </TableCell>
  );
};

const TableCellsContainer = ({
  index,
  columns,
  item,
  getActionButtons,
  getModalButton,
  pagination,
  isCategory
}) => {
  return columns.map(
    (
      { name, label, type, actionType, formatter, cathover, icon, onClick },
      idx
    ) => {
      const format = formatter ? formatter(item[name]) : item[name];
      const nameFormat =
        item?.[name] !== undefined && item?.[name] !== null ? format : '';
      switch (type) {
        case tableTypes.FILE:
          return (
            <LinkCell
              fileLink={item?.linkFile}
              key={`${item?.id}${name}`}
              fileName={item?.fileName}
            >
              {item?.fileName}
            </LinkCell>
          );
        case tableTypes.ACTION:
          return (
            <TableCell
              key={`${item?.id}actionsss${idx}`}
              sx={{
                px: 1,
                ':hover': cathover && {
                  bgcolor: 'primary.lighter',
                  '.MuiTableCell-root': {
                    color: 'primary.dark'
                  },
                  cursor: 'pointer'
                }
              }}
            >
              {getActionButtons(item, actionType, index)}
            </TableCell>
          );
        case tableTypes.MODAL:
          return (
            <TableCell
              key={`${item?.id}${nanoid()}`}
              sx={{
                px: 1,
                ':hover': cathover && {
                  bgcolor: 'primary.lighter',
                  '.MuiTableCell-root': {
                    color: 'primary.dark'
                  },
                  cursor: 'pointer'
                }
              }}
            >
              {getModalButton(item, actionType)}
            </TableCell>
          );
        case tableTypes.CHECK_MARK:
          return (
            <TableCell key={`${item?.id}${label}`} sx={{ px: 1 }}>
              {item?.[name]?.toUpperCase() === 'Y' ? icon : ''}
            </TableCell>
          );
        default:
          return (
            <TableCell
              key={`${item?.id}${name}${label}`}
              sx={{
                color: 'text.primary',
                fontSize: 13,
                ':hover': cathover &&
                  isCategory && {
                    bgcolor: 'secondary.lighter',
                    '.MuiTableCell-root': {
                      color: 'primary.dark'
                    },
                    color: 'tex.secondary',
                    cursor: 'pointer'
                  }
              }}
              onClick={onClick ? () => onClick(item) : () => {}}
            >
              {name === '#'
                ? (pagination?.page || 0) * (pagination?.pageSize || 20) +
                  index +
                  1
                : nameFormat}
            </TableCell>
          );
      }
    }
  );
};

export default function MyTable({
  hover,
  onRowClick,
  onCellClick,
  getActionButtons,
  selectedIndex = null,
  getModalButton,
  columns,
  data,
  rerender = false,
  pagination,
  emptyDescription,
  sx,
  paginationAdornment,
  maxHeight,
  minusHeight = 350,
  onChangePage,
  isCategory = false,
  getFilterIcon,
  loadingComponent = null
}) {
  const [focusedRow, setFocusedRow] = useState(null);
  const tableRef = useRef(null);
  const handleCellClick = (item, name) => {
    if (onCellClick && !window.getSelection().toString()) {
      onCellClick(item, name);
    }
  };

  const handleRowClick = (item, index) => {
    if (onRowClick && !window.getSelection().toString())
      onRowClick(item, index);
    setFocusedRow(index);
  };

  const renderComponent = (render, item, name, index) =>
    render ? render(item, index) : item[name];
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setLoading(false);
      if (selectedIndex) setFocusedRow(selectedIndex);
    }
  }, [data]);
  const scrollToRow = () => {
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        id="table-container"
        component={isCategory ? CategoryBox : BorderBox}
        sx={{ mt: '20px', mb: '20px', ...sx }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
          // minHeight: '400px'
        }}
      >
        <Table stickyHeader ref={tableRef}>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '50px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={`${col?.name}+${col?.label}`}
                  align={col.align}
                  sx={{
                    minWidth: col.minWidth,
                    direction: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {col.label}
                  {col.hasFilter && getFilterIcon(col)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: 1.5,
                  height: '54px',
                  lineHeight: '22px'
                },
                position: 'relative',
                zIndex: 0
              }}
            >
              {data.map((item, index) =>
                rerender ? (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item?.id}+${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      ':hover': hover && {
                        bgcolor: 'secondary.lighter',
                        '.MuiTableCell-root': {
                          color: 'primary.dark'
                        },
                        cursor: 'pointer'
                      },
                      ':focus': isCategory &&
                        focusedRow === index && {
                          bgcolor: 'secondary.lighter',
                          '.MuiTableCell-root': {
                            color: 'primary.dark'
                          }
                        }
                    }}
                    onClick={() => handleRowClick(item, index)}
                  >
                    {columns.map(
                      (
                        {
                          name,
                          label,
                          render,
                          cellProps,
                          type,
                          actionType,

                          onClick
                        },
                        idx
                      ) => {
                        const keyIndex = idx.toString();
                        if (type === tableTypes.ACTION)
                          return (
                            <TableCell
                              key={`${idx}actionsssss${label}`}
                              sx={{ px: 1 }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {getActionButtons(item, actionType)}
                            </TableCell>
                          );

                        if (type === tableTypes.MODAL) {
                          return (
                            <TableCell
                              key={`${keyIndex}${nanoid()}`}
                              sx={{ px: 1, cursor: 'pointer' }}
                            >
                              {getModalButton(item)}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            align="left"
                            {...cellProps}
                            key={`${index}actionbl${name}`}
                            onClick={
                              onClick
                                ? () => onClick(item, name)
                                : handleCellClick(item, name)
                            }
                          >
                            {name === '#'
                              ? (pagination?.page || 0) *
                                  (pagination?.pageSize || 20) +
                                index +
                                1
                              : renderComponent(render, item, name, index)}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                ) : (
                  <TableRow
                    key={`${index}-${item?.id}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      ':hover': hover && {
                        bgcolor: 'secondary.lighter',
                        '.MuiTableCell-root': {
                          color: 'primary.dark'
                        },
                        cursor: 'pointer'
                      },

                      ...(isCategory &&
                        focusedRow === index && {
                          bgcolor: 'secondary.lighter',
                          '.MuiTableCell-root': {
                            color: 'primary.dark'
                          }
                        })
                    }}
                    onClick={() => handleRowClick(item, index)}
                  >
                    <TableCellsContainer
                      index={index}
                      item={item}
                      columns={columns}
                      pagination={pagination}
                      getActionButtons={getActionButtons}
                      getModalButton={getModalButton}
                      isCategory={isCategory}
                    />
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <TableBody
              sx={{ display: data?.length === 0 && isCategory ? 'none' : '' }}
            >
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  {/* eslint-disable-next-line no-unneeded-ternary */}
                  {loadingComponent ? (
                    loadingComponent
                  ) : (
                    <NoData description={emptyDescription} />
                  )}
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data?.length > 0 && pagination && (
        <Stack direction="row" justifyContent="center">
          {paginationAdornment?.left && (
            <Stack position="absolute" left={0}>
              {paginationAdornment?.left}
            </Stack>
          )}
          <Stack flexDirection="row" alignItems="center" position="relative">
            {loading && (
              <CircularProgress
                size={22}
                sx={{
                  position: 'absolute',
                  right: 0,
                  left: 0,
                  margin: 'auto'
                }}
              />
            )}
            <Pagination
              count={pagination?.count || 1}
              variant="outlined"
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              page={pagination.page + 1}
              disabled={loading}
              onChange={(e, page) => {
                if (page !== pagination.page + 1) {
                  setLoading(true);
                  setTimeout(() => {
                    scrollToRow();
                  }, 500);
                  onChangePage(e, page - 1);
                }
              }}
            />
          </Stack>
          {paginationAdornment?.right && (
            <Stack position="absolute" right={0}>
              {paginationAdornment?.right}
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
}
