import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@svg/trash';
import { PencilIcon } from '@svg/pencil';
import adminServices from '@services/admin';
import MyDialog from '@components/MyDialog';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useTranslation } from 'react-i18next';
import { columns } from './enhance';
import CreateAdminDialog from './CreateAdminDialog';
import UpdateAdminDialog from './UpdateAdminDialog';

const buttonStyle = {
  width: '40px',
  height: '40px'
};

export default function Authority() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    const { data } = await adminServices.getListAdmin({
      page
      // size: pageSize,
    });

    data?.items?.forEach((each) => {
      if (each?.isSuper?.toUpperCase() !== 'Y') {
        each.isAdmin = 'Y';
        each.isSuper = 'N';
      }

      if (each?.isDisplayManager?.toUpperCase() === 'Y') {
        each.isAdmin = 'N';
        each.isSuper = 'N';
      }

      if (!each?.isProductMaster) {
        each.isProductMaster = 'N';
      }

      if (!each?.isStockMaster) {
        each.isStockMaster = 'N';
      }

      if (!each?.isWarehouseMaster) {
        each.isWarehouseMaster = 'N';
      }
      if (!each?.isDisplayManager) {
        each.isDisplayManager = 'N';
      }
    });

    setData(data);
  };

  const getActionButtons = (item, actionType, index) => (
    <Stack direction="row" spacing={1} justifyContent="center">
      <IconButton
        sx={{
          ...buttonStyle,
          bgcolor: 'primary.lighter'
        }}
        size="small"
        onClick={(e) => editIconClickHandler(e, item, index)}
      >
        <PencilIcon />
      </IconButton>

      <IconButton
        sx={{
          ...buttonStyle,
          bgcolor: 'background.grey'
        }}
        size="small"
        onClick={(e) => trashIconClickHandler(e, item)}
      >
        <TrashIcon />
      </IconButton>
    </Stack>
  );

  const editIconClickHandler = (e, item, index) => {
    e.stopPropagation();
    setOpenUpdateDialog(true);
    setSelectedItem({ ...item, index });
  };

  const trashIconClickHandler = (e, item) => {
    e.stopPropagation();
    setOpenConfirmDialog(true);
    setSelectedItem(item);
  };

  const deleteAdminHandler = async () => {
    if (!selectedItem) return;

    const res = await adminServices.deleteAdmin(selectedItem.adminCode);
    if (res.error && res.error.response.status === 403) {
      dispatch(setError(error2Text({ keyError: 'UNAUTHORIZED' })));
    } else if (res?.error) {
      dispatch(setError(error2Text(res.error)));
    }
    setOpenConfirmDialog(false);
    getData();
  };

  const updateAdminHandler = async () => {
    let updateData = {};
    const dataToCompare = data.items[selectedItem.index];

    if (
      dataToCompare.isSuper !== selectedItem.isSuper ||
      dataToCompare.isProductMaster !== selectedItem.isProductMaster ||
      dataToCompare.isStockMaster !== selectedItem.isStockMaster ||
      dataToCompare.isWarehouseMaster !== selectedItem.isWarehouseMaster ||
      dataToCompare.isDisplayManager !== selectedItem.isDisplayManager
    ) {
      updateData.type = 'role';
      updateData.isSuper = selectedItem.isSuper === 'Y' ? 'Y' : 'N';
      updateData.isProductMaster = selectedItem.isProductMaster;
      updateData.isStockMaster = selectedItem.isStockMaster;
      updateData.isWarehouseMaster = selectedItem.isWarehouseMaster;
      updateData.isDisplayManager = selectedItem.isDisplayManager;

      const res = await adminServices.updateAdmin(
        updateData,
        selectedItem.adminCode
      );
      if (res.error) {
        dispatch(setError(error2Text(res.error)));
      }

      getData();
    }

    if (selectedItem?.password?.length > 0) {
      updateData = {};

      updateData.type = 'password';
      updateData.password = selectedItem.password;

      const res = await adminServices.updateAdmin(
        updateData,
        selectedItem.adminCode
      );
      if (res.error) {
        dispatch(setError(error2Text(res.error)));
      }

      getData();
    }

    setOpenUpdateDialog(false);
  };
  return (
    <HelmetContainer title={t('pageTitle.authority')}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          onClick={() => {
            setOpenCreateDialog(true);
          }}
        >
          {t('common.register')}
        </Button>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {data?.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        getActionButtons={getActionButtons}
        columns={columns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page
        }}
        onChangePage={(e, page) => setPage(page)}
        minusHeight={150}
      />
      {selectedItem && (
        <MyDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onOk={deleteAdminHandler}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.name
          })}
          hasCancelButton
        />
      )}

      {selectedItem && !openConfirmDialog && (
        <UpdateAdminDialog
          open={openUpdateDialog}
          setOpen={setOpenUpdateDialog}
          onOk={() =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'maidifor',
                action: () => updateAdminHandler()
              })
            )
          }
          hasCancelButton
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          title={t('common.edit')}
        />
      )}

      <CreateAdminDialog
        dispatch={dispatch}
        open={openCreateDialog}
        setOpen={setOpenCreateDialog}
        getData={getData}
        title={t('common.register')}
      />
    </HelmetContainer>
  );
}
