import MyDialog from '@components/MyDialog';
import MyTable from '@components/MyTable';
import { PRIVATE_CODE } from '@constants/utils';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import memberService from '@services/member';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { memberRFIDConfigColumn } from '../enhance';
import RFIDFrom from './RFIDFrom';

export default function RFIDList({
  memberCode = '',
  onClose,
  cancelButtonProps,
  submitButtonProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [editRFID, setEditRFID] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(undefined);

  const getActionButtons = (item) => (
    <Stack direction="row">
      <Button
        variant="contained"
        sx={{ ml: 1.5, whiteSpace: 'nowrap' }}
        onClick={() => setEditRFID(item)}
      >
        {t('button.edit')}
      </Button>

      <Button
        variant="contained"
        sx={{ ml: 1.5, whiteSpace: 'nowrap' }}
        onClick={() => handleDeleteRFID(item)}
      >
        {t('button.delete')}
      </Button>
    </Stack>
  );

  const handleDeleteRFID = async (e) => {
    const { memberCode, id } = e;
    setNotifyDialog({
      title: t('dialog.title.areYouSureDeleteThisRecord'),
      onOk: async () => {
        const result = await memberService.deleteRFID(memberCode, {
          rfidId: id
        });
        if (result.error) {
          dispatch(
            setError({
              title: t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain')
            })
          );
        } else {
          getListRFID();
        }
        setNotifyDialog(undefined);
      },
      setOpen: () => {
        setNotifyDialog(undefined);
      }
    });
  };

  const getListRFID = async () => {
    const result = await memberService.getListRFID(memberCode);
    if (!result.error) {
      setItems(result.data?.data || []);
    }
  };

  const handleCloseEditForm = () => {
    getListRFID();
    setIsSubmitting(false);
    setEditRFID(undefined);
  };

  const handleSubmitRFID = async (e) => {
    setIsSubmitting(true);
    const result =
      editRFID === null
        ? await memberService.createRFID(memberCode, e)
        : await memberService.updateRFID(memberCode, e);
    if (!result?.error) {
      handleCloseEditForm();
    } else {
      dispatch(setError(error2Text(result.error)));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (memberCode?.startsWith(PRIVATE_CODE.MEMBER)) {
      getListRFID();
    }
  }, [memberCode]);

  return (
    <Stack sx={{ width: '100%' }}>
      <MyTable
        hover
        columns={memberRFIDConfigColumn(t)}
        data={items}
        getActionButtons={getActionButtons}
      />
      <MyDialog
        open={editRFID !== undefined}
        setOpen={!isSubmitting ? handleCloseEditForm : () => {}}
        title={t(
          `pages.member.dialog.title.${
            editRFID == null ? 'registerRFID' : 'editRFID'
          }`
        )}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <RFIDFrom
          item={editRFID}
          onClose={handleCloseEditForm}
          onSubmit={(e) => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: editRFID ? 'modify' : 'save',
                action: () => handleSubmitRFID(e)
              })
            );
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: editRFID == null ? t('button.register') : t('button.modify')
          }}
        />
      </MyDialog>
      <MyDialog
        open={notifyDialog !== undefined}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={() => setEditRFID(null)}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
