import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Stack, TableCell, TableRow } from '@mui/material';

import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import MyTextField from '@components/MyTextField';

import curationServices from '@services/curation';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

import { curationDetailColumns as columns } from '../../enhance';

const CurationTextFieldCell = ({ data, disabled, setSuccessDialog }) => {
  const [t] = useTranslation();
  const curationNameRef = useRef();
  const dispatch = useDispatch();

  const handleModify = async () => {
    const result = await curationServices.updateCuration(data?.curationCode, {
      curationName: curationNameRef.current.value
    });
    if (result.error) {
      dispatch(setError(error2Text(result.error)));
      curationNameRef.current.value = data?.curationName;
    } else {
      setSuccessDialog(true);
    }
  };

  useEffect(() => {
    curationNameRef.current.value = data?.curationName || '';
  }, [data]);

  return (
    <TableCell>
      <Stack direction="row" alignItems="center">
        <MyTextField
          fullWidth
          ref={curationNameRef}
          defaultValue={data?.curationName}
          onKeyDown={(e) => {
            if (e.isComposing || e.keyCode === 13) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'modify',
                  action: () => handleModify()
                })
              );
            }
          }}
          disabled={disabled}
        />
        <Button
          variant="outlined"
          sx={{ ml: '10px', height: 36 }}
          onClick={() =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'modify',
                action: () => handleModify()
              })
            )
          }
          disabled={disabled}
        >
          {t('pages.curation.modify')}
        </Button>
      </Stack>
    </TableCell>
  );
};

const DetailTable = ({ data, disabled, setSuccessDialog }) => {
  return (
    <ZombieTable columns={columns}>
      <CustomTableBody>
        <TableRow>
          <TableCell>
            {data?.curationDate &&
              new Date(data.curationDate).toLocaleString('en-CA', {
                hour12: false,
                timeZone: 'Asia/Seoul'
              })}
          </TableCell>
          <CurationTextFieldCell
            data={data}
            disabled={disabled}
            setSuccessDialog={setSuccessDialog}
          />
          <TableCell>{data?.clientName}</TableCell>
          <TableCell>
            {data?.totalAmount
              ? new Intl.NumberFormat('en-CA').format(data.totalAmount)
              : '-'}
          </TableCell>
        </TableRow>
      </CustomTableBody>
    </ZombieTable>
  );
};

export default DetailTable;
