import { Box, Container, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import MyTextField from '@components/MyTextField';
import MyAutocomplete from '@components/MyAutocomplete';
import locationService from '@services/location';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};
const LocationForm = ({ value, setValue }) => {
  const { t } = useTranslation();
  const [param, setParam] = useState({
    hasProduct: 'N',
    locationName: '',
    page: 0
  });
  const [locations, setLocations] = useState([]);
  const getLocations = async (param) => {
    const rs = await locationService.getLocationList(param);
    if (!rs?.error) {
      setLocations(rs?.data?.items);
    }
  };
  useEffect(() => {
    getLocations(param);
  }, [param?.page, param?.locationName]);
  const handleSelectLocation = (location) => {
    if (!location) {
      setValue({
        ...value,
        locationFloor: '',
        locationLine: '',
        locationNum: ''
      });
      return;
    }
    setValue({ ...value, ...location });
  };
  const handleInputChange = (e) => {
    setParam({ ...param, locationName: e?.target?.value });
  };
  return (
    <Container component={BorderBox}>
      <FieldContainer label={t('common.select')}>
        <MyAutocomplete
          name="location"
          sx={{ width: '250px', m: 2, mb: 1 }}
          isOptionEqualToValue={(option, value) =>
            option.locationName === value?.locationName
          }
          value={value || null}
          getOptionLabel={(option) => option?.locationName || ''}
          onChange={(e, val) => handleSelectLocation(val)}
          onInputChange={handleInputChange}
          options={locations}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.floor')}>
        <MyTextField
          value={value?.locationFloor}
          name="locationFloor"
          sx={{ width: '250px', m: 2, mb: 1 }}
          disabled
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.line')}>
        <MyTextField
          value={value?.locationLine}
          name="locationLine"
          sx={{ width: '250px', m: 2, mb: 1 }}
          disabled
        />
      </FieldContainer>
      <FieldContainer label={t('pages.inventory.number')}>
        <MyTextField
          value={value?.locationNum}
          name="locationNum"
          sx={{ width: '250px', m: 2, mb: 2 }}
          disabled
        />
      </FieldContainer>
    </Container>
  );
};

const FieldContainer = ({ label, children }) => {
  return (
    <Stack direction="row">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          padding: '0 10px',
          pt: 1,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 80,
            fontSize: 13,
            color: '#6F869C',
            textAlign: 'center'
          }}
        >
          {label}
          <span style={{ color: 'red', marginLeft: 5 }}>*</span>
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default LocationForm;
