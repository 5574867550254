import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { toast } from 'react-toastify';
import { setError } from '@store/reducers/appReducer';
import useResponsive from '@hooks/useResponsive';
import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import minibarOrderService from '@services/minibarOrderService';
import { Formatter } from '@utils/formatter';
import { MAX_INT } from '@constants/utils';
import { minibarOrderListTableBodyStyle } from '../enhance';

const EditPriceInfoDialog = ({ open, setOpen, data }) => {
  const isSm = useResponsive('down', 900);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [stock, setUpdateStock] = useState({
    properQuantity: data?.properStock || 0
  });

  const handleUpdateStock = async () => {
    setLoading(true);
    if (!data.minibarStockCode) return;

    const result = await minibarOrderService.updateProperStock(
      data.minibarStockCode,
      stock
    );
    setLoading(false);
    if (result.error) dispatch(setError({ title: 'Something went wrong' }));
    else {
      toast.success(t('pages.minibarOrder.messages.success.inventory'), {
        autoClose: 2000
      });
      setOpen(false);
    }
  };

  const handleChangeStockInput = (e) => {
    const price = parseInt(e.target.value?.replaceAll(',', '') || 0, 10);
    setUpdateStock({
      ...stock,
      [e.target.name]: Math.min(price, MAX_INT)
    });
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.product.proper-stock')}
      okTitle={t('button.edit')}
      cancelTitle={t('button.close')}
      hasCloseButton
      hasCancelButton
      onOk={handleUpdateStock}
      loading={loading}
      sx={{
        maxWidth: 460,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableBody
            sx={{
              ...minibarOrderListTableBodyStyle,
              th: {
                borderRight: '1px solid #EEEEEE',
                fontWeight: 'fontWeightBold',
                fontSize: isSm ? 5 : 13,
                textAlign: 'center',
                color: '#6F869C',
                bgcolor: '#FCFCFC',
                height: '64px',
                maxWidth: isSm ? 120 : 150
              }
            }}
          >
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.client-name')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={data?.clientName}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.minibar-nickname')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={data?.minibarNickname}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.product-name')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={data?.productName}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.product.proper-stock')}
              </TableCell>
              <TableCell>
                <MyTextField
                  typeValue="onlyNumber"
                  name="properQuantity"
                  value={Formatter.fNumber(stock?.properQuantity || 0)}
                  onChange={handleChangeStockInput}
                />
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibarOrder.branch-price')}
              </TableCell>
              <TableCell>
                <MyTextField
                  typeValue="onlyNumber"
                  name="branchPrice"
                  value={Formatter.fNumber(priceInfo?.branchPrice || 0)}
                  onChange={handleChangePriceInput}
                />
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default EditPriceInfoDialog;
