import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Stack, styled, Typography } from '@mui/material';

import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';

import { isEmail, isKoreanPhoneNumber } from '@utils/boolean';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const rowBorder = { borderBottom: '1px solid #D3D3D3' };

const leftLabelStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: '700',
  fontSize: 13,
  borderRight: '1px solid #D3D3D3',
  color: '#6F869C',
  paddingLeft: 3,
  backgroundColor: '#FCFCFC'
};

const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '116px'
};

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};

const VendorRegister = ({
  value,
  setValue,
  errMsg,
  setErrMsg,
  maxLengthOf
}) => {
  const { t } = useTranslation();
  const [nameFile, setNameFile] = useState({
    businessFile: '',
    taxBillFile: ''
  });

  const handleChangeInput = (e) => {
    const { name, value: valuee } = e.target;
    setValue({ ...value, [name]: valuee });

    let msg = '';
    if (!valuee && name === 'vendorName') {
      msg = t('common.required');
    } else if (valuee?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };

  const checkValidPhoneNumber = (e) => {
    if (e.target?.value && !isKoreanPhoneNumber(e.target.value)) {
      setErrMsg({ picContact: t('validation.invalidPhoneNumber') });
    }
  };

  const checkValidEmailAddress = (e) => {
    if (e.target.value && !isEmail(e.target.value)) {
      setErrMsg({ picEmail: t('validation.invalidEmailAddress') });
    }
  };

  const handleChangeUpload = (name, e) => {
    setValue({
      ...value,
      [name]: e.target.files[0]
    });
    setNameFile({ ...nameFile, [name]: e.target.files[0].name });
  };

  useEffect(() => {
    setErrMsg({
      vendorName: '',
      picName: '',
      picContact: '',
      vendorType: '',
      vendingProduct: '',
      bank: '',
      bankAccountNumber: '',
      picEmail: '',
      businessNumber: ''
    });
  }, []);

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        mt: '30px',
        p: 0,
        width: '100%',
        maxHeight: '80vh'
      }}
    >
      <Container component={BorderBox}>
        <Stack direction="row" sx={rowBorder}>
          <Stack sx={{ ...leftLabelStyle }}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.vendor')}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
              flexWrap="wrap"
            >
              <Stack
                flexDirection="row"
                sx={{
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 120,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.vendor-name')}
                    <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="vendorName"
                  placeholder={t('pages.inventory.vendor-name')}
                  value={value?.vendorName}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.vendorName}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '33%' },
                  maxHeight: 80
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.pic-name')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="picName"
                  placeholder={t('pages.inventory.pic-name')}
                  value={value?.picName}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.picName}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '33%' },
                  maxHeight: 80,
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.pic-contact')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="picContact"
                  placeholder={t('pages.inventory.pic-contact')}
                  value={value?.picContact}
                  onChange={(e) => handleChangeInput(e)}
                  onBlur={checkValidPhoneNumber}
                  typeValue="onlyNumber"
                  errMg={errMsg?.picContact}
                  sx={inputStyle}
                />
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                sx={{
                  width: { xs: '100%', md: '33%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.type')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="vendorType"
                  placeholder={t('pages.inventory.type')}
                  value={value?.vendorType}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.vendorType}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '33%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.vending-product')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="vendingProduct"
                  placeholder={t('pages.inventory.vending-product')}
                  value={value?.vendingProduct}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.vendingProduct}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '33%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.business-registration')}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: 'primary.darker',
                    fontWeight: 'fontWeightBold',
                    width: '100%',
                    fontSize: 13,
                    p: 2,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  {nameFile.businessFile}
                </Typography>
                <InputFile
                  onChange={(e) => handleChangeUpload('businessFile', e)}
                  title={t('button.Select')}
                  sx={{
                    root: {
                      height: '100%',
                      width: 'calc(100% - 120px)',
                      paddingLeft: 2,
                      paddingRight: 2
                    },
                    width: '100%',
                    label: {
                      maxWidth: '100%'
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                sx={{ width: { xs: '100%', md: '50%' }, height: '100%' }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.bank')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="bank"
                  placeholder={t('pages.inventory.bank')}
                  value={value?.bank}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.bank}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '50%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.account-number')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="bankAccountNumber"
                  placeholder={t('pages.inventory.account-number')}
                  value={value?.bankAccountNumber}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.bankAccountNumber}
                  sx={inputStyle}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row">
          <Stack sx={{ ...leftLabelStyle }}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.tax-bill')}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                sx={{ width: { xs: '100%', md: '50%' }, height: '100%' }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.email')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="picEmail"
                  placeholder={t('pages.inventory.email')}
                  value={value?.picEmail}
                  onChange={(e) => handleChangeInput(e)}
                  onBlur={checkValidEmailAddress}
                  errMg={errMsg?.picEmail}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '50%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('pages.inventory.business-number')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  name="businessNumber"
                  placeholder={t('pages.inventory.business-number')}
                  value={value?.businessNumber}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg?.businessNumber}
                  sx={inputStyle}
                />
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: { xs: '100%', md: '100%' },
                  p: '10px 0',
                  height: 70
                }}
              >
                <Typography
                  sx={{
                    color: 'primary.darker',
                    fontWeight: 'fontWeightBold',
                    width: '100%',
                    fontSize: 13,
                    p: 2,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  {nameFile.taxBillFile}
                </Typography>
                <InputFile
                  onChange={(e) => handleChangeUpload('taxBillFile', e)}
                  title={t('button.Select')}
                  sx={{
                    root: {
                      height: '100%',
                      width: 'calc(100% - 120px)',
                      padding: '0 16px'
                    },
                    width: '100%',
                    label: {
                      maxWidth: '100%'
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default VendorRegister;
