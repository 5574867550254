import { tableTypes } from '@constants/utils';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const s = i18n.getFixedT(null, null, 'pages.statistics');
const c = i18n.getFixedT(null, null, 'pages.curation');
const p = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

export const columns = [
  {
    name: '#',
    label: 'No.',
    align: 'center',
    minWidth: 60,
    render: (item, index) => index + 1
  },
  {
    name: 'productName',
    label: p('product-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'category1Name',
    label: c('1st-cat'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'category2Name',
    label: c('2nd-cat'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'category3Name',
    label: c('3rd-cat'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'totalPaymentAmount',
    label: s('total-payment-amount'),
    align: 'center',
    minWidth: 160,
    render: ({ totalPaymentAmount }) => formatMoney(totalPaymentAmount)
  },
  {
    name: 'totalSalesQuantity',
    label: s('total-sales-quantity'),
    align: 'center',
    minWidth: 160,
    render: ({ totalSalesQuantity }) => formatMoney(totalSalesQuantity)
  },
  {
    name: 'note',
    label: s('note'),
    align: 'center',
    minWidth: 160,
    type: tableTypes.ACTION
  }
];

export const paymentColumns = [
  {
    name: 'num',
    label: 'No.',
    align: 'center',
    minWidth: 60,
    render: (item, index) => index + 1
  },
  {
    name: 'clientName',
    label: g('common.client'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'minibarLocation',
    label: s('kiosk-location'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'totalPaymentAmount',
    label: s('total-payment-amount'),
    align: 'center',
    minWidth: 160,
    render: ({ totalPaymentAmount }) => formatMoney(totalPaymentAmount)
  },
  {
    name: 'totalPurchaseQuantity',
    label: s('total-purchase-quantity'),
    align: 'center',
    minWidth: 160,
    render: ({ totalPurchaseQuantity }) => formatMoney(totalPurchaseQuantity)
  }
];
