import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const totalOrderService = {
  async getTotalOrders(data) {
    try {
      const res = await new RestService().setPath('/order/list').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createMember(data) {
    try {
      const res = await new RestService().setPath('/order/receipt').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportTotalOrder(data) {
    try {
      const res = await new RestService()
        .setPath('order/export')
        .setResponseType('blob')
        .post(data);

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportTotalOrderForcounting(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/order/export-settlement')
        .setConfig({ params })
        .setResponseType('blob')
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPaymentHistory(id) {
    try {
      const res = await new RestService()
        .setPath(`member/${id}/payment-history`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default totalOrderService;
