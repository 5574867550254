import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from 'features/error-boundary';

import App from './App';
import Globals from './_Globals';
import store from './store';
import ThemeConfig from './theme';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const AppRender = () => {
  const [forceUpdateCount, setForceUpdateCount] = useState(0);
  const rerender = useSelector((state) => state.app.rerender);
  const forceUpdate = () => {
    setForceUpdateCount(forceUpdateCount + 1);
  };

  useEffect(() => {
    forceUpdate();
  }, [rerender]);
  return (
    <>
      <App />
      <Globals />
    </>
  );
};
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <ThemeConfig>
            <AppRender />
          </ThemeConfig>
        </Provider>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
