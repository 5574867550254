/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';

import { Box, IconButton, Stack, TableCell, Typography } from '@mui/material';
import { ReactComponent as XBoldIcon } from '@svg/x-bold.svg';

import MySelect from '@components/MySelect';
import NoData from '@components/NoData';

const SelectedItem = ({ v, data, categoryKey, handleRemoveItem }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderRadius: 100,
        bgcolor: 'primary.lighter',
        p: '0px 10px 0px 15px',
        height: 36
      }}
    >
      <Typography
        sx={{
          maxWidth: 180,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontWeight: 'fontWeightBold',
          fontSize: 13,
          lineHeight: '18px',
          color: 'primary.main',
          mr: 2
        }}
      >
        {data.find((e) => e?.categoryCode === v)?.[categoryKey]}
      </Typography>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => handleRemoveItem(v)}
      >
        <XBoldIcon />
      </IconButton>
    </Stack>
  );
};

const CustomTableCell = ({ data, setValue, value, multiple }) => {
  const { lang } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const handleRemoveItem = (v) => {
    const newArrValue = [...value];
    const index = newArrValue.indexOf(v);
    if (index > -1) {
      newArrValue.splice(index, 1);
    }
    setValue(newArrValue);
  };

  const categoryKey =
    lang === 'en' || lang === 'en-us' ? 'categoryNameEng' : 'categoryName';

  return (
    <TableCell sx={{ verticalAlign: 'top' }}>
      <Box
        sx={{
          py: 1,
          borderBottom: '1px solid #EEEEEE',
          mx: '-12px',
          px: '12px'
        }}
      >
        <MySelect
          multiple={multiple}
          sx={{ width: 260 }}
          fullWidth
          data={data.map((cat) => ({
            label: cat?.[categoryKey],
            value: cat.categoryCode
          }))}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </Box>
      <SimpleBar
        forceVisible="y"
        autoHide
        style={{ maxHeight: 160, width: 260, margin: '0 auto' }}
      >
        {Array.isArray(value) ? (
          <Stack alignItems="center" spacing={1.25} sx={{ py: 1.25 }}>
            {value.length > 0 ? (
              value.map((v, index) => (
                <SelectedItem
                  v={v}
                  key={index}
                  data={data}
                  categoryKey={categoryKey}
                  handleRemoveItem={handleRemoveItem}
                />
              ))
            ) : (
              <NoData description={t('pages.curation.selectCategories')} />
            )}
          </Stack>
        ) : (
          <Stack alignItems="center" sx={{ py: 1.25 }}>
            {value ? (
              <SelectedItem
                v={value}
                data={data}
                categoryKey={categoryKey}
                handleRemoveItem={() => setValue('')}
              />
            ) : (
              <NoData description={t('pages.curation.selectCategories')} />
            )}
          </Stack>
        )}
      </SimpleBar>
    </TableCell>
  );
};

export default CustomTableCell;
