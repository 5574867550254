/* eslint-disable no-plusplus */
import numeral from 'numeral';
import moment from 'moment';
import { currencyUnitData } from '@constants/utils';
import 'moment-timezone';

const formatCurrencyByAbbreviations = (
  number,
  depth = 2,
  data = currencyUnitData
) => {
  const pNum = Math.abs(number);
  const { length } = data;
  let magicNumber = '';
  const magic = (num, depth) => {
    if (depth <= 0 || num === 0) return ''; // end depth

    for (let index = 0; index < length; index++) {
      const item = data[index];
      const integer = parseInt(num / item.unit, 10);

      if (integer <= 0) return num;
      if (integer < 10 && integer > 0) {
        const r = num % item.unit; // ex: 105
        const f = num / item.unit - integer; // ex: 0.000105
        const m = f === 0 ? 0 : -Math.floor(Math.log(f) / Math.log(10) + 1); // num of zero digits after decimal point, ex: 0.000105 -> 3
        depth -= m;
        return integer + item.abbreviations + magic(r, --depth);
      }
    }
    return num;
  };

  if (pNum >= data[length - 1].unit * 10) {
    magicNumber = magic(
      (pNum % data[length - 1].unit) + data[length - 1].unit,
      depth
    );
    magicNumber =
      parseInt(pNum / data[length - 1].unit, 10) + magicNumber.slice(1);
  } else magicNumber = magic(pNum, depth);

  return number > 0 ? magicNumber : `-${magicNumber}`;
};

export const Formatter = {
  fCurrency(number) {
    return numeral(number).format(
      Number.isInteger(number) ? '$0,0' : '$0,0.00'
    );
  },

  fPercent(number) {
    return numeral(number / 100).format('0.0%');
  },

  fNumber(number) {
    return number ? numeral(number).format('0,0') : '';
  },

  fShortenNumber(number) {
    return numeral(number).format('0[.]0a');
  },

  fData(number) {
    return numeral(number).format('0.0 b');
  },

  fStringToNumber(string) {
    return (string && string.toString().match(/\d/g)?.join('')) || '';
  },

  fStringToRangeNumber(string, end = 100, start = 0) {
    let number = this.fStringToNumber(string);

    if (number > end || number < start) {
      number = number.slice(0, -1);
    }
    if (number.length > 1 && number[0] === '0') {
      number = number.slice(1);
    }
    return number;
  },
  formatTime(time, format = 'YYYY-MM-DD, HH:mm:ss') {
    const dateTime = moment(time).utcOffset(`+09:00`);
    return dateTime.format(format);
  },
  formatTime2(time) {
    const newTime = time?.split(' ').join(', ');
    const timeReturn = newTime.substring(0, newTime.length - 2);
    return timeReturn;
  },
  fDateStringWithoutDelimiter(dateWithoutDelimiter) {
    if (!dateWithoutDelimiter) return '';
    const year = dateWithoutDelimiter.slice(0, 4);
    const month = dateWithoutDelimiter.slice(4, 6);
    const day = dateWithoutDelimiter.slice(6);
    return `${year}-${month}-${day}`;
  },
  fKrPhone(phoneNumber) {
    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  },
  formatCurrencyByAbbreviations
};
