export const PencilIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6458 0.43289C11.2258 -0.144296 12.1661 -0.144297 12.7461 0.43289L18.565 6.22345C19.145 6.80064 19.145 7.73644 18.565 8.31363L9.06499 17.7674C8.92573 17.906 8.73685 17.9839 8.5399 17.9839H2.07127L1.2677 18.7836C0.977694 19.0721 0.507506 19.0721 0.217502 18.7836C-0.0725008 18.495 -0.0725008 18.0271 0.217502 17.7385L1.02107 16.9388V10.3169C1.02107 10.1209 1.09931 9.93292 1.23858 9.79433L10.6458 0.43289ZM2.50627 16.5059H6.78081L2.50627 12.1356V16.5059ZM2.80682 10.3239L3.87694 11.418L12.7807 2.55748L11.6959 1.47798L2.80682 10.3239ZM13.8309 3.60257L4.91295 12.4772L6.59772 14.1997L15.5388 5.30212L13.8309 3.60257ZM16.589 6.34721L7.63372 15.2589L8.54704 16.1927L17.5148 7.26854L16.589 6.34721Z"
        fill="#4267EB"
      />
    </svg>
  );
};
