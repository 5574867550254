import env from '@constants/env';
import { tableTypes } from '@constants/utils';
import i18n from '@utils/i18n';
import fallbackImg from '@svg/fallback-img.svg';
import { formatMoney } from '@utils/helpers';

const t = i18n.getFixedT(null, null, 'pages.product');

const productImgStyle = {
  objectFit: 'contain',
  width: '40px',
  height: '40px',
  border: '1px solid #EEEEEE'
};

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'imgUrl',
    label: t('image'),
    minWidth: 70,
    align: 'center',
    render: ({ imgUrl }) => {
      return (
        <img
          src={`${env.s3BaseUrl}/${imgUrl}`}
          alt="product"
          style={productImgStyle}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = fallbackImg;
          }}
        />
      );
    }
  },
  {
    name: 'productName',
    label: t('product-name'),
    minWidth: 200,
    align: 'center'
  },
  {
    name: 'barcode',
    label: t('bar-code'),
    minWidth: 130,
    align: 'center'
  },
  {
    name: 'category1Name',
    label: t('first-category'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: t('second-category'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: t('third-category'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'maker',
    label: t('manufacturer'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'vendorName',
    label: t('vendor'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacity',
    label: t('volume'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: t('package'),
    minWidth: 70,
    align: 'center'
  },
  {
    name: 'box',
    label: t('box'),
    minWidth: 70,
    align: 'center',
    render: ({ box }) => formatMoney(box)
  },
  {
    name: 'makeCost',
    label: t('cost'),
    minWidth: 80,
    align: 'center',
    render: ({ makeCost }) => formatMoney(makeCost)
  },
  {
    name: 'cost',
    label: t('master-price'),
    minWidth: 100,
    align: 'center',
    render: ({ cost }) => formatMoney(cost)
  },
  {
    label: t('notes'),
    minWidth: 70,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const productFilterConditionList = [
  { label: t('product-name'), value: 'productName' },
  { label: t('bar-code'), value: 'barcode' },
  { label: t('manufacturer'), value: 'maker' },
  { label: t('vendor'), value: 'vendorName' }
];

export const notificationSchema = [
  {
    name: 'announce01',
    type: 'text',
    label: t('type-of-food'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce02',
    type: 'text',
    label: t('name-address-maker'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce03',
    type: 'text',
    label: t('date-of'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce04',
    type: 'text',
    label: t('volume'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce05',
    type: 'text',
    label: t('modified-food'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce06',
    type: 'text',
    label: t('special-using-food'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce07',
    type: 'text',
    label: t('possible-effects'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce08',
    type: 'text',
    label: t('import-food'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce09',
    type: 'text',
    label: t('name-of-raw'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce10',
    type: 'text',
    label: t('client-phone'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce11',
    type: 'text',
    label: t('seller'),
    border: { borderLeft: 0 }
  },
  {
    name: 'announce12',
    type: 'text',
    label: t('address'),
    border: { borderBottom: 0, borderLeft: 0 }
  }
];

export const productDetailSchema = [
  {
    name: 'productName',
    type: 'text',
    label: t('product-name'),
    grid: 12,
    border: { borderLeft: 0 }
  },
  {
    name: 'image',
    type: 'upload',
    label: t('image'),
    sx: {
      height: 100
    },
    grid: 12,
    border: { borderLeft: 0 }
  },
  {
    name: 'category1Code',
    type: 'category',
    label: t('first-category'),
    grid: 6,
    border: { borderLeft: 0 },
    options: (data) => data?.first
  },
  {
    name: 'category2Code',
    type: 'category',
    label: t('second-category'),
    grid: 6,
    options: (data) => data?.second
  },
  {
    name: 'category3Code',
    type: 'category',
    label: t('third-category'),
    grid: 6,
    border: { borderLeft: 0 },
    options: (data) => data?.third
  },

  {
    name: 'maker',
    type: 'text',
    label: t('manufacturer'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'vendorCode',
    type: 'select',
    label: t('vendor'),
    grid: 6,
    options: (data) => data?.vendors
  },
  {
    name: 'capacity',
    type: 'text',
    label: t('volume'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'capacityType',
    type: 'text',
    label: t('package'),
    grid: 6
  },
  {
    name: 'box',
    type: 'text',
    label: t('box'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'barcode',
    type: 'text',
    label: t('bar-code'),
    grid: 6
  },
  {
    name: 'makeCost',
    type: 'number',
    label: t('cost'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'cost',
    type: 'number',
    label: t('master-price'),
    grid: 6
  },
  {
    name: 'sellCount',
    type: 'number',
    label: t('sales-quantity'),
    grid: 12,
    border: { borderBottom: 0, borderLeft: 0 }
  }
];
