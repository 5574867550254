/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import minibarServices from '@services/minibar';
import MyAutocomplete from '../MyAutocomplete';

const MinibarAutoCompleteV3 = ({
  setMinibar,
  minibar,
  setKeyword,
  disabled,
  client,
  label = true,
  freeSolo = false,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();

  const [itemList, setItemList] = useState([]);
  const [itemInput, setItemInput] = useState('');
  const [minibar2, setMinibar2] = useState(null);
  const [inputModeValue, setInputModeValue] = useState('text'); // Set to 'text' by default

  const getItems = async () => {
    const additionalPayload = {
      clientCode: client?.clientCode || null,
      isOption: Boolean(client?.clientCode)
    };
    const { data } = await minibarServices.getListMinibarAll({
      ...additionalPayload
    });
    if (!data) return;
    setItemList(data);
  };
  const onInputChange = (e, val) => {
    if (setKeyword) {
      setMinibar('');
      setKeyword(val);
    }
  };

  const onChange = (e, val) => {
    if (!val) {
      setMinibar(null);
      setMinibar2(null);
      setInputModeValue('text'); // Set to 'text' when no item is selected
      return;
    }
    setMinibar(val);
    setMinibar2(val);
    setInputModeValue('none'); // Set to 'none' when an item is selected
  };

  useEffect(() => {
    setMinibar2(null);
    setItemList([]);
    getItems();
  }, []);

  useEffect(() => {
    if (client) getItems();
    else {
      setMinibar(null);
      setItemList([]);
    }
  }, [client]);

  return (
    <MyAutocomplete
      label={label && t('common.minibar')}
      textFieldProps={{
        placeholder: t('common.master')
      }}
      value={
        minibar2 ||
        (minibar?.minibarNickname && minibar?.minibarCode ? minibar : null)
      }
      isOptionEqualToValue={(option, value) =>
        parseInt(option.id, 10) === parseInt(value?.id, 10)
      }
      getOptionLabel={(option) =>
        option?.minibarNickname ? option?.minibarNickname : option
      }
      onChange={onChange}
      onInputChange={onInputChange}
      options={itemList}
      disabled={disabled}
      freeSolo={freeSolo}
      onClose={() => {
        if (!minibar) {
          setItemInput('');
          // Set to 'text' when the dropdown is closed
        }
      }}
      sx={{
        '.MuiInputBase-root': {
          bgcolor: !disabled ? 'common.white' : '#f2f2f2',
          height: 40
        },
        ...sx
      }}
      ListboxProps={{ style: { overflow: 'visible' } }}
      renderOption={(option, { ref }) => {
        const { key, id, ...restOption } = option;

        return (
          <MenuItem
            {...restOption}
            key={id}
            sx={{ overflowX: 'hidden' }}
            ref={ref}
          >
            {key}
          </MenuItem>
        );
      }}
      {...rest}
    />
  );
};

export default MinibarAutoCompleteV3;
