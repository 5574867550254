import i18n from '@utils/i18n';
import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages');
const p = i18n.getFixedT(null, null, '');
export const storeColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'typeName',
    label: t('pre-order.industry'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'usage',
    label: t('pre-order.category.stores-matched'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'regDt',
    label: t('pre-order.regDt'),
    minWidth: 80,
    formatter: (value) => value && Formatter.formatTime(value, 'YYYY-MM-DD'),
    align: 'center'
  },
  {
    label: p('common.edit'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    label: p('button.delete'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];

export const categoryColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'categoryName',
    label: `${t('inventory.product')} ${t('product.category')}`,
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'storeCount',
    label: t('pre-order.category.stores-matched-by-category'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'usage',
    label: t('pre-order.category.products-matched-by-category'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'regDt',
    label: t('pre-order.regDt'),
    minWidth: 80,
    formatter: (value) => value && Formatter.formatTime(value),
    align: 'center'
  },
  {
    label: p('common.edit'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },
  {
    label: p('button.delete'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 4
  }
];

export const storeData = [
  {
    industryName: '베이커리',
    matchedStores: 15,
    storeCode: 'dabb78ac-dd6b-4b68-a619-401f294802a8',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '꽃집',
    matchedStores: 0,
    storeCode: '63122eea-8791-42b7-b5df-71809c859b2d',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '커피숍',
    matchedStores: 8,
    storeCode: '7ed49cbb-147e-4b99-b338-34f907b9a9e4',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '아이스크림 가게',
    matchedStores: 12,
    storeCode: '186425fb-a565-434e-8f21-3fcef54f4876',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '서점',
    matchedStores: 0,
    storeCode: '32ebcf04-438f-430b-8630-3823c8e274ce',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '식료품점',
    matchedStores: 10,
    storeCode: 'af1846de-b318-4afb-964d-395caeaeddee',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '패션 매장',
    matchedStores: 6,
    storeCode: '13c6ae79-45af-42d9-9e7c-83ce8f093b2c',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '헬스클럽',
    matchedStores: 19,
    storeCode: '9d928bd4-92f4-4e95-90d8-89dd5f6c8f59',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '전자제품 매장',
    matchedStores: 3,
    storeCode: '56cfa980-3c71-4eb1-97dc-63a2ae598909',
    regDt: '2024-09-23 02:00:34'
  },
  {
    industryName: '편의점',
    matchedStores: 0,
    storeCode: 'b31f4c2e-9264-4edf-8fdc-843920b3b208',
    regDt: '2024-09-23 02:00:34'
  }
  // Add the rest following the same structure...
];

export const categoryType = [
  { value: 'First', label: t('first-category') },
  { value: 'Second', label: t('second-category') },
  { value: 'Third', label: t('third-category') }
  // { value: 'Special', label: t('special-category') }
];

export const actions = {
  UPDATE_STORE: 'UPDATE_STORE',
  DELETE_STORE: 'DELETE_STORE',
  OPTIONS: 'options',
  CREATE_STORE: 'CREATE_STORE',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY'
};

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 15,

      color: '#6F869C',
      bgcolor: '#FCFCFC',
      p: '8px 20px',
      minWidth: 120
    },
    td: {
      p: '15px 12px',
      width: 270
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
  // Actions to take jin  the table
};
export const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  flexGrow: 1,
  width: '100%',
  overflowY: 'auto'
});
