import env from '@constants/env';
import { Stack } from '@mui/material';
import React from 'react';

export default function ViewAdvertiseArea({ item }) {
  React.useEffect(() => {
    console.log('ad item', item);
  }, []);

  return (
    <Stack sx={{ margin: 'auto' }}>
      <video
        width="100%"
        height="100%"
        src={`${env.s3BaseUrl}/${item?.adLink}`}
        controls
      >
        <track kind="captions" />
      </video>
    </Stack>
  );
}
