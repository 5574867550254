/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable unused-imports/no-unused-vars */
import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import SearchBar from '@components/SearchBar';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import memberService from '@services/member';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyLoadingButton from '@components/MyLoadingButton';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { dateFormat } from '@constants/utils';
import moment from 'moment';
import MyDialog from '@components/MyDialog';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { Formatter } from '@utils/formatter';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { conditions, memberConfigColumns } from './enhance';
import RFIDList from './components/RFIDList';
import MemberDetailForm from './components/MemberDetailForm';
import CouponForm from './components/CouponForm';

export default function Member() {
  const [items, setItems] = useState([]);
  const [memberRFID, setMemberRFID] = useState(undefined);
  const [memberDetail, setMemberDetail] = useState(undefined);
  const [coupon, setCoupon] = useState(undefined);
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    totalRow: 0
  });

  const [searchParams, setSearchParams] = useState({
    clientCode: '',
    phone: '',
    name: '',
    sex: null,
    age: '',
    email: '',
    keyWord: '',
    page: 0
  });
  const [client, setClient] = useState(null);
  const [condition, setCondition] = useState('phone');

  const getActionButtons = (item) => (
    <Stack direction="row" alignItems="center">
      <Button
        sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
        onClick={(e) => {
          e.stopPropagation();
          setMemberDetail(item);
        }}
      >
        {t('pages.member.button.detail')}
      </Button>
      <Button
        sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
        onClick={async (e) => {
          e.stopPropagation();
          setNotifyDialog({
            title: t('pages.member.dialog.title.wouldYouLikeToSendALink'),
            onOk: async () => {
              const rs = await memberService.sendLink({
                phone: item?.encodedPhone
              });
              if (rs?.error) {
                dispatch(setError(error2Text(item?.clientContact)));
              } else {
                setNotifyDialog(undefined);
              }
            },
            setOpen: () => {
              setNotifyDialog(undefined);
            }
          });
        }}
      >
        {t('pages.member.button.sendLink')}
      </Button>
      <Button
        sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
        onClick={(e) => {
          e.stopPropagation();
          setCoupon(item);
        }}
      >
        {t('pages.member.button.coupon')}
      </Button>
      <Button
        sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
        onClick={(e) => {
          e.stopPropagation();
          setNotifyDialog({
            title: t('dialog.title.areYouSureDeleteThisRecord'),
            onOk: () => {
              handleDelete(item?.memberCode);
              setNotifyDialog(undefined);
            },
            setOpen: () => {
              setNotifyDialog(undefined);
            }
          });
        }}
      >
        {t('button.delete')}
      </Button>
    </Stack>
  );

  const handleDelete = async (e) => {
    const result = await memberService.deleteMember(e);
    if (!result?.error) {
      getListMember(searchParams);
    } else {
      dispatch(
        setError({
          title: t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain')
        })
      );
    }
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ ...searchParams, page });
    const query = objToParams(
      ignoreEmpty({
        ...searchParams,
        page: page + 1
      })
    );
    navigate(query);
  };

  const handleSearch = async () => {
    const data = {
      ...searchParams,
      phone: '',
      name: '',
      sex: null,
      age: '',
      email: '',
      [condition]:
        condition === 'sex'
          ? getConditionSex(searchParams.keyWord)
          : searchParams.keyWord,
      page: 0
    };
    const query = objToParams(ignoreEmpty({ ...data, page: data?.page + 1 }));
    navigate(query);
    const result = await memberService.getListMember(data);
    if (!result?.error) {
      const { items, page, totalPage, totalRow } = result.data;
      setPagination({
        ...pagination,
        page,
        totalRow,
        count: totalPage || 0
      });
      setItems(items || []);
    }
  };

  const getConditionSex = (sex) => {
    if (sex === 'male' || sex === 'Male' || sex === '남') {
      return 'Male';
    }
    if (sex === 'female' || sex === 'Female' || sex === '여' || sex === '녀') {
      return 'Female';
    }
    return null;
  };

  const getListMember = async (searchParams) => {
    const data = {
      ...searchParams,
      phone: '',
      name: '',
      sex: null,
      age: '',
      email: '',
      [condition]:
        condition === 'sex'
          ? getConditionSex(searchParams.keyWord)
          : searchParams.keyWord,
      page: searchParams.page
    };
    const result = await memberService.getListMember(searchParams);
    if (!result?.error) {
      const { items, page, totalPage, totalRow } = result.data;
      setPagination({
        ...pagination,
        page,
        totalRow,
        count: totalPage || 0
      });
      setItems(items || []);
    }
  };

  const handleChangeInput = (e) => {
    if (e?.key === 'selection') {
      setSearchParams({
        ...searchParams,
        startDate: moment(e.startDate).format(dateFormat),
        endDate: moment(e.endDate).format(dateFormat)
      });
    } else {
      const { name, value } = e.target;
      setSearchParams({ ...searchParams, [name]: value });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { phone, name, sex, age, email } = query;
    setCondition(
      (phone && 'phone') ||
        (name && 'name') ||
        (sex && 'sex') ||
        (age && 'age') ||
        (email && 'email') ||
        'phone'
    );
    setClient({ ...client, clientCode: query?.clientCode || null });
    setSearchParams({
      phone: query?.phone || '',
      name: query?.name || '',
      sex: query?.sex ? getConditionSex(query?.keyWord) : null,
      age: query?.age || '',
      email: query?.email || '',
      clientCode: query?.clientCode || null,
      keyWord: query?.keyWord || '',
      page: query?.page - 1 || 0
    });
    getListMember({
      phone: query?.phone || '',
      name: query?.name || '',
      sex: query?.sex ? getConditionSex(query?.keyWord) : null,
      age: query?.age || '',
      email: query?.email || '',
      clientCode: query?.clientCode || null,
      keyWord: query?.keyWord || '',
      page: query?.page - 1 || 0
    });
  }, [location?.search]);

  const handleCloseCoupon = () => {
    setIsSubmitting(false);
    setCoupon(undefined);
    getListMember(searchParams);
  };

  const handleSubmitCoupon = async (e) => {
    setIsSubmitting(true);
    const result = await memberService.createCoupon(e);
    if (!result.error) {
      handleCloseCoupon();
    } else {
      setNotifyDialog({
        title: t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain'),
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
      setIsSubmitting(false);
    }
  };

  return (
    <HelmetContainer title={t('pageTitle.member')}>
      <SearchBar>
        <FormControl>
          <FormControlLabel
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <ClientAutoCompleteV2
                client={client}
                setClient={(client) => {
                  setClient(client);
                  setSearchParams({
                    ...searchParams,
                    clientCode: client?.clientCode
                  });
                }}
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              />
            }
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            label={t('pages.member.labels.condition')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <MySelect
                value={condition}
                name={condition}
                onChange={(e) => {
                  setCondition(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    phone: '',
                    name: '',
                    sex: 'All',
                    age: '',
                    email: '',
                    keyWord: ''
                  });
                }}
                data={conditions(t)}
                sx={{ width: '100%' }}
              />
            }
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <MyTextField
                value={searchParams.keyWord}
                size="small"
                name="keyWord"
                onChange={handleChangeInput}
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              />
            }
          />
        </FormControl>
        <MyLoadingButton
          variant="contained"
          sx={{ ml: 1.5 }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </MyLoadingButton>
        {/* <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button> */}
      </SearchBar>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: '30px' }}>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}
            {'  '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        onRowClick={(e) => {
          setMemberRFID(e?.memberCode || '');
        }}
        columns={memberConfigColumns(t)}
        data={items}
        pagination={pagination}
        getActionButtons={getActionButtons}
        minusHeight={235}
        onChangePage={(e, page) => handleOnChangePage(page)}
      />

      <MyDialog
        open={memberRFID !== undefined}
        setOpen={() => setMemberRFID(undefined)}
        title={t('pages.member.dialog.title.RFIDStatusByShelf')}
        hideDialogButton
        hasCloseButton
        sx={{
          width: '100%',
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <RFIDList
          memberCode={memberRFID || ''}
          onClose={() => setMemberRFID(undefined)}
          cancelButtonProps={{
            text: t('button.cancel')
          }}
          submitButtonProps={{
            text: t('button.register')
          }}
        />
      </MyDialog>

      <MyDialog
        open={coupon !== undefined}
        setOpen={() => (!isSubmitting ? handleCloseCoupon() : () => {})}
        title={t('pages.member.dialog.title.couponIssue')}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <CouponForm
          coupon={coupon}
          onClose={handleCloseCoupon}
          onSubmit={handleSubmitCoupon}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t('button.issue')
          }}
        />
      </MyDialog>

      <MyDialog
        open={memberDetail !== undefined}
        setOpen={() => setMemberDetail(undefined)}
        title={t('pages.member.dialog.title.paymentDetail', {
          phoneMember: Formatter.fKrPhone(memberDetail?.phone)
        })}
        hasCloseButton
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
        okTitle={t('button.close')}
      >
        <MemberDetailForm memberCode={memberDetail?.memberCode} />
      </MyDialog>
      <MyDialog
        open={notifyDialog !== undefined}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
    </HelmetContainer>
  );
}
