import HelmetContainer from '@components/HelmetContainer';
import InputFile from '@components/InputFile';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import ZombieTable from '@components/MyTable/ZombieTable';
import {
  Button,
  CircularProgress,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import registerPerformService from '@services/registerPerfom';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const Register = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const onInput = (e) => {
    const file = e.target.files[0];
    setValue({ ...value, file, fileName: file?.name });
  };
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);
  const register = async () => {
    const dataSubmit = new FormData();
    dataSubmit.append('clientCode', client?.clientCode);
    dataSubmit.append('date', formatTime(new Date(), 'YYYY-MM-DD'));
    dataSubmit.append('file', value?.file);
    setLoading(true);
    const rs = await registerPerformService.register(dataSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      setLoading(false);
    } else {
      setLoading(false);
    }
    setClient(null);
    setValue(null);
  };
  return (
    <HelmetContainer title={t('pageTitle.register')} content="Register page">
      <ZombieTable
        columns={[
          {
            name: 'picName',
            label: t('labels.group.client'),
            width: '33%',
            align: 'center'
          },
          {
            name: 'picName',
            label: t('pages.inventory.date'),
            width: '33%',
            align: 'center'
          },
          {
            name: 'picName',
            label: t('button.upload'),
            width: '33%',
            align: 'center'
          }
        ]}
      >
        <TableBody>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #EEEEEE' }}>
              <ClientAutoCompleteV2
                label={false}
                client={client}
                setClient={setClient}
                sx={{ width: '100%' }}
              />
            </TableCell>
            <TableCell
              sx={{ textAlign: 'center', borderRight: '1px solid #EEEEEE' }}
            >
              {formatTime(new Date(), 'YYYY - MM - DD')}
            </TableCell>
            <TableCell>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    color: 'primary.darker',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    textDecoration: 'underline',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '20px'
                  }}
                  visibility={!value?.fileName && 'hidden'}
                >
                  {value?.fileName}
                </Typography>
                <Stack flexDirection="row" gap={1}>
                  <InputFile
                    loading={loading}
                    disabled={!client}
                    onChange={onInput}
                  />
                  {value?.file && (
                    <Button
                      onClick={register}
                      variant="contained"
                      sx={{ height: 35 }}
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
                      OK
                    </Button>
                  )}
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </ZombieTable>
    </HelmetContainer>
  );
};

export default Register;
