import MyTable from '@components/MyTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_INT } from '@constants/utils';
import { fillOutColumns } from '../enhance';

const FillOutForm = ({ items, setItems }) => {
  const { t } = useTranslation(0);

  const handleChange = (item, e) => {
    const val = parseInt(e.target.value.replace(/,/g, '') || 0, 10);
    const index = items.findIndex((i) => i.productCode === item.productCode);
    setItems((oldItems) => [
      ...oldItems.slice(0, index),
      {
        ...oldItems.slice(index, index + 1)?.[0],
        [e.target.name]: Math.min(val, MAX_INT)
      },
      ...oldItems.slice(index + 1)
    ]);
  };

  const configColumns = fillOutColumns(t, handleChange);

  return <MyTable rerender columns={configColumns} data={items} />;
};

export default FillOutForm;
