import RestService from './RestService';

const ChannelService = {
  async getChannels() {
    try {
      const res = await new RestService().setPath('channel').get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};
export default ChannelService;
