import RestService from './RestService';

const profitAndLossService = {
  async getProfitAndLoss(id) {
    try {
      const res = await new RestService()
        .setPath(`performance/profit-status/${id}`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default profitAndLossService;
