import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TableCell,
  TableRow
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import LabelBox from '@components/LabelBox';
import CustomImage from '@components/CustomImage';
import NoData from '@components/NoData';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';

import { productServices, clientOrderServices } from '@services/index';

import { MAX_INT_VALUE } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import { setError } from '@store/reducers/appReducer';

import { addProductColumns as columns } from '../../enhance';

const formatAmount = (value) => new Intl.NumberFormat('en-CA').format(value);
const formatCurrency = (value) => new Intl.NumberFormat('en-CA').format(value);

const AddProductTableRow = ({ item, selectedItem, onCheckBoxClick }) => {
  return (
    <TableRow>
      <TableCell>
        <Checkbox
          onClick={() => onCheckBoxClick(item)}
          checked={selectedItem?.productCode === item.productCode}
        />
      </TableCell>

      <TableCell>
        <CustomImage fileName={item.imgUrl} fileLink={item.imgUrl} />
      </TableCell>
      <TableCell>{item?.productName}</TableCell>
      <TableCell>
        {item?.stockQuantity ? formatAmount(item.stockQuantity) : null}
      </TableCell>
      <TableCell>{item?.cost ? formatCurrency(item.cost) : null}</TableCell>
      <TableCell>
        {item?.masterPrice ? formatCurrency(item.masterPrice) : null}
      </TableCell>
    </TableRow>
  );
};

const AddProductDialog = ({ open, setOpen, clientOrderData, fetchData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState('0');
  const [productName, setProductName] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [endMore, setEndMore] = useState(false);

  const getData = async (param) => {
    const result = await productServices.getProductsByFilter(param);
    setData(result.data?.items || []);
  };

  const fetchMoreAPI = async () => {
    if (data?.length < 200 && !endMore) {
      setHasMore(true);
      const res = await productServices.getProductsByFilter({
        productName,
        page: page + 1,
        size: 20
      });
      setPage(page + 1);
      setEndMore(!res?.data?.items?.length);
      if (!res.data || res?.data?.items?.length === 0) {
        setHasMore(false);
      } else {
        setData(data.concat(res?.data?.items));
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  };

  const handleSearch = () => {
    setData([]);
    setSelectedItem();
    setQuantity('0');
    getData({ productName, page: 0, size: 20 });
    setEndMore(false);
    setPage(0);
  };

  const handleCheckBoxClick = (item) => {
    if (item.productCode === selectedItem?.productCode) setSelectedItem();
    else setSelectedItem(item);
  };

  const handleRegister = async () => {
    setLoading(true);
    const result = await clientOrderServices.addProduct(
      clientOrderData?.orderCode,
      {
        productCode: selectedItem?.productCode,
        quantity: parseInt(quantity, 10)
      }
    );
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setOpen(false);
      fetchData();
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCancelButton
      hasCloseButton
      okTitle={t('button.register')}
      title={t('pages.curation.add-product')}
      onOk={handleRegister}
      sx={{
        maxWidth: 600,
        '.MuiDialogContent-root': {
          overflow: 'overlay'
        }
      }}
      loading={loading}
      disabled={!selectedItem || quantity === '0'}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ p: '15px 20px', bgcolor: '#F8F8F8', borderRadius: '12px' }}
      >
        <MyTextField
          label={t('pages.curation.product-name')}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          disabled={!productName}
          variant="contained"
          sx={{ height: 36 }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </Stack>
      <ZombieTable
        columns={columns}
        sx={{ my: 1.875, maxHeight: 410 }}
        position="relative"
        stickyHeader
        fetchMore={fetchMoreAPI}
      >
        <CustomTableBody>
          {data?.length > 0 ? (
            <>
              {data.map((item) => (
                <AddProductTableRow
                  key={item.productCode}
                  item={item}
                  selectedItem={selectedItem}
                  onCheckBoxClick={handleCheckBoxClick}
                />
              ))}
              {hasMore && (
                <TableRow>
                  <TableCell
                    sx={{
                      height: '30px !important',
                      p: '10px 0 20px 0 !important'
                    }}
                    colSpan={5}
                  >
                    <i>로딩... {'  '}</i>
                    <CircularProgress size={12} color="primary" />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow sx={{ height: 200 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                <NoData />
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{
          border: '1px solid #d3d3d3',
          borderRadius: 1,
          overflow: 'hidden',
          pr: 3
        }}
      >
        <LabelBox title={t('pages.curation.quantity')} />
        <MyTextField
          fullWidth
          value={quantity}
          onChange={(e) =>
            setQuantity(
              formatAmount(
                Formatter.fStringToRangeNumber(e.target.value, MAX_INT_VALUE)
              )
            )
          }
        />
      </Stack>
    </MyDialog>
  );
};

export default AddProductDialog;
