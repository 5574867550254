/* eslint-disable no-nested-ternary */
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const picking = i18n.getFixedT(null, null, 'pages.picking');

export const inventorySearchSelectOptions = [
  { value: 'productName', label: p('product-name') },
  { value: 'barcode', label: p('bar-code') }
];
export const stockingColumns = () => [
  {
    name: 'regDt',
    label: t('date'),
    minWidth: 120,
    align: 'center',
    render: ({ regDt }) => regDt && Formatter.formatTime(regDt)
  },

  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 160,
    align: 'center',
    render: ({ expiryDate }) =>
      expiryDate && Formatter.formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'quantity',
    label: t('stocking-quantity'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 160,
    align: 'center'
  },

  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'maker',
    label: p('manufacturer'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'registerAdmin',
    label: picking('registerAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'modifyAdmin',
    label: picking('modifyAdmin'),
    minWidth: 120,
    align: 'center'
  }
];
