/* eslint-disable no-nested-ternary */
import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import {
  Button,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Checkbox
} from '@mui/material';
import { CancelRounded, Delete } from '@mui/icons-material';
import AlertDialog from '@components/MyDialog/AlertDialog';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import kioskServices from '@services/kiosk';
import MyTable from '@components/MyTable';
import useResponsive from '@hooks/useResponsive';
import CopyToClipboardButton from '@components/CopyToClipboard';
import MobileActionsDialog from './components/MobileActionsDialog';
import KioskDialog from './components/KioskDialog';
import KioskForm from './components/KioskForm';
import HistoryForm from './components/HistoryForm';
import { kioskConfigColumns, actions, mobileColumns } from './enhance';

function Kiosk() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 1000);
  const [items, setItems] = useState([]);
  const [editKiosk, setEditKiosk] = useState(undefined);
  const [errorHistoryKiosk, setErrorHistoryKiosk] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const [searchItem, setSearchItem] = useState('');
  const [filteredKiosks, setFilteredKiosks] = useState([]);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [openClear, setOpenClear] = useState(false);

  const [mobileActions, setMobileActions] = useState({
    title: '',
    content: '',
    isOpen: false,
    action: '',
    item: {}
  });
  const [selected, setSelected] = useState({
    title: '',
    content: '',
    isOpen: false,
    okTitle: t('pages.kiosk.dialog.title.okTitle'),
    offTitle: t('pages.kiosk.dialog.title.offTitle'),
    action: '',
    item: {}
  });
  const [dialog, setDialog] = useState({
    openConfirm: false
  });
  const [loading, setLoading] = useState(false);

  const getListKiosk = async () => {
    setLoading(true);
    const storedSearchItem = localStorage.getItem('searchItem');
    const result = await kioskServices.getListKiosk();
    if (!result?.error) {
      setItems(result.data || []);
      if (storedSearchItem) {
        const filteredItems = result?.data?.filter((item) =>
          `${item.kioskNickname?.toLowerCase()}${item?.minibarNickname?.toLowerCase()}`.includes(
            storedSearchItem.toLowerCase()
          )
        );
        setFilteredKiosks(filteredItems);
      } else {
        setFilteredKiosks(result.data || []);
      }
    }
    setLoading(false);
  };

  const handleRowClick = (item) => {
    setEditKiosk(item);
  };
  const handleChangeModification = (name, { target: { value } }) => {
    setSelected({
      ...selected,
      item: {
        ...selected.item,
        [name]: value
      }
    });
  };

  const handleSearchChange = (event) => {
    const searchTerm = event?.target.value;
    setSearchItem(searchTerm);
    const filteredItems = items.filter((item) =>
      `${item.kioskNickname?.toLowerCase()}${item?.minibarNickname?.toLowerCase()}`.includes(
        searchTerm.toLowerCase()
      )
    );
    setFilteredKiosks(filteredItems);
  };

  const actionComponent = (kioskCode = '', isNeedRepair = false) => {
    return (
      <Button
        variant="outlined"
        component="label"
        sx={{
          height: '36px',
          p: '14px 15px',
          marginRight: 2,
          backgroundColor: isNeedRepair ? 'yellow' : 'none'
        }}
        onClick={(e) => {
          e.stopPropagation();
          setErrorHistoryKiosk(kioskCode);
        }}
      >
        {t('button.errorHistory')}
      </Button>
    );
  };

  const handleCloseEditKiosk = () => {
    setEditKiosk(undefined);
    setIsSubmitting(false);
    getListKiosk();
  };

  const handleCloseErrorHistoryKiosk = () => {
    setErrorHistoryKiosk(undefined);
    getListKiosk();
  };
  // const getFilterIcon = (col) => {
  //   switch (col?.name) {
  //     case 'kioskNickname':
  //       setIsColOpen(true);
  //       return <MenuOpenIcon sx={{ width: 15, height: 15 }} />;

  //     default:
  //       return '';
  //   }
  // };
  const handleSubmitKiosk = async (data) => {
    setIsSubmitting(true);
    const result = await kioskServices.updateKiosk(editKiosk.kioskCode, data);
    if (!result.error) {
      handleCloseEditKiosk();
    } else {
      setNotifyDialog({
        title: t('dialog.title.pleaseEnterTheRequestedInformation'),
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
      setIsSubmitting(false);
    }
  };
  const getActionButtons = (item, actionType) => {
    if (actions[actionType]) {
      let cellContent;

      switch (actionType) {
        case actions.updateYn:
          cellContent = (
            <Checkbox
              checked={item?.[actionType] === 'Y'}
              onClick={() => onSubmit(actions.updateYn, item)}
            />
          );
          break;
        case actions.kioskYn:
        case actions.endYn:
        case actions.outYn:
        case actions.redoYn:
        case actions.inspectYn:
          cellContent = (
            <Typography
              color={item?.[actionType] === 'Y' ? 'blue' : 'red'}
              onClick={(e) => {
                e.stopPropagation();
                onAction(actionType, item);
              }}
            >
              {item?.[actionType]}
            </Typography>
          );
          break;
        case actions.logoYn:
          cellContent = ['L7 호텔'].includes(item?.appNickname) ? (
            <Typography
              color={item.logoYn === 'Y' ? 'blue' : 'red'}
              onClick={(e) => {
                e.stopPropagation();
                onAction(actions.updateLogoYn, item);
              }}
            >
              {item?.logoYn || 'N'}
            </Typography>
          ) : (
            ''
          );
          break;
        case actions.delete:
          cellContent = (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItem(item);
                setDialog({ ...dialog, openConfirm: true });
              }}
            >
              <Delete />
            </IconButton>
          );
          break;

        case actions.hwRestart:
          cellContent = (
            <Button
              variant="contained"
              sx={{ borderRadius: 1, height: '40px' }}
              onClick={(e) => {
                e.stopPropagation();
                onAction(actions.hwRestart, item);
              }}
            >
              {t('pages.kiosk.labels.restart')}
            </Button>
          );
          break;
        default:
          cellContent = (
            <Button
              variant="outlined"
              sx={{ borderRadius: 1, height: '40px' }}
              onClick={(e) => {
                e.stopPropagation();
                onAction(actions.restart, item);
              }}
            >
              {t('pages.kiosk.labels.restart')}
            </Button>
          );
          break;
      }
      return cellContent;
    }
    switch (actionType) {
      case 1:
        return <CopyToClipboardButton value={item?.anydeskId} />;
      default:
        return (
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              component="label"
              sx={{
                height: '30px',
                p: '8px 10px',
                marginRight: 2
              }}
              onClick={(e) => {
                e.stopPropagation();
                setMobileActions({ ...mobileActions, isOpen: true, item });
              }}
            >
              {t('pages.kiosk.labels.management')}
            </Button>
            <Button
              variant="outlined"
              component="label"
              sx={{
                height: '30px',
                p: '8px 10px',
                backgroundColor: item?.isNeedRepair === 'Y' ? 'yellow' : 'none'
              }}
              onClick={(e) => {
                e.stopPropagation();
                setErrorHistoryKiosk(item?.kioskCode);
              }}
            >
              {t('button.errorHistory')}
            </Button>
          </Stack>
        );
    }
  };

  function onAction(actionType, kioskValue) {
    switch (actionType) {
      case actions.kioskYn:
        setSelected({
          ...selected,
          action: actions.kioskYn,
          title: t('pages.kiosk.labels.kioskMode'),
          content: t('pages.kiosk.dialog.content.kioskMode'),
          okTitle: t('pages.kiosk.dialog.title.okTitle'),
          offTitle: t('pages.kiosk.dialog.title.offTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.redoYn:
        setSelected({
          ...selected,
          action: actions.redoYn,
          title: t('pages.kiosk.labels.restartMode'),
          content: t('pages.kiosk.dialog.content.restartMode'),
          okTitle: t('pages.kiosk.dialog.title.okTitle'),
          offTitle: t('pages.kiosk.dialog.title.offTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.outYn:
        setSelected({
          ...selected,
          action: actions.outYn,
          title: t('pages.kiosk.labels.screenOutageMode'),
          content: t('pages.kiosk.dialog.content.screenOutageMode'),
          okTitle: t('pages.kiosk.dialog.title.okTitle'),
          offTitle: t('pages.kiosk.dialog.title.offTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.endYn:
        setSelected({
          ...selected,
          action: actions.endYn,
          title: t('pages.kiosk.labels.originServer'),
          content: t('pages.kiosk.dialog.content.originServer'),
          okTitle: t('pages.kiosk.dialog.title.okTitle'),
          offTitle: t('pages.kiosk.dialog.title.offTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.logoYn:
        setSelected({
          ...selected,
          action: actions.logoYn,
          title: t('pages.kiosk.labels.logoRelated'),
          content: t('pages.kiosk.dialog.content.logoRelated'),
          okTitle: t('pages.kiosk.dialog.title.okTitle'),
          offTitle: t('pages.kiosk.dialog.title.offTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.inspectYn:
        console.log('updating inspection');
        setSelected({
          ...selected,
          action: actions.inspectYn,
          title: t('pages.kiosk.labels.inspection'),
          okTitle: t('pages.kiosk.dialog.title.inspectionOkTitle'),
          offTitle: t('pages.kiosk.dialog.title.inspectionCancelTitle'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.restart:
        console.log('updating restarting');
        setSelected({
          ...selected,
          action: actions.restart,
          title: t('pages.kiosk.labels.restart'),
          okTitle: t('pages.kiosk.labels.restart'),
          content: t('pages.kiosk.dialog.content.restart'),
          isOpen: true,
          item: kioskValue
        });
        break;
      case actions.hwRestart:
        console.log('updating  device restarting');
        setSelected({
          ...selected,
          action: actions.hwRestart,
          title: t('pages.kiosk.labels.hwRestart'),
          okTitle: t('pages.kiosk.labels.restart'),
          content: t('pages.kiosk.dialog.content.hwRestart'),
          isOpen: true,
          item: kioskValue
        });
        break;
      default:
        console.log('defaultCase');
    }
  }
  useEffect(() => {
    const storedSearchItem = localStorage.getItem('searchItem');
    if (storedSearchItem) {
      setSearchItem(storedSearchItem);
    }
    getListKiosk(storedSearchItem || '');
  }, []);

  useEffect(() => {
    localStorage.setItem('searchItem', searchItem);
  }, [searchItem]);
  const onSubmit = async (action, kioskValue) => {
    if (action === actions.updateYn) {
      const data = { setOption: !(kioskValue?.updateYn === 'Y') };
      const result = await kioskServices.updateKioskStatus(
        kioskValue?.kioskCode,
        data
      );
      if (!result.error) {
        setFilteredKiosks((prev) => {
          const updatedList = prev.map((k) => {
            if (k?.kioskCode === kioskValue?.kioskCode) {
              return {
                ...k,
                updateYn: k?.updateYn === 'Y' ? 'N' : 'Y'
              };
            }
            return k; // return unmodified item for other kiosks
          });
          return updatedList;
        });
        dispatch(setSuccess({ message: t('info.success.content') }));
      } else {
        dispatch(setError(error2Text(result?.error)));
        getListKiosk();
      }
    } else {
      const sendKioskCode = selected?.item?.kioskCode;
      let sendData = { setMode: kioskValue };
      let rs;
      switch (action) {
        case actions.kioskYn:
          rs = await kioskServices.updateKioskMode(sendKioskCode, sendData);

          break;
        case actions.redoYn:
          rs = await kioskServices.updateKioskRedo(sendKioskCode, sendData);

          break;
        case actions.outYn:
          rs = await kioskServices.updateKioskOut(sendKioskCode, sendData);

          break;
        case actions.endYn:
          rs = await kioskServices.updateKioskEnd(sendKioskCode, sendData);
          break;
        case actions.logoYn:
          rs = await kioskServices.updateKioskLogo(sendKioskCode, sendData);
          break;
        case actions.inspectYn:
          sendData = { flag: kioskValue, time: selected?.item?.time || ' ' };
          rs = await kioskServices.updateKioskInspect(sendKioskCode, sendData);

          break;
        case actions.restart:
          rs = await kioskServices.updateKioskReboot(sendKioskCode, sendData);
          break;
        case actions.hwRestart:
          rs = await kioskServices.updateDeviceReboot(sendKioskCode, sendData);
          break;
        case actions.delete:
          rs = await kioskServices.updateKioskUseYn(selectedItem?.kioskCode);
          break;
        default:
          console.log('defaultCase');
      }
      if (!rs.error) {
        getListKiosk();
        dispatch(setSuccess({ message: t('info.success.content') }));
        setSelected({ ...selected, open: false });
        setDialog({ ...dialog, openConfirm: false });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };

  return (
    <HelmetContainer title={t('pageTitle.kiosk')}>
      <SearchBar sx={{ justifyContent: 'space-between' }}>
        <Stack width={300}>
          <MyTextField
            label={t('pages.kiosk.labels.nickname')}
            texfieldStyle={{ height: '10px' }}
            value={searchItem}
            onChange={handleSearchChange}
            onKeyPress={({ charCode }) => charCode === 13 && handleSearchChange}
            placeholder={t('pages.kiosk.labels.nickname')}
            onClear={() => {
              setSearchItem('');
            }}
            onFocus={() => {
              setOpenClear(true);
            }}
            onBlur={() =>
              setTimeout(() => {
                setOpenClear(false);
              }, 200)
            }
            InputProps={{
              endAdornment: searchItem && (
                <InputAdornment
                  position="end"
                  sx={{
                    visibility: !searchItem && 'hidden'
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handleSearchChange({ target: { value: '' } })
                    }
                  >
                    <CancelRounded
                      sx={{
                        fontSize: { md: '24px', xs: '24px' },
                        color: 'lightgrey'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: { md: 15, sm: 10 },
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {filteredKiosks?.length || 0}
            </Typography>
          </Typography>
        </Stack>
      </SearchBar>

      <MyTable
        hover
        rerender
        maxHeight={isMobile && 'none'}
        columns={
          isMobile
            ? mobileColumns(t)
            : kioskConfigColumns(
                actionComponent,
                t,
                onAction,
                onSubmit,
                setEditKiosk
              )
        }
        onRowClick={handleRowClick}
        getActionButtons={getActionButtons}
        data={filteredKiosks}
        sx={
          isMobile && {
            '.MuiTableCell-root': {
              py: 0,
              px: 0.5,
              fontSize: '10px'
            },
            mx: 1,
            width: 'auto'
          }
        }
        minusHeight={isMobile ? -235 : 150}
        loadingComponent={
          loading ? (
            <Stack>
              <CircularProgress size={100} />
            </Stack>
          ) : (
            false
          )
        }
      />
      <MyDialog
        open={editKiosk !== undefined}
        setOpen={!isSubmitting ? handleCloseEditKiosk : () => {}}
        title={t('pages.kiosk.dialog.title.editKioskInformation')}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <KioskForm
          item={editKiosk}
          onClose={handleCloseEditKiosk}
          onSubmit={handleSubmitKiosk}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t('button.save')
          }}
          hasCancelButton
          hasCloseButton
        />
      </MyDialog>

      <HistoryForm
        kioskCode={errorHistoryKiosk}
        onClose={handleCloseErrorHistoryKiosk}
      />
      <MyDialog
        open={notifyDialog !== undefined}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
      {isMobile && (
        <MobileActionsDialog
          open={mobileActions?.isOpen}
          setOpen={() => setMobileActions({ ...mobileActions, isOpen: false })}
          item={mobileActions?.item}
        />
      )}

      <KioskDialog
        title={selected?.title || 'Update'}
        open={selected?.isOpen}
        setOpen={() => setSelected({ ...selected, isOpen: false })}
        onOk={() => onSubmit(selected?.action, 1, selected?.item)}
        onOff={() => onSubmit(selected?.action, 0, selected?.item)}
        okTitle={selected?.okTitle}
        offTitle={selected?.offTitle || 'OFF'}
        hasCancelButton={[actions.restart, actions.hwRestart].includes(
          selected.action
        )}
        hasOtherActionButton
        hasCloseButton
      >
        {selected?.action === actions.inspectYn ? (
          <MyTextField
            size="small"
            label={`${t('pages.kiosk.labels.inspection')} ${t(
              'pages.kiosk.labels.message'
            )}`}
            placeholder={t('pages.kiosk.labels.message')}
            value={selected?.item?.time}
            name="message"
            onChange={(e) => handleChangeModification('time', e)}
            sx={{
              paddingLeft: 0,
              paddingRight: 2,
              width: '100%',
              margin: '0px 0px',
              mb: 1
            }}
          />
        ) : (
          <Typography>{selected?.content}</Typography>
        )}
      </KioskDialog>
      {selectedItem && (
        <AlertDialog
          open={dialog.openConfirm}
          setOpen={() =>
            setDialog({
              ...dialog,
              openConfirm: false,
              type: null,
              okTitle: null,
              title: null,
              subTitle: null,
              buttonVariant: null
            })
          }
          onOk={() => onSubmit('delete')}
          okTitle={t('button.delete')}
          isAlert
          title={dialog?.title || t('common.confirm-delete-new-title')}
          subTitle={dialog?.subtitle || t('common.confirm-delete-new-subtitle')}
          type={dialog?.buttonVariant}
          hasCancelButton
        />
      )}
    </HelmetContainer>
  );
}
export default Kiosk;
