import RestService from './RestService';

const clientServices = {
  // ?keyword=&page=0&size=20
  async getListClient(params = {}) {
    try {
      const res = await new RestService()
        .setPath('client')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getListClientAll(params = {}) {
    try {
      const res = await new RestService()
        .setPath('client/all')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getClient(clientCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`client/${clientCode}`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteClient(clientCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`client/${clientCode}`)
        .setConfig({ params })
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createClient(data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`client`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateClient(clientCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`client/${clientCode}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async initialGoldPerformance(data = {}, params = {}) {
    const { clientCode } = data;
    try {
      const res = await new RestService()
        .setPath(`client/${clientCode}/gold-performance`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async renewalContract(data = {}, params = {}) {
    const { clientCode } = data;
    try {
      const res = await new RestService()
        .setPath(`client/${clientCode}/renewal-contract`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default clientServices;
