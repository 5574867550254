import { Box } from '@mui/material';
import { styled } from '@mui/system';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.AD');

export const advertisementColumns = (editAd, viewVideo) => [
  {
    name: 'adName',
    label: t('AD-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'adNickname',
    label: t('AD-nickname'),
    minWidth: 160,
    align: 'center',
    cellProps: { sx: { color: '#007bff' } },
    onClick: ({ adCode }) => editAd(adCode)
  },
  {
    name: 'adVersion',
    label: t('version'),
    minWidth: 160,
    cellProps: { sx: { color: '#007bff' } },
    align: 'center',
    onClick: (item) => viewVideo(item)
  }
];
export const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  overflowY: 'auto'
});
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 135
    },
    td: {
      p: '10px 12px',
      width: 300
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};
