import { tableTypes } from '@constants/utils';

import { dayOfWeekShort, formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages');
const preOrderT = i18n.getFixedT(null, null, 'pages.pre-order');
export const requiredFields = [
  'storeTypeCode',
  'storeName',
  'managerFullName',
  'managerContact',
  'address',
  'contact',
  'latitude',
  'longitude',
  'orderFixDays',
  'businessHours'
];

export const localeOptions = [
  { value: 'all', label: g('common.total') },
  { value: 'ko', label: preOrderT('locales.ko') },
  { value: 'jp', label: preOrderT('locales.jp') }
];
export const localeRadioOptions = [
  { value: 'ko', label: preOrderT('locales.ko') },
  { value: 'jp', label: preOrderT('locales.jp') }
];

const localeRenderObject = {
  ko: preOrderT('locales.ko'),
  jp: preOrderT('locales.jp')
};

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },

  {
    name: 'preorderLocale',
    label: t('pre-order.locales.label'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => localeRenderObject[value] || value
  },
  {
    name: 'storeName',
    label: t('pre-order.store-name'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'storeType',
    label: t('pre-order.industry'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'address',
    label: t('pre-order.address'),
    minWidth: 310,
    align: 'center'
  },
  {
    name: 'contact',
    label: ` ${t('pre-order.store')}  ${t('pre-order.contact')}`,
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'workingDays',
    label: t('pre-order.working-day'),
    minWidth: 100,
    align: 'center',
    formatter: (value) => value?.map((day) => dayOfWeekShort(day))?.join(', ')
  },
  {
    name: 'orderFixDays',
    label: t('pre-order.cancel-day'),
    minWidth: 120,
    align: 'center',
    hasToolTip: true,
    // toolTipTitle: s('salePrice'),
    toolTipContent: t('pre-order.tooltip.confirm-date'),
    formatter: (value) => t('pre-order.n-day-before', { day: value })
  },

  {
    name: 'delete',
    label: g('button.delete'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const addProductColumns = [
  {
    label: g('pages.curation.select'),
    align: 'center',
    minWidth: 60
  },
  {
    label: g('pages.curation.image'),
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: g('pages.curation.product-name'),
    align: 'center',
    minWidth: 130
  },
  {
    name: 'barcode',
    label: g('pages.minibarOrder.barcode'),
    align: 'center',
    minWidth: 60
  },

  {
    name: 'masterUnitPrice',
    label: g('pages.curation.master-unit-price'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const storeOwnerInfoSchema = {
  ko: [
    {
      name: 'managerFullName',
      type: 'text',
      fullWidth: true,
      label: preOrderT('store-form.owner-name'),
      grid: 6,
      border: { borderLeft: 0, borderBottom: 0 }
    },
    {
      name: 'managerContact',
      type: 'number',
      fullWidth: true,
      label: preOrderT('store-form.owner-contact-ko'),
      grid: 6,
      border: { borderLeft: '1px solid #D3D3D3', borderBottom: 0 }
    }
  ],
  jp: [
    {
      name: 'managerLastName',
      type: 'text',
      label: preOrderT('store-form.owner-name'),
      fullWidth: true,
      grid: 6,
      border: { borderLeft: 0, borderRight: '0 !important' },
      childern: [
        {
          name: 'managerFirstName',
          type: 'text',
          fullWidth: true,
          border: { borderLeft: 0 }
        }
      ]
    },

    {
      name: 'managerLastNameKana',
      type: 'text',
      label: preOrderT('store-form.owner-name-kana'),
      grid: 6,
      border: { borderLeft: '1px solid #D3D3D3' },
      childern: [
        {
          name: 'managerFirstNameKana',
          type: 'text',
          sx: { paddingLeft: 0 },
          border: { borderLeft: 0 }
        }
      ]
    },
    {
      name: 'managerContact',
      type: 'number',
      fullWidth: true,

      label: preOrderT('store-form.owner-contact-jp'),
      grid: 6,
      border: { borderLeft: '1px solid #D3D3D3', borderBottom: 0 }
    },
    {
      name: 'managerEmail',
      type: 'text',
      label: preOrderT('store-form.owner-email'),
      grid: 6,
      fullWidth: true,
      border: { borderLeft: 0, borderRight: '0 !important', borderBottom: 0 }
    }
  ]
};

export const storeInfoSchema = [
  {
    name: 'storeTypeCode',
    required: true,
    type: 'autocomplete',
    label: preOrderT('industry'),
    fullWidth: true,
    options: (data) => data?.storeTypeList || [],
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'storeName',
    type: 'text',
    fullWidth: true,
    label: preOrderT('store'),
    grid: 6,
    border: { borderRight: 0 }
  },
  {
    name: 'address',
    type: 'text',
    fullWidth: true,
    label: preOrderT('store-form.address'),
    grid: 6,
    border: { borderLeft: 0 }
  },

  {
    name: 'latitude',
    type: 'text',
    label: preOrderT('store-form.coordinates'),
    placeholder: preOrderT('store-form.latitude'),
    grid: 6,
    border: { borderLeft: '1px solid #D3D3D3' },
    childern: [
      {
        name: 'longitude',
        type: 'text',
        fullWidth: true,
        placeholder: preOrderT('store-form.longitude'),
        sx: { paddingLeft: 0 },
        border: { borderLeft: 0 }
      }
    ]
  }
];

// store Products page

export const categoryColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'categoryName',
    label: `${t('inventory.product')} ${t('product.category')}`,
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'usage',
    label: t('pre-order.product-number'),
    minWidth: 80,
    align: 'center'
  }
];

export const productColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'productName',
    label: t('product.product-name'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'price',
    label: t('product.price'),
    minWidth: 80,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },

  {
    label: g('common.edit'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    label: g('button.delete'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];
export const storeProductDetailSchema = [
  {
    name: 'productName',
    type: 'text',
    fullWidth: true,
    label: g('pages.product.product-name'),
    grid: 12,
    border: { borderRight: 0, borderLeft: 0 }
  },
  {
    name: 'price',
    type: 'number',
    fullWidth: true,
    label: g('pages.product.price'),
    grid: 12,
    border: { borderLeft: 0 },
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'productImage',
    type: 'upload',
    fullWidth: true,
    required: true,
    label: `${g('pages.inventory.product')} ${g('pages.product.image')}`,
    grid: 12,
    border: { borderRight: 0, borderLeft: 0 }
  }
];

export const storeMinibarProductSchema = [
  {
    name: 'productName',
    type: 'text',
    fullWidth: true,
    disabled: true,
    label: `${g('common.registered')} ${g('pages.product.product-name')}`,
    grid: 12,
    border: { borderRight: 0, borderLeft: 0 }
  },
  {
    name: 'price',
    type: 'number',
    fullWidth: true,
    disabled: true,
    label: `${g('common.registered')} ${g('pages.product.price')}`,
    grid: 12,
    border: { borderLeft: 0 },
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'productImage',
    type: 'upload',
    fullWidth: true,
    disabled: true,

    label: `${g('common.registered')} ${g('pages.inventory.product')} ${g(
      'pages.product.image'
    )}`,
    grid: 12,
    border: { borderRight: 0, borderLeft: 0 }
  }
];

export const storeProductDetailImageSchema = [
  {
    name: 'description',
    type: 'textarea',
    label: preOrderT('store-form.product-detail'),
    grid: 12,
    border: { borderLeft: 0, borderBottom: 0 }
  }
];
export const days = [
  { value: 'MONDAY', label: g('dayOfWeek.1') },
  { value: 'TUESDAY', label: g('dayOfWeek.2') },
  { value: 'WEDNESDAY', label: g('dayOfWeek.3') },
  { value: 'THURSDAY', label: g('dayOfWeek.4') },
  { value: 'FRIDAY', label: g('dayOfWeek.5') },
  { value: 'SATURDAY', label: g('dayOfWeek.6') },
  { value: 'SUNDAY', label: g('dayOfWeek.7') }
];

export const calculateWidth = (grid) => {
  if (grid === 12) return '100%';
  if (grid === 6) return '50%';
  if (grid === 4) return '33.3%';
  if (grid === 3) return '25%';
  return `${(100 / 12) * grid}%`; // Fallback for other values
};

export const actions = {
  UPDATE_STORE: 'UPDATE_STORE',
  DELETE_STORE: 'DELETE_STORE',
  OPTIONS: 'options',
  CREATE_STORE: 'CREATE_STORE',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY'
};

export const storeLocales = {
  KO: 'ko',
  JP: 'jp'
};

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #D3D3D3'
    },
    th: {
      borderRight: '1px solid #D3D3D3',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FFFFFF',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
