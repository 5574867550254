/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';

import { curationServices, clientServices } from '@services/index';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import { MAX_INT_VALUE } from '@constants/utils';

import { curationBudgetColumns } from '../../../enhance';

const BudgetArea = ({ onApply, selectedProductList }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [clientDetail, setClientDetail] = useState();
  const [minibar, setMinibar] = useState(null);
  const [budget, setBudget] = useState('');
  const [loading, setLoading] = useState(false);

  const getClientDetail = async () => {
    const result = await clientServices.getClient(client.clientCode);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else setClientDetail(result.data);
  };

  const handleApplyBudget = async () => {
    const formattedBudget = budget
      ? parseInt(budget.toString().replaceAll(',', ''), 10)
      : null;
    const payload = {
      clientCode: client?.clientCode || null,
      minibarCode: minibar?.minibarCode || null,
      budget: formattedBudget,
      pricePolicyType: clientDetail?.pricePolicyType || null,
      listProduct: selectedProductList.map(({ saleQuantity, productCode }) => ({
        salesQuantity: saleQuantity,
        productCode
      }))
    };
    setLoading(true);
    const result = await curationServices.getRecommenedQuantity(payload);
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else
      onApply({
        data: result.data?.data || [],
        clientChannel: clientDetail?.clientChannel || null,
        clientCode: clientDetail?.clientCode || null,
        minibarCode: minibar?.minibarCode || null,
        budgetAmount: formattedBudget,
        priceType: clientDetail?.pricePolicyType || null
      });
  };

  useEffect(() => {
    setClient(null);
    setMinibar(null);
    setBudget('');
  }, [selectedProductList]);

  useEffect(() => {
    setMinibar();
    if (!client?.clientCode) {
      setClientDetail();
    } else getClientDetail();
  }, [client]);

  return (
    <Box sx={{ mt: '30px', position: 'relative' }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        border="1px solid #D3D3D3"
        bgcolor="#F9FAFB"
        borderRadius={1}
      >
        {curationBudgetColumns.map((item, index) => (
          <Stack
            width="25%"
            textAlign="center"
            borderLeft={index !== 0 && '1px solid #D3D3D3'}
          >
            <Typography color="#6F869C" fontSize={13} fontWeight="bold" p={1.5}>
              {item?.label}
            </Typography>
            <Stack
              bgcolor="white"
              p="0 10px 0 10px"
              borderRadius="0 0 10px 10px"
              minHeight="60px"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {index === 0 && (
                <ClientAutoCompleteV2
                  setClient={setClient}
                  client={client}
                  label={null}
                  disabled={selectedProductList?.length <= 0}
                  sx={{ width: '100%' }}
                />
              )}
              {index === 1 && (
                <MinibarAutoCompleteV2
                  disabled={!client}
                  setMinibar={setMinibar}
                  minibar={minibar}
                  client={client}
                  label={null}
                  sx={{ width: '100%' }}
                />
              )}
              {index === 2 && (
                <span>{clientDetail?.pricePolicyType || ''}</span>
              )}
              {index === 3 && (
                <MyTextField
                  fullWidth
                  value={budget}
                  onChange={(e) =>
                    setBudget(
                      formatMoney(
                        Formatter.fStringToRangeNumber(
                          e.target.value,
                          MAX_INT_VALUE,
                          0
                        )
                      )
                    )
                  }
                />
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Box sx={{ textAlign: 'center', mt: '20px' }}>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={handleApplyBudget}
          disabled={
            !client || !minibar || selectedProductList?.length <= 0 || !budget
          }
        >
          {t('pages.curation.apply-budget')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BudgetArea;
