/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, TableRow } from '@mui/material';

import MyTextField from '@components/MyTextField';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import LabelBox from '@components/LabelBox';
import NoData from '@components/NoData';

import curationServices from '@services/curation';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

import RQRow from './RQRow';
import { curationProductDetailColumns } from '../../../enhance';

const RecommendedQuantityArea = ({
  selectedProductList,
  handleRemoveProduct,
  recommenedData
}) => {
  const { lang } = useSelector((state) => state.app);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [curationName, setCurationName] = useState('');
  const [loading, setLoading] = useState(false);

  const isEnglish = lang === 'en' || lang === 'en-us';

  const handleSaveCuration = async () => {
    const { clientChannel, clientCode, minibarCode, budgetAmount, priceType } =
      recommenedData;
    const payload = {
      curationName,
      clientChannel,
      clientCode,
      minibarCode,
      budgetAmount: budgetAmount || 0,
      listCurationProduct: data.map((item) => ({
        ...item,
        cost: item?.cost || 0,
        salesQuantity: item?.saleQuantity || 0,
        stockQuantity: item?.stockQuantity || 0,
        properStock: item?.properStock || 0,
        masterPrice: item?.masterPrice || 0,
        orderQuantity: item?.recommendedQuantity || 0,
        pricePolicyType: item?.unitPrice || 0,
        recommendedQuantity: item?.recommendedQuantity || 0,
        totalPrice:
          item?.recommendedQuantity && item?.unitPrice
            ? item.recommendedQuantity * item.unitPrice
            : 0,
        priceType
      }))
    };
    setLoading(true);
    const result = await curationServices.createCuration(payload);
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else setTimeout(() => navigate('/product-management/curation'), 300);
  };

  const handleRowChange = (newItem) => {
    const newData = [...data];
    const objIndex = newData.findIndex(
      (obj) => obj?.productCode === newItem?.productCode
    );
    if (newData?.[objIndex]) {
      newData[objIndex] = newItem;
      setData(newData);
    }
  };

  useEffect(() => {
    const mergedData = data.reduce((prev, curr) => {
      const mergedItem = {
        ...curr,
        ...recommenedData?.data?.find(
          (rItem) => rItem?.productCode === curr?.productCode
        )
      };
      prev.push(mergedItem);
      return prev;
    }, []);
    setData(mergedData);
  }, [recommenedData]);

  useEffect(() => {
    setData(selectedProductList);
  }, [selectedProductList]);

  return (
    <Box sx={{ mt: '30px', position: 'relative' }}>
      <ZombieTable columns={curationProductDetailColumns}>
        <CustomTableBody>
          {data ? (
            data.length > 0 ? (
              data.map((item) => (
                <RQRow
                  key={item.productCode}
                  item={item}
                  handleRemoveItem={handleRemoveProduct}
                  isEnglish={isEnglish}
                  priceType={recommenedData?.priceType}
                  onChange={handleRowChange}
                />
              ))
            ) : (
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '20%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description="Please add product and apply budget" />
                </Box>
              </TableRow>
            )
          ) : (
            <TableRow sx={{ height: 200 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '40%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                Loading...
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: '100%',
          my: '30px',
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        <LabelBox
          title={t('pages.curation.curation-name')}
          sx={{ width: 'unset', whiteSpace: 'nowrap' }}
        />
        <Box sx={{ width: '100%', px: 1.5 }}>
          <MyTextField
            fullWidth
            value={curationName}
            onChange={(e) => setCurationName(e.target.value)}
            onKeyDown={(e) => {
              if (e.isComposing || e.keyCode === 13) {
                if (curationName) {
                  dispatch(
                    onOpenConfirm({
                      open: true,
                      type: 'save',
                      action: () => handleSaveCuration()
                    })
                  );
                }
              }
            }}
          />
        </Box>
      </Stack>
      <Box sx={{ textAlign: 'center' }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => {
            if (curationName) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'save',
                  action: () => handleSaveCuration()
                })
              );
            }
          }}
          disabled={!curationName || !data?.length > 0 || !recommenedData}
        >
          {t('pages.curation.save-curation')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default RecommendedQuantityArea;
