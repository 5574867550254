import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  styled
} from '@mui/material';

import MySelect from '@components/MySelect';
import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';
import MyAutocomplete from '@components/MyAutocomplete';
import { MAX_INT } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import categoryService from '@services/category';
import productService from '@services/product';
import { useDispatch } from 'react-redux';
import { onOpenConfirm } from '@store/reducers/appReducer';
import { productDetailSchema } from '../../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const maxLengthOf = {
  productName: 50,
  maker: 50,
  capacity: 15,
  capacityType: 10,
  box: 10,
  barcode: 18
};
const numberFields = ['cost', 'makeCost', 'sellCost'];

const requiredFields = [
  'productName',
  'category1Code',
  'category2Code',
  'category3Code',
  'barcode',
  'cost'
];

const ProductDetailForm = (props) => {
  const { onAction, value, setValue, vendors } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = location?.pathname?.split('/');
  const productCode = pathname[pathname.length - 1];

  const [category, setCategory] = useState({
    first: [],
    second: [],
    third: [],
    special: []
  });
  const dispatch = useDispatch();
  const [errMg, setErrMg] = useState({
    productName: '',
    barcode: '',
    category1code: '',
    category2Code: '',
    category3Code: '',
    cost: ''
  });

  const getCategoryList = async () => {
    const rs = await categoryService.getCategory();

    const categoryConvertOptions = rs.data.map((item) => {
      return {
        value: item.categoryCode,
        label: item.categoryName,
        category2List: item.categories2List
      };
    });
    if (!['register', 'product-register'].includes(productCode)) {
      const productRs = await productService.getProductDetail(productCode);

      const currentCategory = rs.data.find(
        (item) => item.categoryCode === productRs.data?.category1Code
      );
      const categoryConvertOptions2 = currentCategory?.categories2List?.map(
        (item) => {
          return {
            value: item?.categoryCode,
            label: item?.categoryName,
            category3List: item?.categories3List
          };
        }
      );
      const currentCategory2 = currentCategory?.categories2List?.find(
        (item) => item.categoryCode === productRs.data?.category2Code
      );
      const categoryConvertOptions3 = currentCategory2?.categories3List?.map(
        (item) => {
          return {
            value: item?.categoryCode,
            label: item?.categoryName
          };
        }
      );

      setCategory({
        ...category,
        first: categoryConvertOptions,
        second: categoryConvertOptions2,
        third: categoryConvertOptions3
      });
    } else {
      setCategory({
        ...category,
        first: categoryConvertOptions,
        second: [],
        third: []
      });
    }
  };
  const onChangeCategory = (type, val) => {
    switch (type) {
      case 'category1Code':
        if (!val?.value) {
          setCategory({ ...category, second: [], third: [] });
          setValue({
            ...value,
            [type]: ''
          });
          break;
        }
        // eslint-disable-next-line no-case-declarations
        const category1ConvertOptions = val?.category2List.map((item) => {
          return {
            value: item.categoryCode,
            label: item.categoryName,
            category3List: item.categories3List
          };
        });
        setCategory({
          ...category,
          second: category1ConvertOptions,
          third: []
        });
        setValue({
          ...value,
          [type]: val.value
        });
        break;

      case 'category2Code':
        if (!val?.value) {
          setCategory({ ...category, third: [] });
          setValue({
            ...value,
            [type]: ''
          });
          break;
        }
        // eslint-disable-next-line no-case-declarations
        const category2ConvertOptions = val?.category3List.map((item) => {
          return { value: item.categoryCode, label: item.categoryName };
        });
        setCategory({ ...category, third: category2ConvertOptions });
        setValue({
          ...value,
          [type]: val.value
        });
        break;

      case 'category3Code':
        setValue({
          ...value,
          [type]: val.value
        });
        break;

      // Add more cases as needed

      default:
        // Default case
        break;
    }
  };
  const onChange = (e, type) => {
    validateOnChange(e.target);
    if (e.target.name === 'vendorCode') {
      const getVendorName = vendors.vendors.filter(
        (i) => i.value === e.target.value
      )[0].label;
      setValue({
        ...value,
        vendorName: getVendorName,
        vendorCode: e.target.value
      });
      return;
    }
    if (e.target.name !== 'vendorCode' && e.target.name) {
      setValue({
        ...value,
        [e.target.name]:
          type === 'number' ? e.target.value.replace(/,/g, '') : e.target.value
      });
      return;
    }
    if (e.target.files) {
      setValue({
        ...value,
        dataFile: {
          name: e.target.files[0].name,
          src: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0]
        }
      });
    }
  };

  const validateOnChange = ({ name, value: val }) => {
    let msg = '';
    if (!val && requiredFields.includes(name)) {
      msg = t('common.required');
    } else if (
      numberFields.includes(name) &&
      val?.replaceAll(',', '') > MAX_INT
    ) {
      msg = t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) });
    } else if (val.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  const checkValidate = (data) => {
    let isValidate = true;
    const vldErr = { ...errMg };
    if (!data?.productName) {
      vldErr.productName = t('common.required');
      isValidate = false;
    }
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });
    setErrMg(vldErr);
    return isValidate;
  };

  const isValidToSubmit = () => {
    let isValidate = true;
    requiredFields.forEach((field) => {
      if (!value?.[field]) {
        isValidate = false;
      }
    });
    return isValidate;
  };

  const handleAction = (action) => {
    if (checkValidate(value)) {
      dispatch(
        onOpenConfirm({
          open: true,
          type: action === 'register' ? 'save' : 'modify',
          action: () => onAction(action)
        })
      );
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      <Container component={BorderBox}>
        <Stack flexDirection="row" flexWrap="wrap">
          {productDetailSchema.map((item) => {
            return (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: {
                    xs: '100%',
                    md:
                      item.name === 'productName' || item.name === 'image'
                        ? '100%'
                        : '50%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                  ...item.border
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                    {requiredFields.includes(item.name) && (
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    )}
                  </Typography>
                </Stack>
                {item.type === 'category' ? (
                  <RenderCateggory
                    onChangeInput={onChangeCategory}
                    errMg={errMg?.[item?.name]}
                    {...item}
                    disabled={
                      location?.pathname?.includes('inventory-management') &&
                      item?.name === 'barcode'
                    }
                    data={
                      item?.options &&
                      (item?.name === 'vendorCode'
                        ? item?.options(vendors)
                        : item?.options(category))
                    }
                    value={value}
                  />
                ) : (
                  <RenderField
                    onChangeInput={(e) => onChange(e, item?.type)}
                    errMg={errMg?.[item?.name]}
                    {...item}
                    disabled={
                      location?.pathname?.includes('inventory-management') &&
                      item?.name === 'barcode'
                    }
                    data={
                      item?.options &&
                      (item?.name === 'vendorCode'
                        ? item?.options(vendors)
                        : item?.options(category))
                    }
                    value={value}
                  />
                )}
              </Stack>
            );
          })}
        </Stack>
      </Container>
      <Stack flexDirection="row" justifyContent="center" gap={2} m={4}>
        <Button variant="outlined" onClick={() => onAction('cancel')}>
          {t('button.cancel')}
        </Button>
        {location?.pathname?.includes('register') ? (
          <Button
            variant="contained"
            onClick={() => handleAction('register')}
            disabled={!isValidToSubmit()}
          >
            {t('pages.inventory.register')}
          </Button>
        ) : (
          <Stack flexDirection="row" gap={2}>
            <Button
              variant="contained"
              onClick={() => handleAction('modification')}
              disabled={!isValidToSubmit()}
            >
              {t('pages.inventory.modify')}
            </Button>
            {!location?.pathname?.includes('warehouse') && (
              <>
                <Button
                  variant="contained"
                  onClick={() => onAction('nutritional')}
                >
                  {t('pages.product.nutritional-info-modify')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onAction('notification')}
                >
                  {t('pages.product.notify-info')}
                </Button>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

const RenderField = ({ data, value, errMg, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;
  if (type === 'upload')
    return (
      <InputFile
        dataFile={value?.dataFile}
        onChange={onChangeInput}
        sx={{ root: { height: 150, width: '100%' } }}
      />
    );
  if (type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          value={value[item.name]}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ minWidth: '100%' }}
        />
      </Stack>
    );
  return (
    <MyTextField
      value={
        type === 'number' ? formatMoney(value[item.name]) : value[item.name]
      }
      errMg={errMg}
      onChange={onChangeInput}
      size="small"
      {...rest}
      sx={{ ...inputStyle, minWidth: 210 }}
      typeValue={type === 'number' && 'onlyNumber'}
    />
  );
};

const RenderCateggory = ({ data, value, errMg, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        ...inputStyle
      }}
    >
      <MyAutocomplete
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={(option) => option.label || ''}
        required
        onChange={(e, val) => onChangeInput(item.name, val, e)}
        options={data}
        sx={{ width: 160, flex: 1 }}
        value={{
          value: value[item.name],
          label:
            data?.filter((i) => i?.value === value[item.name])?.[0]?.label || ''
        }}
        errMg={errMg}
        // onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
      />
    </Stack>
  );
};

export default ProductDetailForm;
