import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useMergeState from '@hooks/useMergeState';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HelmetContainer from '@components/HelmetContainer';
import DaumAddress from '@components/DaumAddress';
import LabelBox from '@components/LabelBox';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';

import { minibarServices } from '@services/index';
import { Formatter } from '@utils/formatter';
import { onOpenConfirm } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import { formatMoney } from '@utils/helpers';

import { tableBodyStyle } from '../enhance';
import EditListEquipmentDialog from '../EditListEquipmentDialog';

const requiredFields = ['clientCode', 'minibarNickname'];

const maxLengthOf = {
  minibarNickname: 30,
  shippingManager: 20,
  shippingLocation: 20,
  accessMethod: 40,
  shippingDistrict: 20,
  shippingAddress1: 250,
  shippingAddress2: 250,
  parkingType: 20,
  other: 200,
  shippingCycle: 10,
  note: 250
};

const EditMinibar = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { minibarCode } = useParams();
  const [data, setData] = useState();
  const [openListEquipment, setOpenListEquipment] = useState(false);
  const [listEquipment, setListEquipment] = useState();
  const [client, setClient] = useState(null); // 그룹명
  const [goalPrice, setGoalPrice] = useState(''); // 목표금액
  const [loading, setLoading] = useState(false);

  const [errMsg, setErrMsg] = useMergeState({
    clientCode: '',
    minibarNickname: '',
    shippingManager: '',
    shippingLocation: '',
    accessMethod: '',
    shippingDistrict: '',
    shippingAddress1: '',
    shippingAddress2: '',
    parkingType: '',
    other: '',
    goalPrice: '',
    shippingCycle: '',
    note: ''
  });

  const [daumAddress, setDaumAddress] = useState('');

  // 매대현황
  const minibarNicknameRef = useRef(); // 별칭
  // 배송정보
  const shippingManagerRef = useRef(); // 배송 담당자
  const shippingLocationRef = useRef(); // 매대위치
  const accessMethodRef = useRef(); // 출입여부
  const shippingDistrictRef = useRef(); // 지역구
  const shippingAddressRef = useRef(); // 배송주소
  const parkingTypeRef = useRef(); // 주차유형
  const otherRef = useRef(); // 특이사항
  const shippingCycleRef = useRef(); // 배송주기

  const [shippingWeekMon, setShippingWeekMon] = useState(false); // 배송요일
  const [shippingWeekTue, setShippingWeekTue] = useState(false); // 배송요일
  const [shippingWeekWed, setShippingWeekWed] = useState(false); // 배송요일
  const [shippingWeekThu, setShippingWeekThu] = useState(false); // 배송요일
  const [shippingWeekFri, setShippingWeekFri] = useState(false); // 배송요일

  // 메모
  const noteRef = useRef(); // 메모

  const getEquiments = async () => {
    setListEquipment();
    const result = await minibarServices.getListEquipmentByMinibar({
      page: 0,
      size: 9,
      minibarCode
    });
    if (!result?.data) toast.error('Something went wrong');
    else setListEquipment(result.data?.data || []);
  };

  const getDetailMinibar = async () => {
    const result = await minibarServices.getMinibar(minibarCode);
    if (result?.data) setData(result.data.data);
  };

  const initData = () => {
    setDaumAddress(data.shippingAddress1 || '');
    minibarNicknameRef.current.value = data.minibarNickname || '';
    shippingManagerRef.current.value = data.shippingManager || '';
    shippingLocationRef.current.value = data.shippingLocation || '';
    accessMethodRef.current.value = data.accessMethod || '';
    shippingDistrictRef.current.value = data.shippingDistrict || '';
    shippingAddressRef.current.value = data.shippingAddress2 || '';
    parkingTypeRef.current.value = data.parkingType || '';
    otherRef.current.value = data.other || '';
    setGoalPrice(data?.goalPrice || '');
    shippingCycleRef.current.value = data.shippingCycle || '';
    setShippingWeekMon(data.shippingWeekMon === 'Y');
    setShippingWeekTue(data.shippingWeekTue === 'Y');
    setShippingWeekWed(data.shippingWeekWed === 'Y');
    setShippingWeekThu(data.shippingWeekThu === 'Y');
    setShippingWeekFri(data.shippingWeekFri === 'Y');
    noteRef.current.value = data.note || '';
    setClient({ clientCode: data.clientCode, clientName: data.clientName });
  };

  const handleChangeGoalPrice = (e) =>
    setGoalPrice(Formatter.fStringToRangeNumber(e.target.value, 9_999_999_999));

  const handleDaumAddressComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setDaumAddress(fullAddress);
    setErrMsg({
      shippingAddress1:
        fullAddress?.length > maxLengthOf.shippingAddress1
          ? t('validation.dataTooLong', { max: maxLengthOf.shippingAddress1 })
          : ''
    });
  };

  const inputFormListener = ({ name, value }) => {
    let msg = '';

    if (requiredFields.includes(name) && !value) {
      msg = t('common.required');
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }

    setErrMsg({ [name]: msg });
  };

  const validateInputForm = () => {
    const tempErr = { ...errMsg };
    if (!client) {
      tempErr.clientCode = t('common.required');
    }
    if (!minibarNicknameRef.current.value) {
      tempErr.minibarNickname = t('common.required');
    }
    setErrMsg(tempErr);
    // eslint-disable-next-line no-restricted-syntax
    for (const err of Object.values(tempErr)) {
      if (err) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      minibarCode,
      clientCode: client?.clientCode,
      minibarNickname: minibarNicknameRef.current.value,
      note: noteRef.current.value,
      goalPrice: parseInt(goalPrice, 10) || 0, // int
      shippingInfo: {
        shippingManager: shippingManagerRef.current.value,
        shippingAddress1: daumAddress,
        shippingAddress2: shippingAddressRef.current.value,
        shippingDistrict: shippingDistrictRef.current.value,
        shippingLocation: shippingLocationRef.current.value,
        accessMethod: accessMethodRef.current.value,
        parkingType: parkingTypeRef.current.value,
        other: otherRef.current.value,
        shippingCycle: shippingCycleRef.current.value,
        shippingWeekMon: shippingWeekMon ? 'Y' : 'N',
        shippingWeekTue: shippingWeekTue ? 'Y' : 'N',
        shippingWeekWed: shippingWeekWed ? 'Y' : 'N',
        shippingWeekThu: shippingWeekThu ? 'Y' : 'N',
        shippingWeekFri: shippingWeekFri ? 'Y' : 'N'
      }
    };

    const result = await minibarServices.updateMinibar(minibarCode, data);
    setLoading(false);
    if (result.error) toast.error('Something went wrong');
    else navigate(-1);
  };

  const handleOpenListEquipment = () => {
    getEquiments();
    setOpenListEquipment(true);
  };

  useEffect(() => {
    if (data) {
      initData();
    }
  }, [data]);

  useEffect(() => {
    if (minibarCode) getDetailMinibar();
  }, [minibarCode]);

  return (
    <HelmetContainer
      title={t('pageTitle.editMinibar')}
      content="Minibar Detail"
    >
      <Box
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableBody sx={tableBodyStyle}>
              {/* first big row 매대현황 */}
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('pages.minibar.minibarStatus')}
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.groupName')} required />
                    <ClientAutoCompleteV2
                      label={null}
                      client={client}
                      setClient={(e) => {
                        setClient(e);
                        setErrMsg({ clientCode: '' });
                      }}
                      name="clientCode"
                      sx={{ mx: 1.5, flex: 1 }}
                      disabled={loading}
                      errMg={!client && errMsg.clientCode}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.nickname')} required />
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      ref={minibarNicknameRef}
                      placeholder={t('pages.minibar.nickname')}
                      disabled={loading}
                      name="minibarNickname"
                      errMg={errMsg.minibarNickname}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.equipment-status')} />
                    <Stack justifyContent="center" alignItems="center" flex={1}>
                      <Button
                        variant="outlined"
                        disabled={loading}
                        onClick={handleOpenListEquipment}
                      >
                        {t('pages.minibar.equipmentInput')}
                      </Button>
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>

              {/* second big row 배송정보 */}
              <TableRow>
                <TableCell rowSpan={4} component="th" scope="row">
                  {t('pages.minibar.shippingInfo')}
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.shippingManager')} />
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      ref={shippingManagerRef}
                      placeholder={t('pages.minibar.shippingInfo')}
                      disabled={loading}
                      name="shippingManager"
                      errMg={errMsg.shippingManager}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.location')} />
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      placeholder={t('pages.minibar.location')}
                      ref={shippingLocationRef}
                      disabled={loading}
                      name="shippingLocation"
                      errMg={errMsg.shippingLocation}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.accessMethods')} />
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      placeholder={t('pages.minibar.accessMethods')}
                      ref={accessMethodRef}
                      disabled={loading}
                      name="accessMethod"
                      errMg={errMsg.accessMethod}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox
                      title={t('pages.minibar.areaDistrict')}
                      sx={{ height: 129 }}
                    />
                    <MyTextField
                      placeholder={t('pages.minibar.areaDistrict')}
                      sx={{ width: 150, flex: 1, mx: 1.5 }}
                      ref={shippingDistrictRef}
                      disabled={loading}
                      name="shippingDistrict"
                      errMg={errMsg.shippingDistrict}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={4}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox
                      title={t('pages.minibar.shippingAddress')}
                      sx={{ height: 129 }}
                    />
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      spacing={1.5}
                      sx={{ mx: 1.5 }}
                      flex={1}
                    >
                      <DaumAddress
                        name="shippingAddress1"
                        errMg={errMsg.shippingAddress1}
                        value={daumAddress}
                        onComplete={handleDaumAddressComplete}
                        placeholder={t('pages.minibar.shippingAddress')}
                        disabled={loading}
                      />
                      <MyTextField
                        sx={{ width: '100%' }}
                        placeholder={t('pages.minibar.shippingAddress')}
                        name="shippingAddress2"
                        errMg={errMsg.shippingAddress2}
                        onChange={(e) => inputFormListener(e.target)}
                        ref={shippingAddressRef}
                        fullWidth
                        disabled={loading}
                      />
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.parkingType')} />
                    <MyTextField
                      placeholder={t('pages.minibar.parkingType')}
                      sx={{ width: 150, flex: 1, mx: 1.5 }}
                      ref={parkingTypeRef}
                      disabled={loading}
                      name="parkingType"
                      errMg={errMsg.parkingType}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.other')} />
                    <MyTextField
                      name="other"
                      errMg={errMsg.other}
                      onChange={(e) => inputFormListener(e.target)}
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      placeholder={t('pages.minibar.other')}
                      ref={otherRef}
                      disabled={loading}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={2}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.goalPrice')} />
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                      placeholder={t('pages.minibar.goalPrice')}
                      name="goalPrice"
                      errMg={errMsg.goalPrice}
                      value={formatMoney(goalPrice)}
                      onChange={handleChangeGoalPrice}
                      typeValue="onlyNumber"
                      disabled={loading}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2.5} rowSpan={1}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.shippingCycle')} />
                    <MyTextField
                      sx={{ flex: 1, mx: 1.5 }}
                      placeholder={t('pages.minibar.shippingCycle')}
                      ref={shippingCycleRef}
                      disabled={loading}
                      name="shippingCycle"
                      errMg={errMsg.shippingCycle}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
                <TableCell colSpan={3.5}>
                  <Stack direction="row" alignItems="center">
                    <LabelBox title={t('pages.minibar.shippingDays')} />
                    <FormControl
                      disabled={loading}
                      sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t('pages.minibar.mon')}
                        checked={shippingWeekMon}
                        onChange={(e) => setShippingWeekMon(e.target.checked)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t('pages.minibar.tue')}
                        checked={shippingWeekTue}
                        onChange={(e) => setShippingWeekTue(e.target.checked)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t('pages.minibar.wed')}
                        checked={shippingWeekWed}
                        onChange={(e) => setShippingWeekWed(e.target.checked)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t('pages.minibar.thu')}
                        checked={shippingWeekThu}
                        onChange={(e) => setShippingWeekThu(e.target.checked)}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={t('pages.minibar.fri')}
                        checked={shippingWeekFri}
                        onChange={(e) => setShippingWeekFri(e.target.checked)}
                      />
                    </FormControl>
                  </Stack>
                </TableCell>
              </TableRow>

              {/* last big row 메모 */}
              <TableRow>
                <TableCell component="th" scope="row" sx={{ height: 68 }}>
                  {t('pages.minibar.note')}
                </TableCell>
                <TableCell colSpan={6}>
                  <Stack direction="row" alignItems="center">
                    <MyTextField
                      sx={{ minWidth: 150, flex: 1, mx: 1.5, my: 1 }}
                      placeholder={t('pages.minibar.note')}
                      multiline
                      rows={3}
                      ref={noteRef}
                      disabled={loading}
                      name="note"
                      errMg={errMsg.note}
                      onChange={(e) => inputFormListener(e.target)}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {openListEquipment && listEquipment && (
          <EditListEquipmentDialog
            open={openListEquipment}
            setOpen={setOpenListEquipment}
            data={listEquipment}
            minibarCode={minibarCode}
            key={listEquipment}
          />
        )}
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{ mt: '30px', p: 0 }}
      >
        <Button
          onClick={() => navigate(-1)}
          variant="outlined"
          sx={{
            height: '44px',
            minWidth: '110px'
          }}
        >
          {t('button.cancel')}
        </Button>
        <LoadingButton
          onClick={() => {
            if (validateInputForm()) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'modify',
                  action: () => handleSubmit()
                })
              );
            }
          }}
          variant="contained"
          sx={{
            height: '44px',
            minWidth: '110px'
          }}
          loading={loading}
        >
          {t('button.check')}
        </LoadingButton>
      </Stack>
    </HelmetContainer>
  );
};

export default EditMinibar;
