/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, TableCell, TableRow } from '@mui/material';

import CustomImage from '@components/CustomImage';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import NoData from '@components/NoData';

import { curationProductColumns } from '../../../enhance';

const ProductArea = ({ onAdd, data }) => {
  const { lang } = useSelector((state) => state.app);
  const [t] = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([]);

  const isEnglish = lang === 'en' || lang === 'en-us';

  const handleApplyCategoryFilter = () => {
    onAdd(selectedProducts);
  };

  const handleCheckboxClick = (e, item) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, item]);
    } else {
      const newArray = [...selectedProducts];
      setSelectedProducts(
        newArray.filter((product) => product.productCode !== item.productCode)
      );
    }
  };

  useEffect(() => {
    setSelectedProducts([]);
  }, [data]);

  return (
    <Box sx={{ mt: '30px', position: 'relative' }}>
      <ZombieTable
        columns={curationProductColumns}
        stickyHeader
        sx={{ maxHeight: 350 }}
      >
        <CustomTableBody>
          {data ? (
            data.length > 0 ? (
              data.map((item) => (
                <TableRow key={item.productCode}>
                  <TableCell>{item.productCode}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.some(
                        ({ productCode }) => productCode === item.productCode
                      )}
                      onChange={(e) => handleCheckboxClick(e, item)}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomImage
                      fileName={item.imgUrl}
                      fileLink={item.imgUrl}
                    />
                  </TableCell>
                  <TableCell>{item?.productName}</TableCell>
                  <TableCell>
                    {isEnglish ? item?.category1NameEng : item?.category1Name}
                  </TableCell>
                  <TableCell>
                    {isEnglish ? item?.category2NameEng : item?.category2Name}
                  </TableCell>
                  <TableCell>
                    {isEnglish ? item?.category3NameEng : item?.category3Name}
                  </TableCell>
                  <TableCell>
                    {isEnglish
                      ? item?.categoryNameEngSpecial
                      : item?.categoryNameSpecial}
                  </TableCell>
                  <TableCell>
                    {item?.saleQuantity &&
                      new Intl.NumberFormat('en-CA').format(item.saleQuantity)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ height: 110 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '20%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData />
                </Box>
              </TableRow>
            )
          ) : (
            <TableRow sx={{ height: 110 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '40%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                Loading...
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
      <Box sx={{ textAlign: 'center', mt: '20px' }}>
        <Button variant="outlined" onClick={handleApplyCategoryFilter}>
          {t('button.add')}
        </Button>
      </Box>
    </Box>
  );
};

export default ProductArea;
