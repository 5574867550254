import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMergeState from '@hooks/useMergeState';
import useAuthorization from '@hooks/useAuthorization';

import { Button, CircularProgress, Stack, Typography } from '@mui/material';

import MyDialog from '@components/MyDialog';
import HelmetContainer from '@components/HelmetContainer';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';

import { scopes } from '@constants/utils';
import error2Text from '@utils/error2Text';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import vendorService from '@services/vendor';
import { columns } from './enhance';
import VendorRegister from './components/VendorRegister';

const maxLengthOf = {
  vendorName: 50,
  picName: 10,
  picContact: 20,
  vendorType: 100,
  vendingProduct: 100,
  bank: 20,
  bankAccountNumber: 100,
  picEmail: 50,
  businessNumber: 50
};

const Vendor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const configColumns = columns;
  const [param, setParam] = useState({
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [vendorForm, setVendorForm] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPop, setLoadingPop] = useState(false);
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const [errMsg, setErrMsg] = useMergeState({
    vendorName: '',
    picName: '',
    picContact: '',
    vendorType: '',
    vendingProduct: '',
    bank: '',
    bankAccountNumber: '',
    picEmail: '',
    businessNumber: ''
  });
  const { items, pagination } = state;

  const onAction = (action) => {
    if (action === 'vendor-register') {
      setVendorForm(null);
      setSelected({
        open: true,
        action,
        title: `${t('pageTitle.vendor')} ${t('button.register')}`
      });
    }
  };

  const getVendors = async (param) => {
    const rs = await vendorService.getVendorList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  const handleChangePage = (page) => {
    setParam({ ...param, page });
  };

  const handleClickTable = (item) => {
    navigate(`${item.vendorCode}`);
  };

  const downloadVendors = async () => {
    setLoading(true);
    const rs = await vendorService.exportVendor();
    if (rs?.error) dispatch(setError(error2Text(rs?.error)));
    setLoading(false);
  };

  const checkValidate = (data) => {
    let isValidate = true;
    const vldErr = { ...errMsg };

    if (!data?.vendorName) {
      vldErr.vendorName = t('common.required');
      isValidate = false;
    }
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });

    setErrMsg(vldErr);
    return isValidate;
  };

  const handleDialogOk = async () => {
    setLoadingPop(true);
    const dataSubmit = new FormData();
    const keys = Object.keys(vendorForm);
    const values = Object.values(vendorForm);
    keys.forEach((key, idx) => {
      dataSubmit.append([key], values[idx]);
    });
    if (selected?.action === 'vendor-register') {
      const rs = await vendorService.createVendor(dataSubmit);
      if (!rs?.error) {
        setSelected({ ...selected, open: false });
        getVendors({ ...param, page: 0 });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
    setLoadingPop(false);
    return true;
  };

  useEffect(() => {
    getVendors(param);
  }, [param?.page]);

  return (
    <HelmetContainer title={t('pageTitle.vendor')} content="Vendor Page">
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            onClick={downloadVendors}
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => onAction('vendor-register')}
            disabled={!isAuthorized}
          >
            {t('pageTitle.vendor')} {t('button.register')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination?.totalRow || 0}
          </Typography>
        </Typography>
      </Stack>
      <GroupMultiHeaderTable
        hover
        columns={configColumns}
        pagination={pagination}
        data={items}
        onRowClick={handleClickTable}
        minusHeight={120}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        onClose={() => setErrMsg({ vendorName: '' })}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          if (checkValidate(vendorForm)) {
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'save',
                action: () => handleDialogOk()
              })
            );
          }
        }}
        loading={loadingPop}
        setLoading={setLoadingPop}
      >
        <VendorRegister
          value={vendorForm}
          setValue={setVendorForm}
          maxLengthOf={maxLengthOf}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default Vendor;
