import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';

import InputFile from '@components/InputFile';
import minibarOrderService from '@services/minibarOrderService';

import MyDialog from '@components/MyDialog';
import ExcelRegisterHistory from './ExcelRegisterHistory';

const ExcelRegisterDialog = ({
  open,
  setOpen,
  onAction,
  minibarCode,
  type
}) => {
  const { t } = useTranslation();

  const [dataFile, setDataFile] = useState({
    name: '',
    file: '',
    src: ''
  });

  const [errorUpload, setErrorUpload] = useState({
    open: false,
    action: '',
    type,
    item: {}
  });

  const [errmsg, setErrMsg] = useState({
    fileType: ''
  });

  const title =
    type === 'shelf'
      ? t('button.bulk-shelf-register')
      : t('button.bulk-register');
  const handleRegisterByExcel = async () => {
    const dataSubmit = new FormData();
    console.log('minibarCode: ', minibarCode);
    dataSubmit.append('file', dataFile.file);
    // TODO   update api and handle error response in new modal
    // alert(type)
    const rs =
      type === 'shelf'
        ? await minibarOrderService.createMinibarShelfOrdersByExcel(
            minibarCode,
            dataSubmit
          )
        : await minibarOrderService.createMinibarProductOrdersByExcel(
            minibarCode,
            dataSubmit
          );
    if (!rs.error) {
      // const rs2 = await getErrorHistory()

      if (rs.data?.data?.fail) {
        console.log('fail');
        setErrorUpload({
          ...errorUpload,
          open: true,
          action: 'error',
          item: rs.data?.data
        });
      } else if (rs.data?.message) {
        console.log('this is rs', rs);
        let errMsg;
        switch (rs.data?.message) {
          case 'E5100':
            errMsg = `파일이 유효 하지 않습니다.`;
            break;
          case 'E5111':
            errMsg = t('pages.minibarOrder.messages.error.barcodeInvalid', {
              row: rs.data?.fieldErrors?.detail
            });
            break;
          case 'E5112':
            errMsg = t('pages.minibarOrder.messages.error.barcodeError', {
              row: rs.data?.fieldErrors?.detail
            });
            break;
          case 'E5120':
            errMsg = t('pages.minibarOrder.messages.error.requiredQuantity', {
              row: rs.data?.fieldErrors?.detail
            });
            break;
          case 'E5121':
            errMsg = t(
              'pages.minibarOrder.messages.error.requiredQuantityInvalid',
              { row: rs.data?.fieldErrors?.detail }
            );
            break;
          default:
            errMsg =
              '파일 내용에 문제가 있습니다. 샘플에 확인 후 다시 업로드 해주세요. 오류가 있으면 관리자에게 보내주세요';
            break;
        }
        setErrMsg({ ...errmsg, fileType: `${errMsg} [${rs.data?.message}]` });
      } else {
        setOpen(false);
        onAction('upload-success');
      }
    } else {
      let errMsg;
      console.log('error', rs.error);
      switch (rs.error?.message) {
        case 'E5100':
          errMsg = `파일이 유효 하지 않습니다.`;
          break;
        case 'E5111':
          errMsg = t('pages.minibarOrder.messages.error.barcodeInvalid', {
            row: rs.error?.fieldErrors?.detail
          });
          break;
        case 'E5112':
          errMsg = t('pages.minibarOrder.messages.error.barcodeError', {
            row: rs.error?.fieldErrors?.detail
          });
          break;
        case 'E5120':
          errMsg = t('pages.minibarOrder.messages.error.requiredQuantity', {
            row: rs.error?.fieldErrors?.detail
          });
          break;
        case 'E5121':
          errMsg = t(
            'pages.minibarOrder.messages.error.requiredQuantityInvalid',
            { row: rs.error?.fieldErrors?.detail }
          );
          break;
        default:
          errMsg =
            '파일 내용에 문제가 있습니다. 샘플에 확인 후 다시 업로드 해주세요.';
          break;
      }
      setErrMsg({
        ...errmsg,
        fileType: `${errMsg} [${
          rs.error?.response?.request?.status || 'E5100'
        }]`
      });
    }
  };

  const validateFile = (file) => {
    const extension = file.name.split('.').pop(); // Get the file extension

    console.log('Validation:', extension);

    if (['xlsx', 'xls'].includes(extension.toLowerCase())) {
      console.log('true :', extension);
      setErrMsg({ ...errmsg, fileType: '' });
      return true; // File extension is valid
      // eslint-disable-next-line no-else-return
    } else {
      setErrMsg({ ...errmsg, fileType: '잘못된 파일입니다.' });
      return false; // File extension is not valid
    }
  };

  const exportSample = async () => {
    // TODO export sample based on type
    // eslint-disable-next-line no-unused-expressions
    type === 'shelf'
      ? await minibarOrderService.exportShelfSample()
      : await minibarOrderService.exportSample();
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    validateFile(file);
    setDataFile({ ...dataFile, name: file.name, file });
  };
  // useEffect(() => {
  //   //get hisotyError
  // }, [error])
  return (
    <MyDialog
      disabled={!dataFile.file || errmsg.fileType}
      open={open}
      setOpen={setOpen}
      title={title}
      okTitle={t('button.add')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onClose={() => setDataFile({ name: '', file: '', src: '' })}
      onOk={handleRegisterByExcel}
      sx={{ width: 460 }}
    >
      <MyDialog
        open={errorUpload.open}
        setOpen={() => setErrorUpload({ ...errorUpload, open: false })}
        title={
          errorUpload?.action === 'error'
            ? t('pages.minibarOrder.dialog.title.excelUploadError')
            : t('button.errorHistory')
        }
        hideDialogButton
        hasCancelButton={errorUpload.action === 'history'}
        cancelTitle={t('button.cancel')}
        hasCloseButton
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <ExcelRegisterHistory
          minibarCode={minibarCode}
          type={errorUpload.type}
          actionType={errorUpload.action}
          setOpen={() => setErrorUpload({ ...errorUpload, open: false })}
          setRegisterClose={setOpen}
          data={errorUpload.item}
        />
      </MyDialog>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2.5 }}
      >
        <Typography
          sx={{
            color: '#6F869C',
            fontWeight: 'fontWeightBold',
            fontSize: { md: 13, xs: 10 }
          }}
        >
          {t('pages.product.example-excel-notes')}
        </Typography>
        {type === 'product' && (
          <Button
            sx={{ textTransform: 'lowercase', height: { md: 36, xs: 24 } }}
            onClick={() =>
              setErrorUpload({ ...errorUpload, open: true, action: 'history' })
            }
          >
            {t('button.errorHistory')}
          </Button>
        )}

        <Button
          sx={{ textTransform: 'lowercase', height: { md: 36, xs: 24 } }}
          onClick={exportSample}
        >
          {t('button.example-file-label')}
        </Button>
      </Stack>
      <Box
        sx={{
          border: errmsg.fileType ? '1px solid red' : '1px solid #ccc',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          p: '10px 20px'
        }}
      >
        <Tooltip title={dataFile.name}>
          <Typography
            sx={{
              color: 'primary.darker',
              fontWeight: 'fontWeightBold',
              fontSize: { md: 13, xs: 10 },
              textDecoration: 'underline',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '10px',
              marginTop: 1
            }}
          >
            {dataFile.name}
          </Typography>
        </Tooltip>

        <InputFile title={t('button.attachment')} onChange={handleFileUpload} />
      </Box>
      {errmsg.fileType && (
        <span
          style={{
            color: 'red',
            fontSize: { md: 12, xs: 9 }
          }}
        >
          * {errmsg.fileType}
        </span>
      )}
    </MyDialog>
  );
};
export default ExcelRegisterDialog;
