import RestService from './RestService';

const adminServices = {
  async getListAdmin(params) {
    try {
      const res = await new RestService()
        .setPath('admin')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getAdmin(adminCode) {
    try {
      const res = await new RestService().setPath(`admin/${adminCode}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteAdmin(adminCode) {
    try {
      const res = await new RestService()
        .setPath(`admin/${adminCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createAdmin(data) {
    try {
      const res = await new RestService().setPath(`admin`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateAdmin(data, adminCode) {
    try {
      const res = await new RestService()
        .setPath(`admin/${adminCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default adminServices;
