import i18n from '@utils/i18n';
import env from '@constants/env';
import fallbackImg from '@svg/fallback-img.svg';
import { LOCATION_TYPES, tableTypes } from '@constants/utils';
import { Typography } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

const productImgStyle = {
  objectFit: 'contain',
  width: '40px',
  height: '40px',
  border: '1px solid #EEEEEE'
};

export const columns = [
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('zone'),
    minWidth: 120,
    align: 'center'
  },

  // {
  //   name: 'productCategory',
  //   label: p('category'),
  //   minWidth: 200,
  //   align: 'center',
  //   render: ({ productCategory }) => {
  //     const catgArr = productCategory?.split(' ')?.join('')?.split('>');
  //     const catg1 = catgArr?.[0];
  //     const catg2 = catgArr?.[1];
  //     const catg3 = catgArr?.[2];
  //     return (
  //       <span>
  //         {catg2 ? `${catg1} > ` : catg1}
  //         {catg3 ? `${catg2} > ` : catg2}
  //         {catg3 && catg3}
  //       </span>
  //     );
  //   }
  // },
  {
    name: 'locationProperty',
    label: t('property'),
    minWidth: 120,
    align: 'center',
    render: ({ locationProperty }) => {
      const property = propertyOptions.find(
        (item) => item.value === locationProperty
      );
      return <Typography>{property?.label}</Typography>;
    }
  },
  {
    name: 'useYn',
    label: t('usedYn'),
    minWidth: 100,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    name: 'productName',
    label: g('button.modify'),
    minWidth: 100,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  }
];

export const productColumns = [
  {
    name: 'imgUrl',
    label: p('image'),
    minWidth: 70,
    align: 'center',
    render: ({ imgUrl }) => {
      return (
        <img
          src={`${env.s3BaseUrl}/${imgUrl}`}
          alt="product"
          style={productImgStyle}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = fallbackImg;
          }}
        />
      );
    }
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'quantity',
    label: p('quantity'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'cost',
    label: p('cost'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'masterPrice',
    type: 'text',
    label: p('master-price'),
    minWidth: 160,
    align: 'center'
  }
];

export const searchConditions = [
  { value: 'locationName', label: `${t('location')}` },
  { value: 'locationProperty', label: `${t('property')}` }
];
export const propertyOptions = [
  {
    value: LOCATION_TYPES.PICKING,
    label: `${t('propertyOptions.pickingLocation')}`
  },
  { value: LOCATION_TYPES.BUFFER, label: `${t('propertyOptions.buffer')}` },
  { value: LOCATION_TYPES.VIRTURAL, label: `${t('propertyOptions.virtual')}` }
];
