/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import orderService from '@services/pre-order/order';
import DateRangePicker from '@components/DateRangePicker';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import { dateFormat } from '@constants/utils';
import { sub } from 'date-fns';
import HelmetContainer from '@components/HelmetContainer';
import MyTextField from '@components/MyTextField';
import MyTable from '@components/MyTable';
import { Formatter } from '@utils/formatter';
// import MySelect from '@components/MySelect';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import MySelect from '@components/MySelect';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { columns, orderStatusOptions } from './enhance';
import { localeOptions } from '../Store/enhance';

export default function OrderHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);

  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const { formatTime } = Formatter;
  const [param, setParam] = useState({
    storeName: '',
    orderStatus: 'all',
    locale: 'all',
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat)
  });

  const [loading, setLoading] = useState(false);

  const getData = async (params) => {
    setLoading(true);
    const result = await orderService.getOrderList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRows: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleSearch = () => {
    const query = {
      startDate: formatTime(param?.startDate, dateFormat),
      endDate: formatTime(param?.endDate || new Date(), dateFormat),
      storeName: param?.storeName,
      orderStatus: param?.orderStatus || 'all',
      locale: param?.locale,
      orderDate: param?.orderDate || '',
      page: pagination.page + 1
    };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      storeName: param?.storeName,
      orderStatus: param?.orderStatus === 'all' ? null : param?.orderStatus,
      preorderLocale: param?.locale === 'all' ? null : param?.locale,
      startDate: query.startDate,
      endDate: query?.endDate,
      page: 0
    });
  };

  const handleRowClick = (row) => {
    navigate(row?.orderCode);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      storeName,
      orderStatus,
      locale,

      page
    } = query;
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      storeName,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      orderStatus: orderStatus === 'all' ? null : orderStatus,
      preorderLocale: locale === 'all' ? null : locale,

      page: page - 1 || 0
    };
    setParam({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      storeName: storeName || '',
      orderStatus: orderStatus || 'all',
      locale: locale || 'all'
    });

    setPagination({ ...pagination, page: page - 1 || 0 });
    getData(ignoreEmpty(dataParams));
  }, [location?.search]);
  const onAction = async (action, value) => {
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }

    if (action === 'changePage') {
      setParam({ ...param, page: value });
      const query = objToParams(
        ignoreEmpty({
          ...param,
          page: value + 1
        })
      );
      navigate(query);
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.preorder-order-history')}
      content="preorder order history"
      sx={{ width: '100%' }}
    >
      <SearchBar>
        <MySelect
          label={t('pages.pre-order.locales.label')}
          value={param?.locale}
          data={localeOptions}
          onChange={(e) => setParam({ ...param, locale: e?.target?.value })}
          sx={{ width: '120px', mr: 2 }}
        />
        <MyTextField
          label={t('pages.pre-order.store')}
          value={param?.storeName}
          onChange={(e) => setParam({ ...param, storeName: e?.target?.value })}
          sx={{ mr: 2, height: 40 }}
        />
        <FormControl sx={{ mr: 2 }}>
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />
        </FormControl>
        <MySelect
          label={t('pages.pre-order.status')}
          value={param?.orderStatus}
          data={orderStatusOptions}
          onChange={(e) =>
            setParam({ ...param, orderStatus: e?.target?.value })
          }
          sx={{ width: '120px', mr: 2 }}
        />
        <Button variant="contained" onClick={handleSearch}>
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ mt: '30px', mb: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRows || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <MyTable
        hover
        rerender
        columns={columns}
        data={data || []}
        onRowClick={handleRowClick}
        pagination={pagination}
        onChangePage={(e, page) => {
          const query = paramsToObj(location?.search);
          const { startDate, endDate, storeName, orderStatus, locale } = query;
          const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
          const dataParams = {
            storeName,
            startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
            endDate: formatTime(endDate || new Date(), dateFormat),
            orderStatus: orderStatus === 'all' ? null : orderStatus,
            preorderLocale: locale === 'all' ? null : locale
          };
          const newQuery = objToParams(
            ignoreEmpty({ page: page + 1, ...dataParams })
          );
          setPagination({ ...pagination, page });
          getData({ page, size: 20, ...dataParams });
          navigate(newQuery);
        }}
        minusHeight={235}
      />
    </HelmetContainer>
  );
}
