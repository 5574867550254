import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import useMergeState from '@hooks/useMergeState';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';
import DaumAddress from '@components/DaumAddress';
import LabelBox from '@components/LabelBox';
import MyDialog from '@components/MyDialog';

import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';
import { MINIBAR_OWNERSHIP_TYPES } from '@constants/utils';
import { minibarServices } from '@services/index';
import { formatMoney } from '@utils/helpers';
import MySelect from '@components/MySelect';
import { minibarOwnershipStatusesList } from '@pages/ProductManagement/MinibarOrder/enhance';
import ListEquipmentDialog from './ListEquipmentDialog';
import { tableBodyStyle } from './enhance';

const requiredFields = ['clientCode', 'minibarNickname'];

const maxLengthOf = {
  minibarNickname: 30,
  // minibarGroup: 30,
  shippingManager: 20,
  shippingLocation: 20,
  accessMethod: 40,
  shippingDistrict: 20,
  shippingAddress1: 250,
  shippingAddress2: 250,
  parkingType: 20,
  other: 200,
  shippingCycle: 10,
  note: 250
};

const RegisterMinibarDialog = ({ open, setOpen }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [openListEquipment, setOpenListEquipment] = useState(false);
  const [listEquipment, setListEquipment] = useState([]);
  const [client, setClient] = useState(); // 그룹명
  const [goalPrice, setGoalPrice] = useState(''); // 목표금액
  const [loading, setLoading] = useState(false);
  const [equipmentLoading, setEquipmentLoading] = useState(false);

  const [errMsg, setErrMsg] = useMergeState({
    clientCode: '',
    minibarNickname: '',
    // minibarGroup: '',
    shippingManager: '',
    shippingLocation: '',
    accessMethod: '',
    shippingDistrict: '',
    shippingAddress1: '',
    shippingAddress2: '',
    parkingType: '',
    other: '',
    goalPrice: '',
    shippingCycle: '',
    note: ''
  });
  const [ownershipType, setOwnershipType] = useState(
    MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED
  );
  const [daumAddress, setDaumAddress] = useState('');

  // 매대현황
  const minibarNicknameRef = useRef();
  // const minibarGroupRef = useRef(); // 별칭
  // 배송정보
  const shippingManagerRef = useRef(); // 배송 담당자
  const shippingLocationRef = useRef(); // 매대위치
  const accessMethodRef = useRef(); // 출입여부
  const shippingDistrictRef = useRef(); // 지역구
  const shippingAddressRef = useRef(); // 배송주소
  const parkingTypeRef = useRef(); // 주차유형
  const otherRef = useRef(); // 특이사항
  const shippingCycleRef = useRef(); // 배송주기
  const shippingWeekMon = useRef(); // 배송요일
  const shippingWeekTue = useRef(); // 배송요일
  const shippingWeekWed = useRef(); // 배송요일
  const shippingWeekThu = useRef(); // 배송요일
  const shippingWeekFri = useRef(); // 배송요일
  // 메모
  const noteRef = useRef(); // 메모

  const handleDaumAddressComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setDaumAddress(fullAddress);
    setErrMsg({
      shippingAddress1:
        fullAddress?.length > maxLengthOf.shippingAddress1
          ? t('validation.dataTooLong', { max: maxLengthOf.shippingAddress1 })
          : ''
    });
  };

  const inputFormListener = ({ name, value }) => {
    let msg = '';

    if (requiredFields.includes(name) && !value) {
      msg = t('common.required');
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }

    setErrMsg({ [name]: msg });
  };

  const validateInputForm = () => {
    const tempErr = { ...errMsg };
    if (!client) {
      tempErr.clientCode = t('common.required');
    }
    if (!minibarNicknameRef.current.value) {
      tempErr.minibarNickname = t('common.required');
    }
    // if (!minibarGroupRef.current.value) {
    //   tempErr.minibarGroupRef = t('common.required');
    // }
    setErrMsg(tempErr);
    // eslint-disable-next-line no-restricted-syntax
    for (const err of Object.values(tempErr)) {
      if (err) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      clientCode: client?.clientCode,
      minibarNickname: minibarNicknameRef.current.value,
      // minibarGroup: minibarGroupRef.current.value,
      note: noteRef.current.value,
      goalPrice: parseInt(goalPrice, 10) || 0, // int
      ownershipType,
      shippingInfo: {
        shippingManager: shippingManagerRef.current.value,
        shippingAddress1: daumAddress,
        shippingAddress2: shippingAddressRef.current.value,
        shippingDistrict: shippingDistrictRef.current.value,
        shippingLocation: shippingLocationRef.current.value,
        accessMethod: accessMethodRef.current.value,
        parkingType: parkingTypeRef.current.value,
        other: otherRef.current.value,
        shippingCycle: shippingCycleRef.current.value,
        shippingWeekMon: shippingWeekMon.current.checked ? 'Y' : 'N',
        shippingWeekTue: shippingWeekTue.current.checked ? 'Y' : 'N',
        shippingWeekWed: shippingWeekWed.current.checked ? 'Y' : 'N',
        shippingWeekThu: shippingWeekThu.current.checked ? 'Y' : 'N',
        shippingWeekFri: shippingWeekFri.current.checked ? 'Y' : 'N'
      },
      listEquipment: listEquipment
        .map(
          ({
            equipmentCode,
            equipmentQuantity,
            equipmentPrice,
            sumAmount
          }) => ({
            equipmentCode,
            equipmentQuantity,
            equipmentPrice,
            sumAmount
          })
        )
        .filter(({ equipmentQuantity }) => equipmentQuantity)
    };

    const result = await minibarServices.createMinibar(data);
    setLoading(false);
    if (result?.error) dispatch(setError(error2Text(result.error)));
    else setOpen(false);
  };

  const getEquiments = async () => {
    setEquipmentLoading(true);
    const result = await minibarServices.getListEquipmentByMinibar({
      page: 0,
      size: 9
    });
    setEquipmentLoading(false);
    if (!result?.data) {
      toast.error('Something went wrong');
      return false;
    }

    setListEquipment(result.data.data || []);
    return true;
  };

  const handleOpenListEquipment = async () => {
    if (listEquipment?.length <= 0) {
      setOpenListEquipment(await getEquiments());
    } else {
      setOpenListEquipment(true);
    }
  };

  const handleChangeGoalPrice = (e) =>
    setGoalPrice(Formatter.fStringToRangeNumber(e.target.value, 9_999_999_999));

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.minibar.registerMinibar')}
      hasCloseButton
      hasCancelButton
      onOk={() => {
        if (validateInputForm()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => handleSubmit()
            })
          );
        }
      }}
      loading={loading}
      setLoading={setLoading}
      sx={{
        maxWidth: 1200,
        '.MuiDialogContent-root': {
          border: '1px solid #D3D3D3',
          borderRadius: '8px'
        }
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableBody sx={tableBodyStyle}>
            {/* first big row 매대현황 */}
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.minibarStatus')}
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.groupName')} required />
                  <ClientAutoCompleteV2
                    sx={{ mx: 1.5 }}
                    label={null}
                    client={client}
                    setClient={(e) => {
                      // name, code
                      setClient(e);
                      setErrMsg({ clientCode: '' });
                    }}
                    name="clientCode"
                    errMg={!client && errMsg.clientCode}
                    disabled={loading}
                  />
                </Stack>
                {/* <Stack direction="row" alignItems="center" sx={{ mr: 1.5 }}>
                  <LabelBox title={t('pages.minibar.minibar-group')}  />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    ref={minibarGroupRef}
                    placeholder={t('pages.minibar.minibar-group')}
                    disabled={loading}
                    name="minibarGroup"
                    errMg={errMsg.minibarGroup}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack> */}
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center" sx={{ mr: 1.5 }}>
                  <LabelBox title={t('pages.minibar.nickname')} required />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    ref={minibarNicknameRef}
                    placeholder={t('pages.minibar.nickname')}
                    disabled={loading}
                    name="minibarNickname"
                    errMg={errMsg.minibarNickname}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.equipment-status')} />
                  <Stack justifyContent="center" alignItems="center" flex={1}>
                    <LoadingButton
                      variant="outlined"
                      disabled={loading}
                      onClick={handleOpenListEquipment}
                      loading={equipmentLoading}
                    >
                      {t('pages.minibar.equipmentInput')}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>

            {/* second big row 배송정보 */}
            <TableRow>
              <TableCell rowSpan={4} component="th" scope="row">
                {t('pages.minibar.shippingInfo')}
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.shippingManager')} />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    ref={shippingManagerRef}
                    placeholder={t('pages.minibar.shippingInfo')}
                    disabled={loading}
                    name="shippingManager"
                    errMg={errMsg.shippingManager}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.location')} />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    placeholder={t('pages.minibar.location')}
                    ref={shippingLocationRef}
                    disabled={loading}
                    name="shippingLocation"
                    errMg={errMsg.shippingLocation}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.accessMethods')} />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    placeholder={t('pages.minibar.accessMethods')}
                    ref={accessMethodRef}
                    disabled={loading}
                    name="accessMethod"
                    errMg={errMsg.accessMethod}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.areaDistrict')} />
                  <MyTextField
                    placeholder={t('pages.minibar.areaDistrict')}
                    sx={{ width: 150, flex: 1, mx: 1.5 }}
                    ref={shippingDistrictRef}
                    disabled={loading}
                    name="shippingDistrict"
                    errMg={errMsg.shippingDistrict}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <LabelBox title={t('pages.minibar.ownershipType')} />
                  <MySelect
                    name="ownershipType"
                    value={ownershipType}
                    disabled={loading}
                    data={minibarOwnershipStatusesList}
                    onChange={(e) => setOwnershipType(e.target.value)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={4}>
                <Stack direction="row" alignItems="center">
                  <LabelBox
                    title={t('pages.minibar.shippingAddress')}
                    sx={{ height: 129 }}
                  />
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={1.5}
                    sx={{ mx: 1.5 }}
                    flex={1}
                  >
                    <DaumAddress
                      name="shippingAddress1"
                      errMg={errMsg.shippingAddress1}
                      value={daumAddress}
                      onComplete={handleDaumAddressComplete}
                      placeholder={t('pages.minibar.shippingAddress')}
                      disabled={loading}
                    />
                    <MyTextField
                      sx={{ width: '100%' }}
                      placeholder={t('pages.minibar.shippingAddress')}
                      name="shippingAddress2"
                      errMg={errMsg.shippingAddress2}
                      onChange={(e) => inputFormListener(e.target)}
                      ref={shippingAddressRef}
                      fullWidth
                      disabled={loading}
                    />
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.parkingType')} />
                  <MyTextField
                    placeholder={t('pages.minibar.parkingType')}
                    sx={{ width: 150, flex: 1, mx: 1.5 }}
                    ref={parkingTypeRef}
                    disabled={loading}
                    name="parkingType"
                    errMg={errMsg.parkingType}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.other')} />
                  <MyTextField
                    name="other"
                    errMg={errMsg.other}
                    onChange={(e) => inputFormListener(e.target)}
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    placeholder={t('pages.minibar.other')}
                    ref={otherRef}
                    disabled={loading}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={2}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.goalPrice')} />
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5 }}
                    placeholder="1,100"
                    name="goalPrice"
                    errMg={errMsg.goalPrice}
                    value={formatMoney(goalPrice)}
                    onChange={handleChangeGoalPrice}
                    typeValue="onlyNumber"
                    disabled={loading}
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2.5} rowSpan={1}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.shippingCycle')} />
                  <MyTextField
                    sx={{ flex: 1, mx: 1.5 }}
                    placeholder={t('pages.minibar.shippingCycle')}
                    ref={shippingCycleRef}
                    disabled={loading}
                    name="shippingCycle"
                    errMg={errMsg.shippingCycle}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
              <TableCell colSpan={3.5}>
                <Stack direction="row" alignItems="center">
                  <LabelBox title={t('pages.minibar.shippingDays')} />
                  <FormControl
                    disabled={loading}
                    sx={{ display: 'flex', flexDirection: 'row', mx: 3 }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('pages.minibar.mon')}
                      inputRef={shippingWeekMon}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('pages.minibar.tue')}
                      inputRef={shippingWeekTue}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('pages.minibar.wed')}
                      inputRef={shippingWeekWed}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('pages.minibar.thu')}
                      inputRef={shippingWeekThu}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('pages.minibar.fri')}
                      inputRef={shippingWeekFri}
                    />
                  </FormControl>
                </Stack>
              </TableCell>
            </TableRow>

            {/* last big row 메모 */}
            <TableRow>
              <TableCell component="th" scope="row" sx={{ height: 68 }}>
                {t('pages.minibar.note')}
              </TableCell>
              <TableCell colSpan={6}>
                <Stack direction="row" alignItems="center">
                  <MyTextField
                    sx={{ minWidth: 150, flex: 1, mx: 1.5, my: 1 }}
                    placeholder={t('pages.minibar.note')}
                    multiline
                    rows={3}
                    ref={noteRef}
                    disabled={loading}
                    name="note"
                    errMg={errMsg.note}
                    onChange={(e) => inputFormListener(e.target)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ListEquipmentDialog
        open={openListEquipment}
        setOpen={setOpenListEquipment}
        data={listEquipment}
        setData={setListEquipment}
      />
    </MyDialog>
  );
};

export default RegisterMinibarDialog;
