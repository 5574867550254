/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Stack } from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import minibarOrderService from '@services/minibarOrderService';
import minibarServices from '@services/minibar';
import MySelect from '@components/MySelect';
import error2Text from '@utils/error2Text';
import { setError } from '@store/reducers/appReducer';
import SearchBar from '@components/SearchBar';
import MyTable from '@components/MyTable';
import {
  minibarMultipleSelectColumns,
  minibarOwnershipStatusesList
} from '../enhance';

const AddProductDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [minibarList, setMinibarList] = useState([]);
  const [originalMinibarList, setOriginalMinibarList] = useState([]);
  const [isViewingFilteredItems, setIsviewingFilteredItems] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectOwnershipType, setSelectOwnershipType] = useState(null);
  const [loading, setLoading] = useState(false);
  const getMinibarList = async () => {
    const result = await minibarServices.getListMinibarAll({
      clientCode: null,
      isOption: false
    });
    const filteredMinibars = result.data?.filter(
      (item) => item?.isTestingDevice !== 'Y'
    );
    setMinibarList(filteredMinibars || []);
    setOriginalMinibarList(filteredMinibars || []);
  };

  const handleSearch = () => {
    if (!searchInput) {
      setIsviewingFilteredItems(false);
      setMinibarList(originalMinibarList);
    }
    const filteredMinibars = originalMinibarList?.filter((item) =>
      item.minibarNickName.toLowerCase().includes(searchInput.toLowerCase())
    );
    setIsviewingFilteredItems(true);
    setMinibarList(filteredMinibars);
  };
  const handleClear = () => {
    if (!searchInput && !selectOwnershipType) return;
    setSearchInput('');
    setSelectOwnershipType(null);
    setSelectedItems([]);
    setMinibarList(originalMinibarList);
  };

  const handleSearchChange = (value) => {
    if (!searchInput) setMinibarList(originalMinibarList);
    setSearchInput(value);
    const filteredMinibars = originalMinibarList.filter((item) =>
      item.minibarNickname.toLowerCase().includes(value.toLowerCase())
    );
    setMinibarList(filteredMinibars);
  };

  const handleSelectOwnership = (value) => {
    if (!searchInput) setMinibarList(originalMinibarList);
    setSelectOwnershipType(value);
    const filteredMinibars = originalMinibarList.filter(
      (item) => item.ownershipType === value
    );

    setSelectedItems((prev) => {
      return filteredMinibars.map((minibar) => minibar.minibarCode);
    });
    setMinibarList((prev) => {
      return filteredMinibars || [];
    });
  };
  const handleCheckBoxClick = (item) => {
    if (selectedItems.includes(item?.minibarCode)) {
      setSelectedItems((preItems) => {
        return preItems.filter((code) => code !== item.minibarCode);
      });
    } else {
      setSelectedItems([...selectedItems, item.minibarCode]);
    }
  };
  const handleExportMultipleMinibarData = async () => {
    setLoading(true);
    const sendData = { minibarCodes: selectedItems };
    const result = await minibarOrderService.exportMultipleMinibarData(
      sendData
    );
    if (result.error) {
      dispatch(setError(error2Text(result.error)));
      return;
    }
    setSelectedItems([]);
    setOpen(false);
    setLoading(false);
  };
  const getActionButtons = (item, actionType) => {
    return (
      <Checkbox
        onClick={() => handleCheckBoxClick(item)}
        checked={selectedItems?.includes(item?.minibarCode)}
      />
    );
  };

  useEffect(() => {
    getMinibarList();
  }, []);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCancelButton
      hasCloseButton
      okTitle={t('pages.minibarOrder.excelBulkDownload')}
      title={t('pages.minibarOrder.excelBulkDownload')}
      onOk={handleExportMultipleMinibarData}
      sx={{
        maxWidth: 800,
        maxHeight: 'none'
      }}
      badgeCount={selectedItems.length}
      loading={loading}
      disabled={selectedItems?.length === 0}
    >
      <SearchBar>
        <Stack direction="row" alignItems="center" spacing={2}>
          <MyTextField
            label={t('pages.minibarOrder.minibar-nickname')}
            placeholder={t('pages.minibarOrder.minibar-nickname')}
            value={searchInput}
            sx={{ maxWidth: 250 }}
            onChange={(e) => handleSearchChange(e.target.value)}
            onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
          <Stack direction="row" alignItems="center" spacing={2}>
            <MySelect
              label={t('pages.minibar.ownershipType')}
              value={selectOwnershipType}
              data={minibarOwnershipStatusesList}
              sx={{ width: 150 }}
              onChange={(e) => handleSelectOwnership(e.target.value)}
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
            />
          </Stack>
          <Button
            disabled={!searchInput && !selectOwnershipType}
            variant="contained"
            sx={{ height: 36 }}
            onClick={handleClear}
          >
            {t('button.clear')}
          </Button>
        </Stack>
      </SearchBar>
      <Stack>
        <MyTable
          rerender
          columns={minibarMultipleSelectColumns}
          data={minibarList}
          getActionButtons={getActionButtons}
          minusHeight={140}
          // loadingComponent={
          //   <Stack alignItems="center" justifyContent="center">
          //     <CircularProgress
          //       size={44}
          //       sx={{
          //         right: 0,
          //         left: 0,
          //         margin: 'auto'
          //       }}
          //     />
          //   </Stack>
          // }
        />
      </Stack>
    </MyDialog>
  );
};

export default AddProductDialog;
