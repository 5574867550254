/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import usageHistoryService from '@services/usageHistory';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import DateRangePicker from '@components/DateRangePicker';
import { sub } from 'date-fns';
import { Formatter } from '@utils/formatter';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@constants/utils';
import { columnUsageHistory } from './enhance';

export default function UsageHitoryPage() {
  const { t } = useTranslation();
  const configColumn = columnUsageHistory;
  const navigate = useNavigate();
  const location = useLocation();
  const { formatTime } = Formatter;
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [param, setParam] = useState({
    minibarCode: null,
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    page: 0
  });
  const [minibar, setMinibar] = useState(null);
  const getUsageHistory = async (param) => {
    const result = await usageHistoryService.getUsageHistory(param);
    if (!result.error) {
      setState({
        ...state,
        items: result.data?.items || [],
        pagination: {
          ...pagination,
          page: result.data?.page || 0,
          totalRow: result.data?.totalRow || 0,
          count: result.data?.totalPage || 0
        }
      });
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    setParam({
      ...param,
      startDate: query?.startDate || formatTime(firstDayOfMonth, dateFormat),
      endDate: query?.endDate || formatTime(new Date(), dateFormat),
      page: query?.page - 1 || 0,
      minibarCode: query?.minibarCode || null
    });
    setMinibar({ ...minibar, minibarCode: query?.minibarCode || null });
    getUsageHistory({
      ...param,
      startDate: formatTime(query?.startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(query?.endDate || new Date(), dateFormat),
      page: query?.page - 1 || 0,
      minibarCode: query?.minibarCode || null
    });
  }, [location?.search]);

  const { items, pagination } = state;

  const onAction = (action, value) => {
    if (action === 'search') {
      const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
      getUsageHistory({ ...param, page: 0 });
      navigate(query);
    }
    if (action === 'selectMinibar') {
      setParam({
        ...param,
        minibarCode: value?.minibarCode || null
      });
    }
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }
    if (action === 'changePage') {
      setParam({
        ...param,
        page: value
      });
      const query = objToParams(ignoreEmpty({ ...param, page: value + 1 }));
      getUsageHistory({ ...param, page: value });
      navigate(query);
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.usageHistory')}
      content="Usage history page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />
        </FormControl>
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={(e) => {
            setMinibar(e);
            setParam({ ...param, minibarCode: e?.minibarCode });
          }}
          onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
        />
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={() => onAction('search')}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: '30px' }}>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        columns={configColumn}
        data={items}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
    </HelmetContainer>
  );
}
