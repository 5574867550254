import React, { useEffect, useState } from 'react';

import { Box, Button, TableRow } from '@mui/material';

import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import { categoryServices } from '@services/index';

import CustomTableCell from './CustomTableCell';
import { categoryColumns } from '../../../enhance';

const CategoryArea = ({ onApply, pageT, listCtgState }) => {
  const [data, setData] = useState();
  const {
    stCat,
    setStCat,
    ndCat,
    setNdCat,
    rdCat,
    setRdCat,
    specCat,
    setSpecCat
  } = listCtgState;
  const getCategories = async () => {
    const result = await categoryServices.getCategory();
    if (result.data) {
      setData(result.data);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box sx={{ mt: '30px' }}>
      <ZombieTable columns={categoryColumns}>
        <CustomTableBody>
          <TableRow>
            <CustomTableCell
              data={data?.first || []}
              setValue={setStCat}
              value={stCat}
            />
            <CustomTableCell
              data={data?.second || []}
              setValue={setNdCat}
              value={ndCat}
            />
            <CustomTableCell
              data={data?.third || []}
              setValue={setRdCat}
              value={rdCat}
            />
            <CustomTableCell
              data={data?.special || []}
              setValue={setSpecCat}
              value={specCat}
              multiple
            />
          </TableRow>
        </CustomTableBody>
      </ZombieTable>
      <Box sx={{ textAlign: 'center', mt: '20px' }}>
        <Button variant="outlined" onClick={onApply}>
          {pageT('apply')}
        </Button>
      </Box>
    </Box>
  );
};

export default CategoryArea;
