import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { tableBodyStyle } from '../enhance';

const ProcessedArea = ({
  data,
  onNavigateBack,
  onChangeStatus,
  statusList,
  isProcessed
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'pages.development-request'
  });
  const { t: buttonT } = useTranslation(null, {
    keyPrefix: 'button'
  });
  const handleChangeStatus = () => {
    onChangeStatus({
      title: t('change-status'),
      cancelTitle: buttonT('cancel'),
      okTitle: buttonT('register'),
      status: statusList[4].value // Completed
    });
  };
  return (
    <Box>
      <Typography
        sx={{ fontWeight: 'fontWeightBold', fontSize: 16, mb: '10px' }}
      >
        {t('processed')}
      </Typography>
      <TableContainer
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden',
          mb: '30px'
        }}
      >
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell role="row" component="th">
                {t('title')}
              </TableCell>
              <TableCell>{data.reqSubject}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('writer')}
              </TableCell>
              <TableCell>{data.progressName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('register-date')}
              </TableCell>
              <TableCell>
                {new Date(data.progressDate).toLocaleString('en-CA', {
                  hour12: false,
                  timeZone: 'Asia/Seoul'
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isProcessed && (
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="contained" onClick={handleChangeStatus}>
            {buttonT('complete')}
          </Button>
          <Button variant="outlined" onClick={onNavigateBack}>
            {t('list')}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ProcessedArea;
