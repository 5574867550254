import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const minibarT = i18n.getFixedT(null, null, 'pages.minibar');
const eqmT = i18n.getFixedT(null, null, 'pages.equipment');

export const minibarColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'clientName',
    label: minibarT('client'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'groupName',
    label: minibarT('groupName'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: minibarT('nickname'),
    minWidth: 120,
    align: 'center'
  },
  {
    label: minibarT('ownershipType'),
    minWidth: 180,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 7
  },
  {
    name: 'placement',
    label: minibarT('minibarPlacement'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'interworking',
    label: minibarT('isKioskConnected'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'monitoringStatus',
    label: minibarT('monitoringStatus'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },
  {
    name: 'connectionStatus',
    label: minibarT('connectionStatus'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 4
  },
  {
    name: 'lastSignalTime',
    label: minibarT('lastConnectionTime'),
    minWidth: 150,
    align: 'center',
    render: ({ lastSignalTime }) => {
      return lastSignalTime ? Formatter.formatTime(lastSignalTime) : '';
    }
  },
  {
    name: 'testingStatus', // isRate
    label: minibarT('testingStatus'),

    minWidth: 70,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 5
  },
  {
    name: 'isPromotion',
    label: minibarT('promotionStatus'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 6
  },
  {
    name: 'rateCompany',
    label: minibarT('discountRate'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.MODAL
  },
  {
    name: 'investmentAmount',
    label: minibarT('investmentAmount'),
    minWidth: 65,
    align: 'center',
    render: ({ investmentAmount }) => {
      return new Intl.NumberFormat('en-CA').format(investmentAmount);
    }
  },
  {
    name: 'shippingAddress1',
    label: minibarT('shippingAddress'),
    minWidth: 148,
    align: 'center'
  },
  {
    name: 'editDt',
    label: minibarT('editDt'),
    minWidth: 150,
    align: 'center',
    render: ({ editDt }) => {
      return editDt ? Formatter.formatTime(editDt) : '';
    }
  },
  {
    name: 'regDt',
    label: minibarT('regDt'),
    minWidth: 150,
    align: 'center',
    render: ({ regDt }) => {
      return new Date(regDt).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      });
    }
  },
  // {
  //   label: minibarT('equipmentType'),
  //   minWidth: 92,
  //   align: 'center',
  //   type: tableTypes.ACTION,
  //   actionType: 2
  // },

  {
    label: minibarT('notes'),
    minWidth: 50,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  }
];

export const equipmentDialogColumns = [
  {
    name: 'equipmentName',
    label: minibarT('equipmentName'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'isCheck',
    label: minibarT('isCheck'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'equipmentQuantity',
    label: minibarT('equipmentQuantity'),
    minWidth: 80,
    align: 'center'
  },

  {
    name: 'sumAmount',
    label: minibarT('sumAmount'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'remainingQuantity',
    label: eqmT('remainingEqm'),
    minWidth: 80,
    align: 'center'
  }
];

export const equipmentListTableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      height: '64px'
    },
    td: {
      p: 0,
      borderBottom: 'none',
      ':not(:last-child)': {
        borderRight: '1px solid #EEEEEE'
      }
    },
    'th, td': {
      p: '0 12px'
    }
  }
};

export const equipmentListTableHeaderStyle = {
  '.MuiTableCell-root': {
    p: 0,
    height: '50px',
    fontWeight: 'fontWeightBold',
    fontSize: '13px',
    color: '#6F869C',
    lineHeight: '18px',
    borderBottom: '1px solid #D3D3D3',
    '&:not(:last-child)': {
      borderRight: '1px solid #EEEEEE'
    }
  },
  bgcolor: '#FCFCFC'
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 80,
      whiteSpace: 'nowrap'
    },
    td: { px: 0, borderRight: '1px solid #EEEEEE' }
  },
  '.MuiTableCell-root': { p: '0 12px', borderBottom: 'none' }
};
export const minibarActiontypes = {
  monitoring: 'monitoring',
  connectionStatus: 'connectionStatus',
  testStatus: 'testStatus',
  promnotionStatus: 'promotionStatus'
};
