import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Stack,
  styled,
  Box,
  Container,
  Typography,
  Button
} from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import { useParams } from 'react-router-dom';
import consultationInquiryService from '@services/consultationInquiry';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import Backbutton from '@components/BackButton';

import { inquirDetailSchema } from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%'
};
const labelStyle = {
  backgroundColor: '#FCFCFC',

  borderRight: '1px solid #D3D3D3'
};

export default function ConsultationInquiryDetail() {
  const { introductionRequestCode } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation(null, {
    keyPrefix: 'pages.consultationInquiry'
  });
  const [g] = useTranslation();
  const [data, setData] = useState([]);

  const getData = async () => {
    const result = await consultationInquiryService.getServiceIntroDetail(
      introductionRequestCode
    );
    if (result?.data) setData(result.data?.data || []);
  };

  const updateStatus = async (newStatus) => {
    const rs = await consultationInquiryService.updateServiceInquiryStatus(
      introductionRequestCode,
      {
        requestStatus: newStatus
      }
    );
    if (!rs?.error) {
      dispatch(setSuccess({ message: t('info.success.content') }));
      window.location.reload();
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  useEffect(() => {
    if (introductionRequestCode) getData();
  }, [introductionRequestCode]);

  return (
    <HelmetContainer
      title={g('pageTitle.service-inquiry-detail')}
      content="Service Inquiry detail"
    >
      <Backbutton route={-1} sx={{ mt: -2, mb: 2, ml: 0 }} />
      <Container component={BorderBox}>
        <Stack flexDirection="row" flexWrap="wrap">
          {inquirDetailSchema.map((item) => {
            return (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: {
                    xs: '100%',
                    md: item?.grid === 12 ? '100%' : '50%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                  ...item.border
                }}
                key={`${item?.name} - ${item?.label}`}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
                <RenderField
                  // onChangeInput={(e) => onChange(e, item?.type)}
                  // errMg={errMg?.[item?.name]}
                  {...item}
                  value={data}
                />
              </Stack>
            );
          })}
        </Stack>
      </Container>

      <Stack
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{ zIndex: 50 }}
        mt={2}
      >
        {/* <Button onClick={() => navigate(-1)} variant="outlined">
          {t('list')}
        </Button> */}
        <Button
          onClick={() =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'modify',
                action: () =>
                  updateStatus(
                    data?.requestStatus === 'REGISTERED' && 'COMPLETED'
                  )
              })
            )
          }
          variant="outlined"
          sx={{ ml: 1.5, minWidth: '120px', boxShadow: 0 }}
          disabled={data?.requestStatus !== 'REGISTERED'}
        >
          {['REGISTERED', 'REJECTED'].includes(data?.requestStatus)
            ? t('send')
            : t('sending-complete')}
        </Button>
        <Button
          onClick={() =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'modify',
                action: () =>
                  updateStatus(
                    data?.requestStatus === 'REGISTERED' && 'REJECTED'
                  )
              })
            )
          }
          variant="outlined"
          color="error"
          sx={{ ml: 1.5, minWidth: '120px' }}
          disabled={!(data?.requestStatus === 'REGISTERED')}
        >
          {['REGISTERED', 'COMPLETED'].includes(data?.requestStatus)
            ? t('refusing')
            : t('refuse-complete')}
        </Button>
      </Stack>
    </HelmetContainer>
  );
}

const RenderField = ({ value, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;

  // if (type === 'select')
  return (
    <Stack
      direction="row"
      sx={{
        // flexGrow: 1,
        height: '60px',
        ...inputStyle
      }}
    >
      <Typography sx={{ color: '#111111', fontSize: 14, ...item?.style }}>
        {item?.formatter
          ? item?.formatter(value[item?.name])
          : value[item?.name]}
      </Typography>
    </Stack>
  );
  // return (
  //   <MyTextField
  //     value={
  //       type === 'number' ? formatMoney(value[item.name]) : value[item.name]
  //     }
  //     errMg={errMg}
  //     onChange={onChangeInput}
  //     size="small"
  //     {...rest}
  //     sx={{ ...inputStyle, minWidth: 210 }}
  //     typeValue={type === 'number' && 'onlyNumber'}
  //   />
  // );
};
