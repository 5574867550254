/* eslint-disable no-unused-expressions */
import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import MyTextField from '@components/MyTextField';
import { IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MyDialog from '@components/MyDialog';
import { useDispatch } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import MyTable from '@components/MyTable';
import { useLocation, useNavigate } from 'react-router-dom';
import pickingService from '@services/picking';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { PICKING_STATUSES } from '@constants/utils';
import { totalPickingColumns, indiPickingColumns } from '../enhance';

//  [improve]
//  handle type functions in state
// update type rendering in every location params change
const Picking = () => {
  // eslint-disable-next-line no-useless-escape
  const format = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location?.pathname?.split('/');
  const type = pathname[pathname.length - 1];
  const tableColumns =
    type === 'total' ? totalPickingColumns : indiPickingColumns;

  const [dialog, setDialog] = useState({
    open: false,
    action: ''
  });

  const [items, setItems] = useState([]);
  const [state, setState] = React.useState({
    items: []
  });

  const [selectedItem, setSelectedItem] = useState([]);

  const getTotalPickings = async () => {
    if (type !== 'total') return;

    const rs = await pickingService.getTotalPickingList();
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      const data = rs?.data?.data.map((p) => ({ ...p, editMode: false }));
      setItems(data || []);
    }
  };

  const getIndividualPickings = async () => {
    if (type === 'total') return;
    const rs = await pickingService.getIndividualPickingList();
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      setItems(rs?.data.data);
    }
  };

  const getData = () => {
    type === 'total' ? getTotalPickings() : getIndividualPickings();
  };

  useEffect(() => {
    setState({
      ...state,
      title:
        type === 'total'
          ? t('pages.picking.totalList')
          : t('pages.picking.indiList'),
      action: type
    });
    getData();
  }, []);

  const handleClickTable = (item) => {
    let hasPickingCode = false;
    if (type === 'total') {
      hasPickingCode = item?.totalPickingCode && true;
    } else hasPickingCode = item?.individualPickingCode && true;
    hasPickingCode &&
      navigate(
        type === 'total' ? item?.totalPickingCode : item?.individualPickingCode
      );
  };

  const handleDelete = async (code) => {
    if (!code) return;

    const rs =
      type === 'total'
        ? await pickingService.deleteTotalPicking(code)
        : await pickingService.deleteIndividualPicking(code);

    if (!rs?.error) {
      getData();
      dispatch(setSuccess({ message: t('info.success.content') }));
      onAction('refreshDialog');
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const onAction = (command, item, event) => {
    switch (command) {
      case 'success':
        type === 'total' ? getTotalPickings() : getIndividualPickings();
        break;

      case 'name':
        setItems((prev) => {
          const updatedItems = prev.map((i) => {
            if (i.id === item?.id) {
              return {
                ...i,
                pickingNickname: event?.target.value,
                nameErr:
                  // eslint-disable-next-line no-nested-ternary
                  event?.target.value?.length > 15
                    ? t('validation.dataTooLong', { max: 15 })
                    : format.test(event?.target?.value)
                    ? '가능한 특수 문자: "_", "-"'
                    : ''
              };
            }
            return { ...i };
          });
          return updatedItems;
        });
        break;

      case 'refreshDialog':
        setDialog({ ...dialog, open: false, action: '', actionType: '' });
        break;

      default:
        break;
    }
  };

  const handleClickEditIcon = (totalPickingCode) => {
    if (!totalPickingCode) return;
    setItems((prev) => {
      return prev.map((p) => {
        if (p.totalPickingCode === totalPickingCode) {
          return { ...p, editMode: !p.editMode };
        }
        return { ...p };
      });
    });
  };

  const handleUpdateNickName = async (item) => {
    if (!item?.editMode) return;
    if (!item?.totalPickingCode) return;
    if (!item?.pickingNickname) return;

    if (item?.pickingNickname?.length > 15) {
      setDialog({ ...dialog, open: true, action: 'nameErr' });
    } else {
      const rs = await pickingService.updateTotalPickingNickName(
        item?.totalPickingCode,
        { pickingNickName: item.pickingNickname }
      );
      if (rs.error) {
        dispatch(setError(error2Text(rs.error)));
      } else {
        onAction('success');
        dispatch(setSuccess({ message: t('info.success.content') }));
      }
    }
  };
  // eslint-disable-next-line consistent-return
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            onClick={(e) => e.stopPropagation()}
          >
            <MyTextField
              value={item?.pickingNickname}
              onChange={(event) => {
                onAction('name', item, event);
              }}
              sx={{ width: 'auto' }}
              errMg={item?.nameErr}
              disabled={!item?.editMode}
            />

            {/* dynamic icons */}
            <IconButton
              disabled={item.nameErr}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (item.editMode) {
                  // eslint-disable-next-line no-unused-expressions

                  handleUpdateNickName(item);
                }
                handleClickEditIcon(item.totalPickingCode);
              }}
            >
              {item.editMode ? <CheckCircleIcon /> : <ModeEditIcon />}
            </IconButton>
          </Stack>
        );

      case 2:
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({
                ...dialog,
                open: true,
                action: 'delete',
                actionType: 'individual'
              });
            }}
            disabled={![PICKING_STATUSES.REGISTERED].includes(item?.status)}
          >
            <DeleteIcon />
          </IconButton>
        );

      default:
        break;
    }
  };

  return (
    <HelmetContainer
      title={
        type === 'total'
          ? t('pages.picking.totalList')
          : t('pages.picking.indiList')
      }
      content="Picking page"
    >
      <Stack direction="row" justifyContent="space-between" mx={1}>
        <IconButton
          onClick={() => navigate('/inventory-management/picking')}
          sx={{
            display: 'flex',
            gap: 0.5,
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}
          disableRipple
          disableTouchRipple
        >
          <ArrowBackIosNewIcon
            sx={{ height: '20px', color: 'black', fontWeight: 500 }}
          />
          <Typography sx={{ fontSize: 20, color: 'black', fontWeight: 500 }}>
            {t('pageTitle.picking')}
          </Typography>
        </IconButton>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {items?.length || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        columns={tableColumns}
        rerender
        data={items}
        onRowClick={handleClickTable}
        getActionButtons={getActionButtons}
        minusHeight={270}
      />

      {/* dialog */}
      {dialog?.action === 'delete' && (
        <MyDialog
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false })}
          isAlert
          title={t('dialog.title.areYouSureDeleteThisRecord')}
          hasCancelButton
        />
      )}

      {dialog?.action === 'nameErr' && (
        <MyDialog
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false })}
          isAlert
          title={t('error.INVALID_DATA.title')}
          subTitle={t('validation.dataTooLong', { max: 15 })}
          hasCancelButton
        />
      )}

      <MyDialog
        open={dialog.open && dialog.action === 'delete'}
        setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
        onOk={() =>
          handleDelete(
            type === 'total'
              ? selectedItem?.totalPickingCode
              : selectedItem?.individualPickingCode
          )
        }
        isAlert
        title={t('common.confirm-delete-title', {
          item:
            selectedItem?.totalPickingNickName ||
            selectedItem?.pickingNickName ||
            t('pageTitle.picking')
        })}
        hasCancelButton
      />
    </HelmetContainer>
  );
};

export default Picking;
