/* eslint-disable no-nested-ternary */
import { tableTypes } from '@constants/utils';
import { Box, Button, Stack, Typography } from '@mui/material';
import i18n from '@utils/i18n';
import env from '@constants/env';
import fallbackImg from '@svg/fallback-img.svg';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';
import { capitalize, formatMoney } from '@utils/helpers';
import MyLoadingButton from '@components/MyLoadingButton';
import { Formatter } from '@utils/formatter';

const productImgStyle = {
  objectFit: 'contain',
  width: '40px',
  height: '40px',
  border: '1px solid #EEEEEE'
};

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const c = i18n.getFixedT(null, null, 'common');
const g = i18n.getFixedT(null, null, '');

export const searchConditions = [
  { value: 'productName', label: `${p('product-name')}` },
  { value: 'barcode', label: `${p('bar-code')}` },
  { value: 'locationName', label: `${t('location')}` }
];

export const columns = [
  {
    name: 'centerName',
    label: t('center'),
    minWidth: 100,
    align: 'center'
  },

  {
    name: 'locationName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'shelfLife',
    label: c('expiryDate'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) => Formatter.formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'maker',
    label: p('manufacturer'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'stockQuantity',
    label: t('stock-quantity'),
    minWidth: 100,
    align: 'center',
    render: ({ stockQuantity }) => formatMoney(stockQuantity)
  }
];

export const stockingColumns = (onEvent, componentRender) => [
  {
    name: 'managerName',
    label: t('pic'),
    minWidth: 120,
    align: 'center'
  },

  {
    name: 'location',
    label: t('location'),
    minWidth: 160,
    align: 'center',
    render: ({ locationName, ...item }) => {
      return (
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography visibility={!locationName && 'hidden'}>
            {locationName}
          </Typography>
          <Button
            variant="contained"
            sx={{ fontSize: 12, height: 30, width: 80 }}
            onClick={() => onEvent(locationName ? 'modify' : 'register', item)}
          >
            {locationName ? t('modify') : t('register')}
          </Button>
        </Stack>
      );
    }
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'shelfLife',
    label: t('shelfLife'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'quantity',
    label: t('stocking-quantity'),
    minWidth: 160,
    align: 'center',
    render: (item) => componentRender.stockQuantityComponent(item)
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 160,
    align: 'center'
  },

  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'maker',
    label: p('manufacturer'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'productInfo',
    label: p('product-info'),
    minWidth: 160,
    align: 'center',
    render: (item) => (
      <Box>
        <Button
          disabled={!item?.centerName}
          onClick={() => onEvent('productSearch', item)}
          variant="contained"
          sx={{ fontSize: 12, height: 30, width: 80 }}
        >
          {t('product')}
        </Button>
      </Box>
    )
  },

  {
    label: t('action'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const unregisteredProductColumns = (onClick, componentRender) => [
  {
    name: 'managerName',
    label: t('pic'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'warehouse',
    label: t('warehouse-stock'),
    minWidth: 160,
    align: 'center',
    render: (item) => {
      return componentRender.warehouseComponent(item);
    }
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'quantity',
    label: t('stocking-quantity'),
    minWidth: 160,
    align: 'center',
    render: (item) => componentRender.stockQuantityComponent(item)
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'maker',
    label: p('manufacturer'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'productInfo',
    label: p('product-info'),
    minWidth: 150,
    align: 'center',
    render: (item) => (
      <Button
        onClick={() =>
          onClick(
            item?.productCode ? 'product-modify' : 'product-register',
            item
          )
        }
        variant="contained"
        sx={{ fontSize: 12, height: 30, width: 80 }}
      >
        {item?.productCode ? t('modify') : t('register')}
      </Button>
    )
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 160,
    align: 'center',
    render: ({ locationName, ...item }) => {
      return (
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography visibility={!locationName && 'hidden'} width={120}>
            {locationName}
          </Typography>
          <Button
            disabled={!item?.productCode}
            variant="contained"
            sx={{ fontSize: 12, height: 30, width: 80 }}
            onClick={() =>
              onClick(
                locationName ? 'locationModify' : 'locationRegister',
                item
              )
            }
          >
            {locationName ? t('modify') : t('register')}
          </Button>
        </Stack>
      );
    }
  },
  {
    label: t('action'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const stockTakingColumns = (onEvent) => [
  {
    name: 'centerName',
    label: t('center'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'quantity',
    label: t('stock-quantity'),
    minWidth: 120,
    align: 'center',
    render: ({ quantity }) => formatMoney(quantity)
  },
  {
    name: 'stocktakingQuantity',
    label: t('stock-taking-quantity'),
    minWidth: 130,
    align: 'center',
    render: (item) => {
      return item?.stocktakingYn === 'Y' ? (
        formatMoney(item?.surveyQty)
      ) : (
        <MyTextField
          onChange={(e) => onEvent('surveyQty', e, item)}
          name="surveyQty"
          value={formatMoney(item?.surveyQty)}
          typeValue="onlyNumber"
        />
      );
    }
  },
  {
    name: 'loss',
    label: t('loss'),
    minWidth: 130,
    align: 'center',
    render: ({ quantity, surveyQty, lossQuantity }) => (
      <span>
        {formatMoney(
          quantity
            ? parseInt(quantity, 10) - parseInt(surveyQty, 10)
            : lossQuantity
        )}
      </span>
    )
  },
  {
    name: 'manager',
    label: t('managements'),
    minWidth: 160,
    align: 'center',
    render: (item) => (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <MySelect
          placeholder={c('select')}
          renderValue={(selected) => {
            if (selected === -1) {
              return (
                <Typography sx={{ color: '#bbbbbb', fontStyle: 'italic' }}>
                  {c('select')}...
                </Typography>
              );
            }

            return selected;
          }}
          disabled={
            item?.stocktakingYn === 'Y' ||
            item?.surveyQty === '0' ||
            !item?.surveyQty ||
            parseInt(item?.quantity, 10) < parseInt(item?.surveyQty, 10)
          }
          onChange={(e) => onEvent('reason', e, item)}
          value={
            item?.surveyQty === '0' ||
            !item?.surveyQty ||
            parseInt(item?.quantity, 10) < parseInt(item?.surveyQty, 10)
              ? ''
              : item?.reason
              ? capitalize(item?.reason?.toLowerCase())
              : -1
          }
          sx={{ minWidth: 130 }}
          data={[
            { value: 'Loss', label: t('loss') },
            { value: 'Dispose', label: t('dispose') }
          ]}
        />
        <MyLoadingButton
          disabled={item?.stocktakingYn === 'Y'}
          onClick={() => onEvent('adjust', null, item)}
          variant="outlined"
          sx={{ height: 35, fontSize: 13 }}
        >
          {t('adjust')}
        </MyLoadingButton>
      </Stack>
    )
  },
  {
    name: 'stocktaking',
    label: t('stock-taking-date'),
    minWidth: 160,
    align: 'center',
    render: ({ inspectorDt, stocktakingDt, stocktakingYn }) =>
      new Date(
        stocktakingYn === 'Y' ? inspectorDt : stocktakingDt
      ).toLocaleString('en-CA', { hour12: false, timeZone: 'Asia/Seoul' })
  },
  {
    name: 'stockStatus',
    label: t('stock-taking-status'),
    minWidth: 160,
    align: 'center',
    render: ({ stocktakingYn }) =>
      stocktakingYn === 'Y' ? t('confirmed') : t('unidentified')
  },
  {
    label: t('action'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const lossColumns = [
  {
    name: 'date',
    label: t('date'),
    minWidth: 160,
    align: 'center',
    render: ({ lossDt }) =>
      new Date(lossDt).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    name: 'minibarName',
    label: c('minibar'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'capacity',
    label: p('volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: p('package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'loss',
    label: `${t('loss')} ${t('quantity')}`,
    minWidth: 100,
    align: 'center',
    render: ({ lossCount, lossDivision }) => (
      <span>{lossDivision === 'Loss' && lossCount}</span>
    )
  },
  {
    name: 'dispose',
    label: `${t('dispose')} ${t('quantity')}`,
    minWidth: 100,
    align: 'center',
    render: ({ lossCount, lossDivision }) => (
      <span>{lossDivision === 'Dispose' && lossCount}</span>
    )
  },
  {
    name: 'return',
    label: `${g('pages.minibarOrder.withdrawalAmount')}`,
    minWidth: 100,
    align: 'center',
    render: ({ lossCount, lossDivision }) => (
      <span>{lossDivision === 'Return' && lossCount}</span>
    )
  },
  {
    name: 'clientName',
    label: p('client'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'adminName',
    label: t('pic'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'managements',
    label: t('managements'),
    minWidth: 160,
    align: 'center'
  }
];

export const stockTakingStatus = [
  { value: '', label: t('total') },
  { value: 'N', label: t('unidentified') },
  { value: 'Y', label: t('confirmed') }
];

export const productSearchColumns = [
  {
    name: 'imgUrl',
    label: p('image'),
    minWidth: 70,
    align: 'center',
    render: ({ imgUrl }) => {
      return (
        <img
          src={`${env.s3BaseUrl}/${imgUrl}`}
          alt="product"
          style={productImgStyle}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = fallbackImg;
          }}
        />
      );
    }
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 130,
    align: 'center'
  },
  {
    name: 'cost',
    label: p('cost'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'masterPrice',
    type: 'text',
    label: p('master-price'),
    minWidth: 160,
    align: 'center'
  }
];

export const searchConditionsLoss = [
  { value: 'productName', label: p('product-name') },
  { value: 'barcode', label: p('bar-code') },
  { value: 'pic', label: t('pic') }
];

export const timeRadios = [
  { value: 'today', label: t('today') },
  { value: 'currentWeek', label: t('current-week') },
  { value: 'currentMonth', label: t('current-month') }
];

export const lossRadios = [
  { value: '', label: t('total') },
  { value: 'Loss', label: t('loss') },
  { value: 'Dispose', label: t('dispose') },
  { value: 'Return', label: g('pages.minibarOrder.withdrawal') }
];
