/* eslint-disable unused-imports/no-unused-vars */
import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchBar from '@components/SearchBar';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import DateRangePicker from '@components/DateRangePicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formatter } from '@utils/formatter';
import { dateFormat } from '@constants/utils';
import { sub } from 'date-fns';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import {
  compareDates,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import releaseService from '@services/release';

import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import PictureForm from './componenets/PictureForm';
import { columns, shippingStates, statusRadios } from './enhance';

const Release = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    minibarCode: null,
    clientCode: null,
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    picName: null,
    state: null,
    page: 0
  });
  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const dispatch = useDispatch();
  const [photoForm, setPhotoForm] = useState(null);
  const configColumns = columns;
  const [selected, setSelected] = useState(null);

  const { items, pagination } = state;

  const getReleaseList = async (param) => {
    const rs = await releaseService.getReleaseList({
      ...param,
      state: param?.state === 'total' ? null : param?.state
    });
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  const handleAction = async (action, item) => {
    if (action === 'start-ship') {
      const rs = await releaseService.updateShippingStatus(item?.shippingCode, {
        status: 'OnShipping'
      });
      getReleaseList(param);
      if (rs?.error) dispatch(setError(error2Text(rs?.error)));
    }
    if (action === 'end-ship') {
      const rs = await releaseService.updateShippingStatus(item?.shippingCode, {
        status: 'OnDisplay'
      });
      getReleaseList(param);
      if (rs?.error) dispatch(setError(error2Text(rs?.error)));
    }
    if (action === 'before') {
      const rs = await releaseService.getDetailShipping(item?.shippingCode);
      setSelected({
        ...selected,
        open: true,
        title: t('pages.inventory.photo-before-shipping'),
        action
      });
      setPhotoForm({ ...photoForm, ...rs?.data });
    }
    if (action === 'after') {
      const rs = await releaseService.getDetailShipping(item?.shippingCode);
      setSelected({
        ...selected,
        open: true,
        title: t('pages.inventory.photo-after-shipping'),
        action
      });
      setPhotoForm({ ...photoForm, ...rs?.data });
    }
    if (action === 'detail') {
      navigate('display', { state: { shippingCode: item?.shippingCode } });
    }
  };

  const getActionButtons = (item) =>
    Object.keys(shippingStates).includes(item?.shippingState) ? (
      <span style={{ color: item?.shippingState === 'Completed' && 'green' }}>
        {shippingStates[item?.shippingState]}
      </span>
    ) : (
      item?.shippingState
    );

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(
      ignoreEmpty({
        ...param,
        page: page + 1
      })
    );
    getReleaseList({
      ...param,
      state: param?.state === 'total' ? null : param?.state,
      page
    });
    navigate(query);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      clientCode,
      minibarCode,
      page,
      state,
      picName
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      clientCode: clientCode || null,
      minibarCode: minibarCode || null,
      page: page - 1 || 0,
      state: state || 'total',
      picName
    };
    setClient({ ...client, clientCode: clientCode || null });
    setMinibar({ ...minibar, minibarCode: minibarCode || null });
    setParam(dataParams);
    getReleaseList({
      ...dataParams,
      state: dataParams?.state === 'total' ? null : dataParams?.state
    });
  }, [location?.search]);

  const dialogOk = async () => {
    const dataSubmit = new FormData();
    const keys = Object.keys(photoForm);
    const values = Object.values(photoForm);
    keys.forEach((key, idx) => {
      dataSubmit.append(key, values[idx]);
    });
    let rs = null;
    if (selected?.action === 'before') {
      dataSubmit.append('isPictureBeforeShipping', true);
      rs = await releaseService.updateShippingSchedule(
        photoForm?.shippingCode,
        dataSubmit
      );
    }
    if (selected?.action === 'after') {
      dataSubmit.append('isPictureBeforeShipping', false);
      dataSubmit.append('managerCode', photoForm?.managerCode);
      dataSubmit.append('schDetailId', photoForm?.schDetailId);
      rs = await releaseService.updateShippingSchedule(
        photoForm?.shippingCode,
        dataSubmit
      );
    }
    getReleaseList({
      ...param,
      state: param?.state === 'total' ? null : param?.state
    });
    if (!rs?.error) {
      setSelected({ ...selected, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  const handleSearch = () => {
    const dataParam = {
      startDate: param?.startDate,
      endDate: param?.endDate,
      clientCode: client?.clientCode,
      minibarCode: minibar?.minibarCode,
      picName: param?.picName || null,
      state: param?.state === 'total' ? null : param?.state,
      page: 0
    };
    const query = objToParams(ignoreEmpty({ ...dataParam, page: 1 }));
    getReleaseList(dataParam);
    navigate(query);
  };
  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };
  return (
    <HelmetContainer title={t('pageTitle.release')} content="Release page">
      <SearchBar>
        <ClientAutoCompleteV2
          setClient={setClient}
          client={client}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={setMinibar}
          client={client}
          disabled={!client?.clientCode}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MyTextField
          label={t('pages.display.pic')}
          onChange={(e) => setParam({ ...param, picName: e.target.value })}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Stack flexDirection="row" alignItems="center">
          <FormControl>
            <FormControlLabel
              label={t('labels.period')}
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  whiteSpace: 'nowrap',
                  mr: 1
                }
              }}
              control={
                <DateRangePicker
                  state={{
                    startDate: new Date(param.startDate),
                    endDate: new Date(param.endDate),
                    key: 'selection'
                  }}
                  setState={handleDatetime}
                />
              }
            />
          </FormControl>
          <FormControlLabel
            label={t('pages.inventory.today')}
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap'
              },
              ml: 2
            }}
            control={
              <Radio
                size="small"
                checked={
                  !compareDates(param.startDate, param.endDate) &&
                  !compareDates(
                    param.startDate,
                    formatTime(new Date(), dateFormat)
                  )
                }
                onClick={() =>
                  setParam({
                    ...param,
                    startDate: formatTime(new Date(), dateFormat),
                    endDate: formatTime(new Date(), dateFormat)
                  })
                }
              />
            }
          />
        </Stack>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1.5
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {statusRadios.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({ ...param, state: e.target.value })
                        }
                        checked={
                          param?.state === null
                            ? item.value === 'total'
                            : param?.state === item.value
                        }
                        size="small"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: '30px' }}>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        rerender
        columns={configColumns}
        data={items}
        pagination={pagination}
        getActionButtons={getActionButtons}
        minusHeight={290}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => dialogOk()
            })
          );
        }}
        disabled={!photoForm?.latitude && !photoForm?.longitude}
      >
        <PictureForm
          value={photoForm}
          setValue={setPhotoForm}
          action={selected?.action}
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default Release;
