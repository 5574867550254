import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';
import Calendar from '@components/Calendar';

import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import welfarePointsServices from '@services/welfarePoints';

import { isEmail } from '@utils/boolean';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';

import { tableBodyStyle } from '../enhance';

const maxLengthOf = {
  name: 20,
  email: 100
};

const UpdateMemberDialog = ({
  open,
  setOpen,
  initData,
  setSuccess,
  clientCode
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    gender: 'Male',
    dob: new Date()
  });

  const [errMsg, setErrMsg] = useState({
    name: '',
    email: '',
    dob: ''
  });

  const genderSelections = [
    { label: t('pages.welfare-points.male'), value: 'Male' },
    { label: t('pages.welfare-points.female'), value: 'Female' }
  ];

  const validateEmail = () => {
    let msg = '';
    if (!data.email) {
      msg = t('common.required');
    } else if (!isEmail(data.email)) {
      msg = t('validation.invalidEmailAddress');
    }
    setErrMsg({
      ...errMsg,
      email: msg
    });
  };

  const onChange = ({ name, value }) => {
    setData({ ...data, [name]: value });
    let msg = '';
    if (!value) {
      msg = t('common.required');
    } else if (maxLengthOf[name] && value.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({
      ...errMsg,
      [name]: msg
    });
  };

  const validateOnSubmit = () => {
    const tempErr = { ...errMsg };
    let isPassed = true;
    if (!isEmail(data.email)) {
      tempErr.email = t('validation.invalidEmailAddress');
      isPassed = false;
    }
    // eslint-disable-next-line	no-restricted-syntax
    for (const property in errMsg) {
      if (!data[property]) {
        tempErr[property] = t('common.required');
        isPassed = false;
      } else if (data[property].length > maxLengthOf[property]) {
        tempErr[property] = t('validation.dataTooLong', {
          max: maxLengthOf[property]
        });
        isPassed = false;
      }
    }

    setErrMsg(tempErr);
    return isPassed;
  };

  const handleUpdateWelfarePointMember = async () => {
    setSuccess(false);
    if (!clientCode) {
      dispatch(setError({ title: 'Please select client' }));
      return;
    }

    setLoading(true);
    const result = await welfarePointsServices.updateMember(
      initData.memberCode,
      {
        name: data.name,
        email: data.email,
        sex: data.gender,
        age: data.dob ? moment(data.dob).format('YYYYMMDD') : null,
        clientCode
      }
    );
    setLoading(false);
    if (result?.error) {
      dispatch(setError(error2Text(result.error)));
    } else {
      setSuccess(true);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (initData) {
      const age = Formatter.fDateStringWithoutDelimiter(initData.age);
      setData({
        name: initData.name,
        phone: initData.phone,
        email: initData.email,
        gender: initData.sex,
        dob: initData.age ? new Date(age) : null
      });
    }
  }, []);
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.welfare-points.wp-member-edit')}
      okTitle={t('button.check')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onOk={() => {
        if (validateOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleUpdateWelfarePointMember()
            })
          );
        }
      }}
      sx={{
        '.MuiDialogContent-root': {
          border: '1px solid #D3D3D3',
          borderRadius: '8px'
        }
      }}
      loading={loading}
      setLoading={setLoading}
    >
      <TableContainer>
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.name')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="name"
                  value={data.name}
                  onChange={(e) => onChange(e.target)}
                  errMg={errMsg.name}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.phone-number')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="phone"
                  value={Formatter.fKrPhone(data.phone)}
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.email')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="email"
                  value={data.email}
                  onChange={(e) => onChange(e.target)}
                  onBlur={validateEmail}
                  errMg={errMsg.email}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.gender')}
              </TableCell>
              <TableCell>
                <MySelect
                  name="gender"
                  value={data.gender}
                  data={genderSelections}
                  onChange={(e) => onChange(e.target)}
                  fullWidth
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.date-of-birth')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <Calendar
                  name="dob"
                  date={data.dob}
                  setDate={(e) => {
                    setData({ ...data, dob: e });
                    setErrMsg({
                      ...errMsg,
                      dob: e ? '' : t('common.required')
                    });
                  }}
                  sx={{ inputStyle: { width: 160 } }}
                  errMsg={errMsg.dob}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default UpdateMemberDialog;
