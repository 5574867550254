import RestService from '@services/RestService';

const performanceService = {
  async getPerformanceGoal(params) {
    try {
      const response = await new RestService()
        .setPath(`/performance`)
        .setConfig({ params })
        .get();
      return { data: response?.data, error: null };
    } catch (err) {
      return { data: null, error: err?.response?.data || err };
    }
  },
  async getPerformance(performanceCode) {
    try {
      const res = await new RestService()
        .setPath(`/performance/${performanceCode}`)
        .get();
      return {
        data: res?.data,
        error: res?.null
      };
    } catch (err) {
      return {
        data: null,
        error: err?.data || err
      };
    }
  },
  async updatePerformance(performanceCode, updateData) {
    try {
      const res = await new RestService()
        .setPath(`/performance/${performanceCode}`)
        .put(updateData);
      return {
        data: res?.data,
        error: res?.null
      };
    } catch (err) {
      return {
        data: null,
        error: err?.data || err
      };
    }
  }
};

export default performanceService;
