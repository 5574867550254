import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Stack } from '@mui/material';

import { PRIVATE_CODE } from '@constants/utils';
import { errorHistoryConfigColumns } from '@pages/Manager/kiosk/enhance';
import MyDialog from '@components/MyDialog';
import MyTable from '@components/MyTable';
import kioskServices from '@services/kiosk';
import { onOpenConfirm } from '@store/reducers/appReducer';
import ErrorHistoryForm from './ErrorHistoryForm';

export default function HistoryForm({ kioskCode, onClose }) {
  const [items, setItems] = useState([]);
  const [editItem, setEditItem] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(undefined);

  const [numberError, setNumberError] = useState({
    untreated: 0,
    complete: 0
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actionComponent = (errorCode = '', repairYn = false) => {
    return repairYn ? (
      t('pages.kiosk.labels.processComplete')
    ) : (
      <Button
        variant="contained"
        component="label"
        sx={{
          height: '36px',
          p: '14px 15px',
          marginRight: 2
        }}
        onClick={() => setEditItem(errorCode)}
      >
        {t('button.process')}
      </Button>
    );
  };

  const getErrorHistory = async () => {
    const result = await kioskServices.getListErrorHistory(kioskCode);
    if (!result?.error) {
      setItems(result.data?.data || []);
    }
  };

  const onCloseEdit = () => {
    setIsSubmitting(false);
    setEditItem(undefined);
    getErrorHistory();
  };

  const handleSubmitError = async (e) => {
    setIsSubmitting(true);
    const result =
      editItem != null
        ? await kioskServices.processErrorHistoryOfKiosk(kioskCode, {
            kioskCode,
            errorCode: editItem,
            repairContent: e || ''
          })
        : await kioskServices.createErrorHistoryOfKiosk(kioskCode, {
            kioskCode,
            errorContent: e || ''
          });
    if (!result.error) {
      onCloseEdit();
    } else {
      setNotifyDialog({
        title: t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain'),
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (kioskCode?.startsWith(PRIVATE_CODE.KIOSK)) {
      getErrorHistory();
    }
  }, [kioskCode]);

  useEffect(() => {
    const complete = items.filter((i) => i.repairYn === 'Y').length;
    const untreated = items.length - complete;

    setNumberError({ untreated, complete });
  }, [items]);
  return (
    <MyDialog
      open={kioskCode !== undefined}
      setOpen={onClose}
      title={t('pages.kiosk.dialog.title.errorHistory', {
        untreated: numberError.untreated,
        processingComplete: numberError.complete
      })}
      hideDialogButton
      hasCloseButton
      sx={{
        '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
      }}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          p: 0,
          width: '100%',
          maxHeight: '80vh'
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Button variant="contained" onClick={() => setEditItem(null)}>
            {t('button.register')}
          </Button>
        </Stack>
        <MyTable
          hover
          rerender
          columns={errorHistoryConfigColumns(actionComponent, t)}
          data={items}
        />
        <MyDialog
          open={editItem !== undefined}
          setOpen={!isSubmitting ? onCloseEdit : () => {}}
          title={t(
            `pages.kiosk.dialog.title.${
              editItem == null ? 'receiveErrorHistory' : 'processErrorHistory'
            }`
          )}
          hideDialogButton
          hasCloseButton={!isSubmitting}
          sx={{
            '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
          }}
        >
          <ErrorHistoryForm
            item={editItem}
            onClose={onCloseEdit}
            onSubmit={(data) =>
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'save',
                  action: () => handleSubmitError(data)
                })
              )
            }
            cancelButtonProps={{
              disabled: isSubmitting,
              text: t('button.cancel')
            }}
            submitButtonProps={{
              loading: isSubmitting,
              text: editItem == null ? t('button.register') : t('button.check')
            }}
            hasCancelButton
            hasCloseButton
          />
        </MyDialog>
        <MyDialog
          open={notifyDialog !== undefined}
          isAlert
          setOpen={notifyDialog?.setOpen}
          onOk={notifyDialog?.onOk}
          hasCancelButton
          hasCloseButton
          title={notifyDialog?.title || ''}
        />
      </Stack>
    </MyDialog>
  );
}
