/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import NoData from '@components/NoData';

import { setError } from '@store/reducers/appReducer';
import minibarServices from '@services/minibar';

import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';

import {
  equipmentDialogColumns,
  equipmentListTableBodyStyle,
  equipmentListTableHeaderStyle
} from './enhance';

const EquipmentTableRow = ({ item, handleChange }) => {
  const [checked, setChecked] = useState(Boolean(item?.isCheck));
  const [quantity, setQuantity] = useState(item?.registeredEquipmentQuantity);

  const handleChangeQuantity = (e, item) => {
    const value = Formatter.fStringToRangeNumber(
      e.target.value,
      item?.remainingQuantity
    );
    setQuantity(value);
  };

  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    if (isChecked) setQuantity(1);
    else setQuantity(0);
  };

  useEffect(() => {
    handleChange(parseInt(quantity, 10), item);
  }, [quantity]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {item.equipmentName}
      </TableCell>
      <TableCell align="center">
        <Checkbox checked={checked} onChange={handleCheck} />
      </TableCell>
      <TableCell align="center">
        <TextField
          size="small"
          value={item.registeredEquipmentQuantity || ''}
          onChange={(e) => handleChangeQuantity(e, item)}
          disabled={!checked}
        />
      </TableCell>
      <TableCell align="center">
        {new Intl.NumberFormat('en-CA').format(
          item?.equipmentPrice && item?.registeredEquipmentQuantity
            ? item.equipmentPrice * item.registeredEquipmentQuantity
            : 0
        )}
      </TableCell>
      <TableCell align="center">{item?.remainingQuantity}</TableCell>
    </TableRow>
  );
};

const EditListEquipmentDialog = ({ open, setOpen, data, minibarCode }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [equipmentList, setEquipmentList] = useState();

  const handleChange = (quantity, item) => {
    const foundIndex = equipmentList.findIndex((x) => x.id === item.id);
    if (foundIndex !== -1) {
      const newData = [...equipmentList];
      newData[foundIndex] = {
        ...newData[foundIndex],
        registeredEquipmentQuantity: quantity || 0,
        sumAmount: quantity * newData[foundIndex].equipmentPrice || 0
      };
      setEquipmentList(newData);
    }
  };

  const handleUpdateEquipmentList = async () => {
    const payload = {
      minibarCode,
      listEquipment: equipmentList
        .map((equipment) => ({
          equipmentCode: equipment.equipmentCode,
          equipmentQuantity: equipment.registeredEquipmentQuantity,
          equipmentPrice: equipment.equipmentPrice,
          sumAmount: equipment.sumAmount
        }))
        .filter((equipment) => equipment.equipmentQuantity)
    };
    setLoading(true);
    const result = await minibarServices.updateListEquipmentByMinibar(
      minibarCode,
      payload
    );
    setLoading(false);
    if (result?.error) dispatch(setError(error2Text(result.error)));
    else {
      setOpen(false);
      setEquipmentList();
    }
  };

  useEffect(() => {
    const timemoutId = setTimeout(() => setEquipmentList(data), [300]);
    return () => clearTimeout(timemoutId);
  }, [data]);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.minibar.equipment-edit')}
      hasCloseButton
      hasCancelButton
      onOk={handleUpdateEquipmentList}
      loading={loading}
      sx={{
        maxWidth: 600,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableHead sx={equipmentListTableHeaderStyle}>
            <TableRow>
              {equipmentDialogColumns.map((col) => (
                <TableCell
                  key={col.name}
                  align={col.align}
                  sx={{ minWidth: col.minWidth }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={equipmentListTableBodyStyle}>
            {equipmentList ? (
              equipmentList.length > 0 ? (
                equipmentList.map((item) => (
                  <EquipmentTableRow
                    item={item}
                    key={item.id}
                    handleChange={handleChange}
                  />
                ))
              ) : (
                <TableRow sx={{ height: 200 }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '55%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}
                    component="td"
                  >
                    <NoData />
                  </Box>
                </TableRow>
              )
            ) : (
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 'calc(50% + 25px)',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                  component="td"
                >
                  loading...
                </Box>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default EditListEquipmentDialog;
