import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const productService = {
  async getProducts(params) {
    try {
      const res = await new RestService()
        .setPath('/product')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editProduct(productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product/${productCode}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getProductDetail(id) {
    try {
      const res = await new RestService().setPath(`/product/${id}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getNutritional(id) {
    try {
      const res = await new RestService()
        .setPath(`/product/${id}/nutritional`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateNutritional(productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product/${productCode}/nutritional`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateNotification(productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product/${productCode}/notice`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createProduct(data) {
    try {
      const res = await new RestService()
        .setPath('/product')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStatusCode(id) {
    try {
      const res = await new RestService()
        .setPath(`/product/${id}/minibar-status`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getVendors() {
    try {
      const res = await new RestService().setPath('/vendor').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createProductsByExcel(data) {
    try {
      const res = await new RestService()
        .setPath('/product/import')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportEntireProduct(params = {}) {
    try {
      const res = await new RestService()
        .setPath('product/export-entire-product')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportRaw(params) {
    try {
      const res = await new RestService()
        .setPath('/product/export-raw-data')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSample() {
    try {
      const res = await new RestService()
        .setPath('/product/export-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportPriceByMinibar(data) {
    try {
      const res = await new RestService()
        .setPath('/product/export-price')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // when add Add Product
  async getProductsByFilter(params) {
    try {
      const res = await new RestService()
        .setPath('product/by-filter')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default productService;
