/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAuthorization from '@hooks/useAuthorization';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack } from '@mui/material';

import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import Button2in1 from '@components/Button/Button2in1';
import MyTextField from '@components/MyTextField';
import MyAutocomplete from '@components/MyAutocomplete';
import HelmetContainer from '@components/HelmetContainer';
import MyLoadingButton from '@components/MyLoadingButton';

import { scopes } from '@constants/utils';
import error2Text from '@utils/error2Text';
import { formatMoney } from '@utils/helpers';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import locationService from '@services/location';
import warehouseService from '@services/warehouse';
import LocationForm from './components/LocationForm';
import { unregisteredProductColumns } from './enhance';

const UnregisteredProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [warehouseList, setWarehouseList] = useState([]);
  const [items, setItems] = useState([]);
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);

  const getUnregisteredList = async () => {
    const rs = await warehouseService.getStockingList({ isStocking: 'N' });
    if (!rs.error) {
      setItems(rs?.data?.items || []);
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  const getCenters = async () => {
    const rs = await warehouseService.getCenter();
    if (!rs?.error) {
      setWarehouseList(rs?.data?.body);
    }
  };

  useEffect(() => {
    getCenters();
    getUnregisteredList();
  }, []);

  const updateQuantity = async (item) => {
    const rs = await warehouseService.updateQuantity(item?.id, {
      quantity: item?.value || 0,
      isStocking: 'N'
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getUnregisteredList();
      setSelectedQuantity(null);
    }
  };
  const handleSelectWarehouse = (val, item) => {
    const idxFilter = items?.findIndex((i) => i?.id === item?.id);
    setItems((oldItem) => [
      ...oldItem.slice(0, idxFilter),
      {
        ...oldItem.slice(idxFilter, idxFilter + 1)?.[0],
        centerName: val?.centerName,
        centerCode: val?.centerCode
      },
      ...oldItem.slice(idxFilter + 1)
    ]);
  };
  const handleQuantityNotYet = (val) => {
    setItems((oldItem) => [
      ...oldItem.slice(0, items.length - 1),
      {
        ...oldItem.slice(items.length - 1)?.[0],
        quantity: +val
      }
    ]);
  };
  const handleClickTableCell = async (action, item) => {
    if (action === 'product-modify' || action === 'product-register') {
      localStorage.setItem('wearingId', item.id);
      navigate(
        `/inventory-management/warehouse/${action}${
          action === 'product-modify' ? `/${item.productCode}` : ``
        }`,
        { state: action === 'product-modify' ? {} : { itemObj: item } }
      );
    }
    if (action === 'locationRegister') {
      setSelected({
        ...selected,
        title: `${t('pageTitle.location')} ${t('button.register')}`,
        open: true,
        action
      });
      setSelectedItem({ productCode: item?.productCode, wearingId: item?.id });
    }
    if (action === 'locationModify') {
      const rs = await locationService.getLocationrDetail(item?.locationCode);
      if (!rs?.error) {
        setSelected({
          ...selected,
          title: `${t('pageTitle.location')} ${t('button.modify')}`,
          open: true,
          action
        });
        setSelectedItem({ ...rs?.data?.data, wearingId: item?.id });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };
  const warehouseComponent = (item) => (
    <MyAutocomplete
      sx={{ width: 150 }}
      name="warehouse"
      value={item}
      isOptionEqualToValue={(option, value) =>
        option.centerName === value?.centerName
      }
      getOptionLabel={(option) => option?.centerName || ''}
      onChange={(e, val) => handleSelectWarehouse(val, item)}
      options={warehouseList}
    />
  );
  const stockQuantityComponent = (item) => {
    return (
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <MyTextField
          typeValue="onlyNumber"
          value={
            selectedQuantity?.id && selectedQuantity?.id === item?.id
              ? formatMoney(selectedQuantity?.value)
              : formatMoney(item?.quantity)
          }
          onChange={(e) =>
            item?.id
              ? setSelectedQuantity({
                  ...selectedQuantity,
                  value: +e?.target?.value?.replace(/,/g, '')
                })
              : handleQuantityNotYet(+e?.target?.value?.replace(/,/g, ''))
          }
          onFocus={() =>
            setSelectedQuantity({ ...item, value: item?.quantity })
          }
        />
        {item?.id && selectedQuantity && selectedQuantity?.id === item?.id && (
          <Button2in1
            onOk={() => updateQuantity(selectedQuantity)}
            onCancel={() => {
              setSelectedQuantity(null);
            }}
          />
        )}
      </Stack>
    );
  };
  const componentRender = {
    warehouseComponent,
    stockQuantityComponent
  };
  const configColumns = unregisteredProductColumns(
    handleClickTableCell,
    componentRender
  );

  const getActionButtons = (item) => (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        setSelectedItem(item);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
  const { pagination } = state;

  const handleOkDialog = async () => {
    const rs = await locationService.editLocation(
      selectedItem?.locationCode,
      selectedItem
    );
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getUnregisteredList();
      setSelected({ ...selected, open: false });
    }
  };

  const deleteStocking = async () => {
    const rs = await warehouseService.deleteStocking(selectedItem?.id, {
      isStocking: 'N'
    });
    if (rs?.error) {
      setSelected({ ...selected, open: false });
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getUnregisteredList();
      setOpenConfirmDialog(false);
    }
  };
  const handleRegisterStocking = async () => {
    const dataSubmit = {
      isStocking: 'N',
      listStockingProductId: items?.reduce((rs, i) => {
        return rs.concat([i.id]);
      }, [])
    };
    const rs = await warehouseService.createStockingRegister(dataSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getUnregisteredList();
      dispatch(
        setError({
          title: `${t('pages.inventory.product')} ${t(
            'pageTitle.stocking'
          )} ${t('pages.inventory.register')} ${t('info.serverInfo.title')}`
        })
      );
    }
  };

  return (
    <HelmetContainer
      title={t('pageTitle.unregistered-product')}
      content="Unregistered Product Page"
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => navigate('/inventory-management/warehouse')}
        >
          {t('pages.inventory.list')}
        </Button>
      </Stack>
      <MyTable
        hover
        rerender
        columns={configColumns}
        data={items}
        getActionButtons={getActionButtons}
        minusHeight={150}
      />
      <Stack flexDirection="row" justifyContent="center" mt={2}>
        <MyLoadingButton
          variant="contained"
          onClick={() => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'save',
                action: () => handleRegisterStocking()
              })
            );
          }}
          disabled={!isAuthorized || items.length === 0}
        >
          {`${t('pages.inventory.product')} ${t('pageTitle.stocking')} ${t(
            'pages.inventory.register'
          )}`}
        </MyLoadingButton>
      </Stack>
      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        isAlert
        title={t('dialog.title.areYouSureDeleteThisRecord')}
        hasCancelButton
        onOk={deleteStocking}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        hasCancelButton={selected?.action !== 'productSearch'}
        hasCloseButton
        hideDialogButton={selected?.action === 'productSearch'}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleOkDialog()
            })
          );
        }}
      >
        <LocationForm value={selectedItem} setValue={setSelectedItem} />
      </MyDialog>
    </HelmetContainer>
  );
};

export default UnregisteredProduct;
