import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, Slide, Bounce } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// import i18n (needs to be bundled ;))

import {
  Backdrop,
  CircularProgress,
  DialogContentText,
  Stack,
  Typography
} from '@mui/material';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import './App.css';
import './index.css';
import './utils/i18n';

import MyDialog from '@components/MyDialog';
import AlertDialog from '@components/MyDialog/AlertDialog';
import MyLoadingButton from '@components/MyLoadingButton';
import {
  onOpenConfirm,
  setError,
  setOpenConfirm,
  setSuccess
} from '@store/reducers/appReducer';
import { delay } from '@utils/helpers';
import MySnackbar from '@components/MySnackbar';
import useResponsive from '@hooks/useResponsive';

const Globals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSm = useResponsive('down', 900);
  const { loading, error, info, onOk, confirmPopup, success, openConfirm } =
    useSelector((state) => state.app);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (error || info || success || openConfirm) setOpenDialog(true);
  }, [error, info, success, openConfirm]);

  return (
    <>
      <ToastContainer
        autoClose={isSm ? 1000 : 3000}
        draggable={false}
        position={isSm ? 'top-center' : 'top-right'}
        transition={isSm ? Slide : Bounce}
        hideProgressBar={isSm}
        newestOnTop
        closeOnClick
        rtl={false}
        theme={isSm ? 'dark' : 'light'}
        pauseOnHover
        closeButton={!isSm}
        bodyClassName={isSm && 'toast-body'}
      />

      {confirmPopup?.open && (
        <MyDialog
          sx={{ m: 0, p: '20px', minWidth: 300 }}
          onClose={() =>
            dispatch(onOpenConfirm({ open: false, type: null, action: null }))
          }
          hideDialogButton
          open={confirmPopup?.open}
          title={
            // eslint-disable-next-line no-nested-ternary
            confirmPopup?.title
              ? confirmPopup?.title
              : confirmPopup?.type === 'save'
              ? t('button.save')
              : t('button.modify')
          }
        >
          <Stack
            pb={3}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography letterSpacing="0.5px" whiteSpace="pre-line">
              {confirmPopup?.msg ||
                (confirmPopup?.type === 'save'
                  ? t('notify.u-save')
                  : t('notify.u-modify'))}
            </Typography>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={2}
            mb={2}
          >
            <MyLoadingButton
              variant="contained"
              onClick={async () => {
                await confirmPopup.action();
                await delay(250);
                dispatch(
                  onOpenConfirm({ open: false, action: null, type: null })
                );
              }}
            >
              {t('common.yes')}
            </MyLoadingButton>
            <MyLoadingButton
              variant="outlined"
              onClick={() =>
                dispatch(
                  onOpenConfirm({ open: false, type: null, action: null })
                )
              }
            >
              {t('common.no')}
            </MyLoadingButton>
          </Stack>
        </MyDialog>
      )}
      {openConfirm?.open && (
        <AlertDialog
          open={openConfirm.open}
          setOpen={() =>
            dispatch(
              setOpenConfirm({
                action: null,
                open: false,
                type: null,
                msg: null
              })
            )
          }
          onCancel={() =>
            dispatch(
              setOpenConfirm({
                action: null,
                open: false,
                type: null,
                msg: null
              })
            )
          }
          onOk={openConfirm.action}
          okTitle={openConfirm.okTitle}
          isAlert
          title={openConfirm?.title || t('common.confirm-delete-new-title')}
          subTitle={openConfirm?.msg || t('common.confirm-delete-new-subtitle')}
          type={openConfirm?.type}
          hasCancelButton
        />
      )}
      {info && (
        <MyDialog
          open={openDialog}
          setOpen={() => {
            setOpenDialog(false);
            onOk();
          }}
          isAlert
          {...info}
        >
          {info?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {info?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      {error && (
        <MyDialog
          open={openDialog}
          setOpen={() => dispatch(setError(null))}
          isAlert
          sx={{ width: 'auto', height: 'auto' }}
          {...error}
        >
          {error?.content && (
            <DialogContentText
              sx={{
                fontSize: 18,
                color: '#000000',
                textAlign: 'center',

                height: 'auto',
                whiteSpace: 'pre-line'
              }}
            >
              {error?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      {success && (
        <MySnackbar
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            dispatch(setSuccess(null));
          }}
          message={success?.message}
        />
      )}
      <Backdrop
        sx={{
          color: 'common.white',
          zIndex: (theme) => theme.zIndex.modal + 1
        }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CircularProgress
        sx={{
          display: loading ? 'block' : 'none',
          position: 'fixed',
          bottom: '10px',
          right: '15px',
          zIndex: 9999
        }}
      />
    </>
  );
};

export default Globals;
