import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { scopes } from '@constants/utils';

const useAuthorization = (type) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);

    if (token) {
      switch (type) {
        case scopes.SUPER:
          setIsAuthorized(decodedToken?.scope?.includes(scopes.SUPER));
          break;
        case scopes.ADMIN_INVENTORY_MASTER:
          setIsAuthorized(
            decodedToken?.scope?.includes(scopes.SUPER) ||
              decodedToken?.scope?.includes(scopes.ADMIN_INVENTORY_MASTER)
          );
          break;
        case scopes.ADMIN_PRODUCT_MASTER:
          setIsAuthorized(
            decodedToken?.scope?.includes(scopes.SUPER) ||
              decodedToken?.scope?.includes(scopes.ADMIN_PRODUCT_MASTER)
          );
          break;
        default:
          setIsAuthorized(false);
      }
    }
  }, []);

  return isAuthorized;
};

export default useAuthorization;
