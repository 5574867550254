import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TableCell,
  TableRow
} from '@mui/material';
import { toast } from 'react-toastify';
import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import LabelBox from '@components/LabelBox';
import CustomImage from '@components/CustomImage';
import NoData from '@components/NoData';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import MySelect from '@components/MySelect';
import { productServices } from '@services/index';
import minibarOrderService from '@services/minibarOrderService';
import { MAX_INT_VALUE } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import { setError } from '@store/reducers/appReducer';

import { addProductColumns as columns } from '../../enhance';

import { productFilterConditionList } from '../enhance';

const formatAmount = (value) => new Intl.NumberFormat('en-CA').format(value);
const formatCurrency = (value) => new Intl.NumberFormat('en-CA').format(value);

const AddProductTableRow = ({ item, selectedItem, onCheckBoxClick }) => {
  return (
    <TableRow>
      <TableCell>
        <Checkbox
          onClick={() => onCheckBoxClick(item)}
          checked={selectedItem?.productCode === item.productCode}
        />
      </TableCell>

      <TableCell>
        <CustomImage fileName={item.imgUrl} fileLink={item.imgUrl} />
      </TableCell>
      <TableCell>{item?.productName}</TableCell>
      <TableCell>{item?.barcode}</TableCell>
      <TableCell>
        {item?.stockQuantity && formatAmount(item.stockQuantity)}
      </TableCell>
      <TableCell>{item?.cost ? formatCurrency(item.cost) : null}</TableCell>

      <TableCell>
        {item?.masterPrice ? formatCurrency(item.masterPrice) : null}
      </TableCell>
    </TableRow>
  );
};

const AddProductDialog = ({ open, setOpen, minibarCode, onAction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState('0');
  const [properQuantity, setProperQuantity] = useState('0');
  const [condition, setCondition] = useState('productName');

  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [endMore, setEndMore] = useState(false);
  const [params, setParams] = useState({
    productName: '',
    barcode: ''
  });

  const getData = async (param) => {
    const result = await productServices.getProductsByFilter(param);
    setData(result.data?.items || []);
  };

  const fetchMoreAPI = async () => {
    if (data?.length < 200 && !endMore) {
      setHasMore(true);
      const res = await productServices.getProductsByFilter({
        productName: params?.productName || null,
        barcode: params?.barcode || null,
        page: page + 1,
        size: 20
      });
      setPage(page + 1);
      setEndMore(!res?.data?.items?.length);
      if (!res.data || res?.data?.items?.length === 0) {
        setHasMore(false);
      } else {
        setData(data.concat(res?.data?.items));
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  };

  const handleSearch = () => {
    setData([]);
    setSelectedItem();
    setQuantity('0');
    setProperQuantity('0');
    getData({
      productName: params?.productName || null,
      barcode: params?.barcode || null,
      page: 0,
      size: 20
    });
    setEndMore(false);
    setPage(0);
  };

  const handleCheckBoxClick = (item) => {
    if (item.productCode === selectedItem?.productCode) setSelectedItem();
    else setSelectedItem(item);
  };

  const handleRegister = async () => {
    setLoading(true);
    const result = await minibarOrderService.addMinibarProduct(minibarCode, {
      productCode: selectedItem?.productCode,
      quantity: parseInt(quantity, 10),
      properQuantity: parseInt(properQuantity, 10)
    });
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setOpen(false);
      toast.success(t('pages.minibarOrder.messages.success.addProduct'), {
        autoClose: 2000
      });
      onAction('upload-success');
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCancelButton
      hasCloseButton
      okTitle={t('button.register')}
      title={t('pages.curation.add-product')}
      onOk={handleRegister}
      sx={{
        maxWidth: 600,
        '.MuiDialogContent-root': {
          overflow: 'overlay'
        }
      }}
      loading={loading}
      disabled={!selectedItem || properQuantity === '0'}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ p: '15px 20px', bgcolor: '#F8F8F8', borderRadius: '12px' }}
      >
        <MySelect
          label={t('pages.product.condition')}
          data={productFilterConditionList?.slice(0, 2)}
          defaultValue="productName"
          value={condition}
          onChange={(e) => {
            setCondition(e?.target?.value);
            setParams({ ...params, productName: '', barcode: '' });
          }}
        />
        <MyTextField
          value={params?.[condition]}
          onChange={(e) =>
            setParams({ ...params, [condition]: e?.target?.value })
          }
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          disabled={!params?.productName && !params?.barcode}
          variant="contained"
          sx={{ height: 36 }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </Stack>
      <ZombieTable
        columns={columns}
        sx={{ my: 1.875, maxHeight: 410 }}
        position="relative"
        stickyHeader
        fetchMore={fetchMoreAPI}
      >
        <CustomTableBody>
          {data?.length > 0 ? (
            <>
              {data.map((item) => (
                <AddProductTableRow
                  key={item.productCode}
                  item={item}
                  selectedItem={selectedItem}
                  onCheckBoxClick={handleCheckBoxClick}
                />
              ))}
              {hasMore && (
                <TableRow>
                  <TableCell
                    sx={{
                      height: '30px !important',
                      p: '10px 0 20px 0 !important'
                    }}
                    colSpan={5}
                  >
                    <i>로딩... {'  '}</i>
                    <CircularProgress size={12} color="primary" />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow sx={{ height: 200 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                <NoData />
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
      <Stack
        direction="row"
        alignItems="space between"
        spacing={1}
        sx={{
          overflow: 'hidden'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            border: '1px solid #d3d3d3',
            borderRadius: 1,
            overflow: 'hidden',
            pr: 3
          }}
        >
          <LabelBox title={t('pages.curation.quantity')} />
          <MyTextField
            sx={{ width: '140px' }}
            value={quantity}
            onChange={(e) => {
              const formattedValue = Formatter.fStringToRangeNumber(
                e.target.value,
                MAX_INT_VALUE
              );
              setQuantity(formattedValue === '0' ? '0' : formattedValue);
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            border: '1px solid #d3d3d3',
            borderRadius: 1,
            overflow: 'hidden',
            pr: 3
          }}
        >
          <LabelBox title={t('pages.product.proper-stock')} />
          <MyTextField
            value={properQuantity}
            sx={{ width: '140px' }}
            onChange={(e) =>
              setProperQuantity(
                formatAmount(
                  Formatter.fStringToRangeNumber(e.target.value, MAX_INT_VALUE)
                )
              )
            }
          />
        </Stack>
      </Stack>
    </MyDialog>
  );
};

export default AddProductDialog;
