/* eslint-disable no-unused-expressions */
import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  styled
} from '@mui/material';
// import MyDialog from '@components/MyDialog';
import MyTable from '@components/MyTable';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MyDialog from '@components/MyDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import pickingService from '@services/picking';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { nanoid } from 'nanoid';

import { PICKING_STATUSES } from '@constants/utils';
import {
  IndiPickingDetailHeader,
  indiPickingStats,
  pickingTypeDetailColumns,
  totalPickingDetailHeader,
  totalPickingStats
} from '../enhance';
import EditTotalPickingList from './EditTotalPickingList';
import FixDialog from './FixDialog';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const PickingDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formatTime } = Formatter;
  const configColumns = pickingTypeDetailColumns;
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameArr = location?.pathname?.split('/');
  const pickingCode = pathnameArr[pathnameArr.length - 1];
  const type = pathnameArr[pathnameArr.length - 2];

  const [state, setState] = useState({
    info: {},
    items: []
  });
  const disabled = state?.info?.status !== PICKING_STATUSES.REQUESTED;
  const [param, setParam] = useState({
    status: 'StandBy',
    page: 0
  });

  const headerRows =
    type === 'total' ? totalPickingDetailHeader : IndiPickingDetailHeader;
  const statRows = type === 'total' ? totalPickingStats : indiPickingStats;
  const [selectedItem, setSelectedItem] = useState(null);

  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    type: '',
    action: ''
  });

  const handleOpenReason = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setDialog({ ...dialog, open: true, action: 'fix' });
  };

  const getTotalPickingDetail = async (code, params) => {
    const rs = await pickingService.getTotalPickingDetail(code, params);
    if (!rs?.error) {
      const { listPickingItems, ...rest } = rs.data.data;
      setState({
        ...state,
        items: listPickingItems?.items,
        info: rest,
        pagination: {
          ...state?.pagination,
          page: listPickingItems?.page || 0,
          totalRow: listPickingItems?.totalRow || 0,
          count: listPickingItems?.totalPage || 0
        }
      });
    }
  };

  const getIndividualPickingDetail = async (code, params) => {
    const rs = await pickingService.getIndividualPickingDetail(code, params);
    if (!rs?.error) {
      const { listPickingItems, ...rest } = rs.data.data;
      setState({
        ...state,
        items: listPickingItems?.items,
        info: rest,
        pagination: {
          ...state?.pagination,
          page: listPickingItems?.page || 0,
          totalRow: listPickingItems?.totalRow || 0,
          count: listPickingItems?.totalPage || 0
        }
      });
    } else {
      setState({
        ...state,
        items: []
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { page } = query;
    const dataParams = {
      page: page - 1 || 0
    };
    setParam(dataParams);

    type === 'total'
      ? getTotalPickingDetail(pickingCode, dataParams)
      : getIndividualPickingDetail(pickingCode, dataParams);
  }, [location?.search]);

  const [loading, setLoading] = useState(false);
  const exportPickingTotalProducts = async () => {
    setLoading(true);
    const rs = await pickingService.exportTotalPickingProducts(pickingCode);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    type === 'total'
      ? getTotalPickingDetail(pickingCode, { ...param, page })
      : getIndividualPickingDetail(pickingCode, { ...param, page });
    navigate(query);
  };
  // eslint-disable-next-line consistent-return
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        console.log('complete', item?.completeYn);
        if (item?.hasShortageNote === 'Y') {
          return (
            <Button
              variant={item?.completeYn === 'Y' ? 'contained' : 'outlined'}
              width="auto"
              onClick={(e) => handleOpenReason(e, item)}
            >
              {t('pages.picking.reason')}
            </Button>
          );
        }
        break;
      case 2:
        return (
          <IconButton>
            <DeleteIcon />
          </IconButton>
        );
      case 6:
        if (item?.hasShortageNote === 'Y') {
          return (
            <Button
              variant={item?.completeYn === 'Y' ? 'contained' : 'outlined'}
              width="auto"
              onClick={(e) => handleOpenReason(e, item)}
            >
              {t('pages.picking.fix')}
            </Button>
          );
        }
        break;

      default:
        break;
    }
  };

  const handleShipping = async () => {
    let rs;
    // validation for shipping

    switch (type) {
      case 'individual':
        if (!pickingCode) return;
        rs = await pickingService.createShippingForIndiPickings(pickingCode);
        break;
      case 'total':
        if (!pickingCode) return;
        rs = await pickingService.createShippingForTotalPickings(pickingCode);
        break;
      default:
    }
    if (!rs?.error) {
      dispatch(setSuccess({ message: t('info.register.content') }));
      setDialog({ ...dialog, open: false, action: '' });
      navigate('/inventory-management/picking');
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer
      title={
        type === 'total'
          ? t('pages.picking.totalDetail')
          : t('pages.picking.indiDetail')
      }
      content="Picking Detail page"
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: -1,
          mb: 1
        }}
      >
        <Stack>
          <IconButton
            onClick={() => navigate(`/inventory-management/picking/${type}`)}
            sx={{
              ':hover': { backgroundColor: 'transparent' },
              display: 'flex'
            }}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <ArrowBackIosNewIcon
              sx={{ height: '20px', color: 'black', fontWeight: 500 }}
            />
            <Typography sx={{ fontSize: 20, color: 'black', fontWeight: 500 }}>
              {type === 'total'
                ? t('pages.picking.totalList')
                : t('pages.picking.indiList')}
            </Typography>
          </IconButton>
        </Stack>

        <Stack>
          <Button
            variant="contained"
            width="auto"
            onClick={() => setDialog({ open: true, action: 'shipping' })}
            disabled={
              disabled ||
              !state?.items ||
              state?.info?.isValidToShipping === 'N'
            }
          >
            {t('pages.picking.shipping')}
          </Button>
        </Stack>
      </Stack>
      <Container component={BorderBox}>
        <Table sx={{ borderBottom: '0.5px solid #D3D3D3 !important' }}>
          <TableRow>
            {headerRows.map((row) => {
              return (
                <FieldContainer
                  key={nanoid()}
                  label={row?.label}
                  content={
                    row.name !== 'regDt'
                      ? state?.info?.[row?.name]
                      : formatTime(
                          state?.info?.pickedDt || state?.info?.regDt,
                          'YYYY-MM-DD hh:mm:ss'
                        )
                  }
                  // sx={{ borderLeft: index === 0 && 0 }}
                />
              );
            })}
          </TableRow>
        </Table>
        <Table sx={{ border: '0px !important' }}>
          <TableRow>
            {statRows.map((row) => {
              return (
                <FieldContainer
                  key={nanoid()}
                  label={row?.label}
                  content={
                    row.name !== 'regDt'
                      ? state?.info?.[row?.name] || 0
                      : formatTime(
                          state?.info?.pickedDt || state?.info?.regDt,
                          'YYYY-MM-DD hh:mm:ss'
                        )
                  }
                  // sx={{ borderLeft: index === 0 && 0 }}
                />
              );
            })}
          </TableRow>
        </Table>
      </Container>
      {type === 'total' && (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Button
            sx={{ mt: 2 }}
            onClick={() => exportPickingTotalProducts()}
            variant="outlined"
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>

          <Button
            sx={{ mt: 2 }}
            onClick={() =>
              setDialog({ ...dialog, open: true, action: 'editList' })
            }
            variant="outlined"
          >
            {`${t('pages.picking.totalList')} ${t('button.modify')}`}
          </Button>
        </Stack>
      )}

      <MyTable
        rerender
        columns={configColumns}
        data={state?.items}
        pagination={state?.pagination}
        getActionButtons={getActionButtons}
        onChangePage={(e, page) => handleChangePage(page)}
        minusHeight={285}
      />

      {/* dialog */}
      {dialog?.action === 'editList' && (
        <EditTotalPickingList
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          onAction={() => getTotalPickingDetail(pickingCode, param)}
          itemCode={pickingCode}
        />
      )}
      {dialog?.action === 'fix' && (
        <FixDialog
          open={dialog.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          pickingCode={pickingCode}
          pickingProductCode={selectedItem?.pickingProductCode}
          type={type}
          item={selectedItem}
          onSuccess={() => {
            type === 'total'
              ? getTotalPickingDetail(pickingCode, param)
              : getIndividualPickingDetail(pickingCode, param);
          }}
        />
      )}
      {dialog?.action === 'shipping' && (
        <MyDialog
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          isAlert
          title={t('pages.picking.shipping')}
          subTitle={t('pages.picking.pickingSave')}
          hasCancelButton
          onOk={handleShipping}
        />
      )}
    </HelmetContainer>
  );
};

const FieldContainer = ({ label, content, sx }) => {
  return (
    <TableCell sx={{ padding: 0, border: 0 }}>
      <Stack flexDirection="row" alignItems="center">
        <Stack
          color="#6F869C"
          bgcolor="#FCFCFC"
          p="12px 26px"
          borderLeft="1px solid #D3D3D3"
          sx={{
            width: {
              sm: 150,
              lg: 'auto',
              height: 50,
              flexDirection: 'row',
              alignItems: 'center'
            },
            ...sx
          }}
        >
          <Typography fontWeight={600} fontSize={13} textAlign="center">
            {label}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: {
              sm: 180,
              lg: 'auto'
            }
          }}
        >
          <Typography fontSize={13} p="0 12px" minWidth={100}>
            {content}
          </Typography>
        </Stack>
      </Stack>
    </TableCell>
  );
};

export default PickingDetailPage;
