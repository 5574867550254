import MyTextField from '@components/MyTextField';
import { Chip } from '@mui/material';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';
import { MINIBAR_OWNERSHIP_TYPES, tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';

const curationT = i18n.getFixedT(null, null, 'pages.curation');
const t = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');
export const radioOptions = (t) => [
  { value: 'Total', label: t('pages.inventory.total') },
  { value: 'Adequate', label: t('labels.proper') },
  { value: 'Insufficient', label: t('labels.insufficient') },
  { value: 'NeedReview', label: t('labels.check') }
];

const rerender = (item, value) => {
  return (
    <span
      style={{
        textDecoration: item.hasRollbacked ? 'line-through' : '',
        color: item.hasRollbacked && 'gray'
      }}
    >
      {value}
    </span>
  );
};
export const inventoryActionOptions = [
  { value: 'display', label: g('pages.minibarOrder.display') },
  { value: 'loss', label: g('pages.inventory.loss') },
  { value: 'recall', label: g('pages.minibarOrder.withdrawal') },
  { value: 'discard', label: g('common.DISCARD') }
];

export const inventoryActions = {
  DISPLAY: 'display',
  LOSS: 'loss',
  RECALL: 'recall',
  DISCARD: 'discard'
};
const minibarOwnershipStatusListObject = {
  [MINIBAR_OWNERSHIP_TYPES.CLIENT_OWNED]: g(
    'pages.minibar.ownershipTypeList.clientOwned'
  ),
  [MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED]: g(
    'pages.minibar.ownershipTypeList.companyOwned'
  )
};
const minibarActionsTypeMapper = {
  display: g('pages.minibarOrder.display'),
  loss: g('pages.inventory.loss'),
  discard: g('common.DISCARD'),
  recall: g('pages.minibarOrder.withdrawal')
};
export const minibarOwnershipStatusesList = [
  {
    value: MINIBAR_OWNERSHIP_TYPES.CLIENT_OWNED,
    label: g('pages.minibar.ownershipTypeList.clientOwned')
  },
  {
    value: MINIBAR_OWNERSHIP_TYPES.COMPANY_OWNED,
    label: g('pages.minibar.ownershipTypeList.companyOwned')
  }
];
export const minibarMultipleSelectColumns = [
  {
    label: g('common.select'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  },
  {
    name: 'minibarNickname',
    label: g('common.minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'ownershipType',
    label: g('pages.minibar.ownershipType'),
    minWidth: 100,
    align: 'center',
    render: ({ ownershipType }) =>
      ownershipType
        ? minibarOwnershipStatusListObject?.[ownershipType]
        : ownershipType
  }
];
export const columns = (t) => [
  {
    label: t('common.select'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  },
  {
    label: t('common.status'),
    minWidth: 100,
    align: 'center',
    render: ({ stockStatus }) => {
      return (
        <Chip
          label={
            (stockStatus === 'Insufficient' && t('labels.insufficient')) ||
            (stockStatus === 'Adequate' && t('labels.proper')) ||
            (stockStatus === 'NeedReview' && t('labels.check'))
          }
          color={
            (stockStatus === 'Insufficient' && 'error') ||
            (stockStatus === 'Adequate' && 'primary') ||
            (stockStatus === 'NeedReview' && 'warning') ||
            'secondary'
          }
        />
      );
    }
  },
  {
    name: 'minibarNickname',
    label: t('common.minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: t('pages.minibarOrder.barcode'),
    minWidth: 160,
    align: 'center',
    sortable: true
    // hasFilter: true
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    sortable: true,
    isPinned: true
    // hasFilter: true
  },
  {
    name: 'category1Name',
    label: curationT('1st-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: curationT('2nd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: curationT('3rd-cat'),
    minWidth: 120,
    align: 'center'
  },

  {
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: t('common.package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'salesQuantity',
    label: t('pages.product.sales-quantity'),
    minWidth: 100,
    align: 'center',
    render: ({ salesQuantity }) => formatMoney(salesQuantity)
  },
  {
    name: 'stockQuantity',
    label: t('pages.product.minibar-stock'),
    minWidth: 100,
    align: 'center',
    render: ({ stockQuantity }) => formatMoney(stockQuantity)
  },
  {
    name: 'properStock',
    label: t('pages.product.proper-stock'),
    minWidth: 100,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'properStock',
    render: ({ properStock }) => formatMoney(properStock)
  },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },
  {
    name: 'rateCompany',
    label: t('pages.minibarOrder.discountRate'),
    minWidth: 75,
    align: 'center'
  },
  {
    name: 'masterPrice',
    label: t('pages.product.master-price'),
    minWidth: 100,
    align: 'center',
    render: ({ masterPrice }) => formatMoney(masterPrice)
  },
  {
    name: 'branchPrice',
    label: t('common.branchPrice'),
    minWidth: 100,
    align: 'center',
    cathover: true,
    type: tableTypes.MODAL,
    actionType: 1
  },
  {
    label: t('pages.minibarOrder.issueYn'),
    minWidth: 50,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'issueYn'
  },
  {
    label: t('pages.minibarOrder.progressYn'),
    minWidth: 50,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'progressYn'
    // hasFilter: true,
    // isPinned: true,
  }
];

export const displayManagerViewColumns = (t) => [
  {
    label: t('common.status'),
    minWidth: 100,
    align: 'center',
    render: ({ stockStatus }) => {
      return (
        <Chip
          label={
            (stockStatus === 'Insufficient' && t('labels.insufficient')) ||
            (stockStatus === 'Adequate' && t('labels.proper')) ||
            (stockStatus === 'NeedReview' && t('labels.check'))
          }
          color={
            (stockStatus === 'Insufficient' && 'error') ||
            (stockStatus === 'Adequate' && 'primary') ||
            (stockStatus === 'NeedReview' && 'warning') ||
            'secondary'
          }
        />
      );
    }
  },
  {
    name: 'minibarNickname',
    label: t('common.minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: t('pages.minibarOrder.barcode'),
    minWidth: 160,
    align: 'center',
    sortable: true
    // hasFilter: true
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    sortable: true,
    isPinned: true
    // hasFilter: true
  },
  {
    name: 'category1Name',
    label: curationT('1st-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: curationT('2nd-cat'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: curationT('3rd-cat'),
    minWidth: 120,
    align: 'center'
  },

  {
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: t('common.package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'salesQuantity',
    label: t('pages.product.sales-quantity'),
    minWidth: 100,
    align: 'center',
    render: ({ salesQuantity }) => formatMoney(salesQuantity)
  },
  {
    name: 'stockQuantity',
    label: t('pages.product.minibar-stock'),
    minWidth: 100,
    align: 'center',
    render: ({ stockQuantity }) => formatMoney(stockQuantity)
  },
  {
    name: 'properStock',
    label: t('pages.product.proper-stock'),
    minWidth: 100,
    align: 'center',

    render: ({ properStock }) => formatMoney(properStock)
  },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },
  {
    name: 'rateCompany',
    label: t('pages.minibarOrder.discountRate'),
    minWidth: 75,
    align: 'center'
  },
  {
    name: 'masterPrice',
    label: t('pages.product.master-price'),
    minWidth: 100,
    align: 'center',
    render: ({ masterPrice }) => formatMoney(masterPrice)
  },
  {
    name: 'branchPrice',
    label: t('common.branchPrice'),
    minWidth: 100,
    align: 'center',
    cathover: true
  }
];

export const actionsHistoryColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'regDate',
    label: g('pages.inventory.date'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, Formatter.formatTime(item?.regDate))
  },

  {
    name: 'minibarName',
    label: g('common.minibar'),
    minWidth: 140,
    align: 'center',
    render: (item) => rerender(item, item?.minibarName)
  },
  {
    name: 'productName',
    label: g('common.productName'),
    minWidth: 180,
    align: 'center',
    render: (item) => rerender(item, item?.productName)
  },
  {
    name: 'barcode',
    label: g('pages.minibarOrder.barcode'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item?.barcode)
  },
  {
    name: 'type',
    label: g('pages.kiosk.labels.typeName'),
    minWidth: 80,
    align: 'center',
    render: (item) => rerender(item, minibarActionsTypeMapper[item?.updateType])
  },
  {
    name: 'quantity',
    label: `${g('button.change')} ${g('pages.equipment.quantity')}`,
    minWidth: 80,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.quantity))
  },

  {
    name: 'barcode',
    label: g('pages.minibarOrder.changed-quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) =>
      rerender(
        item,
        `${formatMoney(item?.quantityBefore)} -> ${formatMoney(
          item?.quantityAfter
        )}`
      )
  },
  {
    name: 'regName',
    label: g('pages.minibarOrder.display-pic'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.regName)
  },
  {
    name: 'delete',
    label: g('button.delete'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION
  },
  {
    name: 'editName',
    label: g('pages.minibarOrder.manager'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.editName)
  }
];

export const mobileColumns = (t) => [
  {
    name: 'barcode',
    label: t('pages.minibarOrder.barcode'),
    minWidth: 60,
    align: 'center',
    sortable: true
    // hasFilter: true
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 100,
    align: 'center',
    sortable: true,
    isPinned: true
    // hasFilter: true
  },
  {
    name: 'stockQuantity',
    label: t('pages.product.minibar-stock'),
    minWidth: 40,
    align: 'center',
    render: ({ stockQuantity }) => formatMoney(stockQuantity)
  },
  {
    name: 'properStock',
    label: t('pages.product.proper-stock'),
    minWidth: 40,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'properStock',
    render: ({ properStock }) => formatMoney(properStock)
  },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 40,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 3
  },

  {
    name: 'masterPrice',
    label: t('pages.product.master-price'),
    minWidth: 40,
    align: 'center',
    render: ({ masterPrice }) => formatMoney(masterPrice)
  },
  {
    name: 'branchPrice',
    label: t('common.branchPrice'),
    minWidth: 40,
    align: 'center',
    cathover: true,
    type: tableTypes.MODAL,
    actionType: 1
  },
  {
    label: t('pages.minibarOrder.issueYn'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'issueYn'
  },
  {
    label: t('pages.minibarOrder.progressYn'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 'progressYn'
    // hasFilter: true,
    // isPinned: true,
  }
];

export const displayManagerMobileColumns = (t) => [
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 100,
    align: 'center',
    sortable: true,
    isPinned: true
    // hasFilter: true
  },
  {
    name: 'stockQuantity',
    label: t('pages.product.minibar-stock'),
    minWidth: 40,
    align: 'center'
  },
  {
    name: 'properStock',
    label: t('pages.product.proper-stock'),
    minWidth: 40,
    align: 'center',

    actionType: 'properStock',
    render: ({ properStock }) => formatMoney(properStock)
  },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 40,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 4
  },
  {
    name: 'barcode',
    label: t('pages.minibarOrder.barcode'),
    minWidth: 60,
    align: 'center',
    sortable: true
    // hasFilter: true
  },

  {
    name: 'masterPrice',
    label: t('pages.product.master-price'),
    minWidth: 40,
    align: 'center',
    render: ({ masterPrice }) => formatMoney(masterPrice)
  },
  {
    name: 'branchPrice',
    label: t('common.branchPrice'),
    minWidth: 40,
    align: 'center',
    actionType: 1
  }
];

export const fillOutColumns = (t, handleChange) => [
  {
    name: 'clientName',
    label: t('pages.product.client'),
    minWidth: 180,
    align: 'center'
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 240,
    align: 'center'
  },
  {
    name: 'category1Name',
    label: `1st ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category2Name',
    label: `2nd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'category3Name',
    label: `3rd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'capacityType',
    label: t('common.package'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'salesQuantity',
    label: t('pages.product.sales-quantity'),
    minWidth: 100,
    align: 'center',
    render: ({ salesQuantity }) => formatMoney(salesQuantity)
  },
  {
    name: 'stockQuantity',
    label: t('pages.product.minibar-stock'),
    minWidth: 100,
    align: 'center',
    render: ({ stockQuantity }) => formatMoney(stockQuantity)
  },
  {
    label: t('pages.product.proper-stock'),
    minWidth: 100,
    align: 'center',
    render: (item) => (
      <MyTextField
        typeValue="onlyNumber"
        name="properStock"
        value={formatMoney(item?.properStock || 0)}
        onChange={(e) => handleChange(item, e)}
      />
    )
  },
  {
    label: t('pages.product.order-quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => {
      return (
        <MyTextField
          typeValue="onlyNumber"
          name="orderQuantity"
          value={formatMoney(item?.orderQuantity || 0)}
          onChange={(e) => handleChange(item, e)}
        />
      );
    }
  }
];

export const productFilterConditionList = [
  { label: t('product-name'), value: 'productName' },
  { label: t('bar-code'), value: 'barcode' },
  { label: g('pages.minibarOrder.branch-price'), value: 'branchPrice' }
];
export const minibarOrderListTableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      height: '64px'
    },
    td: {
      p: 0,
      borderBottom: 'none',
      ':not(:last-child)': {
        borderRight: '1px solid #EEEEEE'
      }
    },
    'th, td': {
      p: '0 12px'
    }
  }
};
