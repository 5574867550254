import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.log');

export const columnSystemLog = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: t('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'msg',
    label: t('service'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'flag',
    label: t('log'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'errorMsg',
    label: t('error-msg'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'regDate',
    label: t('date-occurrence'),
    minWidth: 160,
    align: 'center',
    formatter: (date) => {
      return new Date(date).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      });
    }
  }
];

export const optionSearch = [
  { value: 'msg', label: t('service') },
  { value: 'flag', label: t('log') },
  { value: 'errorMsg', label: t('error-msg') }
];
