import React from 'react';
import { Box, Snackbar } from '@mui/material';
import { nanoid } from 'nanoid';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackbar = ({
  open,
  onClose,
  message,
  placement = { vertical: 'bottom', horizontal: 'center' }
}) => {
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={placement}
        open={open}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        onClose={() => onClose(false)} // Close Snackbar automatically after duration
        key={nanoid()}
      >
        <Alert
          onClose={() => onClose(false)} // Allow manual close by the user
          severity="success"
          sx={{ width: '100%', backgroundColor: '#30343C', color: '#ffff' }}
        >
          {message || 'This is a success message!'}{' '}
          {/* Use dynamic message prop or default message */}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MySnackbar;
