import InputFile from '@components/InputFile';
import MyAutocomplete from '@components/MyAutocomplete';
import { Box, Container, Stack, Typography, styled } from '@mui/material';
import releaseService from '@services/release';
import warehouseService from '@services/warehouse';
import { Formatter } from '@utils/formatter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};

const PictureForm = ({ action, value, setValue }) => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const [pic, setPic] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [fileName, setFileName] = useState('');
  const [warningLocation, setWarningLocation] = useState('');
  const [locationStatus, setLocationStatus] = useState('');
  const getPic = async () => {
    const rs = await warehouseService.getPic();
    if (!rs?.error) {
      setPic(rs?.data?.items);
    }
  };

  const getLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;
          setValue({ ...value, latitude: lat, longitude: long });
          setLocationStatus('granted');
        },
        () => {
          setWarningLocation(t('notify.warning-location'));
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      setWarningLocation('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    getPic();
    handlePermission();
  }, []);
  useEffect(() => {
    getLocation();
  }, [navigator.geolocation]);
  useEffect(() => {
    if (value?.managerCode) {
      handleSelectPic({
        managerCode: value?.managerCode,
        managerName: value?.managerName
      });
    }
  }, [value?.managerCode]);

  const handleSelectPic = async (val) => {
    setValue({
      ...value,
      managerCode: val?.managerCode,
      managerName: val?.managerName
    });
    const rs = await releaseService.getScheduleList({
      managerCode: val?.managerCode
    });
    if (!rs?.error) {
      setSchedule(rs?.data?.data);
    }
  };
  const handleSelectSchedule = (val) => {
    setValue({
      ...value,
      schDetailId: val?.schDetailId,
      managerName: val?.managerName,
      clientName: val?.clientName,
      minibarNickname: val?.minibarNickname
    });
  };
  const handleFile = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name);
    setValue({ ...value, file });
  };
  function handlePermission() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
      navigator?.permissions?.query({ name: 'geolocation' }).then((result) => {
        if (result?.state === 'granted') {
          report(result?.state);
        } else if (result?.state === 'prompt') {
          report(result?.state);
        } else if (result?.state === 'denied') {
          report(result?.state);
        }
        result?.addEventListener('change', () => {
          report(result?.state);
        });
      });
    }
  }

  function report(state) {
    if (state !== 'granted') {
      setValue({ ...value, latitude: null, longitude: null });
      setWarningLocation('');
    } else getLocation();
    setLocationStatus(state);
    setWarningLocation(t('notify.warning-location'));
  }

  return (
    <Stack>
      <Container component={BorderBox} style={{ minWidth: 400 }}>
        <FieldContainer label={t('pages.inventory.pic')} required>
          <MyAutocomplete
            disabled={action === 'after'}
            value={{
              managerName: value?.managerName
            }}
            name="picName"
            isOptionEqualToValue={(option, value) =>
              option.managerName === value?.managerName
            }
            sx={{
              '.MuiInputBase-root': {
                bgcolor: action === 'after' && '#f2f2f2'
              }
            }}
            getOptionLabel={(option) => option?.managerName || ''}
            onChange={(e, val) => handleSelectPic(val, value)}
            options={pic}
          />
        </FieldContainer>
        <FieldContainer label={t('pages.display.client')}>
          <Typography sx={{ width: '100%' }}>{value?.clientName}</Typography>
        </FieldContainer>
        <FieldContainer label={t('pages.inventory.date')}>
          <Typography sx={{ width: '100%' }}>
            {formatTime(new Date())}
          </Typography>
        </FieldContainer>
        <FieldContainer label={t('pages.display.schedule')} required>
          <MyAutocomplete
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) =>
              (option?.schName && option?.schName) ||
              (option?.managerName &&
                option?.clientName &&
                option?.minibarNickname &&
                `${option?.managerName}, ${option?.clientName}, ${option?.minibarNickname}`) ||
              ''
            }
            disabled={action === 'after'}
            sx={{
              '.MuiInputBase-root': {
                bgcolor: action === 'after' && '#f2f2f2'
              }
            }}
            onChange={(e, val) => handleSelectSchedule(val, value)}
            options={schedule}
          />
        </FieldContainer>
        <FieldContainer label={t('pages.product.image')} required>
          <Stack
            sx={{ width: '100%' }}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                visibility: !fileName && 'hidden',
                color: 'primary.darker',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '161px',
                paddingRight: '20px'
              }}
            >
              {fileName}
            </Typography>
            <InputFile sx={{ root: { height: 40 } }} onChange={handleFile} />
          </Stack>
        </FieldContainer>
      </Container>
      {locationStatus !== 'granted' && (
        <small
          style={{
            color: 'red',
            fontSize: 12
          }}
        >
          {warningLocation}
        </small>
      )}
    </Stack>
  );
};

const FieldContainer = ({ children, label, required }) => {
  return (
    <Stack direction="row" alignItems="center" mr={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 60,
          mr: 2,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 100,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>{children}</Stack>
    </Stack>
  );
};

export default PictureForm;
