import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { Box, Button, Stack, TableCell, TableRow } from '@mui/material';

import MyDialog from '@components/MyDialog';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';

import { setError } from '@store/reducers/appReducer';
import { Formatter } from '@utils/formatter';
import { clientOrderSheetColumns as columns } from '@pages/ProductManagement/enhance';
import clientOrderServices from '@services/clientOrder';

import AddProductDialog from './AddProductDialog';

const OrderSheetArea = ({
  data,
  pageT,
  sx,
  fetchData,
  clientOrderId,
  disabled
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteOrderOrderSheet = async () => {
    setLoading(true);
    const result = await clientOrderServices.deleteOrder(clientOrderId);
    setLoading(false);
    if (result.error) dispatch(setError(result.error));
    else {
      setOpen(false);
      setTimeout(() => {
        navigate('/product-management/client-order');
      }, 300);
    }
  };
  return (
    <Box sx={sx}>
      <ZombieTable columns={columns}>
        <CustomTableBody>
          <TableRow>
            <TableCell>{Formatter.formatTime(data?.orderDt)}</TableCell>
            <TableCell>{data?.clientChannel}</TableCell>
            <TableCell>{data?.clientName}</TableCell>
            <TableCell>{data?.minibarNickname}</TableCell>
            <TableCell>
              {data?.orderAmount &&
                new Intl.NumberFormat('en-CA').format(data.orderAmount)}
            </TableCell>
            <TableCell>
              <Stack direction="row" justifyContent="center" spacing={1.25}>
                <Button
                  onClick={() => setOpenAddProduct(true)}
                  disabled={disabled}
                >
                  {pageT('addProduct')}
                </Button>
                <Button onClick={() => setOpen(true)} disabled={disabled}>
                  {pageT('deleteOrderSheet')}
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </CustomTableBody>
      </ZombieTable>
      {openAddProduct && (
        <AddProductDialog
          open={openAddProduct}
          setOpen={setOpenAddProduct}
          clientOrderData={data}
          fetchData={fetchData}
        />
      )}
      <MyDialog
        open={open}
        loading={loading}
        setOpen={setOpen}
        isAlert
        title={t('common.confirm-delete-title', {
          item: data?.orderCode || ''
        })}
        hasCancelButton
        onOk={handleDeleteOrderOrderSheet}
      />
    </Box>
  );
};

export default OrderSheetArea;
