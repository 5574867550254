/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { sub } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';

import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';
import MySelect from '@components/MySelect';
import MyTable from '@components/MyTable';
import SearchBar from '@components/SearchBar';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import { Formatter } from '@utils/formatter';
import { curationServices } from '@services/index';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { dateFormat, scopes } from '@constants/utils';
import useAuthorization from '@hooks/useAuthorization';
import { curationColumns as columns } from '../enhance';

const Curation = ({ t: pageT }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const { formatTime } = Formatter;
  const [dateRange, setDateRange] = useState({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
    key: 'selection'
  });
  const [channel, setChannel] = useState(null);
  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const [status, setStatus] = useState('total');
  const isAuthorized = useAuthorization(scopes.ADMIN_PRODUCT_MASTER);

  const getData = async (more) => {
    const result = await curationServices.getListCuration({
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      channel,
      clientCode: client?.clientCode || null,
      minibarCode: minibar?.minibarCode || null,
      page,
      status: status === 'complete' ? 'Y' : status === 'standBy' ? 'N' : null,
      ...more
    });
    setData(result.data);
  };

  const channels = [
    { value: 'B2C', label: 'B2C' },
    { value: 'B2B', label: 'B2B' },
    { value: 'B2B2C', label: 'B2B2C' }
  ];

  const handleSearch = () => {
    const dataParams = {
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      channel,
      clientCode: client?.clientCode || null,
      minibarCode: minibar?.minibarCode || null,
      status: status === 'complete' ? 'Y' : status === 'standBy' ? 'N' : null
    };
    const query = objToParams(ignoreEmpty({ ...dataParams, page: 1 }));
    getData({ dataParams, page: 0 });
    navigate(query);
  };

  const handleRowClick = (row) => {
    if (row?.curationCode) navigate(row.curationCode);
  };

  const handleRegister = () => {
    navigate('register');
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    setDateRange({
      ...dateRange,
      startDate: new Date(
        query?.startDate ? query?.startDate : firstDayOfMonth
      ),
      endDate: new Date(query?.endDate ? query?.endDate : new Date())
    });
    setChannel(query?.channel || 'B2C');
    setClient({ ...client, clientCode: query?.clientCode || null });
    setMinibar({
      ...minibar,
      minibarCode: query?.minibarCode || null
    });
    setPage(query?.page - 1 || 0);
    setStatus(
      query?.status === 'Y'
        ? 'complete'
        : query?.status === 'N'
        ? 'standBy'
        : 'total'
    );
    const dataParams = {
      startDate: moment(
        query?.startDate || sub(new Date(), { months: 1 })
      ).format(dateFormat),
      endDate: moment(query?.endDate || new Date()).format(dateFormat),
      channel: query?.channel || 'B2C',
      clientCode: query?.clientCode || null,
      minibarCode: query?.minibarCode || null,
      page: query?.page - 1 || 0,
      status: query?.status || null
    };
    getData(dataParams);
  }, [location?.search]);

  return (
    <HelmetContainer title={t('pageTitle.curation')} content="Curation">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={pageT('period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker state={dateRange} setState={setDateRange} />
            }
          />
        </FormControl>
        <MySelect
          label={pageT('channel')}
          value={channel || ''}
          data={channels}
          onChange={(e) => {
            setChannel(e.target.value);
            setClient(null);
          }}
          sx={{ mr: 1.5 }}
        />
        <ClientAutoCompleteV2
          channel={channel}
          setClient={(e) => {
            setClient(e);
            setMinibar(null);
          }}
          client={client}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MinibarAutoCompleteV2
          disabled={!client?.clientCode}
          onKeyDown={(e) => {
            if (e.isComposing || e.keyCode === 13) {
              handleSearch();
            }
          }}
          setMinibar={setMinibar}
          minibar={minibar}
          client={client}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={2.5}
          sx={{ ml: 2, mr: 'auto' }}
        >
          <Typography
            sx={{
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }}
          >
            {pageT('status')}
          </Typography>
          <FormControl
            sx={{
              '.MuiTypography-root': {
                color: '#343434',
                fontSize: 14,
                whiteSpace: 'nowrap'
              }
            }}
          >
            <RadioGroup
              row
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <FormControlLabel
                value="total"
                control={<Radio />}
                label={pageT('total')}
              />
              <FormControlLabel
                value="standBy"
                control={<Radio />}
                label={pageT('stand-by')}
              />
              <FormControlLabel
                value="complete"
                control={<Radio />}
                label={pageT('complete')}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={handleRegister}
            disabled={!isAuthorized}
          >
            {t('button.register')}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        rerender
        onRowClick={handleRowClick}
        columns={columns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page
        }}
        onChangePage={(e, page) => {
          setPage(page);
          const dataParams = {
            startDate: moment(dateRange.startDate).format(dateFormat),
            endDate: moment(dateRange.endDate).format(dateFormat),
            channel,
            clientCode: client?.clientCode || null,
            minibarCode: minibar?.minibarCode || null,
            page,
            status:
              status === 'complete' ? 'Y' : status === 'standBy' ? 'N' : null
          };
          const query = objToParams(
            ignoreEmpty({ ...dataParams, page: page + 1 })
          );
          getData(dataParams);
          navigate(query);
        }}
        minusHeight={290}
      />
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.curation' })(Curation);
