import RestService from './RestService';

const developmentServices = {
  // [QUESTION] get or post
  async getList(data) {
    try {
      const res = await new RestService()
        .setPath('dev-request/list')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getDetail(fileCode) {
    try {
      const res = await new RestService()
        .setPath(`dev-request/${fileCode}`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //   body: reqSubject, reqContent, files
  async create(data) {
    try {
      const res = await new RestService()
        .setPath('dev-request')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //   body: reqContent, files
  async update(fileCode, data) {
    try {
      const res = await new RestService()
        .setPath(`dev-request/${fileCode}`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async delete(fileCode) {
    try {
      const res = await new RestService()
        .setPath(`dev-request/${fileCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteFile(fileCode, id) {
    try {
      const res = await new RestService()
        .setPath(`dev-request/${fileCode}/${id}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //   body: fileCode, comment, status
  async updateStatus(fileCode, data) {
    try {
      const res = await new RestService()
        .setPath(`dev-request/${fileCode}/status`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};
export default developmentServices;
