/* eslint-disable prefer-exponentiation-operator */
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'dayOfWeek');
const allT = i18n.getFixedT(null, null, '');
const g = i18n.getFixedT(
  null,
  null,
  'pages.performance-management.goal.labels'
);
const c = i18n.getFixedT(null, null, 'common');

const capitalize = (text) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

const compareDates = (date1, date2) => {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();

  if (d1 > d2) {
    return 1;
  }
  if (d1 < d2) {
    return -1;
  }
  return 0;
};

function countDates(date1, date2) {
  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  const timeDiff = Math.abs(d2 - d1);
  const diffDays = Math.ceil(timeDiff / oneDay);
  return diffDays;
}

function dayOfWeek(date) {
  const day = new Date(date).toLocaleDateString('en-US', {
    weekday: 'long',
    timeZone: 'Asia/Seoul'
  });
  if (day === 'Invalid Date') {
    if (typeof date === 'string') {
      return t(`${date?.toLowerCase()}`);
    }
  }
  return t(`${day?.toLowerCase()}`);
}

function dayOfWeekFromIndex(index) {
  return t(`${index}`);
}

function dayOfWeekShort(date) {
  const day = new Date(date).toLocaleDateString('en-US', {
    weekday: 'long',
    timeZone: 'Asia/Seoul'
  });
  if (day === 'Invalid Date') {
    if (typeof date === 'string') {
      return allT(`day-of-week-short.${date?.toLowerCase()}`);
    }
  }
  return t(`${day?.toLowerCase()}`);
}

const convertMonth = (month) => {
  return g(`${month === 'Dec' ? 'dcb' : month.toLowerCase()}`);
};

function secondsToTime(secs) {
  const day = Math.floor(secs / 24 / 3600);
  const hours = Math.floor((secs % (24 * 3600)) / 3600);
  const minutes = Math.floor(((secs % (24 * 3600)) % 3600) / 60);
  const seconds = Math.floor(((secs % (24 * 3600)) % 3600) % 60);
  return `${day ? `${day}${c('day')} ` : ''}${
    hours ? `${hours}${c('hour')} ` : ''
  }${minutes ? `${minutes}${c('minute')} ` : ''}${
    seconds && `${seconds}${c('second')} `
  }`;
}
function space2Date(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d2 - d1;
}

function formatMoney(n) {
  if (!Number(n)) {
    return n;
  }
  if (n || n === 0) return (Math.round(n * 100) / 100).toLocaleString('en-CA');
  return null;
}

function format100(n) {
  if (!Number(n)) {
    return n;
  }
  if (n || n === 0) return (n / 1000000).toFixed(0).toLocaleString('en-CA');
  return null;
}

// eslint-disable-next-line consistent-return
const onlyNumber = (event) => {
  const key = event?.key;
  const keyCode = event?.keyCode;
  // Kiểm tra xem ký tự có phải là số không
  const number =
    isNumber(key) ||
    key === 'Backspace' ||
    key === 'Delete' ||
    keyCode === 37 ||
    keyCode === 39;
  // Kiểm tra xem Ctrl hoặc Command (Mac) có được nhấn cùng với phím vừa nhấn không
  const isCtrlOrCmdPressed = event?.ctrlKey || event?.metaKey;

  // Chặn các phím không phải số nếu không có Ctrl/Command được nhấn cùng lúc
  if (!number && !isCtrlOrCmdPressed) {
    event.target.blur();
    event?.preventDefault();
  }
  return 'number';
};

const hasKeyInObj = (obj, key) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (property === key) return true;
  }
  return false;
};

const isNumber = (char) => {
  // Kiểm tra bằng phương pháp isNaN()
  // return !isNaN(char);

  // Kiểm tra bằng regex
  const numberRegex = /^[0-9]+$/;
  return numberRegex.test(char);
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const daySpace = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const secs = (d2 - d1) / 1000;
  return secondsToTime(secs);
};

const objToParams = (obj) => `?${new URLSearchParams(obj).toString()}`;
const paramsToObj = (params) => {
  const obj = Object.fromEntries(new URLSearchParams(params));
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (
      obj[property] === 'null' ||
      obj[property] === 'undefined' ||
      !obj[property]
    )
      obj[property] = null;
  }
  return obj;
};
const ignoreEmpty = (obj) => {
  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (
      obj[property] === 'null' ||
      obj[property] === 'undefined' ||
      !obj[property]
    ) {
      obj[property] = null;
    } else {
      newObj[property] = obj[property];
    }
  }
  return newObj;
};

const filterItems = (items, filterField, filterValue) => {
  return items.filter((item) => item[filterField] === filterValue);
};
export {
  ignoreEmpty,
  paramsToObj,
  objToParams,
  space2Date,
  capitalize,
  compareDates,
  countDates,
  dayOfWeek,
  secondsToTime,
  convertMonth,
  formatMoney,
  onlyNumber,
  hasKeyInObj,
  delay,
  isNumber,
  daySpace,
  format100,
  filterItems,
  dayOfWeekFromIndex,
  dayOfWeekShort
};
