import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchBar from '@components/SearchBar';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  sub
} from 'date-fns';
import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import MyTable from '@components/MyTable';
import warehouseService from '@services/warehouse';
import DateRangePicker from '@components/DateRangePicker';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  compareDates,
  countDates,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import {
  lossColumns,
  lossRadios,
  searchConditionsLoss,
  timeRadios
} from './enhance';

const Loss = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const configColumns = lossColumns;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    productName: null,
    barcode: null,
    pic: null,
    lossDivision: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getLossLiss({ ...param, page });
    navigate(query);
  };
  const { items, pagination } = state;
  const [condition, setCondition] = useState('productName');
  const [lossRadio, setLossRadio] = useState('');
  const getLossLiss = async (param) => {
    const rs = await warehouseService.getLossList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      productName,
      pic,
      barcode,
      lossDivision,
      page
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParam = {
      ...param,
      productName,
      pic,
      barcode,
      lossDivision: lossDivision || null,
      page: page - 1 || 0,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat)
    };
    setLossRadio(lossDivision || '');
    setParam(dataParam);
    getLossLiss(dataParam);
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getLossLiss({ ...param, page: 0 });
    navigate(query);
  };
  const handleTimeRadio = (name) => {
    if (name === 'today')
      setParam({
        ...param,
        startDate: formatTime(new Date(), dateFormat),
        endDate: formatTime(new Date(), dateFormat)
      });
    if (name === 'currentWeek')
      setParam({
        ...param,
        startDate: formatTime(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          dateFormat
        ),
        endDate: formatTime(
          endOfWeek(new Date(), { weekStartsOn: 1 }),
          dateFormat
        )
      });
    if (name === 'currentMonth')
      setParam({
        ...param,
        startDate: formatTime(startOfMonth(new Date()), dateFormat),
        endDate: formatTime(endOfMonth(new Date()), dateFormat)
      });
  };
  const handleLossRadio = (name) => {
    if (!name) {
      setParam({ ...param, lossDivision: null });
    } else {
      setParam({ ...param, lossDivision: name });
    }
    setLossRadio(name);
  };
  const handleChange = (e) => {
    setParam({ ...param, [e.target.name]: e.target.value });
  };
  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };
  const [loading, setLoading] = useState(false);
  const exportExcel = async (param) => {
    setLoading(true);
    const query = paramsToObj(location?.search);
    const { startDate, endDate, productName, pic, barcode, lossDivision } =
      query;
    const rs = await warehouseService.exportLoss({
      startDate: startDate || param?.startDate,
      endDate: endDate || param?.endDate,
      productName,
      pic,
      barcode,
      lossDivision
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };
  const checkDateTimeRadio = (name) => {
    if (name === 'today') {
      return (
        !compareDates(param.startDate, param.endDate) &&
        !compareDates(param.startDate, formatTime(new Date(), dateFormat))
      );
    }
    if (name === 'currentWeek') {
      return (
        new Date(param.startDate).getDay() === 1 &&
        new Date(param.endDate).getDay() === 0 &&
        countDates(param.startDate, param.endDate) === 6 &&
        countDates(param.startDate, formatTime(new Date(), dateFormat)) < 7
      );
    }
    if (name === 'currentMonth') {
      return (
        new Date(param.startDate).getDate() === 1 &&
        new Date(param.endDate).getDate() >= new Date().getDate() &&
        new Date(param.startDate).getMonth() === new Date().getMonth() &&
        new Date(param.endDate).getMonth() === new Date().getMonth() &&
        countDates(param.startDate, param.endDate) !== 6
      );
    }
    return false;
  };
  return (
    <HelmetContainer
      title={t('pageTitle.loss-dispose')}
      content="Loss/Dispose Page"
    >
      <SearchBar>
        <Stack flexDirection="row" flexWrap="wrap" gap={1}>
          <Stack flexDirection="row" gap={1}>
            <MySelect
              label={t('labels.condition')}
              value={condition}
              data={searchConditionsLoss}
              onChange={(e) => {
                setParam({
                  ...param,
                  barcode: null,
                  productName: null,
                  pic: null
                });
                setCondition(e.target.value);
              }}
              sx={{ mr: 1.5, label: { width: 70 } }}
            />
            <MyTextField
              onChange={handleChange}
              name={condition}
              value={param[condition] || ''}
              placeholder={t('button.search')}
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
            />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
          >
            <FormControl>
              <FormControlLabel
                label={t('pages.product.period')}
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    width: 70,
                    textAlign: 'center'
                  }
                }}
                control={
                  <DateRangePicker
                    state={{
                      startDate: new Date(param.startDate),
                      endDate: new Date(param.endDate),
                      key: 'selection'
                    }}
                    setState={handleDatetime}
                  />
                }
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    mr: 1
                  }
                }}
                control={
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {timeRadios.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={
                          <Radio
                            onClick={() => handleTimeRadio(value)}
                            checked={checkDateTimeRadio(value)}
                            size="small"
                          />
                        }
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                }
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          display="flex"
          flexGrow={1}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography
              sx={{
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                width: 60,
                textAlign: 'center',
                mr: 1
              }}
            >
              {t('pages.product.type')}
            </Typography>
            <FormControl>
              <FormControlLabel
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    mr: 1.5
                  }
                }}
                control={
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {lossRadios.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={
                          <Radio
                            onClick={() => handleLossRadio(value)}
                            checked={lossRadio === value}
                            size="small"
                          />
                        }
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                }
              />
            </FormControl>
          </Stack>
          <Button
            onClick={() => handleSearch()}
            variant="contained"
            sx={{ ml: 1.5, width: '80px' }}
          >
            {t('button.search')}
          </Button>
        </Stack>
      </SearchBar>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Stack flexDirection="row" gap={2}>
          <Button onClick={() => navigate(-1)} variant="outlined">
            {t('pages.inventory.list')}
          </Button>
          <Button
            onClick={() => exportExcel(param)}
            variant="outlined"
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('pages.inventory.saveExcel')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        columns={configColumns}
        data={items}
        pagination={pagination}
        minusHeight={280}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default Loss;
