import React, { useState } from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';

import userServices from '@services/user';

const DownloadableText = ({ fileName, fileLink, children, sx, ...rest }) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async (e) => {
    e.stopPropagation();
    if (fileLink) {
      setDownloading(true);
      await userServices.getFile({ fileName, fileLink });
      setDownloading(false);
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <CircularProgress
        size="1.2rem"
        sx={{
          display: downloading ? 'block' : 'none',
          mr: '.5rem'
        }}
      />
      <Typography
        onClick={handleDownload}
        sx={{
          color: 'primary.darker',
          fontSize: 13,
          fontWeight: 'bold',
          cursor: fileLink ? 'pointer' : 'auto',
          textDecoration: 'underline',
          ...sx
        }}
        {...rest}
      >
        {children}
      </Typography>
    </Stack>
  );
};
export default DownloadableText;
