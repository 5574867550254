/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Stack, Typography } from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';
import MyDialog from '@components/MyDialog';

import { welfarePointsServices, memberServices } from '@services/index';
// import {CHANNELS} from '@constants/utils'
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import MemberRegisterDialog from './MemberRegisterDialog';
import UpdateMemberDialog from './UpdateMemberDialog';
import UpdateWelfarePointsDialog from './UpdateWelfarePointsDialog';
import ExcelRegisterDialog from './ExcelRegisterDialog';

import { conditionList, welfarePointsColumns as columns } from '../enhance';

const WelfarePoints = ({ t: pageT }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [success, setSuccess] = useState(false);

  const [open, setOpen] = useState(false);
  const [openUpdateMember, setOpenUpdateMember] = useState(false);
  const [isEditWP, setIsEditWP] = useState(false);
  const [openUpdateWelfarePoint, setOpenUpdateWelfarePoint] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openExcelRegisterDialog, setOpenExcelRegisterDialog] = useState(false);

  const [condition, setCondition] = useState(conditionList[1].value);
  const [keyword, setKeyword] = useState('');
  const [client, setClient] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [itemDetail, setItemDetail] = useState();

  const [editable, setEditable] = useState(false);
  const [channel, setChannel] = useState(null);

  const channels = [
    { value: 'B2B,B2C,B2B2C', label: t('pages.client-order.all') },
    { value: 'B2C', label: 'B2C' },
    { value: 'B2B', label: 'B2B' },
    { value: 'B2B2C', label: 'B2B2C' }
  ];
  const getData = async (data) => {
    const result = await welfarePointsServices.getList(data);
    if (result.error) dispatch(setError({ title: 'Something went wrong' }));
    else {
      setData(result.data);
      setEditable(data?.clientCode);
    }
  };

  const getItemDetails = async (item) => {
    if (!item?.memberCode) return;
    const result = await memberServices.getMemberDetails(item.memberCode);
    if (result.error) dispatch(setError({ title: 'Something went wrong' }));
    else setItemDetail(result.data.data);
  };

  const getActionButtons = (item) => {
    return (
      <Stack direction="row" spacing={1.25} justifyContent="center">
        <Button
          onClick={async (e) => {
            e.stopPropagation();
            setSelectedItem(item);
            await getItemDetails(item);
            setOpenUpdateMember(true);
          }}
          disabled={!editable}
          sx={{ height: 36 }}
        >
          {t('button.edit')}
        </Button>
        <Button
          sx={{ height: 36 }}
          onClick={async (e) => {
            e.stopPropagation();
            setIsEditWP(true);
            setSelectedItem(item);
            await getItemDetails(item);
            setOpenUpdateWelfarePoint(true);
          }}
        >
          {pageT('wp-edit')}
        </Button>
        <Button
          sx={{ height: 36 }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedItem(item);
            setOpenConfirm(true);
          }}
        >
          {t('button.delete')}
        </Button>
      </Stack>
    );
  };

  const handleSearch = () => {
    const data = {
      clientCode: client?.clientCode || null,
      clientChannel: channel,
      page: 0,
      [condition]: keyword
    };
    getData(data);
    const query = objToParams(ignoreEmpty({ ...data, page: 1 }));
    navigate(query);
  };

  const handleDelete = async (item) => {
    if (!item.welfareId) return;
    setSuccess(false);
    const result = await welfarePointsServices.delete(item.welfareId);
    if (result?.error) dispatch(setError(error2Text(result.error)));
    else setSuccess(true);
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (success)
      getData({
        clientCode: client?.clientCode,
        clientChannel: channel,
        page,
        [condition]: keyword
      });
  }, [success]);

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setPage(query?.page - 1 || 0);
    if (query?.clientCode) {
      setClient({ ...client, clientCode: query?.clientCode || null });
    } else {
      setClient(null);
      setEditable(false);
    }
    setCondition(
      (query?.name && 'name') ||
        (query?.phone && 'phone') ||
        (query?.idCardNo && 'idCardNo') ||
        'name'
    );
    setChannel(query?.clientChannel || 'B2B,B2C,B2B2C');
    setKeyword(query?.name || query?.phone || query?.idCardNo || '');
    const condition = {
      name: query?.name || null,
      phone: query?.phone || null,
      idCardNo: query?.idCardNo || null
    };
    getData({
      clientCode: query?.clientCode || null,
      clientChannel: query?.clientChannel || null,
      page: query?.page - 1 || 0,
      ...condition
    });
  }, [location?.search]);

  return (
    <HelmetContainer
      title={t('pageTitle.welfarePoints')}
      content="welfare points"
    >
      <SearchBar>
        <MySelect
          label={t('labels.clientChannel')}
          value={channel || channels[0]}
          data={channels}
          onChange={(e) => {
            setChannel(e.target.value);
          }}
          sx={{ mr: 1.5 }}
        />
        <ClientAutoCompleteV2
          client={client}
          setClient={setClient}
          setChannel={setChannel}
          channel={channel}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MySelect
          label={pageT('condition')}
          value={condition}
          data={conditionList}
          onChange={(e) => setCondition(e.target.value)}
          sx={{ mr: 1.5 }}
        />
        <Stack flex={1} sx={{ minWidth: 200 }}>
          <MyTextField
            placeholder={pageT('input-keyword')}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            inputProps={{
              onKeyDown: (e) => {
                if (e.isComposing || e.keyCode === 13) {
                  handleSearch();
                }
              }
            }}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => setOpen(true)}
            disabled={!client?.clientCode}
          >
            {t('button.register')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenExcelRegisterDialog(true)}
          >
            {t('button.excel-register')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setIsEditWP(false);
              setOpenUpdateWelfarePoint(true);
            }}
            disabled={!client?.clientCode}
          >
            {pageT('wp-register')}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        getActionButtons={getActionButtons}
        columns={columns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page
        }}
        onChangePage={(e, page) => {
          const query = objToParams(
            ignoreEmpty({
              clientCode: client?.clientCode || null,
              [condition]: keyword,
              page: page + 1
            })
          );
          navigate(query);
        }}
        minusHeight={235}
      />
      <MemberRegisterDialog
        open={open}
        setOpen={setOpen}
        clientCode={client?.clientCode}
        key={open}
        setSuccess={setSuccess}
      />
      <ExcelRegisterDialog
        open={openExcelRegisterDialog}
        setOpen={setOpenExcelRegisterDialog}
        key={`erd${openExcelRegisterDialog}`}
        setSuccess={setSuccess}
      />
      {itemDetail && selectedItem && openUpdateMember && (
        <UpdateMemberDialog
          open={openUpdateMember}
          setOpen={setOpenUpdateMember}
          initData={{ ...itemDetail, memberCode: selectedItem?.memberCode }}
          setSuccess={setSuccess}
          key={itemDetail}
          clientCode={client?.clientCode}
        />
      )}
      <UpdateWelfarePointsDialog
        open={openUpdateWelfarePoint}
        setOpen={setOpenUpdateWelfarePoint}
        initData={{ ...itemDetail, memberCode: selectedItem?.memberCode }}
        clientCode={client?.clientCode}
        key={`uwpd${openUpdateWelfarePoint}`}
        setSuccess={setSuccess}
        isEdit={isEditWP}
      />
      <MyDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        isAlert
        title={t('common.confirm-delete-title', { item: selectedItem?.name })}
        hasCancelButton
        onOk={() => handleDelete(selectedItem)}
      />
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.welfare-points' })(
  WelfarePoints
);
