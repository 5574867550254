import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const stSty = {
  fontSize: 'inherit',
  color: 'primary.main',
  fontWeight: 'fontWeightBold'
};

const ndSty = {
  fontSize: 'inherit',
  color: 'primary.darker',
  fontWeight: 'fontWeightBold'
};

const rdSty = {
  fontSize: 'inherit',
  color: 'primary.darker',
  fontWeight: 'fontWeightBold'
};

const AnalysisBlock = ({
  title,
  secondTitle,
  summaryTitle,
  data,
  isSales = false,
  sx
}) => {
  const [t] = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: '12px',
        p: '20px 20px 8px',
        minWidth: '200px',
        height: '230px',
        bgcolor: 'secondary.lighter',
        ...sx
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography
          sx={{
            fontWeight: 'fontWeightBold',
            fontSize: 16,
            color: 'text.primary'
          }}
        >
          {title || t('pages.dashboard.salesThisYear')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ color: 'text.secondary', fontSize: 24, lineHeight: '33px' }}
        >
          {isSales ? (
            <div>
              <Typography component="span" sx={stSty}>
                {data?.salesThisYear}
              </Typography>
              {t('common.won')}
            </div>
          ) : (
            <Typography component="span" sx={stSty}>
              {data?.total}
            </Typography>
          )}
        </Stack>
      </Box>

      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: 18,
            lineHeight: '22px',
            fontWeight: 'fontWeightSemiMedium',
            py: '12px'
          }}
        >
          <Typography sx={{ fontWeight: 'inherit', fontSize: 15 }}>
            {secondTitle || t('pages.dashboard.achievementRate')}
          </Typography>
          {isSales ? (
            <div>
              <Typography component="span" sx={ndSty}>
                {data?.rate || ''}
              </Typography>
              %
            </div>
          ) : (
            <Typography component="span" sx={ndSty}>
              {data?.prev || ''}
            </Typography>
          )}
        </Stack>

        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            color: 'text.secondary',
            fontSize: 18,
            lineHeight: '25px',
            fontWeight: 'fontWeightSemiMedium',
            py: '12px'
          }}
        >
          <Typography sx={{ fontWeight: 'inherit', fontSize: 15 }}>
            {summaryTitle || t('pages.dashboard.target')}
          </Typography>
          {isSales ? (
            <div>
              <Typography component="span" sx={rdSty}>
                {data?.target}
              </Typography>
              {t('common.million')}
            </div>
          ) : (
            <Typography
              component="span"
              sx={{ ...rdSty, color: 'primary.dark' }}
            >
              {data?.monthChange || ''}
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default AnalysisBlock;
