import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import MySelect from '@components/MySelect';
import ChannelService from '@services/channel';
import clientServices from '@services/client';
import { MAX_INT, PRIVATE_CODE } from '@constants/utils';
import MyTextField from '@components/MyTextField';
import InputFile from '@components/InputFile';
import { useTranslation } from 'react-i18next';
import Calendar from '@components/Calendar';
import { LoadingButton } from '@mui/lab';
import DaumAddress from '@components/DaumAddress';
// import { now } from 'moment';
import { isEmail } from '@utils/boolean';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { onOpenConfirm } from '@store/reducers/appReducer';
import MyDialog from '@components/MyDialog';
import { PRICE_POLICY_TYPE } from '../enhance';
import PerformanceForm from './PerformanceForm';
import RenewalForm from './RenewalForm';
import AggregationSetting from './AggregationSetting';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const rowBorder = { borderBottom: '1px solid #D3D3D3' };

const leftLabelStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: '700',
  fontSize: 13,
  borderRight: '1px solid #D3D3D3',
  color: '#6F869C',
  paddingLeft: 3,
  backgroundColor: '#FCFCFC'
};

const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '116px'
};

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};

const defaultValue = {
  id: '',
  recontractRegDt: '',
  shippingCode: '',
  clientCode: '',
  clientChannel: '',
  clientName: '',
  clientGroup: '',
  recontractDate: '',
  clientRecontractId: '',
  recontractPeriod: '',
  investmentAmount: '',
  businessFileName: '',
  shippingLocation: '',
  shippingAddress1: '',
  shippingDistrict: '',
  shippingAddress2: '',
  businessNumber: '',
  clientId: '',
  contractDate: new Date(),
  clientContact: '',
  note: '',
  groupCode: '',
  goalAmount: '',
  ownerName: '',
  aggregationCode: '',
  clientAddress1: '',
  clientAddress2: '',
  contractTerm: '',
  salesManager: '',
  pricePolicyType: '',
  managerPhone: '',
  managerName: '',
  groupName: '',
  officialEmail: '',
  chargerEmail: '',
  jul: '',
  nov: '',
  aug: '',
  apr: '',
  oct: '',
  may: '',
  jan: '',
  jun: '',
  dcb: '',
  feb: '',
  sep: '',
  mar: '',
  performanceCode: '',
  accessMethod: '',
  other: '',
  parkingType: '',
  shippingCycle: '',
  shippingWeekMon: '',
  shippingWeekTue: '',
  shippingWeekWed: '',
  shippingManager: '',
  shippingWeekThu: '',
  shippingWeekFri: ''
};

const maxLengthOf = {
  clientName: 30,
  clientId: 20,
  managerPhone: 20,
  managerName: 20,
  ownerName: 20,
  groupName: 20,
  clientContact: 20,
  businessNumber: 20,
  officialEmail: 40,
  chargerEmail: 40,
  contractTerm: 10,
  salesManager: 10,
  shippingManager: 20,
  shippingDistrict: 20,
  shippingLocation: 20,
  accessMethod: 40,
  parkingType: 20,
  shippingCycle: 10,
  other: 200,
  note: 256
};

const requiredFields = [
  'clientChannel',
  'clientName',
  'clientId',
  'groupName',
  'pricePolicyType'
];

const emailFields = ['officialEmail', 'chargerEmail'];

const numberFields = ['goalAmount', 'investmentAmount'];

function ClientForm({
  clientCode = '',
  cancelButtonProps,
  submitButtonProps,
  isLoading,
  onClose,
  onSubmit
}) {
  const [channels, setChannels] = useState([]);
  const [client, setClient] = useState(defaultValue);
  const [performance, setPerformance] = useState(undefined);
  const [renewal, setRenewal] = useState(undefined);
  const [isEdit] = useState(clientCode.startsWith(PRIVATE_CODE.CLIENT));
  const [file, setFile] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState({
    clientChannel: '',
    clientName: '',
    clientId: '',
    groupName: '',
    pricePolicyType: '',
    officialEmail: '',
    chargerEmail: '',
    goalAmount: '',
    investmentAmount: '',
    managerPhone: '',
    managerName: '',
    ownerName: '',
    clientContact: '',
    businessNumber: '',
    contractTerm: '',
    salesManager: '',
    shippingManager: '',
    shippingDistrict: '',
    shippingLocation: '',
    accessMethod: '',
    parkingType: '',
    shippingCycle: '',
    other: '',
    note: ''
  });

  const handleDaumAddressComplete = (data) => {
    let fullAddress = data.address;
    const { addressType, bname, buildingName, name } = data;
    let extraAddress = '';

    if (addressType === 'R') {
      if (bname !== '') {
        extraAddress += bname;
      }
      if (buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${buildingName}` : buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setClient({ ...client, [name]: fullAddress });
  };

  const handleSubmit = () => {
    const isPassed = validateFormOnSubmit(client);
    if (isPassed) {
      if (onSubmit) {
        onSubmit({ ...client, file });
      } else {
        onClose();
      }
    }
  };

  const getChannels = async () => {
    const result = await ChannelService.getChannels();
    if (!result.error) {
      const a = result.data.data.map((cn) => ({
        value: cn.channelName,
        label: cn.channelName
      }));

      setChannels(a);
    }
  };

  const getClient = async () => {
    if (isEdit) {
      const result = await clientServices.getClient(clientCode);
      if (!result.error) {
        setClient(result.data);
      }
    }
  };

  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    getChannels();
    getClient();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value, type, checked, align } = e.target;
    const checkBoxValue = checked ? 'Y' : '';
    const checkValue =
      align === 'number' ? Formatter.fNumber(value.replaceAll(',', '')) : value;
    setClient({
      ...client,
      [name]: type === 'checkbox' ? checkBoxValue : checkValue
    });
    validateFormOnChange(e.target.name, e.target.value);
  };

  const validateFormOnChange = (name, valuee) => {
    let msg = '';
    if (name === 'clientId' && valuee) {
      const regex = /^[a-zA-Z0-9_]+$/;
      if (!regex.test(valuee)) {
        msg = t('validation.text&num');
      }
    }
    if (requiredFields.includes(name) && !valuee) {
      msg = t('common.required');
    } else if (
      numberFields.includes(name) &&
      Formatter.fStringToNumber(valuee) > MAX_INT
    ) {
      msg = t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) });
    }
    if (
      Object.hasOwn(maxLengthOf, name) &&
      valuee?.length > maxLengthOf[name]
    ) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrorMessage({
      ...errorMessage,
      [name]: msg
    });
  };

  const validateEmailAddress = ({ name, value: valuee }) => {
    let msg = '';
    if (valuee) {
      if (valuee.length > maxLengthOf[name]) {
        msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
      } else if (!isEmail(valuee)) {
        msg = t('validation.invalidEmailAddress');
      }
    }
    setErrorMessage({
      ...errorMessage,
      [name]: msg
    });
  };

  const validateFormOnSubmit = (data) => {
    let isPassed = true;
    const tempError = { ...errorMessage };
    requiredFields.forEach((field) => {
      if (!data?.[field]) {
        tempError[field] = t('common.required');
        isPassed = false;
      }
    });
    emailFields.forEach((mail) => {
      if (data?.[mail] && !isEmail(data?.[mail])) {
        tempError[mail] = t('validation.invalidEmailAddress');
        isPassed = false;
      }
    });
    numberFields.forEach((money) => {
      if (Formatter.fStringToNumber(data?.[money]) > MAX_INT) {
        tempError[money] = t('validation.maxNumberIs', {
          max: Formatter.fNumber(MAX_INT)
        });
        isPassed = false;
      }
    });
    // eslint-disable-next-line no-restricted-syntax
    for (const property in maxLengthOf) {
      if (data?.[property]?.length > maxLengthOf[property]) {
        tempError[property] = t('validation.dataTooLong', {
          max: maxLengthOf[property]
        });
        isPassed = false;
      }
    }
    setErrorMessage(tempError);
    return isPassed;
  };

  const editPerformance = () => {
    setPerformance(client.performanceCode);
  };

  const showRenewalDialog = () => {
    setRenewal(client.clientCode);
  };

  const handleClosePerformanceAction = () => {
    setIsSubmitting(false);
    setPerformance(undefined);
  };

  const handleSubmitPerformance = async (e) => {
    setIsSubmitting(true);
    const result = await clientServices.initialGoldPerformance({
      ...e,
      clientCode: client?.clientCode
    });
    if (!result.error) {
      getClient();
    } else {
      const content =
        result.error.data?.message ||
        t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain');
      setNotifyDialog({
        title: content,
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
    }
    setPerformance(undefined);
    setIsSubmitting(false);
  };

  const handleCloseRenewalAction = () => {
    setIsSubmitting(false);
    setRenewal(undefined);
  };

  const handleSubmitRenewal = async (e) => {
    if (!e.recontractPeriod) {
      const content = t('dialog.title.incorrectInformation');
      setNotifyDialog({
        title: content,
        onOk: () => {
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setNotifyDialog(undefined);
        }
      });
      setIsSubmitting(false);
    } else {
      const result = await clientServices.renewalContract(e);
      if (!result.error) {
        getClient();
      } else {
        const content =
          result.error.data?.message ||
          t('dialog.title.anErrorHasOccurredPleaseReloadAndTryAgain');
        setNotifyDialog({
          title: content,
          onOk: () => {
            setNotifyDialog(undefined);
          },
          setOpen: () => {
            setNotifyDialog(undefined);
          }
        });
      }
      setIsSubmitting(false);
      setRenewal(undefined);
    }
  };
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        mt: '30px',
        p: 0,
        width: '100%',
        maxHeight: '80vh'
      }}
    >
      <Container component={BorderBox}>
        <Stack direction="row" sx={rowBorder}>
          <Stack sx={{ ...leftLabelStyle }}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.channel')}
              <span style={{ color: 'red', marginLeft: 5 }}>*</span>
            </Typography>
          </Stack>
          <Stack
            justifyContent="space-between"
            alignItems="flex-end"
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              width: isEdit ? '100%' : '30%'
            }}
          >
            <Stack
              direction="row"
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                paddingRight: 2,
                width: { xs: '100%', md: 'auto' },
                marginTop: '10px',
                marginBottom: '10px'
              }}
            >
              <MySelect
                value={client?.clientChannel || ''}
                name="clientChannel"
                onChange={handleChangeInput}
                data={channels}
                sx={{ width: '100%' }}
                required
                errMg={errorMessage.clientChannel}
              />
            </Stack>

            {isEdit && (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: 'auto' },
                  flexGrow: 1
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.clientCode')}
                  </Typography>
                </Stack>

                <MyTextField
                  size="small"
                  value={client?.clientCode}
                  disabled
                  sx={inputStyle}
                />
              </Stack>
            )}
            {isEdit && (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: 'auto' },
                  flexGrow: 1
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.groupCode')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.groupCode}
                  disabled
                  sx={inputStyle}
                />
              </Stack>
            )}
            {isEdit && (
              <Stack
                direction="row"
                justifyContent="center"
                sx={{
                  flexGrow: 1,
                  paddingLeft: 2,
                  width: { xs: '100%', md: 'auto' },
                  paddingRight: 2,
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' }
                }}
              >
                <Button
                  variant="contained"
                  onClick={showRenewalDialog}
                  sx={{ margin: '10px 0px', maxWidth: 260, height: 40 }}
                >
                  {t('button.renewal')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" sx={rowBorder}>
          <Stack sx={{ ...leftLabelStyle }}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.client')}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%',
                  borderBottom: '1px solid #D3D3D3'
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.clientName')}
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.clientName')}
                    value={client?.clientName}
                    name="clientName"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    required
                    errMg={errorMessage.clientName}
                  />
                </Stack>

                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.managerName')}
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.managerName')}
                    value={client?.managerName}
                    name="managerName"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.managerName}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.clientContact')}
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.clientContact')}
                    value={client?.clientContact}
                    name="clientContact"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.clientContact}
                    typeValue="onlyNumber"
                  />
                </Stack>
              </Stack>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%',
                  borderBottom: '1px solid #D3D3D3'
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.clientId')}
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.clientId')}
                    value={client?.clientId}
                    name="clientId"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    required
                    disabled={isEdit}
                    errMg={errorMessage.clientId}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.ownerName')}
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.ownerName')}
                    value={client?.ownerName}
                    name="ownerName"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.ownerName}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.businessNumber')}
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.businessNumber')}
                    value={client?.businessNumber}
                    name="businessNumber"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.businessNumber}
                    typeValue="onlyNumber"
                  />
                </Stack>
              </Stack>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%',
                  borderBottom: '1px solid #D3D3D3'
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{
                    height: '100%',
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.managerPhone')}
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.managerPhone')}
                    value={client?.managerPhone}
                    name="managerPhone"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.managerPhone}
                    typeValue="onlyNumber"
                  />
                </Stack>

                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' },
                    height: '100%'
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.groupName')}
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Typography>
                  </Stack>
                  <MyTextField
                    size="small"
                    placeholder={t('labels.groupName')}
                    value={client?.groupName}
                    name="groupName"
                    onChange={(e) => handleChangeInput(e)}
                    sx={inputStyle}
                    errMg={errorMessage.groupName}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    height: '100%',
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.businessFileName')}
                    </Typography>
                  </Stack>
                  <InputFile
                    title={t('button.Select')}
                    urlData={
                      isEdit
                        ? {
                            url: client?.businessFileLink,
                            name: client?.businessFileName
                          }
                        : {}
                    }
                    sx={{
                      root: {
                        height: '100%',
                        width: 'calc(100% - 120px)',
                        paddingLeft: 2,
                        paddingRight: 2
                      },
                      width: '100%',
                      label: {
                        maxWidth: '100%'
                      }
                    }}
                    file={file}
                    onChange={(event) => handleChangeFile(event)}
                    disabled={isLoading}
                  />
                </Stack>
              </Stack>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%'
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    height: '100%',
                    width: { xs: '100%', md: '66%' }
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.address')}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    sx={{ width: '100%' }}
                  >
                    <Stack sx={{ width: '100%' }}>
                      <DaumAddress
                        value={client?.clientAddress1}
                        title={t('button.search')}
                        onComplete={(data) =>
                          handleDaumAddressComplete({
                            ...data,
                            name: 'clientAddress1'
                          })
                        }
                        placeholder={t('labels.addressExample')}
                        name="clientAddress1"
                        sx={inputStyle}
                      />
                      <MyTextField
                        size="small"
                        value={client?.clientAddress2}
                        placeholder={t('labels.addressExample')}
                        name="clientAddress2"
                        onChange={(e) => handleChangeInput(e)}
                        sx={inputStyle}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: '33%' }
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      flexGrow: 1,
                      height: '100%',
                      borderBottom: { xs: 'none', md: '1px solid #D3D3D3' }
                    }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        flexGrow: 1,
                        paddingLeft: 2,
                        ...labelStyle
                      }}
                    >
                      <Typography
                        sx={{
                          width: 100,
                          fontSize: 13,
                          color: '#6F869C'
                        }}
                      >
                        {t('labels.officialEmail')}
                      </Typography>
                    </Stack>
                    <MyTextField
                      size="small"
                      value={client?.officialEmail}
                      placeholder="officialEmail@example.com"
                      name="officialEmail"
                      onChange={(e) => handleChangeInput(e)}
                      sx={inputStyle}
                      errMg={errorMessage.officialEmail}
                      onBlur={(e) => validateEmailAddress(e.target)}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      height: '100%',
                      flexGrow: 1
                    }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        flexGrow: 1,
                        paddingLeft: 2,
                        ...labelStyle
                      }}
                    >
                      <Typography
                        sx={{
                          width: 100,
                          fontSize: 13,
                          color: '#6F869C'
                        }}
                      >
                        {t('labels.chargerEmail')}
                      </Typography>
                    </Stack>
                    <MyTextField
                      size="small"
                      value={client?.chargerEmail}
                      placeholder="chargerEmail@example.com"
                      name="chargerEmail"
                      onChange={(e) => handleChangeInput(e)}
                      sx={inputStyle}
                      errMg={errorMessage.chargerEmail}
                      onBlur={(e) => validateEmailAddress(e.target)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" sx={rowBorder}>
          <Stack sx={leftLabelStyle}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.contract')}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.contractPeriod')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.contractTerm}
                  placeholder={t('labels.contractPeriod')}
                  name="contractTerm"
                  onChange={(e) => handleChangeInput(e)}
                  sx={inputStyle}
                  errMg={errorMessage.contractTerm}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '33%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    height: '100%',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.contractDate')}
                  </Typography>
                </Stack>
                <Stack sx={{ paddingLeft: 2, paddingRight: 2, width: '100%' }}>
                  <Calendar
                    date={
                      client?.contractDate
                        ? new Date(client?.contractDate)
                        : null
                    }
                    sx={{
                      inputStyle: {
                        width: '100%'
                      }
                    }}
                    setDate={(e) =>
                      handleChangeInput({
                        target: { value: e, name: 'contractDate' }
                      })
                    }
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.pricePolicyType')}
                    <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    flexGrow: 1,
                    ...inputStyle
                  }}
                >
                  <MySelect
                    value={client?.pricePolicyType || ''}
                    name="pricePolicyType"
                    onChange={handleChangeInput}
                    data={PRICE_POLICY_TYPE}
                    sx={{ width: '100%' }}
                    errMg={errorMessage.pricePolicyType}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.salesManager')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  placeholder={t('labels.salesManager')}
                  value={client?.salesManager}
                  name="salesManager"
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.salesManager}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.goalAmount')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={Formatter.fNumber(
                    client?.goalAmount?.toString().replaceAll(',', '')
                  )}
                  placeholder={t('labels.goalAmount')}
                  name="goalAmount"
                  typeValue="onlyNumber"
                  errMg={errorMessage.goalAmount}
                  onChange={handleChangeInput}
                  inputProps={{ align: 'number' }}
                  sx={inputStyle}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.investmentAmount')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={Formatter.fNumber(
                    client?.investmentAmount?.toString().replaceAll(',', '')
                  )}
                  placeholder={t('labels.investmentAmount')}
                  name="investmentAmount"
                  typeValue="onlyNumber"
                  errMg={errorMessage.investmentAmount}
                  onChange={handleChangeInput}
                  inputProps={{ align: 'number' }}
                  sx={inputStyle}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" sx={rowBorder}>
          <Stack sx={leftLabelStyle}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.shipping')}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: { xs: '100%', md: '33.5%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    height: '100%',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.shippingManager')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.shippingManager}
                  placeholder={t('labels.shippingManager')}
                  name="shippingManager"
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.shippingManager}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '66.5%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.address')}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  sx={{ width: '100%' }}
                >
                  <Stack sx={{ width: '100%' }}>
                    <DaumAddress
                      value={client?.shippingAddress1}
                      title="Search"
                      onComplete={(data) =>
                        handleDaumAddressComplete({
                          ...data,
                          name: 'shippingAddress1'
                        })
                      }
                      placeholder={t('labels.addressExample')}
                      name="shippingAddress1"
                      sx={inputStyle}
                    />
                    <MyTextField
                      size="small"
                      value={client?.shippingAddress2}
                      placeholder={t('labels.addressExample')}
                      name="shippingAddress2"
                      onChange={handleChangeInput}
                      sx={inputStyle}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.shippingDistrict')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.shippingDistrict}
                  name="shippingDistrict"
                  placeholder={t('labels.shippingDistrict')}
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.shippingDistrict}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.shippingLocation')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.shippingLocation}
                  name="shippingLocation"
                  placeholder={t('labels.shippingLocation')}
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.shippingLocation}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  height: '100%',
                  width: { xs: '100%', md: '33%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.accessMethod')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client.accessMethod}
                  placeholder={t('labels.accessMethod')}
                  name="accessMethod"
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.accessMethod}
                />
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%',
                borderBottom: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  height: '100%',
                  width: { xs: '100%', md: '33.5%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.parkingType')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.parkingType}
                  name="parkingType"
                  placeholder={t('labels.parkingType')}
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.parkingType}
                />
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '66.5%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    height: '100%',
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.other')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.other}
                  placeholder={t('labels.other')}
                  name="other"
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.other}
                />
              </Stack>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-end"
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                width: '100%'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: { xs: '100%', md: '33.5%' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.shippingCycle')}
                  </Typography>
                </Stack>
                <MyTextField
                  size="small"
                  value={client?.shippingCycle}
                  placeholder={t('labels.shippingCycle')}
                  name="shippingCycle"
                  onChange={handleChangeInput}
                  sx={inputStyle}
                  errMg={errorMessage.shippingCycle}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: '66.5%' },
                  height: '100%'
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    paddingLeft: 2,
                    height: '100%',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 100,
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {t('labels.shippingDate')}
                  </Typography>
                </Stack>

                <FormGroup sx={{ width: '100%' }}>
                  <Stack
                    justifyContent="space-around"
                    direction={{ xs: 'column', md: 'row' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client?.shippingWeekMon === 'Y'}
                          name="shippingWeekMon"
                          onClick={handleChangeInput}
                        />
                      }
                      label={t('labels.monday')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client?.shippingWeekTue === 'Y'}
                          name="shippingWeekTue"
                          onClick={handleChangeInput}
                        />
                      }
                      label={t('labels.tuesday')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client.shippingWeekWed === 'Y'}
                          name="shippingWeekWed"
                          onClick={handleChangeInput}
                        />
                      }
                      label={t('labels.wednesday')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client.shippingWeekThu === 'Y'}
                          name="shippingWeekThu"
                          onClick={handleChangeInput}
                        />
                      }
                      label={t('labels.thursday')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={client.shippingWeekFri === 'Y'}
                          name="shippingWeekFri"
                          onClick={handleChangeInput}
                        />
                      }
                      label={t('labels.friday')}
                    />
                  </Stack>
                </FormGroup>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {isEdit && (
          <Stack direction="row" sx={rowBorder}>
            <Stack sx={leftLabelStyle}>
              <Typography sx={{ width: 100 }}>
                {t('labels.group.performance')}
              </Typography>
            </Stack>
            <Stack justifyContent="space-between" sx={{ width: '100%' }}>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%',
                  borderBottom: '1px solid #D3D3D3'
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      sx={{
                        width: '100%'
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                        <FieldMonth t={t} month={i} value={client} />
                      ))}
                    </Stack>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ height: '36px', p: '14px 15px', marginRight: 2 }}
                      onClick={editPerformance}
                    >
                      {t('button.change')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  width: '100%'
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 100,
                        fontSize: 13,
                        color: '#6F869C'
                      }}
                    >
                      {t('labels.aggregateClient')}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      flexGrow: 1,
                      paddingLeft: 2,
                      paddingRight: 2,
                      margin: '10px 0px'
                    }}
                  >
                    <AggregationSetting
                      value={client?.aggregationCode || ''}
                      onChange={handleChangeInput}
                      name="aggregationCode"
                      textFieldProps={{
                        placeholder: t('labels.aggregateClient')
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack direction="row">
          <Stack sx={{ ...leftLabelStyle }}>
            <Typography sx={{ width: 100 }}>
              {t('labels.group.note')}
            </Typography>
          </Stack>
          <Stack
            justifyContent="space-between"
            sx={{ width: '100%', padding: 3 }}
          >
            <TextField
              value={client?.note}
              name="note"
              onChange={handleChangeInput}
              multiline
              placeholder={t('labels.group.note')}
              rows={3}
              variant="outlined"
              inputProps={{
                className: {
                  resize: 'both'
                }
              }}
              errMg={errorMessage.note}
            />
          </Stack>
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || 'Cancel'}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || 'Save'}
          </LoadingButton>
        )}
      </Stack>
      <MyDialog
        open={performance !== undefined}
        setOpen={!isSubmitting ? handleClosePerformanceAction : () => {}}
        title={
          performance == null
            ? t('dialog.title.registrationOfTargetPerformance')
            : t('dialog.title.modificationOfTargetPerformance')
        }
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{ '.MuiDialogContent-root': { overflow: 'hidden' } }}
      >
        <PerformanceForm
          item={client}
          onClose={handleClosePerformanceAction}
          onSubmit={handleSubmitPerformance}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t('button.check')
          }}
          hasCancelButton
          hasCloseButton
        />
      </MyDialog>
      <MyDialog
        open={renewal !== undefined}
        setOpen={!isSubmitting ? handleCloseRenewalAction : () => {}}
        title={t('dialog.title.renewal')}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{ '.MuiDialogContent-root': { overflow: 'hidden' } }}
      >
        <RenewalForm
          item={client}
          onClose={handleCloseRenewalAction}
          onSubmit={(e) =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'modify',
                action: () => handleSubmitRenewal(e)
              })
            )
          }
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t('button.register')
          }}
          hasCancelButton
          hasCloseButton
        />
      </MyDialog>
      <MyDialog
        open={notifyDialog !== undefined}
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        isAlert
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
    </Stack>
  );
}

const FieldMonth = ({ month, value, t }) => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dcb'
  ];
  return (
    <Stack direction="row" alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          // paddingLeft: 2,
          ...labelStyle,
          width: 80,
          height: 40
        }}
      >
        <Typography
          sx={{
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {`${month}${t('labels.month')}`}
        </Typography>
      </Stack>
      <MyTextField
        size="small"
        value={Formatter.fNumber(
          value?.[months[month - 1]]?.toString().replaceAll(',', '')
        )}
        sx={{
          paddingRight: 2,
          width: 150,
          margin: '10px 0px'
        }}
        disabled
      />
    </Stack>
  );
};

export default ClientForm;
