/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
// import SearchBar from '@components/SearchBar';
import {
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
  CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import useResponsive from '@hooks/useResponsive';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import minibarOrderService from '@services/minibarOrderService';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';
import MyDialog from '@components/MyDialog';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { useDispatch } from 'react-redux';

import MinibarTable from './components/Table/MinibarTable';
import AddProductDialog from './components/AddProductDialog';
import FillOutForm from './components/FillOutForm';
import {
  columns,
  productFilterConditionList,
  radioOptions,
  mobileColumns
} from './enhance';
import EditCompanyRateDialog from './components/EditCompanyRateDialog';
import EditPriceInfoDialog from './components/EditPriceInfo';
import ExcelRegisterDialog from './components/ExcelRegister';
import EditManagementActions from './components/EditManagementActions';
import ExcelBulkDownload from './components/ExcelBulkDownload';

const MinibarOrder = () => {
  const { t } = useTranslation();
  const isSm = useResponsive('down', 640);
  const isMd = useResponsive('down', 1000);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState();
  const [openStock, setOpenStock] = useState(false);
  const [openPriceInfo, setOpenPriceInfo] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [excelRegister, setExcelRegister] = useState({ open: false, type: '' });
  const [openExcelBulkDownload, setOpenExcelBulkDownload] = useState(false);
  const [minibar, setMinibar] = useState({
    minibarCode: '',
    minibarNickname: ''
  });

  const [loading, setLoading] = useState(false);
  const [allZero, setAllZero] = useState(false);
  const [condition, setCondition] = useState('productName');
  const [param, setParam] = useState({
    minibarCode: '',

    stockStatuses: ['Adequate', 'Insufficient', 'NeedReview'],
    productName: null,
    barcode: null,
    branchPrice: '',
    page: 0
  });
  const [selectedFillout, setSelectedFillout] = useState({
    open: false,
    title: '',
    action: '',
    items: []
  });
  const [selected, setSelected] = useState({
    open: false,
    title: '',
    action: '',
    items: []
  });
  const [selectedItems, setSelectedItems] = useState([]);

  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const { items, pagination } = state;

  const getMinibarOrderList = async (params) => {
    if (params?.minibarCode) {
      const paramSubmit = { page: param?.page };
      paramSubmit.minibarCode = params?.minibarCode;
      paramSubmit.stockStatuses = params?.stockStatuses?.join(',');
      paramSubmit.productName = params?.productName;
      paramSubmit.barcode = params?.barcode;
      paramSubmit.branchPrice = params?.branchPrice;
      if (sortField) {
        // eslint-disable-next-line no-unneeded-ternary
        paramSubmit.isAsc = order === 'asc' ? true : false;
        paramSubmit.orderBy = sortField;
      }
      const rs = await minibarOrderService.getMinibarOrderList(paramSubmit);
      if (!rs?.error) {
        setState({
          ...state,
          items: rs?.data?.items || [],
          pagination: {
            ...pagination,
            page: rs.data?.page || 0,
            totalRow: rs.data?.totalRow || 0,
            count: rs.data?.totalPage || 0
          }
        });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    } else {
      setState({
        items: [],
        pagination: {
          page: 0,
          count: 0,
          totalRow: 0
        }
      });
    }
  };

  const handleSortingChange = async (accessor) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);

    const paramSubmit = { page: param?.page };
    paramSubmit.minibarCode = minibar?.minibarCode;
    paramSubmit.stockStatuses = param?.stockStatuses?.join(',');
    paramSubmit.productName = param?.productName;
    paramSubmit.barcode = param?.barcode;

    // eslint-disable-next-line no-unneeded-ternary
    paramSubmit.isAsc = sortOrder === 'asc' ? true : false;
    paramSubmit.orderBy = accessor || '';
    const rs = await minibarOrderService.getMinibarOrderList(paramSubmit);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs?.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const onSelectCheckbox = (item, e) => {
    setOpenPriceInfo(false);

    if (e.target.checked) {
      const minQuantity =
        item?.properStock < item?.stockQuantity
          ? 1
          : 0.7 * Number(item?.properStock || 0) -
            Number(item?.stockQuantity || 0);
      let oQuantity = 1;
      if (minQuantity < 0) {
        oQuantity = 1;
      } else if (minQuantity > parseInt(minQuantity, 10)) {
        oQuantity = parseInt(minQuantity + 1, 10);
      } else {
        oQuantity = parseInt(minQuantity, 10) || 1;
      }
      setSelectedItems(
        selectedItems?.concat([
          {
            ...item,
            orderQuantity: oQuantity
          }
        ])
      );
    } else {
      setSelectedItems(
        selectedItems?.filter((i) => i?.productCode !== item?.productCode)
      );
    }
  };

  const configColumns = columns(t);
  const mobileCols = mobileColumns(t);
  useEffect(() => {
    if (!openStock && !openPriceInfo) {
      setSelectedItems([]);
      getMinibarOrderList(param);
    }
  }, [openStock, openPriceInfo]);

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setMinibar({ ...minibar, minibarCode: query?.minibarCode || null });
    setCondition(
      (query?.barcode && 'barcode') ||
        (query?.branchPrice && 'branchPrice') ||
        'productName'
    );
    setParam({
      ...param,
      minibarCode: query?.minibarCode || null,
      stockStatuses: query?.stockStatuses?.split(',') || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: query?.productName,
      branchPrice: query?.branchPrice,
      barcode: query?.barcode,
      page: query?.page - 1 || 0
    });
    getMinibarOrderList({
      minibarCode: query?.minibarCode || null,
      stockStatuses: query?.stockStatuses?.split(',') || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: query?.productName || null,
      barcode: query?.barcode || null,
      page: query?.page - 1 || 0,
      branchPrice: query?.branchPrice,
      isAsc: order,
      orderBy: sortField
    });
  }, [location?.search]);

  useEffect(() => {
    setSelectedItems([]);
    getMinibarOrderList({
      minibarCode: minibar?.minibarCode || null,
      stockStatuses: param?.stockStatuses || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: param.productName || null,
      branchPrice: param?.branchPrice || null,
      barcode: param.barcode || null,
      page: param?.page
    });
  }, [minibar]);

  const onAction = (action, val) => {
    switch (action) {
      case 'display':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.display'),
          action: 'display',
          items: val
        });

        break;
      case 'withdrawal':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.withdrawal'),
          action: 'withdrawal',
          items: val
        });
        break;
      case 'dispose':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.dispose'),
          action: 'dispose',
          items: val
        });
        break;
      case 'loss':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.loss'),
          action: 'loss',
          items: val
        });
        break;
      default:
        break;
    }
    if (action === 'fillOut') {
      setSelectedFillout({
        ...selectedFillout,
        open: true,
        title: t('pages.product.feelout-ordersheet'),
        action
      });
    }
    if (action === 'selectCondition') {
      setCondition(val);
      setParam({
        ...param,
        productName: '',
        barcode: '',
        branchPrice: ''
      });
      return;
    }
    if (action === 'typingSearch') {
      setParam({
        ...param,
        [condition]: val
      });
      return;
    }
    if (action === 'changePage') {
      setParam({ ...param, page: val });
      if (sortField) {
        const query = objToParams(
          ignoreEmpty({
            ...param,
            page: val + 1,
            isAsc: order,
            orderBy: sortField
          })
        );
        navigate(query);
      } else {
        const query = objToParams(ignoreEmpty({ ...param, page: val + 1 }));
        getMinibarOrderList({ ...param, page: val });
        navigate(query);
      }
    }
    if (action === 'upload-success') getMinibarOrderList(param);
  };

  const handleSearch = () => {
    const dataParams = {
      page: 1,
      minibarCode: minibar?.minibarCode,
      stockStatuses: param?.stockStatuses,
      productName: param?.productName,
      branchPrice: param?.branchPrice,
      barcode: param?.barcode
    };
    const query = objToParams(ignoreEmpty(dataParams));
    setSelectedItems([]);
    getMinibarOrderList({ ...dataParams, page: 0 });
    navigate(query);
  };

  const handleChangeCheckbox = (val, e) => {
    if (e.target.checked) {
      setParam({
        ...param,
        stockStatuses:
          val === 'Total'
            ? ['Adequate', 'Insufficient', 'NeedReview']
            : param?.stockStatuses?.concat([val])
      });
    } else {
      setParam({
        ...param,
        stockStatuses:
          val === 'Total' ? [] : param?.stockStatuses?.filter((i) => i !== val)
      });
    }
  };
  const handleChangeIssueCheckbox = async (val, e, action) => {
    const sendValue = e.target.checked ? 'Y' : 'N';

    try {
      // Optimistic UI update
      const updatedItems = state.items.map((item) => {
        if (item.minibarStockCode === val.minibarStockCode) {
          return {
            ...item,
            hasIssue: sendValue
          };
        }
        return item;
      });

      setState({
        ...state,
        items: updatedItems
      });

      const rs =
        action === 'issueYn'
          ? await minibarOrderService.updateIssueYn(val?.minibarStockCode, {
              isIssued: sendValue
            })
          : await minibarOrderService.deleteMinibarStock(val?.minibarStockCode);

      if (!rs?.error) {
        // Actual API response received, no need to do anything
      } else {
        // API error, rollback to previous data
        setState({
          ...state,
          items: await getMinibarOrderList(param) // Assuming getMinibarOrderList returns the updated data
        });

        dispatch(setError(error2Text(rs?.error)));
      }
    } catch (error) {
      // Handle unexpected errors
      setState({
        ...state,
        items: await getMinibarOrderList(param)
      });

      dispatch(setError({ title: 'Unexpected error occurred' }));
    }
    setLoading(false);
  };
  const handleChangeDeleteCheckbox = async (val, e) => {
    const sendValue = e.target.checked ? 'N' : 'Y';

    const rs = await minibarOrderService.updateDeleteMinibarStock(
      val?.minibarStockCode,
      { deleteYn: sendValue }
    );
    if (!rs?.error) {
      getMinibarOrderList(param);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const handleConfirmFillOut = async () => {
    const arrSubmit = [];
    selectedItems.forEach((item) => {
      arrSubmit.push({
        productCode: item?.productCode || null,
        properStock: parseInt(item?.properStock, 10) || 0,
        orderQuantity: parseInt(item?.orderQuantity, 10) || 0
      });
    });
    const dataSubmit = {
      products: arrSubmit
    };
    const rs = await minibarOrderService.updateFillOut(
      minibar.minibarCode,
      dataSubmit
    );
    if (!rs?.error) {
      getMinibarOrderList(param);
      setSelected({ ...selected, open: false });
      setSelectedItems([]);
      setSelectedFillout({ ...selectedFillout, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const getModalButton = (item, actionType) => {
    switch (actionType) {
      default:
        return (
          <Typography
            onClick={
              item?.deleteYn !== 'Y' &&
              ((e) => {
                e.stopPropagation();
                setSelectedItem(item);
                setOpenPriceInfo(true);
              })
            }
            sx={{
              textDecoration: item?.deleteYn !== 'Y' && 'underline'
            }}
          >
            {item?.branchPrice || 0}
          </Typography>
        );
    }
  };

  const getFilterButton = () => {
    return <MoreVertIcon />;
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 2:
        return loading ? (
          <CircularProgress />
        ) : (
          <Checkbox
            checked={
              selectedItems?.filter((i) => i?.productCode === item?.productCode)
                ?.length
            }
            onChange={(e) => onSelectCheckbox(item, e)}
          />
        );
      case 3:
        return (
          <Stack direction="row" alignItems="center">
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('display', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.display')}
            </Button>
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
              onClick={async (e) => {
                e.stopPropagation();
                onAction('withdrawal', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.withdrawal')}
            </Button>
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('dispose', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.dispose')}
            </Button>
            <Button
              sx={{ ml: 1.5, whiteSpace: 'nowrap', height: 35 }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('loss', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.loss')}
            </Button>
          </Stack>
        );
      case 'properStock':
        return (
          <Typography
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setOpenStock(true);
            }}
            sx={{
              textDecoration: 'underline'
            }}
          >
            {actionType === 'stock'
              ? item?.stockQuantity || 0
              : item?.properStock || 0}
          </Typography>
        );
      case 'issueYn':
        return loading ? (
          <CircularProgress />
        ) : (
          <Checkbox
            checked={item?.hasIssue === 'Y'}
            onChange={(e) => handleChangeIssueCheckbox(item, e, 'issueYn')}
          />
        );
      case 'progressYn':
        return (
          <Checkbox
            checked={item?.deleteYn === 'N'}
            onChange={(e) => handleChangeDeleteCheckbox(item, e, 'progressYn')}
          />
        );
      default:
        return (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenConfirmDialog(true);
              setSelectedItem(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
    }
  };

  const handleDeleteMinibarStock = async () => {
    const result = await minibarOrderService.deleteMinibarStock(
      selectedItem.minibarStockCode
    );
    if (result?.error) dispatch(setError({ title: 'Something went wrong' }));
    else {
      handleSearch();
    }
    setOpenConfirmDialog(false);
  };

  const handleSetAllZero = async () => {
    const result = await minibarOrderService.setAllZero(minibar.minibarCode, {
      resetSoldAmount: 0
    });
    if (result?.error) dispatch(setError({ title: 'Something went wrong' }));
    else {
      getMinibarOrderList(param);
      toast.success(t('pages.minibarOrder.messages.success.setAllZero'), {
        autoClose: 1000
      });
    }
    setAllZero(false);
  };

  // export minibarOrder details
  const handleDownloadExcelByMinibar = async () => {
    const rs = await minibarOrderService.exportMinibarOrder(param.minibarCode);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer
      title={t('menusidebar.product-management.minibar-order')}
      content="Minibar Order page"
    >
      <Stack
        sx={{
          p: '10px',
          borderRadius: '12px',
          bgcolor: '#F8F8F8',
          flexWrap: 'wrap',
          fontSize: 12
        }}
        direction={isSm ? 'column' : 'row'}
        gap={1.5}

        // alignItems={isSm ? 'center' : ''}
      >
        <Stack>
          <MinibarAutoCompleteV2
            required
            minibar={minibar}
            setMinibar={(item) => {
              setMinibar(item);
              setParam({ ...param, minibarCode: item?.minibarCode });
            }}
            onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
        </Stack>

        <Stack direction="row" gap={2}>
          <Stack>
            <MySelect
              label={t('pages.product.condition')}
              value={condition}
              data={productFilterConditionList}
              onChange={(e) => onAction('selectCondition', e.target.value)}
              sx={{ maxWidth: isSm ? '50%' : '100%', label: { mr: 1 } }}
            />
          </Stack>

          <Stack>
            <MyTextField
              placeholder={t('pages.product.input-keyword')}
              value={param[condition] || ''}
              onChange={(e) => onAction('typingSearch', e.target.value)}
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              sx={{ maxWidth: isSm ? '80%' : 'auto' }}
            />
          </Stack>
        </Stack>
        <Stack>
          <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              label={t('labels.type')}
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: isSm ? 10 : 13,
                  whiteSpace: 'nowrap',
                  mr: isSm ? 1 : 2
                }
              }}
              control={
                <Stack flexDirection="row">
                  {radioOptions(t).map(({ value, label }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            value === 'Total'
                              ? param?.stockStatuses?.length === 3
                              : param?.stockStatuses?.indexOf(value) > -1
                          }
                          onChange={(e) => handleChangeCheckbox(value, e)}
                        />
                      }
                      label={label}
                    />
                  ))}
                </Stack>
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            disabled={!minibar}
            variant="contained"
            onClick={handleSearch}
            sx={{ ml: 1.5, width: isSm && 30, height: isSm && 30 }}
          >
            {t('button.search')}
          </Button>
        </Stack>
      </Stack>

      {/* <div style={{ overflowX: 'auto' }}> */}
      <Stack
        sx={{
          p: '10px',
          borderRadius: '12px',

          fontSize: 12,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
        direction="row"
        gap={1}
      >
        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={() => setOpenAddProduct(true)}
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {t('pages.client-order.addProduct')}
        </Button>
        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={() =>
            setExcelRegister({
              ...excelRegister,
              open: true,
              type: 'product'
            })
          }
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {isMd ? t('button.bulk-register-mobile') : t('button.bulk-register')}
        </Button>

        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={() =>
            setExcelRegister({
              ...excelRegister,
              open: true,
              type: 'shelf'
            })
          }
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {isMd
            ? t('button.bulk-shelf-register-mobile')
            : t('button.bulk-shelf-register')}
        </Button>
        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={() => setAllZero(true)}
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {t('button.reset-sales-quantity')}
        </Button>

        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={handleDownloadExcelByMinibar}
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {t('button.downloadExcel')}
        </Button>
        <Button
          variant="outlined"
          onClick={() => setOpenExcelBulkDownload(true)}
          sx={{
            ml: isSm ? 0.5 : 1.5,
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {t('pages.minibarOrder.excelBulkDownload')}
        </Button>
        <Stack sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',

              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      {/* </div> */}

      <MinibarTable
        hover
        getActionButtons={getActionButtons}
        getModalButton={getModalButton}
        // onRowClick={handleRowClick}
        columns={isMd ? mobileCols : configColumns}
        sortField={sortField}
        order={order}
        handleSortingChange={handleSortingChange}
        data={items}
        rerender
        pagination={pagination}
        getFilterIcon={getFilterButton}
        paginationAdornment={{
          right: (
            <Button
              disabled={!selectedItems.length}
              onClick={() => onAction('fillOut')}
            >
              {t('pages.product.feelout-ordersheet')}
            </Button>
          )
        }}
        minusHeight={isMd ? -400 : 235}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
      {selectedFillout.open && (
        <MyDialog
          open={selectedFillout.open}
          title={selectedFillout.title}
          hasCancelButton
          hasCloseButton
          setOpen={
            selectedFillout?.open
              ? () => setSelectedFillout({ ...selectedFillout, open: false })
              : () => {}
          }
          onOk={() => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'save',
                action: () => handleConfirmFillOut()
              })
            );
          }}
        >
          <FillOutForm items={selectedItems} setItems={setSelectedItems} />
        </MyDialog>
      )}
      {selectedItem && openStock && (
        <EditCompanyRateDialog
          open={setOpenStock}
          setOpen={setOpenStock}
          data={selectedItem}
        />
      )}

      {selectedItem && openPriceInfo && (
        <EditPriceInfoDialog
          open={openPriceInfo}
          setOpen={setOpenPriceInfo}
          data={selectedItem}
        />
      )}
      {selected.open && (
        <EditManagementActions
          open={selected?.open}
          title={selected?.title}
          actionType={selected?.action}
          setOpen={() => setSelected({ ...selected, open: false })}
          data={selected?.items}
          getData={() => getMinibarOrderList(param)}
        />
      )}

      {openAddProduct && (
        <AddProductDialog
          open={openAddProduct}
          setOpen={setOpenAddProduct}
          minibarCode={minibar?.minibarCode || ''}
          onAction={onAction}
        />
      )}

      {openExcelBulkDownload && (
        <ExcelBulkDownload
          open={openExcelBulkDownload}
          setOpen={setOpenExcelBulkDownload}
        />
      )}

      <ExcelRegisterDialog
        open={excelRegister.open}
        setOpen={
          excelRegister?.open
            ? () => setExcelRegister({ ...excelRegister, open: false })
            : () => {}
        }
        minibarCode={param.minibarCode}
        key={excelRegister.open}
        onAction={onAction}
        type={excelRegister.type}
      />

      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onOk={handleDeleteMinibarStock}
        isAlert
        hasCancelButton
        title={t('common.confirm-delete-title', {
          item: selectedItem?.productName
        })}
      />
      <MyDialog
        open={allZero}
        setOpen={setAllZero}
        onOk={handleSetAllZero}
        okTitle={t('button.check')}
        hasCloseButton
        hasCancelButton
        title={t('pages.minibarOrder.dialog.title.setAllZero')}
      >
        {t('pages.minibarOrder.dialog.content.setAllZero')}
      </MyDialog>
    </HelmetContainer>
  );
};

export default MinibarOrder;
