import i18n from '@utils/i18n';
import { tableTypes } from '@constants/utils';

const t = i18n.getFixedT(null, null, 'pages.product');
const p = i18n.getFixedT(null, null, 'common');
export const columnCategory = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'categoryName',
    label: t('category'),
    cathover: true,
    minWidth: 160,
    align: 'center'
  },
  {
    label: p('edit'),
    minWidth: 30,
    align: 'center',
    type: tableTypes.ACTION
  }
  // {
  //   name: 'edit',
  //   label: t('code'),
  //   minWidth: 60,
  //   align: 'center'
  // }
];

export const categoryType = [
  { value: 'First', label: t('first-category') },
  { value: 'Second', label: t('second-category') },
  { value: 'Third', label: t('third-category') }
  // { value: 'Special', label: t('special-category') }
];
