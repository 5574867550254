import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';

import developmentServices from '@services/development';
import { setError } from '@store/reducers/appReducer';

const ChangeStatusDialog = ({ status, fileCode, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleUpdateStatus = async () => {
    const payload = {
      fileCode,
      comment,
      status
    };
    setLoading(true);
    const result = await developmentServices.updateStatus(fileCode, payload);
    setLoading(false);
    if (result.error) dispatch(setError({ title: 'Something went wrong' }));
    else props.setOpen(false);
  };

  return (
    <MyDialog
      hasCancelButton
      hasCloseButton
      sx={{ width: 420 }}
      disabled={!comment}
      onOk={handleUpdateStatus}
      loading={loading}
      {...props}
    >
      <Typography
        sx={{
          fontWeight: 'fontWeightBold',
          fontSize: 13,
          color: '#6F869C',
          mb: '10px'
        }}
      >
        {t('pages.development-request.comment')}
      </Typography>
      <MyTextField
        multiline
        rows={5}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        sx={{ width: '100%' }}
      />
    </MyDialog>
  );
};

export default ChangeStatusDialog;
