/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
// import SearchBar from '@components/SearchBar';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';

import useResponsive from '@hooks/useResponsive';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import minibarOrderService from '@services/minibarOrderService';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';

import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import MyDialog from '@components/MyDialog/new';

import MinibarTable from './components/Table/MinibarTable';
import {
  displayManagerViewColumns,
  productFilterConditionList,
  radioOptions,
  displayManagerMobileColumns
} from './enhance';

import InventoryActionsDialog from './components/DisplayManagerActionsDialog';
import EditManagementActions from './components/EditManagementActions';

const DisplayManagerView = () => {
  const { t } = useTranslation();
  const isSm = useResponsive('down', 640);
  const isMd = useResponsive('down', 1000);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState();
  const [openStock, setOpenStock] = useState(false);

  const [dialog, setDialog] = useState({ open: false });
  const [allZero, setAllZero] = useState(false);

  const [minibar, setMinibar] = useState({
    minibarCode: '',
    minibarNickname: ''
  });
  const [searchVisibility, setSearchVisibility] = useState('none');

  const [condition, setCondition] = useState('productName');
  const [param, setParam] = useState({
    minibarCode: '',

    stockStatuses: ['Adequate', 'Insufficient', 'NeedReview'],
    productName: null,
    barcode: null,
    branchPrice: '',
    page: 0
  });
  const [selectedFillout, setSelectedFillout] = useState({
    open: false,
    title: '',
    action: '',
    items: []
  });
  const [selected, setSelected] = useState({
    open: false,
    title: '',
    action: '',
    items: []
  });
  const [selectedItems, setSelectedItems] = useState([]);

  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const { items, pagination } = state;

  const getMinibarOrderList = async (params) => {
    if (params?.minibarCode) {
      const paramSubmit = { page: param?.page };
      paramSubmit.minibarCode = params?.minibarCode;
      paramSubmit.stockStatuses = params?.stockStatuses?.join(',');
      paramSubmit.productName = params?.productName;
      paramSubmit.barcode = params?.barcode;
      paramSubmit.branchPrice = params?.branchPrice;
      if (sortField) {
        // eslint-disable-next-line no-unneeded-ternary
        paramSubmit.isAsc = order === 'asc' ? true : false;
        paramSubmit.orderBy = sortField;
      }
      const rs = await minibarOrderService.getMinibarOrderList(paramSubmit);
      if (!rs?.error) {
        setState({
          ...state,
          items: rs?.data?.items?.filter((i) => i?.deleteYn === 'N') || [],
          pagination: {
            ...pagination,
            page: rs.data?.page || 0,
            totalRow: rs.data?.totalRow || 0,
            count: rs.data?.totalPage || 0
          }
        });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    } else {
      setState({
        items: [],
        pagination: {
          page: 0,
          count: 0,
          totalRow: 0
        }
      });
    }
  };

  const handleSetAllZero = async () => {
    const result = await minibarOrderService.setAllZero(minibar.minibarCode, {
      resetSoldAmount: 0
    });
    if (result?.error) dispatch(setError(error2Text(result?.error)));
    else {
      getMinibarOrderList(param);
      dispatch(
        setSuccess({ message: '해당 미니바 재고의 판매량이 수정되었습니다.' })
      );
    }
    setAllZero(false);
  };

  const handleSortingChange = async (accessor) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);

    const paramSubmit = { page: param?.page };
    paramSubmit.minibarCode = minibar?.minibarCode;
    paramSubmit.stockStatuses = param?.stockStatuses?.join(',');
    paramSubmit.productName = param?.productName;
    paramSubmit.barcode = param?.barcode;

    // eslint-disable-next-line no-unneeded-ternary
    paramSubmit.isAsc = sortOrder === 'asc' ? true : false;
    paramSubmit.orderBy = accessor || '';
    const rs = await minibarOrderService.getMinibarOrderList(paramSubmit);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs?.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const configColumns = displayManagerViewColumns(t);
  const mobileCols = displayManagerMobileColumns(t);

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setMinibar({ ...minibar, minibarCode: query?.minibarCode || null });
    setCondition(
      (query?.barcode && 'barcode') ||
        (query?.branchPrice && 'branchPrice') ||
        'productName'
    );
    setParam({
      ...param,
      minibarCode: query?.minibarCode || null,
      stockStatuses: query?.stockStatuses?.split(',') || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: query?.productName,
      branchPrice: query?.branchPrice,
      barcode: query?.barcode,
      page: query?.page - 1 || 0
    });
    getMinibarOrderList({
      minibarCode: query?.minibarCode || null,
      stockStatuses: query?.stockStatuses?.split(',') || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: query?.productName || null,
      barcode: query?.barcode || null,
      page: query?.page - 1 || 0,
      branchPrice: query?.branchPrice,
      isAsc: order,
      orderBy: sortField
    });
  }, [location?.search]);

  useEffect(() => {
    setSelectedItems([]);
    getMinibarOrderList({
      minibarCode: minibar?.minibarCode || null,
      stockStatuses: param?.stockStatuses || [
        'Adequate',
        'Insufficient',
        'NeedReview'
      ],
      productName: param.productName || null,
      branchPrice: param?.brtanchPrice || null,
      barcode: param.barcode || null,
      page: param?.page
    });
  }, [minibar]);

  const onAction = (action, val) => {
    switch (action) {
      case 'display':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.display'),
          action: 'display',
          items: val
        });

        break;
      case 'withdrawal':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.withdrawal'),
          action: 'withdrawal',
          items: val
        });
        break;
      case 'dispose':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.dispose'),
          action: 'dispose',
          items: val
        });
        break;
      case 'loss':
        setSelected({
          ...selected,
          open: true,
          title: t('pages.minibarOrder.loss'),
          action: 'loss',
          items: val
        });
        break;
      default:
        break;
    }
    if (action === 'fillOut') {
      setSelectedFillout({
        ...selectedFillout,
        open: true,
        title: t('pages.product.feelout-ordersheet'),
        action
      });
    }
    if (action === 'selectCondition') {
      setCondition(val);
      setParam({
        ...param,
        productName: '',
        barcode: '',
        branchPrice: ''
      });
      return;
    }
    if (action === 'typingSearch') {
      setParam({
        ...param,
        [condition]: val
      });
      return;
    }
    if (action === 'changePage') {
      setParam({ ...param, page: val });
      if (sortField) {
        const query = objToParams(
          ignoreEmpty({
            ...param,
            page: val + 1,
            isAsc: order,
            orderBy: sortField
          })
        );
        navigate(query);
      } else {
        const query = objToParams(ignoreEmpty({ ...param, page: val + 1 }));
        getMinibarOrderList({ ...param, page: val });
        navigate(query);
      }
    }
    if (action === 'upload-success') getMinibarOrderList(param);
  };

  const handleSearch = () => {
    const dataParams = {
      page: 1,
      minibarCode: minibar?.minibarCode,
      stockStatuses: param?.stockStatuses,
      productName: param?.productName,
      branchPrice: param?.branchPrice,
      barcode: param?.barcode
    };
    const query = objToParams(ignoreEmpty(dataParams));
    setSelectedItems([]);
    getMinibarOrderList({ ...dataParams, page: 0 });
    navigate(query);
  };

  const handleChangeCheckbox = (val, e) => {
    if (e.target.checked) {
      setParam({
        ...param,
        stockStatuses:
          val === 'Total'
            ? ['Adequate', 'Insufficient', 'NeedReview']
            : param?.stockStatuses?.concat([val])
      });
    } else {
      setParam({
        ...param,
        stockStatuses:
          val === 'Total' ? [] : param?.stockStatuses?.filter((i) => i !== val)
      });
    }
  };

  const handleConfirmFillOut = async () => {
    const arrSubmit = [];
    selectedItems.forEach((item) => {
      arrSubmit.push({
        productCode: item?.productCode || null,
        properStock: parseInt(item?.properStock, 10) || 0,
        orderQuantity: parseInt(item?.orderQuantity, 10) || 0
      });
    });
    const dataSubmit = {
      products: arrSubmit
    };
    const rs = await minibarOrderService.updateFillOut(
      minibar.minibarCode,
      dataSubmit
    );
    if (!rs?.error) {
      getMinibarOrderList(param);
      setSelected({ ...selected, open: false });
      setSelectedItems([]);
      setSelectedFillout({ ...selectedFillout, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 3:
        return (
          <Stack
            direction="row"
            alignItems="center"
            sx={{ gap: { md: 1, xs: 0.5 } }}
          >
            <Button
              sx={{
                whiteSpace: 'nowrap',
                height: { md: 35, xs: 30 },
                borderRadius: 1
              }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('display', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.display')}
            </Button>
            <Button
              sx={{
                whiteSpace: 'nowrap',
                height: { md: 35, xs: 30 },
                borderRadius: 1
              }}
              onClick={async (e) => {
                e.stopPropagation();
                onAction('withdrawal', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.withdrawal')}
            </Button>
            <Button
              sx={{
                whiteSpace: 'nowrap',
                height: { md: 35, xs: 30 },
                borderRadius: 1
              }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('dispose', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.dispose')}
            </Button>
            <Button
              sx={{
                whiteSpace: 'nowrap',
                height: { md: 35, xs: 30 },
                borderRadius: 1
              }}
              onClick={(e) => {
                e.stopPropagation();
                onAction('loss', item);
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.loss')}
            </Button>
          </Stack>
        );

      case 4:
        return (
          <Stack
            direction="row"
            alignItems="center"
            sx={{ gap: { md: 1, xs: 0.5 } }}
          >
            <Button
              variant="contained"
              sx={{
                whiteSpace: 'nowrap',
                height: { md: 35, xs: 30 },
                borderRadius: 1
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItem(item);
                setDialog({ ...dialog, open: true });
              }}
              disabled={item?.deleteYn === 'Y'}
            >
              {t('pages.minibarOrder.inventory-management')}
            </Button>
          </Stack>
        );

      default:
        return (
          <Typography
            sx={{ textDecoration: 'underline' }}
            onClick={(e) => {
              e.stopPropagation();

              setSelectedItem(item);
              setDialog({ ...dialog, open: true });
            }}
          >
            {item?.stockQuantity}
          </Typography>
        );
    }
  };

  return (
    <HelmetContainer
      title={t('menusidebar.product-management.minibar-order')}
      content="Minibar Order page"
    >
      {/* pc search */}
      <Stack
        sx={{
          p: '10px',
          display: { md: 'flex', xs: 'none' },
          borderRadius: '12px',
          bgcolor: '#F8F8F8',
          flexWrap: 'wrap',
          fontSize: 12
        }}
        direction={isSm ? 'column' : 'row'}
        gap={1.5}

        // alignItems={isSm ? 'center' : ''}
      >
        <Stack>
          <MinibarAutoCompleteV2
            required
            minibar={minibar}
            setMinibar={(item) => {
              setMinibar(item);
              setParam({ ...param, minibarCode: item?.minibarCode });
            }}
            onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
        </Stack>

        <Stack direction="row" gap={2}>
          <Stack>
            <MySelect
              label={t('pages.product.condition')}
              value={condition}
              data={productFilterConditionList}
              onChange={(e) => onAction('selectCondition', e.target.value)}
              sx={{ maxWidth: isSm ? '50%' : '100%', label: { mr: 1 } }}
            />
          </Stack>

          <Stack>
            <MyTextField
              placeholder={t('pages.product.input-keyword')}
              value={param[condition] || ''}
              onChange={(e) => onAction('typingSearch', e.target.value)}
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              sx={{ maxWidth: isSm ? '80%' : 'auto' }}
            />
          </Stack>
        </Stack>
        <Stack>
          <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              label={t('labels.type')}
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: isSm ? 10 : 13,
                  whiteSpace: 'nowrap',
                  mr: isSm ? 1 : 2
                }
              }}
              control={
                <Stack flexDirection="row">
                  {radioOptions(t).map(({ value, label }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            value === 'Total'
                              ? param?.stockStatuses?.length === 3
                              : param?.stockStatuses?.indexOf(value) > -1
                          }
                          onChange={(e) => handleChangeCheckbox(value, e)}
                        />
                      }
                      label={label}
                    />
                  ))}
                </Stack>
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            disabled={!minibar}
            variant="contained"
            onClick={handleSearch}
            sx={{ ml: 1.5, width: isSm && 30, height: isSm && 30 }}
          >
            {t('button.search')}
          </Button>
        </Stack>
      </Stack>

      {/* mobile search  */}
      <Stack
        sx={{
          width: '100%',
          p: 1.5,

          borderRadius: 1,
          display: { md: 'none', xs: 'flex' },
          bgcolor: '#F8F8F8',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            flexDirection: 'row',
            gap: 1.5
          }}
        >
          <Stack sx={{ position: 'relative', gap: 1, width: '100%' }}>
            <Stack>
              <MinibarAutoCompleteV2
                required
                minibar={minibar}
                setMinibar={(item) => {
                  setMinibar(item);
                  setParam({ ...param, minibarCode: item?.minibarCode });
                }}
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  control: { width: '100%' },
                  label: {
                    width: '100%',
                    mx: 0,
                    '.MuiTypography-root': {
                      color: '#6F869C',
                      fontWeight: 'fontWeightBold',
                      fontSize: { md: 13, xs: 10 },
                      minWidth: { xs: 24, md: 'auto' },
                      whiteSpace: 'nowrap'
                    }
                  }
                }}
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              />
            </Stack>

            <Stack direction="row" gap={1} sx={{ width: '100%' }}>
              <MySelect
                label={t('pages.product.condition')}
                value={condition}
                data={productFilterConditionList}
                onChange={(e) => onAction('selectCondition', e.target.value)}
                sx={{
                  width: 'auto',
                  minWidth: 'auto',
                  label: {
                    m: 0,
                    formControl: { width: '100%' },
                    '.MuiTypography-root': {
                      color: '#6F869C',
                      fontWeight: 'fontWeightBold',
                      fontSize: 13,
                      whiteSpace: 'nowrap',
                      minWidth: 24
                    }
                  }
                }}
              />

              <Stack sx={{ flexGrow: 1 }}>
                <MyTextField
                  placeholder={t('pages.product.input-keyword')}
                  value={param[condition] || ''}
                  onChange={(e) => onAction('typingSearch', e.target.value)}
                  onKeyPress={({ charCode }) =>
                    charCode === 13 && handleSearch()
                  }
                  sx={{ flexGrow: 1 }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ position: 'relative' }}
          >
            <Button
              disabled={!minibar}
              variant="contained"
              onClick={() => {
                handleSearch();
                setSearchVisibility('none');
              }}
              sx={{ minWidth: 60, height: 36, borderRadius: 1 }}
            >
              {t('button.search')}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/* </div> */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mt: 1,
          height: '100%',
          width: '100%'
        }}
      >
        <Button
          disabled={!minibar?.minibarCode}
          variant="outlined"
          onClick={() => setAllZero(true)}
          sx={{
            minWidth: 'auto',
            fontSize: isSm && 9,
            padding: 1,
            height: isSm && 35
          }}
        >
          {t('button.reset-sales-quantity')}
        </Button>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',

            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MinibarTable
        hover
        getActionButtons={getActionButtons}
        // onRowClick={handleRowClick}
        columns={isMd ? mobileCols : configColumns}
        sortField={sortField}
        order={order}
        handleSortingChange={handleSortingChange}
        data={items}
        rerender
        pagination={pagination}
        minusHeight={isMd ? 160 : 200}
        onChangePage={(e, page) => onAction('changePage', page)}
      />

      {selected.open && (
        <EditManagementActions
          open={selected?.open}
          title={selected?.title}
          actionType={selected?.action}
          setOpen={() => setSelected({ ...selected, open: false })}
          data={selected?.items}
          getData={() => getMinibarOrderList(param)}
        />
      )}

      {selectedItem && (
        <InventoryActionsDialog
          open={dialog.open}
          setOpen={() => {
            setDialog({ ...dialog, open: false });
            setSelectedItem(null);
          }}
          setSelectedItem={setSelectedItem}
          item={selectedItem}
          onAction={() => {
            getMinibarOrderList({ ...param, page: pagination?.page });
            setDialog({ ...dialog, open: false });
            setSelectedItem(null);
          }}
        />
      )}

      <MyDialog
        open={allZero}
        setOpen={setAllZero}
        onOk={handleSetAllZero}
        okTitle={t('button.check')}
        hasCloseButton
        hasCancelButton
        title={t('pages.minibarOrder.dialog.title.setAllZero')}
      >
        <Typography
          sx={{
            fontSize: { md: 13, xs: 12 },
            whiteSpace: 'pre-line',
            wordBreak: 'keep-all', // Prevent breaking words
            overflowWrap: 'break-word'
          }}
        >
          {t('pages.minibarOrder.dialog.content.setAllZero')}
        </Typography>
      </MyDialog>
    </HelmetContainer>
  );
};

export default DisplayManagerView;
