import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  styled
} from '@mui/material';

import Calendar from '@components/Calendar';
import MySelect from '@components/MySelect';

import MyTextField from '@components/MyTextField';

import i18n from '@utils/i18n';
// import MyAutocomplete from '@components/MyAutocomplete';
import { useDispatch } from 'react-redux';
import { Formatter } from '@utils/formatter';
import { onOpenConfirm } from '@store/reducers/appReducer';
import SearchBar from '@components/SearchBar';
// import InputMultipleFile from '@components/InputMultipleFile';
import InputFile from '@components/InputFile';
import RichEditor from '@components/MyRichtextEditor/CustomRichTextField';
import env from '@constants/env';
// import { TooltipContainer } from '@components/MyTable/GroupMultiHeaderTable';
import {
  calculateWidth,
  storeMinibarProductSchema,
  storeProductDetailImageSchema,
  storeProductDetailSchema
} from '../../enhance';
import AddProductDialog from './AddMinibarProductDialog';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const maxLengthOf = {
  productName: 48
};

const g = i18n.getFixedT(null, null, '');
const ProductDetailForm = (props) => {
  const {
    onAction,
    value,
    setValue,
    options,
    optionsData,
    setOptionsData,
    requiredFields,

    isValidOptions
  } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState({ open: false });
  const [isModified, setIsmodified] = useState(false);

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [errMg, setErrMg] = useState({
    productName: '',
    price: ''
  });

  const onChange = (e) => {
    setIsmodified(true);
    validateOnChange(e.target);

    if (e.target.files) {
      setValue({
        ...value,
        productImage: {
          name: e.target.files[0].name,
          src: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0]
        }
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleChangeOptions = (e) => {
    setOptionsData((prev) => ({ ...prev, [e?.target.name]: e?.target.value }));
  };

  const validateOnChange = ({ name, value: val }) => {
    let msg = '';
    if (!val && requiredFields.includes(name)) {
      msg = t('common.required');
    } else if (val.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  const checkValidate = () => {
    let isValidate = true;
    const vldErr = { ...errMg };
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });
    setErrMg(vldErr);
    return isValidate;
  };

  const isValidToSubmit = () => {
    let isValidate = true;
    requiredFields.forEach((field) => {
      if (!value?.[field]) {
        isValidate = false;
      }
    });
    if (!isValidOptions()) isValidate = false;
    if (!value?.productImage?.file && !value?.imageUrl) isValidate = false;
    return isValidate;
  };

  const handleAction = (action) => {
    if (checkValidate(value)) {
      dispatch(
        onOpenConfirm({
          open: true,
          type: action === 'register' ? 'save' : 'modify',
          action: () => onAction(action)
        })
      );
    }
  };
  const handleRegisterMinibarProduct = (action, item) => {
    if (item) {
      setValue({
        ...value,
        minibarProduct: {
          productName: item?.productName,
          productCode: item?.productCode,
          price: item?.masterPrice,
          imgUrl: item?.imgUrl
        }
      });
    }
  };

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={5}
        mb={1}
        sx={{ display: 'flex', width: '100%', pr: 2, alignItems: 'center' }}
      >
        <SearchBar sx={{ flexGrow: 1 }}>
          {' '}
          <IconButton
            onClick={() => onAction('cancel')}
            sx={{
              display: 'flex',
              gap: 0.5,
              ':hover': {
                backgroundColor: 'transparent'
              }
            }}
            disableRipple
            disableTouchRipple
          >
            <ArrowBackIosNewIcon
              sx={{ height: '20px', color: 'black', fontWeight: 500 }}
            />
          </IconButton>
          <Stack sx={{ flexDirection: 'row', gap: 5, ml: 5 }}>
            <Typography>
              {t('pages.pre-order.industry')}:{' '}
              <span style={{ marginLeft: '4px', fontWeight: 500 }}>
                {location?.state?.storeTypeName}
              </span>
            </Typography>
            <Typography>
              {t('pages.pre-order.store')}:{' '}
              <span style={{ marginLeft: '4px', fontWeight: 500 }}>
                {' '}
                {location?.state?.storeName}
              </span>
            </Typography>
          </Stack>
        </SearchBar>

        {location?.state?.action === 'register' ? (
          <Button
            variant="contained"
            sx={{ minWidth: '100px' }}
            onClick={() => handleAction('register')}
            disabled={!isValidToSubmit()}
          >
            {t('button.save')}
          </Button>
        ) : (
          <Button
            sx={{ minWidth: '100px' }}
            variant={isModified ? 'contained' : 'outlined'}
            onClick={() => handleAction('modification')}
            disabled={!isValidToSubmit()}
          >
            {isModified ? t('button.save') : t('pages.inventory.modify')}
          </Button>
        )}
      </Stack>

      <Stack sx={{ width: '100%', gap: 1.5, mt: 1.5 }}>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            {/* price and product fields */}
            <Stack
              flexDirection="row"
              flexWrap="wrap"
              sx={{ width: '50%', borderRight: '1px solid #D3D3D3' }}
            >
              {storeProductDetailSchema.map((item) => {
                return (
                  <Stack
                    direction="row"
                    sx={{
                      borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                      width: {
                        xs: '100%',
                        md: `${calculateWidth(item.grid)}`
                      },
                      flexGrow: 1,
                      borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                      ...item.border
                    }}
                    key={`${item?.name}-${item?.label}`}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: '0 10px',
                        borderRight: '1px solid #D3D3D3',
                        ...labelStyle
                      }}
                    >
                      <Typography
                        sx={{
                          minWidth: '140px',
                          fontSize: 13,
                          fontWeight: 400,
                          color: '#6F869C',
                          textAlign: 'center'
                        }}
                      >
                        {item.label}
                        {(requiredFields.includes(item.name) ||
                          item?.required) && (
                          <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        )}
                      </Typography>
                    </Stack>

                    <RenderField
                      onChangeInput={(e) => onChange(e, item?.type)}
                      errMg={errMg?.[item?.name]}
                      {...item}
                      disabled={item?.disabled}
                      value={value}
                      editorRef={editorRef}
                    />
                  </Stack>
                );
              })}
            </Stack>
            {/* minibarProductFields */}
            <Stack flexDirection="row" flexWrap="wrap" sx={{ width: '50%' }}>
              {value?.minibarProduct &&
                storeMinibarProductSchema.map((item) => {
                  return (
                    <Stack
                      direction="row"
                      sx={{
                        borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                        width: {
                          xs: '100%',
                          md: `${calculateWidth(item.grid)}`
                        },
                        flexGrow: 1,
                        borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                        ...item.border
                      }}
                      key={`${item?.name}-${item?.label}`}
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          padding: '0 10px',
                          borderRight: '1px solid #D3D3D3',
                          ...labelStyle
                        }}
                      >
                        <Typography
                          sx={{
                            minWidth: '140px',
                            fontSize: 13,
                            fontWeight: 400,
                            color: '#6F869C',
                            textAlign: 'center'
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Stack>

                      <RenderField
                        onChangeInput={(e) => onChange(e, item?.type)}
                        errMg={errMg?.[item?.name]}
                        {...item}
                        disabled={item?.disabled}
                        value={value?.minibarProduct}
                        editorRef={editorRef}
                      />
                      {item?.name === 'productName' && (
                        <Stack
                          sx={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 10px'
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setDialog({ ...dialog, open: true })}
                          >
                            {t('pages.inventory.product')} {t('button.add')}
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              {!value?.minibarProduct && (
                <Stack
                  sx={{
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 10px',
                    borderBottom: '1px solid #D3D3D3'
                  }}
                >
                  <Stack
                    sx={{
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0 10px'
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setDialog({ ...dialog, open: true })}
                    >
                      {t('pages.inventory.product')} {t('button.add')}
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack flexDirection="row" flexWrap="wrap">
            {/* options */}
            {options?.length > 0 &&
              options.map((item) => {
                return (
                  <Stack
                    direction="row"
                    sx={{
                      borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                      width: {
                        xs: '100%',
                        md: `${calculateWidth(item.grid)}`
                      },
                      flexGrow: 1,
                      borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                      ...item.border
                    }}
                    key={`${item?.name}-${item?.label}`}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: '0 10px',
                        borderRight: '1px solid #D3D3D3',
                        ...labelStyle
                      }}
                    >
                      <Typography
                        sx={{
                          minWidth: '140px',
                          fontSize: 13,
                          fontWeight: 400,
                          color: '#6F869C',
                          textAlign: 'center'
                        }}
                      >
                        {item.label}

                        <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                      </Typography>
                    </Stack>

                    <RenderField
                      onChangeInput={(e) => handleChangeOptions(e, item?.type)}
                      errMg={errMg?.[item?.name]}
                      name={item?.name}
                      type={item?.type}
                      fullWidth
                      disabled={item?.disabled}
                      data={item?.options ? item?.options : []}
                      value={optionsData}
                      editorRef={editorRef}
                    />
                  </Stack>
                );
              })}
          </Stack>
          <Stack flexDirection="row" flexWrap="wrap">
            {/* options */}
            {storeProductDetailImageSchema.map((item) => {
              return (
                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: {
                      xs: '100%',
                      md: `${calculateWidth(item.grid)}`
                    },
                    flexGrow: 1,
                    borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                    ...item.border
                  }}
                  key={`${item?.name}-${item?.label}`}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      padding: '0 10px',
                      borderRight: '1px solid #D3D3D3',
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        minWidth: '140px',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#6F869C',
                        textAlign: 'center'
                      }}
                    >
                      {item.label}

                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexGrow: 1, px: 1.25 }}>
                    {' '}
                    <RenderField
                      onChangeInput={(e) => onChange(e, item?.type)}
                      errMg={errMg?.[item?.name]}
                      {...item}
                      disabled={item?.disabled}
                      data={item?.options ? item?.options : []}
                      value={value}
                      editorRef={editorRef}
                    />{' '}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          <AddProductDialog
            open={dialog.open}
            setOpen={() => setDialog({ ...dialog, open: false })}
            onAction={handleRegisterMinibarProduct}
          />
        </Container>
      </Stack>
    </>
  );
};

const RenderField = ({ data, value, errMg, editorRef, sx, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const productCode = pathname[pathname.length - 1];
  const { formatTime } = Formatter;
  if (type === 'upload') {
    if (item?.disabled)
      return (
        <Stack
          sx={{
            flexGrow: 1,
            maxHeight: '150px',
            py: 1,
            pl: '16px',
            justifyContent: 'center'
          }}
        >
          <img
            src={`${env.s3BaseUrl}/${value?.imgUrl}`}
            alt="minibar product"
            style={{ width: '100px', height: '100px', objectFit: 'contain' }}
          />
        </Stack>
      );
    return (
      <Stack
        sx={{
          flexGrow: 1,
          maxHeight: '150px',
          py: 1,
          justifyContent: 'center'
        }}
      >
        <InputFile
          dataFile={value?.productImage}
          accept="image/png, image/gif, image/jpeg"
          onChange={onChangeInput}
          sx={{ image: { height: '100px', width: '100px' } }}
        />
      </Stack>
    );
  }
  if (type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          value={value[item.name]}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ minWidth: '100%' }}
        />
      </Stack>
    );
  if (type === 'textarea') {
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <RichEditor
          value={value.description}
          errMg={errMg}
          storeCode={location?.state?.storeCode}
          productCode={productCode}
          ref={editorRef}
          setEditorValue={(val) =>
            onChangeInput({ target: { name: 'description', value: val } })
          }
        />
      </Stack>
    );
  }
  if (type === 'date') {
    return (
      <Stack
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          gap: 4,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <FormControlLabel
          key={`${item?.name}-${label}`}
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              mr: 2
            }
          }}
          control={
            <Calendar
              date={value?.[item?.name] ? new Date(value?.[item?.name]) : null}
              sx={{
                inputStyle: {
                  width: '100%',
                  ...((!value?.hasDeadline ||
                    value?.departmentType === 'TalentPool') && {
                    backgroundColor: '#f2f2f2'
                  })
                }
              }}
              setDate={(e) =>
                onChangeInput({
                  target: {
                    name: item?.name,
                    value: formatTime(e, 'YYYY.MM.D')
                  }
                })
              }
              disabled={
                !value?.hasDeadline || value?.departmentType === 'TalentPool'
              }
            />
          }
          label={g('common.period')}
        />
        <FormControlLabel
          key={`${item?.name}-${label}`}
          control={
            <Checkbox
              disableRipple
              checked={!value?.hasDeadline}
              onChange={(e) =>
                onChangeInput({
                  target: {
                    name: 'hasDeadline',
                    value: !e?.target?.checked
                  }
                })
              }
            />
          }
          label={g('pages.homepage.always')}
        />
      </Stack>
    );
  }
  return (
    <MyTextField
      value={
        item?.formatter ? item?.formatter(value[item.name]) : value[item.name]
      }
      errMg={errMg}
      onChange={onChangeInput}
      size="small"
      {...rest}
      sx={{ ...inputStyle, ...sx }}
      textFieldStyle={{
        borderRadius: 0,
        ...(item?.disabled && { backgroundColor: '#f2f2f2' })
        // '& fieldset': { border: 'none' }
      }}
      typeValue={type === 'number' && 'onlyNumber'}
    />
  );
};

export default ProductDetailForm;
