import React, { useState } from 'react';
import qs from 'qs';

import { Box, Typography } from '@mui/material';

import MyDialog from '@components/MyDialog';
import env from '@constants/env';
import { useSelector } from 'react-redux';

/**
 * see more https://developers.google.com/maps/documentation/embed/embedding-map
 */
const GeoMapDialog = ({ item, dialogTitle, children, iframeProps, sx }) => {
  const { lang } = useSelector((state) => state.app);
  const [open, setOpen] = useState(false);

  const handleGeoClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const parameters = qs.stringify({
    key: env.ggMapAPIKey,
    q: `${item?.latitude},${item?.longitude}`,
    language: lang
  });

  return (
    <Box>
      <Typography
        sx={{
          color: 'primary.darker',
          fontWeight: 'fontWeightBold',
          fontSize: 14,
          textDecoration: 'underline',
          cursor: 'pointer',
          ...sx
        }}
        onClick={handleGeoClick}
      >
        {children}
      </Typography>
      <MyDialog
        open={open}
        setOpen={setOpen}
        title={dialogTitle}
        hideDialogButton
        hasCloseButton
        sx={{ width: 660 }}
      >
        <iframe
          title={dialogTitle}
          width={600}
          height={400}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed/v1/place?${parameters}`}
          {...iframeProps}
        />
      </MyDialog>
    </Box>
  );
};

export default GeoMapDialog;
