import React, { useRef, useState } from 'react';
import moment from 'moment';
import { DateRangePicker as RDRDateRangePicker } from 'react-date-range';
import { ko } from 'react-date-range/dist/locale';
import { Box, IconButton, InputAdornment, Menu } from '@mui/material';
import MyTextField from '@components/MyTextField';
import useResponsive from '@hooks/useResponsive';
import { ReactComponent as CalendarIcon } from '@svg/calendar.svg';
import { dateFormat } from '@constants/utils';
import './index.css';

const smallSizeStyle = {
  '.rdrDefinedRangesWrapper': {
    width: 170,
    display: 'none'
  },
  '.rdrStaticRangeLabel': {
    padding: '4px 10px'
  },
  '.rdrInputRanges': {
    fontSize: 12,
    padding: '4px 0',
    '.rdrInputRange': {
      padding: '4px 10px'
    }
  },
  '.rdrMonth': {
    padding: '0 0.8em 0.666em 0.8em',
    width: '18em',
    '.rdrDays': {
      '.rdrDay': {
        height: '2.4em',
        lineHeight: '2.4em',
        '&.rdrDayToday': {
          backgroundColor: 'rgb(61, 145, 255)',
          borderRadius: '50%', // Add background color
          '.rdrDayNumber': {
            span: { color: '#FFFFFF !important' }, // Optional: Change the text color to white for contrast
            '& ::after': {
              content: 'none' // Remove the line-through effect
            }
          }
        }
      }
    }
  },
  '.rdrMonthName': {
    padding: '0.5em'
  },
  '.rdrMonthAndYearWrapper': {
    height: 34,
    my: 1,
    pt: 0
  },
  '.rdrMonthAndYearPickers select': {
    padding: '6px 24px 6px 8px'
  },
  '.rdrDateDisplay': {
    margin: '0.4em'
  },
  '.rdrDateDisplayItem input': {
    height: '2.0em'
  }
};

const DateRangePicker = ({
  state,
  setState,
  sx,
  dateRangeProps,
  size,
  fullWidth = false,
  textFieldStyle,
  errorMessage
}) => {
  const isSm = useResponsive('down', 700);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const calendarRef = useRef(null);

  const handleClick = (event) => {
    calendarRef.current.focus();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (ranges) => {
    setState(ranges.selection);
  };

  return (
    <Box sx={{ ...sx }}>
      <MyTextField
        onClick={handleClick}
        sx={{
          width: 256,

          '.MuiInputBase-root': {
            bgcolor: 'common.white',
            height: 40
          },
          '.MuiInputBase-input': {
            textAlign: 'center',
            fontSize: {
              md: 14,

              xs: 12
            },
            color: 'text.primary'
          },
          ...sx?.textfield
        }}
        errMg={errorMessage}
        fullWidth={fullWidth}
        value={`${moment(state.startDate).format(dateFormat)}    ~    ${moment(
          state.endDate
        ).format(dateFormat)}`}
        placeholder={`${dateFormat}    ~    ${dateFormat}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton size="small" onClick={handleClick}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
          inputRef: calendarRef
        }}
        textFieldStyle={textFieldStyle}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            bgcolor: 'transparent',
            border: 'none',
            boxShadow: 'none'
          }
        }}
      >
        <Box
          sx={{
            border: '1px solid #D3D3D3',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px',
            overflow: 'hidden',
            bgcolor: 'common.white',
            ...(size === 'small' && smallSizeStyle),
            ...(isSm && smallSizeStyle)
          }}
        >
          <RDRDateRangePicker
            dateDisplayFormat="yyyy-MM-dd"
            locale={ko}
            onChange={handleChange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            weekStartsOn={1}
            showMonthArrow
            months={2}
            ranges={[state]}
            direction="horizontal"
            {...dateRangeProps}
          />
        </Box>
      </Menu>
    </Box>
  );
};

export default DateRangePicker;

// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRange } from "react-date-range";
// import { Box, TextField, ClickAwayListener } from "@mui/material";
// import moment from "moment";
// import { useState, useEffect, useRef } from "react";

// const PickDates = () => {
//   const pickerStyles = {
//     boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
//     //TO HIDE MONTH AND YEAR DROPDOWN
//     ".rdrMonthAndYearPickers": {
//       display: "none"
//     },

//     //TO HIDE THE DEFAULT DATE TEXT FIELDS PROVIDED BY THIS PACKAGE
//     ".rdrDateDisplayWrapper": {
//       display: "none"
//     },
//     //TO ALIGN MONTH ARROW WITH MONTH NAME
//     ".rdrMonthAndYearWrapper": {
//       alignItems: "unset",
//       height: "0px",
//       paddingTop: "20px"
//     },

//     //TO ALIGN CENTER MONTH AND YEAR NAME
//     ".rdrMonthName": {
//       textAlign: "center",
//       fontSize: "16px"
//     },

//     ".rdrWeekDays": {
//       fontSize: "14px"
//     },

//     //TO CHANGE THE RANGE BACKGROUND COLOR
//     ".rdrInRange": {
//       backgroundColor: "#3C4F5D"
//     },
//     //FOR RESPONSIVE DESIGN(SMALL DEVICES)
//     "@media (max-width: 600px)": {
//       ".rdrMonth,.rdrCalendarWrapper": {
//         width: "100%",
//         padding: 0
//       },
//       ".rdrWeekDays": {
//         marginTop: "24px"
//       },
//       width: "100%"
//     }
//   };

//   const [range, setRange] = useState({
//     startDate: null,
//     endDate: new Date(""), // This is required to overcome the bug of auto-range-selection
//     key: "selection"
//   });

//   const [showCalendar, setCalendarStatus] = useState(false);
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const startDateRef = useRef(null);
//   const endDateRef = useRef(null);
//   const [dateIdentifier, setDateIdentifier] = useState(null);

//   useEffect(() => {
//     setStartDate(
//       moment(range.startDate).format("YYYY-MM-DD") === "Invalid date"
//         ? ""
//         : moment(range.startDate).format("YYYY-MM-DD")
//     );
//     setEndDate(
//       moment(range.endDate).format("YYYY-MM-DD") === "Invalid date"
//         ? ""
//         : moment(range.endDate).format("YYYY-MM-DD")
//     );
//   }, [range]);

//   const handleChange = (item) => {
//     const { startDate, endDate } = item.selection;
//     if (dateIdentifier === "startDate") {
//       //IF USER TRIES TO SELECT START DATE GREATER THAN END DATE
//       if (range.endDate && startDate > range.endDate) {
//         setRange((prev) => ({
//           ...prev,
//           startDate: startDate,
//           endDate: new Date("")
//         }));
//         const newStartDate =
//           item.selection.startDate !== "Invalid date"
//             ? moment(item.selection.startDate).format("YYYY-MM-DD")
//             : null;

//         setStartDate(newStartDate);
//         setEndDate("");
//         setDateIdentifier("endDate");
//         if (endDateRef.current) {
//           endDateRef.current.focus();
//         }
//       } else {
//         setRange((prev) => ({ ...prev, startDate: startDate }));
//         const newStartDate =
//           item.selection.startDate !== "Invalid date"
//             ? moment(item.selection.startDate).format("YYYY-MM-DD")
//             : null;

//         setStartDate(newStartDate);
//         setDateIdentifier("endDate");
//         if (endDateRef.current) {
//           endDateRef.current.focus();
//         }
//       }
//     } else if (dateIdentifier === "endDate") {
//       if (range.startDate === startDate) {
//         //IF USER SELECTS THE END DATE EARLIER THAN START DATE
//         if (range.startDate && endDate < range.startDate) {
//           setRange((prev) => ({
//             ...prev,
//             startDate: endDate,
//             endDate: new Date("")
//           }));
//           const newStartDate =
//             item.selection.endDate !== "Invalid Date"
//               ? moment(item.selection.endDate).format("YYYY-MM-DD")
//               : null;

//           setStartDate(newStartDate);
//           setEndDate("");
//           if (endDateRef.current) {
//             endDateRef.current.focus();
//           }
//         } else {
//           setRange((prev) => ({ ...prev, endDate: endDate }));
//           const newEndDate =
//             item.selection.endDate !== "Invalid Date"
//               ? moment(item.selection.endDate).format("YYYY-MM-DD")
//               : null;
//           setEndDate(newEndDate);
//           setCalendarStatus(false);
//         }
//       } else {
//         // USER CLICKS ON THE END DATE FIELD TO CHANGE THE DATE BUT UPDATED DATE IS COMING IN START DATE FROM PACKAGE
//         if (range.startDate && startDate < range.startDate) {
//           setRange((prev) => ({
//             ...prev,
//             startDate: startDate,
//             endDate: new Date("")
//           }));
//           const newStartDate =
//             item.selection.startDate !== "Invalid Date"
//               ? moment(item.selection.startDate).format("YYYY-MM-DD")
//               : null;

//           setStartDate(newStartDate);
//           setEndDate("");
//           if (endDateRef.current) {
//             endDateRef.current.focus();
//           }
//         } else {
//           setRange((prev) => ({ ...prev, endDate: startDate }));
//           const newEndDate =
//             item.selection.startDate !== "Invalid Date"
//               ? moment(item.selection.startDate).format("YYYY-MM-DD")
//               : null;
//           setEndDate(newEndDate);
//         }
//       }
//     }
//   };

//   const handleClickAway = () => {
//     setCalendarStatus(false);
//   };

//   return (
//     <ClickAwayListener onClickAway={handleClickAway}>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center"
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "center"
//           }}
//         >
//           <TextField
//             inputRef={startDateRef}
//             margin="dense"
//             label="Start Date"
//             value={startDate}
//             size="small"
//             onClick={() => {
//               setCalendarStatus(true);
//               setDateIdentifier("startDate");
//             }}
//             InputProps={{
//               readOnly: true
//             }}
//           />

//           <TextField
//             inputRef={endDateRef}
//             margin="dense"
//             label="End Date"
//             value={endDate}
//             size="small"
//             InputProps={{
//               readOnly: true
//             }}
//             onClick={() => {
//               setCalendarStatus(true);
//               if (startDate !== "") {
//                 setDateIdentifier("endDate");
//               } else {
//                 setDateIdentifier("startDate");
//                 if (startDateRef.current) {
//                   startDateRef.current.focus();
//                 }
//               }
//             }}
//           />
//         </Box>
//         {showCalendar && (
//           <Box sx={pickerStyles}>
//             <DateRange
//               ranges={[range]}
//               onChange={(item) => handleChange(item)}
//               months={2} // CALENDAR WILL SHOW 2 MONTHS VIEW
//               direction={window.innerWidth <= 600 ? "vertical" : "horizontal"} // FOR SMALL DEVICES CALENDAR WILL BE SHOWN VERTICAL
//               scroll={{ enabled: window.innerWidth <= 600 ? true : false }}
//               minDate={new Date()} //USER CANNOT SELECT DATE PRIOR TO TODAY'S DATE
//               maxDate={moment().add(12, "months").toDate()} //USER WILL BE ABLE TO SELECT END DATE MAX WITHIN 1 YEAR.
//             />
//           </Box>
//         )}
//       </Box>
//     </ClickAwayListener>
//   );
// };

// export default PickDates;
