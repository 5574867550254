import React from 'react';
import MyTextField from '@components/MyTextField';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import { formatMoney } from '@utils/helpers';
import { pricePolicyTypeModifyColumns } from '../enhance';

const ProductTypeModification = (props) => {
  const { data, onChange } = props;
  const columns = pricePolicyTypeModifyColumns;
  const items = {};
  columns.forEach((col) => {
    if (col.children) {
      col.children.forEach((item) => {
        items[item.name] = data[item.name] || null;
      });
    } else {
      items[col.name] = data[col.name] || null;
    }
  });
  Object.entries(items).forEach((i) => {
    if (i[0] !== 'barcode' && i[0] !== 'productName') {
      items[i[0]] = (
        <MyTextField
          sx={{ width: 70 }}
          onChange={onChange}
          value={formatMoney(i[1] || 0)}
          name={i[0]}
          typeValue="onlyNumber"
          inputProps={{ style: { fontSize: 13 } }}
        />
      );
    }
  });
  return <GroupMultiHeaderTable data={[items]} columns={columns} />;
};

export default ProductTypeModification;
