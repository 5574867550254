/* eslint-disable unused-imports/no-unused-vars */
import i18n from '@utils/i18n';
import { tableTypes } from '@constants/utils';
import { formatMoney } from '@utils/helpers';
import { Formatter } from '@utils/formatter';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

const byMinibarT = i18n.getFixedT(null, null, 'pages.equipment-by-minibar');
const equipmentT = i18n.getFixedT(null, null, 'pages.equipment');

export const submitStatus = {
  FROM_INUSE_TO_REMAINING: 'from_in_use_to_remaining',
  FROM_INUSE_TO_INUSE: 'from_in_use_to_in_use',
  FROM_REMAINING_TO_INUSE: 'from_remaining_to_in_use'
};
export const radioOptions = [
  {
    value: submitStatus.FROM_INUSE_TO_INUSE,
    label: equipmentT('modal.radioOptions.in_use_to_in_use')
  },
  {
    value: submitStatus.FROM_REMAINING_TO_INUSE,
    label: equipmentT('modal.radioOptions.remaining_to_in_use')
  },
  {
    value: submitStatus.FROM_INUSE_TO_REMAINING,
    label: equipmentT('modal.radioOptions.in_use_to_remaining')
  }
];
// for table

export const columns = [
  {
    name: 'equipmentName',
    label: equipmentT('equipmentName'),
    minWidth: 170,
    align: 'center'
  },
  {
    name: 'equipmentStock',
    label: equipmentT('equipmentStock'),
    minWidth: 80,
    align: 'center',
    render: ({ equipmentStock }) => formatMoney(equipmentStock)
  },
  {
    name: 'usedY',
    label: equipmentT('eqmQuantity'),
    align: 'center',

    children: [
      {
        name: 'currentSaleTotal',
        label: equipmentT('ordered'),
        align: 'center',
        minWidth: 60,

        formatter: (item) => formatMoney(item)
      },

      {
        name: 'broken',
        label: equipmentT('smartCube'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'periodPreMonthSaleTotal',
        label: equipmentT('preProduction'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'compareSale',
        label: equipmentT('moitech'),
        align: 'center',
        minWidth: 60
      },
      {
        name: 'broken',
        label: equipmentT('manufacturer'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'currentSaleTotal',
        label: equipmentT('test'),
        align: 'center',
        minWidth: 60,

        formatter: (item) => formatMoney(item)
      },
      {
        name: 'periodPreMonthSaleTotal',
        label: equipmentT('others'),
        align: 'center',
        minWidth: 60,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'remainingQuantity',
    label: equipmentT('remainingEqm'),
    minWidth: 80,
    align: 'center'
  },

  // {
  //   name: 'equipmentContact',
  //   label: equipmentT('equipmentContact'),
  //   minWidth: 140,
  //   align: 'center'
  // },
  // {
  //   name: 'fileName',
  //   label: equipmentT('fileName'),
  //   minWidth: 240,
  //   align: 'center',
  //   type: tableTypes.FILE
  // },
  {
    label: equipmentT('manage'),
    minWidth: 60,
    align: 'center',
    actionType: 2,
    type: tableTypes.ACTION
  },
  {
    label: equipmentT('notes'),
    minWidth: 60,
    align: 'center',
    actionType: 1,
    type: tableTypes.ACTION
  }
];

export const minibarColumns = [
  {
    name: 'equipmentCode',
    label: byMinibarT('eqm-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'equipmentName',
    label: byMinibarT('eqm-name'),
    minWidth: 170,
    align: 'center'
  },
  {
    name: 'equipmentPrice',
    label: byMinibarT('unit-price'),
    minWidth: 120,
    align: 'center',
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'equipmentQuantity',
    label: byMinibarT('quantity'),
    minWidth: 80,
    align: 'center',
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'sumAmount',
    label: byMinibarT('price'),
    minWidth: 120,
    align: 'center',
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const historyColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'purchaseDate',
    label: equipmentT('orderDate'),
    minWidth: 100,
    align: 'center',
    render: (item) => Formatter.formatTime(item?.purchaseDate, 'YYYY-MM-DD')
  },
  {
    name: 'equipmentName',
    label: equipmentT('equipmentName'),
    minWidth: 170,
    align: 'center'
  },

  {
    name: 'additionalInfo',
    label: equipmentT('additionalInfo'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'contactName',
    label: equipmentT('management'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'contactPhone',
    label: equipmentT('contractManager'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'vendor',
    label: equipmentT('vendor'),
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'purchaseQuantity',
    label: byMinibarT('quantity'),
    minWidth: 80,
    align: 'center',
    render: (item) =>
      new Intl.NumberFormat('en-CA').format(item?.purchaseQuantity)
  },
  {
    name: 'purchasePrice',
    label: equipmentT('unitPrice'),
    minWidth: 80,
    align: 'center',
    render: (item) => new Intl.NumberFormat('en-CA').format(item?.purchasePrice)
  },
  {
    name: 'sum',
    label: equipmentT('supplyPrice'),
    minWidth: 80,
    align: 'center',
    render: (item) =>
      formatMoney(
        parseInt(item?.purchaseQuantity, 10) * parseInt(item?.purchasePrice, 10)
      ) || 0
  },
  {
    name: 'shippingFee',
    label: equipmentT('shippingFee'),
    minWidth: 80,
    align: 'center',
    render: (item) => new Intl.NumberFormat('en-CA').format(item?.shippingFee)
  },
  {
    name: 'purchaseTax',
    label: equipmentT('taxAmount'),
    minWidth: 80,
    align: 'center',
    render: (item) => new Intl.NumberFormat('en-CA').format(item?.purchaseTax)
  },
  {
    name: 'purchaseTotalPrice',
    label: equipmentT('sum'),
    minWidth: 100,
    align: 'center',
    render: (item) =>
      new Intl.NumberFormat('en-CA').format(item?.purchaseTotalPrice)
  },
  {
    name: 'quotationFile',
    label: equipmentT('estimate'),
    minWidth: 120,
    align: 'center',
    actionType: 3,
    type: tableTypes.ACTION
  },
  {
    name: 'invoiceFile',
    label: equipmentT('taxReceipt'),
    minWidth: 120,
    align: 'center',
    actionType: 2,
    type: tableTypes.ACTION
  },
  {
    name: 'orderStatus',
    label: equipmentT('orderStatus'),
    minWidth: 120,
    align: 'center',
    type: tableTypes.ACTION
  },
  {
    name: 'management',
    label: equipmentT('manage'),
    minWidth: 80,
    align: 'center',
    actionType: 1,
    type: tableTypes.ACTION
  }
];
// for dialog

export const rows = [
  {
    name: 'equipmentName',
    label: equipmentT('equipmentName'),
    key: 'equipmentName'
  },
  {
    name: 'equipmentCode',
    key: 'equipmentCode',
    label: equipmentT('equipmentCode'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'additionalInfo',
    label: equipmentT('additionalInfo'),
    key: 'additionalInfo'
  },

  { name: 'vendorName', key: 'equipmentSupplier', label: equipmentT('vendor') },
  {
    name: 'contactName',
    key: 'equipmentManager',
    label: equipmentT('management')
  },
  {
    name: 'contactNumber',
    key: 'equipmentContact',
    label: equipmentT('contractManager')
  },
  { name: 'file', key: 'quotationFile', label: equipmentT('estimate') }
];

export const editUnitrows = [
  {
    name: 'equipmentName',
    label: equipmentT('retainedQuantity'),
    key: 'equipmentName'
  },
  {
    name: 'equipmentCode',
    key: 'equipmentCode',
    label: equipmentT('usedQuantity'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'quantity',
    key: 'equipmentStock',
    label: equipmentT('ordered'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'price',
    key: 'equipmentPrice',
    label: equipmentT('usedY'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'supplier',
    key: 'equipmentSupplier',
    label: equipmentT('addingSoon'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  { name: 'manager', key: 'equipmentManager', label: equipmentT('broken') },
  {
    name: 'managerContact',
    key: 'equipmentContact',
    label: equipmentT('dispose')
  },
  {
    name: 'manager',
    key: 'equipmentManager',
    label: equipmentT('underRepair')
  },
  {
    name: 'managerContact',
    key: 'equipmentContact',
    label: equipmentT('installationTeam')
  },
  { name: 'manager', key: 'equipmentManager', label: equipmentT('smartCube') },
  {
    name: 'managerContact',
    key: 'equipmentContact',
    label: equipmentT('preProduction')
  },
  { name: 'manager', key: 'equipmentManager', label: equipmentT('moitech') },
  {
    name: 'managerContact',
    key: 'equipmentContact',
    label: equipmentT('manufacturer')
  },
  { name: 'manager', key: 'equipmentManager', label: equipmentT('test') },
  {
    name: 'managerContact',
    key: 'equipmentContact',
    label: equipmentT('others')
  }
];

export const buyEquipmentRows = [
  {
    name: 'equipmentName',
    label: equipmentT('equipmentName'),
    key: 'equipmentName',
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'additionalInfo',
    key: 'additionalInfo',
    label: equipmentT('additionalInfo'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  { name: 'purchaseDate', key: 'purchaseDate', label: equipmentT('orderDate') },
  {
    name: 'purchaseQuantity',
    key: 'purchaseQuantity',
    label: equipmentT('quantity')
  },
  {
    name: 'purchasePrice',
    key: 'purchasePrice',
    label: equipmentT('unitPrice')
  },
  {
    name: 'sum',
    key: 'sum',
    label: equipmentT('supplyPrice'),
    disabled: true,
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'shippingFee',
    key: 'equipmentContact',
    label: equipmentT('shippingFee')
  },
  {
    name: 'purchaseTax',
    key: 'taxAmount',
    label: equipmentT('taxAmount'),
    disabled: true,
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },
  {
    name: 'purchaseTotalPrice',
    key: 'purchaseTotalPrice',
    label: equipmentT('sum'),
    disabled: true,
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },

  {
    name: 'vendorName',
    key: 'equipmentContact',
    label: equipmentT('vendor'),
    editMode: false,
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    }
  },

  {
    name: 'contactName',
    key: 'contactName',
    label: equipmentT('management'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    },
    editMode: false
  },
  {
    name: 'contactNumber',
    key: 'contactNumber',
    label: equipmentT('contractManager'),
    props: {
      sx: {
        '.MuiInputBase-root': {
          bgcolor: '#f2f2f2'
        }
      }
    },
    editMode: false
  },
  {
    name: 'quotationFile',
    key: 'quotationFile',
    label: equipmentT('estimate')
  },
  { name: 'invoiceFile', key: 'taxReceipt', label: equipmentT('taxReceipt') }
];
// for styling

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120
    },
    td: {
      p: '10px 5px',
      width: 270
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));
