import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import NoData from '@components/NoData';

import { tableTypes } from '@constants/utils';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});

const TableCellsContainer = ({ index, columns, item, getActionButtons }) => {
  return columns.map(({ name, type, actionType, formatter }) => {
    const format = formatter ? formatter(item[name]) : item[name];

    const nameFormat =
      item?.[name] !== undefined && item?.[name] !== null ? format : '';

    switch (type) {
      case tableTypes.ACTION:
        return (
          <TableCell key={`${item?.id}action${actionType}`} sx={{ px: 1 }}>
            {getActionButtons(item, actionType, index)}
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={`${item?.id}${name}`}
            sx={{
              color: 'text.primary',
              fontSize: 15,
              '&:first-child': {
                bgcolor: '#FCFCFC'
              }
            }}
          >
            {name === '#' ? index + 1 : nameFormat}
          </TableCell>
        );
    }
  });
};

export default function PerformanceGoalTable({
  hover,
  data,
  columns,
  getActionButtons,
  emptyDescription,
  sx
}) {
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        component={BorderBox}
        sx={{ mt: '20px', mb: '30px', ...sx }}
      >
        <Table>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '50px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC'
            }}
          >
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.label}
                  align={col.align}
                  sx={{
                    minWidth: col.minWidth,
                    whiteSpace: 'pre-line'
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: 1.5,
                  height: '54px',
                  lineHeight: '22px'
                }
              }}
            >
              {data.map((item, index) => (
                <TableRow
                  key={item.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    'td:not(:last-child), th:not(:last-child)': {
                      borderRight: '1px solid #EEEEEE'
                    },
                    ':hover': hover && {
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: 'pointer'
                    }
                  }}
                >
                  <TableCellsContainer
                    index={index}
                    item={item}
                    columns={columns}
                    getActionButtons={getActionButtons}
                  />
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
