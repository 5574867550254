/* eslint-disable no-lonely-if */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sub } from 'date-fns';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { dateFormat } from '@constants/utils';
import {
  formatMoney,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Formatter } from '@utils/formatter';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MyDialog from '@components/MyDialog';
import MySelect from '@components/MySelect';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import HelmetContainer from '@components/HelmetContainer';
import DateRangePicker from '@components/DateRangePicker';

import GroupTableCustomize from '@components/MyTable/GroupTableCustomize';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import useResponsive from '@hooks/useResponsive';
import { useLocation, useNavigate } from 'react-router-dom';
import totalOrderService from '@services/totalOrder';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useDispatch } from 'react-redux';
import memberService from '@services/member';
import { styled } from '@mui/material/styles';
import DetailPayment from './components/DetailPayment';
import { columnMemberOrderHistory, searchOptions } from './enhance';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    border: '1px solid #EEEEEE',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],

    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

const OrderHistoryPage = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const navigate = useNavigate();
  const location = useLocation();
  const configColumn = columnMemberOrderHistory(t);
  const [param, setParam] = useState({
    minibarCode: null,
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    page: 0
  });
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    },
    totalAmount: 0,
    totalDiscount: 0,
    totalLosses: 0,
    totalOrder: 0,
    totalRefund: 0,
    totalSearchCount: 0
  });
  const [selected, setSelected] = useState(null);
  const { items, pagination } = state;
  const [cols, setcols] = useState([]);
  const dispatch = useDispatch();
  const isSm = useResponsive('down', 900);
  const [filteredCols, setFilteredCols] = useState([]);
  const [exportLoading, setExportLoading] = useState({
    default: false,
    accounting: false
  });
  const [showActionsBar, setShowActionsBar] = useState('none');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectOptionSearch, setSelectOptionSearch] = useState({
    key: 'phone',
    value: ''
  });
  const [minibars, setMinibars] = useState(null);
  const [isDateRangeOver, setIsDateRangeOver] = useState(false);
  const getListMemberOrderHistory = async (param) => {
    setLoading(true);
    const result = await memberService.getOrderHistory(param);
    const { listPurchase, ...rest } = result.data.data;
    if (!result.error) {
      let id = null;
      let no = 0;
      const arrItems =
        listPurchase?.items?.map((i, idx) => {
          if (id !== i?.id) {
            id = i?.id;
            no += 1;
          }
          return { index: idx, no, ...i };
        }) || [];
      setState({
        ...state,
        items: arrItems,
        pagination: {
          ...state.pagination,
          pageSize: listPurchase?.pageSize || 0,
          page: listPurchase?.page || 0,
          totalRow: listPurchase?.totalRow || 0,
          count: listPurchase?.totalPage || 0
        },
        ...rest
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const savedIds = localStorage.getItem('closed_member_cols');
    const idsArray = savedIds ? JSON.parse(savedIds) : [];
    if (idsArray) {
      const formattedCols = columnMemberOrderHistory(t)?.filter(
        (i) => !idsArray?.includes(i?.id)
      );
      const formattedFilterCols = columnMemberOrderHistory(t)?.map((i) => {
        if (idsArray?.includes(i?.id)) return { ...i, isOpen: false };
        return i;
      });
      setFilteredCols(formattedCols);
      setcols(formattedFilterCols);
    } else {
      setFilteredCols(columnMemberOrderHistory(t));
      setcols(columnMemberOrderHistory(t));
    }
  }, []);
  const handleSwitch = (id) => {
    const savedIds = localStorage.getItem('closed_member_cols');
    const idsArray = savedIds ? JSON.parse(savedIds) : [];
    setcols((prevcols) => {
      const updatedCols = prevcols.map((item) => {
        if (item.id === id) {
          if (item?.isOpen) {
            localStorage.setItem(
              'closed_member_cols',
              JSON.stringify([...idsArray, id])
            );
          } else {
            if (idsArray.includes(id)) {
              localStorage.setItem(
                'closed_member_cols',
                JSON.stringify(idsArray?.filter((i) => i !== id))
              );
            }
          }
          return { ...item, isOpen: !item?.isOpen };
        }
        return item;
      });

      const newCols = updatedCols.filter((item) => item?.isOpen === true);
      setFilteredCols(newCols);

      return updatedCols;
    });
  };
  const getPaymentHistory = async (id) => {
    const rs = await totalOrderService.getPaymentHistory(id);
    if (!rs.error) {
      let id = null;
      let no = 0;
      const arrItems =
        rs?.data?.data?.map((i, idx) => {
          if (id !== i?.id) {
            id = i?.id;
            no += 1;
          }
          return { index: idx, no, ...i };
        }) || [];
      let totalPaymentAmount = 0;
      arrItems.forEach((item, index) => {
        if (
          !arrItems[index - 1] ||
          item?.purchaseCode !== arrItems[index - 1]?.purchaseCode
        ) {
          totalPaymentAmount += item?.totalAmount;
        }
      });
      setSelected({
        open: true,
        title: (
          <span>
            <span>
              {t('pages.member.dialog.title.paymentDetail', {
                phoneMember:
                  arrItems[0]?.memberName ||
                  Formatter.fKrPhone(arrItems[0]?.phone)
              })}
            </span>
            <span style={{ padding: '0 10px' }}>-</span>
            <span>
              {t('pages.member.dialog.title.totalPaymentAmount', {
                t: Formatter.fNumber(totalPaymentAmount)
              })}
            </span>
          </span>
        ),

        items: arrItems
      });
    }
  };
  const exportOrderHistory = async () => {
    setExportLoading({ ...exportLoading, default: true });
    const rs = await memberService.exportOrderHistory(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setExportLoading({ ...exportLoading, default: false });
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      minibarCode,
      productName,
      phone,
      totalAmount,
      page
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      minibarCode: minibarCode || null,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      phone: phone || null,
      totalAmount: totalAmount || null,
      page: page - 1 || 0
    };
    setParam(dataParams);
    setSelectOptionSearch({
      ...selectOptionSearch,
      key:
        (phone && 'phone') ||
        (productName && 'productName') ||
        (totalAmount && 'totalAmount') ||
        'phone',
      value: phone || productName || totalAmount || ''
    });
    setMinibars({ ...minibars, minibarCode: minibarCode || null });
    getListMemberOrderHistory(dataParams);
  }, [location?.search]);

  const isDateRangeValid = (startDate, endDate) => {
    const maxAllowedDays = 30;
    const diffInDays = Math.ceil(
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
    );
    return diffInDays <= maxAllowedDays;
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onAction = async (action, value) => {
    if (action === 'search') {
      const { startDate, endDate, type, phone, productName, totalAmount } =
        param;
      const dataParams = {
        minibarCode: minibars?.minibarCode,
        startDate,
        endDate,
        type,
        phone,
        productName,
        totalAmount
      };
      const query = objToParams(
        ignoreEmpty({
          ...dataParams,
          page: 1
        })
      );

      getListMemberOrderHistory({ ...dataParams, page: 0 });
      navigate(query);
      setShowActionsBar('none');
    }
    if (action === 'searchTyping') {
      setSelectOptionSearch({
        key: value.target.name,
        value: value.target.value
      });
      setParam({
        ...param,
        [value.target.name]: value.target.value || null
      });
    }
    if (action === 'selectMinibar') {
      setParam({
        ...param,
        minibarCode: value?.minibarCode || null
      });
    }
    if (action === 'selectSearchType') {
      setSelectOptionSearch({
        key: value.target.value,
        value: ''
      });
      setParam({
        ...param,
        phone: null,
        productName: null,
        totalAmount: null
      });
    }
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setIsDateRangeOver(!isDateRangeValid(value.startDate, value.endDate));

      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }
    if (action === 'clickRowTable') {
      if (value?.isMember === 'Y') getPaymentHistory(value?.memberCode);
    }
    if (action === 'changePage') {
      setParam({ ...param, page: value });
      const query = objToParams(
        ignoreEmpty({
          ...param,
          page: value + 1
        })
      );
      navigate(query);
    }
    if (action === 'reset') {
      setSelectOptionSearch({
        key: 'phone',
        value: ''
      });
      setParam({
        ...param,
        productName: null,
        phone: null,
        barcode: null
      });
    }
  };

  return (
    <HelmetContainer
      title={t('pageTitle.memberOrderHistory')}
      content="Member order history page"
    >
      <Stack
        bgcolor="#F6FAFF"
        direction="row"
        alignItems="center"
        justifyContent="center"
        margin="20px 0"
        padding="15px 0"
        sx={{ mt: '-12px', p: { md: '15px 0', xs: '15px 10px' } }}
        borderRadius={1}
      >
        <Typography sx={{ fontSize: { xs: 12, md: 15 } }}>
          {t('pages.product.totalOrder')}
          {'  '}
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ fontSize: { xs: 12, md: 15 } }}
          >
            {formatMoney(state.totalOrder)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.cancel')}
          {'  '}
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ fontSize: { xs: 12, md: 15 } }}
          >
            {formatMoney(state.totalRefund)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.discount')}{' '}
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ fontSize: { xs: 12, md: 15 } }}
          >
            {formatMoney(state.totalDiscount)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.discard')}{' '}
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ fontSize: { xs: 12, md: 15 } }}
          >
            {formatMoney(state.totalDiscard)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.loss')}{' '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalLosses)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 20, fontWeight: 'bold', margin: '0 5px' }}>
            =
          </span>{' '}
          <Typography
            component="span"
            color="primary.darker"
            fontWeight="bold"
            fontSize={22}
          >
            {formatMoney(state.totalAmount)}
          </Typography>
          <Typography component="span" fontSize={22}>
            {t('pages.dashboard.won')}
          </Typography>
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          '#table-container': {
            borderTop: '0px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderRight: '1px solid #D3D3D3 !important',
            borderLeft: '1px solid #D3D3D3 !important',
            borderBottom: '1px solid #D3D3D3 !important'
          }
        }}
      >
        <SearchBar
          sx={{
            width: '100%',
            p: 1.25,
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
            border: '1px solid #D3D3D3',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            justifyContent: 'space-between'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
              display: { xs: 'none', md: 'flex' }
            }}
          >
            <FormControl sx={{ display: 'flex' }}>
              <FormControlLabel
                label={t('labels.period')}
                labelPlacement="start"
                sx={{
                  mr: 0,
                  ml: 0,
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    mr: 1
                  }
                }}
                control={
                  <DateRangePicker
                    state={{
                      startDate: new Date(param.startDate),
                      endDate: new Date(param.endDate),
                      key: 'selection'
                    }}
                    setState={(e) => onAction('selectPeriod', e)}
                    sx={{ width: '100%', textField: { maxWidth: '256px' } }}
                  />
                }
              />
            </FormControl>
            <MinibarAutoCompleteV2
              minibar={minibars}
              setMinibar={(minibar) => {
                setMinibars(minibar);
                setParam({ ...param, minibarCode: minibar?.minibarCode });
              }}
              onKeyPress={({ charCode }) =>
                charCode === 13 && onAction('search')
              }
              sx={{ minWidth: '200px', width: 'auto', mr: 2 }}
              ListboxProps={{ style: { maxHeight: { xs: 200, md: 400 } } }}
            />

            <Button
              variant="contained"
              sx={{ ml: 1.5, minWidth: '100px', borderRadius: 1, height: 40 }}
              onClick={() => onAction('search')}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : t('button.search')}
            </Button>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              gap: 2,
              justifyContent: { xs: 'space-between', md: 'flex-start' },
              width: { xs: '100%', md: 'auto' }
            }}
          >
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  sx={{
                    borderRadius: 1,
                    p: 1,
                    height: '40px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #D3D3D3'
                  }}
                  onClick={() =>
                    setShowActionsBar((prev) =>
                      prev === 'filter' ? 'none' : 'filter'
                    )
                  }
                >
                  <FilterAltIcon />
                </IconButton>
                <Paper
                  elevation={24}
                  sx={{
                    minWidth: '300px',
                    zIndex: 1300,
                    backgroundColor: '#FFFFFF',
                    display: showActionsBar === 'filter' ? 'flex' : 'none',
                    position: 'absolute',
                    right: 0,
                    ...(isSm && { left: 0 }),
                    border: '1px solid #D3D3D3',
                    top: '45px',
                    py: 1.5,
                    flexDirection: 'column',

                    borderRadius: 0.5
                  }}
                >
                  {/* header */}
                  <Stack
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      pb: 1.5,
                      px: 2,
                      alignItems: 'center',
                      borderBottom: '1px solid #D3D3D3',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        gap: 0.5,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FilterAltOutlinedIcon
                        sx={{ width: '20px', height: '20px' }}
                      />{' '}
                      <Typography
                        sx={{ fontSize: 14, color: '#212121', fontWeight: 700 }}
                      >
                        {t('button.search')} {t('common.options')}
                      </Typography>
                    </Stack>

                    <IconButton
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#212121',
                        fontSize: '15px',
                        p: 0
                      }}
                      onClick={() => setShowActionsBar('none')}
                    >
                      <CloseOutlinedIcon
                        sx={{ width: '20px', height: '20px' }}
                      />
                    </IconButton>
                  </Stack>
                  {/* search body */}
                  <Stack
                    sx={{
                      width: '100%',
                      maxHeight: 'calc(100vh - 360px)',
                      overflowY: 'scroll'
                    }}
                  >
                    {/* search period */}

                    <Stack
                      sx={{
                        width: '100%',
                        px: 2,
                        py: 1.5,
                        display: { md: 'none', xs: 'flex' },
                        borderBottom: '1px solid #EEEEEE'
                      }}
                    >
                      <FormControl sx={{ display: 'flex', width: '100%' }}>
                        <FormControlLabel
                          label={t('labels.period')}
                          labelPlacement="top"
                          sx={{
                            mr: 0,
                            ml: 0,

                            textAlign: 'left',
                            '.MuiTypography-root': {
                              color: '#6F869C',
                              width: '100%',
                              mb: 1,
                              textAlign: 'left',
                              fontWeight: 'fontWeightBold',
                              fontSize: 13,
                              whiteSpace: 'nowrap'
                            },
                            width: '100%'
                          }}
                          control={
                            <DateRangePicker
                              state={{
                                startDate: new Date(param.startDate),
                                endDate: new Date(param.endDate),
                                key: 'selection'
                              }}
                              setState={(e) => onAction('selectPeriod', e)}
                              sx={{
                                width: '100%',
                                textField: { minWidth: '256px', width: '100%' }
                              }}
                              size="small"
                              textFieldStyle={{
                                width: '100% !important',
                                flexGrow: 1
                              }}
                              fullWidth
                              dateRangeProps={{ direction: 'vertical' }}
                            />
                          }
                        />
                      </FormControl>
                    </Stack>

                    {/* minibar */}
                    <Stack
                      sx={{
                        width: '100%',
                        px: 2,
                        py: 1.5,
                        display: { md: 'none', xs: 'flex' },
                        borderBottom: '1px solid #EEEEEE'
                      }}
                    >
                      <Stack
                        sx={{
                          width: '100%',
                          mb: 1,
                          flexDirection: 'row'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#6F869C',
                            width: '100%',
                            textAlign: 'left',
                            fontWeight: 'fontWeightBold',
                            fontSize: 13,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {t('common.minibar')}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          width: '100%',

                          flexDirection: 'row'
                        }}
                      >
                        <MinibarAutoCompleteV2
                          label={false}
                          minibar={minibars}
                          setMinibar={(minibar) => {
                            setMinibars(minibar);
                            setParam({
                              ...param,
                              minibarCode: minibar?.minibarCode
                            });
                          }}
                          onKeyPress={({ charCode }) =>
                            charCode === 13 && onAction('search')
                          }
                          sx={{ minWidth: '200px', width: '100%' }}
                          ListboxProps={{
                            style: { maxHeight: { xs: 200, md: 400 } }
                          }}
                        />
                      </Stack>
                    </Stack>

                    <Stack
                      sx={{
                        width: '100%',
                        px: 2,
                        py: 1.5,

                        borderBottom: '1px solid #EEEEEE'
                      }}
                    >
                      <Stack
                        sx={{
                          width: '100%',
                          mb: 1,
                          flexDirection: 'row'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#6F869C',
                            width: '100%',
                            textAlign: 'left',
                            fontWeight: 'fontWeightBold',
                            fontSize: 13,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {t('labels.condition')}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          width: '100%',

                          flexDirection: 'row'
                        }}
                      >
                        <MySelect
                          value={selectOptionSearch?.key}
                          defaultValue="phone"
                          data={searchOptions(t)}
                          onChange={(e) => onAction('selectSearchType', e)}
                          sx={{ minWidth: 'auto' }}
                          selectStyle={{ minWidth: 'auto' }}
                        />
                        <MyTextField
                          name={selectOptionSearch.key}
                          value={selectOptionSearch.value}
                          onChange={(e) => onAction('searchTyping', e)}
                          placeholder={t('button.search')}
                          onKeyPress={({ charCode }) =>
                            charCode === 13 && onAction('search')
                          }
                          sx={{ minWidth: '140px', width: 'auto', ml: 1 }}
                        />
                      </Stack>
                    </Stack>

                    {/* status */}
                  </Stack>
                  {/* buttons */}
                  <Stack
                    sx={{
                      width: '100%',
                      px: 2,
                      pt: 1.5,
                      flexDirection: 'row',
                      gap: 1,
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: '8px',
                        height: { md: '40px', xs: '22px' },
                        minWidth: { md: '80px', xs: 'auto' },
                        border: '1px solid #E5E7EB !important',
                        boxShadow: 0,
                        color: '#353D4A',
                        ':hover': {
                          boxShadow: 0,
                          backgroundColor: '#FAFAFA',
                          color: '#353D4A'
                        }
                      }}
                      onClick={() => onAction('reset')}
                    >
                      {t('button.clear')}
                    </Button>
                    <Button
                      disabled={loading}
                      variant="contained"
                      sx={{
                        minWidth: '100px',
                        borderRadius: 1,
                        height: { md: '40px', xs: '30px' }
                      }}
                      onClick={() => onAction('search')}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        t('button.search')
                      )}
                    </Button>
                  </Stack>
                </Paper>
              </Stack>

              <Stack sx={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  sx={{
                    borderRadius: 1,
                    p: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                    border: '1px solid #D3D3D3'
                  }}
                  onClick={() =>
                    setShowActionsBar((prev) =>
                      prev === 'export' ? 'none' : 'export'
                    )
                  }
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <Paper
                  elevation={24}
                  sx={{
                    minWidth: '210px',
                    zIndex: 1000,
                    backgroundColor: '#FFFFFF',
                    display: showActionsBar === 'export' ? 'flex' : 'none',
                    position: 'absolute',
                    right: 0,
                    ...(isSm && { left: 0 }),
                    border: '1px solid #EEEEEE',
                    top: '45px',
                    py: 1.5,
                    flexDirection: 'column',

                    borderRadius: 0.5
                  }}
                >
                  {/* header */}
                  <Stack
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      pb: 1.5,
                      px: 2,
                      alignItems: 'center',
                      borderBottom: '1px solid #D3D3D3',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        gap: 0.5,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14, color: '#212121', fontWeight: 700 }}
                      >
                        {t('button.downloadExcel')}
                      </Typography>
                    </Stack>

                    <IconButton
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#212121',
                        fontSize: '15px',
                        p: 0
                      }}
                      onClick={() => setShowActionsBar('none')}
                    >
                      <CloseOutlinedIcon
                        sx={{ width: '20px', height: '20px' }}
                      />
                    </IconButton>
                  </Stack>
                  {/* body */}
                  <Stack sx={{ width: '100%' }}>
                    <MenuItem
                      onClick={exportOrderHistory}
                      disabled={exportLoading?.default}
                    >
                      {exportLoading?.default && (
                        <CircularProgress
                          sx={{ color: '#212121', mr: 0.5 }}
                          size={20}
                        />
                      )}{' '}
                      {t('button.saveExcel')}
                    </MenuItem>
                  </Stack>
                </Paper>
              </Stack>
              <Stack>
                <Button
                  id="demo-customized-button"
                  sx={{
                    borderRadius: 1,
                    p: 1,
                    fontSize: 12,
                    height: 40
                  }}
                  aria-controls={anchorEl ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? 'true' : undefined}
                  variant="outlinedInherit"
                  onClick={handleClick}
                  disableElevation
                  endIcon={
                    anchorEl ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                >
                  {t('pages.equipment.filter')}
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                  }}
                  anchorEl={anchorEl}
                  open={anchorEl}
                  sx={{ maxHeight: { md: '500px', xs: '350px' } }}
                  onClose={() => setAnchorEl(null)}
                >
                  {cols
                    ?.filter((i) => i?.canBeFiltered)
                    ?.map((column) => (
                      <MenuItem
                        disableTouchRipple
                        disableRipple
                        key={`${column?.id}-${column?.name}`}
                        sx={{
                          minHeight: 'auto !important',
                          py: '0px !important'
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={column?.isOpen}
                              onClick={() => handleSwitch(column?.id)}
                            />
                          }
                          label={column.label}
                        />
                      </MenuItem>
                    ))}
                </StyledMenu>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 'fontWeightSemiMedium',
                  fontSize: 15,
                  color: 'text.primary'
                }}
                component="span"
              >
                {t('common.number-of-results')}{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
                >
                  {pagination.totalRow}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </SearchBar>
        <GroupTableCustomize
          dense
          hasToolBar
          hover
          onRowClick={(e) => onAction('clickRowTable', e)}
          columns={filteredCols}
          data={items}
          pagination={pagination}
          minusHeight={isSm ? 120 : 200}
          onChangePage={(e, page) => onAction('changePage', page)}
        />
      </Stack>

      <MyDialog
        open={selected?.open}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        title={selected?.title}
        hasCloseButton={1}
      >
        <DetailPayment items={selected?.items} />
      </MyDialog>
    </HelmetContainer>
  );
};

export default OrderHistoryPage;
