import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';

const Content = ({ headerHeight, footerHeight = 60, drawerWidth, open }) => {
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        minHeight: '100vh',
        width: `calc(100vw - ${open ? drawerWidth : 0}px)`
      }}
    >
      <Toolbar sx={{ height: `${headerHeight}px` }} />
      <Box
        component="main"
        sx={{
          height: '100%',
          p: { md: '30px', xs: '15px' }
        }}
        // style={{ maxHeight: `calc(100vh - ${150}px)` }}
      >
        <Outlet />
      </Box>
      <Toolbar
        sx={{
          height: {
            md: `${footerHeight}px`,
            xs: '0px'
          },
          minHeight: { xs: '0px !important' }
        }}
      />
    </Box>
  );
};

export default Content;
