import { IconButton, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { setSuccess } from '@store/reducers/appReducer';

const CopyToClipboardButton = ({ value, onCopy }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (onCopy) {
      onCopy(value);
    } else {
      navigator.clipboard.writeText(value);
    }
    dispatch(setSuccess({ message: 'copied' }));
  };

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      onClick={handleClick}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 0.6,
          minWidth: 150,

          overflow: 'hidden',
          border: '0.5px solid rgb(233, 231, 231)',
          borderRadius: 1
        }}
        onClick={handleClick}
      >
        {' '}
        <Typography
          sx={{ color: '#919EAB', fontSize: { md: 15, xs: 12, maxWidth: 160 } }}
        >
          {value}
        </Typography>{' '}
        <IconButton
          onClick={() => handleClick()}
          sx={{
            backgroundColor: 'white',
            zqIndex: 10,
            ':hover': {
              backgroundColor: '#FFFFFF !important'
            }
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: { md: '18px', xs: '14px' },
              color: 'lightgrey'
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CopyToClipboardButton;
