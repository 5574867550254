import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, IconButton, styled, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1A1F27',
    color: '#FFFFFF',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'pre-line',
    border: '1px solid #dadde9'
  }
}));

const ToolTipContent = ({ title, content }) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap={0.5}
      >
        <ErrorOutlineIcon sx={{ width: '16px', height: '16px' }} />
        <Typography fontSize="13px" fontWeight="bold">
          {' '}
          {title}
        </Typography>
      </Box>

      <Typography fontSize="10px" my={1}>
        {content}
      </Typography>
    </Box>
  );
};
const MyToolTip = ({ title, content }) => {
  return (
    <CustomToolTip
      title={<ToolTipContent title={title} content={content} />}
      placement="top"
      arrow
    >
      <IconButton>
        <HelpOutlineOutlinedIcon sx={{ width: '18px', height: '18px' }} />
      </IconButton>
    </CustomToolTip>
  );
};

export default MyToolTip;
