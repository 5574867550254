import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import Calendar from '@components/Calendar';
import MyDialog from '@components/MyDialog';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';

import welfarePointsServices from '@services/welfarePoints';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

import { isEmail, isKoreanPhoneNumber } from '@utils/boolean';

import { tableBodyStyle } from '../enhance';

const maxLengthOf = {
  name: 20,
  phone: 20,
  email: 100
};

const MemberRegisterDialog = ({ open, setOpen, clientCode, setSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: '',
    phone: '',
    email: '',
    gender: 'Male',
    dob: undefined
  });

  const [errMsg, setErrMsg] = useState({
    name: '',
    phone: '',
    email: '',
    gender: '',
    dob: ''
  });

  const genderSelections = [
    { label: t('pages.welfare-points.male'), value: 'Male' },
    { label: t('pages.welfare-points.female'), value: 'Female' }
  ];

  const onChange = ({ name, value }) => {
    setData({ ...data, [name]: value });

    let msg = '';
    if (!value) {
      msg = t('common.required');
    } else if (maxLengthOf[name] && value.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({
      ...errMsg,
      [name]: msg
    });
  };

  const validatePhoneNumber = () => {
    let msg = '';
    if (!data.phone) {
      msg = t('common.required');
    } else if (!isKoreanPhoneNumber(data.phone)) {
      msg = t('validation.invalidPhoneNumber');
    }
    setErrMsg({
      ...errMsg,
      phone: msg
    });
  };

  const validateEmail = () => {
    let msg = '';
    if (!data.email) {
      msg = t('common.required');
    } else if (!isEmail(data.email)) {
      msg = t('validation.invalidEmailAddress');
    }
    setErrMsg({
      ...errMsg,
      email: msg
    });
  };

  const validateOnSubmit = () => {
    const tempErr = { ...errMsg };
    let isPassed = true;
    if (!isEmail(data.email)) {
      tempErr.email = t('validation.invalidEmailAddress');
      isPassed = false;
    }
    if (!isKoreanPhoneNumber(data.phone)) {
      tempErr.phone = t('validation.invalidPhoneNumber');
      isPassed = false;
    }
    // eslint-disable-next-line	no-restricted-syntax
    for (const property in data) {
      if (!data[property]) {
        tempErr[property] = t('common.required');
        isPassed = false;
      } else if (
        maxLengthOf[property] &&
        data[property]?.length > maxLengthOf[property]
      ) {
        tempErr[property] = t('validation.dataTooLong', {
          max: maxLengthOf[property]
        });
        isPassed = false;
      }
    }
    setErrMsg(tempErr);
    return isPassed;
  };

  const handleRegisterWelfarePointMember = async () => {
    setLoading(true);
    setSuccess(false);
    const result = await welfarePointsServices.createMember({
      name: data.name,
      phone: data.phone,
      email: data.email.toLowerCase(),
      sex: data.gender,
      age: moment(data.dob || new Date()).format('YYYYMMDD'),
      clientCode
    });
    setLoading(false);
    if (result?.error) {
      dispatch(setError(error2Text(result.error)));
    } else {
      setSuccess(true);
      setOpen(false);
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.welfare-points.wp-member-register')}
      okTitle={t('button.register')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onOk={() => {
        if (validateOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => handleRegisterWelfarePointMember()
            })
          );
        }
      }}
      sx={{
        '.MuiDialogContent-root': {
          border: '1px solid #D3D3D3',
          borderRadius: '8px'
        }
      }}
      loading={loading}
      setLoading={setLoading}
    >
      <TableContainer>
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.name')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="name"
                  value={data.name}
                  errMg={errMsg.name}
                  onChange={(e) => onChange(e.target)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.phone-number')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="phone"
                  value={data.phone}
                  onChange={(e) => onChange(e.target)}
                  onBlur={validatePhoneNumber}
                  typeValue="onlyNumber"
                  errMg={errMsg.phone}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.email')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="email"
                  value={data.email}
                  onChange={(e) => onChange(e.target)}
                  onBlur={validateEmail}
                  errMg={errMsg.email}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.gender')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MySelect
                  name="gender"
                  value={data.gender}
                  data={genderSelections}
                  onChange={(e) => onChange(e.target)}
                  errMg={errMsg.gender}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.date-of-birth')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <Calendar
                  name="dob"
                  date={data.dob}
                  setDate={(e) => setData({ ...data, dob: e })}
                  onChange={() => setErrMsg({ ...errMsg, dob: '' })}
                  errMsg={errMsg.dob}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default MemberRegisterDialog;
