import i18n from '@utils/i18n';
import { Formatter } from '@utils/formatter';

import { Chip } from '@mui/material';

const t = i18n.getFixedT(null, null, 'pages.consultationInquiry');
const g = i18n.getFixedT(null, null, '');
const { formatTime } = Formatter;

export const statusList = [
  { value: 'all', label: t('total') },
  { value: 'GENERAL', label: t('in-progress') },
  { value: 'SERVICE', label: t('receipt') }
];

export const serviceStatusList = [
  { value: 'all', label: t('total') },
  { value: 'REJECTED', label: t('refused') },
  { value: 'REGISTERED', label: t('receipt') },
  { value: 'COMPLETED', label: t('complete') }
];

export const serviceEntryTypeLsit = [
  { value: 'all', label: t('total') },
  { value: 'GENERAL', label: t('general') },
  { value: 'ADS', label: t('ad') }
];
const statusObject = {
  REJECTED: t('refused'),
  REGISTERED: t('receipt'),
  COMPLETED: t('complete')
};

const serviceType = {
  ADS: t('ad'),
  GENERAL: t('general')
};
export const columns = [
  { name: '#', label: 'No', align: 'center', minWidth: 60 },
  {
    name: 'regDt',
    label: t('type'),
    align: 'center',
    minWidth: 80,
    render: ({ entryType }) => {
      return serviceType[entryType] || entryType;
    }
  },
  {
    name: 'regDt',
    label: t('registration-date'),
    align: 'center',
    minWidth: 120,
    render: ({ regDt }) => {
      return formatTime(regDt);
    }
  },
  {
    name: 'companyName',
    label: t('companyName'),
    align: 'center',
    minWidth: 120
  },

  {
    name: 'picName',
    label: t('pic'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'picPhone',
    label: t('phone'),
    align: 'center',
    minWidth: 60
  },
  {
    name: 'picEmail',
    label: t('email'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'answeredAdminName',
    label: t('confirm-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'answeredDt',
    label: t('confirm-date'),
    align: 'center',
    minWidth: 100,
    render: ({ answeredDt }) => answeredDt && formatTime(answeredDt)
  },
  {
    name: 'contactYn',
    label: t('inquiry-status'),
    align: 'center',
    minWidth: 100,
    render: ({ requestStatus }) => {
      let variant = 'primary';
      if (requestStatus === 'REGISTERED') {
        variant = 'info';
      } else if (requestStatus === 'REJECTED') variant = 'error';
      return (
        <Chip
          color={variant}
          sx={{
            minWidth: '60px',
            '&.MuiChip-colorInfo': {
              backgroundColor: '#FFFFFF',
              border: '3px solid blue',
              color: 'text.primary'
            }
          }}
          label={statusObject[requestStatus] || requestStatus}
        />
      );
    }
  }
];

export const conditionList = [
  { label: i18n.t('common.companyName'), value: 'companyName' }
];

export const inquirDetailSchema = [
  {
    name: 'regDt',
    type: 'date',
    label: t('registration-date'),
    grid: 6,
    formatter: (value) => (value ? formatTime(value) : ''),
    border: { borderLeft: 0 }
  },
  {
    name: 'entryType',
    type: 'text',
    label: t('type'),
    style: { color: ' #4267EB', fontWeight: 600 },
    formatter: (entryType) => serviceType[entryType] || entryType,
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },
  {
    name: 'companyName',
    type: 'text',
    label: t('companyName'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'picName',
    type: 'text',
    label: t('pic'),
    border: {
      borderLeft: '1px solid #D3D3D3'
    },
    grid: 6
  },
  {
    name: 'picPhone',
    type: 'text',
    label: t('phone'),
    grid: 6,
    border: { borderLeft: 0 }
  },

  {
    name: 'picEmail',
    type: 'textarea',
    label: t('email'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  },

  {
    name: 'requestStatus',
    type: 'text',
    label: g('common.status'),
    formatter: (value) => statusObject[value] || value,
    grid: 12,
    border: {
      borderLeft: 0
    }
  },
  {
    name: 'answeredDt',
    type: 'date',
    label: t('reply-date'),
    grid: 6,
    formatter: (value) => (value ? formatTime(value) : ''),
    border: { borderLeft: 0 }
  },
  {
    name: 'answeredAdminName',
    type: 'text',
    label: t('reply-admin'),
    grid: 6,
    border: {
      borderLeft: '1px solid #D3D3D3'
    }
  }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
