/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import useMergeState from '@hooks/useMergeState';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import MyTextField from '@components/MyTextField';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
// import KioskServices from '@services/kiosk';
// import adServices from '@services/ad';
// import apkServices from '@services/apk';
import useResponsive from '@hooks/useResponsive';
import { Formatter } from '@utils/formatter';
import { BorderBox, tableStyle } from '../enhance';
import AdVersionComplete from './AdVersionComplete';
import AppVersionComplete from './AppVersionComplete';

const requiredFields = ['kioskNickname', 'minibarCode'];
const MobileStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 10,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: '40%'
    },
    td: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      p: '7px 12px',
      width: '60%'
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};
function KioskForm({
  item = '',
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const [kiosk, setKiosk] = useState(undefined);
  const [app, setApp] = useState(null);
  const [ad, setAd] = useState(null);
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useMergeState({
    kioskNickname: '',
    minibarCode: ''
  });
  const isSm = useResponsive('down', 700);
  const handleChangeInput = (e) => {
    const { name, value, fieldName, fieldValue, versionName, versionValue } =
      e.target;

    setKiosk((prevKiosk) => ({
      ...prevKiosk,
      [name]: value,
      [fieldName]: fieldValue,
      [versionName]: versionValue
    }));
    setErrMsg({ [name]: !value ? t('common.required') : '' });
    if (name === 'kioskNickname' && value.length > 20) {
      setErrMsg({
        kioskNickname: t('validation.dataTooLong', { max: 20 })
      });
    }
  };

  const validateOnSubmit = () => {
    let isPassed = true;
    requiredFields.forEach((field) => {
      if (!kiosk?.[field]) {
        setErrMsg({ [field]: t('common.required') });
        isPassed = false;
      }
    });
    if (kiosk?.kioskNickname?.length > 20) {
      setErrMsg({ kioskNickname: t('validation.dataTooLong', { max: 20 }) });
      isPassed = false;
    }
    return isPassed;
  };
  const handleAppChange = (appItem) => {
    console.log('appItem received', appItem);
    if (appItem?.appCode && appItem?.appNickname) {
      const { appCode, appNickname, appVersion } = appItem;
      setKiosk((prevKiosk) => ({
        ...prevKiosk,
        appCode,
        appNickname,
        appVersion
      }));
    }
  };
  const handleAdChange = (adItem) => {
    console.log('appItem received', adItem);
    if (adItem?.adCode && adItem?.adNickname) {
      const { adCode, adNickname, adVersion } = adItem;
      setKiosk((prevKiosk) => ({
        ...prevKiosk,
        adCode,
        adNickname,
        adVersion
      }));
    }
  };

  const handleSubmit = () => {
    const isPassed = validateOnSubmit();
    if (isPassed) {
      onSubmit({ ...kiosk, ...app, ...ad });
    }
  };

  useEffect(() => {
    setKiosk(item);
  }, []);

  return (
    <Stack sx={{ width: '100%' }}>
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={{ ...(isSm ? MobileStyle : tableStyle) }}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.nickname')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="kioskNickname"
                  value={kiosk?.kioskNickname}
                  placeholder={t('pages.kiosk.labels.nickname')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.kioskNickname}
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.typeName')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="typeName"
                  value={kiosk?.typeName}
                  placeholder={t('pages.kiosk.labels.typeName')}
                  onChange={(e) => handleChangeInput(e)}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.minibarNickname')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MinibarAutoCompleteV2
                  label={null}
                  sx={{ width: { md: '100%', sm: 'auto', xs: 'auto' } }}
                  minibar={{
                    minibarCode: kiosk?.minibarCode,
                    minibarNickname: kiosk?.minibarNickname
                  }}
                  setMinibar={(e) => {
                    if (e) {
                      setKiosk({
                        ...kiosk,
                        minibarCode: e?.minibarCode,
                        minibarNickname: e?.minibarNickname
                      });
                    } else {
                      setKiosk({
                        ...kiosk,
                        minibarCode: '',
                        minibarNickname: ''
                      });
                    }
                  }}
                />
                {errMsg.minibarCode && (
                  <span style={{ color: 'red' }}>* {errMsg.minibarCode}</span>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.anydesk-id')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="anydeskId"
                  value={kiosk?.anydeskId}
                  placeholder={t('pages.kiosk.labels.anydesk-id')}
                  onChange={(e) => handleChangeInput(e)}
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.appNickname')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <AppVersionComplete
                  sx={{ width: { md: '100%', sm: 'auto', xs: 'auto' } }}
                  app={
                    app || {
                      appCode: kiosk?.appCode || item.appCode,
                      appNickname: kiosk?.appNickname || item.appNickname,
                      appVersion: kiosk?.appVersion || item.appVersion
                    }
                  }
                  onChange={handleAppChange}
                  textFieldProps={{
                    placeholder: t('pages.kiosk.labels.appNickname')
                  }}
                  setApp={setApp}
                />
                {errMsg.appCode && (
                  <span style={{ color: 'red' }}>* {errMsg.appCode}</span>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.appVersion')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appVersion"
                  placeholder={t('pages.kiosk.labels.appVersion')}
                  value={app?.appVersion}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.adNickname')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <AdVersionComplete
                  sx={{ width: { md: '100%', sm: 'auto', xs: 'auto' } }}
                  ad={
                    ad || {
                      adCode: kiosk?.adCode || item?.adCode,
                      adNickname: kiosk?.adNickname || item?.adNickname,
                      adVersion: kiosk?.adVersion || item?.adVersion
                    }
                  }
                  onChange={handleAdChange}
                  textFieldProps={{
                    placeholder: t('pages.kiosk.labels.adNickname')
                  }}
                  setAd={setAd}
                />
                {/* {errMsg.adCode && (
                  <span style={{ color: 'red' }}>* {errMsg.adCode}</span>
                )} */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.adVersion')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="adVersion"
                  value={ad?.adVersion}
                  placeholder={t('pages.kiosk.labels.adVersion')}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.lastConnectionTime')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="lastSignalTime"
                  value={Formatter.formatTime(kiosk?.lastSignalTime)}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                  textFieldStyle={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2',
                      fontWeight: 700,
                      color: '#111',
                      px: 0
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.regDt')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="regDt"
                  value={Formatter.formatTime(kiosk?.regDt)}
                  placeholder={t('pages.kiosk.labels.regDt')}
                  onChange={(e) => handleChangeInput(e)}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.kiosk.labels.editDt')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="editDt"
                  value={Formatter.formatTime(kiosk?.editDt)}
                  placeholder={t('pages.kiosk.labels.editDt')}
                  onChange={(e) => handleChangeInput(e)}
                  disabled
                  sx={{
                    width: { md: '100%', sm: 'auto', xs: 'auto' },
                    padding: '7px 0'
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: { md: 5, xs: 2, sm: 2 }, mb: { md: 5, xs: 2, sm: 2 } }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: { md: 6, xs: 3, sm: 3 },
              minWidth: { md: 12, xs: 6, sm: 6 }
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: { md: 6, xs: 3, sm: 3 },
              minWidth: { md: 12, xs: 6, sm: 6 }
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
export default KioskForm;
