import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  styled,
  Container
} from '@mui/material';

import InputFile from '@components/InputFile';
// import minibarOrderService from '@services/minibarOrderService';
import MySelect from '@components/MySelect';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import { clientChannelList } from '@pages/ProductManagement/enhance';
import { useDispatch } from 'react-redux';
import MyDialog from '@components/MyDialog';
import clientOrderServices from '@services/clientOrder';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '60%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};
const ExcelRegisterDialog = ({ open, setOpen, onSuccess }) => {
  const { t } = useTranslation();
  const requiredFields = ['channel', 'client'];
  const dispatch = useDispatch();
  const [dataFile, setDataFile] = useState({
    name: '',
    file: '',
    src: ''
  });
  const [orderData, setOrderData] = useState({
    channel: '',
    client: '',
    minibar: ''
    // shippingDate: new Date()
  });
  const onDataChange = (name, e) => {
    const { value } = e.target;
    if (name === 'channel') {
      setOrderData({ ...orderData, [name]: value, client: '', minibar: '' });
    } else if (name === 'client') {
      setOrderData({ ...orderData, [name]: value, minibar: '' });
    } else {
      setOrderData({ ...orderData, [name]: value });
    }

    if (requiredFields.includes(name)) {
      setErrMsg({
        ...errmsg,
        [name]: value ? '' : t('common.required')
      });
    }
  };

  const [errmsg, setErrMsg] = useState({
    fileType: ''
  });

  const handleRegisterByExcel = async () => {
    const dataSubmit = new FormData();

    dataSubmit.append('file', dataFile.file);
    dataSubmit.append('clientChannel', orderData?.channel);
    dataSubmit.append('clientCode', orderData?.client?.clientCode);
    if (orderData?.channel === 'B2C' && orderData?.client?.clientCode) {
      dataSubmit.append('minibarCode', orderData?.minibar?.minibarCode);
    }
    const rs = await clientOrderServices.createClientOrdersByExcel(dataSubmit);
    if (!rs.error) {
      dispatch(setSuccess({ message: t('info.register.content') }));
      onSuccess();
      setOpen(false);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const validateFile = (file) => {
    const extension = file.name.split('.').pop();

    if (['xlsx', 'xls'].includes(extension.toLowerCase())) {
      console.log('true :', extension);
      setErrMsg({ ...errmsg, fileType: '' });
      return true;
      // eslint-disable-next-line no-else-return
    } else {
      setErrMsg({ ...errmsg, fileType: '잘못된 파일입니다.' });
      return false; // File extension is not valid
    }
  };

  const isValidData = () => {
    let isValid = true;

    Object.keys(orderData).forEach((k) => {
      if (requiredFields.includes(k) && !orderData[k]) {
        isValid = false;
      }
    });
    if (orderData?.channel === 'B2C' && !orderData?.minibar?.minibarCode) {
      isValid = false;
    }
    return isValid;
  };

  const exportSample = async () => {
    const rs = await clientOrderServices.exportSample();
    if (rs?.error) console.log(rs?.error);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    validateFile(file);
    setDataFile({ ...dataFile, name: file.name, file });
  };
  return (
    <MyDialog
      disabled={!dataFile.file || errmsg.fileType || !isValidData()}
      open={open}
      setOpen={setOpen}
      title={t('pages.client-order.excel-register')}
      okTitle={t('button.add')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onClose={() => setDataFile({ name: '', file: '', src: '' })}
      onOk={handleRegisterByExcel}
      sx={{ width: 600 }}
    >
      <Stack gap={1.5}>
        <Container component={BorderBox}>
          <FieldContainer label={t('labels.clientChannel')} required>
            <MySelect
              value={orderData?.channel || ''}
              data={clientChannelList}
              onChange={(e) => {
                onDataChange('channel', e);
              }}
              sx={{
                ...inputStyle,
                mb: 1
              }}
            />
          </FieldContainer>
          <FieldContainer label={t('common.client')} required>
            <ClientAutoCompleteV2
              label={false}
              client={orderData?.client}
              setClient={(value) =>
                onDataChange('client', { target: { value } })
              }
              channel={orderData?.channel}
              //   onKeyPress={({ charCode }) => charCode === 13 && onDataChange('client', )}
              sx={{ ...inputStyle, mb: 1 }}
              disabled={!orderData?.channel}
              // ListboxProps={{ style: { maxHeight: 220 } }}
            />
          </FieldContainer>
          <FieldContainer label={t('common.minibar')}>
            <MinibarAutoCompleteV2
              label={false}
              minibar={orderData?.minibar}
              setMinibar={(value) =>
                onDataChange('minibar', { target: { value } })
              }
              client={orderData?.client}
              disabled={
                !orderData?.client?.clientCode || orderData?.channel !== 'B2C'
              }
              ListboxProps={{ style: { maxHeight: 200 } }}
              sx={{ ...inputStyle, mb: 1 }}
              //   onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
            />
          </FieldContainer>
        </Container>

        <Stack
          direction="row"
          justifyContent="space-between"
          px={1}
          alignItems="center"
        >
          <Typography
            sx={{
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: { md: 13, xs: 10 }
            }}
          >
            {t('pages.product.example-excel-notes-no-image')}
          </Typography>

          <Button
            sx={{ textTransform: 'lowercase', height: { md: 36, xs: 24 } }}
            onClick={exportSample}
          >
            {t('button.example-file-label')}
          </Button>
        </Stack>
        <Box
          sx={{
            border: errmsg.fileType ? '1px solid red' : '1px solid #ccc',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'space-between',
            p: '10px 20px'
          }}
        >
          <Tooltip title={dataFile.name}>
            <Typography
              sx={{
                color: 'primary.darker',
                fontWeight: 'fontWeightBold',
                fontSize: { md: 13, xs: 10 },
                textDecoration: 'underline',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '10px',
                marginTop: 1
              }}
            >
              {dataFile.name}
            </Typography>
          </Tooltip>

          <InputFile
            title={t('button.attachment')}
            onChange={handleFileUpload}
          />
        </Box>
        {errmsg.fileType && (
          <span
            style={{
              color: 'red',
              fontSize: { md: 12, xs: 9 }
            }}
          >
            * {errmsg.fileType}
          </span>
        )}
      </Stack>
    </MyDialog>
  );
};

const FieldContainer = ({ label, required, children }) => {
  return (
    <Stack direction="row">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          pb: 1,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 160,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && (
            <span
              style={{
                color: 'red',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {' '}
              *
            </span>
          )}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};
export default ExcelRegisterDialog;
