import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const c = i18n.getFixedT(null, null, 'pages.curation');
const p = i18n.getFixedT(null, null, 'pages.perform');
const prod = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

export const columns = [
  {
    name: 'aggregationCode',
    label: p('aggregate-client-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientCode',
    label: g('labels.clientCode'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientChannel',
    label: g('labels.clientChannel'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'clientName',
    label: g('labels.clientName'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'totalSales',
    label: g('menusidebar.performance-management.sales'),
    minWidth: 160,
    align: 'center',
    render: ({ totalSales }) => formatMoney(totalSales)
  }
];

export const detailColumns = [
  {
    name: 'productName',
    label: prod('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'volume',
    label: prod('volume'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'package',
    label: prod('package'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'categoryName1',
    label: c('1st-cat'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'categoryName2',
    label: c('2nd-cat'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'categoryName3',
    label: c('3rd-cat'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'costOfSales',
    label: p('cost-of-sales'),
    minWidth: 160,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'salesQuantity',
    label: c('salesQuantity'),
    minWidth: 160,
    align: 'center',
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'totalSales',
    label: p('total-sales'),
    minWidth: 160,
    align: 'center',
    formatter: (item) => formatMoney(item)
  }
];
