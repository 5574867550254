import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { tableBodyStyle } from '../enhance';

const CompletedArea = ({ data, onNavigateBack }) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'pages.development-request'
  });
  return (
    <Box>
      <Typography
        sx={{ fontWeight: 'fontWeightBold', fontSize: 16, mb: '10px' }}
      >
        {t('completed')}
      </Typography>
      <TableContainer
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden',
          mb: '30px'
        }}
      >
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell role="row" component="th">
                {t('title')}
              </TableCell>
              <TableCell>{data.reqSubject}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('writer')}
              </TableCell>
              <TableCell>{data.completeName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell role="row" component="th">
                {t('register-date')}
              </TableCell>
              <TableCell>
                {new Date(data.completeDate).toLocaleString('en-CA', {
                  hour12: false,
                  timeZone: 'Asia/Seoul'
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button variant="outlined" onClick={onNavigateBack}>
          {t('list')}
        </Button>
      </Stack>
    </Box>
  );
};

export default CompletedArea;
