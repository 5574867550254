import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const minibarOrderService = {
  async getMinibarOrderList(params) {
    try {
      const res = await new RestService()
        .setPath('/product-minibar')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getMinibarActionsHistory(params) {
    try {
      const res = await new RestService()
        .setPath('/product-minibar/stock-update-history')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async rollbackMinibarAction(minibarStockCode) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/stock-update-history/${minibarStockCode}`)

        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO: Modify this for PRoduct => Done
  async getProductExcelErrorHistoryList(minibarCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/stocks-import-registrations`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO: Modify this for PRoduct =>DOne
  async getMinibarExcelErrorHistoryList(minibarCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/stocks-import-modifications`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async addMinibarProduct(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/stocks`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createMinibarProductOrdersByExcel(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/stocks-import-registration`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO update this endpoint for shelf => DONE
  async createMinibarShelfOrdersByExcel(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/stocks-import-modification`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateFillOut(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/order`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO: update setAllZero endpoint == DONE
  async setAllZero(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/stocks`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteMinibarStock(minibarStockCode) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateDeleteMinibarStock(minibarStockCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // checkboxes
  async updateIssueYn(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/issue`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateProgressYn(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/issue`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updatePriceInfo(minibarStockCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}/price-info`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateProperStock(minibarStockCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}/proper-quantity`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMinibarStock(minibarStockCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}/proper-quantity`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // display update
  async updateDisplayInfo(minibarStockCode, data) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarStockCode}/display`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // update Recall info
  async updateRecallInfo(minibarStockCode, data, recallType) {
    try {
      const res = await new RestService()
        .setPath(
          `product-minibar/${minibarStockCode}/recall-type/${recallType}`
        )
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO: Update expor sample for minibar product and shelf
  async exportSample() {
    try {
      const res = await new RestService()
        .setPath('product-minibar/export-registration-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportShelfSample() {
    try {
      const res = await new RestService()
        .setPath('product-minibar/export-modification-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async exportProductErrorHistory(minibarCode, params = {}) {
    console.log('registrationCode', params);
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/stocks-import-registration`)
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async exportShelfErrorHistory(minibarCode, params = {}) {
    console.log('registrationCode', params);
    try {
      const res = await new RestService()
        .setPath(`product-minibar/${minibarCode}/stocks-import-modification`)
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // TODO: update export exel => Done
  async exportMinibarOrder(minibarCode) {
    try {
      const res = await new RestService()
        .setPath(`/product-minibar/${minibarCode}/export`)
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async exportMultipleMinibarData(minibarCodeList) {
    try {
      const res = await new RestService()
        .setPath(`product-minibar/export-minibars-stocks`)
        .setResponseType('blob')
        .post(minibarCodeList);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default minibarOrderService;
