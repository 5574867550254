import { Box, Container, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { shippingInfo } from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};

const ShippingInfo = ({ data }) => {
  return (
    <Container component={BorderBox}>
      {shippingInfo.map(({ name, label }) => (
        <FieldContainer label={label}>
          {name === 'client-minibar' && (
            <Typography>
              {data?.clientChannel === 'B2B'
                ? data?.clientName
                : data?.minibarNickname}
            </Typography>
          )}
          {name === 'shipping-address' && (
            <Typography>
              {data?.shippingAddress1}
              {'  '} {data?.shippingAddress2}
            </Typography>
          )}
          <Typography>{data[name]}</Typography>
        </FieldContainer>
      ))}
    </Container>
  );
};

const FieldContainer = ({ children, label, required }) => {
  return (
    <Stack direction="row" alignItems="center" mr={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 60,
          mr: 2,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 140,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>{children}</Stack>
    </Stack>
  );
};

export default ShippingInfo;
