import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const consultationInquiryService = {
  async getList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/consultation-inquiry')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStatus(inquiryCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/consultation-inquiry/${inquiryCode}/status`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getDetail(inquireyCode) {
    try {
      const res = await new RestService()
        .setPath(`consultation-inquiry/${inquireyCode}`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // Service inquiry
  async getServiceInquiryList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/introduction-request')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async exportConsultationIqnuiry(params = {}) {
    try {
      const res = await new RestService()
        .setPath('consultation-inquiry/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateServiceInquiryStatus(inquiryCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/introduction-request/${inquiryCode}/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getServiceIntroDetail(inquireyCode) {
    try {
      const res = await new RestService()
        .setPath(`introduction-request/${inquireyCode}`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default consultationInquiryService;
