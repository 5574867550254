import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
  styled
} from '@mui/material';

import MySelect from '@components/MySelect';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MyTextField from '@components/MyTextField';
// import MyAutocomplete from '@components/MyAutocomplete';
import { formatMoney } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { setOpenConfirm } from '@store/reducers/appReducer';
import { productDetailSchema } from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const maxLengthOf = {
  question: 100,
  answer: 3000,
  questionEng: 100,
  answerEng: 3000
};

const requiredFields = [
  'question',
  'answer',
  'questionEng',
  'answerEng',
  'category'
];

const NoticeDetailForm = (props) => {
  const { onAction, value, setValue } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const [isModified, setIsmodified] = useState(false);
  const dispatch = useDispatch();
  const [errMg, setErrMg] = useState({
    question: '',
    answer: '',
    category: ''
  });

  const onChange = (e, type) => {
    validateOnChange(e.target);
    setIsmodified(true);
    if (e.target.name !== 'vendorCode' && e.target.name) {
      setValue({
        ...value,
        [e.target.name]:
          type === 'number' ? e.target.value.replace(/,/g, '') : e.target.value
      });
    }
  };

  const validateOnChange = ({ name, value: val }) => {
    let msg = '';
    if (!val && requiredFields.includes(name)) {
      msg = t('common.required');
    } else if (val.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  const checkValidate = () => {
    let isValidate = true;
    const vldErr = { ...errMg };
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });
    setErrMg(vldErr);
    return isValidate;
  };

  const isValidToSubmit = () => {
    let isValidate = true;
    requiredFields.forEach((field) => {
      if (!value?.[field]) {
        isValidate = false;
      }
    });
    return isValidate;
  };

  const handleAction = (action) => {
    if (checkValidate(value)) {
      dispatch(
        setOpenConfirm({
          open: true,
          title: action === 'register' ? t('button.save') : t('button.modify'),
          msg:
            action === 'register'
              ? t('common.confirm-register')
              : t('common.confirm-edit'),
          type: action === 'register' ? 'register' : 'edit',
          action: () => onAction(action),
          okTitle: action === 'register' ? t('button.save') : t('button.edit')
        })
      );
    }
  };

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
        mb={4}
        sx={{ display: 'flex' }}
      >
        <IconButton
          onClick={() => onAction('cancel')}
          sx={{
            display: 'flex',
            gap: 0.5,
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}
          disableRipple
          disableTouchRipple
        >
          <ArrowBackIosNewIcon
            sx={{ height: '20px', color: 'black', fontWeight: 500 }}
          />
        </IconButton>
        {location?.pathname?.includes('register') ? (
          <Button
            variant="contained"
            onClick={() => handleAction('register')}
            disabled={!isValidToSubmit()}
          >
            {t('button.save')}
          </Button>
        ) : (
          <Button
            variant={isModified ? 'contained' : 'outlined'}
            onClick={() => handleAction('modification')}
            disabled={!isValidToSubmit()}
          >
            {isModified ? t('button.save') : t('pages.inventory.modify')}
          </Button>
        )}
      </Stack>
      <Container component={BorderBox}>
        <Stack flexDirection="row" flexWrap="wrap">
          {productDetailSchema.map((item) => {
            return (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: {
                    xs: '100%',
                    md: item?.grid === 12 ? '100%' : '50%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                  ...item.border
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                    {requiredFields.includes(item.name) && (
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    )}
                  </Typography>
                </Stack>

                <RenderField
                  onChangeInput={(e) => onChange(e, item?.type)}
                  errMg={errMg?.[item?.name]}
                  {...item}
                  disabled={item?.disabled}
                  data={item?.options}
                  value={value}
                />
              </Stack>
            );
          })}
        </Stack>
      </Container>
      {/* <Stack flexDirection="row" justifyContent="center" gap={2} m={4}>
        <Button variant="outlined" onClick={() => onAction('cancel')}>
          {t('button.cancel')}
        </Button>
        {location?.pathname?.includes('register') ? (
          <Button
            variant="contained"
            onClick={() => handleAction('register')}
            disabled={!isValidToSubmit()}
          >
            {t('pages.inventory.register')}
          </Button>
        ) : (
          <Stack flexDirection="row" gap={2}>
            <Button
              variant="contained"
              onClick={() => handleAction('modification')}
              disabled={!isValidToSubmit()}
            >
              {t('pages.inventory.modify')}
            </Button>
          </Stack>
        )}
      </Stack> */}
    </>
  );
};

const RenderField = ({ data, value, errMg, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;

  if (type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          ...inputStyle,
          ...item?.inputStyle
        }}
      >
        <MySelect
          value={value[item.name]}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ width: '300px' }}
        />
      </Stack>
    );
  if (type === 'textarea') {
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MyTextField
          value={
            type === 'number' ? formatMoney(value[item.name]) : value[item.name]
          }
          errMg={errMg}
          onChange={onChangeInput}
          size="small"
          multiline
          rows={5}
          {...rest}
          sx={{ ...inputStyle, minWidth: 210, px: 0 }}
          textFieldStyle={{
            borderRadius: 0,
            ...(item?.disabled && { backgroundColor: '#f2f2f2' })
            // '& fieldset': { border: 'none' }
          }}
          typeValue={type === 'number' && 'onlyNumber'}
        />
      </Stack>
    );
  }
  return (
    <MyTextField
      value={
        item?.formatter ? item?.formatter(value[item.name]) : value[item.name]
      }
      errMg={errMg}
      onChange={onChangeInput}
      size="small"
      {...rest}
      sx={{ ...inputStyle, minWidth: 210 }}
      textFieldStyle={{
        borderRadius: 0,
        ...(item?.disabled && { backgroundColor: '#f2f2f2' })
        // '& fieldset': { border: 'none' }
      }}
      typeValue={type === 'number' && 'onlyNumber'}
    />
  );
};

export default NoticeDetailForm;
