import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyDialog from '@components/MyDialog';
import SearchBar from '@components/SearchBar';
import DateRangePicker from '@components/DateRangePicker';
import { Formatter } from '@utils/formatter';
import { dateFormat } from '@constants/utils';
import {
  add,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import {
  compareDates,
  countDates,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import warehouseService from '@services/warehouse';
import scheduleService from '@services/schedule';
import { useLocation, useNavigate } from 'react-router-dom';
import MyAutocomplete from '@components/MyAutocomplete';
import { useDispatch } from 'react-redux';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { columns, timeRadios } from './enhance';
import ScheduleForm from './components/ScheduleForm';

const Schedule = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const navigate = useNavigate();
  const location = useLocation();
  const configColumns = columns;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [listSchedule, setListSchedule] = useState([
    {
      index: 1,
      clientChannel: '',
      clientName: '',
      clientCode: '',
      minibarNickname: '',
      minibarCode: '',
      managerZone: '',
      managerArea: ''
    }
  ]);
  const [gValue, setGValue] = useState({
    shippingDate: formatTime(new Date(), dateFormat),
    managerCode: null
  });
  const [keyword, setKeyword] = useState('');
  const [pic, setPic] = useState([]);
  const [param, setParam] = useState({
    endDate: formatTime(add(new Date(), { months: 1 }), dateFormat),
    startDate: formatTime(new Date(), dateFormat),
    pic: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getSchedules({ ...param, page });
    navigate(query);
  };

  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };
  const checkDateTimeRadio = (name) => {
    if (name === 'today') {
      return (
        !compareDates(param.startDate, param.endDate) &&
        !compareDates(param.startDate, formatTime(new Date(), dateFormat))
      );
    }
    if (name === 'currentWeek') {
      return (
        new Date(param.startDate).getDay() === 1 &&
        new Date(param.endDate).getDay() === 0 &&
        countDates(param.startDate, param.endDate) === 6 &&
        countDates(param.startDate, formatTime(new Date(), dateFormat)) < 7
      );
    }
    if (name === 'currentMonth') {
      return (
        new Date(param.startDate).getDate() === 1 &&
        new Date(param.endDate).getDate() >= new Date().getDate() &&
        new Date(param.startDate).getMonth() === new Date().getMonth() &&
        new Date(param.endDate).getMonth() === new Date().getMonth() &&
        countDates(param.startDate, param.endDate) !== 6
      );
    }
    return false;
  };
  const handleTimeRadio = (name) => {
    if (name === 'today')
      setParam({
        ...param,
        startDate: formatTime(new Date(), dateFormat),
        endDate: formatTime(new Date(), dateFormat)
      });
    if (name === 'currentWeek')
      setParam({
        ...param,
        startDate: formatTime(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          dateFormat
        ),
        endDate: formatTime(
          endOfWeek(new Date(), { weekStartsOn: 1 }),
          dateFormat
        )
      });
    if (name === 'currentMonth')
      setParam({
        ...param,
        startDate: formatTime(startOfMonth(new Date()), dateFormat),
        endDate: formatTime(endOfMonth(new Date()), dateFormat)
      });
  };
  const getPic = async () => {
    const { data } = await warehouseService.getPic({
      keyword,
      page: 0,
      size: 5
    });
    if (!data?.items) return;

    setPic(data.items);
  };
  const handleInputChange = (e, val, reason) => {
    return reason === 'input' && setKeyword(val);
  };
  useEffect(() => {
    getPic();
  }, [keyword]);
  const getSchedules = async (param) => {
    const rs = await scheduleService.getScheduleList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { startDate, endDate, managerCode, page } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    setParam({
      ...param,
      startDate: startDate || formatTime(firstDayOfMonth, dateFormat),
      endDate:
        endDate || formatTime(add(new Date(), { months: 1 }), dateFormat),
      managerCode,
      page: page - 1 || 0
    });
    getSchedules({
      ...param,
      startDate: startDate || formatTime(new Date(), dateFormat),
      endDate:
        endDate || formatTime(add(new Date(), { months: 1 }), dateFormat),
      managerCode,
      page: page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getSchedules({ ...param, page: 0 });
    navigate(query);
  };

  const [errMg, setErrMg] = useState({
    managerCode: '',
    clientName: '',
    minibarNickname: ''
  });
  const dialogOk = async () => {
    let rs = null;
    const dataSubmit = {
      managerCode: gValue?.managerCode,
      shippingDate: gValue?.shippingDate,
      listScheduleDetail: listSchedule
        .filter((i) => i?.clientCode)
        .map((i) => {
          return {
            ...i,
            managerArea: gValue?.managerArea,
            managerZone: gValue?.managerZone,
            minibarCode: i?.minibarCode || '',
            minibarNickname: i?.minibarNickname || ''
          };
        })
    };
    rs = await scheduleService.createSchedule(dataSubmit);

    if (!rs?.error) {
      getSchedules({ ...param, page: 0 });
      setSelected({ ...selected, open: false });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    return true;
  };

  const handleSelectPic = async (val) => {
    if (!val) setKeyword('');
    setParam({ ...param, managerCode: val?.managerCode });
  };
  const handleClickRow = (e) => {
    navigate('detail', { state: { id: e?.schCode } });
  };
  const [loading, setLoading] = useState(false);
  const downloadScheduleToday = async () => {
    setLoading(true);
    const rs = await scheduleService.exportScheduleToday(param);
    if (rs?.error) dispatch(setError(error2Text(rs?.error)));
    setLoading(false);
  };
  return (
    <HelmetContainer title={t('pageTitle.schedule')} content="Release page">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('pages.product.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                width: 70,
                textAlign: 'center'
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {timeRadios.map(({ value, label }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={
                      <Radio
                        onClick={() => handleTimeRadio(value)}
                        checked={checkDateTimeRadio(value)}
                        size="small"
                      />
                    }
                    label={label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <MyAutocomplete
          name="picName"
          label={t('pages.display.pic')}
          isOptionEqualToValue={(option, value) =>
            option.managerName === value?.managerName
          }
          value={
            param?.managerCode
              ? pic?.filter((i) => i?.managerCode === param?.managerCode)?.[0]
              : null
          }
          getOptionLabel={(option) => option?.managerName || ''}
          onChange={(e, val) => handleSelectPic(val)}
          onInputChange={handleInputChange}
          options={pic}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={() => handleSearch()}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        mb={2}
        mt={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setListSchedule([
                {
                  index: 1,
                  clientChannel: '',
                  clientName: '',
                  clientCode: '',
                  minibarNickname: '',
                  minibarCode: '',
                  managerZone: '',
                  managerArea: ''
                }
              ]);
              setGValue({
                shippingDate: formatTime(new Date(), dateFormat),
                managerCode: null
              });
              setSelected({
                ...selected,
                open: true,
                title: t('pages.inventory.register')
              });
            }}
          >
            {t('common.register')}
          </Button>
          <Button
            variant="outlined"
            onClick={downloadScheduleToday}
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {state?.pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        hover
        rerender
        onRowClick={handleClickRow}
        data={state?.items}
        columns={configColumns}
        minusHeight={235}
        pagination={state?.pagination}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          if (!gValue?.managerCode) {
            setErrMg({ ...errMg, managerCode: t('common.required') });
            return false;
          }
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => dialogOk()
            })
          );
          return true;
        }}
        sx={{ maxHeight: '80vh', minHeight: 600, width: '70%' }}
        onClose={() => setErrMg({ ...errMg, managerCode: '' })}
      >
        <ScheduleForm
          value={listSchedule}
          setValue={setListSchedule}
          gValue={gValue}
          setGValue={setGValue}
          errMg={errMg}
          setErrMg={setErrMg}
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default Schedule;
