/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sub } from 'date-fns';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import MyLoadingButton from '@components/MyLoadingButton';
import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import Calendar from '@components/Calendar';
import { setError } from '@store/reducers/appReducer';
import { Formatter } from '@utils/formatter';
import { useLocation, useNavigate } from 'react-router-dom';
import error2Text from '@utils/error2Text';
import { objToParams, paramsToObj } from '@utils/helpers';
import salesStatisticsService from '@services/salesStatistics';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import {
  clientDatacolumns,
  monthlyDataColumns,
  memberColumns,
  purchasesColumns
} from './enhance';

const Sales = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({
    monthly: false,
    members: false,
    purchase: false
  });
  const [param, setParam] = useState({
    period: formatTime(sub(new Date(), { months: 0 }), 'YYYY-MM'),
    date: sub(new Date(), { months: 0 }),
    page: 0
  });

  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });

  const [minibar, setMinibar] = useState(null);
  const [clientStats, setClientStats] = useState([]);
  const [memberStats, setMemberStats] = useState([]);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [purchaseStats, setPurchaseStats] = useState([]);

  const [tab, setTab] = useState('1');

  const getMonthlyStats = async () => {
    setLoading({ ...loading, monthly: true });
    const rs = await salesStatisticsService.getMonthlyStats();
    if (!rs?.error) {
      setMonthlyStats(rs?.data);
    }
    setLoading({ ...loading, monthly: false });
  };

  const getMemberStats = async (param) => {
    setLoading({ ...loading, members: true });
    const rs = await salesStatisticsService.getMemberStats(param);
    if (!rs?.error) {
      setMemberStats(rs?.data);
    }
    setLoading({ ...loading, members: false });
  };

  const getPurchasestats = async (param) => {
    setLoading({ ...loading, purchase: true });
    const rs = await salesStatisticsService.getPurchaseStats(param);
    if (!rs?.error) {
      setPurchaseStats(rs?.data);
    }
    setLoading({ ...loading, purchase: false });
  };

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams({
      period: param?.period,
      page: page + 1
    });
    navigate(query);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  // clientSales
  const getSalesStatistics = async (param) => {
    const rs = await salesStatisticsService.getSalesStatistics(param);
    if (!rs?.error) {
      setClientStats(rs?.data?.result.items);
      console.log('clientData', rs?.data);
      setState({
        ...state,
        ...rs?.data,
        pagination: {
          ...state?.pagination,
          page: rs.data?.result.page || 0,
          totalRow: rs.data?.result.totalRow || 0,
          count: rs.data?.result.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setParam({
      ...param,
      period:
        query?.period || formatTime(sub(new Date(), { months: 0 }), 'YYYY-MM'),
      date: query?.period
        ? sub(new Date(query?.period), { months: 0 })
        : sub(new Date(), { months: 0 }),
      page: query?.page - 1 || 0,
      minibarCode: query?.minibarCode || ''
    });
    getSalesStatistics({
      period:
        query?.period || formatTime(sub(new Date(), { months: 0 }), 'YYYY-MM'),
      date: query?.period
        ? sub(new Date(query?.period), { months: 0 })
        : sub(new Date(), { months: 0 }),
      page: query?.page - 1 || 0,
      minibarCode: query?.minibarCode || minibar?.minibarCode || ''
    });
  }, [location?.search, minibar]);

  useEffect(() => {
    getMonthlyStats();
    getMemberStats();
    getPurchasestats();
  }, []);
  const handleSearch = () => {
    const query = objToParams({
      period: param?.period || '',
      page: 1,
      minibarCode: param?.minibarCode
    });
    navigate(query);
  };
  const rawData = async () => {
    const rs = await salesStatisticsService.exportSalesStatistics({
      period: param?.period
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs.error)));
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.salesStatistics')}
      content="salesStatistics page"
    >
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
          <Box sx={{ borderColor: 'divider', mb: 2 }}>
            <TabList onChange={handleChangeTab}>
              <Tab label={t('pages.statistics.byClient')} value="1" />
              <Tab label={t('pages.statistics.monthly')} value="2" />
              <Tab
                label={t('pages.statistics.membershipIndicator')}
                value="3"
              />
              <Tab label={t('pages.statistics.purchaseAmount')} value="4" />
            </TabList>
          </Box>
          {tab === '1' && (
            <SearchBar>
              <FormControl>
                <FormControlLabel
                  label={t('pages.product.period')}
                  labelPlacement="start"
                  sx={{
                    '.MuiTypography-root': {
                      color: '#6F869C',
                      fontWeight: 'fontWeightBold',
                      fontSize: 13,
                      whiteSpace: 'nowrap',
                      width: 70,
                      textAlign: 'center'
                    }
                  }}
                  control={
                    <Calendar
                      monthYear
                      condition="monthCurrent"
                      date={param?.date}
                      setDate={(date) =>
                        setParam({
                          ...param,
                          date,
                          period: formatTime(date, 'YYYY-MM')
                        })
                      }
                    />
                  }
                />
              </FormControl>
              <MinibarAutoCompleteV2
                required
                minibar={minibar}
                setMinibar={(item) => {
                  setMinibar(item);
                  setParam({ ...param, minibarCode: item?.minibarCode });
                }}
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              />
              <Button
                onClick={handleSearch}
                variant="contained"
                sx={{ ml: 1.5, width: '80px' }}
              >
                {t('button.search')}
              </Button>
            </SearchBar>
          )}
          <Stack
            mb={2}
            mt={2}
            flexDirection="row"
            alignItems="center"
            justifyContent={tab === '1' ? 'space-between' : 'flex-end'}
          >
            {tab === '1' && (
              <MyLoadingButton variant="outlined" onClick={rawData}>
                {t('pages.statistics.raw-data')}
              </MyLoadingButton>
            )}
            <Typography
              sx={{
                fontWeight: 'fontWeightSemiMedium',
                fontSize: 15,
                color: 'text.primary'
              }}
              component="span"
            >
              {t('common.number-of-results')}{' '}
              <Typography
                component="span"
                sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
              >
                {state?.pagination?.totalRow}
              </Typography>
            </Typography>
          </Stack>
          <TabPanel sx={{ m: 0, p: 0, mt: 2 }} value="1">
            <ClientStatistics
              data={clientStats}
              state={state}
              handleChangePage={handleChangePage}
            />
          </TabPanel>
          <TabPanel sx={{ m: 0, p: 0 }} value="2">
            <MonthlyStatistics data={monthlyStats} loading={loading.monthly} />
          </TabPanel>
          <TabPanel sx={{ m: 0, p: 0, mt: 2 }} value="3">
            <MemberStatistics data={memberStats} loading={loading.members} />
          </TabPanel>
          <TabPanel sx={{ m: 0, p: 0 }} value="4">
            <PurchaseStatistics
              data={purchaseStats}
              loading={loading.purchase}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </HelmetContainer>
  );
};

const ClientStatistics = ({ data, state, handleChangePage }) => {
  return (
    <Box>
      {data?.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 24,
            height: '30px !important',
            p: '10px 0 20px 0 !important'
          }}
          colSpan={5}
        >
          <i>로딩... {'  '}</i>
          <CircularProgress size={36} color="primary" />
        </Box>
      ) : (
        <GroupMultiHeaderTable
          columns={clientDatacolumns}
          data={data}
          pagination={state?.pagination}
          totalRow={state?.totalData}
          minusHeight={235}
          onChangePage={(e, page) => handleChangePage(page)}
        />
      )}
    </Box>
  );
};
const MonthlyStatistics = ({ data }) => {
  return (
    <Box>
      {data?.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px !important',
            fontSize: 24,
            p: '10px 0 20px 0 !important'
          }}
          colSpan={5}
        >
          <i>로딩... {'  '}</i>
          <CircularProgress size={26} color="primary" />
        </Box>
      ) : (
        <GroupMultiHeaderTable
          columns={monthlyDataColumns}
          data={data}
          minusHeight={100}
        />
      )}
    </Box>
  );
};
const MemberStatistics = ({ data }) => {
  return (
    <Box>
      {data?.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px !important',
            fontSize: 24,
            p: '10px 0 20px 0 !important'
          }}
          colSpan={5}
        >
          <i>로딩... {'  '}</i>
          <CircularProgress size={26} color="primary" />
        </Box>
      ) : (
        <GroupMultiHeaderTable
          columns={memberColumns}
          data={data}
          minusHeight={235}
        />
      )}
    </Box>
  );
};
const PurchaseStatistics = ({ data }) => {
  return (
    <Box>
      {data?.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px !important',
            fontSize: 24,
            p: '10px 0 20px 0 !important'
          }}
          colSpan={5}
        >
          <i>로딩... {'  '}</i>
          <CircularProgress size={26} color="primary" />
        </Box>
      ) : (
        <GroupMultiHeaderTable
          columns={purchasesColumns}
          data={data}
          minusHeight={100}
        />
      )}
    </Box>
  );
};
export default Sales;
