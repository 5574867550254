import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const common = i18n.getFixedT(null, null, 'common');
const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages.homepage');
const devStatus = i18n.getFixedT(null, null, 'enum.dev-request-status');

export const displayStatuslist = [
  { label: t('faq-category.all'), value: 'all' },
  { value: 'Y', label: t('displayed') },
  { value: 'N', label: t('no-display') }
];
export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'title',
    label: t('notice-title'),
    minWidth: 310,
    align: 'center'
  },
  {
    name: 'createdBy',
    label: t('register-admin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'createdDate',
    label: t('reg-dt'),
    minWidth: 150,
    align: 'center',
    render: ({ createdDate }) =>
      new Date(createdDate).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    name: 'updatedBy',
    label: t('edit-admin'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'editDt',
    label: t('edit-dt'),
    minWidth: 120,
    align: 'center',
    render: ({ updatedDate }) =>
      updatedDate
        ? new Date(updatedDate).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'isDisplayed',
    label: t('include-in-homepage'),
    minWidth: 150,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    name: 'delete',
    label: g('button.delete'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const productDetailSchema = [
  {
    name: 'createdBy',
    type: 'text',
    label: t('register-admin'),
    disabled: true,
    grid: 6,

    border: { borderLeft: 0 }
  },
  {
    name: 'updatedBy',
    type: 'text',
    label: t('edit-admin'),
    disabled: true,
    grid: 6,
    border: { borderLeft: '1px solid #D3D3D3' }
  },
  {
    name: 'createdDate',
    type: 'text',
    label: t('reg-dt'),
    disabled: true,
    grid: 6,
    border: { borderLeft: 0 },
    formatter: (val) => val && Formatter.formatTime(val)
  },
  {
    name: 'updatedDate',
    type: 'text',
    label: t('edit-dt'),
    disabled: true,
    grid: 6,
    formatter: (val) => val && Formatter.formatTime(val)
  },
  {
    name: 'title',
    type: 'text',
    label: t('notice-title'),
    grid: 12,
    border: { borderLeft: 0 }
  },

  {
    name: 'content',
    type: 'textarea',
    label: t('notice-content'),
    grid: 12,
    border: { borderLeft: 0 }
  }
];
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const initStatusList = [
  { label: common('all'), value: 'All' },
  { label: devStatus('Submit'), value: 'Submit' },
  { label: devStatus('Reviewed'), value: 'Reviewed' },
  { label: devStatus('InProgress'), value: 'InProgress' },
  { label: devStatus('Completed'), value: 'Completed' },
  { label: devStatus('Canceled'), value: 'Canceled' }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
