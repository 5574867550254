import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import MySelect from '@components/MySelect';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';

import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';

import performanceService from '@services/performance';
import { useLocation, useNavigate } from 'react-router-dom';
import { objToParams, paramsToObj } from '@utils/helpers';
import PerformanceGoalTable from './PerformanceGoalTable';
import ModifyPerformanceDialog from './ModifyPerformanceDialog';
import {
  getPerformanceItemRow,
  getPerformanceYearList,
  goalPerformanceCols
} from './enhance';

const Goal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState(null);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [selectedPerformance, setSelectedPeformance] = useState(null);

  const [items, setItems] = useState([
    getPerformanceItemRow(t, 'initialGoal', null),
    getPerformanceItemRow(t, 'archievedSales', null),
    getPerformanceItemRow(t, 'correctionGoal', null)
  ]);

  const getActionButtons = (item) => (
    <Stack direction="row">
      {item?.performanceType === 'archievedSales' ? null : (
        <Button onClick={() => handleOpenModifyDialog(item)} disabled={!data}>
          {t('button.modify')}
        </Button>
      )}
    </Stack>
  );

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setClient({
      ...client,
      clientCode: query?.clientCode || ''
    });
    setYear(query?.year || new Date().getFullYear());
    if (query?.clientCode) {
      getData({
        clientCode: query?.clientCode,
        year: query?.year || new Date().getFullYear()
      });
    } else {
      setData(null);
      setItems([
        getPerformanceItemRow(t, 'initialGoal', null),
        getPerformanceItemRow(t, 'archievedSales', null),
        getPerformanceItemRow(t, 'correctionGoal', null)
      ]);
    }
  }, [location?.search]);

  const getData = async (param) => {
    const res = await performanceService.getPerformanceGoal(param);
    if (res?.error) {
      dispatch(setError(error2Text(res.error)));
    }
    setData(res?.data);
    setItems([
      getPerformanceItemRow(t, 'initialGoal', res?.data?.initialGoal),
      getPerformanceItemRow(t, 'archievedSales', res?.data?.archievedSales),
      getPerformanceItemRow(t, 'correctionGoal', res?.data?.correctionGoal)
    ]);
  };

  const handleSearch = () => {
    const query = objToParams({
      clientCode: client?.clientCode,
      year
    });
    getData({
      clientCode: client?.clientCode,
      year
    });
    navigate(query);
  };

  const handleOpenModifyDialog = (item) => {
    setOpenModifyDialog(true);
    setSelectedPeformance(item);
  };

  return (
    <HelmetContainer title={t('pageTitle.goalManagement')}>
      <SearchBar>
        <ClientAutoCompleteV2
          client={client}
          setClient={setClient}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MySelect
          label={t('pages.performance-management.goal.labels.year')}
          data={getPerformanceYearList()}
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => handleSearch()}
          disabled={!client || !client?.clientCode}
        >
          {t('button.search')}
        </Button>
      </SearchBar>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          border: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'hidden',
          flexWrap: 'wrap',
          my: '30px'
        }}
      >
        {[
          {
            label: t('pages.performance-management.goal.labels.channel'),
            value: data?.clientChannel
          },
          {
            label: t('pages.performance-management.goal.labels.clientName'),
            value: data?.clientName
          }
        ].map(({ label, value }) => (
          <Stack
            key={label}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: '50px',
              flex: 1,
              '&:not(:first-of-type)': {
                borderLeft: '1px solid #EEEEEE'
              },
              borderBottom: '1px solid #EEEEEE'
            }}
          >
            <Stack
              sx={{
                bgcolor: '#FCFCFC',
                px: '12px',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <Typography
                sx={{
                  minWidth: '96px',
                  textAlign: 'center',
                  fontWeight: 'fontWeightBold',
                  whiteSpace: 'nowrap',
                  fontSize: 13,
                  color: '#6F869C'
                }}
              >
                {label}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ px: '12px', height: '100%', flex: 1 }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: 'text.primary'
                }}
                noWrap
              >
                {value}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <PerformanceGoalTable
        hover
        data={items}
        columns={goalPerformanceCols(t)}
        getActionButtons={getActionButtons}
      />

      <ModifyPerformanceDialog
        open={openModifyDialog}
        setOpen={setOpenModifyDialog}
        performance={selectedPerformance}
        handleAfterUpdating={() => handleSearch()}
      />
    </HelmetContainer>
  );
};

export default Goal;
