import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const EditArea = ({ onUpdateClick, onNavigateBack, loading }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      <LoadingButton
        variant="contained"
        onClick={onUpdateClick}
        loading={loading}
      >
        {t('button.modify')}
      </LoadingButton>
      <Button variant="outlined" onClick={onNavigateBack} disabled={loading}>
        {t('pages.development-request.list')}
      </Button>
    </Stack>
  );
};

export default EditArea;
