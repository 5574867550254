import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const warehouseService = {
  // Warehouse Stock
  async getWarehouseStockList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('warehouse-stock')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateWarehouseStock(code, data) {
    try {
      const res = await new RestService()
        .setPath(`warehouse-stock/${code}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportWarehouseStock(params = {}) {
    try {
      const res = await new RestService()
        .setPath('warehouse-stock/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportWarehouseInboundStock(params = {}) {
    try {
      const res = await new RestService()
        .setPath('stocking/export')
        .setResponseType('blob')
        .setConfig({ params })
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportWarehouseingItems(params = {}) {
    try {
      const res = await new RestService()
        .setConfig({ params })
        .setPath('warehousing/export')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSampleStock() {
    try {
      const res = await new RestService()
        .setPath('warehouse-stock/export-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadFileStock(data) {
    try {
      const res = await new RestService()
        .setPath(`warehouse-stock/import`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // Stocking & Unregistered
  async getStockingList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('stocking')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getWarehousingList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('warehousing')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getWarehousingListAll() {
    try {
      const res = await new RestService().setPath('warehousing/all').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStockingDetail(code) {
    try {
      const res = await new RestService().setPath(`stocking/${code}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // 입고 수량
  async updateQuantity(code, data) {
    try {
      const res = await new RestService()
        .setPath(`warehousing/${code}/quantity`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // 입고 유통기한
  async updateWarehousingExpDate(code, data) {
    try {
      const res = await new RestService()
        .setPath(`warehousing/${code}/expDate`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateWarehousingItemStatus(code, data) {
    try {
      const res = await new RestService()
        .setPath(`warehousing/${code}/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStockProduct(id, data) {
    try {
      const res = await new RestService().setPath(`stocking/${id}`).put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // 입고 주가
  async createWarehousingProduct(data) {
    try {
      const res = await new RestService().setPath(`warehousing`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createWarehousingRegister(data) {
    try {
      const res = await new RestService()
        .setPath(`warehousing/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createStockingRegister(data) {
    try {
      const res = await new RestService()
        .setPath(`stocking/warehouse-stock`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteStocking(code, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`stocking/${code}`)
        .setConfig({ params })
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // Stock Taking
  async getStockTakingList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('stocktaking')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async adjustStatus(id, data) {
    try {
      const res = await new RestService()
        .setPath(`stocktaking/${id}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStatus(id) {
    try {
      const res = await new RestService()
        .setPath(`stocktaking/${id}/status`)
        .put();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // Loss/Dispose
  async getLossList(data) {
    try {
      const res = await new RestService().setPath('/loss').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportLoss(data) {
    try {
      const res = await new RestService()
        .setPath('loss/export')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // Ulti
  async getPic(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/manager')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getCenter(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/center')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default warehouseService;
