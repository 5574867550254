import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';

import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import curationServices from '@services/curation';

import MyLoadingButton from '@components/MyLoadingButton';
import DetailTable from './DetailTable';
import OrderProductDetail from './OrderProductDetail';
import AddProductDialog from './AddProductDialog';

const CurationDetail = ({ t: pageT }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { curationId } = useParams();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const [selectedItem, setSelectedItem] = useState();

  const getData = async () => {
    const result = await curationServices.getCuration(curationId);
    setData(result.data?.data);
  };

  const handleDeleteButtonClick = (row) => {
    setOpen(true);
    setSelectedItem(row);
  };

  const handleDeleteOrderProductItem = async () => {
    const result = await curationServices.deleteCurationProduct(
      curationId,
      selectedItem?.productCode
    );
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      getData();
      setOpen(false);
    }
  };

  const handleOrderBtnClick = async () => {
    setLoading(true);
    const result = await curationServices.createCurationOrder(curationId);
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else getData();
  };

  const handleDownloadExcelFile = async () => {
    const result = await curationServices.exportFile({
      curationCode: curationId
    });
    if (result.error) dispatch(setError(error2Text(result.error)));
  };

  const handleUpdateCuration = async (data) => {
    const result = await curationServices.updateCuration(curationId, data);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      getData();
      setSuccessDialog(true);
    }
  };

  useEffect(() => {
    if (!open || !openAddProduct) getData();
  }, [curationId, open, openAddProduct]);

  return (
    <HelmetContainer
      title={t('pageTitle.curation-detail')}
      content="Curation Detail"
    >
      <DetailTable
        data={data}
        disabled={loading || data?.orderYn === 'Y'}
        setSuccessDialog={setSuccessDialog}
      />
      <OrderProductDetail
        handleDeleteRow={handleDeleteButtonClick}
        data={data?.listProduct || []}
        onChange={(data) => {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleUpdateCuration(data)
            })
          );
        }}
        disabled={loading || data?.orderYn === 'Y'}
      />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" justifyContent="center" spacing={1}>
          <MyLoadingButton variant="outlined" onClick={handleDownloadExcelFile}>
            {pageT('download-excel-file')}
          </MyLoadingButton>
          <Button
            variant="outlined"
            onClick={() => setOpenAddProduct(true)}
            disabled={loading || data?.orderYn === 'Y'}
          >
            {pageT('add-product')}
          </Button>
        </Stack>
        <LoadingButton
          variant="contained"
          onClick={() => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'save',
                action: () => handleOrderBtnClick()
              })
            );
          }}
          disabled={loading || data?.orderYn === 'Y'}
        >
          {pageT('order')}
        </LoadingButton>
      </Stack>
      <MyDialog
        open={open}
        setOpen={setOpen}
        isAlert
        title={t('common.confirm-delete-title', {
          item: selectedItem?.productName
        })}
        hasCancelButton
        onOk={handleDeleteOrderProductItem}
      />
      <MyDialog
        isAlert
        title={t('dialog.title.modifySuccessfully')}
        open={successDialog}
        setOpen={setSuccessDialog}
        hasCloseButton
      />
      {openAddProduct && (
        <AddProductDialog
          open={openAddProduct}
          setOpen={setOpenAddProduct}
          curationId={curationId}
          curationData={data}
        />
      )}
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.curation' })(
  CurationDetail
);
