import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';
import fallbackImg from '@svg/fallback-img.svg';
import env from '@constants/env';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import storeService from '@services/pre-order/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setPreorderStore } from '@store/reducers/preorderReducer';
import { useDispatch } from 'react-redux';
import AlertDialog from '@components/MyDialog/AlertDialog';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import ProductDetailForm from './ProductDetailForm';

const requiredFields = ['productName', 'price', 'description'];
const ProductDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { storeCode, productCode } = useParams();
  const [options, setOptions] = useState({});
  const [formValue, setFormValue] = useState({
    productName: '',
    preorderLocale: 'ko',
    price: '',
    description: '',
    productInfos: '',
    productImage: {
      src: '',
      name: '',
      file: null
    }
  });

  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [optionsData, setOptionsData] = useState({});

  const [hasProductUpdated, setHasProductUpdated] = useState(false);

  const getStoreDetail = async () => {
    if (location?.state?.action !== 'register') {
      const rs = await storeService.getStoreProductDetail(
        storeCode,
        productCode
      );

      setFormValue({
        ...formValue,
        ...rs?.data,
        productImage: {
          name: t('pages.product.image'),
          src: rs?.data?.imageUrl
            ? `${env.s3BaseUrl}/${rs?.data?.imageUrl}`
            : fallbackImg,
          file: null
        }
      });

      setOptionsData((prev) => {
        const convertedOptions = rs?.data?.productInfo?.reduce((acc, i) => {
          return {
            ...acc,
            [i?.fieldCode]: i?.infoSelectionCode || i?.fieldText
          };
        }, {});

        return {
          ...prev,
          ...convertedOptions
        };
      });
    }
    getOptionsList();
  };

  const getOptionsList = async () => {
    const rs = await storeService.getStoreOptions(
      location?.state?.storeTypeCode
    );
    if (rs?.data) {
      const refactoredOptions = rs?.data?.map((item) => {
        if (item?.isSelectable) {
          const updatedSelectOptions = item?.options?.map((i) => ({
            label: i?.optionName,
            value: i?.optionCode
          }));
          return {
            ...item,
            name: item?.productInfoFieldCode,
            type: 'select',
            label: item?.fieldName,
            options: updatedSelectOptions,
            fullWidth: true,
            grid: 12,
            border: { borderLeft: 0, borderRight: '0 !important' }
          };
        }
        return {
          ...item,
          name: item?.productInfoFieldCode,
          type: 'text',
          label: item?.fieldName,
          fullWidth: true,
          grid: 12,
          border: { borderLeft: 0, borderRight: '0 !important' }
        };
      });
      setOptions(refactoredOptions || []);
    }
  };

  useEffect(() => {
    getStoreDetail();
  }, []);

  const isValidForm = () => {
    let isValid = true;
    requiredFields.forEach((field) => {
      if (!formValue[field]) {
        isValid = false;
      }
    });
    if (Number(formValue?.price) < 1000) {
      setAlertDialog({
        open: true,
        okTitle: '확인',
        subtitle: t('pages.pre-order.modal.price-not-valid'),
        type: 'edit'
      });
      isValid = false;
    }
    return isValid;
  };
  const isValidOptions = () => {
    let isValid = true;
    if (options?.length > 0)
      options.forEach((field) => {
        if (!optionsData[field?.name]) {
          isValid = false;
        }
      });
    return isValid;
  };
  const onAction = async (action) => {
    if (action === 'cancel') {
      if (!hasProductUpdated && !formValue?.productCode) {
        const rs = await storeService?.deleteStoreTemporaryProduct(
          storeCode,
          productCode
        );
        if (rs?.error) {
          dispatch(setError(error2Text(rs?.error)));
        }
      }
      navigate(-1);
    }
    if (action === 'register' || action === 'modification') {
      const formattedData = options.map((item) => ({
        fieldCode: item.productInfoFieldCode,
        isSelectable: item.isSelectable,
        ...(item?.isSelectable && {
          infoSelectionCode: optionsData[item.name]
        }),
        fieldText: item?.isSelectable ? null : optionsData[item.name] || ''
      }));

      const dataSubmit = {
        productName: formValue?.productName,
        price: formValue?.price,
        description: formValue?.description,
        minibarProductCode: formValue?.minibarProduct?.productCode || '',
        productInfos: formattedData
      };

      if (isValidForm(dataSubmit) && isValidOptions()) {
        const storeCode = formValue?.storeCode || location?.state?.storeCode;

        let imageUrl = null;
        if (formValue.productImage && formValue?.productImage?.file) {
          const uploadImage = async () => {
            const formData = new FormData();
            formData.append('mainPhotoFile', formValue.productImage?.file); // Directly using the single image

            const uploadResult = await storeService.uploadProductMainImage(
              storeCode,
              productCode,
              formData
            );

            if (uploadResult.error) {
              throw dispatch(error2Text(uploadResult.error));
            }
            return uploadResult.location;
          };

          try {
            imageUrl = await uploadImage();
          } catch (error) {
            dispatch(setError(error.message));
            return;
          }
        }

        if (imageUrl || formValue?.imageUrl) {
          const rs = await storeService.updateStoreProduct(
            storeCode,
            productCode,
            dataSubmit
          );

          if (!rs?.error) {
            dispatch(setSuccess({ message: t('info.success.content') }));
            setHasProductUpdated(true);
            setTimeout(() => {
              navigate(-1, {
                state: {
                  ...location?.state
                }
              });
              dispatch(
                setPreorderStore({
                  tab: '2',
                  storeCode: location?.state?.storeCode || formValue?.storeCode,
                  storeCategoryCode:
                    location?.state?.productCategoryCode ||
                    formValue?.productCategoryCode
                })
              );
            }, 500);
          } else {
            dispatch(setError(error2Text(rs.error)));
          }
        }
      }
    }
  };
  return (
    <HelmetContainer
      title={
        location?.state?.action === 'register'
          ? `${t('pages.inventory.product')} ${t('button.register')}`
          : `${t('pages.inventory.product')}  ${t('button.modify')}`
      }
      content="careers detail page"
      sx={{ width: '100%', height: '100%' }}
    >
      <ProductDetailForm
        onAction={onAction}
        value={formValue}
        setValue={setFormValue}
        storeData={location?.state}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        options={options || []}
        isValidForm={isValidForm}
        isValidOptions={isValidOptions}
        requiredFields={requiredFields}
      />

      <AlertDialog
        open={alertDialog.open}
        setOpen={() => {
          setAlertDialog({
            ...alertDialog,
            open: false,
            onOk: null
          });
        }}
        onOk={() =>
          setAlertDialog({
            ...alertDialog,
            open: false,
            onOk: null
          })
        }
        okTitle={t('button.check')}
        isAlert
        sx={{ minWidth: '350px' }}
        title={null}
        type={alertDialog?.type}
        subTitle={
          <Typography
            sx={{
              fontSize: 15,
              color: '#000000',
              fontWeight: 400,

              textAlign: 'center',
              whiteSpace: 'pre'
            }}
          >
            {alertDialog?.subtitle}
          </Typography>
        }
      />
    </HelmetContainer>
  );
};

export default ProductDetail;
