import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useMergeState from '@hooks/useMergeState';
import useAuthorization from '@hooks/useAuthorization';
import { t } from 'i18next';

import {
  Box,
  Button,
  Container,
  Hidden,
  Stack,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import MyDialog from '@components/MyDialog';
import MySelect from '@components/MySelect';
import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';
import HelmetContainer from '@components/HelmetContainer';

import { dateFormat, scopes } from '@constants/utils';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';

import { downloadFileFromBinary } from '@utils/downloadFile';
import MyLoadingButton from '@components/MyLoadingButton';
import { isEmail, isKoreanPhoneNumber } from '@utils/boolean';
import userServices from '@services/user';
import vendorService from '@services/vendor';
import { vendorModifySchema } from './enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};

const maxLengthOf = {
  vendorName: 50,
  picName: 10,
  picContact: 20,
  vendorType: 100,
  vendingProduct: 100,
  bank: 20,
  bankAccountNumber: 100,
  picEmail: 50,
  businessNumber: 50
};

const VendorModify = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { formatTime } = Formatter;
  const pathnameArr = location?.pathname?.split('/');
  const vendorCode = pathnameArr[pathnameArr.length - 1];
  const [fileName, setFileName] = useState({
    businessFileName: '',
    file: ''
  });
  const [tempFile, setTempFile] = useState({ name: '', file: '' });
  const [taxFile, setTaxFile] = useState(null);
  const [value, setValue] = useState(null);
  const [errMsg, setErrMsg] = useMergeState({
    vendorName: '',
    picName: '',
    picContact: '',
    vendorType: '',
    vendingProduct: '',
    bank: '',
    bankAccountNumber: '',
    picEmail: '',
    businessNumber: ''
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItem, setSelectdItem] = useState(null);
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);

  const getVendorDetail = async () => {
    const rs = await vendorService.getVendorDetail(vendorCode);
    if (!rs?.error) {
      setValue({ ...value, ...rs?.data, businessFile: tempFile?.file || '' });
      setFileName({
        ...fileName,
        file: '',
        businessFileName: tempFile?.name || rs?.data?.businessFileName,
        businessFileLink: rs?.data?.businessFileLink
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const onChange = (e) => {
    const { name, value: valuee } = e.target;
    setValue({ ...value, [name]: valuee });

    let msg = '';
    if (!valuee && name === 'vendorName') {
      msg = t('common.required');
    } else if (valuee?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };

  const handleUpload = (name, e) => {
    const file = e.target.files[0];
    if (name === 'businessFileName') {
      setTempFile({
        name: file?.name,
        file
      });
      setFileName({ ...fileName, businessFileName: file?.name });
      setValue({ ...value, businessFile: file });
    }
    if (name === 'file') {
      setFileName({ ...fileName, file: file?.name });
      setTaxFile(file);
    }
  };

  const checkValidate = (data) => {
    let isValidate = true;
    const vldErr = { ...errMsg };

    if (!data?.vendorName) {
      vldErr.vendorName = t('common.required');
      isValidate = false;
    }
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });

    setErrMsg(vldErr);
    return isValidate;
  };

  const handleModify = async () => {
    const dataSubmit = new FormData();
    const keys = Object.keys(value);
    const values = Object.values(value);
    keys.forEach((key, idx) => {
      if (values[idx]) dataSubmit.append([key], values[idx]);
    });
    const rs = await vendorService.editVendor(vendorCode, dataSubmit);
    if (!rs?.error) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    return true;
  };

  const convertMsToDate = (ms) => {
    const date = new Date(+ms);
    return formatTime(date, dateFormat);
  };

  const getFileNameTaxBill = (name) => {
    const idx = name?.indexOf('-');
    return name?.slice(idx + 1);
  };

  const handleUploadTaxBill = async () => {
    if (taxFile) {
      const dataSubmit = new FormData();
      dataSubmit.append('taxBillFile', taxFile);
      const rs = await vendorService.uploadTaxFile(vendorCode, dataSubmit);
      if (!rs?.error) {
        setTaxFile(null);
        getVendorDetail();
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };

  const handleDeleteTaxBill = async () => {
    const rs = await vendorService.deleteTaxBill(vendorCode, selectedItem?.id);
    if (!rs?.error) {
      setOpenConfirmDialog(false);
      getVendorDetail();
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const handleDownloadFile = async (fileName, fileLink) => {
    userServices.getFile({ fileName, fileLink }).then(({ data }) => {
      if (data) downloadFileFromBinary(data, fileName);
    });
  };

  useEffect(() => {
    getVendorDetail();
  }, []);

  return (
    <HelmetContainer
      title={t('pageTitle.vendorModify')}
      content="vendorModify page"
    >
      <Container component={BorderBox}>
        <Grid container>
          {vendorModifySchema.map((item) => {
            return !item?.children ? (
              <Grid md={item.grid} width="100%">
                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: 'auto' },
                    height: '100%',
                    flexGrow: 1,
                    borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                    ...item.border
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      flexGrow: 1,
                      padding: '0 10px',
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: 150,
                        fontSize: 13,
                        color: '#6F869C',
                        textAlign: 'center'
                      }}
                    >
                      {item.label}
                      {item.required && (
                        <span style={{ color: 'red', marginLeft: 3 }}>*</span>
                      )}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center" width="100%">
                    {item?.name === 'businessFileName' && (
                      <Tooltip title="다운로드">
                        <Typography
                          display={fileName.businessFileName ? 'flex' : 'none'}
                          alignItems="center"
                          marginLeft={2}
                          sx={{
                            color: 'primary.darker',
                            fontWeight: 'fontWeightBold',
                            fontSize: 13,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            ml: 2,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            userSelect: 'none'
                          }}
                          width={{
                            sm: 140,
                            xs: 140,
                            md: 300,
                            lg: 'auto'
                          }}
                          onClick={() =>
                            handleDownloadFile(
                              getFileNameTaxBill(fileName.businessFileName),
                              fileName?.businessFileLink
                            )
                          }
                        >
                          {getFileNameTaxBill(fileName.businessFileName)}
                        </Typography>
                      </Tooltip>
                    )}
                    <RenderField
                      onChangeInput={
                        item.type === 'upload'
                          ? (e) => handleUpload(item.name, e)
                          : (e) => onChange(e)
                      }
                      value={value}
                      errMsg={errMsg?.[item?.name]}
                      setErrMsg={setErrMsg}
                      {...item}
                    />
                  </Stack>
                </Stack>
              </Grid>
            ) : (
              <>
                <Hidden smUp>
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 15,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                  </Typography>
                </Hidden>
                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: { xs: '100%', md: 'auto' },
                    flexGrow: 1,
                    borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                    ...item.border
                  }}
                >
                  <Hidden mdDown>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: '0 10px',
                        ...labelStyle
                      }}
                    >
                      <Typography
                        sx={{
                          width: 150,
                          fontSize: 15,
                          color: '#6F869C',
                          textAlign: 'center'
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Stack>
                  </Hidden>
                  <Grid container xs={12}>
                    {item?.children?.map((child) => (
                      <Grid
                        md={12}
                        lg={child.grid}
                        width="100%"
                        key={child.label}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                            width: { xs: '100%', md: '100%' },
                            height: '100%',
                            flexGrow: 1,
                            borderBottom: {
                              xs: 'none',
                              md: '1px solid #D3D3D3'
                            },
                            ...child.border
                          }}
                        >
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              flexGrow: 1,
                              padding: '0 10px',
                              ...labelStyle
                            }}
                          >
                            <Typography
                              sx={{
                                width: 150,
                                fontSize: 13,
                                color: '#6F869C',
                                textAlign: 'center'
                              }}
                            >
                              {child.label}
                            </Typography>
                          </Stack>
                          <Stack
                            width="100%"
                            flexDirection={
                              child.name === 'file' ? 'row-reverse' : 'row'
                            }
                            justifyContent={
                              child.name === 'file' ? 'flex-end' : 'flex-start'
                            }
                            alignItems="center"
                            position="relative"
                            maxWidth="100%"
                          >
                            {child.name === 'file' && (
                              <MyLoadingButton
                                variant="contained"
                                sx={{
                                  position: 'absolute',
                                  right: 16,
                                  height: 38
                                }}
                                onClick={handleUploadTaxBill}
                                disabled={!isAuthorized}
                              >
                                {t('button.upload')}
                              </MyLoadingButton>
                            )}
                            {child.type === 'upload' && (
                              <Typography
                                sx={{
                                  color: 'primary.darker',
                                  fontWeight: 'fontWeightBold',
                                  fontSize: 13,
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  ml: 2,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                width={{
                                  sm: 140,
                                  xs: 140,
                                  md: 300,
                                  lg: 'auto'
                                }}
                              >
                                {fileName?.file}
                              </Typography>
                            )}
                            <RenderField
                              onChangeInput={
                                child.type === 'upload'
                                  ? (e) => handleUpload(child.name, e)
                                  : (e) => onChange(e)
                              }
                              value={value}
                              errMsg={errMsg?.[child?.name]}
                              setErrMsg={setErrMsg}
                              {...child}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                    {value?.listTaxBill?.length > 0 &&
                      value?.listTaxBill.map((item, idx) => (
                        <Grid
                          md={12}
                          lg={12}
                          width="100%"
                          key={item.businessFileName}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              borderLeft: {
                                xs: 'none',
                                md: '1px solid #D3D3D3'
                              },
                              width: { xs: '100%', md: '100%' },
                              flexGrow: 1,
                              borderBottom: {
                                xs: 'none',
                                md:
                                  value?.listTaxBill?.length === idx + 1
                                    ? 'none'
                                    : '1px solid #D3D3D3'
                              }
                            }}
                          >
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                flexGrow: 1,
                                padding: '0 10px',
                                ...labelStyle
                              }}
                            >
                              <Typography
                                sx={{
                                  width: 150,
                                  fontSize: 13,
                                  color: '#6F869C',
                                  textAlign: 'center'
                                }}
                              >
                                {convertMsToDate(
                                  item?.businessFileName.split('-')[0]
                                )}
                              </Typography>
                            </Stack>
                            <Stack
                              width="100%"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                              position="relative"
                              maxWidth="100%"
                              p={2}
                            >
                              <Tooltip title="다운로드">
                                <Typography
                                  sx={{
                                    color: 'primary.darker',
                                    fontWeight: 'fontWeightBold',
                                    fontSize: 13,
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    ml: 2,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    userSelect: 'none'
                                  }}
                                  width={{
                                    sm: 140,
                                    xs: 140,
                                    md: 300,
                                    lg: 'auto'
                                  }}
                                  onClick={() =>
                                    handleDownloadFile(
                                      getFileNameTaxBill(item.businessFileName),
                                      item?.businessFileLink
                                    )
                                  }
                                >
                                  {getFileNameTaxBill(item.businessFileName)}
                                </Typography>
                              </Tooltip>

                              <Button
                                sx={{ height: 38 }}
                                variant="outlined"
                                onClick={() => {
                                  setOpenConfirmDialog(true);
                                  setSelectdItem(item);
                                }}
                                disabled={!isAuthorized}
                              >
                                {t('button.delete')}
                              </Button>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                  </Grid>
                </Stack>
              </>
            );
          })}
        </Grid>
      </Container>
      <Stack flexDirection="row" justifyContent="center" gap={2} m={4}>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          {t('button.cancel')}
        </Button>
        <MyLoadingButton
          variant="contained"
          onClick={() => {
            if (checkValidate(value)) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'modify',
                  action: () => handleModify()
                })
              );
            }
          }}
          disabled={!isAuthorized}
        >
          {t('button.modify')}
        </MyLoadingButton>
      </Stack>
      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        isAlert
        title={t('dialog.title.areYouSureDeleteThisRecord')}
        hasCancelButton
        onOk={handleDeleteTaxBill}
      />
    </HelmetContainer>
  );
};

const RenderField = ({ data, value, errMsg, setErrMsg, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, ...rest } = item;

  if (item.type === 'upload')
    return (
      <InputFile
        title={t('common.select')}
        dataFile={value?.dataFile}
        onChange={onChangeInput}
        sx={{ root: { height: 60 } }}
      />
    );

  if (item.type === 'select') {
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          value={value?.[rest?.name] || 'N'}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ minWidth: '100%' }}
        />
      </Stack>
    );
  }

  const validatePhoneAndEmail = (e) => {
    const { name, value: valuee } = e.target;
    if (valuee) {
      if (name === 'picEmail' && !isEmail(valuee)) {
        setErrMsg({ picEmail: t('validation.invalidEmailAddress') });
      } else if (name === 'picContact' && !isKoreanPhoneNumber(valuee)) {
        setErrMsg({ picContact: t('validation.invalidPhoneNumber') });
      }
    }
  };

  return (
    <MyTextField
      name={rest?.name}
      value={value?.[rest?.name] || ''}
      onChange={onChangeInput}
      typeValue={rest?.name === 'picContact' && 'onlyNumber'}
      onBlur={validatePhoneAndEmail}
      errMg={errMsg}
      sx={{ ...inputStyle }}
      {...rest}
    />
  );
};

export default VendorModify;
