import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import MyTable from '@components/MyTable';
import MyAutocomplete from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { dateFormat, scopes } from '@constants/utils';
import productService from '@services/product';
import categoryService from '@services/category';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import jwtDecode from 'jwt-decode';
import MyLoadingButton from '@components/MyLoadingButton';
import { columns, productFilterConditionList } from './enhance';
import MinibarStatusDialog from './MinibarStatusDialog';
import ExcelRegisterDialog from './ExcelRegisterDialog';

const Product = ({ t: pageT }) => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openMinibarStatus, setOpenMinibarStatus] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [category, setCategory] = useState({
    first: [],
    second: [],
    third: [],
    special: []
  });
  const getCategoryList = async () => {
    const rs = await categoryService.getCategory();

    // const keys = Object.keys(rs.data);
    // const values = Object.values(rs.data);
    // convert catgeroy list ot select option list
    // for (let i = 0; i < rs?.data.length; i += 1) {

    // }
    const categoryConvertOptions = rs.data.map((item) => {
      return {
        value: item.categoryCode,
        label: item.categoryName,
        category2List: item.categories2List
      };
    });
    console.log('category =>>>>>>>', categoryConvertOptions);
    setCategory({
      ...category,
      first: categoryConvertOptions
    });
  };

  const [condition, setCondition] = useState('productName');
  const [categorySelected, setCategorySelected] = useState({
    category1: { value: '', label: '' },
    category2: { value: '', label: '' },
    category3: { value: '', label: '' }
  });

  const [param, setParam] = useState({
    category1Code: null,
    category2Code: null,
    category3Code: null,
    productName: null,
    barcode: null,
    maker: null,
    vendorName: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [minibarStatus, setMinibarStatus] = useState([]);
  const getProductsList = async (param) => {
    const paramSubmit = {};
    const keys = Object.keys(param);
    const values = Object.values(param);
    keys.forEach((item, index) => {
      if (values[index]) paramSubmit[item] = values[index];
    });
    const rs = await productService.getProducts(paramSubmit);
    if (!rs.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userFromToken = jwtDecode(token);
    console.log('userFromToken', userFromToken);

    if (
      userFromToken?.scope?.includes(scopes.SUPER) ||
      userFromToken?.scope?.includes(scopes.ADMIN_PRODUCT_MASTER)
    ) {
      setIsAuthorized(true);
    }
  }, []);

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      category1Code,
      category2Code,
      category3Code,
      productName,
      barcode,
      maker,
      vendorName,
      page
    } = query;
    const dataParams = {
      category1Code,
      category2Code,
      category3Code,
      productName,
      barcode,
      maker,
      vendorName,
      page: page - 1 || 0
    };
    setCondition(
      (barcode && 'barcode') ||
        (maker && 'maker') ||
        (vendorName && 'vendorName') ||
        'productName'
    );
    setParam(dataParams);
    getProductsList(dataParams);
  }, [location?.search]);

  const { items, pagination } = state;

  const getMinibarStatus = async (item) => {
    const rs = await productService.getStatusCode(item?.productCode);
    setMinibarStatus(rs?.data);
    setOpenMinibarStatus(true);
  };

  const handleRowClick = (row) => {
    navigate(row?.productCode);
  };

  const handleDownloadExcel = async () => {
    const rs = await productService.exportEntireProduct(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const handleDownloadExcelByMinibar = async () => {
    const dataSubmit = {
      minibarCode: param.minibarCode || null,
      startDate: param.startDate,
      endDate: param.endDate
    };
    const rs = await productService.exportPriceByMinibar(dataSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const handleDownloadRawData = async () => {
    const rs = await productService.exportRaw(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const getActionButtons = (item) => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          getMinibarStatus(item);
        }}
        size="small"
        sx={{
          fontSize: 13,
          height: '36px',
          px: '10px'
        }}
      >
        {t('button.status-by-minibar')}
      </Button>
    );
  };

  const onAction = (action, value) => {
    switch (action) {
      case 'changePage':
        setParam({ ...param, page: value });
        // eslint-disable-next-line no-case-declarations
        const query = objToParams(ignoreEmpty({ ...param, page: value + 1 }));
        getProductsList({ ...param, page: value });
        navigate(query);
        break;

      case 'selectCondition':
        setCondition(value);
        setParam({
          ...param,
          productName: '',
          vendorName: '',
          barcode: '',
          maker: ''
        });
        break;

      case 'typingSearch':
        setParam({
          ...param,
          [condition]: value
        });
        break;

      case 'search':
        // eslint-disable-next-line no-case-declarations
        const searchQuery = objToParams(ignoreEmpty({ ...param, page: 1 }));
        getProductsList({ ...param, page: 0 });
        navigate(searchQuery);
        break;

      case 'category1Code':
        if (!value?.value) {
          setCategory({ ...category, second: [], third: [] });
          setParam({
            ...param,
            [action]: value ? value.value : null,
            category2Code: null,
            category3Code: null
          });
          break;
        }
        // eslint-disable-next-line no-case-declarations
        const category1ConvertOptions = value?.category2List.map((item) => {
          return {
            value: item.categoryCode,
            label: item.categoryName,
            category3List: item.categories3List
          };
        });
        setCategory({
          ...category,
          second: category1ConvertOptions || [],
          third: []
        });

        setCategorySelected({
          ...categorySelected,
          [action.replace('Code', '')]: value || { value: '', label: '' }
        });
        setParam({
          ...param,
          [action]: value ? value.value : null
        });
        break;

      case 'category2Code':
        if (!value?.value) {
          setCategory({ ...category, third: [] });
          setParam({
            ...param,
            [action]: value ? value.value : null,
            category3Code: null
          });
          break;
        }
        // eslint-disable-next-line no-case-declarations
        const category2ConvertOptions = value?.category3List.map((item) => {
          return { value: item.categoryCode, label: item.categoryName };
        });
        setCategory({ ...category, third: category2ConvertOptions });

        setCategorySelected({
          ...categorySelected,
          [action.replace('Code', '')]: value || { value: '', label: '' }
        });
        setParam({
          ...param,
          [action]: value ? value.value : null
        });
        break;

      case 'category3Code':
        setCategorySelected({
          ...categorySelected,
          [action.replace('Code', '')]: value || { value: '', label: '' }
        });
        setParam({
          ...param,
          [action]: value ? value.value : null
        });
        break;

      case 'selectPeriod':
        if (value?.key === 'selection') {
          setParam({
            ...param,
            startDate: formatTime(value.startDate, dateFormat),
            endDate: formatTime(value.endDate, dateFormat)
          });
        }
        break;

      case 'upload-success':
        getProductsList(param);
        break;

      // Add more cases as needed

      default:
        // Default case
        break;
    }
  };

  return (
    <HelmetContainer
      title={t('pages.inventory.product')}
      content="Product Management"
    >
      <SearchBar>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ flexWrap: 'wrap', ml: 2 }}
        >
          <Typography
            sx={{
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }}
          >
            {pageT('category')}
          </Typography>
          {console.log(category?.first)}
          <MyAutocomplete
            textFieldProps={{
              placeholder: pageT('first-category')
            }}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option.label || ''}
            onChange={(e, val) => onAction('category1Code', val)}
            options={category?.first}
            sx={{ width: 160, flex: 1 }}
            value={{
              value: param?.category1Code,
              label:
                category?.first?.filter(
                  (i) => i?.value === param?.category1Code
                )?.[0]?.label || ''
            }}
            onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
          />
          <MyAutocomplete
            textFieldProps={{
              placeholder: pageT('second-category')
            }}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option.label || ''}
            onChange={(e, val) => onAction('category2Code', val)}
            options={category?.second}
            sx={{ width: 160, flex: 1 }}
            value={{
              value: param?.category2Code,
              label:
                category?.second?.filter(
                  (i) => i?.value === param?.category2Code
                )?.[0]?.label || ''
            }}
            onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
          />
          <MyAutocomplete
            textFieldProps={{
              placeholder: pageT('third-category')
            }}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            getOptionLabel={(option) => option.label || ''}
            onChange={(e, val) => onAction('category3Code', val)}
            options={category?.third}
            sx={{ width: 160, flex: 1 }}
            value={{
              value: param?.category3Code,
              label:
                category?.third?.filter(
                  (i) => i?.value === param?.category3Code
                )?.[0]?.label || ''
            }}
            onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
          />
        </Stack>
        <Stack flexDirection="row" gap={1}>
          <MySelect
            label={pageT('condition')}
            value={condition}
            data={productFilterConditionList}
            onChange={(e) => onAction('selectCondition', e.target.value)}
            sx={{
              mr: 1.5
            }}
          />

          <Stack flex={1} sx={{ maxWidth: '50%' }}>
            <MyTextField
              placeholder={pageT('input-keyword')}
              value={param[condition] || ''}
              onChange={(e) => onAction('typingSearch', e.target.value)}
              onKeyPress={({ charCode }) =>
                charCode === 13 && onAction('search')
              }
            />
          </Stack>
        </Stack>

        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={() => onAction('search')}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        sx={{ mt: '30px', flexWrap: 'wrap' }}
      >
        <Stack direction="row" gap={1} sx={{ flexWrap: 'wrap' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('register')}
            disabled={!isAuthorized}
          >
            {t('button.produce-register')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenUploadFile(true)}
            disabled={!isAuthorized}
          >
            {t('button.excel-register')}
          </Button>
          {!param?.minibarCode && (
            <MyLoadingButton variant="outlined" onClick={handleDownloadExcel}>
              {t('button.download')}
            </MyLoadingButton>
          )}
          <MyLoadingButton
            variant="outlined"
            onClick={handleDownloadExcelByMinibar}
            disabled={!isAuthorized}
          >
            {t('button.price-by-minibar')}
          </MyLoadingButton>
          <MyLoadingButton variant="outlined" onClick={handleDownloadRawData}>
            {t('button.raw-data')}
          </MyLoadingButton>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover={isAuthorized && 'hover'}
        rerender
        onRowClick={isAuthorized && handleRowClick}
        getActionButtons={getActionButtons}
        columns={columns}
        data={items || []}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
      {/* Dialogs */}
      <MinibarStatusDialog
        data={minibarStatus}
        open={openMinibarStatus}
        setOpen={setOpenMinibarStatus}
      />
      <ExcelRegisterDialog
        open={openUploadFile}
        setOpen={setOpenUploadFile}
        key={openUploadFile}
        onAction={onAction}
      />
      {/* Dialogs */}
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.product' })(Product);
