import { tableTypes } from '@constants/utils';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.log');

export const columnUsageHistory = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: t('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'msg',
    label: t('message'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'regDate',
    label: t('date-occurrence'),
    minWidth: 160,
    align: 'center',
    formatter: (date) => {
      return new Date(date).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      });
    }
  },
  {
    name: 'isChecked',
    label: t('confirm'),
    minWidth: 100,
    align: 'center',
    type: tableTypes.CHECK_MARK,
    icon: <CheckSharpIcon fontSize="medium" />
  }
];
