import DownloadableText from '@components/DownloadableText';
import { Box, Typography, styled } from '@mui/material';

export const apkConfigColumns = (selectApplication, t) => [
  {
    name: 'appGroup',
    label: t('pages.apk.labels.appGroup'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    dataKey: 'appCode',
    groupBy: 'appGroup'
  },
  {
    name: 'appNickname',
    label: t('pages.apk.labels.appNickname'),
    minWidth: 160,
    align: 'center',
    onClick: ({ appCode }) => selectApplication(appCode),
    render: (item) => (
      <Typography sx={{ cursor: 'pointer' }}>
        {item?.appNickname || ''}
      </Typography>
    )
  },
  {
    name: 'appVersion',
    label: t('pages.apk.labels.appVersion'),
    minWidth: 160,
    align: 'center',
    render: (item) => (
      <DownloadableText
        fileName={item?.appLink?.split('/')?.pop()}
        fileLink={item?.appLink}
        sx={{ textDecoration: 'none' }}
      >
        {item?.appVersion}
      </DownloadableText>
    )
  }
];
export const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  overflowY: 'auto'
});
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 135
    },
    td: {
      p: '10px 12px',
      width: 270
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};
