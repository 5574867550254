import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';

const MySelect = ({
  label,
  multiple,
  value,
  onChange,
  data,
  sx,
  selectStyle,
  required = false,
  errMg,
  placeholder,
  ...rest
}) => {
  if (multiple) {
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          multiple
          value={data.reduce(
            (rs, item) =>
              value?.includes(item.value) ? rs.concat([item.value]) : rs,
            []
          )}
          renderValue={(selected) =>
            data
              .reduce(
                (rs, item) =>
                  selected?.includes(item.value) ? rs.concat([item.label]) : rs,
                []
              )
              .join(', ')
          }
          onChange={onChange}
          sx={{
            bgcolor: 'common.white',
            minWidth: 180,
            width: '100%'
          }}
          size="small"
          {...rest}
        >
          {data?.length > 0 ? (
            data.map(({ value: val, label }) => (
              <MenuItem value={val} key={val}>
                <Checkbox checked={value?.indexOf(val) > -1 || false} />
                <ListItemText primary={label} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
  if (!label)
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          value={value}
          onChange={onChange}
          sx={{
            bgcolor: rest?.disabled ? '#f2f2f2' : 'common.white',
            color: rest?.disabled && '#111',
            minWidth: 180,
            width: '100%',
            ...selectStyle
          }}
          size="small"
          {...rest}
        >
          {placeholder && (
            <MenuItem disabled value={-1} key={-1}>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {data?.length > 0 ? (
            data.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  return (
    <FormControl required={required} error={errMg} sx={{ ...sx?.formControl }}>
      <FormControlLabel
        label={
          <span>
            {label} <span style={{ color: 'red' }}>{required && '*'}</span>
          </span>
        }
        labelPlacement="start"
        sx={{
          '.MuiTypography-root': {
            color: '#6F869C',
            fontWeight: 'fontWeightBold',
            fontSize: 13,
            whiteSpace: 'nowrap'
          },
          ...sx?.label
        }}
        control={
          <Select
            value={value}
            onChange={onChange}
            sx={{
              bgcolor: 'common.white',
              minWidth: 100,
              ml: label && 1,
              ...sx
            }}
            size="small"
            {...rest}
          >
            {data?.length > 0 ? (
              data.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No Item</MenuItem>
            )}
          </Select>
        }
      />
      {errMg && (
        <span
          style={{
            color: 'red',
            fontSize: 12
          }}
        >
          * {errMg}
        </span>
      )}
    </FormControl>
  );
};

export default MySelect;
