import React, { useEffect, useState } from 'react';
import useMergeState from '@hooks/useMergeState';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import MyTextField from '@components/MyTextField';
import adServices from '@services/ad';
import { BorderBox, tableStyle } from '../enhance';

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  margin: '10px 0px'
};

const maxLengthOf = {
  adLink: 1000,
  adName: 40,
  adNickname: 40,
  adVersion: 40
};

export default function AdvertisementForm({
  item = null,
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const { t } = useTranslation();
  const [advert, setAdvert] = useState({
    adLink: '',
    adName: '',
    adNickname: '',
    adVersion: ''
  });

  const [errMsg, setErrMsg] = useMergeState({
    adName: '',
    adLink: '',
    adNickname: '',
    adVersion: ''
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setAdvert({
      ...advert,
      [name]: value
    });
    validateOnChange(name, value);
  };

  const validateOnChange = (name, value) => {
    let msg = '';
    if (name === 'adName' && !value) {
      msg = t('common.required');
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };

  const getAdvertisement = async () => {
    const result = await adServices.getAdVersion(item);
    if (!result?.error) {
      setAdvert(
        result.data?.data || {
          adName: '',
          adLink: null,
          adNickname: '',
          adVersion: ''
        }
      );
    }
  };

  const validateOnSubmit = () => {
    let isPassed = true;
    const tempErr = { ...errMsg };
    if (!advert.adName) {
      tempErr.adName = t('common.required');
      isPassed = false;
    }
    Object.values(tempErr).map((err) => {
      if (err) {
        isPassed = false;
      }
      return 0;
    });
    setErrMsg(tempErr);
    return isPassed;
  };

  const handleSubmit = () => {
    const isPassed = validateOnSubmit();
    if (isPassed) {
      const data = { ...advert };
      data.adLink = data.adLink || null;
      setAdvert(data);
      onSubmit(data);
    }
  };

  useEffect(() => {
    getAdvertisement();
  }, []);
  return (
    <Stack sx={{ width: '100%' }}>
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.AD.AD-name')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="adName"
                  value={advert?.adName}
                  placeholder={t('pages.AD.AD-name')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.adName}
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.AD.AD-nickname')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="adNickname"
                  value={advert?.adNickname}
                  placeholder={t('pages.AD.AD-nickname')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.adNickname}
                  disabled={item !== null}
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.AD.AD-link')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="adLink"
                  value={advert?.adLink}
                  placeholder={t('pages.AD.AD-link')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.adLink}
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.AD.version')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="adVersion"
                  value={advert?.adVersion}
                  placeholder={t('pages.AD.version')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.adVersion}
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || 'Cancel'}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={submitButtonProps.loading}
            sx={{
              height: 44,
              minWidth: 110
            }}
          >
            {item ? t('button.check') : t('button.register')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
