import React from 'react';
import env from '@constants/env';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import fallbackImg from '@svg/fallback-img.svg';
import { isImage } from '@utils/boolean';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

const CustomImage = ({ fileName, fileLink, fullUrl, sx, ...rest }) => {
  if (!fullUrl && !isImage(fileName)) return null;

  const src = fullUrl || `${env.s3BaseUrl}/${fileLink}`;

  return (
    <LazyLoadImage
      alt="img"
      src={src}
      style={{ objectFit: 'contain', width: '40px', height: '40px', ...sx }}
      {...rest}
    />
  );
};

export default CustomImage;
