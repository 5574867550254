import React, { useState } from 'react';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MyDialog from '@components/MyDialog';
import { useTranslation } from 'react-i18next';

const InputMultipleFileV2 = ({
  title = 'Upload',
  files,
  setFiles,
  disabled,
  sx,
  size = 'small',
  ...rest
}) => {
  const { t } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const onChange = (e) => {
    setFiles((fileList) => fileList?.concat(Array.from(e.target.files)));
  };
  const handleDelete = () => {
    setFiles([]);
    setOpenConfirmDialog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeItem = (index) => {
    const arr = [...files];
    arr.splice(index, 1);
    setFiles(arr);
    if (arr.length === 0) setAnchorEl(null);
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: 55,
        '.simplebar-placeholder': {
          width: 'auto !important'
        },
        ...sx
      }}
      {...rest}
    >
      <Stack flexDirection="row" alignItems="center">
        <Typography
          sx={{
            visibility: files?.length ? 'visible' : 'hidden',
            color: 'primary.darker',
            fontWeight: 'fontWeightBold',
            fontSize: 13,
            textDecoration: 'underline',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '161px',
            paddingRight: '20px'
          }}
        >
          {files?.length} files
        </Typography>
        {files?.length ? (
          <IconButton onClick={handleClick}>
            {anchorEl ? (
              <ArrowDropUpOutlinedIcon />
            ) : (
              <ArrowDropDownOutlinedIcon />
            )}
          </IconButton>
        ) : null}
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {files?.length ? (
          <IconButton
            sx={{ width: 40, height: 40 }}
            onClick={() => setOpenConfirmDialog(true)}
          >
            <DeleteForeverOutlinedIcon sx={{ color: 'red', fontSize: 30 }} />
          </IconButton>
        ) : null}
        <Button
          variant="outlined"
          component="label"
          sx={
            size === 'small' && {
              height: '36px',
              p: '14px 15px',
              whiteSpace: 'nowrap'
            }
          }
          disabled={disabled}
        >
          {title}
          <input hidden accept="*" type="file" onChange={onChange} multiple />
        </Button>
      </Stack>
      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onOk={handleDelete}
        isAlert
        hasCancelButton
        title={t('dialog.title.areYouSureDeleteThisRecord')}
      />
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack sx={{ padding: 2 }}>
          {files?.map((item, index) => (
            <Stack flexDirection="row" alignItems="center" gap={1} mb={1}>
              <IconButton
                sx={{ width: 30, height: 30 }}
                onClick={() => removeItem(item, index)}
              >
                <HighlightOffOutlinedIcon sx={{ color: 'red' }} />
              </IconButton>
              <Typography
                sx={{
                  color: 'primary.darker',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '161px'
                }}
              >
                {item?.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default InputMultipleFileV2;
