import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  styled
} from '@mui/material';
import Calendar from '@components/Calendar';
import MySelect from '@components/MySelect';
import homepageService from '@services/homepage';
import MyTextField from '@components/MyTextField';
import i18n from '@utils/i18n';
// import MyAutocomplete from '@components/MyAutocomplete';
import { useDispatch } from 'react-redux';
import { Formatter } from '@utils/formatter';
import { onOpenConfirm } from '@store/reducers/appReducer';
import MyRichTextEditor from '@components/MyRichtextEditor';
import {
  employmentTypeList,
  experienceList,
  productDetailSchema,
  recruitDepartmentList,
  requiredFields
} from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const maxLengthOf = {
  title: 200,
  content: 10000
};

const g = i18n.getFixedT(null, null, '');
const CareerDetailForm = (props) => {
  const { onAction, value, setValue } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const [isModified, setIsmodified] = useState(false);

  const [selectData, setSelectData] = useState({
    department: recruitDepartmentList?.filter((i) => i?.value !== 'all'),
    experience: experienceList,
    employment: employmentTypeList,
    location: []
  });

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [errMg, setErrMg] = useState({
    question: '',
    answer: '',
    category: ''
  });
  const getLocationList = async () => {
    const rs = await homepageService.getLocationList();
    if (rs?.data?.data) {
      const locationList = rs?.data?.data?.map((item) => ({
        value: item?.regionCode,
        label: item?.regionName
      }));
      setSelectData({ ...selectData, location: locationList || [] });
    }
  };
  const onChange = (e, type) => {
    setIsmodified(true);
    validateOnChange(e.target);
    // console.log(e.target?.name, e?.target.value?.length);
    // console.log('======================================');
    // console.log(e.target?.name, e?.target.value);

    if (
      e.target.name !== 'hasDeadline' &&
      e.target.name !== 'departmentType' &&
      e.target.name
    ) {
      setValue({
        ...value,
        [e.target.name]:
          type === 'number' ? e.target.value.replace(/,/g, '') : e.target.value
      });
    } else if (e.target.name === 'departmentType') {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
        deadline: e.target.value !== 'TalentPool' ? value?.deadline : null,
        hasDeadline: e.target.value !== 'TalentPool'
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
        deadline: e.target.value ? value?.deadline : null
      });
    }
  };

  const validateOnChange = ({ name, value: val }) => {
    let msg = '';
    if (!val && requiredFields.includes(name)) {
      msg = t('common.required');
    } else if (val.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  const checkValidate = () => {
    let isValidate = true;
    const vldErr = { ...errMg };
    Object.values(vldErr).forEach((error) => {
      if (error) {
        isValidate = false;
      }
    });
    setErrMg(vldErr);
    return isValidate;
  };

  const isValidToSubmit = () => {
    let isValidate = true;
    requiredFields.forEach((field) => {
      if (!value?.[field]) {
        isValidate = false;
      }
    });
    return isValidate;
  };

  const handleAction = (action) => {
    if (checkValidate(value)) {
      dispatch(
        onOpenConfirm({
          open: true,
          type: action === 'register' ? 'save' : 'modify',
          action: () => onAction(action)
        })
      );
    }
  };
  useEffect(() => {
    getLocationList();
  }, []);
  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
        mb={4}
        sx={{ display: 'flex' }}
      >
        <IconButton
          onClick={() => onAction('cancel')}
          sx={{
            display: 'flex',
            gap: 0.5,
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}
          disableRipple
          disableTouchRipple
        >
          <ArrowBackIosNewIcon
            sx={{ height: '20px', color: 'black', fontWeight: 500 }}
          />
        </IconButton>
        {location?.pathname?.includes('register') ? (
          <Button
            variant="contained"
            onClick={() => handleAction('register')}
            disabled={!isValidToSubmit()}
          >
            {t('button.save')}
          </Button>
        ) : (
          <Button
            variant={isModified ? 'contained' : 'outlined'}
            onClick={() => handleAction('modification')}
            disabled={!isValidToSubmit()}
          >
            {isModified ? t('button.save') : t('pages.inventory.modify')}
          </Button>
        )}
      </Stack>
      <Container component={BorderBox}>
        <Stack flexDirection="row" flexWrap="wrap">
          {productDetailSchema.map((item) => {
            return (
              <Stack
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: {
                    xs: '100%',
                    md: item?.grid === 12 ? '100%' : '25%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                  ...item.border
                }}
                key={`${item?.name}-${item?.label}`}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    borderRight: '1px solid #D3D3D3',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: '100px',
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                    {requiredFields.includes(item.name) && (
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    )}
                  </Typography>
                </Stack>

                <RenderField
                  onChangeInput={(e) => onChange(e, item?.type)}
                  errMg={errMg?.[item?.name]}
                  {...item}
                  disabled={item?.disabled}
                  data={item?.options ? item?.options(selectData) : []}
                  value={value}
                  editorRef={editorRef}
                />
              </Stack>
            );
          })}
        </Stack>
      </Container>
    </>
  );
};

const RenderField = ({ data, value, errMg, editorRef, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;
  const { formatTime } = Formatter;
  if (type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          value={value[item.name]}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ minWidth: '100%' }}
        />
      </Stack>
    );
  if (type === 'textarea') {
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MyRichTextEditor
          value={value[item.name]}
          errMg={errMg}
          ref={editorRef}
          onChange={onChangeInput}
          {...rest}
        />
      </Stack>
    );
  }
  if (type === 'date') {
    return (
      <Stack
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          gap: 4,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <FormControlLabel
          key={`${item?.name}-${label}`}
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              mr: 2
            }
          }}
          control={
            <Calendar
              date={value?.[item?.name] ? new Date(value?.[item?.name]) : null}
              sx={{
                inputStyle: {
                  width: '100%',
                  ...((!value?.hasDeadline ||
                    value?.departmentType === 'TalentPool') && {
                    backgroundColor: '#f2f2f2'
                  })
                }
              }}
              setDate={(e) =>
                onChangeInput({
                  target: {
                    name: item?.name,
                    value: formatTime(e, 'YYYY.MM.D')
                  }
                })
              }
              disabled={
                !value?.hasDeadline || value?.departmentType === 'TalentPool'
              }
            />
          }
          label={g('common.period')}
        />
        <FormControlLabel
          key={`${item?.name}-${label}`}
          control={
            <Checkbox
              disableRipple
              checked={!value?.hasDeadline}
              onChange={(e) =>
                onChangeInput({
                  target: {
                    name: 'hasDeadline',
                    value: !e?.target?.checked
                  }
                })
              }
            />
          }
          label={g('pages.homepage.always')}
        />
      </Stack>
    );
  }
  return (
    <MyTextField
      value={
        item?.formatter ? item?.formatter(value[item.name]) : value[item.name]
      }
      errMg={errMg}
      onChange={onChangeInput}
      size="small"
      {...rest}
      sx={{ ...inputStyle, minWidth: 210 }}
      textFieldStyle={{
        borderRadius: 0,
        ...(item?.disabled && { backgroundColor: '#f2f2f2' })
        // '& fieldset': { border: 'none' }
      }}
      typeValue={type === 'number' && 'onlyNumber'}
    />
  );
};

export default CareerDetailForm;
