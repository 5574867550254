import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  styled
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Calendar from '@components/Calendar';
import MySelect from '@components/MySelect';

import MyTextField from '@components/MyTextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from '@utils/i18n';
import MyAutocomplete from '@components/MyAutocomplete';
// import MyAutocomplete from '@components/MyAutocomplete';
import { Formatter } from '@utils/formatter';

import MyToolTip from '@components/MyToolTip';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import MyRichTextEditor from '@components/MyRichtextEditor';
import storeService from '@services/pre-order/store';
import dayjs from 'dayjs';
// import { TooltipContainer } from '@components/MyTable/GroupMultiHeaderTable';
import {
  calculateWidth,
  localeRadioOptions,
  requiredFields,
  storeInfoSchema,
  storeOwnerInfoSchema
} from '../enhance';

const CustomTimePicker = styled(TimePicker)(({ theme }) => ({
  width: 150,
  '.MuiInputBase-root': {
    height: '40px !important'
  },
  '& .MuiDialog-paper': {
    width: '400px' // Increase the width of the dialog
  },
  '& .MuiClock-clock': {
    width: '300px', // Increase the size of the clock face
    height: '300px'
  },
  '& .MuiClockPicker-arrowSwitcher': {
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2)
  },

  '& .MuiClockPicker-container': {
    margin: 0,
    width: '100%'
  },
  '& .MuiClockPicker-view': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .MuiClockNumber-root': {
    width: '50px', // Increase the size of clock numbers
    height: '50px',
    fontSize: '1.2rem'
  }
}));
const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const maxLengthOf = {
  title: 200,
  content: 10000
};

const g = i18n.getFixedT(null, null, '');
const StoreDetailForm = (props) => {
  const { value, setValue, setIsmodified } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const [selectData, setSelectData] = useState({
    storeTypeList: []
  });

  const [selectedDay, setSelectedDay] = useState(null);

  const editorRef = useRef(null);
  const [errMg, setErrMg] = useState({
    question: '',
    answer: '',
    category: ''
  });

  const formatSelectOptions = (businessHours) => {
    if (businessHours)
      return businessHours
        ?.filter((i) => i.isOpen)
        ?.map((i) => ({
          value: i?.dayOfWeek,
          label: g(`day-of-week-short.${i?.dayOfWeek?.toLowerCase()}`)
        }));
    return [];
  };

  const getStoreTypeList = async () => {
    const rs = await storeService.getStoreTypeList();
    if (rs?.data) {
      const refactoredList = rs?.data?.map((item) => ({
        value: item?.storeTypeCode,
        label: item?.typeName
      }));
      setSelectData((prev) => ({
        ...prev,
        storeTypeList: refactoredList || []
      }));
    }
  };
  const onChange = (e, type) => {
    setIsmodified(true);
    validateOnChange(e.target);

    if (e.target.name === 'encodeContact') {
      const newValue = !(value?.encodeContact === true);
      setValue((prev) => ({
        ...prev,
        [e.target.name]: newValue
      }));
    } else if (e.target.name === 'businessHours') {
      if (e?.target?.value?.item?.isOpen) {
        if (value?.businessHours?.filter((i) => i?.isOpen)?.length > 1)
          setValue((prev) => {
            const updatedData = prev?.businessHours?.map((i) => {
              if (
                i?.dayOfWeek?.toLowerCase() ===
                e?.target?.value?.item?.dayOfWeek?.toLowerCase()
              ) {
                return {
                  ...i,
                  isOpen: false,
                  openAt: '09:00',
                  closeAt: '18:00'
                };
              }
              return i;
            });
            return { ...prev, businessHours: updatedData };
          });
      } else
        setValue((prev) => {
          const updatedData = prev?.businessHours?.map((i) => {
            if (
              i?.dayOfWeek?.toLowerCase() ===
              e?.target?.value?.item?.dayOfWeek?.toLowerCase()
            ) {
              return {
                ...i,
                isOpen: true,
                openAt: '09:00',
                closeAt: '18:00'
              };
            }
            return i;
          });
          return { ...prev, businessHours: updatedData };
        });
    } else if (e.target.name === 'setTimeAll') {
      if (!e?.target?.value) {
        setValue({
          ...value,
          [e.target.name]: e.target.value
        });
        setSelectedDay(value?.businessHours?.[0]?.dayOfWeek);
      } else
        setValue({
          ...value,
          [e.target.name]: e.target.value
        });
    } else if (e.target.name === 'storeTypeCode') {
      console.log('check', e.target.name, e.target.value);
      setValue({
        ...value,
        [e?.target.name]: value ? e.target.value?.value : null
      });
    } else if (e.target.name === 'startTime' || e.target.name === 'endTime') {
      // New logic for TimePicker
      const newTime = dayjs(e?.target?.value).format('HH:mm');
      console.log('newTime', e?.target?.value);
      if (value.setTimeAll) {
        // Update all business hours
        const updatedBusinessHours = value.businessHours.map((day) => ({
          ...day,
          [e.target.name === 'startTime' ? 'openAt' : 'closeAt']: newTime
        }));
        setValue((prev) => ({
          ...prev,
          businessHours: updatedBusinessHours
        }));
      } else {
        // Update all business hours
        const updatedBusinessHours = value.businessHours.map((day) => {
          if (day?.dayOfWeek?.toLowerCase() === selectedDay.toLowerCase())
            return {
              ...day,
              [e.target.name === 'startTime' ? 'openAt' : 'closeAt']: newTime
            };
          return day;
        });
        setValue((prev) => ({
          ...prev,
          businessHours: updatedBusinessHours
        }));
      }
    } else if (e.target.name === 'orderFixDays') {
      if (e.target.value) {
        if (e.target.value.replace(/,/g, '') > 0) {
          setValue({
            ...value,
            [e.target.name]:
              type === 'number'
                ? e.target.value.replace(/,/g, '')
                : e.target.value
          });
        }
      } else {
        setValue({
          ...value,
          [e.target.name]:
            type === 'number'
              ? e.target.value.replace(/,/g, '')
              : e.target.value
        });
        setErrMg({ ...errMg, [e?.target.name]: t('common.required') });
      }
    } else {
      setValue({
        ...value,
        [e.target.name]:
          type === 'number' ? e.target.value.replace(/,/g, '') : e.target.value
      });
    }
  };

  const handleChangeDay = (e) => {
    setSelectedDay(e?.target?.value);
  };
  const validateOnChange = ({ name, value: val }) => {
    let msg = '';
    if (!val && requiredFields.includes(name)) {
      msg = t('common.required');
    } else if (val?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMg({ ...errMg, [name]: msg });
  };

  useEffect(() => {
    getStoreTypeList();
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack sx={{ width: '100%', gap: 1.5 }}>
        <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 600 }}>
          {t('pages.pre-order.locales.label')}
        </Typography>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            <Stack
              direction="row"
              sx={{
                width: {
                  xs: '100%'
                },
                flexGrow: 1,
                borderBottom: { xs: 'none' }
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '0 10px',
                  borderRight: '1px solid #D3D3D3',
                  ...labelStyle
                }}
              >
                <Typography
                  sx={{
                    minWidth: '140px',
                    fontSize: 13,
                    fontWeight: 400,
                    color: '#6F869C',
                    textAlign: 'center'
                  }}
                >
                  {t('pages.pre-order.locales.settings')}

                  <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                </Typography>
              </Stack>
              <Stack ml={2} direction="row">
                {localeRadioOptions?.map((i) => (
                  <FormControlLabel
                    key={`${i?.value}-${i?.label}`}
                    label={i?.label}
                    labelPlacement="end"
                    sx={{ mr: 1.5 }}
                    disabled={!location?.pathname?.includes('register')}
                    control={
                      <Radio
                        checked={value?.preorderLocale === i?.value}
                        onChange={() =>
                          setValue({ ...value, preorderLocale: i?.value })
                        }
                      />
                    }
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack sx={{ width: '100%', gap: 1.5, mt: 2 }}>
        <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 600 }}>
          {t('pages.pre-order.store-form.store-owner-info')}
        </Typography>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            {storeOwnerInfoSchema[value?.preorderLocale].map((item) => {
              return (
                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: {
                      xs: '100%',
                      md: `${calculateWidth(item.grid)}`
                    },
                    display: 'flex',
                    flexGrow: 1,
                    borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                    ...item.border
                  }}
                  key={`${item?.name}-${item?.label}`}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      padding: '0 10px',
                      borderRight: '1px solid #D3D3D3',
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        minWidth: '140px',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#6F869C',
                        textAlign: 'center'
                      }}
                    >
                      {item.label}
                      {requiredFields.includes(item.name) && (
                        <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                      )}
                    </Typography>
                  </Stack>

                  <RenderField
                    onChangeInput={(e) => onChange(e, item?.type)}
                    errMg={errMg?.[item?.name]}
                    disabled={item?.disabled}
                    sx={{ width: '100%' }}
                    {...item}
                    data={item?.options ? item?.options(selectData) : []}
                    value={value}
                    editorRef={editorRef}
                  />
                  {item?.childern &&
                    item.childern.map((i) => (
                      <RenderField
                        fullWidth
                        sx={{ width: '100%' }}
                        onChangeInput={(e) => onChange(e, i?.type)}
                        errMg={errMg?.[i?.name]}
                        disabled={i?.disabled}
                        {...i}
                        data={i?.options ? i?.options(selectData) : []}
                        value={value}
                        editorRef={editorRef}
                      />
                    ))}
                </Stack>
              );
            })}
          </Stack>
        </Container>
      </Stack>
      <Stack sx={{ width: '100%', gap: 1.5, mt: 2.5 }}>
        <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 600 }}>
          {t('pages.pre-order.store-form.store-info')}
        </Typography>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            {storeInfoSchema.map((item) => {
              return (
                <Stack
                  direction="row"
                  sx={{
                    borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                    width: {
                      xs: '100%',
                      md: calculateWidth(item?.grid)
                    },
                    flexGrow: 1,
                    borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },

                    ...item.border
                  }}
                  key={`${item?.name}-${item?.label}`}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      padding: '0 10px',
                      borderRight: '1px solid #D3D3D3',
                      ...labelStyle
                    }}
                  >
                    <Typography
                      sx={{
                        width: '140px',
                        fontSize: 13,
                        color: '#6F869C',
                        textAlign: 'center'
                      }}
                    >
                      {item.label}
                      {(requiredFields.includes(item.name) ||
                        item?.required) && (
                        <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                      )}
                    </Typography>
                  </Stack>

                  <RenderField
                    onChangeInput={(e) => onChange(e, item?.type)}
                    errMg={errMg?.[item?.name]}
                    {...item}
                    disabled={item?.disabled}
                    data={selectData?.storeTypeList || []}
                    value={value}
                    editorRef={editorRef}
                  />
                  {item?.childern &&
                    item.childern.map((i) => (
                      <RenderField
                        fullWidth
                        onChangeInput={(e) => onChange(e, i?.type)}
                        errMg={errMg?.[i?.name]}
                        {...i}
                        disabled={i?.disabled}
                        data={i?.options ? i?.options(selectData) : []}
                        value={value}
                        editorRef={editorRef}
                      />
                    ))}
                </Stack>
              );
            })}
          </Stack>

          {/* phone */}
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            sx={{ display: 'flex', alignItems: 'stretch' }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                borderRight: '1px solid #D3D3D3'
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: {
                    xs: '100%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    padding: '0 10px',
                    borderRight: '1px solid #D3D3D3',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: '140px',
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {t('pages.pre-order.store-form.store-contact')}
                    {requiredFields.includes('contact') && (
                      <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    )}
                  </Typography>
                </Stack>

                <RenderField
                  sx={{ width: 'auto', minWidth: '150px' }}
                  name="contact"
                  onChangeInput={(e) => onChange(e, 'number')}
                  type="number"
                  errMg={errMg?.contact}
                  value={value}
                />

                <FormControlLabel
                  sx={{ mr: 1, flexGrow: 1 }}
                  label={
                    <Stack
                      sx={{
                        flexDirection: 'row',

                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#212121',
                          fontSize: 13,
                          whiteSpace: 'pre-line',
                          fontWeight: 500
                        }}
                      >
                        {t('pages.pre-order.store-form.is-personal-phone')}
                      </Typography>
                      <MyToolTip
                        content={t('pages.pre-order.tooltip.personal-phone')}
                      />
                    </Stack>
                  }
                  labelPlacement="start"
                  control={
                    <Checkbox
                      disableRipple
                      checked={value?.encodeContact} // Make sure `checked` is directly linked to the `hasDeadline` state
                      onChange={() =>
                        onChange({
                          target: {
                            name: 'encodeContact',
                            value: !value?.encodeContact // Toggle the value correctly
                          }
                        })
                      }
                    />
                  }
                />
              </Stack>
              {/* working days */}
              <Stack
                direction="row"
                sx={{
                  width: {
                    xs: '100%'
                  },
                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    padding: '0 10px',
                    borderRight: '1px solid #D3D3D3',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: '140px',
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {t('pages.pre-order.store-form.working-days')}

                    <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                  </Typography>
                </Stack>

                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    px: 2,
                    my: '10px',
                    gap: 0.5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    width: '100%'
                  }}
                >
                  {value?.businessHours?.map((item) => {
                    return (
                      <Button
                        variant="outlined"
                        sx={{
                          height: 36,
                          px: 0.5,
                          py: 0.5,
                          borderRadius: 1,
                          boxShadow: 0,
                          color: item?.isOpen ? 'priamry.main' : '#353D4A',
                          border: item?.isOpen
                            ? '1px solid #1367F4'
                            : '1px solid #E5E7EB'
                        }}
                        onClick={() =>
                          onChange({
                            target: {
                              name: 'businessHours',
                              value: { item }
                            }
                          })
                        }
                        key={`${item?.dayOfWeek}-${item?.openAt}`}
                      >
                        {t(
                          `day-of-week-short.${item?.dayOfWeek?.toLowerCase()}`
                        )}
                      </Button>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',

                flexGrow: 1
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: {
                    xs: '100%'
                  },
                  alignItems: 'center',
                  justifyContent: 'center',

                  flexGrow: 1,
                  borderBottom: { xs: 'none', md: '1px solid #D3D3D3' }
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    padding: '0 10px',
                    height: '100%',
                    borderRight: '1px solid #D3D3D3',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: '140px',
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {t('pages.pre-order.store-form.order-confirm-day')}
                    <MyToolTip
                      content={t('pages.pre-order.tooltip.confirm-date')}
                    />
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontSize: 13,
                    ml: 1,
                    whiteSpace: 'nowrap',
                    color: '#6F869C',
                    textAlign: 'center'
                  }}
                >
                  픽업일 포함
                </Typography>
                <RenderField
                  sx={{ flexGrow: 1 }}
                  name="orderFixDays"
                  onChangeInput={(e) => onChange(e, 'number')}
                  errMg={errMg?.orderFixDays}
                  value={value}
                />
                <Typography sx={{ mr: 2, whiteSpace: 'nowrap' }}>
                  {t('pages.pre-order.store-form.day-before')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  time picker */}

          <Stack flexDirection="row" flexWrap="wrap">
            <Stack
              direction="row"
              sx={{
                width: {
                  xs: '100%'
                },
                justifyContent: 'space-between',
                flexGrow: 1
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '0 10px',
                  borderRight: '1px solid #D3D3D3',
                  ...labelStyle
                }}
              >
                <Typography
                  sx={{
                    width: '140px',
                    fontSize: 13,
                    color: '#6F869C',
                    textAlign: 'center'
                  }}
                >
                  {t('pages.pre-order.store-form.business-hours')}

                  <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                </Typography>
              </Stack>

              <Stack
                sx={{
                  flexGrow: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  px: 2.5,
                  py: 1.25
                }}
              >
                {/* radios */}
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    ml: 2.5
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: '#353D4A'
                        }}
                      >
                        {t('pages.pre-order.store-form.all-time')}
                      </Typography>
                    }
                    control={
                      <Radio
                        disabled={value?.businessHours?.length < 1}
                        checked={value?.setTimeAll}
                        onChange={() =>
                          onChange({
                            target: {
                              name: 'setTimeAll',
                              value: true
                            }
                          })
                        }
                      />
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: '#353D4A'
                        }}
                      >
                        {t('pages.pre-order.store-form.daily-time')}
                      </Typography>
                    }
                    control={
                      <Radio
                        checked={!value?.setTimeAll}
                        disabled={value?.businessHours?.length < 1}
                        onChange={() =>
                          onChange({
                            target: {
                              name: 'setTimeAll',
                              value: false
                            }
                          })
                        }
                      />
                    }
                    labelPlacement="end"
                  />
                </Stack>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                    gap: 0.5
                  }}
                >
                  {value?.setTimeAll ? (
                    <>
                      <CustomTimePicker
                        ampm={false}
                        format="HH:mm"
                        disabled={value?.businessHours?.length < 1}
                        value={dayjs(value.businessHours[0]?.openAt, 'HH:mm')}
                        onChange={(newValue) =>
                          onChange(
                            {
                              target: {
                                name: 'startTime',
                                value: newValue
                              }
                            },
                            'time'
                          )
                        }
                        sx={{
                          '&.MuiInputBase-root ': {
                            height: '40px !important'
                          }
                        }}
                        slotProps={{
                          popper: {
                            placement: 'bottom-end',
                            sx: {
                              '&[data-popper-placement="bottom-end"]': {
                                transform: 'translate3d(0, 8px, 0px) !important'
                              }
                            },
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 8]
                                }
                              }
                            ]
                          },
                          desktopPaper: {
                            sx: {
                              '& .MuiPickersLayout-contentWrapper': {
                                width: '100%',
                                minWidth: '150px'
                              },
                              '& .MuiMultiSectionDigitalClock-root': {
                                width: '100%',
                                maxWidth: 'none',
                                '& .MuiMultiSectionDigitalClockSection-root': {
                                  width: '50% !important'
                                }
                              }
                            }
                          }
                        }}
                      />
                      <RemoveIcon sx={{ color: '#AEB3BA' }} />
                      <CustomTimePicker
                        ampm={false}
                        value={dayjs(value.businessHours[0]?.closeAt, 'HH:mm')}
                        onChange={(newValue) =>
                          onChange(
                            {
                              target: { name: 'endTime', value: newValue }
                            },
                            'time'
                          )
                        }
                        slotProps={{
                          popper: {
                            placement: 'bottom-end',
                            sx: {
                              '&[data-popper-placement="bottom-end"]': {
                                transform: 'translate3d(0, 8px, 0px) !important'
                              }
                            },
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 8]
                                }
                              }
                            ]
                          },
                          desktopPaper: {
                            sx: {
                              '& .MuiPickersLayout-contentWrapper': {
                                width: '100%',
                                minWidth: '150px'
                              },
                              '& .MuiMultiSectionDigitalClock-root': {
                                width: '100%',
                                maxWidth: 'none',
                                '& .MuiMultiSectionDigitalClockSection-root': {
                                  width: '50% !important'
                                }
                              }
                            }
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <MySelect
                        data={formatSelectOptions(value?.businessHours || [])}
                        value={selectedDay}
                        onChange={handleChangeDay}
                        selectStyle={{ minWidth: 80 }}
                      />
                      <CustomTimePicker
                        ampm={false}
                        format="HH:mm"
                        disabled={value?.businessHours?.length < 1}
                        value={dayjs(
                          value.setTimeAll
                            ? value.businessHours[0]?.openAt
                            : value.businessHours.find(
                                (day) => day.dayOfWeek === selectedDay
                              )?.openAt,
                          'HH:mm'
                        )}
                        onChange={(newValue) =>
                          onChange(
                            {
                              target: {
                                name: 'startTime',
                                value: newValue
                              }
                            },
                            'time'
                          )
                        }
                        slotProps={{
                          popper: {
                            placement: 'bottom-end',
                            sx: {
                              '&[data-popper-placement="bottom-end"]': {
                                transform: 'translate3d(0, 8px, 0px) !important'
                              }
                            },
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 8]
                                }
                              }
                            ]
                          },
                          desktopPaper: {
                            sx: {
                              '& .MuiPickersLayout-contentWrapper': {
                                width: '100%',
                                minWidth: '150px'
                              },
                              '& .MuiMultiSectionDigitalClock-root': {
                                width: '100%',
                                maxWidth: 'none',
                                '& .MuiMultiSectionDigitalClockSection-root': {
                                  width: '50% !important'
                                }
                              }
                            }
                          }
                        }}
                      />
                      <RemoveIcon sx={{ color: '#AEB3BA' }} />
                      <CustomTimePicker
                        ampm={false}
                        format="HH:mm"
                        disabled={value?.businessHours?.length < 1}
                        value={dayjs(
                          value.setTimeAll
                            ? value.businessHours[0]?.closeAt
                            : value.businessHours.find(
                                (day) => day.dayOfWeek === selectedDay
                              )?.closeAt,
                          'HH:mm'
                        )}
                        onChange={(newValue) =>
                          onChange(
                            {
                              target: { name: 'endTime', value: newValue }
                            },
                            'time'
                          )
                        }
                        slotProps={{
                          popper: {
                            placement: 'bottom-end',
                            sx: {
                              '&[data-popper-placement="bottom-end"]': {
                                transform: 'translate3d(0, 8px, 0px) !important'
                              }
                            },
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 8]
                                }
                              }
                            ]
                          },
                          desktopPaper: {
                            sx: {
                              '& .MuiPickersLayout-contentWrapper': {
                                width: '100%',
                                minWidth: '150px'
                              },
                              '& .MuiMultiSectionDigitalClock-root': {
                                width: '100%',
                                maxWidth: 'none',
                                '& .MuiMultiSectionDigitalClockSection-root': {
                                  width: '50% !important'
                                }
                              }
                            }
                          }
                        }}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  minWidth: '45%',
                  border: '1px solid #D3D3D3',
                  my: 1.5,
                  mr: 1.5,
                  py: 1,
                  px: 2.5,
                  gap: 1,
                  borderRadius: 1.5,
                  minHeight: 150
                }}
              >
                {value?.businessHours &&
                  value.businessHours?.map((item) => (
                    <Typography
                      sx={{
                        fontSize: 13,
                        color: '#6F869C',
                        display: 'flex',
                        gap: 1
                      }}
                      key={`${item?.dayOfWeek}-${item?.startsAt}`}
                    >
                      {`${t(
                        `day-of-week-short.${item?.dayOfWeek?.toLowerCase()}`
                      )} `}{' '}
                      {item?.isOpen ? (
                        <>
                          <span style={{ marginLeft: '8px' }}>
                            {item?.openAt}{' '}
                          </span>{' '}
                          ~ <span>{item?.closeAt}</span>
                        </>
                      ) : (
                        <span style={{ marginLeft: '8px' }}>
                          {t('pages.pre-order.store-form.day-off')}
                        </span>
                      )}
                    </Typography>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </LocalizationProvider>
  );
};

const RenderField = ({ data, value, errMg, editorRef, sx, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, onChangeInput, type, ...rest } = item;
  const { formatTime } = Formatter;

  if (type === 'autocomplete')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MyAutocomplete
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          ListboxProps={{ style: { maxHeight: 200 } }}
          getOptionLabel={(option) => option.label || ''}
          required
          onChange={(e, val) =>
            onChangeInput({ target: { name: item.name, value: val } }, e)
          }
          options={data}
          sx={{ width: 160, flex: 1 }}
          value={{
            value: data?.find((i) => i?.value === value[item.name])
              ?.storeTypeCode,
            label: data?.find((i) => i?.value === value[item.name])?.label || ''
          }}
          errMg={errMg}
          // onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
        />
      </Stack>
    );

  if (type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          value={value[item.name]}
          onChange={onChangeInput}
          multiple={item.multiple}
          name={item.name}
          data={data}
          sx={{ minWidth: '100%' }}
        />
      </Stack>
    );
  if (type === 'textarea') {
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MyRichTextEditor
          value={value[item.name]}
          errMg={errMg}
          ref={editorRef}
          onChange={onChangeInput}
          {...rest}
        />
      </Stack>
    );
  }
  if (type === 'date') {
    return (
      <Stack
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          gap: 4,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <FormControlLabel
          key={`${item?.name}-${label}`}
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              mr: 2
            }
          }}
          control={
            <Calendar
              date={value?.[item?.name] ? new Date(value?.[item?.name]) : null}
              sx={{
                inputStyle: {
                  width: '100%',
                  ...((!value?.hasDeadline ||
                    value?.departmentType === 'TalentPool') && {
                    backgroundColor: '#f2f2f2'
                  })
                }
              }}
              setDate={(e) =>
                onChangeInput({
                  target: {
                    name: item?.name,
                    value: formatTime(e, 'YYYY.MM.D')
                  }
                })
              }
              disabled={
                !value?.hasDeadline || value?.departmentType === 'TalentPool'
              }
            />
          }
          label={g('common.period')}
        />
        <FormControlLabel
          key={`${item?.name}-${label}`}
          control={
            <Checkbox
              disableRipple
              checked={!value?.hasDeadline}
              onChange={(e) =>
                onChangeInput({
                  target: {
                    name: 'hasDeadline',
                    value: !e?.target?.checked
                  }
                })
              }
            />
          }
          label={g('pages.homepage.always')}
        />
      </Stack>
    );
  }
  return (
    <MyTextField
      value={
        item?.formatter ? item?.formatter(value[item.name]) : value[item.name]
      }
      errMg={errMg}
      onChange={onChangeInput}
      size="small"
      {...rest}
      sx={{ ...inputStyle, ...sx, flexGrow: 1 }}
      textFieldStyle={{
        borderRadius: 0,
        ...(item?.disabled && { backgroundColor: '#f2f2f2' })
        // '& fieldset': { border: 'none' }
      }}
      typeValue={type === 'number' && 'onlyNumber'}
    />
  );
};

export default StoreDetailForm;
