import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const scheduleService = {
  async getScheduleList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/schedule')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getScheduleDetail(id) {
    try {
      const res = await new RestService().setPath(`/schedule/${id}`).get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createSchedule(data) {
    try {
      const res = await new RestService().setPath('/schedule').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createScheduleDetail(schCode, data) {
    try {
      const res = await new RestService()
        .setPath(`schedule/${schCode}/sch-detail`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportScheduleToday(params = {}) {
    try {
      const res = await new RestService()
        .setPath('schedule/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportScheduleDetail(id) {
    try {
      const res = await new RestService()
        .setPath(`schedule/${id}/export`)
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editSchedule(id, schDetailId, data) {
    try {
      const res = await new RestService()
        .setPath(`schedule/${id}/sch-detail/${schDetailId}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteSchedule(id, schDetailId) {
    try {
      const res = await new RestService()
        .setPath(`schedule/${id}/sch-detail/${schDetailId}`)
        .delete();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getShippingInfo(schCode, schDetailId) {
    try {
      const res = await new RestService()
        .setPath(`schedule/${schCode}/shipping-info/${schDetailId}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default scheduleService;
