import { Formatter } from '@utils/formatter';

import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages');
const preOrderT = i18n.getFixedT(null, null, 'pages.pre-order');
export const requiredFields = [
  'storeTypeCode',
  'storeName',

  'managerContact',
  'address',
  'contact',
  'latitude',
  'longitude',
  'orderFixDays',
  'businessHours'
];

export const orderStatusOptions = [
  { value: 'all', label: g('common.total') },
  { value: 'ORDERED', label: preOrderT('order-status.confirmed') },
  { value: 'FIXED', label: preOrderT('order-status.fixed') },
  {
    value: 'PICKUP_REQUESTED',
    label: preOrderT('order-status.pickup-request')
  },
  { value: 'PICKUP_COMPLETED', label: preOrderT('order-status.completed') },
  { value: 'CANCELLED', label: preOrderT('order-status.cancelled') }
];
export const localeRadioOptions = [
  { value: 'ko', label: preOrderT('locales.ko') },
  { value: 'jp', label: preOrderT('locales.jp') }
];

const orderStatusparser = {
  ORDERED: preOrderT('order-status.confirmed'),
  CONFIRMED: preOrderT('order-status.confirmed'),
  FIXED: preOrderT('order-status.fixed'),
  PICKUP_REQUESTED: preOrderT('order-status.pickup-request'),
  PICKUP_COMPLETED: preOrderT('order-status.completed'),
  CANCELLED: preOrderT('order-status.cancelled')
};
const localeRenderObject = {
  ko: preOrderT('locales.ko'),
  jp: preOrderT('locales.jp')
};

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'preorderLocale',
    label: t('pre-order.locales.label'),
    minWidth: 100,
    align: 'center',
    render: (item) =>
      localeRenderObject[item?.preorderLocale] || item?.preorderLocale
  },

  {
    name: 'orderedDt',
    label: t('pre-order.order-date'),
    minWidth: 100,
    align: 'center',
    render: ({ orderedDt }) => orderedDt && Formatter.formatTime(orderedDt)
  },
  {
    name: 'storeName',
    label: t('pre-order.store'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'clientName',
    label: t('pre-order.client'),
    minWidth: 100,
    align: 'center'
  },

  {
    name: 'orderName',
    label: t('pre-order.product'),
    minWidth: 120,
    align: 'center',
    render: ({ orderName }) =>
      orderName?.length > 10 ? `${orderName?.slice(0, 10)}...` : orderName
  },
  {
    name: 'quantity',
    label: g('pages.equipment.quantity'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'totalPrice',
    label: t('pre-order.price'),
    minWidth: 60,
    align: 'center',
    render: (item) => formatMoney(item?.totalPrice)
  },
  {
    name: 'preferredPickupDt',
    label: t('pre-order.prefered-pickup-date'),
    minWidth: 100,
    align: 'center',
    render: ({ preferredPickupDt }) =>
      preferredPickupDt && Formatter.formatTime(preferredPickupDt, 'YYYY-MM-DD')
  },
  {
    name: 'orderStatus',
    label: t('pre-order.status'),
    minWidth: 80,
    align: 'center',
    render: ({ orderStatus }) => orderStatusparser[orderStatus] || orderStatus
  }
];

export const orderClientInfoSchema = [
  {
    name: 'clientName',

    type: 'text',
    label: preOrderT('client'),

    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'minibarName',
    type: 'text',
    fullWidth: true,
    label: preOrderT('minibar'),
    grid: 6,
    border: { borderRight: 0 }
  },
  {
    name: 'orderedDt',
    label: preOrderT('order-date'),
    grid: 6,
    border: { borderLeft: 0 },
    formatter: (value) => value && Formatter.formatTime(value?.orderedDt)
  },

  {
    name: 'preferredPickupDt',
    label: preOrderT('prefered-pickup-date'),
    grid: 6,
    border: { borderRight: 0 },

    formatter: (value) =>
      value && Formatter.formatTime(value?.preferredPickupDt)
  },
  {
    name: 'totalQuantity',
    label: preOrderT('total-quantity'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'totalPrice',
    label: preOrderT('total-price'),
    grid: 6,
    border: { borderRight: 0 },
    formatter: (value) => value && formatMoney(value?.totalPrice)
  },
  {
    name: 'products',
    label: preOrderT('productName'),
    grid: 6,
    border: { borderLeft: 0, borderBottom: 0 }
  },
  {
    name: 'customerRequest',
    label: preOrderT('customer-request'),
    grid: 6,
    border: { borderRight: 0 }
  }
];

// store Products page

export const orderStoreInfoSchema = [
  {
    name: 'storeName',

    type: 'text',
    label: preOrderT('store-name'),

    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'orderStatus',

    label: preOrderT('status'),
    grid: 6,
    border: { borderRight: 0 },
    formatter: (value) =>
      `${(value && orderStatusparser[value?.orderStatus]) || value} (${
        value?.cancelReason
      })`
  },
  {
    name: 'displayedDt',
    label: preOrderT('display-dt'),
    grid: 6,
    border: { borderLeft: 0 },
    formatter: (value) => value && Formatter.formatTime(value)
  },

  {
    name: 'displayPhotoUrl',
    label: preOrderT('display-image'),
    grid: 12,
    type: 'image',
    border: { borderLeft: 0, borderBottom: 0 }
  }
];

export const calculateWidth = (grid) => {
  if (grid === 12) return '100%';
  if (grid === 6) return '50%';
  if (grid === 4) return '33.3%';
  if (grid === 3) return '25%';
  return `${(100 / 12) * grid}%`; // Fallback for other values
};

export const storeLocales = {
  KO: 'ko',
  JP: 'jp'
};

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #D3D3D3'
    },
    th: {
      borderRight: '1px solid #D3D3D3',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FFFFFF',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
