/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useTranslation } from 'react-i18next';
import MyTextField from '@components/MyTextField';
import { Stack, Typography } from '@mui/material';
import MySelect from '@components/MySelect';
import restockService from '@services/restock';
import {
  reasonOptions,
  reasonSelectOptions,
  reasonUpdateColumns
} from '../enhance';

const RegisterReasonDialog = ({
  open,
  setOpen,
  selectedItem,
  onSuccess,
  type
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [warning, setWarning] = useState({
    open: false,
    isValid: false,
    action: ''
  });

  const [item, setItem] = useState(null);
  const onAction = (name, item, value) => {
    switch (name) {
      case 'quantity':
        setItems((prev) => {
          const updatedItems = prev.map((i) => {
            if (item?.id === i.id) {
              const newQuantity = value;
              return { ...i, shortageQuantity: newQuantity };
            }
            return { ...i };
          });

          return updatedItems;
        });
        break;
      case 'reasonSelect':
        setItem({
          ...item,
          returningReason: value
        });
        break;
      case 'noteInput':
        setItem({
          ...item,
          noteInput: value,
          noteErr: value ? '' : t('common.required')
        });
        break;
      default:
        break;
    }
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Stack sx={{ width: 100 }}>
            <MyTextField
              value={item?.shortageQuantity}
              typeValue="onlyNumber"
              onChange={(e) => onAction('quantity', item, e?.target.value)}
            />
          </Stack>
        );

      case 2:
        if (type === 'history') {
          return item?.returningReason;
        }
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <MySelect
              value={item?.returningReason}
              data={reasonSelectOptions}
              onChange={(e) => onAction('reasonSelect', item, e.target.value)}
              errMg={item?.reasonErr}
            />
            {item?.returningReason === '기타' && (
              <MyTextField
                value={item?.noteInput}
                placeholder="사유 입력해주세요."
                onChange={(e) => onAction('noteInput', item, e?.target.value)}
              />
            )}
          </Stack>
        );

      default:
        break;
    }
  };

  const handleUpdateReason = async () => {
    const dataSubmit = {
      note:
        item.returningReason === '기타'
          ? `${item?.returningReason} : ${item?.noteInput}`
          : item?.returningReason
    };
    const rs = await restockService.updateReason(
      item?.returningCode,
      dataSubmit
    );
    // handle register
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      onSuccess();
      dispatch(setSuccess({ message: t('info.success.content') }));
      setOpen(false);
    }
  };
  useEffect(() => {
    if (type !== 'history') {
      const isEtc = reasonOptions.includes(selectedItem?.returningReason);
      // eslint-disable-next-line no-nested-ternary
      const returningReason = isEtc ? selectedItem?.returningReason : '기타';
      setItem({
        ...selectedItem,
        returningReason,
        noteInput: isEtc
          ? ''
          : selectedItem?.returningReason?.split(':')?.[1]?.trimLeft() ||
            selectedItem?.returningReason
      });
    } else {
      setItem(selectedItem);
    }
  }, [open]);

  return (
    <MyDialog
      open={open}
      setOpen={() => setOpen(false)}
      title={t('pages.picking.reason')}
      hasCloseButton
      hasCancelButton={type !== 'history'}
      onOk={type === 'history' ? () => setOpen(false) : handleUpdateReason}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mx={1}
          mb={-1}
        >
          <Stack direction="row" gap={2}>
            {' '}
          </Stack>
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 14,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {items.length}
            </Typography>
          </Typography>
        </Stack>
        <MyTable
          rerender
          columns={reasonUpdateColumns}
          data={[item]}
          getActionButtons={getActionButtons}
        />
      </Stack>

      <MyDialog
        isAlert
        open={warning?.open}
        setOpen={() => setWarning({ ...warning, open: false })}
        subTitle={warning?.subTitle}
        onOk={() => handleUpdateReason()}
      />
    </MyDialog>
  );
};

export default RegisterReasonDialog;
