import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const salesPerformServices = {
  async getSales(data) {
    try {
      const res = await new RestService()
        .setPath('performance/sales-status')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSales(data) {
    try {
      const res = await new RestService()
        .setPath('performance/export-sales-status')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getSalesDetail(data) {
    try {
      const res = await new RestService()
        .setPath('performance/detail-sales-status')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSalesDetail(data) {
    try {
      const res = await new RestService()
        .setPath('performance/export-detail-sales-status')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default salesPerformServices;
