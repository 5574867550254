import { tableTypes } from '@constants/utils';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const s = i18n.getFixedT(null, null, 'pages.statistics');
const g = i18n.getFixedT(null, null, '');

export const columns = [
  {
    name: 'memberName',
    label: g('common.member'),
    align: 'center',
    minWidth: 160,
    render: ({ memberName, phone }) => memberName || phone
  },
  {
    name: 'productName',
    label: s('most-purchased-product-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'totalPrice',
    label: s('payment-amount'),
    align: 'center',
    minWidth: 160,
    render: ({ totalPrice }) => formatMoney(totalPrice)
  },
  {
    name: 'quantity',
    label: s('purchase-quantity'),
    align: 'center',
    minWidth: 160,
    render: ({ quantity }) => formatMoney(quantity)
  },
  {
    name: 'numOfUse',
    label: s('number-of-uses'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'num',
    label: s('note'),
    align: 'center',
    minWidth: 160,
    type: tableTypes.ACTION
  }
];

export const paymentColumns = [
  {
    name: 'num',
    label: 'No.',
    align: 'center',
    minWidth: 60,
    render: (item, index) => index + 1
  },
  {
    name: 'productName',
    label: g('pages.product.product-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'clientName',
    label: g('common.client'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'kioskLocation',
    label: s('kiosk-location'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'totalPayment',
    label: s('payment-amount'),
    align: 'center',
    minWidth: 160,
    render: ({ price }) => formatMoney(price)
  },
  {
    name: 'purchaseQuantity',
    label: s('purchase-quantity'),
    align: 'center',
    minWidth: 160,
    render: ({ purchaseQuantity }) => formatMoney(purchaseQuantity)
  },
  {
    name: 'numOfUse',
    label: s('number-of-uses'),
    align: 'center',
    minWidth: 160
  }
];
