import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { Formatter } from '@utils/formatter';
import { dayOfWeek } from '@utils/helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MyDialog from '@components/MyDialog';
import scheduleService from '@services/schedule';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import warehouseService from '@services/warehouse';
import error2Text from '@utils/error2Text';
import {
  scheduleDailyColumns,
  scheduleDetailColumns,
  scheduleMonthlyColumns
} from './enhance';
import ShippingInfo from './components/ShippingInfo';
import ScheduleForm from './components/ScheduleForm';

const ScheduleDetail = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const location = useLocation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [gValue, setGValue] = useState(null);
  const [listDetail, setListDetail] = useState([]);
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const [state, setState] = useState(null);
  const showShipInfo = async (item) => {
    const rs = await scheduleService.getShippingInfo(
      state?.schCode,
      item?.schDetailId
    );
    if (!rs?.error) {
      setSelected({
        ...selected,
        open: true,
        title: t('pages.display.shipping-info'),
        action: 'showShippingInfo',
        item: { ...rs?.data?.data, clientChannel: item?.clientChannel }
      });
    }
  };
  const scheduleDailyColumn = scheduleDailyColumns(
    state?.listScheduleDailyProgressDto?.length
  );
  const scheduleDetailColumn = scheduleDetailColumns(
    showShipInfo,
    state?.listScheduleDetail?.length
  );
  const getActionButtons = (item) => (
    <Stack direction="row" justifyContent="center">
      <IconButton
        size="small"
        onClick={async () => {
          const rs = await warehouseService.getPic();
          const data = rs?.data?.items?.filter(
            (i) => i.managerCode === state?.managerCode
          )?.[0];
          setGValue({
            ...gValue,
            ...item,
            ...state,
            managerArea: data?.managerArea,
            managerZone: data?.managerZone
          });
          setListDetail([item]);
          setSelected({
            ...selected,
            title: t('pages.inventory.modify'),
            open: true,
            action: 'edit'
          });
        }}
      >
        <EditIcon color="primary" />
      </IconButton>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setNotifyDialog({
            open: true,
            title: t('pages.display.wanna-delete-sch'),
            onOk: () => {
              handleDeleteSch(item);
            },
            setOpen: () => {
              setNotifyDialog({ ...notifyDialog, open: false });
            }
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );

  const handleDeleteSch = async (item) => {
    const rs = await scheduleService.deleteSchedule(
      state?.schCode,
      item?.schDetailId
    );
    if (!rs?.error) {
      getScheduleDetail();
      setNotifyDialog(null);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const getScheduleDetail = async () => {
    const rs = await scheduleService.getScheduleDetail(location?.state?.id);
    if (!rs?.error) {
      setState(rs?.data?.data);
    }
  };

  useEffect(() => {
    getScheduleDetail();
  }, []);
  const [loading, setLoading] = useState(false);
  const downloadDetail = async () => {
    setLoading(true);
    const rs = await scheduleService.exportScheduleDetail(location?.state?.id);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };
  const dialogOk = async () => {
    if (selected?.action === 'edit') {
      const dataSubmit = {
        clientCode: listDetail?.[0]?.clientCode,
        minibarCode: listDetail?.[0]?.minibarCode,
        managerZone: gValue.managerZone,
        managerArea: gValue.managerArea
      };
      const rs = await scheduleService.editSchedule(
        state?.schCode,
        gValue?.schDetailId,
        dataSubmit
      );
      if (!rs?.error) {
        getScheduleDetail();
        setSelected({ ...selected, open: false });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    } else {
      const dataSubmit = {
        clientCode: listDetail?.[0]?.clientCode,
        minibarCode: listDetail?.[0]?.minibarCode || '',
        managerZone: gValue.managerZone,
        managerArea: gValue.managerArea
      };
      const rs = await scheduleService.createScheduleDetail(
        state?.schCode,
        dataSubmit
      );
      if (!rs?.error) {
        getScheduleDetail();
        setSelected({ ...selected, open: false });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.schedule-detail')}
      content="Display Detail Page"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ color: 'black', fontWeight: 600 }}>
          {t('pages.display.schedule')} -{' '}
          <span style={{ color: '#4267EB', fontWeight: 600 }}>
            {formatTime(state?.shippingDate, 'MM/DD')} (
            {dayOfWeek(state?.shippingDate)})
          </span>
        </Typography>
        <Stack flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            onClick={downloadDetail}
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>
          <Button
            variant="outlined"
            onClick={async () => {
              const rs = await warehouseService.getPic();
              const data = rs?.data?.items?.filter(
                (i) => i.managerCode === state?.managerCode
              )?.[0];
              setGValue({
                ...gValue,
                ...state,
                managerArea: data?.managerArea,
                managerZone: data?.managerZone
              });
              setListDetail([
                {
                  index: 1,
                  clientChannel: '',
                  clientName: '',
                  clientCode: '',
                  minibarNickname: '',
                  minibarCode: '',
                  managerZone: '',
                  managerArea: ''
                }
              ]);
              setSelected({
                ...selected,
                open: true,
                title: t('button.register'),
                action: 'register-detail'
              });
            }}
          >
            {t('button.add')}
          </Button>
        </Stack>
      </Stack>
      <MyTable
        rerender
        data={state?.listScheduleDetail}
        columns={scheduleDetailColumn}
        getActionButtons={getActionButtons}
      />
      <Stack>
        <Typography sx={{ color: 'black', fontWeight: 600 }}>
          {`${t('pages.display.daily')} ${t('pages.display.progress')} ${t(
            'pages.inventory.managements'
          )}`}
        </Typography>
        <MyTable
          rerender
          columns={scheduleDailyColumn}
          data={state?.listScheduleDailyProgressDto}
        />
      </Stack>
      <Stack>
        <Typography sx={{ color: 'black', fontWeight: 600 }}>
          {`${t('pages.display.monthly')} ${t('pages.display.progress')} ${t(
            'pages.inventory.managements'
          )}`}{' '}
          ({state?.managerName})
        </Typography>
        <MyTable
          rerender
          columns={scheduleMonthlyColumns}
          data={state?.listScheduleMonthlyProgressDto}
        />
      </Stack>
      <MyDialog
        open={notifyDialog?.open}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        hasCancelButton={selected?.action !== 'showShippingInfo'}
        onOk={() =>
          selected?.action !== 'showShippingInfo'
            ? dispatch(
                onOpenConfirm({
                  open: true,
                  type: selected?.action === 'edit' ? 'modify' : 'save',
                  action: () => dialogOk()
                })
              )
            : setSelected({ ...selected, open: false })
        }
        sx={{ maxHeight: '100%', minHeight: 600 }}
      >
        {selected?.action === 'showShippingInfo' ? (
          <ShippingInfo data={selected?.item} />
        ) : (
          <ScheduleForm
            value={listDetail}
            setValue={setListDetail}
            gValue={gValue}
            setGValue={setGValue}
            action={selected?.action}
          />
        )}
      </MyDialog>
    </HelmetContainer>
  );
};

export default ScheduleDetail;
