/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
import React, { forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const MyRichTextEditor = forwardRef(({ value, onChange, name }) => {
  return (
    <Editor
      apiKey="fejvyossr932qg3qpq0murrv553euw5rkrq4a7vy3l4d4oq1"
      value={value}
      onEditorChange={(newValue) => {
        onChange({ target: { name, value: newValue } });
      }}
      init={{
        selector: 'textarea',
        min_height: 500,
        width: '100%',
        menubar: false,
        statusbar: false,
        language: 'ko_KR',
        language_url: '/langs/ko_KR.js',
        help_accessibility: false,
        directionality: 'ltr',
        block_formats: 'p=p; h1=h1; h2=h2; h3=h3; h4=h4; h5=h5; h6=h6',
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'wordcount'
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  );
});

export default MyRichTextEditor;
