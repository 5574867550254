/* eslint-disable unused-imports/no-unused-vars */
import React, { forwardRef } from 'react';
import { FormControl, FormControlLabel, TextField } from '@mui/material';

const MyTextField = forwardRef(
  (
    {
      label,
      sx,
      errMg,
      required,
      typeValue,
      onChange,
      textFieldStyle,
      fullWidth = false,
      placeholder,
      ...rest
    },
    ref
  ) => {
    if (!label)
      return (
        <FormControl sx={{ ...sx, ...(fullWidth && { width: '100%' }) }}>
          <TextField
            size="small"
            sx={{
              '.MuiInputBase-root': {
                bgcolor: 'common.white',
                ...textFieldStyle
              }
            }}
            autoFocus={false}
            placeholder={placeholder}
            onChange={(e) => {
              if (typeValue === 'onlyNumber') {
                const value = e.target.value.replace(/\D/g, '');
                e.target.value = value;
                onChange(e);
              } else {
                onChange(e);
              }
            }}
            error={errMg}
            inputRef={ref}
            {...rest}
          />
          {errMg && (
            <span
              style={{
                color: 'red',
                fontSize: 12
              }}
            >
              * {errMg}
            </span>
          )}
        </FormControl>
      );
    return (
      <FormControl sx={{ flexDirection: 'row', ...sx }} error={errMg}>
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            },
            ...sx?.label
          }}
          control={
            <TextField
              size="small"
              sx={{
                '.MuiInputBase-root': { bgcolor: 'common.white' },
                ml: 1,
                ...sx?.textfield
              }}
              autoFocus={false}
              onChange={(e) => {
                if (typeValue === 'onlyNumber') {
                  const value = e.target.value.replace(/\D/g, '');
                  e.target.value = value;
                  onChange(e);
                } else {
                  onChange(e);
                }
              }}
              inputRef={ref}
              {...rest}
            />
          }
        />
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  }
);

export default MyTextField;
