import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import MyTextField from '@components/MyTextField';
import { Formatter } from '@utils/formatter';

import { MAX_INT } from '@constants/utils';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  marginBottom: 20,
  padding: '0px !important'
});

const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '116px'
};

export default function PerformanceForm({
  item,
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const { t } = useTranslation();
  const [performance, setPerformance] = useState({
    jan: 0,
    mar: 0,
    feb: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dcb: 0
  });

  const [errorMessage, setErrorMessage] = useState({
    jan: '',
    mar: '',
    feb: '',
    apr: '',
    may: '',
    jun: '',
    jul: '',
    aug: '',
    sep: '',
    oct: '',
    nov: '',
    dcb: ''
  });

  const handleChangeValue = (e) => {
    const { value, name, align } = e.target;
    const checkValue =
      align === 'number' ? Formatter.fNumber(value.replaceAll(',', '')) : value;
    setPerformance({ ...performance, [name]: checkValue });

    const valueInNumber = value.replaceAll(',', '');
    setErrorMessage({
      ...errorMessage,
      [name]:
        valueInNumber > MAX_INT
          ? t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) })
          : ''
    });
  };

  const validationOnSubmit = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const errMsg in errorMessage) {
      if (errorMessage[errMsg]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (validationOnSubmit()) {
      if (onSubmit) {
        const dataSubmit = getPerformanceInNumber();
        onSubmit(dataSubmit);
      } else {
        onClose();
      }
    }
  };

  const getPerformanceInNumber = () => {
    let temp = {};
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in performance) {
      temp = {
        ...temp,
        [property]:
          parseInt(Formatter.fStringToNumber(performance?.[property]), 10) || 0
      };
    }
    return temp;
  };

  useEffect(() => {
    if (item) {
      const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dcb } =
        item;
      setPerformance({
        jan: jan || 0,
        feb: feb || 0,
        mar: mar || 0,
        apr: apr || 0,
        may: may || 0,
        jun: jun || 0,
        jul: jul || 0,
        aug: aug || 0,
        sep: sep || 0,
        oct: oct || 0,
        nov: nov || 0,
        dcb: dcb || 0
      });
    }
  }, [item]);
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        mt: '30px',
        p: 0,
        maxWidth: 1000,
        maxHeight: '80vh'
      }}
    >
      <Container component={BorderBox}>
        <Stack
          justifyContent="space-between"
          alignItems="flex-end"
          direction="row"
        >
          <Stack
            direction="row"
            sx={{
              flexGrow: 1
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                borderBottomLeftRadius: '8px',
                borderTopLeftRadius: '8px',
                ...labelStyle
              }}
            >
              <Typography
                sx={{
                  width: 100,
                  fontSize: 13,
                  color: '#6F869C'
                }}
              >
                {t('labels.group.channel')}
              </Typography>
            </Stack>

            <MyTextField
              size="small"
              value={item.clientChannel}
              disabled
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                margin: '10px 0px'
              }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              borderLeft: '1px solid #D3D3D3',
              flexGrow: 1
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                ...labelStyle
              }}
            >
              <Typography
                sx={{
                  width: 100,
                  fontSize: 13,
                  color: '#6F869C'
                }}
              >
                {t('labels.clientName')}
              </Typography>
            </Stack>

            <MyTextField
              size="small"
              value={item.clientName}
              disabled
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                margin: '10px 0px'
              }}
            />
          </Stack>
        </Stack>
      </Container>
      <Container component={BorderBox}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          sx={{
            paddingLeft: 2
          }}
        >
          <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
            {Object.keys(performance).map((month, i) => (
              <Stack direction="row" alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    ...labelStyle,
                    width: 70,
                    height: 40
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 13,
                      color: '#6F869C'
                    }}
                  >
                    {`${i + 1}${t('labels.month')}`}
                  </Typography>
                </Stack>
                <MyTextField
                  name={month}
                  value={Formatter.fNumber(
                    performance?.[month]?.toString().replaceAll(',', '')
                  )}
                  onChange={handleChangeValue}
                  typeValue="onlyNumber"
                  errMg={errorMessage[month]}
                  size="small"
                  sx={{
                    paddingRight: 2,
                    width: 150,
                    margin: '10px 0px'
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {submitButtonProps.text || t('button.save')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
