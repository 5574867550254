import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.product');

export const pricePolicyTypeColumns = [
  {
    name: 'productName',
    label: t('product-name'),
    minWidth: 160,
    align: 'center'
  },
  { name: 'barcode', label: t('bar-code'), minWidth: 160, align: 'center' },
  {
    name: 'master_parent',
    label: t('master-price'),
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'masterPrice',
        label: t('guide'),
        minWidth: 80,
        align: 'center',
        render: ({ masterPrice }) => formatMoney(masterPrice)
      },
      {
        name: 'masterMargin',
        label: t('margin'),
        minWidth: 80,
        align: 'center',
        render: ({ masterMargin }) => formatMoney(masterMargin)
      },
      {
        name: 'masterMarginRate',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ masterMarginRate }) => formatMoney(masterMarginRate)
      },
      {
        name: 'masterCostRate',
        label: t('cost-price'),
        minWidth: 80,
        align: 'center',
        render: ({ masterCostRate }) => formatMoney(masterCostRate)
      }
    ]
  },
  {
    name: 'type1',
    label: `${t('type')} 1`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType1',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType1 }) => formatMoney(pricePolicyType1)
      },
      {
        name: 'priceMarginRateType1',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType1 }) => formatMoney(priceMarginRateType1)
      }
    ]
  },
  {
    name: 'type2',
    label: `${t('type')} 2`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType2',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType2 }) => formatMoney(pricePolicyType2)
      },
      {
        name: 'priceMarginRateType2',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType2 }) => formatMoney(priceMarginRateType2)
      }
    ]
  },
  {
    name: 'type3',
    label: `${t('type')} 3`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType3',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType3 }) => formatMoney(pricePolicyType3)
      },
      {
        name: 'priceMarginRateType3',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType3 }) => formatMoney(priceMarginRateType3)
      }
    ]
  },
  {
    name: 'type4',
    label: `${t('type')} 4`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType4',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType4 }) => formatMoney(pricePolicyType4)
      },
      {
        name: 'priceMarginRateType4',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType4 }) => formatMoney(priceMarginRateType4)
      }
    ]
  },
  {
    name: 'type5',
    label: `${t('type')} 5`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType5',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType5 }) => formatMoney(pricePolicyType5)
      },
      {
        name: 'priceMarginRateType5',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType5 }) => formatMoney(priceMarginRateType5)
      }
    ]
  },
  {
    name: 'type6',
    label: `${t('type')} 6`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType6',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType6 }) => formatMoney(pricePolicyType6)
      },
      {
        name: 'priceMarginRateType6',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType6 }) => formatMoney(priceMarginRateType6)
      }
    ]
  },
  {
    name: 'type7',
    label: `${t('type')} 7`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType7',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType7 }) => formatMoney(pricePolicyType7)
      },
      {
        name: 'priceMarginRateType7',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType7 }) => formatMoney(priceMarginRateType7)
      }
    ]
  },
  {
    name: 'type8',
    label: `${t('type')} 8`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType8',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType8 }) => formatMoney(pricePolicyType8)
      },
      {
        name: 'priceMarginRateType8',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType8 }) => formatMoney(priceMarginRateType8)
      }
    ]
  },
  {
    name: 'type9',
    label: `${t('type')} 9`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType9',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType9 }) => formatMoney(pricePolicyType9)
      },
      {
        name: 'priceMarginRateType9',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType9 }) => formatMoney(priceMarginRateType9)
      }
    ]
  },
  {
    name: 'type10',
    label: `${t('type')} 10`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType10',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType10 }) => formatMoney(pricePolicyType10)
      },
      {
        name: 'priceMarginRateType10',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType10 }) =>
          formatMoney(priceMarginRateType10)
      }
    ]
  },
  {
    name: 'type11',
    label: `${t('type')} 11`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType11',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType11 }) => formatMoney(pricePolicyType11)
      },
      {
        name: 'priceMarginRateType11',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType11 }) =>
          formatMoney(priceMarginRateType11)
      }
    ]
  },
  {
    name: 'type12',
    label: `${t('type')} 12`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType12',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType12 }) => formatMoney(pricePolicyType12)
      },
      {
        name: 'priceMarginRateType12',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType12 }) =>
          formatMoney(priceMarginRateType12)
      }
    ]
  },
  {
    name: 'type13',
    label: `${t('type')} 13`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType13',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType13 }) => formatMoney(pricePolicyType13)
      },
      {
        name: 'priceMarginRateType13',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType13 }) =>
          formatMoney(priceMarginRateType13)
      }
    ]
  },
  {
    name: 'type14',
    label: `${t('type')} 14`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType14',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType14 }) => formatMoney(pricePolicyType14)
      },
      {
        name: 'priceMarginRateType14',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType14 }) =>
          formatMoney(priceMarginRateType14)
      }
    ]
  },
  {
    name: 'type15',
    label: `${t('type')} 15`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType15',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType15 }) => formatMoney(pricePolicyType15)
      },
      {
        name: 'priceMarginRateType15',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType15 }) =>
          formatMoney(priceMarginRateType15)
      }
    ]
  },
  {
    name: 'type16',
    label: `${t('type')} 16`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType16',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType16 }) => formatMoney(pricePolicyType16)
      },
      {
        name: 'priceMarginRateType16',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType16 }) =>
          formatMoney(priceMarginRateType16)
      }
    ]
  },
  {
    name: 'type17',
    label: `${t('type')} 17`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType17',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType17 }) => formatMoney(pricePolicyType17)
      },
      {
        name: 'priceMarginRateType17',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType17 }) =>
          formatMoney(priceMarginRateType17)
      }
    ]
  },
  {
    name: 'type18',
    label: `${t('type')} 18`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType18',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType18 }) => formatMoney(pricePolicyType18)
      },
      {
        name: 'priceMarginRateType18',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType18 }) =>
          formatMoney(priceMarginRateType18)
      }
    ]
  },
  {
    name: 'type19',
    label: `${t('type')} 19`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType19',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType19 }) => formatMoney(pricePolicyType19)
      },
      {
        name: 'priceMarginRateType19',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType19 }) =>
          formatMoney(priceMarginRateType19)
      }
    ]
  },
  {
    name: 'type20',
    label: `${t('type')} 20`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType20',
        label: t('price'),
        minWidth: 80,
        align: 'center',
        render: ({ pricePolicyType20 }) => formatMoney(pricePolicyType20)
      },
      {
        name: 'priceMarginRateType20',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center',
        render: ({ priceMarginRateType20 }) =>
          formatMoney(priceMarginRateType20)
      }
    ]
  }
];

export const pricePolicyTypeModifyColumns = [
  {
    name: 'productName',
    label: t('product-name'),
    minWidth: 160,
    align: 'center'
  },
  { name: 'barcode', label: t('bar-code'), minWidth: 120, align: 'center' },
  {
    name: 'master_parent',
    label: t('master-price'),
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'masterPrice',
        label: t('guide'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'masterMargin',
        label: t('margin'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'masterMarginRate',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'masterCostRate',
        label: t('cost-price'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type1',
    label: `${t('type')} 1`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType1',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType1',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type2',
    label: `${t('type')} 2`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType2',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType2',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type3',
    label: `${t('type')} 3`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType3',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType3',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type4',
    label: `${t('type')} 4`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType4',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType4',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type5',
    label: `${t('type')} 5`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType5',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType5',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type6',
    label: `${t('type')} 6`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType6',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType6',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type7',
    label: `${t('type')} 7`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType7',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType7',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type8',
    label: `${t('type')} 8`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType8',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType8',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type9',
    label: `${t('type')} 9`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType9',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType9',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type10',
    label: `${t('type')} 10`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType10',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType10',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type11',
    label: `${t('type')} 11`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType11',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType11',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type12',
    label: `${t('type')} 12`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType12',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType12',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type13',
    label: `${t('type')} 13`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType13',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType13',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type14',
    label: `${t('type')} 14`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType14',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType14',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type15',
    label: `${t('type')} 15`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType15',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType15',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type16',
    label: `${t('type')} 16`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType16',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType16',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type17',
    label: `${t('type')} 17`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType17',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType17',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type18',
    label: `${t('type')} 18`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType18',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType18',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type19',
    label: `${t('type')} 19`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType19',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType19',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  },
  {
    name: 'type20',
    label: `${t('type')} 20`,
    minWidth: 160,
    align: 'center',
    children: [
      {
        name: 'pricePolicyType20',
        label: t('price'),
        minWidth: 80,
        align: 'center'
      },
      {
        name: 'priceMarginRateType20',
        label: t('margin-rate'),
        minWidth: 80,
        align: 'center'
      }
    ]
  }
];

export const radioOptions = (t) => [
  { value: '', label: t('common.total') },
  { value: 'Y', label: t('common.completion') },
  { value: 'N', label: t('common.necessary') }
];
