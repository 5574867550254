import MyTable from '@components/MyTable';
import memberService from '@services/member';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentConfigColumns } from '../enhance';

export default function MemberDetailForm({ memberCode = '' }) {
  const [payments, setPayments] = useState([]);
  const { t } = useTranslation();

  const getMemberDetail = async () => {
    const result = await memberService.getPaymentHistory(memberCode);
    if (result?.data) {
      setPayments(result.data?.data || []);
    }
  };
  useEffect(() => {
    getMemberDetail();
  }, []);
  return <MyTable hover columns={paymentConfigColumns(t)} data={payments} />;
}
