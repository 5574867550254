import { Checkbox } from '@mui/material';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';
import { tableTypes } from '@constants/utils';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, 'pages');
const picking = i18n.getFixedT(null, null, 'pages.picking');

export const returningSelectOptions = [
  { value: 'productName', label: p('product-name') },
  { value: 'barcode', label: p('bar-code') },
  { value: 'clientName', label: g('client-order.orderClient') }
];
export const columns = (onEvent, selectedItem) => [
  {
    name: 'select',
    label: t('select'),
    minWidth: 60,
    align: 'center',
    render: (item) => {
      return (
        <Checkbox
          checked={
            selectedItem?.filter(
              (i) => i?.returningCode === item?.returningCode
            )?.length
          }
          onClick={(e) => {
            e.stopPropagation();
            onEvent(item, e);
          }}
        />
      );
    }
  },
  {
    name: 'regDt',
    label: t('date'),
    minWidth: 120,
    align: 'center',
    render: ({ regDt }) => regDt && Formatter.formatTime(regDt)
  },
  // {
  //   name: 'centerName',
  //   label: t('center'),
  //   minWidth: 100,
  //   align: 'center'
  // }

  {
    name: 'clientName',
    label: g('client-order.orderClient'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: p('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'shelfLife',
    label: g('inventory.shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) => Formatter.formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'registerAdmin',
    label: g('picking.registerAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'modifyAdmin',
    label: g('picking.modifyAdmin'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'returningQuantity',
    label: t('restock-quantity'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'returningReason',
    label: t('restock-reason'),
    minWidth: 150,
    align: 'center',
    render: ({ returningReason }) =>
      reasonOptions.includes(returningReason) ? returningReason : '기타'
  }
];
export const reasonSelectOptions = [
  {
    value: 'all',
    label: '전체'
  },
  {
    value: '진열 후 남은 상품',
    label: '진열 후 남은 상품'
  },
  {
    value: '철수 제품',
    label: '철수 제품'
  },
  { value: '기타', label: '기타' }
];
export const reasonOptions = ['진열 후 남은 상품', '철수 제품'];

export const historyColumns = [
  {
    name: 'regDt',
    label: t('date'),
    minWidth: 120,
    align: 'center',
    render: ({ regDt }) => regDt && Formatter.formatTime(regDt)
  },
  // {
  //   name: 'centerName',
  //   label: t('center'),
  //   minWidth: 100,
  //   align: 'center'
  // }

  {
    name: 'clientName',
    label: g('client-order.orderClient'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: p('minibar'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'vendorName',
    label: p('vendor'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'shelfLife',
    label: g('inventory.shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) => Formatter.formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'registerAdmin',
    label: g('picking.registerAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'modifyAdmin',
    label: g('picking.modifyAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'returningQuantity',
    label: t('restock-quantity'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'returningReason',
    label: t('restock-reason'),
    minWidth: 150,
    align: 'center',
    render: ({ returningReason }) =>
      reasonOptions.includes(returningReason) ? returningReason : '기타'
  }
];

export const reasonUpdateColumns = [
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 120,
    align: 'center',
    render: ({ expiryDate }) =>
      expiryDate && Formatter.formatTime(expiryDate, 'YYYY-MM-DD')
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'returningQuantity',
    label: picking('lossQuantity'),
    minWidth: 80,
    align: 'center'
  },

  {
    label: picking('reason'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 2
  },
  {
    name: 'registerAdmin',
    label: t('pic'),
    minWidth: 100,
    align: 'center'
  }
];
