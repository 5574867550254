import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import preorderReducer from './preorderReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  preorder: preorderReducer
});

export default rootReducer;
