/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import MyTextField from '@components/MyTextField';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MyDialog from '@components/MyDialog';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import pricePolicyService from '@services/pricePolicy';
import { useDispatch } from 'react-redux';
import MyLoadingButton from '@components/MyLoadingButton';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import ProductTypeModification from './components/ProductTypeModification';
import { pricePolicyTypeColumns, radioOptions } from './enhance';
import ExcelRegisterDialog from './ExcelRegisterDialog';

const PricePolicyTypePage = () => {
  const { t } = useTranslation();
  const columnsConfig = pricePolicyTypeColumns;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    page: 0,
    isPriceType: '',
    productName: ''
  });
  const [state, setState] = React.useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [selected, setSelected] = useState(null);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const { items, pagination } = state;
  const getPricePolicyTypeList = async (param) => {
    const rs = await pricePolicyService.getPricePolicyList(param);
    setState({
      ...state,
      items: rs?.data?.items || [],
      pagination: {
        ...state.pagination,
        pageSize: rs?.data?.pageSize || 0,
        page: rs?.data?.page || 0,
        totalRow: rs?.data?.totalRow || 0,
        count: rs?.data?.totalPage || 0
      }
    });
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    setParam({
      ...param,
      isPriceType: query?.isPriceType || '',
      productName: query?.productName || '',
      page: query?.page - 1 || 0
    });
    getPricePolicyTypeList({
      ...param,
      isPriceType: query?.isPriceType || '',
      productName: query?.productName || '',
      page: query?.page - 1 || 0
    });
  }, [location?.search]);
  const onAction = (action, value) => {
    if (action === 'edit') {
      setSelected({
        ...selected,
        items: value,
        open: true,
        title: t('pages.product.product-type-modify')
      });
    }
    if (action === 'changePage') {
      setParam({ ...param, page: value });
      const query = objToParams(
        ignoreEmpty({
          ...param,
          page: value + 1
        })
      );
      navigate(query);
    }
  };
  const handleChangeModify = (e) => {
    setSelected({
      ...selected,
      items: {
        ...selected?.items,
        // eslint-disable-next-line no-unsafe-optional-chaining
        [e.target.name]: +e.target.value?.replace(/,/g, '')
      }
    });
  };
  const handleImportExcel = () => {
    setOpenUploadFile(true);
  };
  const onSuccessImportExcel = () => {
    getPricePolicyTypeList(param);
  };
  const handleSubmit = async (action) => {
    if (action === 'submitModification') {
      const rs = await pricePolicyService.editPricePolicy(selected?.items);
      if (!rs?.error) {
        setSelected({
          ...selected,
          open: false
        });
        getPricePolicyTypeList(param);
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
    if (action === 'saveExcel') {
      const rs = await pricePolicyService.exportData(param);
      if (rs?.error) {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };
  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getPricePolicyTypeList({ ...param, page: 0 });
    navigate(query);
  };
  return (
    <HelmetContainer
      title={t('labels.pricePolicyType')}
      content="Price Policy Type page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {radioOptions(t).map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({ ...param, isPriceType: e.target.value })
                        }
                        checked={param?.isPriceType === item.value}
                        size="small"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <MyTextField
          label={t('pages.product.product-name')}
          placeholder={t('button.search')}
          onChange={(e) => setParam({ ...param, productName: e.target.value })}
          value={param?.productName}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleImportExcel}>
            {t('button.excel-register')}
          </Button>
          <MyLoadingButton
            variant="outlined"
            onClick={() => handleSubmit('saveExcel')}
          >
            {t('button.saveExcel')}
          </MyLoadingButton>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <GroupMultiHeaderTable
        hover
        columns={columnsConfig}
        data={items}
        pagination={pagination}
        onRowClick={(e) => onAction('edit', e)}
        minusHeight={235}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
      <MyDialog
        open={selected?.open}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        title={selected?.title}
        onOk={() =>
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleSubmit('submitModification')
            })
          )
        }
        hasCloseButton
        hasCancelButton
      >
        <ProductTypeModification
          data={selected?.items}
          onChange={handleChangeModify}
        />
      </MyDialog>
      <ExcelRegisterDialog
        open={openUploadFile}
        setOpen={setOpenUploadFile}
        key={openUploadFile}
        onSuccess={onSuccessImportExcel}
      />
    </HelmetContainer>
  );
};

export default PricePolicyTypePage;
