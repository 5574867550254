/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import pickingService from '@services/picking';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useTranslation } from 'react-i18next';
import MyTextField from '@components/MyTextField';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack, Typography } from '@mui/material';
import MySelect from '@components/MySelect';
import { nanoid } from 'nanoid';
import {
  reasonSelectOptions,
  reasonUpdateColumns,
  reasonUpdateColumnsIndi
} from '../enhance';

const RegisterReasonDialog = ({
  open,
  setOpen,
  pickingCode,
  pickingProductCode,
  type,
  onSuccess
}) => {
  const dispatch = useDispatch();
  const [selectList, setSelectList] = useState({
    clientList: [],
    minibarList: []
  });
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const columns =
    type === 'total' ? reasonUpdateColumns : reasonUpdateColumnsIndi;
  const MAX_QUANTITY = 50;
  const [warning, setWarning] = useState({
    open: false,
    isValid: false,
    action: ''
  });

  const [state, setState] = useState({
    totalShortageQuantity: 0,
    fixedShortageQuantity: 0,
    isValidToSubmit: false,
    isValidToAdd: true
  });
  const getReasonData = async () => {
    // if (!itemCode) return;
    // if (item?.productCode) return;

    let rs;
    switch (type) {
      case 'individual':
        const dataParams = {
          individualPickingProductCode: pickingProductCode
        };
        rs = await pickingService.getIndividualPickingNoteDetail(
          pickingCode,
          dataParams
        );
        break;
      case 'total':
        const data = {
          totalPickingProductCode: pickingProductCode
        };
        rs = await pickingService.getTotalPickingNoteDetail(pickingCode, data);
        break;
      default:
        break;
    }
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      const formattedData = rs?.data?.data?.noteList.map((item) => {
        if (item?.note?.toLowerCase().split(':')[0].trim().includes('기타')) {
          return {
            ...item,
            filteredMinibarList: item?.listMinibars
              ?.filter((i) => i.clientCode === item?.clientCode)
              ?.map((v) => {
                if (v.minibarCode)
                  return { value: v.minibarCode, label: v.minibarNickname };
              }),

            note: item?.note?.toLowerCase().split(':')[0].trim(),
            noteInput: item?.note?.toLowerCase().split(':')[1]
          };
        }
        return {
          ...item,
          filteredMinibarList:
            item?.listMinibars
              ?.filter((i) => i.clientCode === item?.clientCode)
              ?.map((v) => {
                if (v.minibarCode)
                  return { value: v.minibarCode, label: v.minibarNickname };
              }) || []
        };
      });
      setItems(formattedData || []);
      setState({
        ...state,
        baseRow: { ...rs?.data?.data?.noteList?.[0] },
        totalShortageQuantity: rs?.data?.data?.totalShortageQuantity
      });
    }
    // setItems(dummyData || []);
  };

  const isValidToSave =
    items?.reduce((accumulator, currentItem) => {
      if (type === 'total') {
        if (
          parseInt(currentItem?.shortageQuantity, 10) > 0 &&
          currentItem?.note &&
          currentItem?.pickingCode
        ) {
          return accumulator + parseInt(currentItem.shortageQuantity, 10);
        }
      } else if (
        parseInt(currentItem?.shortageQuantity, 10) > 0 &&
        currentItem?.note
      ) {
        return accumulator + parseInt(currentItem.shortageQuantity, 10);
      }

      return accumulator;
    }, 0) === state?.totalShortageQuantity;
  useEffect(() => {
    const totalFixedQuantity = items?.reduce((accumulator, currentItem) => {
      if (
        parseInt(currentItem?.shortageQuantity, 10) > 0 &&
        currentItem?.note &&
        currentItem?.pickingCode
      ) {
        return accumulator + parseInt(currentItem.shortageQuantity, 10);
      }
      return accumulator;
    }, 0);
    setState({
      ...state,
      fixedShortageQuantity: totalFixedQuantity,
      isValidToSubmit: MAX_QUANTITY === totalFixedQuantity,
      isValidToAdd:
        parseInt(MAX_QUANTITY, 10) < parseInt(totalFixedQuantity, 10)
    });
  }, [items]);
  const onAction = (name, item, value) => {
    const MAX_INT = item?.shortageQuantity;

    switch (name) {
      case 'quantity':
        setItems((prev) => {
          const updatedItems = prev.map((i) => {
            if (item?.id === i.id) {
              const newQuantity = value;
              return { ...i, shortageQuantity: newQuantity };
            }
            return { ...i };
          });

          // if (totalQuantity < MAX_INT) {
          //   updatedItems.push({ ...prev[0], shortageQuantity: 1, type: 'new' });
          // }

          return updatedItems;
        });
        break;
      case 'reasonSelect':
        setItems((prev) => {
          const updatedItem = prev.map((i) => {
            if (item?.id === i.id) {
              return { ...i, note: value };
            }
            return { ...i };
          });
          return updatedItem;
        });
        break;
      case 'minibarSelect':
        setItems((prev) => {
          const updatedItem = prev.map((i) => {
            if (item?.id === i.id) {
              return { ...i, minibarCode: value, etc: '' };
            }
            return { ...i };
          });
          return updatedItem;
        });
        break;
      case 'clientSelect':
        setItems((prev) => {
          const updatedItem = prev.map((i) => {
            if (item?.id === i.id) {
              const listItems = item?.listMinibars?.filter(
                (i) => i.clientCode === value
              );
              return {
                ...i,
                clientCode: value,
                pickingCode: listItems && listItems[0]?.pickingCode,
                filteredMinibarList:
                  listItems
                    ?.filter((v) => v.minibarCode)
                    ?.map((i) => ({
                      value: i?.minibarCode,
                      label: i?.minibarNickname
                    })) || []
              };
            }
            return { ...i };
          });
          return updatedItem;
        });
        break;
      case 'noteInput':
        setItems((prev) => {
          const updatedItem = prev.map((i) => {
            if (item?.id === i.id) {
              return {
                ...i,
                noteInput: value,
                noteErr: !value ? t('common.required') : ''
              };
            }
            return { ...i };
          });
          return updatedItem;
        });
        break;

      case 'new':
        // eslint-disable-next-line no-case-declarations
        const adminName = localStorage.getItem('userId');
        setItems((prev) => {
          const updatedList = [
            ...items,
            {
              ...state?.baseRow,
              id: nanoid(),
              shortageQuantity: 0,
              note: '',
              clientCode: '',
              pickingCode: '',
              minibarCode: '',
              registerAdminName: adminName,
              type: 'new'
            }
          ];
          return updatedList;
        });
        break;
      default:
        console.log('case not matched');
        break;
    }
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Stack sx={{ width: 100 }}>
            <MyTextField
              value={item?.shortageQuantity}
              typeValue="onlyNumber"
              onChange={(e) => onAction('quantity', item, e?.target.value)}
            />
          </Stack>
        );

      case 2:
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <MySelect
              value={item?.note}
              data={reasonSelectOptions}
              onChange={(e) => onAction('reasonSelect', item, e.target.value)}
              errMg={item?.reasonErr}
            />
            {item?.note === '기타' && (
              <MyTextField
                value={item?.noteInput}
                placeholder="사유 입력해주세요."
                onChange={(e) => onAction('noteInput', item, e?.target.value)}
              />
            )}
          </Stack>
        );

      case 3:
        console.log('minibarList', item?.filteredMinibarList);
        // eslint-disable-next-line no-case-declarations
        const cellContent =
          item?.clientCode && item?.filteredMinibarList.length > 0 ? (
            <MySelect
              value={item?.minibarCode}
              onChange={(e) => onAction('minibarSelect', item, e.target.value)}
              data={item?.filteredMinibarList}
            />
          ) : (
            <Typography>매대 없음</Typography>
          );
        return cellContent;

      case 4:
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setItems((prev) => {
                const updatedItems = prev.filter((i) => i.id !== item?.id);
                return updatedItems;
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      case 5:
        // eslint-disable-next-line no-case-declarations
        const clientList = item?.listMinibars?.map((item) => {
          return {
            value: item?.clientCode,
            label: item?.clientName
          };
        });
        return (
          <MySelect
            value={item?.clientCode}
            onChange={(e) => onAction('clientSelect', item, e.target.value)}
            data={clientList}
          />
        );
      default:
        break;
    }
  };

  const isValid = (items) => {
    let valid = true;
    const MAX_QUANTITY = parseInt(items?.totalShortageQuantity, 10);

    const sumInsertedQuantity = items?.reduce((sum, i) => {
      return sum + (parseInt(i.shortageQuantity, 10) || 0);
    }, 0);

    if (sumInsertedQuantity === MAX_QUANTITY) {
      valid = true;
    }
    return valid;
  };

  const handleUpdateReason = async () => {
    let dataSubmit = {};
    let rs;
    switch (type) {
      case 'individual':
        dataSubmit = {
          productCode: items?.productCode,
          notes: items?.map((i) => ({
            indiPickingNoteCode: i?.individualPickingNoteCode,
            quantity: i?.shortageQuantity,
            note: i.note === '기타' ? `${i?.note}: ${i?.noteInput}` : i?.note
          }))
        };
        rs = await pickingService.updateIndiPickingNote(
          pickingCode,
          dataSubmit
        );
        break;
      case 'total':
        dataSubmit = {
          productCode: items?.[0]?.productCode,
          notes: items.map((i) => ({
            totalPickingNoteCode: i?.pickingTotalNoteCode,
            quantity: i?.shortageQuantity,
            pickingCode: i?.pickingCode,
            note: i.note === '기타' ? `${i?.note}: ${i?.noteInput}` : i?.note
          }))
        };
        rs = await pickingService.updateTotalPickingNote(
          pickingCode,
          dataSubmit
        );
        break;
      default:
        break;
    }
    // handle register
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      onSuccess();
      dispatch(setSuccess({ message: t('info.success.content') }));
      setOpen(false);
    }
  };
  useEffect(() => {
    getReasonData();
  }, [open]);

  return (
    <MyDialog
      open={open}
      setOpen={() => setOpen(false)}
      title={t('pages.picking.reason')}
      hasCloseButton
      hasCancelButton
      disabled={!isValidToSave}
      onOk={handleUpdateReason}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mx={1}
          mb={-1}
        >
          <Stack direction="row" gap={2}>
            <Typography>{`${t('pages.picking.lossQuantity')} (${
              state?.totalShortageQuantity
            })`}</Typography>
          </Stack>
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 14,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {items.length}
            </Typography>
          </Typography>
        </Stack>
        <MyTable
          rerender
          columns={columns}
          data={items}
          getActionButtons={getActionButtons}
          isCategory
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: '-20px',
            border: '1px solid #D3D3D3',
            borderRadius: '0 0 12px 12px'
          }}
        >
          <IconButton
            disableFocusRipple
            onClick={() => onAction('new')}
            sx={{ display: 'flex' }}
            disabled={state.isValidToAdd}
          >
            <AddIcon sx={{ height: 16, color: '#6F869C' }} />
            <Typography
              sx={{
                fontSize: 13,
                color: '#6F869C'
              }}
            >
              {t('button.add')}
            </Typography>
          </IconButton>
        </Stack>
      </Stack>

      <MyDialog
        isAlert
        open={warning?.open}
        setOpen={() => setWarning({ ...warning, open: false })}
        subTitle={warning?.subTitle}
        onOk={() => handleUpdateReason()}
      />
    </MyDialog>
  );
};

export default RegisterReasonDialog;
