import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import DownloadableText from '@components/DownloadableText';
import CustomImage from '@components/CustomImage';

const AttchmentFile = ({
  id,
  fileLink,
  fileName,
  handleDelete,
  sx,
  buttonProps
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: '84px', ...sx }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <CustomImage
          fileName={fileName}
          fileLink={fileLink}
          sx={{ width: '54px', height: '54px' }}
        />
        <DownloadableText
          align="center"
          fileLink={fileLink}
          fileName={fileName}
        >
          {fileName}
        </DownloadableText>
      </Stack>
      {handleDelete && (
        <Button
          variant="outlined"
          sx={{ height: 36 }}
          onClick={() => handleDelete(id)}
          {...buttonProps}
        >
          {t('pages.development-request.delete-attachment')}
        </Button>
      )}
    </Stack>
  );
};

export default AttchmentFile;
