import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import NoData from '@components/NoData';

import { Formatter } from '@utils/formatter';

import {
  equipmentDialogColumns,
  equipmentListTableBodyStyle,
  equipmentListTableHeaderStyle
} from './enhance';

const EquipmentTableRow = ({ item, handleChange }) => {
  const [checked, setChecked] = useState(Boolean(item?.equipmentQuantity));
  const [quantity, setQuantity] = useState(item?.equipmentQuantity);

  const handleChangeQuantity = (e) => {
    const value = Formatter.fStringToRangeNumber(e.target.value, 999);
    setQuantity(value);
  };

  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    if (isChecked) setQuantity(1);
    else setQuantity(0);
  };

  useEffect(() => {
    handleChange(parseInt(quantity, 10), item);
  }, [quantity]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {item.equipmentName}
      </TableCell>
      <TableCell align="center">
        <Checkbox checked={checked} onChange={handleCheck} />
      </TableCell>
      <TableCell align="center" sx={{ maxWidth: 80 }}>
        <TextField
          size="small"
          value={quantity || ''}
          onChange={handleChangeQuantity}
          disabled={!checked}
        />
      </TableCell>
      <TableCell align="center">
        {new Intl.NumberFormat('en-CA').format(
          item?.equipmentPrice && quantity ? item.equipmentPrice * quantity : 0
        )}
      </TableCell>
      <TableCell align="center">{item?.remainingQuantity}</TableCell>
    </TableRow>
  );
};

const ListEquipmentDialog = ({ open, setOpen, data, setData }) => {
  const [t] = useTranslation();
  const handleChange = (quantity, item) => {
    const foundIndex = data.findIndex((x) => x.id === item.id);
    if (foundIndex !== -1) {
      const newData = [...data];
      newData[foundIndex] = {
        ...newData[foundIndex],
        equipmentQuantity: quantity || null,
        sumAmount: quantity * newData[foundIndex].equipmentPrice || 0
      };
      setData(newData);
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.minibar.equipment-register')}
      hasCloseButton
      hasCancelButton
      onCancel={() => {
        setOpen(false);
        setTimeout(() => {
          setData([]);
        }, 200);
      }}
      sx={{
        maxWidth: 900,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableHead sx={equipmentListTableHeaderStyle}>
            <TableRow>
              {equipmentDialogColumns.map((col) => (
                <TableCell
                  key={col.name}
                  align={col.align}
                  sx={{ minWidth: col.minWidth }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={equipmentListTableBodyStyle}>
            {data.length > 0 ? (
              data.map((item) => (
                <EquipmentTableRow
                  item={item}
                  key={item.id}
                  handleChange={handleChange}
                />
              ))
            ) : (
              <TableRow sx={{ height: 576 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                  component="td"
                >
                  <NoData />
                </Box>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default ListEquipmentDialog;
