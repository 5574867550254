/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled, Box, Container, Typography } from '@mui/material';
import HelmetContainer from '@components/HelmetContainer';
import { useParams } from 'react-router-dom';
import orderService from '@services/pre-order/order';
import Lightbox from 'yet-another-react-lightbox';
import Backbutton from '@components/BackButton';
import env from '@constants/env';
import { orderClientInfoSchema, orderStoreInfoSchema } from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%'
};

const labelStyle = {
  backgroundColor: '#FCFCFC',
  borderRight: '1px solid #D3D3D3'
};

export default function ConsultationInquiryDetail() {
  const { orderCode } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const getData = async () => {
    const result = await orderService.getOrderDetail(orderCode);
    setData(result?.data || {});
  };

  useEffect(() => {
    if (orderCode) getData();
  }, [orderCode]);

  return (
    <HelmetContainer
      title={t('pageTitle.preorder-order-detail')}
      content="preorder order detail"
      sx={{ width: '100%' }}
    >
      <Backbutton route={-1} sx={{ mt: -2, mb: 2, ml: 0 }} />
      <Stack sx={{ width: '100%', gap: 1.5, mt: 2 }}>
        {/* Client Detail Section */}
        <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 600 }}>
          {t('pages.pre-order.client-detail')}
        </Typography>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            {orderClientInfoSchema.map((item, index) => (
              <Stack
                key={item.name}
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: item?.grid === 12 ? '100%' : '50%' },
                  flexGrow: 1,
                  borderBottom: {
                    xs: 'none',
                    md:
                      index !== orderClientInfoSchema.length - 1 &&
                      '1px solid #D3D3D3'
                  },
                  ...item.border
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
                <RenderField
                  {...item}
                  value={data}
                  setCurrentIndex={setCurrentIndex}
                  setOpen={setOpen}
                />
              </Stack>
            ))}
          </Stack>
        </Container>
      </Stack>

      {/* Store Info Section */}
      <Stack sx={{ width: '100%', gap: 1.5, mt: 2 }}>
        <Typography sx={{ ml: 1, fontSize: 18, fontWeight: 600 }}>
          {t('pages.pre-order.store-info')}
        </Typography>
        <Container component={BorderBox}>
          <Stack flexDirection="row" flexWrap="wrap">
            {orderStoreInfoSchema.map((item, index) => (
              <Stack
                key={item.name}
                direction="row"
                sx={{
                  borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                  width: { xs: '100%', md: item?.grid === 12 ? '100%' : '50%' },
                  flexGrow: 1,
                  borderBottom: {
                    xs: 'none',
                    md:
                      index !== orderClientInfoSchema.length - 1 &&
                      '1px solid #D3D3D3'
                  },
                  ...item.border
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    flexGrow: 1,
                    padding: '0 10px',
                    ...labelStyle
                  }}
                >
                  <Typography
                    sx={{
                      width: 150,
                      fontSize: 13,
                      color: '#6F869C',
                      textAlign: 'center'
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
                <RenderField
                  {...item}
                  value={data}
                  setCurrentIndex={setCurrentIndex}
                  setOpen={setOpen}
                />
              </Stack>
            ))}
          </Stack>
        </Container>

        {/* Lightbox */}
        {open && data?.displayPhotoUrl && (
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={
              Array.isArray(data.displayPhotoUrl)
                ? data.displayPhotoUrl.map((url) => ({
                    src: `${env.s3BaseUrl}/${url}`
                  }))
                : [{ src: `${env.s3BaseUrl}/${data.displayPhotoUrl}` }]
            }
            index={currentIndex}
            counter={{ style: { top: 'unset', bottom: 0 } }}
            plugins={[]}
            carousel={{ finite: true }}
            styles={{
              root: { '--yarl__color_backdrop': 'rgba(52, 52, 52, 0.6)' }
            }}
          />
        )}
      </Stack>
    </HelmetContainer>
  );
}

const RenderField = ({
  value,

  setCurrentIndex,
  setOpen,
  ...item
}) => {
  const { name, type } = item;

  if (name === 'products') {
    return (
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
          maxHeight: '160px',
          py: 1.5,
          gap: 0.5,
          overflowY: 'scroll',
          width: '100%',

          ...inputStyle,
          justifyContent: 'flex-start',
          textAlign: 'left',
          alignItems: 'flex-start'
        }}
      >
        {value?.[name]?.length > 0 &&
          value?.[name]?.map((item) => (
            <Typography
              key={item.productCode}
              sx={{
                color: '#111111',
                fontSize: 14,
                textAlign: 'left',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1
              }}
            >
              {item?.productName}
            </Typography>
          ))}
      </Stack>
    );
  }

  if (type === 'image') {
    const images = Array.isArray(value?.displayPhotoUrl)
      ? value.displayPhotoUrl
      : [value?.displayPhotoUrl];

    return (
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
          maxHeight: '160px',
          py: 1.5,
          gap: 0.5,
          width: '100%',
          ...inputStyle
        }}
      >
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '150px',
            minHeight: '120px',
            gap: '16px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflowX: 'auto',
            px: 1
          }}
        >
          {images.map(
            (url, index) =>
              url && (
                <div
                  key={url}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setCurrentIndex(index);
                    setOpen(true);
                  }}
                  style={{
                    flexShrink: 0,
                    maxWidth: '150px',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    outline: 'none'
                  }}
                >
                  <img
                    src={`${env.s3BaseUrl}/${url}`}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: '100%',
                      maxWidth: '150px',
                      maxHeight: '120px',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              )
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{
        height: '60px',
        ...inputStyle
      }}
    >
      <Typography sx={{ color: '#111111', fontSize: 14 }}>
        {item?.formatter ? item?.formatter(value) : value[item?.name]}
      </Typography>
    </Stack>
  );
};
