import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import { MotionContainer, varBounceIn } from '@components/animate';
import HelmetContainer from '@components/HelmetContainer';

const RootStyle = styled(HelmetContainer)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

export default function FallbackComponent() {
  return (
    <RootStyle title="500 Page Server Error | Workers high">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography sx={{ fontWeight: 700, fontSize: '1.5rem', mb: 2 }}>
                Oops, Something went wrong
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, an error has occurred on the server.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Please contact the administrator
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/illustrations/illustration_500.svg"
                sx={{ height: 360, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>
            <Button
              href="/"
              size="large"
              variant="contained"
              component="a"
              sx={{
                borderRadius: 100,
                height: '48px',
                fontWeight: 700
              }}
            >
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
