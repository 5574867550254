import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import warehouseService from '@services/warehouse';
import error2Text from '@utils/error2Text';
import MyLoadingButton from '@components/MyLoadingButton';

const ExcelRegisterDialog = ({ open, setOpen, onAction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataFile, setDataFile] = useState({
    name: '',
    file: '',
    src: ''
  });

  const handleRegisterByExcel = async () => {
    const dataSubmit = new FormData();
    dataSubmit.append('file', dataFile.file);
    const rs = await warehouseService.uploadFileStock(dataSubmit);
    if (!rs.error) {
      onAction('upload-success');
      setOpen(false);
    } else {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  const exportSample = async () => {
    const rs = await warehouseService.exportSampleStock();
    if (rs?.error) {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setDataFile({ ...dataFile, name: file.name, file });
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.inventory.stocking-upload')}
      okTitle={t('button.add')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onOk={handleRegisterByExcel}
      onClose={() => setDataFile({ name: '', file: '', src: '' })}
      sx={{ width: 460 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2.5 }}
      >
        <Typography
          sx={{ color: '#6F869C', fontWeight: 'fontWeightBold', fontSize: 13 }}
        >
          {t('pages.product.example-excel-notes')}
        </Typography>
        <MyLoadingButton
          sx={{ textTransform: 'lowercase', height: 36 }}
          onClick={exportSample}
        >
          {t('button.example-file-label')}
        </MyLoadingButton>
      </Stack>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          p: '10px 20px'
        }}
      >
        <Tooltip title={dataFile.name}>
          <Typography
            sx={{
              color: 'primary.darker',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              textDecoration: 'underline',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: '10px',
              marginTop: 1
            }}
          >
            {dataFile.name}
          </Typography>
        </Tooltip>

        <InputFile title={t('button.attachment')} onChange={handleFileUpload} />
      </Box>
    </MyDialog>
  );
};
export default ExcelRegisterDialog;
