import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const c = i18n.getFixedT(null, null, 'pages.curation');
const i = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.perform');
const prod = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

const { formatTime } = Formatter;

export const columnsInvestment = [
  {
    name: 'clientName',
    label: prod('client')
  },
  {
    name: 'clientChannel',
    label: c('channel')
  },
  {
    render: (item) => item && Formatter.fNumber(item),
    name: 'investmentMoney',
    label: p('invest-money')
  },
  {
    name: 'investmentDate',
    label: p('month-invest'),
    render: (item) => item && formatTime(item, 'YYYY-MM-DD')
  },
  {
    name: 'contractTerm',
    label: g('pages.display.term')
  },
  {
    render: (item) => item && Formatter.fNumber(item),
    name: 'goalAmount',
    label: p('goal-amount')
  }
];

export const columnsProfit = [
  {
    name: 'month',
    label: i('date'),
    minWidth: 100,
    align: 'center',
    render: ({ month }) => renderMonth(month)
  },
  {
    name: 'salesQuantity',
    label: c('salesQuantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.salesQuantity)
  },
  {
    name: 'costOfSales',
    label: p('cost-of-sales'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.costOfSales)
  },
  {
    name: 'totalSales',
    label: p('total-sales'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.totalSales)
  },
  {
    name: 'costRate',
    label: p('cost-rate'),
    minWidth: 100,
    align: 'center',
    render: ({ costRate }) =>
      costRate ? `${Number(costRate)?.toFixed(2)}%` : null
  },
  {
    name: 'investmentMoney',
    label: p('invest-money'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.investmentMoney)
  },
  {
    name: 'contractDate',
    label: p('contract-date'),
    minWidth: 100,
    align: 'center',
    render: ({ contractDate }) =>
      contractDate && formatTime(contractDate, 'YYYY-MM-DD')
  },
  {
    name: 'contractTerm',
    label: p('contract-term'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'initialGoal',
    label: p('initial-goal'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.initialGoal)
  },
  {
    name: 'correctionGoal',
    label: p('correction-goal'),
    minWidth: 100,
    align: 'center',
    render: (item) => formatMoney(item?.correctionGoal)
  }
];

const renderMonth = (month) => {
  const months = [
    g('pages.dashboard.january'),
    g('pages.dashboard.february'),
    g('pages.dashboard.march'),
    g('pages.dashboard.april'),
    g('pages.dashboard.may'),
    g('pages.dashboard.june'),
    g('pages.dashboard.july'),
    g('pages.dashboard.august'),
    g('pages.dashboard.september'),
    g('pages.dashboard.october'),
    g('pages.dashboard.november'),
    g('pages.dashboard.december')
  ];
  return months[month - 1];
};
