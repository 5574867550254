import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Calendar as RDRCalendar } from 'react-date-range';
import { ko } from 'react-date-range/dist/locale';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { ReactComponent as CalendarIcon } from '@svg/calendar.svg';
import { dateFormat } from '@constants/utils';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import './index.css';

const Calendar = ({
  date = null,
  setDate,
  sx = {},
  disabled,
  monthYear,
  condition = 'monthCurrent',
  errMsg,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const calendarRef = useRef(null);

  const handleClick = (event) => {
    calendarRef.current.focus();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (date) => {
    setDate(date);
    handleClose();
    if (onChange) onChange();
  };

  return (
    <Box sx={{ ...sx?.root }}>
      <FormControl error={errMsg}>
        <TextField
          disabled={disabled}
          onClick={(e) => !disabled && handleClick(e)}
          size="small"
          sx={{
            width: '100%',
            maxWidth: 300,
            bgcolor: 'common.white',
            '.MuiInputBase-input': {
              fontSize: 14,
              color: 'text.primary'
            },
            ...sx?.inputStyle
          }}
          value={
            date
              ? moment(date).format(monthYear ? 'YYYY - MM' : dateFormat)
              : ''
          }
          placeholder={dateFormat}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={(e) => !disabled && handleClick(e)}
                >
                  <CalendarIcon />
                </IconButton>
              </InputAdornment>
            ),
            readOnly: true,
            inputRef: calendarRef
          }}
          error={errMsg}
        />
        {errMsg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMsg}
          </span>
        )}
      </FormControl>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            bgcolor: 'transparent',
            border: 'none',
            boxShadow: 'none'
          }
        }}
      >
        <Box
          sx={{
            border: '1px solid #D3D3D3',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '6px',
            overflow: 'hidden',
            bgcolor: 'common.white'
          }}
        >
          {monthYear ? (
            <MonthYearPicker
              date={date}
              setDate={setDate}
              handleClose={handleClose}
              condition={condition}
            />
          ) : (
            <RDRCalendar
              dateDisplayFormat="YYYY-MM-DD"
              date={date}
              onChange={handleChange}
              locale={ko}
            />
          )}
        </Box>
      </Menu>
    </Box>
  );
};

const MonthYearPicker = ({ date, setDate, handleClose, condition }) => {
  const { t } = useTranslation();
  const months = [
    { value: 0, label: t('pages.dashboard.january') },
    { value: 1, label: t('pages.dashboard.february') },
    { value: 2, label: t('pages.dashboard.march') },
    { value: 3, label: t('pages.dashboard.april') },
    { value: 4, label: t('pages.dashboard.may') },
    { value: 5, label: t('pages.dashboard.june') },
    { value: 6, label: t('pages.dashboard.july') },
    { value: 7, label: t('pages.dashboard.august') },
    { value: 8, label: t('pages.dashboard.september') },
    { value: 9, label: t('pages.dashboard.october') },
    { value: 10, label: t('pages.dashboard.november') },
    { value: 11, label: t('pages.dashboard.december') }
  ];
  const checkCondition = (date, month) => {
    if (condition === 'monthCurrent') {
      if (new Date().getFullYear() < date?.getFullYear()) return false;
      if (new Date().getFullYear() === date?.getFullYear()) {
        if (new Date().getMonth() >= month) return true;
        return false;
      }
      return true;
    }
    return true;
  };
  const handleLeftClick = () => {
    if (date instanceof Date) {
      setDate(new Date(date.setYear(date.getFullYear() - 1)));
    }
  };

  const handleRightClick = () => {
    if (date instanceof Date) {
      setDate(new Date(date.setYear(date.getFullYear() + 1)));
    }
  };
  return (
    <Box sx={{ padding: 1.3 }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <IconButton onClick={handleLeftClick}>
          <ArrowLeftIcon />
        </IconButton>
        <TextField
          type="number"
          variant="standard"
          size="small"
          sx={{
            width: '50%',
            '& .MuiInputBase-input': {
              textAlign: 'center' // center text
            },
            '& .MuiInputBase-root': {
              border: 'none' // remove border
            }
          }}
          onChange={(e) => {
            if (condition === 'monthCurrent') {
              if (e.target.value <= new Date().getFullYear())
                setDate(new Date(date.setYear(e.target.value)));
            } else {
              setDate(new Date(date.setYear(e.target.value)));
            }
          }}
          value={date && date?.getFullYear()}
        />
        <IconButton disabled={!checkCondition(date)} onClick={handleRightClick}>
          <ArrowRightIcon />
        </IconButton>
      </Stack>
      <Grid2 container spacing={1} sx={{ width: 350, mr: 1.5 }}>
        {months?.map((month, index) => (
          <Grid2 key={`${{ index }}`} xs={4} md={4} lg={4}>
            <ContainerMonth
              month={month}
              date={date}
              disabled={!checkCondition(date, month?.value)}
              onClick={(vl) => {
                if (checkCondition(date, vl?.value)) {
                  handleClose();
                  setDate(new Date(date?.setMonth(vl?.value)));
                }
              }}
            />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

const ContainerMonth = ({ month, date, onClick, disabled }) => {
  return disabled ? (
    <Stack
      sx={{
        userSelect: 'none',
        margin: '0px 10px',
        padding: 1,
        borderRadius: 8,
        textAlign: 'center',
        width: '100%'
      }}
      onClick={() => onClick(month)}
    >
      <Typography sx={{ fontSize: 14, color: 'gray' }}>
        {month?.label}
      </Typography>
    </Stack>
  ) : (
    <Stack
      sx={{
        backgroundColor: date.getMonth() === month?.value && '#3d91ff',
        userSelect: 'none',
        color: date.getMonth() === month?.value && 'white',
        margin: '0px 10px',
        padding: 1,
        borderRadius: 8,
        textAlign: 'center',
        '&:hover': {
          cursor: 'pointer',
          outline: '1px solid #3d91ff'
        },
        width: '100%'
      }}
      onClick={() => onClick(month)}
    >
      <Typography sx={{ fontSize: 14 }}>{month?.label}</Typography>
    </Stack>
  );
};

export default Calendar;
