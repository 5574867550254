/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box, Stack, Typography, Button } from '@mui/material';
import Calendar from '@components/Calendar';
import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';
import moment from 'moment/moment';
import clientOrderServices from '@services/clientOrder';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import Backbutton from '@components/BackButton';
import { Formatter } from '@utils/formatter';
import OrderProductDetailArea from './OrderProductDetailArea';
import OrderSheetArea from './OrderSheetArea';

const DATE_FORMAT = 'YYYY-MM-DD';
const SUNDAY = 0; // moment day index
const SATURDAY = 6; // moment day index
const WEEKENDS = [SATURDAY, SUNDAY];

const ClientOrderDetail = ({ t: pageT }) => {
  const { clientOrderId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const disabled = data?.order?.status !== 'Registration';
  const [dialog, setDialog] = useState({
    open: false,
    action: ''
  });
  const [shippingDate, setShippingDate] = useState(
    addBusinessDays(Formatter.formatTime(new Date(), 'YYYY-MM-DD'), 4)
  );
  const [pickingYn, setPickingYn] = useState(true);
  const getData = async () => {
    const result = await clientOrderServices.getDetail(clientOrderId);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      const sortedProducts = result?.data?.products?.sort((a, b) => {
        if (a.stockQuantity === 0 && b.stockQuantity !== 0) {
          return -1;
        } else if (a.stockQuantity !== 0 && b.stockQuantity === 0) {
          return 1;
        } else {
          return 0;
        }
      });
      setData({ order: result.data?.order, products: sortedProducts });
    }
  };
  const hasStockQuantityZero = data?.products?.some(
    (product) => product?.stockQuantity === 0
  );
  function addBusinessDays(stringDate, numberOfDays, dateFormat = DATE_FORMAT) {
    const date = moment(stringDate, dateFormat);

    let count = 0;

    while (count < numberOfDays) {
      date.add(1, 'day');

      // Skip weekends
      if (!WEEKENDS.includes(date.day())) {
        count += 1;
      }
    }

    // const days= [0,1,1,1,1,1,0]
    // let counts = 4;
    // let cnt = 0;
    // while(counts >=0){
    //   counts -= days[cnt%7];
    //   date.add(1, 'day');
    //   cnt++
    // }

    return date.format(dateFormat);
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    hasStockQuantityZero ? setPickingYn(false) : setPickingYn(true);
  }, [hasStockQuantityZero]);
  const handleDeleteRow = async (item) => {
    const result = await clientOrderServices.deleteOrderProduct(
      clientOrderId,
      item?.productCode
    );
    if (result.error) dispatch(setError(error2Text(result.error)));
    else getData();
  };

  const handlePickingRequest = async () => {
    const result = await clientOrderServices.pickingOrder(clientOrderId, {
      shippingDate
    });
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      dispatch(setSuccess({ message: t('info.register.content') }));
      setDialog({ ...dialog, open: false, action: '' });
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [clientOrderId]);

  return (
    <HelmetContainer
      title={t('pageTitle.client-detail')}
      content="Client Order Detail"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: 2.5
        }}
      >
        <Backbutton
          title={`${t('menusidebar.product-management.client-order')} ${t(
            'pages.picking.detail'
          )}`}
          route="product-management/client-order"
        />
        <Button
          variant="outlined"
          onClick={() => {
            setDialog({ ...dialog, open: true, action: 'picking' });
          }}
          disabled={
            disabled ||
            !data?.products ||
            data?.products?.length <= 0 ||
            !pickingYn
          }
        >
          {pageT('pickingRequesting')}
        </Button>
      </Box>

      <OrderSheetArea
        pageT={pageT}
        data={data?.order}
        fetchData={getData}
        clientOrderId={clientOrderId}
        disabled={disabled}
      />

      <OrderProductDetailArea
        pageT={pageT}
        data={data?.products || []}
        handleDeleteRow={handleDeleteRow}
        fetchData={getData}
        disabled={disabled}
      />
      {dialog?.action === 'picking' && (
        <MyDialog
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          title={pageT('pickingRequesting')}
          hasCloseButton
          hasCancelButton
          onOk={() =>
            dispatch(
              onOpenConfirm({
                open: true,
                type: 'save',
                action: () => handlePickingRequest()
              })
            )
          }
          disabled={disabled}
        >
          <Stack direction="row" gap={2} sx={{ mt: 1 }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                pb: 0.5
              }}
            >
              <Typography
                sx={{
                  fontSize: 13,
                  color: '#6F869C',
                  whiteSpace: 'nowrap'
                }}
              >
                {t('labels.shippingDate')}
                <span style={{ color: 'red', marginLeft: 5 }}>*</span>
              </Typography>
            </Stack>
            <Calendar
              // eslint-disable-next-line no-unneeded-ternary
              date={shippingDate ? new Date(shippingDate) : new Date()}
              sx={{
                inputStyle: {
                  width: '100%'
                }
              }}
              setDate={(e) =>
                setShippingDate(Formatter.formatTime(e, 'YYYY-MM-DD'))
              }
            />
          </Stack>
        </MyDialog>
      )}
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.client-order' })(
  ClientOrderDetail
);
