import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import MySelect from '@components/MySelect';

import welfarePointsServices from '@services/welfarePoints';

import { MAX_WELFARE_POINTS } from '@constants/utils';

import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';

import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import { tableBodyStyle, initTypeList } from '../enhance';

const UpdateWelfarePointsDialog = ({
  open,
  setOpen,
  clientCode,
  setSuccess,
  initData,
  isEdit = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [type, setType] = useState(initTypeList[0].value);
  const [welfarePoint, setWelfarePoint] = useState('0');
  const [loading, setLoading] = useState(false);

  const onChange = ({ target: { value } }) => {
    const temp = value.replaceAll(',', '');
    setWelfarePoint(temp > MAX_WELFARE_POINTS ? MAX_WELFARE_POINTS : temp || 0);
  };

  const handleRegisterWelfarePoints = () => {
    return welfarePointsServices.create({
      isFare: type === initTypeList[0].value ? 'Y' : 'N', // sum ==> Y, init ==> N
      welfarePoint,
      clientCode
    });
  };

  const handleEditWelfarePoints = () => {
    return welfarePointsServices.update(initData.welfareId, {
      isFare: type === initTypeList[0].value ? 'Y' : 'N', // sum ==> Y, init ==> N
      welfarePoint,
      clientCode
    });
  };

  const handleOk = async () => {
    setSuccess(false);
    setLoading(true);

    const service = isEdit
      ? handleEditWelfarePoints
      : handleRegisterWelfarePoints;

    const result = await service();

    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setOpen(false);
      setSuccess(true);
    }
    setLoading(false);
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={
        isEdit
          ? t('pages.welfare-points.wp-edit')
          : t('pages.welfare-points.wp-register')
      }
      okTitle={isEdit ? t('button.edit') : t('button.register')}
      cancelTitle={t('button.cancel')}
      hasCancelButton
      hasCloseButton
      onOk={() => {
        dispatch(
          onOpenConfirm({
            open: true,
            type: isEdit ? 'modify' : 'save',
            action: () => handleOk()
          })
        );
      }}
      sx={{
        '.MuiDialogContent-root': {
          border: '1px solid #D3D3D3',
          borderRadius: '8px'
        }
      }}
      loading={loading}
    >
      <TableContainer>
        <Table>
          <TableBody sx={tableBodyStyle}>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.sum-init')}
              </TableCell>
              <TableCell>
                <MySelect
                  data={initTypeList}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                  disabled={loading}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" role="row">
                {t('pages.welfare-points.wp')}
              </TableCell>
              <TableCell>
                <MyTextField
                  value={Formatter.fNumber(welfarePoint)}
                  onChange={(e) => onChange(e)}
                  typeValue="onlyNumber"
                  disabled={loading}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default UpdateWelfarePointsDialog;
