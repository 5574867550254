/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMergeState from '@hooks/useMergeState';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import MyDialog from '@components/MyDialog';
import InputFile from '@components/InputFile';
import MyTextField from '@components/MyTextField';
import { MAX_INT } from '@constants/utils';

import { onOpenConfirm } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import equipmentServices from '@services/equipment';
import { Formatter } from '@utils/formatter';
import { rows, tableStyle } from '../enhance';

const numberFields = ['quantity', 'price'];

const maxLengthOf = {
  equipmentName: 200,
  supplier: 40,
  manager: 40,
  managerContact: 40
};

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

// [IMPROVE] this component rerender many times caused by [useMergeState], [form > table] cant not get values when submited ??
const UpdateEqmDialog = ({ open, setOpen, data, onAction }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [state, setState] = useMergeState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useMergeState({
    equipmentName: '',
    supplier: '',
    manager: '',
    managerContact: ''
  });

  const handleChange = (name, event) => {
    if (name === 'file') setState({ ...state, [name]: event.target.files[0] });
    else {
      const { value } = event.target;
      const checkValue = numberFields.includes(name)
        ? value.replaceAll(',', '')
        : value;
      setState({ ...state, [name]: checkValue });
    }

    const value = event?.target?.value;
    let msg = '';
    if (name === 'equipmentName' && !value) {
      msg = t('common.required');
    } else if (
      numberFields.includes(name) &&
      value?.replaceAll(',', '') > MAX_INT
    ) {
      msg = t('validation.maxNumberIs', { max: Formatter.fNumber(MAX_INT) });
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ [name]: msg });
  };

  const validationOnSubmit = () => {
    let isPassed = true;
    const tempErr = { ...errMsg };
    if (!state?.equipmentName) {
      tempErr.equipmentName = t('common.required');
      isPassed = false;
    }
    Object.values(tempErr).map((err) => {
      if (err) {
        isPassed = false;
      }
      return 0;
    });
    setErrMsg(tempErr);
    return isPassed;
  };

  const handleUpdate = async () => {
    setLoading(true);
    const { error } = await equipmentServices.updateEquipment(
      state.equipmentCode,
      {
        equipmentName: state.equipmentName,
        vendorName: state.vendorName,
        additionalInfo: state?.additionalInfo,
        contactName: state.contactName,
        contactPhone: state.contactPhone
      }
    );
    if (error) {
      console.log(error);
    } else {
      onAction('success');
      setOpen(false);
    }

    setLoading(false);
  };

  const resetState = () => {
    setState(
      rows.reduce((prev, { name }) => {
        return {
          ...prev,
          [name]: null
        };
      }, {})
    );
    setErrMsg({
      equipmentName: '',
      supplier: '',
      manager: '',
      managerContact: ''
    });
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  useEffect(() => {
    setState({
      equipmentCode: data?.equipmentCode,
      equipmentName: data?.equipmentName,
      additionalInfo: data?.additionalInfo,
      vendorName: data?.vendorName,
      contactName: data?.contactName,
      contactPhone: data?.contactPhone
    });
  }, [data]);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.equipment.updateEqm')}
      hasCloseButton
      hasCancelButton
      okTitle={t('button.save')}
      onOk={() => {
        if (validationOnSubmit()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'modify',
              action: () => handleUpdate()
            })
          );
        }
      }}
      loading={loading}
      setLoading={setLoading}
      sx={{ maxWidth: 460 }}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            {rows.map(({ label, name, props }) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row">
                  {label}
                  {['equipmentName', 'quantity', 'unitPrice'].includes(
                    name
                  ) && <span style={{ color: 'red', marginLeft: 3 }}>*</span>}
                </TableCell>
                <TableCell>
                  {name === 'file' ? (
                    <InputFile
                      title={label}
                      file={state?.[name]}
                      urlData={{ url: state?.linkFile, name: state?.fileName }}
                      onChange={(event) => handleChange(name, event)}
                      disabled={loading}
                    />
                  ) : ['equipmentCode', 'supplyPrice', 'sum'].includes(name) ? (
                    <MyTextField
                      value={state?.[name] || ''}
                      onChange={(event) => handleChange(name, event)}
                      disabled
                      {...props}
                    />
                  ) : name === 'quantity' || name === 'price' ? (
                    <MyTextField
                      value={Formatter.fNumber(state?.[name])}
                      onChange={(event) => handleChange(name, event)}
                      typeValue="onlyNumber"
                      errMg={errMsg[name]}
                      disabled={loading}
                      {...props}
                    />
                  ) : (
                    <MyTextField
                      value={state?.[name]}
                      onChange={(event) => handleChange(name, event)}
                      errMg={errMsg[name]}
                      disabled={loading}
                      {...props}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default UpdateEqmDialog;
