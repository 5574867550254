import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

import RestService from './RestService';

const welfarePointsServices = {
  // name=&phone=01044198339&idCardNo=idCardNo&minibarCode=minimini63fdd88c0003e
  async getList(params) {
    try {
      const res = await new RestService()
        .setPath('welfare')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async create(data) {
    try {
      const res = await new RestService().setPath('welfare').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async update(id, data) {
    try {
      const res = await new RestService().setPath(`welfare/${id}`).put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async delete(id) {
    try {
      const res = await new RestService().setPath(`welfare/${id}`).delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   *  { "minibarCode": "minimini63fdd88c0003e", "name": "Member 100", "email": "email1@gmail.com", "phone": "111111111", "age": "20200101" }
   */
  async createMember(data) {
    try {
      const res = await new RestService().setPath('member').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  /**
   * data: { file }
   */
  async importMember(data) {
    try {
      const res = await new RestService()
        .setPath('member/import')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // .xlsx
  async getMemberSampleFile() {
    try {
      const res = await new RestService()
        .setPath('member/export-sample')
        .setResponseType('blob')
        .post();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMember(id, data) {
    try {
      const res = await new RestService().setPath(`member/${id}`).put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default welfarePointsServices;
