import React from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubmitArea = ({
  onChangeStatus,
  onEdit,
  onDeleteClick,
  statusList,
  onNavigateBack
}) => {
  const { t } = useTranslation();

  const handleChangeStatus = () => {
    onChangeStatus({
      title: t('pages.development-request.change-status'),
      cancelTitle: t('button.close'),
      okTitle: t('button.register'),
      status: statusList[2].value // Reviewed
    });
  };
  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      <Button variant="contained" onClick={handleChangeStatus}>
        {t('pages.development-request.review')}
      </Button>
      <Button variant="outlined" onClick={onEdit}>
        {t('pages.development-request.modify')}
      </Button>
      <Button variant="outlined" onClick={onDeleteClick}>
        {t('pages.development-request.delete')}
      </Button>
      <Button variant="outlined" onClick={onNavigateBack}>
        {t('pages.development-request.list')}
      </Button>
    </Stack>
  );
};

export default SubmitArea;
