/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Checkbox, IconButton, Stack, Typography } from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
// import { Formatter } from '@utils/formatter';
import homepageService from '@services/homepage';

import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { Delete } from '@mui/icons-material';
import MyDialog from '@components/MyDialog/AlertDialog';
import MediaActions from './MeadiActions';
import { columns } from './enhance';

export default function DevelopmentRequest() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mediaData, setMediaData] = useState();
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    totalRow: 0
  });
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialog, setDialog] = useState({
    openConfirm: false,
    update: false,
    register: false,
    action: 'register'
  });

  // eslint-disable-next-line unused-imports/no-unused-vars

  const getData = async (data) => {
    const payload = {
      page,
      pageSize: 20
    };
    const result = await homepageService.getMediaList(data || payload);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setMediaData({
        ...mediaData,
        items: result.data?.data?.news?.items,
        totalDisplayedMedia: result.data?.data?.totalDisplayedMedia || 0
      });

      setPagination({
        ...pagination,
        page: result.data?.data.news.page || 0,
        totalRow: result.data?.data.news.totalRow || 0,
        count: result.data?.data.news.totalPage || 0
      });
    }
  };

  const handleRowClick = (row) => {
    setDialog({ ...dialog, update: true, action: 'update' });
    setSelectedItem(row);
  };
  const handleChangeCheckbox = async () => {
    // change from the list
    const rs = await homepageService.updateMediaDisplayStatus(
      selectedItem?.newsCode,
      {
        isDisplayed: selectedItem?.isDisplayed === 'Y' ? 'N' : 'Y'
      }
    );

    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      getData();
    } else {
      setMediaData((prev) => {
        const updatedList = prev?.items.map((news) => {
          if (news.newsCode === selectedItem?.newsCode) {
            return {
              ...news,
              isDisplayed: selectedItem?.isDisplayed === 'Y' ? 'N' : 'Y'
            };
          }
          return news;
        });
        const updatedTotalDisplayedMedia =
          selectedItem?.isDisplayed === 'Y'
            ? prev.totalDisplayedMedia - 1
            : prev.totalDisplayedMedia + 1;
        return {
          ...mediaData,
          items: updatedList,
          totalDisplayedMedia: updatedTotalDisplayedMedia
        };
      });
      dispatch(setSuccess({ message: t('info.success.content') }));
      setSelectedItem(null);
    }
    setDialog({
      ...dialog,
      openConfirm: false,
      type: null,
      okTitle: null,
      title: null,
      subtitle: null,
      buttonVariant: null
    });
  };
  const deleteNews = async () => {
    // change from the list
    const rs = await homepageService.deleteNews(selectedItem?.newsCode);
    if (rs?.error) {
      getData();
    } else {
      setSelectedItem(null);
      setDialog({
        ...dialog,
        openConfirm: false,
        type: null,
        okTitle: null,
        title: null,
        subtitle: null,
        buttonVariant: null
      });
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getData();
    }
  };
  useEffect(() => {
    if (!open) {
      const query = paramsToObj(location?.search);
      const { page } = query;

      setPage(page - 1 || 0);
      getData();
    }
  }, [location?.search, dialog.register, dialog.update]);

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Checkbox
            checked={item?.isDisplayed === 'Y'}
            onChange={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              const isDisplayed = item?.isDisplayed === 'Y';
              const dialogContent = {
                okTitle: isDisplayed
                  ? t('pages.homepage.exclude')
                  : t('button.check'),
                title: isDisplayed
                  ? t('pages.homepage.exclude-news-homepage')
                  : t('pages.homepage.include-news-homepage'),
                subtitle: isDisplayed
                  ? t('pages.homepage.exclude-news-homepage-subtitle')
                  : t('pages.homepage.include-news-homepage-subtitle'),
                type: 'edit',
                buttonVariant: !isDisplayed && 'edit'
              };
              setDialog({
                ...dialog,
                openConfirm: true,
                ...dialogContent
              });
            }}
          />
        );

      default:
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({ ...dialog, openConfirm: true });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };
  return (
    <HelmetContainer title={t('pageTitle.media')} content="Homepage media">
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button
          variant="outlined"
          onClick={() =>
            setDialog({ ...dialog, register: true, action: 'register' })
          }
        >
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        onRowClick={handleRowClick}
        getActionButtons={getActionButtons}
        columns={columns(mediaData)}
        data={mediaData?.items || []}
        pagination={pagination}
        onChangePage={(e, page) => {
          const dataParams = {
            page,
            pageSize: 20
          };
          const query = objToParams(ignoreEmpty({ page: page + 1 }));
          getData(dataParams);
          navigate(query);
        }}
        minusHeight={235}
        rerender
      />

      <MediaActions
        open={dialog?.[dialog.action]}
        setOpen={() => setDialog({ ...dialog, [dialog.action]: false })}
        key={open}
        action={dialog.action}
        item={dialog.action === 'update' && selectedItem}
      />

      {selectedItem && (
        <MyDialog
          open={dialog.openConfirm}
          setOpen={() =>
            setDialog({
              ...dialog,
              openConfirm: false,
              type: null,
              okTitle: null,
              title: null,
              subTitle: null,
              buttonVariant: null
            })
          }
          onOk={dialog?.type === 'edit' ? handleChangeCheckbox : deleteNews}
          okTitle={dialog?.okTitle || t('button.delete')}
          isAlert
          title={dialog?.title || t('common.confirm-delete-new-title')}
          subTitle={dialog?.subtitle || t('common.confirm-delete-new-subtitle')}
          type={dialog?.buttonVariant}
          hasCancelButton
        />
      )}
      {/* {selectedItem && (
        <MyDialog
          open={dialog.openConfirm}
          setOpen={() => setDialog({ ...dialog, openConfirm: false })}
          onOk={deleteNews}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.newsTitle
          })}
          hasCancelButton
        />
      )} */}
    </HelmetContainer>
  );
}
