import React, { forwardRef } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  TextField
} from '@mui/material';
import useResponsive from '@hooks/useResponsive';
import { ReactComponent as ArrowDownBoldIcon } from '@svg/arrow-down-bold.svg';
import { useTranslation } from 'react-i18next';

const MyAutocomplete = forwardRef(
  (
    {
      label,
      textFieldProps,
      required,
      freeSolo = false,
      filterOptions,
      sx,
      errMg,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    const isSm = useResponsive('down', 900);
    const autocompleteWidth = isSm ? 250 : 300;

    if (!label) {
      return (
        <Autocomplete
          size="small"
          freeSolo={freeSolo}
          sx={{
            '.MuiInputBase-root': { bgcolor: 'common.white' },
            width: autocompleteWidth,
            ...sx
          }}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errMg}
              inputRef={ref}
              {...textFieldProps}
              helperText={errMg && `* ${errMg}`}
            />
          )}
          slotProps={{
            popupIndicator: { sx: { width: '24px', height: '24px' } }
          }}
          popupIcon={<ArrowDownBoldIcon />}
          ref={ref}
          {...rest}
        />
      );
    }

    return (
      <FormControl sx={{ flexDirection: 'row', ...sx?.control }}>
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            },

            ...sx?.label
          }}
          control={
            <Autocomplete
              disablePortal
              filterOptions={filterOptions}
              size="small"
              freeSolo={freeSolo}
              sx={{
                ml: 1,
                '.MuiInputBase-root': { bgcolor: 'common.white' },

                width: autocompleteWidth,
                ...sx
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errMg}
                  {...textFieldProps}
                  helperText={errMg && `* ${errMg}`}
                />
              )}
              slotProps={{
                popupIndicator: { sx: { width: '24px', height: '24px' } }
              }}
              popupIcon={<ArrowDownBoldIcon />}
              noOptionsText={t('common.noOptions')}
              ref={ref}
              {...rest}
            />
          }
        />
      </FormControl>
    );
  }
);

export default MyAutocomplete;
