/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import moment from 'moment';
import { sub } from 'date-fns';

import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';

import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import DateRangePicker from '@components/DateRangePicker';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import MyTable from '@components/MyTable';
import { Formatter } from '@utils/formatter';
import developmentServices from '@services/development';

import { setError } from '@store/reducers/appReducer';
import { dateFormat } from '@constants/utils';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import RegisterDevelopmentRequestDialog from './RegisterDevelopmentRequestDialog';
import { columns, initStatusList } from './enhance';

export default function DevelopmentRequest() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
    key: 'selection'
  });
  const [status, setStatus] = useState(initStatusList[0].value);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [statusList, setStatusList] = useState(initStatusList);
  const [keyword, setKeyword] = useState('');

  const getData = async (data) => {
    const payload = {
      reqUsername: keyword,
      status: status === initStatusList[0].value ? null : status,
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      page
    };
    const result = await developmentServices.getList(data || payload);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else setData(result.data);
  };

  const handleSearch = () => {
    const dataParams = {
      reqUsername: keyword,
      status: status === initStatusList[0].value ? null : status,
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      page: 0
    };
    const query = objToParams(ignoreEmpty({ ...dataParams, page: 1 }));
    getData(dataParams);
    navigate(query);
  };

  const handleRowClick = (row) => {
    navigate(row.fileCode);
  };

  useEffect(() => {
    if (!open) {
      const query = paramsToObj(location?.search);
      const { reqUsername, startDate, endDate, status, page } = query;
      setKeyword(reqUsername || '');
      // eslint-disable-next-line prefer-template
      const firstDayOfMonth =
        Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
      setDateRange({
        ...dateRange,
        startDate: new Date(
          startDate ? moment(startDate).format(dateFormat) : firstDayOfMonth
        ),
        endDate: new Date(
          endDate ? moment(endDate).format(dateFormat) : new Date()
        )
      });
      setStatus(status || 'All');
      setPage(page - 1 || 0);
      getData({
        reqUsername,
        page: page - 1 || 0,
        status,
        startDate: moment(startDate || firstDayOfMonth).format(dateFormat),
        endDate: moment(endDate || new Date()).format(dateFormat)
      });
    }
  }, [location?.search, open]);

  return (
    <HelmetContainer title={t('pageTitle.devRq')} content="Development Request">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('pages.development-request.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker state={dateRange} setState={setDateRange} />
            }
          />
        </FormControl>

        <MySelect
          label={t('pages.development-request.status2')}
          value={status}
          data={statusList}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ mr: 1.5 }}
        />

        <Stack flex={1} sx={{ minWidth: 300 }}>
          <MyTextField
            placeholder={t('pages.development-request.input-keyword')}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            inputProps={{
              onKeyDown: (e) => {
                if (e.isComposing || e.keyCode === 13) {
                  handleSearch();
                }
              }
            }}
          />
        </Stack>

        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {data?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        onRowClick={handleRowClick}
        columns={columns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page
        }}
        onChangePage={(e, page) => {
          const dataParams = {
            reqUsername: keyword,
            status: status === initStatusList[0].value ? null : status,
            startDate: moment(dateRange.startDate).format(dateFormat),
            endDate: moment(dateRange.endDate).format(dateFormat),
            page
          };
          const query = objToParams(
            ignoreEmpty({ ...dataParams, page: page + 1 })
          );
          getData(dataParams);
          navigate(query);
        }}
        minusHeight={235}
        rerender
      />
      <RegisterDevelopmentRequestDialog
        open={open}
        setOpen={setOpen}
        key={open}
      />
    </HelmetContainer>
  );
}
