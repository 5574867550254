/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clientServices from '@services/client';
import { MenuItem, TextField } from '@mui/material';
import MyAutocomplete from '../MyAutocomplete';

const ClientAutoCompleteV2 = ({
  setClient,
  client,
  disabled,
  channel,
  sx,
  errMg,
  label = true,
  ...rest
}) => {
  const { t } = useTranslation();

  const [itemList, setItemList] = useState([]);
  const [itemInput, setItemInput] = useState('');
  const [client2, setClient2] = useState(null);

  // Fetch items from the server based on the selected channel
  const getItems = async () => {
    const additionalPayload = {
      clientChannel: channel?.value || channel || null
    };
    const { data } = await clientServices.getListClientAll({
      ...additionalPayload
    });
    // Ensure data is available before updating the itemList
    if (data) {
      setItemList(data);
    }
  };

  // Handle changes in the autocomplete input
  const onChange = async (e, val) => {
    try {
      if (!val) {
        setClient('');
        setClient2('');
      }
      if (val && val.clientCode) {
        setClient(val);
        setClient2(val || null);
      }
    } catch (error) {
      console.error('Error handling autocomplete change:', error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    setItemList([]);
    getItems();
  }, []);
  // Fetch items whenever the channel changes
  useEffect(() => {
    if (channel) {
      setClient2(null);
      setItemList([]);
      getItems();
    }
  }, [channel]);

  return (
    <MyAutocomplete
      label={label && t('common.client')}
      isOptionEqualToValue={(option, value) =>
        option.clientCode === value?.clientCode
      }
      getOptionLabel={(option) => option.clientName}
      value={
        client2 || (client?.clientName && client?.clientCode ? client : null)
      }
      onChange={onChange}
      options={itemList}
      disabled={disabled}
      onClose={() => {
        if (!client) {
          setItemInput('');
        }
      }}
      sx={{
        '.MuiInputBase-root': {
          bgcolor: !disabled ? 'common.white' : '#f2f2f2'
        },
        ...sx
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('common.master')}
          error={errMg}
          helperText={errMg && `* ${errMg}`}
        />
      )}
      renderOption={(option, { ref }) => {
        const { key, id, ...restOption } = option;
        return (
          <MenuItem
            {...restOption}
            key={id}
            sx={{ overflowX: 'hidden' }}
            ref={ref}
          >
            {key}
          </MenuItem>
        );
      }}
      {...rest}
    />
  );
};

export default ClientAutoCompleteV2;
