import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

import RestService from './RestService';

const userServices = {
  async getUsers() {
    try {
      const res = await new RestService().setPath('user').get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getFile(data) {
    try {
      const res = await new RestService()
        .setPath('file/download')
        .setResponseType('blob')
        .post(data);

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async testExportFile() {
    try {
      const res = await new RestService()
        .setPath('file/test-export')
        .setResponseType('blob')
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default userServices;
