import MyAutocomplete from '@components/MyAutocomplete';
import apkServices from '@services/apk';
import React, { useEffect, useState } from 'react';

export default function AppVersionComplete({
  sx,

  app,
  setApp,
  textFieldProps
}) {
  const [options, setOptions] = useState([]);
  const [appItem, setAppItem] = useState(null);

  const getItems = async () => {
    const result = await apkServices.getListApk({
      page: 0,
      size: 100
    });
    if (!result.error) {
      if (app.appNickname) {
        const currentApp = result.data?.items.find((item) =>
          item?.appNickname
            .toLowerCase()
            .includes(app.appNickname.toLowerCase())
        );
        if (currentApp) {
          setApp(currentApp);
          setAppItem(currentApp);
        }
      }
      setOptions(result.data?.items || []);
    } else {
      console.log('error fetching the apps');
    }
  };

  useEffect(() => {
    setOptions([]);
    getItems();
  }, []);

  const handleChange = (selectedValue) => {
    if (selectedValue?.appCode) {
      setAppItem(selectedValue);
      setApp(selectedValue);
    }
  };
  return (
    <MyAutocomplete
      sx={sx}
      isOptionEqualToValue={(option, value) =>
        option.appCode === value?.appCode
      }
      textFieldProps={textFieldProps}
      getOptionLabel={(option) =>
        option?.appNickname ? option?.appNickname : 'Unknown'
      }
      onChange={(e, val) => handleChange(val)}
      value={app || (appItem?.appCode && appItem?.appNickname ? appItem : null)}
      options={options}
    />
  );
}
