/* eslint-disable prefer-template */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
// import SearchBar from '@components/SearchBar';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import useResponsive from '@hooks/useResponsive';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formatter } from '@utils/formatter';
import minibarOrderService from '@services/minibarOrderService';
import MyTextField from '@components/MyTextField';
import DateRangePicker from '@components/DateRangePicker';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { sub } from 'date-fns';
import { dateFormat } from '@constants/utils';
import { Close, Search } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import MyDialog from '@components/MyDialog/AlertDialog';
import MinibarTable from '../components/Table/MinibarTable';

import { actionsHistoryColumns } from '../enhance';

const MinibarActionsHistory = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const isMd = useResponsive('down', 1000);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [param, setParam] = useState({
    minibarCode: '',
    productName: null,
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    page: 0
  });
  const [searchVisibility, setSearchvisibility] = useState('none');
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [minibar, setMinibar] = useState({
    minibarCode: '',
    minibarNickname: ''
  });

  const [dialog, setDialog] = useState({ open: false });
  const [selectedItem, setSelectedItem] = useState(null);
  const { items, pagination } = state;

  const getMinibarOrderList = async (params) => {
    const rs = await minibarOrderService.getMinibarActionsHistory(
      ignoreEmpty(params)
    );
    if (!rs?.error) {
      setState({
        ...state,
        items: rs?.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const handleRollbackAction = async () => {
    if (!selectedItem) return;
    const rs = await minibarOrderService.rollbackMinibarAction(
      selectedItem?.minibarStockHistoryCode
    );
    if (rs?.error) {
      dispatch(error2Text(rs?.error));
    } else {
      getMinibarOrderList({ ...param });
      setDialog({ open: false });
      setSelectedItem(null);
    }
  };

  const handleSearch = () => {
    const dataParams = {
      page: 1,
      minibarCode: minibar?.minibarCode,
      startDate: param?.startDate,
      endDate: param?.endDate,
      productName: param?.productName
    };
    const query = objToParams(ignoreEmpty(dataParams));

    getMinibarOrderList({ ...dataParams, page: 0 });
    navigate(query);
  };
  useEffect(() => {
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const query = paramsToObj(location?.search);
    const { minibarCode, productName, startDate, endDate, page } = query;
    setParam({
      ...param,
      page: page || 0,
      minibarCode,
      productName,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat)
    });
    getMinibarOrderList({
      page: page - 1 || 0,
      minibarCode,
      productName,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat)
    });
  }, [location?.search]);

  const onAction = (action, val) => {
    if (action === 'changePage') {
      setParam({ ...param, page: val });

      const query = objToParams(ignoreEmpty({ ...param, page: val + 1 }));
      getMinibarOrderList({ ...param, page: val });
      navigate(query);
    }
    if (action === 'selectPeriod' && val?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(val.startDate, dateFormat),
        endDate: formatTime(val.endDate, dateFormat)
      });
    }
    if (action === 'upload-success') getMinibarOrderList(param);
  };

  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      default:
        return (
          <Chip
            size="medium"
            sx={{
              minWidth: '80px',
              height: '40',
              maxWidth: 'fit-content',
              '&.MuiChip-colorDefault': {
                backgroundColor: '#929292',
                '& .MuiChip-label': {
                  color: '#FFFFFF !important'
                }
              }
            }}
            disabled={item?.hasRollbacked}
            label={t('button.delete')}
            color={item?.hasRollbacked ? 'default' : 'primary'}
            onClick={(e) => {
              e?.stopPropagation();
              setDialog({ ...dialog, open: true });
              setSelectedItem(item);
            }}
          />
        );
    }
  };

  return (
    <HelmetContainer
      title={t('pageTitle.minibarOrder')}
      content="Minibar Order page"
    >
      {/* </div> */}
      {/* PC search bar */}
      <Stack
        sx={{
          p: '10px',
          borderRadius: '12px',
          flexDirection: { md: 'row', xs: 'column' },
          bgcolor: '#F8F8F8',
          flexWrap: 'wrap',
          display: { md: 'flex', xs: 'none' },
          fontSize: 12
        }}
        gap={1.5}

        // alignItems={isSm ? 'center' : ''}
      >
        <FormControl>
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />
        </FormControl>
        <Stack>
          <MinibarAutoCompleteV2
            minibar={minibar}
            setMinibar={(item) => {
              setMinibar(item);
              setParam({ ...param, minibarCode: item?.minibarCode });
            }}
            onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
        </Stack>

        <Stack direction="row" gap={2} sx={{ ml: 1 }}>
          <Stack>
            <MyTextField
              label={t('common.productName')}
              placeholder={t('pages.product.input-keyword')}
              value={param?.productName || ''}
              onChange={(e) =>
                setParam({
                  ...param,
                  productName: e?.target?.value
                })
              }
              onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              sx={{ maxWidth: 'auto' }}
            />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{ ml: 1.5, height: 40 }}
          >
            {t('button.search')}
          </Button>
        </Stack>
      </Stack>
      {/* Mobile searchbar */}
      <Stack
        sx={{
          p: '10px',
          borderRadius: '12px',
          display: { md: 'none', xs: 'flex' },
          flexDirection: 'row',
          bgcolor: '#F8F8F8',

          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        gap={1.5}

        // alignItems={isSm ? 'center' : ''}
      >
        <Stack sx={{ position: 'relative' }}>
          <IconButton
            sx={{
              backgroundColor: 'transparent !important',
              borderRadius: 1,
              border: '1px solid #D3D3D3',
              gap: 0.3,
              fontSize: '14px',
              fontWeight: 600
            }}
            onClick={() =>
              setSearchvisibility((prev) => (prev === 'none' ? 'flex' : 'none'))
            }
          >
            <Search sx={{ width: '18px', height: '18px' }} />{' '}
            {t('button.search')}
          </IconButton>
          <Stack
            sx={{
              display: searchVisibility,
              position: 'absolute',
              left: 0,
              top: '40px',
              zIndex: 10,
              minWidth: '300px',
              borderRadius: 1,
              p: 1.25,

              flexDirection: 'column',
              gap: 1,

              backgroundColor: '#FFFFFF',
              border: '1px solid #EEEEEE',
              boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Stack sx={{ alignItems: 'flex-end' }}>
              <IconButton
                sx={{
                  backgroundColor: 'transparent !important',
                  borderRadius: 1,

                  fontSize: '14px',
                  fontWeight: 600
                }}
                onClick={() => setSearchvisibility('none')}
              >
                <Close sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Stack>
            <FormControl>
              <FormControlLabel
                label={t('labels.period')}
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: { md: 13, xs: 10 },
                    minWidth: { xs: 27, md: 'auto' },
                    whiteSpace: 'nowrap',
                    mr: 1
                  },
                  mx: 0
                }}
                control={
                  <DateRangePicker
                    state={{
                      startDate: new Date(param.startDate),
                      endDate: new Date(param.endDate),
                      key: 'selection'
                    }}
                    dateRangeProps={{
                      direction: 'vertical'
                    }}
                    size="small"
                    setState={(e) => onAction('selectPeriod', e)}
                  />
                }
              />
            </FormControl>
            <Stack sx={{ width: '100%' }}>
              <MinibarAutoCompleteV2
                minibar={minibar}
                setMinibar={(item) => {
                  setMinibar(item);
                  setParam({ ...param, minibarCode: item?.minibarCode });
                }}
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  control: { width: '100%' },
                  label: {
                    width: '100%',
                    mx: 0,
                    '.MuiTypography-root': {
                      color: '#6F869C',
                      fontWeight: 'fontWeightBold',
                      fontSize: { md: 13, xs: 10 },
                      minWidth: { xs: 27, md: 'auto' },
                      whiteSpace: 'nowrap'
                    }
                  }
                }}
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
              />
            </Stack>

            <Stack direction="row" gap={1} sx={{ width: '100%' }}>
              <MyTextField
                label={t('common.productName')}
                placeholder={t('pages.product.input-keyword')}
                value={param?.productName || ''}
                onChange={(e) =>
                  setParam({
                    ...param,
                    productName: e?.target?.value
                  })
                }
                onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
                sx={{
                  maxWidth: 'auto',
                  width: '100%',
                  display: 'flex',
                  label: {
                    mx: 0,
                    width: '100%'
                  },
                  textfield: { width: '100%', flexGrow: 1 }
                }}
                fullWidth
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                onClick={() => {
                  handleSearch();
                  setSearchvisibility('none');
                }}
                sx={{ ml: 1.5, height: 40, borderRadius: 1, minWidth: 80 }}
              >
                {t('button.search')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',

              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MinibarTable
        hover
        getActionButtons={getActionButtons}
        // onRowClick={handleRowClick}
        columns={actionsHistoryColumns}
        data={items}
        rerender
        pagination={pagination}
        minusHeight={isMd ? 30 : 150}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
      {selectedItem && (
        <MyDialog
          isAlert
          open={dialog.open}
          setOpen={() => {
            setDialog({ ...dialog, open: false });
            setSelectedItem(null);
          }}
          hasCancelButton
          sx={{ minWidth: { xs: '90vw', sm: '444px', maxWidth: '90vw' } }}
          okTitle={t('button.delete')}
          onOk={handleRollbackAction}
          title="선택하신 항목을 롤백하시겠습니까?"
          subTitle={
            <Stack sx={{ gap: 1 }}>
              <Typography
                sx={{
                  fontSize: { xs: 14, md: 16 },
                  fontWeight: 400
                }}
              >
                현재 매대재고 수량 :{' '}
                <span style={{ color: 'blue' }}>
                  {selectedItem?.currentStockQuantity}
                </span>
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 400 }}
              >
                롤백 후 수량 :{' '}
                <span style={{ color: 'blue' }}>
                  {Number(selectedItem?.currentStockQuantity) +
                    Number(selectedItem?.quantity) *
                      (selectedItem?.updateType === 'display' ? -1 : 1)}
                </span>
              </Typography>
            </Stack>
          }
        />
      )}
    </HelmetContainer>
  );
};

export default MinibarActionsHistory;
