import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Stack, Typography } from '@mui/material';

import { b2ChartEnhance } from './enhance';

const { options, getB2CData, getB2B2CData, getB2BData } = b2ChartEnhance;

const B2Chart = ({ data }) => {
  const b2ChartData = [
    getB2CData(data?.B2C?.map((item) => item?.totalSales)),
    getB2B2CData(data?.B2B2C?.map((item) => item?.totalSales)),
    getB2BData(data?.B2B?.map((item) => item?.totalSales))
  ];
  return (
    <Stack spacing={2} justifyContent="space-between" flexGrow={1}>
      {b2ChartData?.map(({ title, color, data }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1} key={title}>
            <Typography
              sx={{
                fontWeight: 'fontWeightBold',
                lineHeight: '18px',
                fontSize: 13,
                color,
                minWidth: '48px'
              }}
            >
              {title}
            </Typography>
            <div style={{ width: 'calc(100% - 48px)', height: '120px' }}>
              <Bar options={options} data={data} />
            </div>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default B2Chart;
