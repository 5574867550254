import { Component } from 'react';
import FallbackComponent from './FallbackComponent';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.resetError = this.resetError.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    console.log('log error');
    // loggerService
    //   .logErrorToServer(error + errorInfo?.componentStack)
    //   .then((data) => {})
    //   .catch((err) => {
    //     this.setState({
    //       error: 'Something went wrong with "logErrorToServer" function',
    //       errorInfo: err.toString()
    //     });
    //   });
  }

  resetError() {
    this.setState({ error: null, errorInfo: null });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <FallbackComponent
          error={error?.toString()}
          errorInfo={errorInfo?.componentStack || errorInfo}
          resetError={this.resetError}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
