import i18n from '@utils/i18n';

const common = i18n.getFixedT(null, null, 'common');
const t = i18n.getFixedT(null, null, 'pages.development-request');
const devStatus = i18n.getFixedT(null, null, 'enum.dev-request-status');

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'reqSubject',
    label: t('title'),
    minWidth: 310,
    align: 'center'
  },
  {
    name: 'reqUsername',
    label: t('requester'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'regDate',
    label: t('register-date'),
    minWidth: 150,
    align: 'center',
    render: ({ regDate }) =>
      new Date(regDate).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    name: 'manager',
    label: t('manager'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'dateInCharge',
    label: t('date-in-charge'),
    minWidth: 120,
    align: 'center',
    render: ({ dateInCharge }) =>
      dateInCharge
        ? new Date(dateInCharge).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'status',
    label: t('status'),
    minWidth: 150,
    align: 'center',
    render: (item) => devStatus(`${item?.status}`)
  }
];

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const initStatusList = [
  { label: common('all'), value: 'All' },
  { label: devStatus('Submit'), value: 'Submit' },
  { label: devStatus('Reviewed'), value: 'Reviewed' },
  { label: devStatus('InProgress'), value: 'InProgress' },
  { label: devStatus('Completed'), value: 'Completed' },
  { label: devStatus('Canceled'), value: 'Canceled' }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
