import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';

import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';

import MyTextField from '@components/MyTextField';
import CustomImage from '@components/CustomImage';
import NoData from '@components/NoData';

import { TrashIcon } from '@svg/trash';
import { formatMoney } from '@utils/helpers';

import { orderProductDetailColumns as columns } from '../../enhance';

const formatCurrency = (value) => new Intl.NumberFormat('en-CA').format(value);

const OrderProductTableRow = ({
  item,
  handleDeleteRow,
  onRowChange,
  disabled
}) => {
  const handleChange = (e) => {
    const orderQuantity = e.target?.value
      ? parseInt(e.target.value?.replace(/,/g, ''), 10)
      : 0;
    const totalPrice = item?.pricePolicyType
      ? orderQuantity * item.pricePolicyType
      : 0;
    const data = { orderQuantity, totalPrice };
    onRowChange(item?.productCode, {
      ...item,
      ...data
    });
  };
  return (
    <TableRow>
      <TableCell>{item?.productCode}</TableCell>
      <TableCell>
        <CustomImage fileLink={item?.imgUrl} fileName={item?.imgUrl} />
      </TableCell>
      <TableCell>{item?.productName}</TableCell>
      <TableCell>{item?.box}</TableCell>
      <TableCell>
        <MyTextField
          value={formatMoney(item?.orderQuantity || 0)}
          onChange={handleChange}
          disabled={disabled}
          typeValue="onlyNumber"
        />
      </TableCell>
      <TableCell>{item?.cost ? formatCurrency(item.cost) : null}</TableCell>
      <TableCell>
        {item?.masterPrice ? formatCurrency(item.masterPrice) : null}
      </TableCell>
      <TableCell>{item?.priceType}</TableCell>
      <TableCell>
        {item?.pricePolicyType ? formatCurrency(item.pricePolicyType) : null}
      </TableCell>
      <TableCell>
        {item?.totalPrice ? formatCurrency(item.totalPrice) : 0}
      </TableCell>
      <TableCell>{item?.minibarLocationNote}</TableCell>
      <TableCell>
        <IconButton
          sx={{ bgcolor: 'background.grey' }}
          onClick={() => handleDeleteRow(item)}
          disabled={disabled}
        >
          <TrashIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const OrderProductDetail = ({ data, handleDeleteRow, onChange, disabled }) => {
  const [t] = useTranslation();
  const [productData, setProductData] = useState([]);

  const handleChange = (productCode, data) => {
    if (!productCode) return;
    const newProductData = [...productData];
    const objIndex = newProductData.findIndex(
      (obj) => obj.productCode === productCode
    );
    if (!newProductData?.[objIndex]) return;
    newProductData[objIndex] = data;
    setProductData(newProductData);
  };

  const handleModify = () => {
    onChange({
      listCurationProduct: productData.map((item) => ({
        productCode: item?.productCode,
        orderQuantity: item?.orderQuantity || 0,
        totalPrice: item?.totalPrice || 0
      }))
    });
  };

  useEffect(() => {
    setProductData(data);
  }, [data]);

  return (
    <Box sx={{ my: 3.75 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2.5, flexWrap: 'wrap' }}
        gap={1}
      >
        <Typography
          sx={{
            fontWeight: 'fontWeightBold',
            color: 'text.primary',
            fontSize: 16,
            whiteSpace: 'nowrap'
          }}
        >
          {t('pages.curation.order-product-detail')}
        </Typography>
        <Button variant="outlined" onClick={handleModify} disabled={disabled}>
          {t('pages.curation.modify')}
        </Button>
      </Stack>
      <ZombieTable columns={columns} position="relative">
        <CustomTableBody>
          {productData?.length > 0 ? (
            productData.map((item) => (
              <OrderProductTableRow
                key={item.productCode}
                item={item}
                handleDeleteRow={handleDeleteRow}
                onRowChange={handleChange}
                disabled={disabled}
              />
            ))
          ) : (
            <TableRow sx={{ height: 200 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                <NoData />
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
    </Box>
  );
};

export default OrderProductDetail;
