/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Switch,
  Stack,
  Typography,
  IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import MyDialog from '@components/MyDialog';
import { setError, setSuccess } from '@store/reducers/appReducer';
import MyTextField from '@components/MyTextField';
import { useDispatch } from 'react-redux';
import kioskServices from '@services/kiosk';
import error2Text from '@utils/error2Text';
import { toast } from 'react-toastify';
import CopyToClipboardButton from '@components/CopyToClipboard';
import {
  updateActionRows,
  MobileTableStyle as tableStyle,
  actions
} from '../enhance';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));
const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

// TODO:
// 1. handle api requests
const MobileActionsDialog = ({ open, setOpen, item }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [kiosk, setKiosk] = useState(null);
  const [time, setTime] = useState('');

  const handleRestart = async (actionType = 'restart') => {
    if (!item?.kioskCode) return;
    let rs;
    switch (actionType) {
      case actions.hwRestart:
        rs = await kioskServices.updateDeviceReboot(item?.kioskCode, {
          setMode: 1
        });
        break;

      default:
        rs = await kioskServices.updateKioskReboot(item?.kioskCode, {
          setMode: 1
        });
        break;
    }

    if (!rs.error) {
      dispatch(
        setSuccess({ message: t('pages.equipment.messages.success.edit') })
      );
    }
  };

  const handleSubmit = async (name, field, mode) => {
    const sendKioskCode = kiosk?.kioskCode;
    setKiosk((prevKiosk) => ({ ...prevKiosk, [field]: mode }));
    let sendData = { setMode: mode === 'Y' };

    let rs;
    switch (field) {
      case actions.kioskYn:
        rs = await kioskServices.updateKioskMode(sendKioskCode, sendData);
        break;
      case actions.redoYn:
        rs = await kioskServices.updateKioskRedo(sendKioskCode, sendData);

        break;
      case actions.outYn:
        rs = await kioskServices.updateKioskOut(sendKioskCode, sendData);

        break;
      case actions.endYn:
        rs = await kioskServices.updateKioskEnd(sendKioskCode, sendData);
        break;
      case actions.logoYn:
        rs = await kioskServices.updateKioskLogo(sendKioskCode, sendData);
        break;
      case actions.inspectYn:
        sendData = { flag: mode === 'Y' ? 1 : 0, time: time || ' ' };
        rs = await kioskServices.updateKioskInspect(sendKioskCode, sendData);
        break;
      case actions.restart:
        rs = await kioskServices.updateKioskReboot(sendKioskCode, sendData);
        break;
      case actions.hwRestart:
        rs = await kioskServices.updateDeviceReboot(sendKioskCode, sendData);
        break;
      default:
        console.log('defaultCase');
    }
    if (rs.error) {
      // error display
      dispatch(setError(error2Text(rs?.error)));
      getKioskDetail();
    } else {
      dispatch(
        setSuccess({ message: t('pages.equipment.messages.success.edit') })
      );
    }
  };
  const getKioskDetail = async () => {
    if (!item?.kioskCode) return;
    const response = await toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          kioskServices.getKiosk(item?.kioskCode).then(resolve).catch(reject);
        }, 300); // delay for 1 second
      }),
      {
        pending: t('info.fetch.loading'),
        success: `${t('info.fetch.success')}👌`,
        error: `${t('info.fetch.success')} 🤯`
      }
    );
    if (!response.error) {
      setKiosk(response?.data?.data);
    }
  };

  useEffect(() => {
    setTime('');
    setKiosk(item);
  }, [item]);
  const titleComponent = () => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: { md: '18px !important', xs: '12px !important' },
            textAlign: 'center',
            py: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {kiosk?.kioskNickname}
        </Typography>
        <IconButton onClick={() => getKioskDetail()}>
          <RefreshIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={() => titleComponent()}
      hasCloseButton
      hideDialogButton
      sx={{ maxWidth: 460, maxHeight: '95vh' }}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            {updateActionRows(t).map(({ label, name, field }) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row">
                  {label}
                </TableCell>
                <TableCell>
                  {(() => {
                    switch (name) {
                      case 'restart':
                        return (
                          <Button
                            variant="outlined"
                            sx={{
                              height: '30px',
                              width: '10px',
                              borderRadius: '5px',
                              p: '5px 5px'
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRestart();
                            }}
                          >
                            {t('pages.kiosk.labels.restart')}
                          </Button>
                        );

                      case 'hwRestart':
                        return (
                          <Button
                            variant="contained"
                            sx={{
                              height: '30px',
                              width: '10px',
                              borderRadius: '5px',
                              p: '5px 5px'
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRestart(actions.hwRestart);
                            }}
                          >
                            {t('pages.kiosk.labels.restart')}
                          </Button>
                        );
                      case 'anydeskId':
                        return (
                          <CopyToClipboardButton value={kiosk?.anydeskId} />
                        );
                      default:
                        return (
                          <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            visibility={
                              name === 'updateLogoYn'
                                ? !['L7 호텔'].includes(item?.appNickname) &&
                                  'hidden'
                                : 'flex'
                            }
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography>Off</Typography>
                              <AntSwitch
                                checked={kiosk?.[field] === 'Y'}
                                onClick={() =>
                                  handleSubmit(
                                    name,
                                    field,
                                    kiosk?.[field] === 'Y' ? 'N' : 'Y'
                                  )
                                }
                                inputProps={{ 'aria-label': 'ant design' }}
                              />
                              <Typography>On</Typography>
                            </Stack>
                            {name === 'updateInspection' && (
                              <MyTextField
                                size="large"
                                placeholder={t('pages.kiosk.labels.message')}
                                value={time}
                                name="message"
                                onChange={(e) => setTime(e.target.value)}
                                sx={{
                                  width: '100%',
                                  margin: '0px 0px',
                                  mt: 2
                                }}
                              />
                            )}
                          </Stack>
                        );
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default MobileActionsDialog;
