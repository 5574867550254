/* eslint-disable no-unsafe-optional-chaining */
import { sub } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';

import MySelect from '@components/MySelect';
import MyTable from '@components/MyTable';
import MyTextField from '@components/MyTextField';
import SearchBar from '@components/SearchBar';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import clientOrderServices from '@services/clientOrder';
import { Formatter } from '@utils/formatter';
import { dateFormat } from '@constants/utils';

import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import RegisterNewOrderDialog from './Components/RegisterNewOrderDialog';
import ExcelOrderRegisterDialog from './Components/ExcelOrderRegisterDialog';
import RejectReasonDialog from './RejectReasonDialog';

import {
  clientOrderConditionList,
  clientOrderStatusList,
  clientOrderColumns as columns
} from '../enhance';

const ClientOrder = ({ t: pageT }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState();
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    action: ''
  });
  const [dateRange, setDateRange] = useState({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
    key: 'selection'
  });

  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const [condition, setCondition] = useState(clientOrderConditionList[1].value);
  const [status, setStatus] = useState(clientOrderStatusList[0].value);
  const [loadingButton, setLoadingButton] = useState(false);
  const keywordRef = useRef();

  const getData = async (more) => {
    const result = await clientOrderServices.getList({
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      clientCode: client?.clientCode,
      minibarCode: minibar?.minibarCode,
      ...(condition === clientOrderConditionList[0].value
        ? {
            requester: keywordRef.current?.value,
            requestType: keywordRef.current?.value,
            orderingVendor: keywordRef.current?.value
          }
        : { [condition]: keywordRef.current?.value }),
      status,
      page,
      ...more
    });
    setData(result?.data);
  };
  const [idxLoading, setIdxLoading] = useState(null);
  const getActionButtons = (item, _, index) => (
    <LoadingButton
      loading={loadingButton && index === idxLoading}
      variant="text"
      sx={{ height: 36 }}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem(item);
        setIdxLoading(index);
        handleDownloadOrderSheet(item?.orderCode);
      }}
    >
      {pageT('orderSheet')}
    </LoadingButton>
  );

  const getModalButton = (item) => (
    <Typography
      sx={{
        ':hover': { textDecoration: 'underline' },
        maxWidth: 96,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedItem(item);
        setOpenRejectReason(true);
      }}
    >
      {(item?.rejectYn === 'Y' && item?.rejectReason) || ''}
    </Typography>
  );

  const handleSearch = () => {
    const dataParams = {
      startDate: moment(dateRange.startDate).format(dateFormat),
      endDate: moment(dateRange.endDate).format(dateFormat),
      clientCode: client?.clientCode,
      minibarCode: minibar?.minibarCode,
      ...(condition === clientOrderConditionList[0].value
        ? {
            requester: keywordRef.current?.value,
            requestType: keywordRef.current?.value,
            orderingVendor: keywordRef.current?.value
          }
        : { [condition]: keywordRef.current?.value }),
      status
    };
    const query = objToParams(ignoreEmpty({ ...dataParams, page: 1 }));
    getData({ ...dataParams, page: 0 });
    navigate(query);
  };

  const handleRowClick = (row) => {
    if (row?.orderCode) navigate(row.orderCode);
  };

  const handleDownloadOrderSheet = async (clientOrderCode) => {
    setLoadingButton(true);
    const params = { orderCode: clientOrderCode };
    const res = await clientOrderServices.downloadOrderSheet(params);
    setLoadingButton(false);
    if (res?.error) {
      dispatch(setError(error2Text(res.error)));
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    setDateRange({
      ...dateRange,
      startDate: new Date(
        query?.startDate
          ? moment(query?.startDate).format(dateFormat)
          : firstDayOfMonth
      ),
      endDate: new Date(
        query?.endDate ? moment(query?.endDate).format(dateFormat) : new Date()
      )
    });
    setClient({ ...client, clientCode: query?.clientCode || null });
    setMinibar({
      ...minibar,
      minibarCode: query?.minibarCode || null
    });
    setStatus(query?.status || 'Registration,Complete,Reject,Picking');
    setPage(query?.page - 1 || 0);
    const { requester, requestType, orderingVendor } = query;
    keywordRef.current.value = requester || requestType || orderingVendor || '';
    setCondition(
      (requester && requestType && orderingVendor && 'All') ||
        (requester && 'requester') ||
        (requestType && 'requestType') ||
        (orderingVendor && 'orderingVendor') ||
        'All'
    );
    const dataParams = {
      startDate: moment(query?.startDate || firstDayOfMonth).format(dateFormat),
      endDate: moment(query?.endDate || new Date()).format(dateFormat),
      clientCode: query?.clientCode || null,
      minibarCode: query?.minibarCode || null,
      status: query?.status || 'Registration,Complete,Reject,Picking',
      page: query?.page - 1 || 0,
      ...{
        requester: requester || null,
        requestType: requestType || null,
        orderingVendor: orderingVendor || null
      }
    };
    getData(dataParams);
  }, [location?.search]);
  const handleRegisterButtons = (actionType) => {
    setDialog({ ...dialog, open: true, action: actionType });
  };
  return (
    <HelmetContainer
      title={t('menusidebar.product-management.client-order')}
      content="Client Order"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={pageT('period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker state={dateRange} setState={setDateRange} />
            }
          />
        </FormControl>
        <ClientAutoCompleteV2
          setClient={(e) => {
            setClient(e);
            setMinibar(null);
          }}
          client={client}
        />
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={setMinibar}
          client={client}
          disabled={!client?.clientCode}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Stack direction="row" alignItems="center" spacing={2.5} sx={{ ml: 2 }}>
          <Typography
            sx={{
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }}
          >
            {pageT('status')}
          </Typography>
          <FormControl
            sx={{
              '.MuiTypography-root': {
                color: '#343434',
                fontSize: 14,
                whiteSpace: 'nowrap'
              }
            }}
          >
            <RadioGroup
              row
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {clientOrderStatusList.map((status) => (
                <FormControlLabel
                  key={status.value}
                  value={status.value}
                  control={<Radio />}
                  label={status.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
        <MySelect
          label={t('common.condition')}
          value={condition}
          data={clientOrderConditionList}
          onChange={(e) => setCondition(e.target.value)}
          sx={{ mr: 1.5 }}
        />
        <Stack flex={1} sx={{ minWidth: 300 }}>
          <MyTextField
            placeholder={pageT('input-keyword')}
            ref={keywordRef}
            autoFocus
            onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" gap={1}>
          <Button
            variant="contained"
            onClick={() => handleRegisterButtons('excel')}
          >
            {t('pages.client-order.excel-register')}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleRegisterButtons('new')}
          >
            {t('pages.client-order.new-order')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {data?.totalRow || 0}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        rerender
        hover
        onRowClick={handleRowClick}
        columns={columns}
        data={data?.items || []}
        pagination={{
          count: data?.totalPage,
          page
        }}
        onChangePage={(e, page) => {
          const dataParams = {
            startDate: moment(dateRange.startDate).format(dateFormat),
            endDate: moment(dateRange.endDate).format(dateFormat),
            clientCode: client?.clientCode,
            minibarCode: minibar?.minibarCode,
            ...(condition === clientOrderConditionList[0].value
              ? {
                  requester: keywordRef.current?.value,
                  requestType: keywordRef.current?.value,
                  orderingVendor: keywordRef.current?.value
                }
              : { [condition]: keywordRef.current?.value }),
            status
          };
          const query = objToParams(
            ignoreEmpty({ ...dataParams, page: page + 1 })
          );
          getData({ ...dataParams, page });
          navigate(query);
        }}
        getActionButtons={getActionButtons}
        getModalButton={getModalButton}
        minusHeight={280}
      />
      {/* dialogs */}
      <RejectReasonDialog
        open={openRejectReason}
        setOpen={setOpenRejectReason}
        key={selectedItem}
        data={selectedItem}
        pageT={pageT}
      />
      {dialog.action === 'new' && (
        <RegisterNewOrderDialog
          open={dialog.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          onSuccess={() => getData()}
        />
      )}
      {dialog.action === 'excel' && (
        <ExcelOrderRegisterDialog
          open={dialog.open}
          setOpen={() => setDialog({ ...dialog, open: false, action: '' })}
          onSuccess={() => getData()}
        />
      )}
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.client-order' })(
  ClientOrder
);
