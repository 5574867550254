import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const { formatTime } = Formatter;

const curationT = i18n.getFixedT(null, null, 'pages.curation');
const g = i18n.getFixedT(null, null, '');
const clientOrderT = i18n.getFixedT(null, null, 'pages.client-order');

export const channelList = [
  { label: 'B2B', value: 1 },
  { label: 'B2C', value: 2 },
  { label: 'B2B2C', value: 3 }
];
export const clientChannelList = [
  { label: 'B2B', value: 'B2B' },
  { label: 'B2C', value: 'B2C' },
  { label: 'B2B2C', value: 'B2B2C' }
];

export const conditionList = [
  { label: curationT('top10-product-purchase-rate'), value: 'PurchaseRate' },
  {
    label: curationT('top10-product-repurchase-rate'),
    value: 'RepurchaseRate'
  },
  { label: curationT('current-minibar-display'), value: 'CurrentMinibar' }
];

export const clientOrderConditionList = [
  { label: clientOrderT('all'), value: 'All' },
  { label: clientOrderT('requester'), value: 'requester' },
  { label: clientOrderT('requestType'), value: 'requestType' },
  { label: clientOrderT('orderingVendor'), value: 'orderingVendor' }
];

export const clientOrderStatusList = [
  {
    label: clientOrderT('total'),
    value: 'Registration,Complete,Reject,Picking'
  },
  { label: clientOrderT('registration'), value: 'Registration' },
  { label: g('pageTitle.picking'), value: 'Picking' },
  { label: clientOrderT('completetion'), value: 'Complete' },
  { label: clientOrderT('reject'), value: 'Reject' }
];
export const clientOrderStatusListObject = {
  Registration: clientOrderT('registration'),
  Picking: g('pageTitle.picking'),
  Complete: clientOrderT('completetion'),
  Reject: clientOrderT('reject')
};

export const clientOrderRequestListObject = {
  WebOrder: clientOrderT('order-type.web-order'),
  AdminOrder: clientOrderT('order-type.admin-order'),
  MinibarOrder: clientOrderT('order-type.minibar-order')
};
export const curationColumns = [
  {
    name: '#',
    label: 'No',
    align: 'center',
    minWidth: 80
  },
  {
    name: 'regDt',
    label: curationT('curation-date'),
    align: 'center',
    minWidth: 120,
    render: ({ regDt }) =>
      new Date(regDt).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    name: 'curationName',
    label: curationT('curation-name'),
    align: 'center',
    minWidth: 160
  },
  {
    name: 'clientChannel',
    label: curationT('channel'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'clientName',
    label: curationT('client'),
    align: 'center',
    minWidth: 150
  },
  {
    name: 'minibarNickname',
    label: curationT('minibar'),
    align: 'center',
    minWidth: 150
  },
  {
    name: 'orderAmount',
    label: curationT('order-price'),
    align: 'center',
    minWidth: 150,
    render: ({ orderAmount }) =>
      new Intl.NumberFormat('en-CA').format(orderAmount)
  },
  {
    name: 'status',
    label: curationT('status'),
    align: 'center',
    minWidth: 150,
    render: ({ status }) =>
      status === 'N' ? curationT('stand-by') : curationT('complete')
  }
];

export const curationProductColumns = [
  {
    name: '#',
    label: 'No',
    align: 'center',
    minWidth: 70
  },
  {
    name: 'isChecked',
    label: curationT('select'), // 선택
    align: 'center',
    minWidth: 60
  },
  {
    name: 'image',
    label: curationT('image'), // 이미지
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: curationT('product-name'), // 상품명
    align: 'center',
    minWidth: 210
  },
  {
    name: 'firstCategory',
    label: curationT('1st-cat'), // 1차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'secondCategory',
    label: curationT('2nd-cat'), // 2차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'thirdCategory',
    label: curationT('3rd-cat'), // 3차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'specialCategory',
    label: curationT('special-cat'), // 특이 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'salesQuantity',
    label: curationT('salesQuantity'), // 판매량
    align: 'center',
    minWidth: 100,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const curationProductDetailColumns = [
  {
    name: '#',
    label: 'No',
    align: 'center',
    minWidth: 70
  },
  {
    name: 'remove',
    label: curationT('remove'), // 선택
    align: 'center',
    minWidth: 60
  },
  {
    name: 'image',
    label: curationT('image'), // 이미지
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: curationT('product-name'), // 상품명
    align: 'center',
    minWidth: 210
  },
  {
    name: 'firstCategory',
    label: curationT('1st-cat'), // 1차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'secondCategory',
    label: curationT('2nd-cat'), // 2차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'thirdCategory',
    label: curationT('3rd-cat'), // 3차 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'specialCategory',
    label: curationT('special-cat'), // 특이 카테고리
    align: 'center',
    minWidth: 150
  },
  {
    name: 'recommendedQuantity',
    label: curationT('recommended-quantity'),
    align: 'center',
    minWidth: 120,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'costRate',
    label: curationT('cost-rate'),
    align: 'center',
    minWidth: 90,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'box',
    label: curationT('box'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'productType',
    label: curationT('product-type'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'productPrice',
    label: curationT('product-price'),
    align: 'center',
    minWidth: 100,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'stockQuantity',
    label: curationT('stock-quantity2'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'location',
    label: curationT('minibar-location'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'minibarProperStock',
    label: curationT('minibar-proper-stock'),
    align: 'center',
    minWidth: 110,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'total',
    label: curationT('total-price'),
    align: 'center',
    minWidth: 110,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const curationDetailColumns = [
  {
    name: 'curationDate',
    label: curationT('curation-date'),
    align: 'center',
    minWidth: 150
  },
  {
    name: 'curationName',
    label: curationT('curation-name'),
    align: 'center',
    minWidth: 420
  },
  {
    name: 'orderClient',
    label: curationT('order-client'),
    align: 'center',
    minWidth: 200
  },
  {
    name: 'total',
    label: curationT('total'),
    align: 'center',
    minWidth: 140,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const categoryColumns = [
  {
    name: '1st-cat',
    label: curationT('1st-cat'),
    align: 'center',
    minWidth: 278
  },
  {
    name: '2nd-cat',
    label: curationT('2nd-cat'),
    align: 'center',
    minWidth: 278
  },
  {
    name: '3rd-cat',
    label: curationT('3rd-cat'),
    align: 'center',
    minWidth: 278
  },
  {
    name: 'special-cat',
    label: curationT('special-cat'),
    align: 'center',
    minWidth: 278
  }
];

export const curationBudgetColumns = [
  {
    name: 'clientName',
    label: curationT('client'),
    align: 'center',
    minWidth: 278
  },
  {
    name: 'minibarNickname',
    label: curationT('minibar'),
    align: 'center',
    minWidth: 278
  },
  {
    name: 'pricePolicyType',
    label: curationT('price-policy-type'),
    align: 'center',
    minWidth: 278
  },
  {
    name: 'Budget',
    label: curationT('budget'),
    align: 'center',
    minWidth: 278
  }
];

export const orderProductDetailColumns = [
  {
    name: 'productCode',
    label: 'No',
    align: 'center',
    minWidth: 60
  },
  {
    name: 'image',
    label: curationT('image'),
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: curationT('product-name'),
    align: 'center',
    minWidth: 190
  },
  {
    name: 'box',
    label: curationT('box'),
    align: 'center',
    minWidth: 70
  },
  {
    name: 'quantity',
    label: curationT('quantity'),
    align: 'center',
    minWidth: 140
  },
  {
    name: 'cost',
    label: curationT('cost'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'masterUnitPrice',
    label: curationT('master-unit-price'),
    align: 'center',
    minWidth: 100,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'type',
    label: curationT('type'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'unitPrice',
    label: curationT('unit-price'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'totalPrice',
    label: curationT('total-price'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'minibarLocation',
    label: curationT('minibar-location'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'notes',
    label: curationT('notes'),
    align: 'center',
    minWidth: 80
  }
];

export const addProductColumns = [
  {
    label: curationT('select'),
    align: 'center',
    minWidth: 60
  },
  {
    label: curationT('image'),
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: curationT('product-name'),
    align: 'center',
    minWidth: 130
  },
  {
    name: 'barcode',
    label: g('pages.minibarOrder.barcode'),
    align: 'center',
    minWidth: 60
  },
  {
    name: 'stockQuantity',
    label: curationT('stock-quantity'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'cost',
    label: curationT('cost'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'masterUnitPrice',
    label: curationT('master-unit-price'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  }
];

export const clientOrderColumns = [
  { name: '#', label: 'No', align: 'center', minWidth: 60 },
  {
    name: 'orderDt',
    label: clientOrderT('orderDate'),
    align: 'center',
    minWidth: 120,
    render: ({ orderDt }) => formatTime(orderDt)
  },
  {
    name: 'clientChannel',
    label: curationT('channel'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'orderClient',
    label: clientOrderT('orderClient'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'minibarNickname',
    label: clientOrderT('minibarNickname'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'requester',
    label: clientOrderT('requester'),
    align: 'center',
    minWidth: 120
  },
  {
    name: 'requestDivision',
    label: clientOrderT('requestType'),
    align: 'center',
    minWidth: 120,
    render: ({ requestDivision }) =>
      (requestDivision && clientOrderRequestListObject[requestDivision]) ||
      requestDivision
  },
  {
    name: 'status',
    label: clientOrderT('status'),
    align: 'center',
    minWidth: 120,
    render: ({ status }) =>
      (status && clientOrderStatusListObject[status]) || status
  },
  {
    label: clientOrderT('reasonForReject'),
    align: 'center',
    minWidth: 120,
    type: tableTypes.MODAL
  },
  {
    label: clientOrderT('orderSheet'),
    align: 'center',
    minWidth: 120,
    type: tableTypes.ACTION
  }
];

export const clientOrderSheetColumns = [
  {
    name: 'orderDate',
    label: clientOrderT('orderDate'),
    align: 'center',
    minWidth: 220
  },
  {
    name: 'channel',
    label: curationT('channel'),
    align: 'center',
    minWidth: 220
  },
  {
    name: 'orderClient',
    label: clientOrderT('orderClient'),
    align: 'center',
    minWidth: 170
  },
  {
    name: 'minibarNickname',
    label: clientOrderT('minibarNickname'),
    align: 'center',
    minWidth: 170
  },
  {
    name: 'orderPrice',
    label: clientOrderT('orderPrice'),
    align: 'center',
    minWidth: 220,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    label: clientOrderT('management'),
    align: 'center',
    minWidth: 220
  }
];

export const clientOrderProductDetailColumns = [
  {
    name: '#',
    label: 'No',
    align: 'center',
    minWidth: 60
  },
  {
    name: 'image',
    label: clientOrderT('image'),
    align: 'center',
    minWidth: 70
  },
  {
    name: 'productName',
    label: clientOrderT('productName'),
    align: 'center',
    minWidth: 190
  },
  {
    name: 'quantity',
    label: clientOrderT('quantity'),
    align: 'center',
    minWidth: 100,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'quantity',
    label: curationT('stock-quantity'),
    align: 'center',
    minWidth: 100,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },

  {
    name: 'cost',
    label: clientOrderT('cost'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    name: 'masterUnitPrice',
    label: clientOrderT('masterUnitPrice'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'saleType',
    label: clientOrderT('saleType'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'saleUnitPrice',
    label: clientOrderT('saleUnitPrice'),
    align: 'center',
    minWidth: 80
  },
  {
    name: 'totalPrice',
    label: clientOrderT('totalPrice'),
    align: 'center',
    minWidth: 80,
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    label: clientOrderT('management'),
    align: 'center',
    minWidth: 80
  }
];
