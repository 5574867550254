import HelmetContainer from '@components/HelmetContainer';
import GroupMultiHeaderTable from '@components/MyTable/GroupMultiHeaderTable';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import Calendar from '@components/Calendar';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MyTextField from '@components/MyTextField';
import { useTranslation } from 'react-i18next';
import VendorOrderServices from '@services/vendorOrder';
import { sub } from 'date-fns';
import { dateFormat } from '@constants/utils';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { columns, radioOptions } from './enhance';

const History = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const columnsConfig = [...columns];
  columnsConfig.pop();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    day: formatTime(sub(new Date(), { days: 1 }), dateFormat),
    status: null,
    productName: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getDetails({ ...param, page });
    navigate(query);
  };
  const { items, pagination } = state;
  const handleChangeCheckbox = (val, e) => {
    if (e.target.checked) {
      setParam({
        ...param,
        status: val
      });
    } else {
      setParam({
        ...param,
        status: null
      });
    }
  };
  const getDetails = async (param) => {
    const rs = await VendorOrderServices.getVendorOrders(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { status, page, productName, day } = query;
    setParam({
      ...param,
      status,
      page: page - 1 || 0,
      productName,
      day: formatTime(day || sub(new Date(), { days: 1 }), dateFormat)
    });
    setDate(new Date(day || sub(new Date(), { days: 1 })));
    getDetails({
      ...param,
      status,
      page: page - 1 || 0,
      productName,
      day: formatTime(day || sub(new Date(), { days: 1 }), dateFormat)
    });
  }, [location?.search]);
  const [date, setDate] = useState(sub(new Date(), { days: 1 }));
  const [loading, setLoading] = useState(false);
  const onExport = async () => {
    setLoading(true);
    const rs = await VendorOrderServices.exportOrders(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getDetails({ ...param, page: 0 });
    navigate(query);
  };

  return (
    <HelmetContainer
      title={t('pageTitle.vendor-order-history')}
      content="Sales page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('pages.product.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                width: 70,
                textAlign: 'center'
              }
            }}
            control={
              <Calendar
                date={date}
                setDate={(e) => {
                  console.log(e);
                  setDate(e);
                  setParam({ ...param, day: formatTime(e, dateFormat) });
                }}
              />
            }
          />
        </FormControl>
        <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 2
              }
            }}
            control={
              <Stack flexDirection="row">
                {radioOptions.map(({ value, label }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value === param?.status}
                        onChange={(e) => handleChangeCheckbox(value, e)}
                      />
                    }
                    label={label}
                  />
                ))}
              </Stack>
            }
          />
        </FormControl>
        <MyTextField
          label={t('pages.product.product-name')}
          onChange={(e) => setParam({ ...param, productName: e.target.value })}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={() => {
            handleSearch();
          }}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack flexDirection="row" justifyContent="space-between" mt={2}>
        <Stack flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            onClick={onExport}
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading}
          >
            {t('button.saveExcel')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/order-management/vendor')}
          >
            {t('button.purchase-list')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <GroupMultiHeaderTable
        rerender
        columns={columnsConfig}
        data={items || []}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default History;
