import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const VendorOrderServices = {
  async getVendorOrders(data) {
    try {
      const res = await new RestService().setPath('vendor-order').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportOrders(data) {
    try {
      const res = await new RestService()
        .setPath('vendor-order/export')
        .setResponseType('blob')
        .post(data);
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async purchaseOn(id) {
    try {
      const res = await new RestService()
        .setPath(`vendor-order/${id}/status`)
        .put();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default VendorOrderServices;
