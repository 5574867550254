/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Typography
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import MyDialog from '@components/MyDialog';
import MySnackbar from '@components/MySnackbar';
import MyTextField from '@components/MyTextField';

import { setError } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import equipmentServices from '@services/equipment';
import error2Text from '@utils/error2Text';
import { tableStyle } from '../enhance';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

const UpdateEqmDialog = ({ open, setOpen, onAction }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [columnList, setColumnList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleChange = (id, event) => {
    const { value } = event.target;
    setColumnList((prevColumnList) => {
      return prevColumnList.map((item) => {
        if (item.id === id) {
          if (!value.trim()) {
            return {
              ...item,
              equipmentStatusName: value,
              errMsg: t('common.required')
            };
          }
          return {
            ...item,
            equipmentStatusName: value,
            errMsg: ''
          };
        }
        return item;
      });
    });
  };
  const [state, setState] = React.useState({
    open: false,
    opedAlert: false
  });

  const handleClose = () => {
    setState({
      ...state,
      open: false,
      message: ''
    });
  };

  const handleDeleteData = async () => {
    // check if there is any equipment in this category

    if (selectedItem?.isNew) {
      setColumnList((prev) => {
        const updatedCols = prev?.filter((i) => i.id !== selectedItem?.id);
        return updatedCols;
      });
      setState({ ...state, opedAlert: false });
    } else {
      if (!selectedItem.equipmentStatusCode || !selectedItem?.isDeletable)
        return;
      const { error } = await equipmentServices.deleteEquipmentStatus(
        selectedItem.equipmentStatusCode
      );
      if (error) {
        dispatch(setError(error2Text(error)));
      } else {
        getEquipmentStatusList();
        onAction('colsUpdate');
        setState({
          ...state,
          opedAlert: false,
          open: true,
          message: t('pages.equipment.messages.success.delete')
        });
        // snackbar success
      }
    }
  };
  const handleCreateData = async (item) => {
    if (!item?.equipmentStatusName) return;
    const { error } = await equipmentServices.createEquipmentStatusName({
      equipmentStatusName: item?.equipmentStatusName
    });
    if (!error) {
      setState({ ...state, open: true });
      getEquipmentStatusList();
      onAction('colsUpdate');
      setState({
        ...state,
        opedAlert: false,
        open: true,
        message: t('pages.equipment.messages.success.edit')
      });
    }
  };

  const handleUpdate = async (id) => {
    const updatedStatus = columnList.find((item) => item.id === id);
    if (!updatedStatus.equipmentStatusCode) return;
    setLoading(true);
    const { error } = await equipmentServices.updateEquipmentStatus(
      updatedStatus.equipmentStatusCode,
      { equipmentStatusName: updatedStatus?.equipmentStatusName }
    );
    if (error && error?.fieldErrors) {
      toast.error(Object.keys(error.fieldErrors).join(', '));
      setState({ ...state, open: true });
    } else {
      onAction('colsUpdate');
      setState({
        ...state,
        opedAlert: false,
        open: true,
        message: t('pages.equipment.messages.success.delete')
      });
    }

    setLoading(false);
  };
  const getEquipmentStatusList = async () => {
    const rs = await equipmentServices.getEquipmentStatusList();
    if (!rs.error) {
      const colList = rs.data?.map((item, index) => ({
        ...item,
        id: index,
        editMode: false
      }));
      setColumnList(colList || []);
    } else {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  useEffect(() => {
    getEquipmentStatusList();
  }, []);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.equipment.modal.modifyUsageTitle')}
      hasCloseButton
      hideDialogButton
      loading={loading}
      setLoading={setLoading}
      sx={{
        minWidth: 460,
        '*::-webkit-scrollbar': {
          width: '0px'
        }
      }}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody
            sx={{
              ...tableStyle,
              '.MuiTableRow-root': {
                ':not(:last-child)': {
                  borderBottom: '1px solid #EEEEEE'
                },
                th: {
                  borderRight: '1px solid #EEEEEE',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  textAlign: 'center',
                  color: '#6F869C',
                  bgcolor: '#FCFCFC',
                  width: 120
                },
                td: {
                  p: '10px 5px'
                }
              }
            }}
          >
            {columnList.map((item, index) => {
              if (index === columnList.length - 1) {
                return (
                  <>
                    <TableRow
                      key={item?.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <TableCell
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: 1
                        }}
                      >
                        <MyTextField
                          value={item?.equipmentStatusName}
                          onChange={(event) => handleChange(item.id, event)}
                          errMg={item.errMsg}
                          disabled={!item?.editMode}
                          sx={{ width: 'auto' }}
                        />
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          ml={2}
                          gap={1}
                        >
                          {/* dynamic icons */}
                          <IconButton
                            disabled={item.errMsg}
                            size="small"
                            onClick={() => {
                              if (item.editMode) {
                                // eslint-disable-next-line no-unused-expressions
                                columnList.length - 1 === index
                                  ? handleCreateData(item)
                                  : handleUpdate(item.id);
                              }
                              setColumnList((prevColumnList) => {
                                return prevColumnList.map((selectedItem) => {
                                  if (selectedItem.id === item.id) {
                                    return {
                                      ...selectedItem,
                                      editMode: !selectedItem.editMode
                                    };
                                  }
                                  return selectedItem;
                                });
                              });
                            }}
                          >
                            {item.editMode ? (
                              <CheckCircleIcon />
                            ) : (
                              <ModeEditIcon />
                            )}
                          </IconButton>
                          <Tooltip
                            title={item.isDeletable === 'Y' ? '' : 'delete'}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#F2F2F2',
                                  color: 'common.black'
                                }
                              }
                            }}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -15]
                                  }
                                }
                              ]
                            }}
                          >
                            <span>
                              <IconButton
                                disabled={item?.isDeletable === 'N'}
                                size="small"
                                onClick={() => {
                                  setState({ ...state, opedAlert: true });
                                  setSelectedItem(item);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={`${item?.equipmentStatusName}+${item?.id}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ':hover': {
                          ...(!item.equipmentStatusName
                            ? {} // If there is an error, do not apply hover styles
                            : {
                                bgcolor: 'secondary.lighter',
                                '.MuiTableCell-root': {
                                  color: 'primary.dark'
                                },
                                cursor: 'pointer'
                              })
                        },
                        ...(!item.equipmentStatusName
                          ? {
                              opacity: 0.5, // Adjust the opacity to visually indicate disabled state
                              color: '#6F869C',
                              pointerEvents: 'none' // Disable pointer events to prevent interaction
                            }
                          : {})
                      }}
                      onClick={() =>
                        !item.errMsg &&
                        setColumnList([
                          ...columnList,
                          {
                            id: index + 2,
                            equipmentStatusName: '',
                            isNew: true,
                            editMode: true
                          }
                        ])
                      }
                    >
                      <TableCell
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: 1
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          ml={2}
                          gap={1}
                        >
                          <AddIcon />
                          <Typography>
                            {t('pages.equipment.modal.modifyUsageTitle')}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                );
                // eslint-disable-next-line no-else-return
              } else {
                return (
                  <TableRow
                    key={item?.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <TableCell
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 1
                      }}
                    >
                      <MyTextField
                        value={item?.equipmentStatusName}
                        onChange={(event) => handleChange(item.id, event)}
                        errMg={item.errMsg}
                        disabled={!item?.editMode}
                        sx={{ width: 'auto' }}
                      />
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        ml={2}
                        gap={1}
                      >
                        {/* dynamic icons */}
                        <IconButton
                          disabled={item.errMsg}
                          size="small"
                          onClick={() => {
                            if (item.editMode) {
                              // eslint-disable-next-line no-unused-expressions
                              columnList.length === index
                                ? handleCreateData(item)
                                : handleUpdate(item.id);
                            }
                            setColumnList((prevColumnList) => {
                              return prevColumnList.map((selectedItem) => {
                                if (selectedItem.id === item.id) {
                                  return {
                                    ...selectedItem,
                                    editMode: !selectedItem.editMode
                                  };
                                }
                                return selectedItem;
                              });
                            });
                          }}
                        >
                          {item.editMode ? (
                            <CheckCircleIcon />
                          ) : (
                            <ModeEditIcon />
                          )}
                        </IconButton>
                        <Tooltip
                          title={item?.isDeletable === 'N' ? 'delete' : ''}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#F2F2F2',
                                color: 'common.black'
                              }
                            }
                          }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -15]
                                }
                              }
                            ]
                          }}
                        >
                          <span>
                            <IconButton
                              disabled={item?.isDeletable === 'N'}
                              size="small"
                              onClick={() => {
                                setState({ ...state, opedAlert: true });
                                setSelectedItem(item);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MySnackbar
        open={state?.open}
        onClose={handleClose}
        message={state.message}
      />
      {selectedItem && (
        <MyDialog
          open={state.opedAlert}
          setOpen={() => setState({ ...state, opedAlert: false })}
          onOk={handleDeleteData}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.equipmentStatusName || ''
          })}
          hasCancelButton
        />
      )}
    </MyDialog>
  );
};

export default UpdateEqmDialog;
