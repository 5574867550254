export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          '&:hover': {
            backgroundColor: theme.palette.primary.darker
          }
        },
        colorWarning: {
          backgroundColor: theme.palette.warning.main,
          color: 'white'
        },
        colorError: {
          backgroundColor: theme.palette.error.main
        },
        colorSecondary: {
          backgroundColor: 'gray',
          color: 'white'
        }
      }
    }
  };
}
