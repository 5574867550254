import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const salesStatisticsService = {
  async getSalesStatistics(params = {}) {
    try {
      const res = await new RestService()
        .setPath('sales-statistics')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getMonthlyStats(params = {}) {
    try {
      const res = await new RestService()
        .setPath('statistics/sales-by-month')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getMemberStats(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/statistics/sales-by-amount-member')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPurchaseStats(params = {}) {
    try {
      const res = await new RestService()
        .setPath('statistics/sales-by-transaction-member')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSalesStatistics(params = {}) {
    try {
      const res = await new RestService()
        .setPath('sales-statistics/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default salesStatisticsService;
