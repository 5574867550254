import RestService from '@services/RestService';

const dashboardService = {
  async getDashboard() {
    try {
      const res = await new RestService().setPath('/dashboard').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default dashboardService;
