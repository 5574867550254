import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import homepageService from '@services/homepage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from '@store/reducers/appReducer';
import jwtDecode from 'jwt-decode';
import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import CareerDetailForm from './CareerDetailForm';
import { requiredFields } from '../enhance';

const CareerDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname?.split('/');
  const recruitCode = pathname[pathname.length - 1];
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const [formValue, setFormValue] = useState({
    createdBy: decodedToken?.name,
    updatedBy: '',
    createdDate: Formatter.formatTime(new Date()),
    updatedDate: '',
    departmentType: '',
    employmentType: '',
    yearOfExperience: '',
    regionCode: '',
    title: '',
    content: '',
    hasDeadline: true,
    deadline: ''
  });
  const getFaqDetail = async () => {
    if (!location?.pathname?.includes('register')) {
      const rs = await homepageService.getJobAnnouncementDetail(recruitCode);
      setFormValue({
        ...formValue,
        ...rs?.data?.data,
        hasDeadline: rs?.data?.data?.deadline
      });
    }
  };

  useEffect(() => {
    getFaqDetail();
  }, []);

  const isValidForm = () => {
    let isValid = true;
    requiredFields.forEach((field) => {
      if (!formValue[field]) {
        isValid = false;
      }
    });
    return isValid;
  };
  const onAction = async (action) => {
    if (action === 'cancel') navigate(-1);
    if (action === 'register' || action === 'modification') {
      const dataSubmit = {
        department: formValue?.departmentType,
        employmentType: formValue?.employmentType,
        yearOfExperience: formValue?.yearOfExperience,
        regionCode: formValue?.regionCode,
        title: formValue?.title,
        content: formValue?.content,
        deadline:
          formValue?.departmentType !== 'TalentPool'
            ? formValue?.deadline
            : null
      };
      if (isValidForm()) {
        let rs = null;
        if (action === 'register') {
          rs = await homepageService.createJobPost(dataSubmit);
        } else {
          rs = await homepageService.updateJobPost(
            formValue?.recruitCode,
            dataSubmit
          );
        }
        if (!rs?.error) {
          dispatch(setSuccess({ message: t('info.success.content') }));
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          dispatch(setError(error2Text(rs.error)));
        }
      }
    }
  };

  return (
    <HelmetContainer
      title={
        recruitCode === 'register'
          ? `${t('pageTitle.talent-hire')} ${t('button.register')}`
          : `${t('pageTitle.talent-hire')} ${t('button.modify')}`
      }
      content="careers detail page"
    >
      <CareerDetailForm
        onAction={onAction}
        value={formValue}
        setValue={setFormValue}
      />
    </HelmetContainer>
  );
};

export default CareerDetail;
