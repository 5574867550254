import { IconButton, InputAdornment, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect } from 'react';
import MyTextField from '@components/MyTextField';
import productService from '@services/product';
import { useLocation } from 'react-router-dom';
import { onlyNumber } from '@utils/helpers';

const NutritionalInformation = ({ value, setValue }) => {
  const location = useLocation();

  const getNutritional = async () => {
    const pathname = location?.pathname?.split('/');
    const productCode = pathname[pathname.length - 1];
    const rs = await productService.getNutritional(productCode);
    if (!rs?.error) {
      const newValue =
        rs?.data?.length > 0
          ? [...rs.data]
          : [{ position: 1, type: '', value: '', per: 0 }];
      newValue.forEach((i, idx) => {
        i.position = idx + 1;
      });
      setValue(newValue);
    }
  };

  useEffect(() => {
    getNutritional();
  }, []);

  const addRow = () => {
    const lastRow = value[value.length - 1];
    if (lastRow.type && lastRow.value) {
      setValue(
        value.concat([
          { position: value.length + 1, type: '', value: '', per: 0 }
        ])
      );
    }
  };

  const removeRow = (idx) => {
    if (value.length === 1) {
      setValue([{ position: 1, type: '', value: '', per: 0 }]);
    } else {
      const newRow = [...value];
      newRow.splice(idx, 1);
      newRow.forEach((i, idx) => {
        i.position = idx + 1;
      });
      setValue(newRow);
    }
  };
  const onChange = (position, e) => {
    const newValue = [...value];
    value.forEach((i) => {
      if (i.position === position) {
        if (e.target.name === 'per') {
          i.per = e.target.value < 100 ? parseInt(e.target.value, 10) : 100;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
    });
    setValue(newValue);
  };
  return (
    <Stack
      sx={{
        ':last-child': {
          borderBottom: '1px solid #D3D3D3'
        }
      }}
    >
      {value.map((item, index) => (
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ borderTop: '1px solid #D3D3D3' }}
          gap={2}
        >
          <MyTextField
            onChange={(e) => onChange(item.position, e)}
            sx={{ margin: '10px 0' }}
            name="type"
            value={item.type}
          />
          <MyTextField
            onChange={(e) => onChange(item.position, e)}
            name="value"
            value={item.value}
          />
          <MyTextField
            onKeyDown={onlyNumber}
            onChange={(e) => onChange(item.position, e)}
            name="per"
            value={item.per || 0}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
          <Stack flexDirection="row" gap={1}>
            <IconButton
              sx={{ backgroundColor: '#F2F2F2' }}
              onClick={() => removeRow(index)}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              className="addMore"
              color="primary"
              sx={{
                backgroundColor: '#e3e7ff',
                ':hover': { backgroundColor: '#F2F4FF' },
                visibility: value.length !== index + 1 && 'hidden'
              }}
              onClick={addRow}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default NutritionalInformation;
