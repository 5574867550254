import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { maxLengthOf, updateModalRole } from './enhance';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  overflow: 'hidden'
});

// [IMPROVE] this component rerender many times caused by [useMergeState], [form > table] cant not get values when submited ??

const UpdateAdminDialog = ({
  open,
  setOpen,
  selectedItem,
  setSelectedItem,
  loading,
  setLoading,
  onOk,
  title
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const [errMsg, setErrMsg] = useState({
    password: ''
  });

  useEffect(() => {
    if (!open) {
      setSelectedItem(null);
    }
  }, [open]);

  const handleChangeRole = (event) => {
    setSelectedItem((prev) => {
      const newState = { ...prev };

      if (event.target.value === 'isSuper' && newState.isSuper !== 'Y') {
        newState.isAdmin = 'N';
        newState.isProductMaster = 'N';
        newState.isStockMaster = 'N';
        newState.isWarehouseMaster = 'N';
        newState.isDisplayManager = 'N';
      } else {
        newState.isAdmin = 'Y';
      }

      if (
        event.target?.value === 'isDisplayManager' &&
        newState?.isDisplayManager === 'N'
      ) {
        newState.isAdmin = 'N';
        newState.isProductMaster = 'N';
        newState.isStockMaster = 'N';
        newState.isWarehouseMaster = 'N';
      }
      if (
        event.target.value === 'isProductMaster' ||
        event.target.value === 'isStockMaster' ||
        event.target.value === 'isWarehouseMaster'
      ) {
        newState.isDisplayManager = 'N';
        newState.isSuper = 'N';
      }

      return {
        ...newState,
        [event.target.value]: newState[event.target.value] === 'Y' ? 'N' : 'Y'
      };
    });
  };

  const handleChangePassword = (event) => {
    setSelectedItem((prev) => {
      const newState = { ...prev };
      newState.password = event.target.value;

      return {
        ...newState
      };
    });
    setErrMsg({
      password:
        event.target.value?.length > maxLengthOf.password
          ? t('validation.dataTooLong', { max: maxLengthOf.password })
          : ''
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSumbit = () => {
    if (onOk && !errMsg.password) {
      onOk();
    }
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={title}
      okTitle={t('button.check')}
      hasCloseButton
      hasCancelButton
      onOk={handleSumbit}
      loading={loading}
      setLoading={setLoading}
    >
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'background.light'
                }}
              >
                {t(`admin.create.regName`)}
              </TableCell>
              <TableCell>
                <MyTextField
                  value={selectedItem?.name}
                  disabled
                  sx={{ width: '100%' }}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'background.light'
                }}
              >
                {t(`admin.update.authority`)}
              </TableCell>
              <TableCell>
                <Stack spacing={3} sx={{ marginLeft: 1 }}>
                  <FormGroup>
                    {updateModalRole.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            disabled={label === 'Admin'}
                            onChange={handleChangeRole}
                            value={value}
                            checked={selectedItem[value] === 'Y'}
                          />
                        }
                        label={t(`admin.update.${label}`)}
                      />
                    ))}
                  </FormGroup>
                </Stack>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'background.light'
                }}
              >
                {t(`admin.create.password`)}
              </TableCell>

              <TableCell>
                <MyTextField
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChangePassword}
                  errMg={errMsg.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default UpdateAdminDialog;
