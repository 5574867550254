import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { Box, Container, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { categoryType } from '../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};
const CategoryForm = (props) => {
  const { t } = useTranslation();
  const { onChange, items, errMg } = props;
  console.log('item in the form: ', items);
  return (
    <Container component={BorderBox}>
      <Stack direction="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flexGrow: 1,
            paddingLeft: 2,
            pt: 1,
            ...labelStyle
          }}
        >
          <Typography
            sx={{
              width: 160,
              fontSize: 13,
              color: '#6F869C'
            }}
          >
            {t('pages.product.select-category')}
          </Typography>
        </Stack>
        {/* <Typography
            sx={{
              width: 160,
              fontSize: 13,
              color: '#6F869C'
            }}
          >
            {items?.categoryType}
          </Typography> */}
        <MySelect
          disabled
          name="categoryType"
          defaultValue="first"
          value={items?.categoryType}
          data={categoryType}
          errMg={errMg?.categoryType}
          onChange={(e) => onChange('categoryType', e)}
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            width: '100%',
            m: 2,
            mb: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          inputProps={{ IconComponent: () => null }}
        />
      </Stack>
      {/* <Stack direction="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flexGrow: 1,
            paddingLeft: 2,
            ...labelStyle
          }}
        >
          <Typography
            sx={{
              width: 160,
              fontSize: 13,
              color: '#6F869C'
            }}
          >
            {t('pages.product.code')}
            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
          </Typography>
        </Stack>
        <MyTextField
          disabled={action === 'editCategory'}
          size="small"
          placeholder={t('pages.product.code')}
          value={items?.categoryCode}
          name="categoryCode"
          errMg={errMg?.categoryCode}
          onChange={(e) => onChange('categoryCode', e)}
          sx={inputStyle}
        />
      </Stack> */}
      <Stack direction="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flexGrow: 1,
            paddingLeft: 2,
            pb: 1,
            ...labelStyle
          }}
        >
          <Typography
            sx={{
              width: 160,
              fontSize: 13,
              color: '#6F869C'
            }}
          >
            {t('pages.product.category-name')}
            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
          </Typography>
        </Stack>
        <MyTextField
          size="small"
          placeholder={t('pages.product.category-name')}
          value={items?.categoryName}
          name="categoryName"
          errMg={errMg?.categoryName}
          onChange={(e) => onChange('categoryName', e)}
          sx={{ ...inputStyle, mb: 1 }}
        />
      </Stack>
      {/* <Stack direction="row">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flexGrow: 1,
            paddingLeft: 2,
            pb: 1,
            ...labelStyle
          }}
        >
          <Typography
            sx={{
              width: 160,
              fontSize: 13,
              color: '#6F869C'
            }}
          >
            {t('pages.product.category-eng-name')}
            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
          </Typography>
        </Stack>
        <MyTextField
          size="small"
          placeholder={t('pages.product.category-eng-name')}
          value={items?.categoryNameEng}
          name="categoryNameEng"
          errMg={errMg?.categoryNameEng}
          onChange={(e) => onChange('categoryNameEng', e)}
          sx={{ ...inputStyle, mb: 2 }}
        />
      </Stack> */}
    </Container>
  );
};

export default CategoryForm;
