import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';

import CustomImage from '@components/CustomImage';
import MyDialog from '@components/MyDialog';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import MyTextField from '@components/MyTextField';

import { clientOrderProductDetailColumns as columns } from '@pages/ProductManagement/enhance';
import { formatMoney } from '@utils/helpers';
import { onOpenConfirm } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';

const formatCurrency = (value) => new Intl.NumberFormat('en-CA').format(value);

const OrderProductModificationDialog = ({
  open,
  setOpen,
  pageT,
  item,
  onModify
}) => {
  const [quantity, setQuantity] = useState(item?.orderQuantity || '0');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleModify = async () => {
    setLoading(true);
    await onModify({
      quantity: parseInt(quantity, 10)
    });
    setLoading(false);
  };

  useEffect(() => {
    setQuantity(item?.orderQuantity || '0');
  }, [item]);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      hasCancelButton
      hasCloseButton
      onOk={() => {
        dispatch(
          onOpenConfirm({
            open: true,
            type: 'modify',
            action: () => handleModify()
          })
        );
      }}
      title={pageT('orderProductModification')}
      loading={loading}
    >
      <ZombieTable columns={columns.slice(0, columns.length - 1)}>
        <CustomTableBody>
          <TableRow>
            <TableCell>{item?.id}</TableCell>
            <TableCell>
              <CustomImage fileName={item.imgUrl} fileLink={item.imgUrl} />
            </TableCell>
            <TableCell>{item?.productName}</TableCell>
            <TableCell>
              <MyTextField
                value={formatMoney(quantity)}
                onChange={(e) => setQuantity(e.target.value?.replace(/,/g, ''))}
              />
            </TableCell>
            <TableCell>
              {item?.cost ? formatCurrency(item.cost) : null}
            </TableCell>
            <TableCell>
              {item?.masterPrice ? formatCurrency(item.masterPrice) : null}
            </TableCell>
            <TableCell>{item?.priceType}</TableCell>
            <TableCell>
              {item?.pricePolicyType && formatCurrency(item.pricePolicyType)}
            </TableCell>
            <TableCell>
              {item?.pricePolicyType &&
                formatCurrency(
                  parseInt(quantity.toString().replaceAll('.', ''), 10) *
                    item.pricePolicyType
                )}
            </TableCell>
          </TableRow>
        </CustomTableBody>
      </ZombieTable>
    </MyDialog>
  );
};

export default OrderProductModificationDialog;
