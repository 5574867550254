/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMergeState from '@hooks/useMergeState';
import {
  Box,
  Divider,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Radio
} from '@mui/material';
import { ArrowRight } from '@svg/arrow-right';
import Snackbar from '@mui/material/Snackbar';
import MyDialog from '@components/MyDialog';
import MyTextfield from '@components/MyTextField';
// import { MAX_INT } from '@constants/utils';

import { setError } from '@store/reducers/appReducer';
import { useDispatch } from 'react-redux';
import equipmentServices from '@services/equipment';
// import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';
import MyAutocomplete from '@components/MyAutocomplete';
import { radioOptions } from '../enhance';

const submitStatus = {
  FROM_INUSE_TO_REMAINING: 'from_in_use_to_remaining',
  FROM_INUSE_TO_INUSE: 'from_in_use_to_in_use',
  FROM_REMAINING_TO_INUSE: 'from_remaining_to_in_use'
};

// [IMPROVE]
//  1. Sanckbar after update delete
//  2. errorMessages on update
//  3. disable delete button based on isDeletable key
//  4. style tooltip add korean message
//  5. seperate table body to seperate component
//  6. update the list after successful delete add and update
const EqmQuantityModifierDialog = ({
  open,
  setOpen,
  onAction,
  equipmentCode = 'minieqp64099afe00295'
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [equipmentData, setEquipmentData] = useState([]);
  const [selectsOptions, setSelectOptions] = useState({ from: [], to: [] });
  const [state, setState] = useState({
    open: false,
    equipmentCode: '',
    from: '',
    to: '',
    radioStatus: submitStatus.FROM_INUSE_TO_INUSE,
    remainingQuantity: 0,
    modifyQuantity: 0
  });
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useMergeState({
    equipmentCode: '',
    modifyQuantity: '',
    fromStatusCode: '',
    toStatusCode: '',
    radioStatus: ''
  });

  const handleQuantityChange = (value) => {
    let inputAmount = parseInt(value?.replaceAll(',', '') || 0, 10);
    let MAX_INT = 0;
    switch (state.radioStatus) {
      case submitStatus.FROM_INUSE_TO_INUSE:
        MAX_INT = parseInt(equipmentData?.from?.equipmentStateQuantity, 10);
        inputAmount = Math.min(inputAmount, MAX_INT);
        setState({
          ...state,
          modifyQuantity: inputAmount,
          from: {
            ...state.from,
            equipmentStateQuantity:
              equipmentData.from.equipmentStateQuantity - inputAmount
          },
          to: {
            ...state.to,
            equipmentStateQuantity:
              equipmentData.to.equipmentStateQuantity + inputAmount
          }
        });

        break;

      case submitStatus.FROM_INUSE_TO_REMAINING:
        MAX_INT = parseInt(equipmentData?.from?.equipmentStateQuantity, 10);
        inputAmount = Math.min(inputAmount, MAX_INT);
        setState({
          ...state,
          modifyQuantity: inputAmount,
          from: {
            ...state.from,
            equipmentStateQuantity:
              equipmentData.from.equipmentStateQuantity - inputAmount
          },
          remainingQuantity: equipmentData.remainingQuantity + inputAmount
        });

        break;

      case submitStatus.FROM_REMAINING_TO_INUSE:
        MAX_INT = parseInt(equipmentData?.remainingQuantity, 10);
        inputAmount = Math.min(inputAmount, MAX_INT);
        setState({
          ...state,
          modifyQuantity: inputAmount,
          to: {
            ...state.to,
            equipmentStateQuantity:
              equipmentData.to.equipmentStateQuantity + inputAmount
          },
          remainingQuantity: equipmentData.remainingQuantity - inputAmount
        });

        break;

      default:
        break;
    }
  };
  const handleChangeFromTo = (value, direction) => {
    if (direction === 'from') {
      setState({ ...state, from: value });
      setEquipmentData({ ...equipmentData, from: value });
    } else {
      setState({ ...state, to: value });
      setEquipmentData({ ...equipmentData, to: value });
    }
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  const handleChangeRadio = (event, value) => {
    if (!value) return;
    setState({ ...state, radioStatus: value });
  };

  const handleUpdate = async () => {
    if (!state.equipmentCode) return;
    setLoading(true);
    let rs;
    let submitData;
    switch (state.radioStatus) {
      case submitStatus.FROM_INUSE_TO_INUSE:
        submitData = {
          equipmentTransQuantity: state?.modifyQuantity,
          equipmentStatusCodeTo: state?.to?.equipmentStatusCode
        };
        rs = await equipmentServices.equipmentQuantityStatusChange(
          {
            equipmentCode: state.equipmentCode,
            fromStatusCode: state.from?.equipmentStatusCode
          },
          submitData
        );
        break;
      case submitStatus.FROM_INUSE_TO_REMAINING:
        submitData = {
          equipmentTransQuantity: state?.modifyQuantity,
          equipmentStatusCodeFrom: state?.from?.equipmentStatusCode
        };
        rs = await equipmentServices.UpdateEqmStatusQuantityToRemaining(
          {
            equipmentCode: state.equipmentCode
          },
          submitData
        );
        break;
      case submitStatus.FROM_REMAINING_TO_INUSE:
        submitData = {
          equipmentTransQuantity: state?.modifyQuantity,
          equipmentStatusCodeTo: state?.to?.equipmentStatusCode
        };
        rs = await equipmentServices.UpdateEqmStatusQuantityFromRemaining(
          {
            equipmentCode: state.equipmentCode
          },
          submitData
        );
        break;

      default:
        break;
    }

    if (rs?.error && rs?.error?.fieldErrors) {
      dispatch(setError(error2Text(rs.error)));
    } else {
      setState({ ...state, open: true });
      onAction('success');
      setOpen(false);
      setLoading(false);
    }
  };
  const getEquipmentStatusDetail = async () => {
    const rs = await equipmentServices.getEquipmentStatusDetail(equipmentCode);
    if (!rs?.error) {
      const eqm = rs?.data;
      if (!eqm) return;
      const remaining =
        parseInt(eqm.equipmentStock, 10) -
        eqm.stateOfEquipments.reduce(
          (sum, item) => sum + parseInt(item?.equipmentStateQuantity, 10),
          0
        );

      const selectOptions = eqm.stateOfEquipments.map((eqm) => ({
        ...eqm,
        value: eqm.equipmentStatusCode,
        label: eqm.equipmentStatusName
      }));

      setSelectOptions({
        ...selectOptions,
        from: selectOptions,
        to: selectOptions
      });
      setEquipmentData({ ...eqm, remainingQuantity: remaining } || []);
      setState({ ...state, equipmentCode, remainingQuantity: remaining });
    } else {
      dispatch(setError(error2Text(rs.error)));
    }
  };

  useEffect(() => {
    getEquipmentStatusDetail();
  }, []);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.equipment.modal.modifyUsageTitle')}
      hasCloseButton
      hasCancelButton
      loading={loading}
      setLoading={setLoading}
      onOk={handleUpdate}
      okTitle={t('button.save')}
      sx={{
        minWidth: 600,
        '*::-webkit-scrollbar': {
          width: '0px'
        }
      }}
    >
      {/* conatiner */}
      <Box>
        {/* checkbox container */}
        <Stack>
          <Stack gap={1}>
            <Typography sx={{ fontSize: 17, fontWeight: 400 }}>
              {t('pages.equipment.modificationType')}
            </Typography>
          </Stack>
          <Stack my={1}>
            {/* need to edit */}
            <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                label=""
                labelPlacement="start"
                sx={{
                  '.MuiTypography-root': {
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    mr: 1
                  }
                }}
                control={
                  <Stack flexDirection="row" ml={1}>
                    {radioOptions.map(({ value, label }) => (
                      <FormControlLabel
                        control={
                          <Radio
                            checked={value === state?.radioStatus}
                            value={value}
                            onChange={(e) => handleChangeRadio(e, value)}
                          />
                        }
                        label={label}
                      />
                    ))}
                  </Stack>
                }
              />
            </FormControl>
          </Stack>
        </Stack>

        <Divider variant="fullWidth" />

        {/* remaining equipments container */}
        <Stack my={2} gap={1}>
          {/* label */}
          <Stack>
            <Typography sx={{ fontSize: 17, fontWeight: 400 }}>
              {t('pages.equipment.remainingEqm')}
            </Typography>
          </Stack>
          {/* text field */}
          <Stack px={1}>
            <MyTextfield
              textFieldStyle={{
                '.MuiInputBase-root': {
                  bgcolor: '#f2f2f2'
                },
                width: 'auto'
              }}
              value={(state?.remainingQuantity && state.remainingQuantity) || 0}
              disabled
            />
          </Stack>
        </Stack>

        <Divider variant="fullWidth" sx={{ borderBottomWidth: 1 }} />

        {/*  in use Container */}
        <Stack my={2}>
          <Stack>
            <Typography sx={{ fontSize: 17, fontWeight: 400 }}>
              {t('pages.equipment.modificationType')}
            </Typography>
          </Stack>
          {/* direction container */}
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <Stack sx={{ flex: 0.5 }}>
              <Stack>
                <Typography
                  sx={{
                    ml: 0.5,
                    fontSize: 12,
                    mt: 1,
                    mb: 0.5,
                    color: '#979797'
                  }}
                >
                  from
                </Typography>
              </Stack>
              <Stack gap={1}>
                <Stack>
                  {state.radioStatus ===
                  submitStatus.FROM_REMAINING_TO_INUSE ? (
                    <MyTextfield
                      textFieldStyle={{
                        '.MuiInputBase-root': {
                          bgcolor: '#f2f2f2'
                        },
                        width: 180
                      }}
                      value={t('pages.equipment.remainingEqm')}
                      disabled
                    />
                  ) : (
                    <MyAutocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.equipmentStatusCode ===
                        value?.equipmentStatusCode
                      }
                      //   textFieldProps={textFieldProps}
                      getOptionLabel={(option) =>
                        option?.equipmentStatusName || option
                      }
                      onChange={(e, val) => handleChangeFromTo(val, 'from')}
                      value={(state.from && state.from) || ' '}
                      options={selectsOptions?.from}
                      sx={{ width: 180 }}
                    />
                  )}
                </Stack>
                <MyTextfield
                  textFieldStyle={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    },
                    width: 'auto'
                  }}
                  value={
                    state?.radioStatus === submitStatus.FROM_REMAINING_TO_INUSE
                      ? state?.remainingQuantity
                      : state.from?.equipmentStateQuantity || 0
                  }
                  disabled
                />
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ArrowRight />
            </Stack>
            <Stack sx={{ flex: 0.5 }}>
              <Stack sx={{ flex: 0.5 }}>
                <Stack>
                  <Typography
                    sx={{
                      ml: 0.5,
                      fontSize: 12,
                      mt: 1,
                      mb: 0.5,
                      color: '#979797'
                    }}
                  >
                    to
                  </Typography>
                </Stack>
                <Stack gap={1}>
                  <Stack>
                    {state.radioStatus === radioOptions[2].value ? (
                      <MyTextfield
                        textFieldStyle={{
                          '.MuiInputBase-root': {
                            bgcolor: '#f2f2f2'
                          },
                          width: 180
                        }}
                        value={t('pages.equipment.remainingEqm')}
                        disabled
                      />
                    ) : (
                      <MyAutocomplete
                        isOptionEqualToValue={(option, value) =>
                          option?.equipmentStatusCode ===
                          value?.equipmentStatusCode
                        }
                        //   textFieldProps={textFieldProps}
                        getOptionLabel={(option) =>
                          option?.equipmentStatusName
                            ? option.equipmentStatusName
                            : option
                        }
                        onChange={(e, val) => handleChangeFromTo(val, 'to')}
                        value={(state.to && state.to) || ' '}
                        options={selectsOptions?.to}
                        sx={{ width: 180 }}
                      />
                    )}
                  </Stack>
                  <MyTextfield
                    textFieldStyle={{
                      '.MuiInputBase-root': {
                        bgcolor: '#f2f2f2'
                      },
                      width: 'auto'
                    }}
                    value={
                      state.radioStatus === submitStatus.FROM_INUSE_TO_REMAINING
                        ? state?.remainingQuantity
                        : state?.to?.equipmentStateQuantity || 0
                    }
                    disabled
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack my={2} gap={0.5}>
            <Stack>
              <Typography fontSize={12} ml={0.5} sx={{ color: '#979797' }}>
                {t('pages.equipment.modificationType')}
              </Typography>
            </Stack>
            <Stack>
              <MyTextfield
                sx={{ width: 'auto' }}
                value={state?.modifyQuantity}
                onChange={(e) => handleQuantityChange(e.target.value)}
                disabled={
                  state.radioStatus === submitStatus.FROM_INUSE_TO_INUSE
                    ? !(state.from && state.to)
                    : !(state.from || state.to)
                }
                errMsg={errMsg.modifyQuantity}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Snackbar
        open={state.open}
        onClose={handleClose}
        message="I love snacks"
        key="keyyy"
      />
    </MyDialog>
  );
};

export default EqmQuantityModifierDialog;
