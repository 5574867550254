/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import HelmetContainer from '@components/HelmetContainer';

import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import MyDialog from '@components/MyDialog';
import { useTranslation } from 'react-i18next';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useDispatch } from 'react-redux';
import MyTextField from '@components/MyTextField';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { dateFormat, scopes } from '@constants/utils';
import {
  compareDates,
  formatMoney,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import MySelect from '@components/MySelect';
import useAuthorization from '@hooks/useAuthorization';
import Calendar from '@components/Calendar';
import { Formatter } from '@utils/formatter';
import storingService from '@services/storing';
import SearchBar from '@components/SearchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { inventorySearchSelectOptions } from '@pages/InventoryManagement/Warehousing (입고)/enhance';
import DateRangePicker from '@components/DateRangePicker';
import LocationAutoComplete from './LocationAutoComplete';
import MyTable from '../../Warehouse/components/NewTable/GroupTableCustomize';
import { storingColumns, storingStatuses, radioOptions } from '../enhance';

// TODO:
// 1.2  quantity > errMsg on 0
//      location search field locationStatusUpdate
// 1.3 delete
// 2. Add Product
//  validation errMsg
// 3. update list Status
// 4. excel file save
// 4. test

const WarehouseDisplay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [selectOptionSearch, setSelectOptionSearch] = useState({
    key: 'productName',
    value: ''
  });
  const location = useLocation();
  const { formatTime } = Formatter;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [param, setParam] = useState({
    page: 0,
    productName: '',
    barcode: '',
    status: 'all',
    returnYn: 'Y'
  });
  const [items, setItems] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingPop, setLoadingPop] = useState(false);
  const [loading, setLoading] = useState({
    excelDownload: false,
    registerAllItems: false
  });
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const { pagination } = state;

  const onListenEventTable = (action, item) => {
    switch (action) {
      case 'expDate':
        setSelected({
          ...selected,
          open: true,
          action,
          item,
          title: `${t('pages.inventory.shelfLife')} ${t(
            'pages.inventory.modify'
          )}`
        });
        setSelectedItem(item);
        break;
      case 'quantity':
        setSelected({
          ...selected,
          open: true,
          action,
          item,
          title: `${t('pages.inventory.quantity')} ${t(
            'pages.inventory.modify'
          )}`
        });
        setSelectedItem(item);
        break;
      case 'locationModify':
      case 'locationRegister':
        setSelected({
          ...selected,
          open: true,
          action,
          item,
          title: `${t('pages.inventory.location')} ${t(
            `button.${action === 'locationRegister' ? 'register' : 'modify'}`
          )}`
        });
        setSelectedItem(item);
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line consistent-return
  const stockQuantityComponent = () => {
    switch (selected?.action) {
      case 'expDate':
        return (
          <Calendar
            // eslint-disable-next-line no-unneeded-ternary
            date={
              selectedItem?.expiryDate
                ? new Date(selectedItem?.expiryDate)
                : new Date()
            }
            sx={{
              inputStyle: {
                width: '100%'
              }
            }}
            setDate={(e) =>
              setSelectedItem({
                ...selectedItem,
                expiryDate: Formatter.formatTime(e, 'YYYY-MM-DD')
              })
            }
          />
        );

      case 'quantity':
        return (
          <MyTextField
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                quantity: +e.target.value.replace(/,/g, '')
              })
            }
            value={formatMoney(selectedItem?.quantity || 0)}
          />
        );

      case 'locationModify':
      case 'locationRegister':
        return (
          <LocationAutoComplete
            location={{
              locationCode: selectedItem?.locationCode,
              locationName: selectedItem?.locationName
            }}
            setLocation={(val) =>
              setSelectedItem({
                ...selectedItem,
                locationCode: val.locationCode,
                locationName: val.locationName
              })
            }
            sx={{ width: 200 }}
          />
        );
      default:
        break;
    }
  };

  const configColumns = storingColumns(onListenEventTable);
  const getActionButtons = (item) => (
    <IconButton
      size="small"
      disabled={[storingStatuses.registered, storingStatuses.stored].includes(
        item?.currentStoringStatus
      )}
      onClick={(e) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        setSelectedItem(item);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
  const getStockingList = async (dataParams) => {
    setLoadingSearch(false);
    const rs = await storingService.getStoringList({
      ...dataParams
    });
    if (!rs.error) {
      setItems(rs?.data.items || []);
      setState({
        ...state,
        items: rs?.data.items,
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
    setLoadingSearch(false);
  };

  const handleModifyStock = async () => {
    setLoadingPop(true);
    let rs;
    switch (selected.action) {
      case 'expDate':
        rs = await storingService.updateStoringExpDate(
          selectedItem?.storingCode,
          {
            expiryDate: selectedItem?.expiryDate
          }
        );
        break;

      case 'quantity':
        rs = await storingService.updateQuantity(selectedItem?.storingCode, {
          quantity: selectedItem?.quantity || 0
        });
        break;
      case 'locationModify':
      case 'locationRegister':
        rs = await storingService.updateStoringLocation(
          selectedItem?.storingCode,
          {
            locationCode: selectedItem?.locationCode
          }
        );
        break;

      default:
        break;
    }
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getStockingList();
      dispatch(setSuccess({ message: t('info.success.content') }));
      setSelected({ ...selected, open: false });
    }
    setLoadingPop(false);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { startDate, endDate, page, productName, barcode, status, returnYn } =
      query;

    const firstDayOfMonth = `${formatTime(new Date(), 'YYYY-MM')}-01`;
    const dataParams = {
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      barcode: barcode || null,
      status: status || 'all',
      returnYn: returnYn || 'Y',
      page: page - 1 || 0
    };
    setParam(dataParams);
    getStockingList(dataParams);
  }, [location?.search]);

  const deleteStocking = async () => {
    const rs = await storingService.updateStoringItemStatus(
      selectedItem?.storingCode,
      {
        status: storingStatuses.rejected
      }
    );
    if (rs?.error) {
      setSelected({ ...selected, open: false });
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getStockingList();
      dispatch(setSuccess({ message: t('info.delete.content') }));
      setOpenConfirmDialog(false);
    }
  };

  const handleOnChangePage = (value) => {
    setParam({ ...param, page: value });
    const query = objToParams(
      ignoreEmpty({
        ...param,
        page: value + 1
      })
    );
    navigate(query);
  };

  const exportStoringItems = async () => {
    setLoading({ ...loading, excelDownload: true });
    const rs = await storingService.exportStoringItems({
      status: param?.status || 'all'
    });
    setLoading({ ...loading, excelDownload: false });
  };
  const handleSearch = () => {
    const { startDate, endDate, status, productName, barcode, returnYn } =
      param;
    const dataParams = {
      startDate,
      endDate,
      status,
      barcode,
      productName,
      returnYn
    };
    const query = objToParams(
      ignoreEmpty({
        ...dataParams,
        page: 1
      })
    );
    navigate(query);
  };
  const onAction = async (action, value) => {
    if (action === 'searchTyping') {
      setSelectOptionSearch({
        key: value.target.name,
        value: value.target.value
      });
      setParam({
        ...param,
        [value.target.name]: value.target.value || null
      });
    }
    if (action === 'selectMinibar') {
      setParam({
        ...param,
        minibarCode: value?.minibarCode || null
      });
    }
    if (action === 'selectSearchType') {
      setSelectOptionSearch({
        key: value.target.value,
        value: ''
      });
      setParam({
        ...param,
        phone: null,
        productName: null,
        totalAmount: null,
        barcode: null
      });
    }
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.warehouseDisplay')}
      content="Storing Page"
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mt: -1,
          mb: 2
        }}
      >
        <Stack>
          <IconButton
            onClick={() => navigate(`/inventory-management/warehouse-display`)}
            sx={{
              ':hover': { backgroundColor: 'transparent' },
              display: 'flex'
            }}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <ArrowBackIosNewIcon
              sx={{ height: '20px', color: 'black', fontWeight: 500 }}
            />
            <Typography sx={{ fontSize: 20, color: 'black', fontWeight: 500 }}>
              {t('pageTitle.restock')}
            </Typography>
          </IconButton>
        </Stack>
      </Stack>
      <SearchBar sx={{ mt: -1, p: 1 }}>
        <MySelect
          label={t('labels.condition')}
          defaultValue="productName"
          value={selectOptionSearch?.key}
          data={inventorySearchSelectOptions}
          onChange={(e) => onAction('selectSearchType', e)}
          sx={{ mr: 1.5 }}
        />
        <MyTextField
          name={selectOptionSearch.key}
          value={selectOptionSearch.value}
          onChange={(e) => onAction('searchTyping', e)}
          placeholder={t('button.search')}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Stack flexDirection="row" alignItems="center">
          <FormControl>
            <FormControlLabel
              label={t('labels.period')}
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  whiteSpace: 'nowrap',
                  mr: 1,
                  width: 60
                }
              }}
              control={
                <DateRangePicker
                  state={{
                    startDate: new Date(param.startDate),
                    endDate: new Date(param.endDate),
                    key: 'selection'
                  }}
                  setState={(e) => onAction('selectPeriod', e)}
                />
              }
            />
          </FormControl>
          <FormControlLabel
            label={t('pages.inventory.today')}
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap'
              },
              ml: 2
            }}
            control={
              <Radio
                checked={
                  !compareDates(param.startDate, param.endDate) &&
                  !compareDates(
                    param.startDate,
                    formatTime(new Date(), dateFormat)
                  )
                }
                onChange={() =>
                  setParam({
                    ...param,
                    startDate: Formatter.formatTime(new Date(), dateFormat),
                    endDate: Formatter.formatTime(new Date(), dateFormat)
                  })
                }
                size="small"
              />
            }
          />
        </Stack>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 0.5,
                width: 50
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {radioOptions.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({
                            ...param,
                            status: e.target.value
                          })
                        }
                        checked={param.status === item.value}
                        size="small"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <Button
          disabled={loadingSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={() => handleSearch()}
        >
          {loadingSearch ? <CircularProgress size={20} /> : t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
          {' '}
        </Stack>
      </Stack>
      <MyTable
        hover
        columns={configColumns}
        pagination={pagination}
        data={items}
        getActionButtons={getActionButtons}
        minusHeight={230}
        onChangePage={(e, page) => handleOnChangePage(page)}
      />

      {/* dialogs */}

      <MyDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        isAlert
        title={t('dialog.title.areYouSureDeleteThisRecord')}
        hasCancelButton
        onOk={deleteStocking}
      />
      {selected?.action === 'notAccepted' && (
        <MyDialog
          open={selected.open}
          setOpen={() => setSelected({ ...selected, open: false })}
          isAlert
          title={`    `}
          subTitle={t('pages.inventory.registerFail')}
          onOk={() => setSelected({ ...selected, open: false })}
        />
      )}
      {['expDate', 'quantity', 'locationModify', 'locationRegister'].includes(
        selected?.action
      ) && (
        <MyDialog
          open={selected?.open}
          setOpen={
            selected?.open
              ? () => setSelected({ ...selected, open: false })
              : () => {}
          }
          title={selected?.title}
          hasCloseButton
          hasCancelButton
          onOk={handleModifyStock}
          disabled={!isAuthorized}
          loading={loadingPop}
          setLoading={setLoadingPop}
        >
          <Stack direction="row" gap={2} sx={{ mt: 1 }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                flexGrow: 1,
                paddingLeft: 2,
                pb: 0.5
              }}
            >
              <Typography
                sx={{
                  fontSize: 13,
                  color: '#6F869C',
                  whiteSpace: 'nowrap'
                }}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  selected?.action === 'expDate'
                    ? t('pages.inventory.shelfLife')
                    : selected?.action === 'quantity'
                    ? t('pages.inventory.display-quantity')
                    : t('pages.inventory.location')
                }
                <span style={{ color: 'red', marginLeft: 5 }}>*</span>
              </Typography>
            </Stack>
            {/* to do conditionally render for quantity and expDate */}
            {stockQuantityComponent()}
          </Stack>
        </MyDialog>
      )}
    </HelmetContainer>
  );
};

export default WarehouseDisplay;
