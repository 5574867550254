import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';

import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';

import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import SearchBar from '@components/SearchBar';
import { sub } from 'date-fns';
import { dateFormat } from '@constants/utils';
import displayService from '@services/display';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import DisplayTable from './DisplayTable';
import { conditionList } from './enhance';

const Display = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    clientCode: null,
    minibarCode: null,
    clientName: null,
    picName: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });

  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);
  const [condition, setCondition] = useState(conditionList[1].value);
  const keywordRef = useRef();

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getDisplayList({ ...param, page });
    navigate(query);
  };

  const handleSearch = () => {
    const dataParam = {
      ...param,
      clientCode: client?.clientCode,
      minibarCode: minibar?.minibarCode,
      [condition]: keywordRef.current?.value || null
    };
    const query = objToParams(ignoreEmpty({ ...dataParam, page: 1 }));
    getDisplayList({ ...dataParam, page: 0 });
    navigate(query);
  };
  const [loading, setLoading] = useState(false);
  const handleDownloadExcel = async () => {
    setLoading(true);
    const rs = await displayService.exportDisplay(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const getDisplayList = async (param) => {
    const rs = await displayService.getDisplayList(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      clientCode,
      minibarCode,
      page,
      clientName,
      picName
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      clientCode: clientCode || null,
      minibarCode: minibarCode || null,
      page: page - 1 || 0,
      clientName,
      picName
    };
    setClient({ ...client, clientCode: query?.clientCode || null });
    setMinibar({ ...minibar, minibarCode: minibarCode || null });
    setCondition((clientName && 'clientName') || 'picName');
    keywordRef.current.value = clientName || picName || '';
    setParam(dataParams);
    getDisplayList(dataParams);
  }, [location?.search]);

  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };

  return (
    <HelmetContainer title={t('pageTitle.display')} content="Display Page">
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('common.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>
        <ClientAutoCompleteV2
          setClient={setClient}
          client={client}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={setMinibar}
          client={client}
          disabled={!client?.clientCode}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Stack flexDirection="row" gap={1}>
          <MySelect
            label={t('common.condition')}
            value={condition}
            data={conditionList}
            onChange={(e) => setCondition(e.target.value)}
            sx={{ mr: 1.5 }}
          />
          <Stack flex={1} sx={{ minWidth: 300, maxWidth: 600 }}>
            <MyTextField
              placeholder={t('common.input-keyword')}
              ref={keywordRef}
              autoFocus
              onKeyDown={(e) => {
                if (e.isComposing || e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
          </Stack>
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Stack direction="row" spacing={1}>
          <Button
            disabled={state?.items?.length <= 0 || loading}
            variant="outlined"
            onClick={handleDownloadExcel}
            startIcon={loading && <CircularProgress size="20px" />}
          >
            {t('button.downloadExcel')}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {state?.pagination?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <DisplayTable
        data={state?.items || []}
        pagination={state?.pagination}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default withTranslation(null, { keyPrefix: 'pages.display' })(Display);
