import RestService from '@services/RestService';

const locationService = {
  async getLocationList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/location')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getLocationListAll() {
    try {
      const res = await new RestService().setPath('/location/all').get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async searchProduct(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/product/by-filter')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getLocationrDetail(locationCode) {
    try {
      const res = await new RestService()
        .setPath(`/location/${locationCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createLocation(data) {
    try {
      const res = await new RestService().setPath('/location').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editLocation(locationCode, data) {
    try {
      const res = await new RestService()
        .setPath(`location/${locationCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateLocationUseYn(locationCode, data) {
    try {
      const res = await new RestService()
        .setPath(`location/${locationCode}/use`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async removeProductFromLocation(locationCode) {
    try {
      const res = await new RestService()
        .setPath(`location/${locationCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default locationService;
