import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const common = i18n.getFixedT(null, null, 'common');
const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages.homepage');
const devStatus = i18n.getFixedT(null, null, 'enum.dev-request-status');

export const columns = (data) => [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'title',
    label: t('news-subject'),
    minWidth: 310,
    align: 'center'
  },
  {
    name: 'publishedDate',
    label: t('news-reg-dt'),
    minWidth: 150,
    align: 'center',
    render: ({ publishedDate }) =>
      publishedDate && Formatter.formatTime(publishedDate, 'YYYY-MM-DD')
  },
  {
    name: 'url',
    label: t('news-link'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'homepageYn',
    label: `${t('include-news-homepage')} (${
      data?.totalDisplayedMedia || 0
    }/4)`,
    minWidth: 150,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    name: 'delete',
    label: g('button.delete'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      minWidth: 120,
      maxWidth: 180
    },
    td: {
      p: '10px 12px',
      minWidth: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const initStatusList = [
  { label: common('all'), value: 'All' },
  { label: devStatus('Submit'), value: 'Submit' },
  { label: devStatus('Reviewed'), value: 'Reviewed' },
  { label: devStatus('InProgress'), value: 'InProgress' },
  { label: devStatus('Completed'), value: 'Completed' },
  { label: devStatus('Canceled'), value: 'Canceled' }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
