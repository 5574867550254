import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';

import MyLoadingButton from '@components/MyLoadingButton';
import CustomImage from '@components/CustomImage';
import MyDialog from '@components/MyDialog';
import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import NoData from '@components/NoData';

import clientOrderServices from '@services/clientOrder';

import { PencilIcon } from '@svg/pencil';
import { TrashIcon } from '@svg/trash';
import { setError } from '@store/reducers/appReducer';
import { Formatter } from '@utils/formatter';
import error2Text from '@utils/error2Text';

import { clientOrderProductDetailColumns as columns } from '@pages/ProductManagement/enhance';
import OrderProductModificationDialog from './OrderProductModificationDialog';

const formatCurrency = (value) => new Intl.NumberFormat('en-CA').format(value);

const OrderProductTableRow = ({
  index,
  item,
  handleDeleteRow,
  pageT,
  fetchData,
  disabled
}) => {
  const { clientOrderId } = useParams();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [openModify, setOpenModify] = useState(false);

  const handleModifyProduct = async (data) => {
    const result = await clientOrderServices.updateOrderProduct(
      clientOrderId,
      item?.productCode,
      data
    );
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      fetchData();
      setOpenModify(false);
    }
  };

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <CustomImage fileName={item.imgUrl} fileLink={item.imgUrl} />
      </TableCell>
      <TableCell>{item?.productName || ''}</TableCell>
      <TableCell>
        {item?.orderQuantity && Formatter.fNumber(item.orderQuantity)}
      </TableCell>
      <TableCell>
        <Typography
          sx={{ color: parseInt(item?.stockQuantity, 10) === 0 && 'red' }}
        >
          {item?.stockQuantity && Formatter.fNumber(item.stockQuantity)}
        </Typography>
      </TableCell>
      <TableCell>{item?.cost && formatCurrency(item.cost)}</TableCell>
      <TableCell>
        {item?.masterPrice && formatCurrency(item.masterPrice)}
      </TableCell>
      <TableCell>{item?.priceType || ''}</TableCell>
      <TableCell>
        {item?.pricePolicyType && formatCurrency(item.pricePolicyType)}
      </TableCell>
      <TableCell>
        {item?.totalPrice && formatCurrency(item.totalPrice)}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton
            sx={{ bgcolor: 'primary.lighter' }}
            onClick={() => setOpenModify(true)}
            disabled={disabled}
          >
            <PencilIcon />
          </IconButton>

          <IconButton
            sx={{ bgcolor: 'background.grey' }}
            onClick={() => setOpen(true)}
            disabled={disabled}
          >
            <TrashIcon />
          </IconButton>
        </Stack>
      </TableCell>
      {open && (
        <MyDialog
          open={open}
          setOpen={setOpen}
          isAlert
          title={t('common.confirm-delete-title', {
            item: item?.productName || ''
          })}
          hasCancelButton
          onOk={() => {
            handleDeleteRow(item);
            setOpen(false);
          }}
        />
      )}
      {openModify && (
        <OrderProductModificationDialog
          open={openModify}
          setOpen={setOpenModify}
          item={item}
          pageT={pageT}
          onModify={handleModifyProduct}
        />
      )}
    </TableRow>
  );
};

const OrderProductDetailArea = ({
  data,
  pageT,
  handleDeleteRow,
  fetchData,
  disabled
}) => {
  const dispatch = useDispatch();
  const { clientOrderId } = useParams();

  const handlePrintTransaction = async () => {
    const params = { orderCode: clientOrderId };
    const res = await clientOrderServices.downloadOrderSheet(params);
    if (res?.error) {
      dispatch(setError(error2Text(res.error)));
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3.75, mb: 2.5 }}
      >
        <Typography
          sx={{
            fontWeight: 'fontWeightBold',
            color: 'text.primary',
            fontSize: 16
          }}
        >
          {pageT('orderProductDetail')}
        </Typography>
        <MyLoadingButton
          variant="outlined"
          disabled={data?.length < 1}
          onClick={handlePrintTransaction}
        >
          {pageT('printTransactionStatement')}
        </MyLoadingButton>
      </Stack>
      <ZombieTable columns={columns} position="relative">
        <CustomTableBody>
          {data?.length > 0 ? (
            data.map((item, index) => (
              <OrderProductTableRow
                key={item.id}
                index={index}
                item={item}
                handleDeleteRow={handleDeleteRow}
                pageT={pageT}
                fetchData={fetchData}
                disabled={disabled}
              />
            ))
          ) : (
            <TableRow sx={{ height: 200 }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                component="td"
              >
                <NoData />
              </Box>
            </TableRow>
          )}
        </CustomTableBody>
      </ZombieTable>
    </Box>
  );
};

export default OrderProductDetailArea;
