import { IconButton, Stack } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React from 'react';

const Button2in1 = ({ row, onOk, onCancel }) => {
  return (
    <Stack flexDirection={row ? 'row' : 'columns'} alignItems="center">
      <IconButton onClick={onCancel} sx={{ width: 25, height: 25 }}>
        <CancelOutlinedIcon
          sx={{
            color: 'red',
            '&:hover': { fontSize: 30, transition: '0.2s' }
          }}
        />
      </IconButton>
      <IconButton onClick={onOk} sx={{ width: 25, height: 25 }}>
        <CheckCircleOutlinedIcon
          sx={{
            color: 'green',
            '&:hover': {
              fontSize: 30,
              transition: '0.2s'
            }
          }}
        />
      </IconButton>
    </Stack>
  );
};

export default Button2in1;
