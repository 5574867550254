/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */

import { Typography } from '@mui/material';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.inventory');
const p = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

export const storingColumns = () => [
  {
    name: 'regDt',
    label: t('date'),
    minWidth: 120,
    align: 'center',

    render: ({ regDt }) => regDt && Formatter.formatTime(regDt)
  },
  {
    name: 'locationName',
    label: t('location'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'barcode',
    label: p('bar-code'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'productName',
    label: p('product-name'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'expiryDate',
    label: t('shelfLife'),
    minWidth: 160,
    align: 'center'
    // render: (item) => [storingStatuses.registered, storingStatuses.stored].includes(item?.currentStoringStatus) ? item?.expiryDate : <Typography onClick={(e) => onEvent('expDate', item, e)} sx={{ textDecoration: 'underline', ':hover': { cursor: 'pointer' } }}>{item?.expiryDate}</Typography>
  },
  {
    name: 'quantity',
    label: t('display-quantity'),
    minWidth: 80,
    align: 'center'
    // render: (item) => componentRender.stockQuantityComponent(item)
    // render: (item) =>[storingStatuses.registered, storingStatuses.stored].includes(item?.currentStoringStatus) ? item?.quantity : <Typography onClick={(e) => onEvent('quantity', item,e)} sx={{ textDecoration: 'underline', ':hover': { cursor: 'pointer' } }}>{item?.quantity}</Typography>
  },
  {
    name: 'registerAdmin',
    label: g('pages.picking.registerAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'modifyAdmin',
    label: g('pages.picking.modifyAdmin'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'purchasedType',
    label: g('pages.display.status'),
    minWidth: 100,
    align: 'center',
    render: (item) => {
      let formattedStatus;
      switch (item?.currentStoringStatus) {
        case storingStatuses.registered:
          formattedStatus = t('storingStatuses.registered');
          break;
        case storingStatuses.requested:
          formattedStatus = t('storingStatuses.requested');
          break;
        case storingStatuses.stored:
          formattedStatus = t('storingStatuses.stored');
          break;
        case storingStatuses.inspection:
          formattedStatus = t('storingStatuses.inspection');
          break;
        default:
          formattedStatus = item?.currentStoringStatus;
          break;
      }
      return <Typography>{formattedStatus}</Typography>;
    }
  }
];

export const storingStatuses = {
  registered: 'REGISTERED',
  requested: 'REQUESTED',
  stored: 'STORED',
  rejected: 'REJECTED',
  inspection: 'INSPECTION'
};

export const radioOptions = [
  { value: 'all', label: t('storingStatuses.all') },
  { value: storingStatuses.registered, label: t('storingStatuses.registered') },
  { value: storingStatuses.inspection, label: t('storingStatuses.inspection') },
  { value: storingStatuses.stored, label: t('storingStatuses.stored') }
];
