import { Formatter } from '@utils/formatter';
import { formatMoney } from '@utils/helpers';

const { formatTime } = Formatter;

const rerender = (item, value) => {
  return (
    <span
      style={{
        textDecoration: item.refund === 'Y' ? 'line-through' : '',
        color: item.refund === 'Y' && 'gray'
      }}
    >
      {value}
    </span>
  );
};

export const columnMemberOrderHistory = (t) => [
  {
    id: 0,
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    isOpen: true,
    render: (item, index) => rerender(item, index)
  },
  {
    id: 1,
    name: 'regDate',
    isOpen: true,
    label: t('common.paymentTime'),
    minWidth: 120,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatTime(item?.regDate))
  },
  {
    id: 2,
    name: 'clientName',
    label: t('common.client'),
    minWidth: 160,
    align: 'center',
    isOpen: true,
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.clientName)
  },
  {
    id: 3,
    name: 'minibarNickname',
    label: t('common.minibar'),
    minWidth: 120,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    isOpen: true,
    render: (item) => rerender(item, item?.minibarNickname || item?.minibarCode)
  },
  {
    id: 4,
    label: t('common.member'),
    minWidth: 80,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    isOpen: true,
    render: (item) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {rerender(item, item?.memberName || Formatter.fKrPhone(item?.phone))}
      </span>
    )
  },
  {
    id: 5,
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    isOpen: true,

    render: (item) => rerender(item, item?.productName)
  },
  {
    id: 6,
    name: 'categoryName1',
    label: `${t('pages.curation.1st-cat')}`,
    minWidth: 120,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.categoryName1)
  },
  {
    id: 7,
    name: 'categoryName2',
    label: `${t('pages.curation.2nd-cat')}`,
    minWidth: 120,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.categoryName2)
  },
  {
    id: 8,
    name: 'categoryName3',
    label: `${t('pages.curation.3rd-cat')}`,
    minWidth: 120,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.categoryName3)
  },
  {
    id: 9,
    name: 'categoryNameSpecial',
    label: t('common.characterstic'),
    minWidth: 120,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.categoryNameSpecial)
  },
  {
    id: 10,
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.orderNumber)
  },
  {
    id: 11,
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.capacity)
  },
  {
    id: 12,
    name: 'capacityType',
    label: t('common.packaging'),
    minWidth: 100,
    align: 'center',
    isOpen: true,
    canBeFiltered: true,
    render: (item) => rerender(item, item?.capacityType)
  },
  {
    id: 13,
    name: 'cost',
    label: t('common.cost'),
    minWidth: 100,
    align: 'center',
    isOpen: true,

    render: (item) => rerender(item, formatMoney(item?.cost))
  },
  {
    id: 14,
    name: 'price',
    label: t('common.salePrice'),
    minWidth: 100,
    align: 'center',
    isOpen: true,

    render: (item) => rerender(item, formatMoney(item?.price))
  },
  {
    id: 15,
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    isOpen: true,

    render: (item) => rerender(item, formatMoney(item?.quantity))
  },
  {
    id: 16,
    name: 'totalAmount',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    isOpen: true,

    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.totalAmount))
  },
  {
    id: 17,
    name: 'selectedPrice',
    label: t('common.productTotalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    isOpen: true,

    render: (item) => rerender(item, formatMoney(item?.selectedPrice))
  },
  {
    id: 18,
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    isOpen: true,

    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.discount))
  }
];

export const columnDetailPayment = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },
  {
    name: 'regDate',
    label: t('common.paymentTime'),
    minWidth: 120,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatTime(item?.regDate))
  },
  {
    name: 'clientName',
    label: t('common.client'),
    minWidth: 150,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.clientName)
  },
  {
    name: 'minibarNickname',
    label: t('common.minibar'),
    minWidth: 120,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.minibarNickname || item?.minibarCode)
  },
  {
    label: t('common.member'),
    minWidth: 80,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {rerender(item, item.memberName || Formatter.fKrPhone(item?.phone))}
      </span>
    )
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item.productName)
  },
  {
    name: 'categoryName1',
    label: `${t('pages.curation.1st-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName1)
  },
  {
    name: 'categoryName2',
    label: `${t('pages.curation.2nd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName2)
  },
  {
    name: 'categoryName3',
    label: `${t('pages.curation.3rd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName3)
  },
  {
    name: 'categoryNameSpecial',
    label: t('common.characterstic'),
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryNameSpecial)
  },
  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item.orderNumber)
  },
  {
    name: 'cost',
    label: t('common.cost'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.cost))
  },
  {
    name: 'price',
    label: t('common.salePrice'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.price))
  },
  {
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.quantity))
  },
  {
    name: 'totalAmount',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.totalAmount))
  },
  {
    name: 'selectedPrice',
    label: t('common.selectedPrice'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.selectedPrice))
  },
  {
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.discount))
  }
];

export const searchOptions = (t) => [
  { value: 'phone', label: t('common.phoneNo') },
  { value: 'productName', label: t('common.productName') },
  { value: 'totalAmount', label: t('common.totalAmount') }
];
