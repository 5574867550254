import React, { forwardRef } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const Label = styled('label')({
  fontWeight: 700,
  fontSize: 13,
  textAlign: 'center',
  color: '#6F869C'
});

const LabelBox = forwardRef(
  ({ title, onClick, required, sx, ...rest }, ref) => {
    return (
      <Stack
        justifyContent="center"
        sx={{
          height: '64px',
          bgcolor: '#FCFCFC',
          px: 2,
          py: 1,
          width: 100,
          textAlign: 'center',
          ...sx
        }}
      >
        <Label onClick={onClick} {...rest} ref={ref}>
          {title}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Label>
      </Stack>
    );
  }
);

export default LabelBox;
