import { tableTypes } from '@constants/utils';
import { Chip } from '@mui/material';
import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const i = i18n.getFixedT(null, null, 'pages.inventory');
const v = i18n.getFixedT(null, null, 'pages.vendorOrder');
const prod = i18n.getFixedT(null, null, 'pages.product');
const g = i18n.getFixedT(null, null, '');

export const radioOptions = [
  { value: 'Adequate', label: g('labels.proper') },
  { value: 'Insufficient', label: g('labels.insufficient') },
  { value: 'NeedReview', label: g('labels.check') }
];

export const columns = [
  { name: 'centerName', label: i('location'), align: 'center', minWidth: 100 },
  {
    name: 'maker',
    label: prod('manufacturer'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'productCode',
    label: prod('product-code'),
    align: 'center',
    minWidth: 160
  },
  { name: 'barcode', label: prod('bar-code'), align: 'center', minWidth: 120 },
  {
    name: 'productName',
    label: prod('product-name'),
    align: 'center',
    minWidth: 160
  },
  { name: 'capacity', label: prod('volume'), align: 'center', minWidth: 100 },
  {
    name: 'capacityType',
    label: prod('package'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'inventoryQuantityTotal',
    label: v('stock'),
    align: 'center',
    minWidth: 100,
    formatter: (item) => formatMoney(item)
  },
  {
    name: 'b2c',
    label: 'B2C',
    align: 'center',
    children: [
      {
        name: 'lastWeekB2c',
        label: v('sales-quantity-per-week'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'lastMonthB2c',
        label: v('sales-quantity-last-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'stockStatusB2c',
        label: v('stock-status'),
        align: 'center',
        minWidth: 100,
        render: ({ stockStatusB2c }) => {
          return (
            <span>
              {stockStatusB2c && (
                <Chip
                  label={
                    (stockStatusB2c === 'Insufficient' &&
                      g('labels.insufficient')) ||
                    (stockStatusB2c === 'Adequate' && g('labels.proper')) ||
                    (stockStatusB2c === 'NeedReview' && g('labels.check')) ||
                    stockStatusB2c
                  }
                  color={
                    (stockStatusB2c === 'Insufficient' && 'error') ||
                    (stockStatusB2c === 'Adequate' && 'primary') ||
                    (stockStatusB2c === 'NeedReview' && 'warning') ||
                    'secondary'
                  }
                />
              )}
            </span>
          );
        }
      },
      {
        name: 'orderAmountB2c',
        label: prod('order-quantity'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'b2b',
    label: 'B2B',
    align: 'center',
    children: [
      {
        name: 'inventoryQuantityB2b',
        label: v('stock'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'shippingRequestB2b',
        label: v('sales-quantity-last-month'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'shippingRequestB2b',
        label: v('shipping-request-quantity'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      },
      {
        name: 'stockStatusB2b',
        label: v('stock-status'),
        align: 'center',
        minWidth: 100,
        render: ({ stockStatusB2b }) => {
          return (
            <span>
              {stockStatusB2b && (
                <Chip
                  label={
                    (stockStatusB2b === 'Insufficient' &&
                      g('labels.insufficient')) ||
                    (stockStatusB2b === 'Adequate' && g('labels.proper')) ||
                    (stockStatusB2b === 'NeedReview' && g('labels.check')) ||
                    'None'
                  }
                  color={
                    (stockStatusB2b === 'Insufficient' && 'error') ||
                    (stockStatusB2b === 'Adequate' && 'primary') ||
                    (stockStatusB2b === 'NeedReview' && 'warning') ||
                    'secondary'
                  }
                />
              )}
            </span>
          );
        }
      },
      {
        name: 'orderAmountB2b',
        label: prod('order-quantity'),
        align: 'center',
        minWidth: 100,
        formatter: (item) => formatMoney(item)
      }
    ]
  },
  {
    name: 'finalQuantity',
    label: v('final-quantity'),
    align: 'center',
    minWidth: 100,
    formatter: (item) => formatMoney(item)
  },
  { name: 'vendor', label: prod('vendor'), align: 'center', minWidth: 120 },
  {
    name: 'orderStatus',
    label: v('purchase-status'),
    align: 'center',
    minWidth: 120,
    render: ({ orderStatus }) =>
      orderStatus === '1'
        ? g('labels.purchase-completed')
        : g('labels.before-purchase')
  },
  {
    name: 'num',
    label: i('managements'),
    align: 'center',
    minWidth: 160,
    type: tableTypes.ACTION
  }
];
