import HelmetContainer from '@components/HelmetContainer';
import React, { useEffect, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';
import storeService from '@services/pre-order/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, IconButton, Stack, Tab } from '@mui/material';
import CareerDetailForm from './StoreDetailForm';
import StoreProductsPage from './StoreProductPage';

import { requiredFields, storeLocales } from '../enhance';

const defaultBusinessHours = [
  { dayOfWeek: 'MONDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
  { dayOfWeek: 'TUESDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
  { dayOfWeek: 'WEDNESDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
  { dayOfWeek: 'THURSDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
  { dayOfWeek: 'FRIDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
  { dayOfWeek: 'SATURDAY', openAt: '09:00', closeAt: '18:00', isOpen: false },
  { dayOfWeek: 'SUNDAY', openAt: '09:00', closeAt: '18:00', isOpen: false }
];
const validLocales = ['ko', 'jp'];

const StoreDetail = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isModified, setIsmodified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState('1');
  const pathname = location?.pathname?.split('/');
  const storeCode = pathname[pathname.length - 1];

  const [formValue, setFormValue] = useState({
    storeTypeCode: '',
    preorderLocale:
      location?.state?.locale && validLocales.includes(location?.state?.locale)
        ? location?.state?.locale
        : 'ko',
    storeName: '',
    managerFullName: '',
    managerContact: '',
    address: '',
    contact: '',
    latitude: '',
    longitude: '',
    encodeContact: false,
    businessHours: [
      { dayOfWeek: 'MONDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
      { dayOfWeek: 'TUESDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
      {
        dayOfWeek: 'WEDNESDAY',
        openAt: '09:00',
        closeAt: '18:00',
        isOpen: true
      },
      {
        dayOfWeek: 'THURSDAY',
        openAt: '09:00',
        closeAt: '18:00',
        isOpen: true
      },
      { dayOfWeek: 'FRIDAY', openAt: '09:00', closeAt: '18:00', isOpen: true },
      {
        dayOfWeek: 'SATURDAY',
        openAt: '09:00',
        closeAt: '18:00',
        isOpen: false
      },
      { dayOfWeek: 'SUNDAY', openAt: '09:00', closeAt: '18:00', isOpen: false }
    ],
    setTimeAll: true,
    startTime: '2022-04-17T15:30',
    orderFixDays: 1
  });
  const getStoreDetail = async () => {
    if (!location?.pathname?.includes('register')) {
      const rs = await storeService.getStoreDetail(storeCode);

      const apiBusinessHours = rs?.data?.businessHours || [];

      const updatedBusinessHours = defaultBusinessHours.map((day) => {
        const apiDay = apiBusinessHours.find(
          (d) => d.dayOfWeek === day.dayOfWeek
        );
        if (apiDay) {
          return {
            ...day,
            ...apiDay,
            isOpen: true
          };
        }
        return { ...day, isOpen: false };
      });
      setFormValue({
        ...formValue,
        ...rs?.data,
        businessHours: updatedBusinessHours,
        encodeContact: rs?.data?.contactEncodedYn || false
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { tab } = query;
    setTab(tab || '1');
  }, [location?.search]);
  useEffect(() => {
    getStoreDetail();
  }, []);
  const isValidForm = () => {
    let isValid = true;
    requiredFields.forEach((field) => {
      if (!formValue[field]) {
        isValid = false;
      }
    });
    return isValid;
  };
  const onAction = async (action) => {
    if (action === 'cancel') {
      navigate('/pre-order/store');
    }
    if (action === 'register' || action === 'modification') {
      const storOwnerInfo =
        formValue?.preorderLocale === storeLocales.KO
          ? {
              managerFullName: formValue?.managerFullName,
              managerContact: formValue?.managerContact
            }
          : {
              managerLastName: formValue?.managerLastName,
              managerFirstName: formValue?.managerFirstName,
              managerLastNameKana: formValue?.managerLastNameKana,
              managerFirstNameKana: formValue?.managerFirstNameKana,
              managerContact: formValue?.managerContact,
              managerEmail: formValue?.managerEmail
            };

      const submitBusinessHours = formValue?.businessHours
        ?.filter((i) => i?.isOpen)
        ?.map((i) => ({
          dayOfWeek: i?.dayOfWeek,
          openAt: i?.openAt,
          closeAt: i?.closeAt
        }));
      const dataSubmit = {
        storeTypeCode: formValue?.storeTypeCode,
        preorderLocale: formValue?.preorderLocale,
        storeName: formValue?.storeName,
        address: formValue?.address,
        contact: formValue?.contact,
        encodeContact: formValue?.encodeContact,
        businessHours: submitBusinessHours,
        orderFixDays: formValue?.orderFixDays,
        latitude: formValue?.latitude,
        longitude: formValue?.longitude,
        ...storOwnerInfo
      };
      if (isValidForm()) {
        let rs = null;
        if (action === 'register') {
          rs = await storeService.createNewStore(dataSubmit);
        } else {
          rs = await storeService.updateStore(storeCode, dataSubmit);
        }
        if (!rs?.error) {
          dispatch(setSuccess({ message: t('info.save.content') }));
          if (action === 'register') {
            console.log('location', rs?.location);
            const path = rs?.location?.split('/');
            const newStoreCode = path[path.length - 1];
            navigate(`/pre-order/store/${newStoreCode}`);
            setIsmodified(false);
          }
        } else {
          dispatch(setError(error2Text(rs.error)));
        }
      }
    }
  };
  const handleChangeTab = (event, newValue) => {
    const searchQuery = objToParams(ignoreEmpty({ tab: newValue }));
    setTab(newValue);
    navigate(searchQuery);
  };
  return (
    <HelmetContainer
      title={
        storeCode === 'register'
          ? `${t('pageTitle.preorder-store')} ${t('button.register')}`
          : `${t('pageTitle.preorder-store')} ${t('button.modify')}`
      }
      content="careers detail page"
      sx={{ width: '100%', height: '100%' }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
        mb={1}
        sx={{ display: 'flex' }}
      >
        <IconButton
          onClick={() => onAction('cancel')}
          sx={{
            display: 'flex',
            gap: 0.5,
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}
          disableRipple
          disableTouchRipple
        >
          <ArrowBackIosNewIcon
            sx={{ height: '20px', color: 'black', fontWeight: 500 }}
          />
        </IconButton>
        {tab !== '2' &&
          (location?.pathname?.includes('register') ? (
            <Button
              variant="contained"
              onClick={() => onAction('register')}
              disabled={!isValidForm()}
            >
              {t('button.save')}
            </Button>
          ) : (
            <Button
              variant={isModified ? 'contained' : 'outlined'}
              onClick={() => onAction('modification')}
              disabled={!isValidForm()}
            >
              {isModified ? t('button.save') : t('pages.inventory.modify')}
            </Button>
          ))}
      </Stack>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: '2px solid #EEEEEE', mb: 2.5 }}>
          <TabList onChange={handleChangeTab}>
            <Tab label={t('pages.pre-order.store-form.basic-info')} value="1" />
            <Tab label={t('pages.pre-order.store-form.menu-info')} value="2" />
          </TabList>
        </Box>

        <TabPanel sx={{ m: 0, p: 0, gap: 3 }} value="1">
          <CareerDetailForm
            onAction={onAction}
            value={formValue}
            setValue={setFormValue}
            tab={tab}
            isModified={isModified}
            setIsmodified={setIsmodified}
          />
        </TabPanel>
        <TabPanel
          sx={{
            m: 0,
            p: 0,
            textAlign: 'center',
            width: '100%',
            height: '100%'
          }}
          value="2"
        >
          <StoreProductsPage />
        </TabPanel>
      </TabContext>
    </HelmetContainer>
  );
};

export default StoreDetail;
