export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="16"
      viewBox="0 0 33 16"
      fill="none"
    >
      <path
        d="M24.7986 6H0.953125V10H24.7986V16L32.7206 8L24.7986 0V6Z"
        fill="black"
      />
    </svg>
  );
};
