/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchBar from '@components/SearchBar';
import { Button, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import EditIcon from '@mui/icons-material/Edit';
import locationService from '@services/location';
import { useDispatch } from 'react-redux';
import {
  onOpenConfirm,
  setError,
  setSuccess
} from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { scopes } from '@constants/utils';
import useAuthorization from '@hooks/useAuthorization';
import LocationRegister from './components/LocationRegister';
import { columns, propertyOptions, searchConditions } from './enhance';

const Location = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [param, setParam] = useState({
    locationCode: null,
    locationProperty: propertyOptions[0]?.value,
    page: 0
  });
  const [formValue, setFormValue] = useState({
    locationName: '',
    locationCenterCode: '',
    locationZone: '',
    locationBay: '',
    locationBin: '',
    locationProperty: ''
  });
  const [errMg, setErrMg] = useState({
    locationName: '',
    locationCenterCode: '',
    locationZone: '',
    locationBay: '',
    locationBin: '',
    locationProperty: ''
  });
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [notifyDialog, setNotifyDialog] = useState(undefined);
  const [selected, setSelected] = useState(null);
  const [condition, setCondition] = useState('locationName');
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const { items, pagination } = state;

  const getLocations = async (param) => {
    const rs = await locationService.getLocationList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { locationName, locationProperty, page } = query;
    setCondition((locationName && 'locationName') || 'locationProperty');
    setParam({ ...param, page: page - 1 || 0, locationName, locationProperty });
    getLocations({
      ...param,
      page: page - 1 || 0,
      locationName,
      locationProperty
    });
  }, [location?.search]);
  const onAction = (action, value) => {
    if (action === 'search') {
      const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
      getLocations({ ...param, page: 0 });
      navigate(query);
    }
    if (action === 'register') {
      setFormValue({
        locationName: '',
        locationCenterCode: '',
        locationZone: '',
        locationBay: '',
        locationBin: '',
        locationProperty: propertyOptions[0].value
      });
      setErrMg({
        locationName: '',
        locationCenterCode: '',
        locationZone: '',
        locationBay: '',
        locationBin: '',
        locationProperty: ''
      });
      setSelected({
        ...selected,
        title: t('pages.inventory.location-register'),
        action,
        open: true
      });
    }
    if (action === 'searchTyping') {
      setParam({
        ...param,
        [condition]: value.target.value
      });
    }
  };

  const validateOnChangeInput = (name, valuee) => {
    let errorMessage = '';
    if (!valuee) {
      errorMessage = t('common.required');
    }
    setErrMg({
      ...errMg,
      [name]: errorMessage
    });
  };

  const handleChangeInput = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
    if (e.target.name !== 'locationPropery') {
      setFormValue((prev) => {
        return {
          ...prev,
          locationName: `${prev.locationCenterCode}-${prev.locationZone}-${prev.locationBay}-${prev.locationBin}`
        };
      });
    }
    validateOnChangeInput(e.target.name, e.target.value);
  };

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getLocations({ ...param, page });
    navigate(query);
  };

  const handleChangeUsedYn = async (item) => {
    if (!item) return;
    const setValue = item?.useYn === 'Y' ? 'N' : 'Y';
    const updatedItems = items.map((loc) => {
      if (loc.locationCode === item?.locationCode) {
        return { ...loc, useYn: setValue };
      }
      return { ...loc };
    });
    setState({ ...state, items: updatedItems });
    const rs = await locationService.updateLocationUseYn(item.locationCode, {
      useYn: setValue
    });
    if (!rs?.error) {
      setSuccess({ message: 'success' });
    } else {
      getLocations(param);
    }
  };

  const getActionsButton = (item, actionType) => {
    switch (actionType) {
      case 1:
        return (
          <Checkbox
            checked={item?.useYn === 'Y'}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeUsedYn(item);
            }}
          />
        );
      case 2:
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditLocation(item);
            }}
          >
            {' '}
            <EditIcon />
          </IconButton>
        );
      default:
        break;
    }
  };
  const checkValidate = (data) => {
    console.log('data => ', data);
    console.log('err => ', errMg);
    let isValidate = true;
    const vldErr = { ...errMg };
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in vldErr) {
      if (!data?.[property]) {
        vldErr[property] = t('common.required');
        isValidate = false;
      }
      if (property !== 'locationName' && data?.[property]?.length > 10) {
        vldErr[property] = t('validation.dataTooLong', { max: 10 });
        isValidate = false;
      }
    }
    setErrMg(vldErr);
    return isValidate;
  };
  const [loadingPop, setLoadingPop] = useState(false);
  const handleRegisterLocation = async () => {
    setLoadingPop(true);
    const {
      locationName,
      locationCenterCode,
      locationZone,
      locationBay,
      locationBin,
      locationProperty
    } = formValue;
    const dataSubmit = {
      locationName,
      centerCode: locationCenterCode,
      zone: locationZone,
      bay: locationBay,
      bin: locationBin,
      property: locationProperty
    };
    const rs = await locationService.createLocation(dataSubmit);
    if (!rs?.error) {
      setSelected({ ...selected, open: false });
      dispatch(
        setSuccess({ message: t('pages.equipment.messages.success.edit') })
      );
      getLocations(param);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoadingPop(false);
    return true;
  };

  const handleModifyLocation = async () => {
    setLoadingPop(true);
    const {
      locationName,
      locationCenterCode,
      locationZone,
      locationBay,
      locationBin,
      locationProperty
    } = formValue;
    const dataSubmit = {
      locationName,
      centerCode: locationCenterCode,
      zone: locationZone,
      bay: locationBay,
      bin: locationBin,
      property: locationProperty
    };
    const rs = await locationService.editLocation(
      selected?.items?.locationCode,
      dataSubmit
    );
    if (!rs?.error) {
      setSelected({ ...selected, open: false });
      dispatch(
        setSuccess({ message: t('pages.equipment.messages.success.edit') })
      );
      getLocations(param);
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoadingPop(false);
    return true;
  };

  const handleEditLocation = async (item) => {
    setErrMg({
      locationName: '',
      locationCenterCode: '',
      locationZone: '',
      locationBay: '',
      locationBin: '',
      locationProperty: ''
    });
    const rs = await locationService.getLocationrDetail(item.locationCode);
    if (!rs?.error) {
      // const catgArr = rs?.data?.data?.productCategory
      //   ?.split(' ')
      //   ?.join('')
      //   ?.split('>');
      setFormValue({
        ...formValue,
        ...rs?.data?.data
        // productName: rs?.data?.data?.productName,
        // category1Name: catgArr?.[0],
        // category2Name: catgArr?.[1],
        // category3Name: catgArr?.[2],
        // initProduct: Boolean(rs?.data?.data?.productCode)
      });
      setSelected({
        ...selected,
        open: true,
        title: `${t('pages.inventory.location')} ${t('button.modify')} (${
          item?.locationName
        })`,
        action: 'location-modify',
        items: rs?.data?.data
      });
    }
  };

  // const removeProduct = async (item) => {
  //   const rs = await locationService.removeProductFromLocation(
  //     item?.locationCode
  //   );
  //   if (rs?.error) {
  //     dispatch(setError(error2Text(rs.error)));
  //   }
  //   await getLocations();
  //   setFormValue({
  //     ...formValue,
  //     productName: null,
  //     productCategory: null,
  //     productCode: null,
  //     category1Name: null,
  //     category2Name: null,
  //     category3Name: null,
  //     initProduct: false
  //   });
  //   setNotifyDialog(false);
  // };

  return (
    <HelmetContainer title={t('pageTitle.location')} content="Location Page">
      <SearchBar>
        <MySelect
          label={t('labels.condition')}
          value={condition}
          data={searchConditions}
          onChange={(e) => {
            setParam({ locationCode: null, locationProperty: null });
            setCondition(e.target.value);
          }}
          sx={{ mr: 1.5 }}
        />
        {condition === 'locationName' ? (
          <MyTextField
            name={condition}
            value={param[condition] || ''}
            onChange={(e) => onAction('searchTyping', e)}
            placeholder={t('button.search')}
            onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
          />
        ) : (
          <MySelect
            value={param[condition] || propertyOptions[0].value}
            data={propertyOptions}
            onChange={(e) => {
              onAction('searchTyping', {
                target: { name: 'locationProperty', value: e.target.value }
              });
            }}
            sx={{ mr: 1.5 }}
          />
        )}

        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={() => onAction('search')}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack flexDirection="row" justifyContent="space-between" mt={2}>
        <Button
          variant="outlined"
          onClick={() => onAction('register')}
          disabled={!isAuthorized}
        >
          {t('button.register')}
        </Button>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination?.totalRow || 0}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        hover
        rerender
        getActionButtons={getActionsButton}
        // onRowClick={handleClickTable}
        columns={columns}
        data={items}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <MyDialog
        sx={{
          width: {
            sm: '100%',
            md: '50%',
            lg: '30%'
          }
        }}
        open={selected?.open}
        title={selected?.title}
        hasCancelButton
        hasCloseButton
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        onOk={() => {
          if (selected?.action === 'location-modify') {
            if (checkValidate(formValue)) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'modify',
                  action: () => handleModifyLocation()
                })
              );
            }
          } else {
            if (checkValidate(formValue)) {
              dispatch(
                onOpenConfirm({
                  open: true,
                  type: 'save',
                  action: () => handleRegisterLocation()
                })
              );
            }
          }
        }}
        disabled={!isAuthorized}
        loading={loadingPop}
        setLoading={setLoadingPop}
      >
        <LocationRegister
          value={formValue}
          setValue={setFormValue}
          errMg={errMg}
          setErrMg={setErrMg}
          onChange={handleChangeInput}
          // removeProduct={(item) => {
          //   setNotifyDialog({
          //     open: true,
          //     title: t('common.confirm-delete-title', {
          //       item: item?.productName
          //     }),
          //     onOk: () => removeProduct(item),
          //     setOpen: () => {
          //       setNotifyDialog({ ...notifyDialog, open: false });
          //     }
          //   });
          // }}
        />
      </MyDialog>
      <MyDialog
        open={notifyDialog?.open}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
    </HelmetContainer>
  );
};

export default Location;
