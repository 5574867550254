import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import MyTextField from '@components/MyTextField';
import apkServices from '@services/apk';
import { BorderBox, tableStyle } from '../enhance';

const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  margin: '10px 0px'
};

export const maxLengthOf = {
  appGroup: 40,
  appName: 40,
  appNickname: 40,
  appVersion: 40,
  appLink: 140
};

export const requiredFields = ['appGroup', 'appName', 'appNickname'];

export default function APKForm({
  item = '',
  onClose,
  onSubmit,
  cancelButtonProps,
  submitButtonProps
}) {
  const [app, setApp] = useState(undefined);
  const { t } = useTranslation();

  const [errMsg, setErrMsg] = useState({
    appGroup: '',
    appName: '',
    appNickname: '',
    appVersion: '',
    appLink: ''
  });

  const getApk = async () => {
    const result = await apkServices.getApk(item);
    if (!result.error) {
      setApp(
        result.data?.data || {
          appGroup: '',
          appName: '',
          appNickname: '',
          appVersion: '',
          appLink: ''
        }
      );
    }
  };

  useEffect(() => {
    getApk();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setApp({ ...app, [name]: value || '' });
    validateOnChange(name, value);
  };

  const validateOnChange = (name, value) => {
    let msg = '';
    if (requiredFields.includes(name) && !value) {
      msg = t('common.required');
    } else if (value?.length > maxLengthOf[name]) {
      msg = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }
    setErrMsg({ ...errMsg, [name]: msg });
  };

  const validateOnSubmit = () => {
    let isPassed = true;
    const tempErr = { ...errMsg };
    requiredFields.forEach((field) => {
      if (!app[field]) {
        tempErr[field] = t('common.required');
      }
    });

    Object.values(tempErr).map((error) => {
      if (error) {
        isPassed = false;
      }
      return 0;
    });

    setErrMsg(tempErr);
    return isPassed;
  };

  const handleSubmit = () => {
    const isPassed = validateOnSubmit();
    if (isPassed) {
      const { appLink, ...rest } = app;
      onSubmit(appLink ? app : rest);
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <TableContainer component={BorderBox}>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.apk.labels.appGroup')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appGroup"
                  value={app?.appGroup || ''}
                  placeholder={t('pages.apk.labels.appGroup')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.appGroup}
                  size="small"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.apk.labels.appNickname')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appNickname"
                  value={app?.appNickname || ''}
                  placeholder={t('pages.apk.labels.appNickname')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.appNickname}
                  size="small"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.apk.labels.appName')}
                <span style={{ color: 'red', marginLeft: 3 }}>*</span>
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appName"
                  value={app?.appName || ''}
                  placeholder={t('pages.apk.labels.appName')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.appName}
                  disabled={item !== null}
                  size="small"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.apk.labels.appLink')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appLink"
                  value={app?.appLink || ''}
                  placeholder={t('pages.apk.labels.appLink')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.appLink}
                  size="small"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.apk.labels.appVersion')}
              </TableCell>
              <TableCell>
                <MyTextField
                  name="appVersion"
                  value={app?.appVersion || ''}
                  placeholder={t('pages.apk.labels.appVersion')}
                  onChange={(e) => handleChangeInput(e)}
                  errMg={errMsg.appVersion}
                  size="small"
                  sx={inputStyle}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: 5, mb: 5 }}
        spacing={5}
      >
        {cancelButtonProps && (
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            disabled={cancelButtonProps.disabled}
          >
            {cancelButtonProps.text || t('button.cancel')}
          </Button>
        )}
        {submitButtonProps && (
          <LoadingButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px'
            }}
            loading={submitButtonProps.loading}
          >
            {item ? t('button.check') : t('button.register')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
