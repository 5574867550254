import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTable from '@components/MyTable';

const MinibarStatusDialog = ({ open, setOpen, t, data }) => {
  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.product.minibar-status-title')}
      hasCloseButton
      okTitle={t('button.close')}
      sx={{ width: 600 }}
    >
      <MyTable
        sx={{ mb: '15px', mt: 0 }}
        columns={[
          { name: '#', label: 'No', minWidth: 60, align: 'center' },
          {
            name: 'clientName',
            label: t('common.client'),
            minWidth: 125,
            align: 'center'
          },
          {
            name: 'minibarNickname',
            label: t('common.minibar'),
            minWidth: 125,
            align: 'center'
          },
          {
            name: 'unitPrice',
            label: t('pages.product.unit-price'),
            minWidth: 90,
            align: 'center',
            formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
          }
        ]}
        data={data}
      />
      <Typography sx={{ color: '#6F869C', fontSize: 14, mt: '14px' }}>
        {t('pages.product.minibar-status-notes')}
      </Typography>
    </MyDialog>
  );
};

export default withTranslation()(MinibarStatusDialog);
