import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  store: {
    tab: '1',
    storeCode: null,
    storeCategoryCode: null,
    storeTypeCode: null
  }
};

export const appSlice = createSlice({
  name: 'preorder',
  initialState,
  reducers: {
    setPreorderStore: (state, { payload }) => {
      state.store = {
        ...state?.store,
        ...payload
      };
    }
  }
});

export const { setPreorderStore } = appSlice.actions;

export default appSlice.reducer;
