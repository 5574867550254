import HelmetContainer from '@components/HelmetContainer';
import SearchBar from '@components/SearchBar';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import MySelect from '@components/MySelect';
import { useLocation, useNavigate } from 'react-router-dom';
import MyTextField from '@components/MyTextField';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import warehouseService from '@services/warehouse';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import {
  formatMoney,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import { scopes } from '@constants/utils';
import useAuthorization from '@hooks/useAuthorization';
import ExcelRegisterDialog from './components/ExcelRegisterDialog';
import { columns, searchConditions } from './enhance';

const Warehouse = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const configColumns = columns;
  const [selectedItem, setSelectedItem] = useState(null);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [param, setParam] = useState({
    barcode: null,
    locationName: null,
    productName: null,
    page: 0
  });
  const [loading, setLoading] = useState(false);
  const [loadingPop, setLoadingPop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [condition, setCondition] = useState('productName');
  const isAuthorized = useAuthorization(scopes.ADMIN_INVENTORY_MASTER);
  const { items, pagination } = state;
  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getWarehouseStock({ ...param, page });
    navigate(query);
  };

  const getWarehouseStock = async (param) => {
    const rs = await warehouseService.getWarehouseStockList(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { productName, barcode, locationName, page } = query;
    setCondition(
      (barcode && 'barcode') ||
        (locationName && 'locationName') ||
        'productName'
    );
    setParam({
      ...param,
      page: page - 1 || 0,
      productName,
      barcode,
      locationName
    });
    getWarehouseStock({
      ...param,
      page: page - 1 || 0,
      size: 100,
      productName,
      barcode,
      locationName
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(ignoreEmpty({ ...param, page: 1 }));
    getWarehouseStock({ ...param, page: 0 });
    navigate(query);
  };

  const exportStock = async () => {
    setLoading(true);
    const query = paramsToObj(location?.search);
    const { productName, locationName, barcode } = query;
    const rs = await warehouseService.exportWarehouseStock({
      productName,
      locationName,
      barcode
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const handleModifyStock = async () => {
    setLoadingPop(true);
    const rs = await warehouseService.updateWarehouseStock(selectedItem?.id, {
      stockQuantity: selectedItem?.stockQuantity || 0
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      getWarehouseStock({ ...param, page: 0 });
      setSelected({ ...selected, open: false });
    }
    setLoadingPop(false);
  };
  return (
    <HelmetContainer title={t('pageTitle.warehouse')} content="Warehouse Page">
      <SearchBar sx={{ alignItems: 'center' }}>
        <MySelect
          label={t('labels.condition')}
          value={condition}
          data={searchConditions}
          onChange={(e) => {
            setParam({ barcode: null, productName: null, locationName: null });
            setCondition(e.target.value);
          }}
          sx={{ mr: 1.5 }}
        />
        <MyTextField
          onChange={(e) => setParam({ ...param, [condition]: e.target.value })}
          name={condition}
          value={param[condition] || ''}
          placeholder={t('button.search')}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={() => handleSearch()}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        flexDirection="row"
        mt={2}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack flexDirection="row" gap={2} flexWrap="wrap">
          <Button
            disabled={state?.items?.length <= 0 || loading}
            variant="outlined"
            onClick={() => exportStock()}
            startIcon={loading && <CircularProgress size="20px" />}
          >
            {t('pages.inventory.saveExcel')}
          </Button>
          <Button variant="outlined" onClick={() => navigate('loss')}>
            {t('pageTitle.loss-dispose')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        hover={isAuthorized}
        rerender
        columns={configColumns}
        data={items}
        pagination={pagination}
        minusHeight={235}
        onChangePage={(e, page) => handleChangePage(page)}
      />
      <ExcelRegisterDialog
        open={openUploadFile}
        setOpen={setOpenUploadFile}
        onAction={() => getWarehouseStock({ ...param, page: 0 })}
      />
      <MyDialog
        open={selected?.open}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        title={selected?.title}
        hasCloseButton
        hasCancelButton
        onOk={handleModifyStock}
        disabled={!isAuthorized}
        loading={loadingPop}
        setLoading={setLoadingPop}
      >
        <Stack direction="row" gap={2} sx={{ mt: 1 }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              flexGrow: 1,
              paddingLeft: 2,
              pb: 0.5
            }}
          >
            <Typography
              sx={{
                fontSize: 13,
                color: '#6F869C'
              }}
            >
              {t('pages.curation.stock-quantity')}
              <span style={{ color: 'red', marginLeft: 5 }}>*</span>
            </Typography>
          </Stack>
          <MyTextField
            onChange={(e) =>
              setSelectedItem({
                ...selectedItem,
                stockQuantity: +e.target.value.replace(/,/g, '')
              })
            }
            value={formatMoney(selectedItem?.stockQuantity || 0)}
          />
        </Stack>
      </MyDialog>
    </HelmetContainer>
  );
};

export default Warehouse;
