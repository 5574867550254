import MyDialog from '@components/MyDialog';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { Stack, Typography } from '@mui/material';
import { setError, setSuccess } from '@store/reducers/appReducer';

import { useDispatch } from 'react-redux';
import { MAX_INT } from '@constants/utils';
import minibarOrderService from '@services/minibarOrderService';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inventoryActionOptions, inventoryActions } from '../enhance';

const recalltypes = {
  withdrawal: 'recall',
  loss: 'loss',
  discard: 'discard'
};
const InventoryActionsDialog = ({
  open,
  setOpen,
  setSelectedItem,
  onAction,
  item
}) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [originalValue, setOriginalValue] = useState(0);
  const dispatch = useDispatch();
  const qunatityFieldLabel = {
    [inventoryActions.DISPLAY]: t('pages.minibarOrder.display-quantity'),
    [inventoryActions.DISCARD]: t('pages.minibarOrder.disposeQuantity'),
    [inventoryActions.LOSS]: t('pages.minibarOrder.loss-quantity'),
    [inventoryActions.RECALL]: t('pages.minibarOrder.withdrawalAmount')
  };
  useEffect(() => {
    setOriginalValue(item?.stockQuantity);
  }, []);

  // ask whether it is okay to reset values when action changed
  const handleChangeActions = (e) => {
    setSelectedAction(e?.target?.value);
    setQuantity(0);
    setSelectedItem({ ...item, stockQuantity: originalValue });
  };
  const handleQuantityChange = (e) => {
    switch (selectedAction) {
      case inventoryActions.DISPLAY:
        if (e?.target?.value)
          setQuantity(Math.min(Number(e.target.value), MAX_INT));
        else setQuantity(e?.target?.value);
        setSelectedItem((prev) => ({
          ...prev,
          stockQuantity: Number(originalValue) + Number(e.target.value)
        }));
        break;
      case inventoryActions.LOSS:
      case inventoryActions.RECALL:
      case inventoryActions.DISCARD:
        if (e?.target?.value)
          setQuantity(Math.min(Number(e.target.value), Number(originalValue)));
        else setQuantity(e?.target?.value);
        setSelectedItem((prev) => ({
          ...prev,
          stockQuantity:
            Number(originalValue) -
            Math.min(Number(e.target.value), Number(originalValue))
        }));
        break;

      default:
        break;
    }
  };

  //   const handleUpdateMinibarInventory = async () => {
  //     const rs = await inventoryService.updateMinibarInventory({
  //       minibarStockCode: item?.minibarStockCode,
  //       type: selectedAction,
  //       quantity
  //     });
  //     if (!rs?.error) {
  //       dispatch(setSuccess({ message: '저장되었습니다.' }));
  //       onAction('success');
  //     } else {
  //       dispatch(setError(error2Text(rs?.error)));
  //     }
  //   };

  const handleUpdateRecallInfo = async () => {
    if (!item.minibarStockCode) return;
    let dataSubmit = {};
    let rs;

    switch (selectedAction) {
      case 'display':
        dataSubmit = {
          displayAmount: quantity,
          totalAmount: item?.quantity
        };
        rs = await minibarOrderService.updateDisplayInfo(
          item.minibarStockCode,
          dataSubmit
        );

        break;
      case 'recall':
        dataSubmit = {
          recalledAmount: quantity,
          totalAmount: item?.stockQuantity
        };
        rs = await minibarOrderService.updateRecallInfo(
          item.minibarStockCode,
          dataSubmit,
          recalltypes.withdrawal
        );

        break;
      case 'discard':
        dataSubmit = {
          recalledAmount: quantity,
          totalAmount: item?.stockQuantity,
          recalledCost:
            Number(quantity) * Number(item?.branchPrice || item?.masterPrice)
        };
        rs = await minibarOrderService.updateRecallInfo(
          item?.minibarStockCode,
          dataSubmit,
          recalltypes.discard
        );

        break;
      case 'loss':
        dataSubmit = {
          recalledAmount: quantity,
          recalledCost:
            Number(quantity) * Number(item?.branchPrice || item?.masterPrice),
          totalAmount: item?.stockQuantity
        };
        rs = await minibarOrderService.updateRecallInfo(
          item.minibarStockCode,
          dataSubmit,
          recalltypes.loss
        );

        break;
      default:
        break;
    }

    if (rs.error) dispatch(setError({ title: 'api 요청 실패' }));
    else {
      dispatch(setSuccess({ message: '저장되었습니다.' }));
      onAction('success');
    }
  };

  return (
    <MyDialog
      open={open}
      title={t('pages.minibarOrder.inventory-management')}
      setOpen={setOpen}
      hasCloseButton
      hasCancelButton
      cancelTitle={t('button.close')}
      okTitle={t('button.save')}
      disabled={!selectedAction || quantity === 0 || !quantity}
      sx={{
        minWidth: { md: '704px', xs: '350px' },
        width: { xs: '90vw', md: 'auto' },
        maxWidth: '90vw'
      }}
      onOk={handleUpdateRecallInfo}
    >
      <Stack sx={{ width: '100%', gap: 2 }}>
        {/* 1st row */}

        <Stack
          sx={{
            width: '100%',

            alignItems: 'center',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Typography sx={{ whiteSpace: 'nowrap', minWidth: '74px' }}>
            {t('common.productName')}
          </Typography>
          <MyTextField
            fullWidth
            sx={{
              flexGrow: 1,
              '.MuiInputBase-root': {
                bgcolor: '#f2f2f2'
              }
            }}
            value={item?.productName}
            disabled
          />
        </Stack>
        {/* 2nd row */}
        <Stack
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { md: 'row', xs: 'column' },
            gap: { md: 4, xs: 2 }
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              width: { xs: '100%', md: 'auto' },
              gap: 2
            }}
          >
            <Typography sx={{ whiteSpace: 'nowrap', minWidth: '74px' }}>
              {t('pages.minibarOrder.inventory')}
            </Typography>
            <MyTextField
              fullWidth
              sx={{
                flexGrow: 1,
                width: { xs: '100%', md: 'auto' },
                '.MuiInputBase-root': {
                  bgcolor: '#f2f2f2'
                }
              }}
              value={originalValue || 0}
              disabled
            />
          </Stack>

          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              width: { xs: '100%', md: 'auto' },
              gap: 2
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                minWidth: '74px',
                textAlign: { xs: 'left', md: 'right' }
              }}
            >
              {t('pages.minibarOrder.action-type')}
            </Typography>
            <MySelect
              data={inventoryActionOptions}
              value={selectedAction}
              sx={{
                flexGrow: { xs: 1, md: 0 }
              }}
              onChange={handleChangeActions}
            />
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            border: '1px solid #D3D3D3',
            mt: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            p: { md: 4, xs: 2 },
            gap: 2,
            minHeight: '160px'
          }}
        >
          {/* 1st row */}

          {selectedAction && (
            <Stack sx={{ gap: 2, width: '100%' }}>
              <Stack
                sx={{
                  width: '100%',

                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 2
                }}
              >
                <Typography sx={{ whiteSpace: 'nowrap', minWidth: '80px' }}>
                  {qunatityFieldLabel[selectedAction]}
                </Typography>
                <MyTextField
                  fullWidth
                  sx={{ flexGrow: 1, width: '100%' }}
                  value={quantity}
                  onChange={handleQuantityChange}
                  disabled={
                    selectedAction !== inventoryActions.DISPLAY &&
                    originalValue < 1
                  }
                  typeValue="onlyNumber"
                />
              </Stack>
              {/* 2nd row */}
              <Stack
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 4
                }}
              >
                <Stack
                  sx={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 2
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap', minWidth: '80px' }}>
                    {selectedAction === inventoryActions.DISPLAY
                      ? '(+)'
                      : '(-)'}{' '}
                    {t('pages.minibarOrder.inventory')}
                  </Typography>
                  <MyTextField
                    fullWidth
                    sx={{
                      flexGrow: 1,
                      '.MuiInputBase-root': {
                        bgcolor: '#f2f2f2'
                      }
                    }}
                    value={item?.stockQuantity}
                    disabled
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </MyDialog>
  );
};

export default InventoryActionsDialog;
