import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import salesPerformServices from '@services/salesPerform';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MyLoadingButton from '@components/MyLoadingButton';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { detailColumns } from './enhance';

const SalesStatusDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const configColumns = detailColumns;
  const [param, setParam] = useState({
    clientCode: null,
    aggregateCode: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const handleChangePage = (page) => setParam({ ...param, page });

  const getDetails = async () => {
    const rs = await salesPerformServices.getSalesDetail({
      ...location?.state,
      page: param?.page
    });
    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    getDetails();
  }, [param?.page]);

  const handleExport = async () => {
    const rs = await salesPerformServices.exportSalesDetail(location?.state);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  return (
    <HelmetContainer
      title={t('pageTitle.salesPerformDetail')}
      content="Release page"
    >
      <Stack
        mb={2}
        mt={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" gap={2}>
          <MyLoadingButton variant="outlined" onClick={handleExport}>
            {t('button.saveExcel')}
          </MyLoadingButton>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            {t('pages.inventory.list')}
          </Button>
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {state?.pagination.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        hover
        data={state?.items}
        columns={configColumns}
        pagination={state?.pagination}
        minusHeight={150}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
};

export default SalesStatusDetail;
