import React, { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  TableBody,
  TableContainer,
  TableRow
} from '@mui/material';

import ZombieTable, { CustomTableBody } from '@components/MyTable/ZombieTable';
import NoData from '@components/NoData';

import DisplayTableRow from './DisplayTableRow';
import { columns } from './enhance';

const DisplayTable = ({ data, pagination, emptyDescription, onChangePage }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer sx={{ mt: '20px', mb: '30px' }}>
        <ZombieTable columns={columns} minusHeight={300}>
          {data?.length === 0 ? (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          ) : (
            <CustomTableBody>
              {data.map((item) => (
                <DisplayTableRow item={item} key={item?.id} />
              ))}
            </CustomTableBody>
          )}
        </ZombieTable>
      </TableContainer>
      {data?.length > 0 && pagination && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {loading && (
            <CircularProgress
              size={22}
              sx={{ position: 'absolute', left: '50%', right: '50%' }}
            />
          )}
          <Pagination
            disabled={loading}
            count={pagination?.count || 1}
            variant="outlined"
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            page={pagination.page + 1}
            onChange={(e, page) => {
              if (page !== pagination.page + 1) {
                setLoading(true);
                onChangePage(e, page - 1);
              }
            }}
          />
        </Stack>
      )}
    </Box>
  );
};

export default DisplayTable;
