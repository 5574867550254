/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import HelmetContainer from '@components/HelmetContainer';
import MyTable from '@components/MyTable';
import { useTranslation } from 'react-i18next';
import SearchBar from '@components/SearchBar';
import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';
import { clientServices } from '@services/index';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MySelect from '@components/MySelect';
import EditIcon from '@mui/icons-material/Edit';
import MyDialog from '@components/MyDialog';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { objToParams, paramsToObj } from '@utils/helpers';
import { dateFormat } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import ClientForm from './components/ClientForm';
import { columnClient } from './enhance';

const Client = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({ keyword: '', page: 0 });
  const [clientV2, setClientV2] = useState(null);
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    },
    client: null,
    clients: []
  });
  const [channel, setChannel] = useState(null);
  const channels = [
    { value: 'B2B,B2C,B2B2C', label: t('pages.client-order.all') },
    { value: 'B2C', label: 'B2C' },
    { value: 'B2B', label: 'B2B' },
    { value: 'B2B2C', label: 'B2B2C' }
  ];

  const handleOnChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams({
      keyword: clientV2?.clientName || '',
      channel,
      page: page + 1
    });
    navigate(query);
  };
  const [editItem, setEditItem] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(undefined);

  const { items, pagination } = state;

  const getListClient = async (param) => {
    const result = await clientServices.getListClient(param);
    if (!result.error) {
      setState({
        ...state,
        items: result.data?.items || [],
        pagination: {
          ...pagination,
          page: result.data?.page || 0,
          totalRow: result.data?.totalRow || 0,
          count: result.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    setClientV2({
      ...clientV2,
      clientName: query?.keyword || '',
      clientCode: ' '
    });
    setChannel(query?.channel || 'B2B,B2C,B2B2C');
    setParam({
      ...param,
      keyword: query?.keyword || '',
      clientChannel: query?.channel,
      page: query?.page - 1 || 0
    });
    getListClient({
      keyword: query?.keyword || '',
      clientChannel: query?.channel || channel,
      page: query?.page - 1 || 0
    });
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams({
      keyword: clientV2?.clientName || '',
      channel,
      page: 1
    });
    navigate(query);
  };

  const getActionButtons = (item) => (
    <Stack direction="row">
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setEditItem(item);
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setNotifyDialog({
            title: t('common.confirm-delete-title', {
              item: item?.clientName
            }),
            onOk: () => handleDeleteClient(item?.clientCode || ''),
            setOpen: () => {
              setNotifyDialog(undefined);
            }
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  );

  const handleDeleteClient = async (clientCode) => {
    const result = await clientServices.deleteClient(clientCode);
    if (!result.error) {
      getListClient(param);
    }
    setNotifyDialog(undefined);
  };

  const handleClose = () => {
    setIsSubmitting(false);
    setEditItem(undefined);
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const {
      clientChannel,
      clientCode,
      clientName,
      groupCode,
      accessMethod,
      aggregationCode,
      businessNumber,
      chargerEmail,
      clientAddress1,
      clientAddress2,
      clientContact,
      clientId,
      contractDate,
      contractTerm,
      goalAmount,
      groupName,
      investmentAmount,
      managerName,
      managerPhone,
      note,
      file,
      officialEmail,
      other,
      ownerName,
      parkingType,
      pricePolicyType,
      salesManager,
      shippingAddress1,
      shippingAddress2,
      shippingCycle,
      shippingDistrict,
      shippingLocation,
      shippingManager,
      shippingWeekFri,
      shippingWeekMon,
      shippingWeekThu,
      shippingWeekTue,
      shippingWeekWed
    } = data;

    const validateRegister =
      clientChannel && clientName && clientId && pricePolicyType;
    const validateEdit = clientCode && validateRegister && groupCode;
    if (
      (editItem == null && !validateRegister) ||
      (editItem && !validateEdit) ||
      editItem === undefined
    ) {
      setNotifyDialog({
        title: t('dialog.title.pleaseEnterTheRequestedInformation'),
        onOk: () => {
          setIsSubmitting(false);
          setNotifyDialog(undefined);
        },
        setOpen: () => {
          setIsSubmitting(false);
          setNotifyDialog(undefined);
        }
      });
    } else {
      const formData = new FormData();
      if (editItem) {
        formData.append('clientCode', clientCode || '');
        formData.append('groupCode', groupCode || '');
      }
      formData.append('clientChannel', clientChannel || '');
      formData.append('clientName', clientName || '');
      formData.append('accessMethod', accessMethod || '');
      formData.append('aggregationCode', aggregationCode || '');
      formData.append('businessNumber', businessNumber || '');
      formData.append('chargerEmail', chargerEmail || '');
      formData.append('clientAddress1', clientAddress1 || '');
      formData.append('clientAddress2', clientAddress2 || '');
      formData.append('clientContact', clientContact || '');
      formData.append('clientId', clientId || '');
      if (contractDate) {
        formData.append(
          'contractDate',
          moment(contractDate).format(dateFormat)
        );
      }
      formData.append('contractPeriod', contractTerm || '');
      formData.append('goalAmount', Formatter.fStringToNumber(goalAmount) || 0);
      formData.append('groupName', groupName || '');
      formData.append(
        'investmentAmount',
        Formatter.fStringToNumber(investmentAmount) || 0
      );
      formData.append('managerName', managerName || '');
      formData.append('managerPhone', managerPhone || '');
      formData.append('note', note || '');
      if (typeof file === 'object') {
        formData.append('file', file || '');
      }
      formData.append('officialEmail', officialEmail || '');
      formData.append('other', other || '');
      formData.append('ownerName', ownerName || '');
      formData.append('parkingType', parkingType || '');
      formData.append('pricePolicyType', pricePolicyType || '');
      formData.append('salesManager', salesManager || '');
      formData.append('shippingAddress1', shippingAddress1 || '');
      formData.append('shippingAddress2', shippingAddress2 || '');
      formData.append('shippingCycle', shippingCycle || '');
      formData.append('shippingDistrict', shippingDistrict || '');
      formData.append('shippingLocation', shippingLocation || '');
      formData.append('shippingManager', shippingManager || '');
      formData.append('shippingWeekMon', shippingWeekMon || 'N');
      formData.append('shippingWeekTue', shippingWeekTue || 'N');
      formData.append('shippingWeekWed', shippingWeekWed || 'N');
      formData.append('shippingWeekThu', shippingWeekThu || 'N');
      formData.append('shippingWeekFri', shippingWeekFri || 'N');

      const result =
        editItem === null
          ? await clientServices.createClient(formData)
          : await clientServices.updateClient(clientCode, formData);
      if (result.error) {
        dispatch(setError(error2Text(result.error)));
      } else {
        setEditItem(undefined);
      }
      getListClient(param);
      setIsSubmitting(false);
    }
  };

  return (
    <HelmetContainer title={t('pageTitle.listClient')}>
      <SearchBar>
        <MySelect
          label={t('labels.clientChannel')}
          value={channel || ''}
          data={channels}
          onChange={(e) => {
            setChannel(e.target.value);
          }}
          sx={{ mr: 1.5 }}
        />
        <ClientAutoCompleteV2
          client={clientV2}
          setClient={setClientV2}
          channel={channel}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => setEditItem(null)}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}
            {'  '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <MyTable
        hover
        onRowClick={(e) => setEditItem(e)}
        columns={columnClient(t)}
        data={items}
        pagination={pagination}
        getActionButtons={getActionButtons}
        minusHeight={235}
        onChangePage={(e, page) => handleOnChangePage(page)}
      />
      <MyDialog
        open={notifyDialog !== undefined}
        isAlert
        setOpen={notifyDialog?.setOpen}
        onOk={notifyDialog?.onOk}
        hasCancelButton
        hasCloseButton
        title={notifyDialog?.title || ''}
      />
      <MyDialog
        open={editItem !== undefined}
        setOpen={!isSubmitting ? handleClose : () => {}}
        title={
          editItem == null
            ? t('dialog.title.registerClient')
            : `${t('dialog.title.editClient')} "${editItem?.clientName}"`
        }
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          width: '100%',
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <ClientForm
          clientCode={editItem?.clientCode || ''}
          onClose={handleClose}
          onSubmit={(data) => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: !editItem ? 'save' : 'modify',
                action: () => handleSubmit(data)
              })
            );
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t(editItem == null ? 'button.register' : 'button.save')
          }}
          isLoading={isSubmitting}
          hasCancelButton
          hasCloseButton
        />
      </MyDialog>
    </HelmetContainer>
  );
};

export default Client;
