import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const displayService = {
  async getDisplayList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/display')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportDisplay(params = {}) {
    try {
      const res = await new RestService()
        .setPath('display/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createPic(data) {
    try {
      const res = await new RestService().setPath('/manager').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editPic(id, data) {
    try {
      const res = await new RestService().setPath(`/manager/${id}`).put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deletePic(id) {
    try {
      const res = await new RestService().setPath(`/manager/${id}`).delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default displayService;
