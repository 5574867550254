import MyTextField from '@components/MyTextField';
import { Box, IconButton, Popper, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MyAutocomplete, {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import warehouseService from '@services/warehouse';
import Calendar from '@components/Calendar';
import { Formatter } from '@utils/formatter';
import { dateFormat } from '@constants/utils';
import { Portal } from 'react-portal';

const ScheduleForm = ({
  value,
  setValue,
  gValue,
  setGValue,
  errMg,
  setErrMg,
  action
}) => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const [date, setDate] = useState(new Date());
  const [pic, setPic] = useState([]);
  const [keyword, setKeyword] = useState('');
  const addRow = () => {
    const lastRow = value[value.length - 1];
    if (lastRow?.clientCode) {
      setValue(
        value.concat([
          {
            index: value.length + 1,
            clientChannel: '',
            clientName: '',
            clientCode: '',
            minibarNickname: '',
            minibarCode: '',
            managerZone: '',
            managerArea: ''
          }
        ])
      );
    }
  };
  const getPic = async () => {
    const { data } = await warehouseService.getPic({
      keyword,
      page: 0,
      size: 5
    });
    if (!data?.items) return;

    setPic(data.items);
  };
  const handleInputChange = (e, val, reason) => {
    return reason === 'input' && setKeyword(val);
  };
  useEffect(() => {
    if (keyword?.length < 1 && keyword !== '') return undefined;
    const timeoutId = setTimeout(() => {
      getPic();
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [keyword]);
  useEffect(() => {
    getPic();
  }, []);
  const removeRow = (idx) => {
    if (value.length === 1 && value[0].clientCode) {
      setValue([
        {
          index: 1,
          clientChannel: '',
          clientName: '',
          clientCode: '',
          minibarNickname: '',
          minibarCode: '',
          managerZone: '',
          managerArea: ''
        }
      ]);
    }
    if (value.length > 1) {
      const newRow = [...value];
      newRow.splice(idx, 1);
      newRow.forEach((i, idx) => {
        i.index = idx + 1;
      });
      setValue(newRow);
    }
  };

  const handleSelectPic = (val) => {
    if (val) {
      setGValue({ ...gValue, ...val });
      setErrMg({ ...errMg, managerCode: '' });
    } else {
      setGValue({
        ...gValue,
        id: '',
        managerArea: '',
        managerZone: '',
        managerCode: '',
        managerName: '',
        pbNum: ''
      });
      setKeyword('');
    }
  };
  const handleSelectClient = (e, item) => {
    const itemIndex = value?.findIndex((i) => i.index === item.index);
    setValue((oldItem) => [
      ...oldItem.slice(0, itemIndex),
      {
        ...oldItem.slice(itemIndex, itemIndex + 1)?.[0],
        clientChannel: e?.clientChannel,
        clientName: e?.clientName,
        clientCode: e?.clientCode,
        minibarNickname: !e && '',
        minibarCode: !e && ''
      },
      ...oldItem.slice(itemIndex + 1)
    ]);
  };
  const handleSelectMinibar = (e, item) => {
    const itemIndex = value?.findIndex((i) => i.index === item.index);
    setValue((oldItem) => [
      ...oldItem.slice(0, itemIndex),
      {
        ...oldItem.slice(itemIndex, itemIndex + 1)?.[0],
        minibarNickname: e?.minibarNickname,
        minibarCode: e?.minibarCode
      },
      ...oldItem.slice(itemIndex + 1)
    ]);
  };
  return (
    <Box>
      <Stack flexDirection="row" justifyContent="center" alignItems="center">
        <Stack
          sx={{
            border: '0.5px solid #D3D3D3',
            display: 'inline-flex',
            borderRadius: '8px',
            padding: '0px !important',
            height: '100%',
            overflowY: 'auto',
            mb: 4
          }}
        >
          <Stack flexDirection="row">
            <FieldContainer required label={t('pages.display.pic')}>
              <MyAutocomplete
                sx={{ width: 200 }}
                disabled={action}
                value={gValue}
                name="picName"
                isOptionEqualToValue={(option, value) =>
                  option.managerName === value?.managerName
                }
                getOptionLabel={(option) => option?.managerName || ''}
                onChange={(e, val) => handleSelectPic(val)}
                onInputChange={handleInputChange}
                options={pic}
                errMg={errMg?.managerCode}
              />
            </FieldContainer>
            <FieldContainer label={t('pages.inventory.date')}>
              <Calendar
                disabled={action}
                sx={{ inputStyle: { width: 200 } }}
                date={
                  gValue?.shippingDate ? new Date(gValue?.shippingDate) : date
                }
                setDate={(e) => {
                  setDate(new Date(e));
                  setGValue({
                    ...gValue,
                    shippingDate: formatTime(new Date(e), dateFormat)
                  });
                }}
              />
            </FieldContainer>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          ':last-child': {
            borderBottom: '1px solid #D3D3D3'
          }
        }}
      >
        {value.map((item, index) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={`${item?.clientCode}${item?.minibarCode}${index}`}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ borderTop: '1px solid #D3D3D3', height: 60, width: '100%' }}
            gap={2}
          >
            {action !== 'edit' && action !== 'register-detail' && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#FCFCFC',
                  height: '100%',
                  maxWidth: '200px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#6F869C',
                    padding: '0 10px',
                    fontWeight: 600,
                    width: 100
                  }}
                >
                  {t('pages.display.schedule')} {index + 1}
                </Typography>
              </Stack>
            )}

            <Stack>
              <ClientAutoCompleteV2
                sx={{ width: 250 }}
                setClient={(e) => handleSelectClient(e, item)}
                client={
                  item?.clientCode
                    ? value?.filter((i) => i.index === item?.index)[0]
                    : { clientName: item?.clientName || '' }
                }
                ListboxProps={{
                  style: {
                    maxHeight: '350px'
                  }
                }}
                // eslint-disable-next-line react/no-unstable-nested-components
                PopperComponent={(props) => (
                  <Portal>
                    <Popper {...props} />
                  </Portal>
                )}
              />
            </Stack>
            <Stack>
              <MinibarAutoCompleteV2
                sx={{ width: 250 }}
                minibar={
                  item?.minibarCode
                    ? value?.filter((i) => i.index === item?.index)[0]
                    : {
                        minibarNickname: item?.minibarNickname || ''
                      }
                }
                setMinibar={(e) => handleSelectMinibar(e, item)}
                client={value?.filter((i) => i.index === item?.index)[0]}
                disabled={
                  item?.clientChannel === 'B2B' ||
                  !item?.clientCode ||
                  !value?.filter((i) => i.index === item?.index)[0]
                }
                ListboxProps={{
                  style: {
                    maxHeight: '350px'
                  }
                }}
                // eslint-disable-next-line react/no-unstable-nested-components
                PopperComponent={(props) => (
                  <Portal>
                    <Popper {...props} />
                  </Portal>
                )}
              />
            </Stack>
            <MyTextField
              label={t('pages.display.area')}
              name="managerArea"
              value={gValue?.managerArea}
              InputProps={{
                readOnly: true
              }}
            />
            <MyTextField
              label={t('pages.display.section')}
              name="managerZone"
              value={gValue?.managerZone}
              InputProps={{
                readOnly: true
              }}
            />
            <Stack sx={{ visibility: 'hidden' }}>x</Stack>
            {!action && (
              <Stack flexDirection="row" gap={1}>
                <IconButton
                  sx={{ backgroundColor: '#F2F2F2' }}
                  onClick={() => removeRow(index)}
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton
                  className="addMore"
                  color="primary"
                  sx={{
                    backgroundColor: '#e3e7ff',
                    ':hover': { backgroundColor: '#F2F4FF' },
                    visibility: value.length !== index + 1 && 'hidden'
                  }}
                  onClick={addRow}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

const FieldContainer = ({ children, label, required }) => {
  return (
    <Stack direction="row" alignItems="center" mr={2} width="100%">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 70,
          mr: 2,
          backgroundColor: '#FCFCFC',
          maxWidth: '200px'
        }}
      >
        <Typography
          sx={{
            width: 70,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>{children}</Stack>
    </Stack>
  );
};

export default ScheduleForm;
