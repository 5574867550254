import RestService from './RestService';

const adServices = {
  async getListAdVersion(params = {}) {
    try {
      const res = await new RestService()
        .setPath('advertisement')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getAdVersion(adCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`advertisement/${adCode}`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createAd(data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`advertisement`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateAd(adCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`advertisement/${adCode}`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default adServices;
