/* eslint-disable no-unused-expressions */
import HelmetContainer from '@components/HelmetContainer';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchBar from '@components/SearchBar';
import {
  ClientAutoCompleteV2,
  MinibarAutoCompleteV2
} from '@components/MyAutocomplete';
import MyTextField from '@components/MyTextField';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  CircularProgress,
  Checkbox
} from '@mui/material';
import MyDialog from '@components/MyDialog';
import DateRangePicker from '@components/DateRangePicker';
import { useDispatch } from 'react-redux';
import { sub } from 'date-fns';
import { PICKING_STATUSES, dateFormat } from '@constants/utils';
import MyTable from '@components/MyTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formatter } from '@utils/formatter';
import pickingService from '@services/picking';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import MySelect from '@components/MySelect';

import {
  compareDates,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import {
  statusRadios,
  columns,
  sizeSelect,
  dateTypeSelectOptions
} from './enhance';

const Picking = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const configColumns = columns;
  const [param, setParam] = useState({
    minibarCode: null,
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    clientCode: null,
    managerName: null,
    searchDateBy: 'regDt',
    status: 'StandBy',
    page: 0
  });
  const [condition, setCondition] = useState('regDt');
  const options = sizeSelect(t);
  const [selected, setSelected] = useState({
    individual: t('pages.picking.indiList'),
    total: t('pages.picking.totalList'),
    indiAction: 'list',
    totalAction: 'list'
  });

  const [dialog, setDialog] = useState({
    open: false,
    action: ''
  });
  const [state, setState] = React.useState({
    items: []
  });
  const [client, setClient] = useState(null);
  const [minibar, setMinibar] = useState(null);

  const { items, pagination } = state;

  const [loading, setLoading] = useState(false);

  const [selectCnt, setSelectCnt] = useState(0);
  const [selectItems, setSelectedItems] = useState([]);

  const exportPicking = async () => {
    const selectMap = items.filter((v) => v.select).map((q) => q.pickingCode);
    const rs = await pickingService.exportPicking(selectMap);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
    setLoading(false);
  };

  const handleClickTable = (item, name) => {
    if (name !== 'select') {
      navigate(item?.pickingCode);
    } else {
      setSelectedItems((prev) => {
        const updatedSelectItems = prev.includes(item?.pickingCode)
          ? prev.filter((code) => code !== item?.pickingCode) // Remove item if already selected
          : [...prev, item?.pickingCode]; // Add item if not selected

        setSelected({
          ...selected,
          individual:
            updatedSelectItems.length > 0
              ? t('pages.picking.indiRegister')
              : t('pages.picking.indiList'),
          total:
            updatedSelectItems.length > 1
              ? t('pages.picking.totalRegister')
              : t('pages.picking.totalList'),
          indiAction: updatedSelectItems.length > 0 ? 'register' : 'list',
          totalAction: updatedSelectItems.length > 1 ? 'register' : 'list'
        });

        setSelectCnt(updatedSelectItems.length);
        return updatedSelectItems;
      });
    }
  };

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    getPickingList({ ...param, page });
    navigate(query);
  };
  const getPickingList = async (param) => {
    let totalCnt = 0;
    let indiCnt = 0;
    const rs = await pickingService.getPickingList(param);

    if (rs.data?.items) {
      rs.data?.items.forEach((v) => {
        v.select = false;

        if (v?.groupBy) {
          if (v.groupBy.toLowerCase() === 'total') {
            totalCnt += 1;
          } else if (v.groupBy.toLowerCase() === 'individual') {
            indiCnt += 1;
          }
        }
      });
    }

    if (!rs?.error) {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        },
        totalCnt, // Save the total count to state
        indiCnt // Save the individual count to state
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      clientCode,
      minibarCode,
      searchDateBy,
      page,
      status,
      managerName
    } = query;
    setCondition((searchDateBy === 'shippingDt' && 'shippingDt') || 'regDt');
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      clientCode: clientCode || null,
      minibarCode: minibarCode || null,
      searchDateBy,
      page: page - 1 || 0,
      status: status || null,
      managerName
    };
    setClient({ ...client, clientCode: clientCode || null });
    setMinibar({ ...minibar, minibarCode: minibarCode || null });
    setParam(dataParams);
    getPickingList(dataParams);
  }, [location?.search]);

  const handleSearch = () => {
    setSelectCnt(0);
    const dataSubmit = {
      ...param,
      clientCode: client?.clientCode,
      minibarCode: minibar?.minibarCode,
      searchDateBy: condition,
      page: 0
    };
    const query = objToParams(ignoreEmpty({ ...dataSubmit, page: 1 }));
    getPickingList(dataSubmit);
    navigate(query);
  };

  const handleSelectRowSize = (size) => {
    if (param?.size === size) return;
    setParam({ ...param, size });
    getPickingList({ ...param, size });
  };
  const handleChangeManager = (e) => {
    setParam({
      ...param,
      managerName: e.target.value || null
    });
  };
  const handleChangeDate = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };
  const resetState = () => {
    setSelectedItems([]);
    setSelected({
      individual: t('pages.picking.indiList'),
      total: t('pages.picking.totalList'),
      indiAction: 'list',
      totalAction: 'list'
    });
  };

  const getActionButtons = (item) => {
    return (
      <Checkbox
        checked={item.select}
        sx={{
          visibility: item?.status !== PICKING_STATUSES.REQUESTED && 'hidden'
        }}
        onClick={(e) => {
          e.stopPropagation();

          item.select = !item.select;
          handleClickTable(item, 'select');
          setSelectCnt(items.filter((v) => v.select).length);
        }}
      />
    );
  };

  const handleButtonActions = (action) => {
    switch (action) {
      case 'individual':
        selected?.indiAction === 'list'
          ? navigate('individual')
          : createIndividualPicking();
        break;

      case 'total':
        selected?.totalAction === 'list'
          ? navigate('total')
          : createTotalPickings();
        break;

      default:
        break;
    }
  };
  // total + individual picking handling

  const createTotalPickings = async () => {
    // selected list check items

    // const isValidToRegister = items.filter(
    //   (item) => item?.status !== PICKING_STATUSES.REQUESTED
    // );
    // if (!isValidToRegister) return;
    // create total pickings
    const dataSubmit = { pickingCodes: selectItems };
    const rs = await pickingService.createTotalPickings(dataSubmit);

    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
      resetState();
    } else {
      setSelectedItems([]);
      dispatch(setSuccess({ message: t('info.register.content') }));
      resetState();
      getPickingList(param);
    }
  };

  const createIndividualPicking = async () => {
    // selected list check items
    if (selectItems.length < 1) return;

    const isValidToRegister = selectItems.filter(
      (item) => item?.status !== PICKING_STATUSES.REGISTERED
    );
    if (!isValidToRegister) return;
    // create individualPicking pickings

    const rs = await pickingService.createIndividualPicking({
      pickingCodes: selectItems
    });

    if (!rs?.error) {
      dispatch(setSuccess({ message: t('info.register.content') }));
      resetState();
      getPickingList(param);
    } else {
      dispatch(setError(error2Text(rs?.error)));
      resetState();
    }
  };
  return (
    <HelmetContainer title={t('pageTitle.picking')} content="Picking page">
      <SearchBar>
        <ClientAutoCompleteV2
          client={client}
          setClient={setClient}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          sx={{ maxWidth: 220 }}
        />
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={setMinibar}
          client={client}
          disabled={!client?.clientCode}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
          sx={{ maxWidth: 200 }}
        />
        <MyTextField
          label={t('pages.display.pic')}
          value={param?.managerName || ''}
          onChange={handleChangeManager}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <MySelect
          label={t('labels.period')}
          data={dateTypeSelectOptions}
          value={condition}
          onChange={(e) => {
            setCondition(e.target.value);
          }}
        />
        <Stack flexDirection="row" alignItems="center">
          <FormControl>
            <FormControlLabel
              labelPlacement="start"
              sx={{
                '.MuiTypography-root': {
                  color: '#6F869C',
                  fontWeight: 'fontWeightBold',
                  fontSize: 13,
                  whiteSpace: 'nowrap',
                  mr: 1
                }
              }}
              control={
                <DateRangePicker
                  state={{
                    startDate: new Date(param.startDate),
                    endDate: new Date(param.endDate),
                    key: 'selection'
                  }}
                  setState={handleChangeDate}
                />
              }
            />
          </FormControl>
          <FormControlLabel
            label={t('pages.inventory.today')}
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap'
              },
              ml: 2
            }}
            control={
              <Radio
                checked={
                  !compareDates(param.startDate, param.endDate) &&
                  !compareDates(
                    param.startDate,
                    formatTime(new Date(), dateFormat)
                  )
                }
                onChange={() =>
                  setParam({
                    ...param,
                    startDate: formatTime(new Date(), dateFormat),
                    endDate: formatTime(new Date(), dateFormat)
                  })
                }
                size="small"
              />
            }
          />
        </Stack>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1.5
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {statusRadios.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({ ...param, status: e.target.value || null })
                        }
                        checked={param.status === item.value}
                        size="small"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: '15px', mx: 1 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: '60%', gap: 1 }}
        >
          <Button
            onClick={() => exportPicking()}
            variant="outlined"
            startIcon={loading && <CircularProgress size="20px" />}
            disabled={loading || selectCnt <= 0}
            sx={{ width: 'auto' }}
          >
            {t('button.saveExcel')} {selectCnt > 0 ? `(${selectCnt})` : ''}
          </Button>
          <Stack direction="row" sx={{ gap: 1 }}>
            <Button
              onClick={() => handleButtonActions('individual')}
              variant={selectItems?.length > 0 ? 'contained' : 'outlined'}
            >
              {selected?.individual}
            </Button>
            <Button
              onClick={() => handleButtonActions('total')}
              variant={selectItems?.length > 1 ? 'contained' : 'outlined'}
            >
              {selected?.total}
            </Button>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: 'auto', gap: 1 }}
        >
          <Stack>
            <Typography
              sx={{
                fontWeight: 'fontWeightSemiMedium',
                fontSize: 15,
                color: 'text.primary'
              }}
              component="span"
            >
              {t('common.number-of-results')}{' '}
              <Typography
                component="span"
                sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
              >
                {state?.pagination?.totalRow}
              </Typography>
            </Typography>
          </Stack>
          <Stack>
            <MySelect
              value={param?.size || 20}
              data={options}
              sx={{ width: 'auto' }}
              onChange={(e) => handleSelectRowSize(e?.target?.value)}
            />
          </Stack>
        </Stack>
      </Stack>
      <MyTable
        hover
        rerender
        columns={configColumns}
        data={state?.items}
        pagination={pagination}
        onRowClick={handleClickTable}
        getActionButtons={getActionButtons}
        minusHeight={270}
        onChangePage={(e, page) => handleChangePage(page)}
      />

      {/* dialog */}
      <MyDialog
        open={dialog?.open}
        setOpen={() => setDialog({ ...dialog, open: false })}
        isAlert
        title={t('dialog.title.areYouSureDeleteThisRecord')}
        hasCancelButton
      />
    </HelmetContainer>
  );
};

export default Picking;
