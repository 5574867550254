import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const pricePolicyService = {
  async getPricePolicyList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/price-policy')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async editPricePolicy(data) {
    try {
      const res = await new RestService()
        .setPath(`/price-policy/${data?.productCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportData(params = {}) {
    try {
      const res = await new RestService()
        .setPath('price-policy/export-price-policy')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportSample() {
    try {
      const res = await new RestService()
        .setPath('price-policy/export-sample')
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async importPricePolicy(data) {
    try {
      const res = await new RestService()
        .setPath('price-policy/import')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default pricePolicyService;
