import React from 'react';
import { Box, Container, Stack, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import InputFile from '@components/InputFile';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { notificationSchema } from '../../enhance';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});
const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  justifyContent: 'center',
  width: '100%',
  margin: '10px 0px'
};
const labelStyle = {
  backgroundColor: '#FCFCFC'
};
const NotificationForm = (props) => {
  const { onChange, data } = props;
  return (
    <Container component={BorderBox}>
      <Grid container>
        {notificationSchema.map((item) => (
          <Grid md={item.grid || 12}>
            <Stack
              direction="row"
              sx={{
                borderLeft: { xs: 'none', md: '1px solid #D3D3D3' },
                width: { xs: '100%', md: 'auto' },
                flexGrow: 1,
                borderBottom: { xs: 'none', md: '1px solid #D3D3D3' },
                ...item.border
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  flexGrow: 1,
                  padding: '0 10px',
                  ...labelStyle
                }}
              >
                <Typography
                  sx={{
                    width: 150,
                    fontSize: 13,
                    color: '#6F869C',
                    textAlign: 'center'
                  }}
                >
                  {item.label}
                </Typography>
              </Stack>
              <RenderField
                {...item}
                value={data?.[item?.name]}
                onChange={onChange}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const RenderField = ({ onChange, data, ...item }) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { label, ...rest } = item;
  if (item.type === 'upload')
    return <InputFile sx={{ root: { height: 150, width: '100%' } }} />;
  if (item.type === 'select')
    return (
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          ...inputStyle
        }}
      >
        <MySelect
          multiple={item.multiple}
          {...rest}
          data={data}
          sx={{ width: '100%' }}
        />
      </Stack>
    );
  return (
    <MyTextField
      size="small"
      {...rest}
      sx={{ ...inputStyle }}
      onChange={onChange}
    />
  );
};

export default NotificationForm;
