import { tableTypes } from '@constants/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Formatter } from '@utils/formatter';

export const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  overflowY: 'auto'
});
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 15,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120
    },
    td: {
      p: '15px 12px',
      width: 270
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
  // Actions to take jin  the table
};

export const MobileTableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 10,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: '40%'
    },
    td: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      p: '15px 12px',
      width: '60%'
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
  // Actions to take jin  the table
};
export const actions = {
  updateYn: 'updateYn',
  kioskYn: 'kioskYn',
  redoYn: 'redoYn',
  outYn: 'outYn',
  endYn: 'endYn',
  logoYn: 'logoYn',
  restart: 'restart',
  hwRestart: 'hwRestart',
  inspectYn: 'inspectYn',
  delete: 'delete'
};
export const inputStyle = {
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  margin: '10px 0px'
};

export const kioskConfigColumns = (actionComponent, t) => [
  {
    name: 'kioskNickname',
    label: t('pages.kiosk.labels.nickname'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'minibarNickname',
    label: t('pages.kiosk.labels.minibarNickname'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'appNickname',
    label: t('pages.kiosk.labels.appNickname'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'appVersion',
    label: t('pages.kiosk.labels.currentAppVersion'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'latestAppVersion',
    label: t('pages.kiosk.labels.latestAppVersion'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'adNickname',
    label: t('pages.kiosk.labels.adNickname'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'adVersion',
    label: t('pages.kiosk.labels.adVersion'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'anydeskNickname',
    label: t('pages.minibar.lastConnectionTime'),
    minWidth: 120,
    align: 'center',
    render: ({ lastSignalTime }) => Formatter.formatTime(lastSignalTime)
  },
  {
    name: 'anydeskNickname',
    label: t('pages.kiosk.labels.anydesk-id'),
    minWidth: 160,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: 1
  },
  {
    name: 'updateStatus',
    label: t('pages.kiosk.labels.updateStatus'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.updateYn
  },

  {
    name: 'kioskMode',
    label: t('pages.kiosk.labels.kioskMode'),
    minWidth: 75,
    cathover: true,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.kioskYn
  },

  {
    name: 'redoMode',
    label: t('pages.kiosk.labels.restartMode'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.redoYn
  },
  {
    name: 'screenOutageMode',
    label: t('pages.kiosk.labels.screenOutageMode'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.outYn
  },
  {
    name: 'originServer',
    label: t('pages.kiosk.labels.originServer'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.endYn
  },
  {
    name: 'logoRelated',
    label: t('pages.kiosk.labels.logoRelated'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.logoYn
  },
  {
    name: 'restart',
    label: t('pageTitle.kiosk'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.restart
  },
  {
    name: 'hwRestart',
    label: t('pages.kiosk.labels.hwRestart'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.hwRestart
  },

  {
    name: 'inspection',
    label: t('pages.kiosk.labels.inspection'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.inspectYn
  },
  // {
  //   name: 'typeName',
  //   label: t('pages.kiosk.labels.typeName'),
  //   minWidth: 150,
  //   align: 'center',
  //   onClick: ({ kioskCode }) => selectKiosk(kioskCode)
  // },

  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 60,
    align: 'center',
    render: ({ kioskCode, isNeedRepair }) =>
      actionComponent(kioskCode, isNeedRepair === 'Y')
  },
  {
    name: 'inspection',
    label: t('button.delete'),
    minWidth: 75,
    align: 'center',
    type: tableTypes.ACTION,
    actionType: actions.delete
  }
];
export const errorHistoryConfigColumns = (actionComponent, t) => [
  {
    name: '#',
    label: t('pages.kiosk.labels.No'),
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'errorDate',
    label: t('pages.kiosk.labels.errorDate'),
    minWidth: 160,
    align: 'center',
    render: ({ errorDate }) =>
      errorDate
        ? new Date(errorDate).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'repairDate',
    label: t('pages.kiosk.labels.repairDate'),
    minWidth: 160,
    align: 'center',
    render: ({ repairDate }) =>
      repairDate
        ? new Date(repairDate).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  {
    name: 'errorContent',
    label: t('pages.kiosk.labels.errorContent'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'repairContent',
    label: t('pages.kiosk.labels.repairContent'),
    minWidth: 160,
    align: 'center'
  },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 60,
    align: 'center',
    render: ({ errorCode, repairYn }) =>
      actionComponent(errorCode, repairYn === 'Y')
  }
];
export const mobileColumns = (t) => [
  {
    name: 'kioskNickname',
    label: t('pages.kiosk.labels.nickname'),
    minWidth: 80,
    align: 'center'
  },
  // {
  //   name: 'minibarNickname',
  //   label: t('pages.kiosk.labels.minibarNickname'),
  //   minWidth: 80,
  //   align: 'center'

  // },
  {
    name: 'anydeskNickname',
    label: t('pages.minibar.lastConnectionTime'),
    minWidth: 100,
    align: 'center',
    render: ({ lastSignalTime }) => Formatter.formatTime(lastSignalTime)
  },
  // {
  //   name: 'updateStatus',
  //   label: t('pages.kiosk.labels.updateStatus'),
  //   minWidth: 60,
  //   align: 'center',
  //   render: (item) => {
  //     return (
  //       <Checkbox
  //         checked={item?.updateYn === 'Y'}
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           item.updateYn = item.updateYn === 'Y' ? 'N' : 'Y';
  //           onSubmit(actions.updateStatus, item);
  //         }}
  //       />
  //     );
  //   }
  // },
  {
    label: t('pages.kiosk.labels.Management'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const updateActionRows = (t) => [
  {
    name: 'restart',
    label: t('pages.kiosk.labels.restart')
  },
  {
    name: 'hwRestart',
    label: t('pages.kiosk.labels.hwRestart')
  },
  {
    name: 'updateKioskMode',
    label: t('pages.kiosk.labels.kioskMode'),
    field: 'kioskYn'
  },

  {
    name: 'restartYn',
    label: t('pages.kiosk.labels.restartMode'),
    field: 'redoYn'
  },
  {
    name: 'screenOutageMode',
    label: t('pages.kiosk.labels.screenOutageMode'),
    field: 'outYn'
  },
  {
    name: 'updateOriginServer',
    label: t('pages.kiosk.labels.originServer'),
    minWidth: 75,
    field: 'endYn'
  },
  {
    name: 'updateLogoYn',
    label: t('pages.kiosk.labels.logoRelated'),
    minWidth: 75,
    field: 'logoYn'
  },
  {
    name: 'anydeskId',
    label: t('pages.kiosk.labels.anydesk-id'),
    field: 'anydeskId'
  },
  {
    name: 'updateInspection',
    label: t('pages.kiosk.labels.inspection'),
    field: 'inspectYn'
  }
];
