export const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0465 7.92C10.4575 7.92 10.7907 8.24236 10.7907 8.64V14.76C10.7907 15.1576 10.4575 15.48 10.0465 15.48C9.63551 15.48 9.30233 15.1576 9.30233 14.76V8.64C9.30233 8.24236 9.63551 7.92 10.0465 7.92Z"
        fill="#656565"
      />
      <path
        d="M6.69767 8.64C6.69767 8.24236 6.36449 7.92 5.95349 7.92C5.54249 7.92 5.2093 8.24236 5.2093 8.64V14.76C5.2093 15.1576 5.54249 15.48 5.95349 15.48C6.36449 15.48 6.69767 15.1576 6.69767 14.76V8.64Z"
        fill="#656565"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51163 0C5.68962 0 5.02326 0.64471 5.02326 1.44V1.62H1.48837C0.666367 1.62 0 2.26471 0 3.06V5.04C0 5.77433 0.568128 6.38028 1.30233 6.46886V16.56C1.30233 17.3553 1.96869 18 2.7907 18H13.2093C14.0313 18 14.6977 17.3553 14.6977 16.56V6.46886C15.4319 6.38028 16 5.77433 16 5.04V3.06C16 2.26471 15.3336 1.62 14.5116 1.62H10.9767V1.44C10.9767 0.64471 10.3104 0 9.48837 0H6.51163ZM13.2093 6.48H2.7907V16.56H13.2093V6.48ZM14.5116 5.04V3.06H10.2326C9.82156 3.06 9.48837 2.73765 9.48837 2.34V1.44H6.51163V2.34C6.51163 2.73765 6.17844 3.06 5.76744 3.06H1.48837V5.04H14.5116Z"
        fill="#656565"
      />
    </svg>
  );
};
