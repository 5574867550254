import { Button, CircularProgress, Stack } from '@mui/material';
import React, { useState } from 'react';

const MyLoadingButton = (props) => {
  const { onClick, children, disabled, timeout = 500, type, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (timeout === 0) {
      onClick();
      return;
    }
    setLoading(true);
    await onClick();
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  if (type === 2) {
    return (
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        {loading ? <CircularProgress size={20} /> : children}
      </Stack>
    );
  }

  return (
    <Button
      startIcon={loading && <CircularProgress size={20} />}
      disabled={disabled || loading}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default React.memo(MyLoadingButton);
