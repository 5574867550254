import palette from '@theme/palette';
import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.dashboard');

export const labels = [
  t('january'),
  t('february'),
  t('march'),
  t('april'),
  t('may'),
  t('june'),
  t('july'),
  t('august'),
  t('september'),
  t('october'),
  t('november'),
  t('december')
];

export const b2DummyData = labels.map(() => Math.floor(Math.random() * 15));
export const lineDummyData = labels.map(() => Math.random() * 120);
export const barDummnyData = labels.map(() => Math.random() * 500);

export const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const mixedChartEnhance = {
  getMixedChartData: ({ lineData, barData }) => ({
    labels: labels.map((_, index) => parseInt(index, 10) + 1),
    datasets: [
      {
        type: 'line',
        label: t('numberOfPurchases'),
        backgroundColor: 'white',
        borderColor: '#0044FE',
        borderWidth: 1,
        fill: false,
        data: lineData,
        yAxisID: 'y1'

        // pointRadius: 3, // points size
        // pointStyle: 'circle'
      },
      {
        type: 'bar',
        label: t('numberOfActiveMembers'),
        backgroundColor: '#429AEB',

        data: barData,
        yAxisID: 'y',
        barThickness: 16

        // pointStyle: 'rect'
      }
    ]
  }),
  options: {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 3,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x'
    },
    scales: {
      y: {
        ticks: {
          color: '#979797',
          callback(value, index, values) {
            if (index + 1 === values.length) {
              return '(건)';
            }
            return value;
          }
        },
        grid: {
          display: true,
          color: '#F8F8F8'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          callback(value, index, values) {
            if (index + 1 === values.length) {
              return '(명)';
            }
            return value;
          }
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container'
      },
      title: {
        display: true,
        text: t('mixedChartTitle'),
        align: 'start',
        color: palette.text.primary,
        padding: {
          bottom: 28
        },
        font: {
          weight: 700,
          size: 16,
          family: 'Noto Sans KR',
          lineHeight: '24px'
        }
      },
      tooltip: {
        // usePointStyle: true,
        callbacks: {
          title: (tooltipItems) => labels[tooltipItems[0].label - 1]
        }
      }
    }
  },
  htmlLegendPlugin: {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = `${item.lineWidth}px`;
        boxSpan.style.borderStyle = 'solid';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '6px';
        boxSpan.style.marginRight = '4px';
        boxSpan.style.width = '9px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.fontSize = '11px';
        textContainer.style.lineHeight = '16px';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  }
};

export const getChartData = (props) => ({
  labels: labels.map((_, index) => parseInt(index, 10) + 1),
  datasets: [
    {
      label: t('numberOfVotes'),
      barThickness: 16,
      ...props
    }
  ]
});

export const b2ChartEnhance = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 5,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x'
    },
    scales: {
      y: {
        ticks: {
          color: '#979797'
        },
        grid: {
          display: true,
          color: '#F8F8F8'
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => labels[tooltipItems[0].label - 1]
        }
      }
    }
  },
  getB2CData: (data) => ({
    title: 'B2C',
    color: '#4A8BEC',
    data: getChartData({ data, backgroundColor: '#4A8BEC' })
  }),
  getB2B2CData: (data) => ({
    title: 'B2B2C',
    color: '#308EC2',
    data: getChartData({ data, backgroundColor: '#308EC2' })
  }),
  getB2BData: (data) => ({
    title: 'B2B',
    color: '#17B3B3',
    data: getChartData({ data, backgroundColor: '#17B3B3' })
  })
};
