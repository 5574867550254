import RestService from '@services/RestService';

const restockService = {
  async getRestockList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/returning')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getRestockHistory(params = {}) {
    try {
      const res = await new RestService()
        .setPath('returning/history')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async stockRegister(data) {
    try {
      const res = await new RestService()
        .setPath('/returning/ware-house')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateReason(returningCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/returning/${returningCode}/reason`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default restockService;
