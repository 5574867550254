import RestService from '@services/RestService';

const categoryService = {
  // Store

  async getStoreList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store-type')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreProductList(storeTypeCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-category`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreOptions(storeTypeCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-info`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createStoreType(data) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store-type')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createStoreProductCategory(storeTypeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-category`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createStoreOptions(storeTypeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-info`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStoreType(storeTypeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStoreProductCategory(storeTypeCode, categoryCode, data) {
    try {
      const res = await new RestService()
        .setPath(
          `/preorder/store-type/${storeTypeCode}/product-category/${categoryCode}`
        )
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteStoreType(storeTypeCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteStoreProductCategory(storeTypeCode, categoryCode) {
    try {
      const res = await new RestService()
        .setPath(
          `/preorder/store-type/${storeTypeCode}/product-category/${categoryCode}`
        )
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default categoryService;
