import RestService from '@services/RestService';

const usageHistoryService = {
  async getUsageHistory(data) {
    try {
      const res = await new RestService().setPath('lock-log/list').post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default usageHistoryService;
