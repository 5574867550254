import React from 'react';
import { Chart } from 'react-chartjs-2';
import { mixedChartEnhance } from './enhance';

const { getMixedChartData, options, htmlLegendPlugin } = mixedChartEnhance;

const MixedChart = ({ data }) => {
  const mixedChartData = getMixedChartData({
    lineData: data?.purchases?.map((item) => item?.number),
    barData: data?.members?.map((item) => item?.number)
  });

  return (
    <div style={{ width: '100%', height: '200px', position: 'relative' }}>
      <div id="legend-container" style={{ position: 'absolute', right: 0 }} />
      {/* <Typography
        sx={{
          fontWeight: 'fontWeightBold',
          fontSize: 16,
          color: 'text.primary',
          mb: 2
        }}
      >
        월별 구매건수/Active회원수
      </Typography> */}
      <Chart
        type="bar"
        data={mixedChartData}
        options={options}
        plugins={[htmlLegendPlugin]}
      />
    </div>
  );
};

export default MixedChart;
