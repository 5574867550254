import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';

import developmentServices from '@services/development';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import InputMultipleFileV2 from '@components/InputMultipleFile/InputMultipleFileV2';

import { tableStyle } from './enhance';

const RegisterDevelopmentRequestDialog = ({ open, setOpen }) => {
  const { t: pageT } = useTranslation(null, {
    keyPrefix: 'pages.development-request'
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reqSubject, setReqSubject] = useState('');
  const [reqContent, setReqContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({
    reqSubject: '',
    reqContent: ''
  });

  const validateForm = () => {
    let check = true;
    const msg = { ...errMsg };
    if (!reqSubject) {
      msg.reqSubject = t('common.required');
      check = false;
    }
    if (!reqContent) {
      msg.reqContent = t('common.required');
      check = false;
    }
    if (reqSubject.length > 40) {
      msg.reqSubject = t('validation.dataTooLong', { max: 40 });
      check = false;
    }
    if (reqContent.length > 1500) {
      msg.reqContent = t('validation.dataTooLong', { max: 1500 });
      check = false;
    }
    setErrMsg({ ...msg });
    return check;
  };

  const handleCreateDevelopementRequest = async () => {
    setLoading(true);
    const result = await developmentServices.create({
      reqSubject,
      reqContent,
      files: fileList
    });
    setLoading(false);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else setOpen(false);
  };

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={pageT('register-dev-request')}
      okTitle={t('button.register')}
      hasCloseButton
      onOk={() => {
        if (validateForm()) {
          dispatch(
            onOpenConfirm({
              open: true,
              type: 'save',
              action: () => handleCreateDevelopementRequest()
            })
          );
        }
      }}
      loading={loading}
      sx={{
        maxWidth: 660,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
    >
      <TableContainer>
        <Table>
          <TableBody sx={tableStyle}>
            <TableRow>
              <TableCell component="th" scope="row">
                {pageT('title')}
              </TableCell>
              <TableCell>
                <MyTextField
                  errMg={errMsg?.reqSubject}
                  value={reqSubject}
                  onChange={(e) => {
                    setReqSubject(e.target.value);
                    setErrMsg({
                      ...errMsg,
                      reqSubject: e.target.value ? '' : t('common.required')
                    });
                  }}
                  fullWidth
                  sx={{ width: '100%' }}
                  disabled={loading}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {pageT('attachments')}
              </TableCell>
              <TableCell sx={{ p: '4px 12px !important' }}>
                <InputMultipleFileV2
                  title={t('button.select-files')}
                  files={fileList}
                  setFiles={setFileList}
                  disabled={loading}
                  sx={{ width: 450 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {pageT('request')}
              </TableCell>
              <TableCell>
                <MyTextField
                  errMg={errMsg?.reqContent}
                  value={reqContent}
                  onChange={(e) => {
                    setReqContent(e.target.value);
                    setErrMsg({
                      ...errMsg,
                      reqContent: e.target.value ? '' : t('common.required')
                    });
                  }}
                  fullWidth
                  multiline
                  rows={6}
                  disabled={loading}
                  sx={{ width: '100%' }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default RegisterDevelopmentRequestDialog;
