import MyTextField from '@components/MyTextField';
import { Box, Container, Stack, Typography, styled } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

const BorderBox = styled(Box)({
  border: '0.5px solid #D3D3D3',
  borderRadius: '8px',
  padding: '0px !important',
  height: '100%',
  overflowY: 'auto'
});

const labelStyle = {
  backgroundColor: '#FCFCFC',
  maxWidth: '200px'
};

const maxLengthOf = {
  employeeNumber: 20,
  employeeName: 50,
  employeePhone: 15,
  employeeArea: 50,
  employeeZone: 50
};

const requiredFields = ['employeeNumber', 'employeeName'];

const PicForm = ({ value, setValue, errMg, setErrMg }) => {
  const { t } = useTranslation();

  const onChange = ({ target: { name, value: valuee } }) => {
    setValue({ ...value, [name]: valuee });

    let errorMessage = '';
    if (!valuee && requiredFields.includes(name)) {
      errorMessage = t('common.required');
    }
    if (valuee?.length > maxLengthOf[name]) {
      errorMessage = t('validation.dataTooLong', { max: maxLengthOf[name] });
    }

    setErrMg({
      ...errMg,
      [name]: errorMessage
    });
  };

  return (
    <Container component={BorderBox}>
      <FieldContainer required label={t('pages.display.employNum')}>
        <MyTextField
          sx={{ mt: 1 }}
          value={value?.employeeNumber}
          onChange={onChange}
          name="employeeNumber"
          errMg={errMg?.employeeNumber}
        />
      </FieldContainer>
      <FieldContainer required label={t('pages.welfare-points.name')}>
        <MyTextField
          sx={{ mt: 1 }}
          value={value?.employeeName}
          onChange={onChange}
          name="employeeName"
          errMg={errMg?.employeeName}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.display.area')}>
        <MyTextField
          sx={{ mt: 1 }}
          value={value?.employeeArea}
          onChange={onChange}
          name="employeeArea"
          errMg={errMg?.employeeArea}
        />
      </FieldContainer>
      <FieldContainer label={t('pages.display.section')}>
        <MyTextField
          sx={{ mt: 1 }}
          value={value?.employeeZone}
          onChange={onChange}
          name="employeeZone"
          errMg={errMg?.employeeZone}
        />
      </FieldContainer>
      <FieldContainer label={t('common.phoneNo')}>
        <MyTextField
          sx={{ mt: 1 }}
          value={value?.employeePhone}
          onChange={onChange}
          typeValue="onlyNumber"
          name="employeePhone"
          errMg={errMg?.employeePhone}
        />
      </FieldContainer>
    </Container>
  );
};

const FieldContainer = ({ children, label, required }) => {
  return (
    <Stack direction="row" mr={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          height: 70,
          mr: 2,
          ...labelStyle
        }}
      >
        <Typography
          sx={{
            width: 140,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {label}
          {required && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Typography>
      </Stack>
      <Stack sx={{ width: '100%' }}>{children}</Stack>
    </Stack>
  );
};

export default PicForm;
