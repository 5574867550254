import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const storingService = {
  async exportStoringItems(params = {}) {
    try {
      const res = await new RestService()
        .setPath('storing/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoringList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('storing')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  //  수량
  async updateQuantity(code, data) {
    try {
      const res = await new RestService()
        .setPath(`storing/${code}/quantity`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //  location
  async updateStoringLocation(code, data) {
    try {
      const res = await new RestService()
        .setPath(`storing/${code}/location`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // 유통기한
  async updateStoringExpDate(code, data) {
    try {
      const res = await new RestService()
        .setPath(`storing/${code}/expDate`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // soft delete
  async updateStoringItemStatus(code, data) {
    try {
      const res = await new RestService()
        .setPath(`storing/${code}/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  //  주가
  async createWarehousingProduct(data) {
    try {
      const res = await new RestService().setPath(`storing`).post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createWarehousingRegister(data) {
    try {
      const res = await new RestService().setPath(`storing/status`).put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default storingService;
