import RestService from './RestService';

const minibarServices = {
  // ?keyword=&page=0&size=20&clientCode=&isOption=
  async getListMinibar(params) {
    try {
      const res = await new RestService()
        .setPath('minibar')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getListMinibarAll(params) {
    try {
      const res = await new RestService()
        .setPath('minibar/all')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getMinibar(minibarCode) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}`)
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createMinibar(data) {
    try {
      const res = await new RestService().setPath('minibar').post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateMinibar(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMinibarStatus(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}/status`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMinibarOwnershipType(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}/ownershipType`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteMinibar(minibarCode) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // minibarCode?
  async getListEquipmentByMinibar(params) {
    try {
      const res = await new RestService()
        .setPath('minibar/equipment-list')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // {  minibarCode, listEquipment: [{equipmentCode, equipmentQuantity, equipmentPrice, sumAmount}]}
  async updateListEquipmentByMinibar(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}/equipment-list`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // { "minibarCode": "minimini63fdd88c0003e", "isRate": "Y", "rateUser": "150", "rateCompany": "140" }
  async updateDiscountRate(minibarCode, data) {
    try {
      const res = await new RestService()
        .setPath(`minibar/${minibarCode}/discount-rate`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default minibarServices;
