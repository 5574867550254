import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Badge
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MyLoadingButton from '@components/MyLoadingButton';

export default function MyDialog({
  open,
  setOpen,
  title = 'Your Title',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  badgeCount,
  sx,
  subTitle,
  disabled,
  onClose,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: '12px',
          width: isAlert ? '280px' : 'unset',
          minWidth: '280px',
          maxWidth: '90vw',

          p: {
            md: isAlert ? '30px 25px 20px' : '30px',
            sm: '30px',
            xs: '15px'
          },
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#343434', opacity: '0.6 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: 'text.primary',
          fontSize: {
            md: isAlert ? '15px !important' : '18px !important',
            xs: isAlert ? '12px !important' : '12px !important'
          },
          textAlign: 'center',
          py: 0,
          px: isAlert ? 0 : 6,
          whiteSpace: isAlert ? 'wrap' : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          mb: isAlert ? 0 : '20px'
        }}
      >
        {typeof title === 'function' ? title() : title}
        {!isAlert && hasCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: { md: 22, sm: 18, xs: 18 },
              top: { md: 22, sm: 14, xs: 12 },
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {subTitle && typeof subTitle === 'function' ? (
          subTitle
        ) : (
          <Typography sx={{ fontSize: 13 }}>{subTitle}</Typography>
        )}
      </DialogTitle>
      {children && <DialogContent sx={{ p: 0 }}>{children}</DialogContent>}
      {!hideDialogButton && (
        <DialogActions
          sx={{
            justifyContent: 'center',
            mt: { md: '30px', sm: '15px', xs: '15px' },
            p: 0,
            gap: 1
          }}
        >
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: { md: '44px', xs: '22px' },
                minWidth: { md: '110px', xs: 'auto' },
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || t('cancel')}
            </Button>
          )}
          <Badge
            badgeContent={badgeCount}
            color="primary"
            invisible={badgeCount === 0}
          >
            <MyLoadingButton
              timeout={onOk ? 500 : 0}
              onClick={onOk || handleClose}
              variant="contained"
              sx={{
                height: { md: '44px', xs: '22px' },
                minWidth: { md: '110px', xs: 'auto' },
                flex: isAlert ? 1 : 'unset'
              }}
              disabled={disabled}
            >
              {okTitle || t('check')}
            </MyLoadingButton>
          </Badge>
        </DialogActions>
      )}
    </Dialog>
  );
}
