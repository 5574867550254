import RestService from './RestService';

const kioskServices = {
  async getListKiosk(params = {}) {
    try {
      const res = await new RestService()
        .setPath('kiosk')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getKiosk(kioskCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateKiosk(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getListErrorHistory(kioskCode = '', params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/error-history`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createErrorHistoryOfKiosk(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/error-history`)
        .setConfig({ params })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async processErrorHistoryOfKiosk(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/error-history`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskStatus(kioskCode = '', data = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/setting/update`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskMode(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/kiosk`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskReboot(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/reboot`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateDeviceReboot(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/usb-restart`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskRedo(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/redo`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskOut(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/out`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskEnd(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/end`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskInspect(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/status/inspect`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskLogo(kioskCode = '', data = {}, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}/notification/logo`)
        .setConfig({ params })
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateKioskUseYn(kioskCode = '') {
    try {
      const res = await new RestService()
        .setPath(`kiosk/${kioskCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default kioskServices;
