import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { Button, Stack } from '@mui/material';
import MyTextField from '@components/MyTextField';

const DaumAddress = ({
  title,
  value,
  onComplete,
  sx,
  placeholder,
  disabled,
  ...rest
}) => {
  const [t] = useTranslation();
  const open = useDaumPostcodePopup();

  const handleClick = () => {
    if (onComplete && !window.getSelection().toString() && !disabled)
      open({ onComplete });
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%', ...sx }}>
      <MyTextField
        sx={{ flex: 1 }}
        size="small"
        value={value}
        readOnly
        placeholder={placeholder}
        onClick={handleClick}
        disabled={disabled}
        {...rest}
      />
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{ height: '36px', whiteSpace: 'nowrap', ml: 1 }}
        disabled={disabled}
      >
        {title || t('button.search')}
      </Button>
    </Stack>
  );
};

export default DaumAddress;
