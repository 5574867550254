/* eslint-disable no-restricted-syntax */
import NoData from '@components/NoData';
import useResponsive from '@hooks/useResponsive';
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import React, { useEffect, useState } from 'react';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});

const GroupCell = ({
  render,
  item,
  name,
  data,
  onClick,
  cellProps,
  dataKey,
  groupBy,
  pagination,
  formatter
}) => {
  const subData = data.filter((row) => row[groupBy] === item[groupBy]);
  const firstGroup = subData[0];
  const rowSpan = subData.length;
  const format = formatter ? formatter(item[name]) : item[name];
  const nameFormat =
    item?.[name] !== undefined && item?.[name] !== null ? format : '';
  return dataKey && item[dataKey] === firstGroup[dataKey] ? (
    <TableCell
      align="left"
      rowSpan={rowSpan}
      {...cellProps}
      onClick={onClick ? () => onClick(item) : () => {}}
    >
      {name === '#' ? (
        <span>
          {render
            ? render(item, renderIndex(item?.no, pagination))
            : renderIndex(item?.no, pagination)}
        </span>
      ) : (
        <span>{render ? render(item) : nameFormat}</span>
      )}
    </TableCell>
  ) : null;
};

const renderIndex = (index, pagination = null) =>
  pagination ? pagination.page * pagination.pageSize + index : index;

export default function GroupTableCustomize({
  hover,
  columns,
  data,
  pagination,
  hasToolBar = false,
  emptyDescription,
  dense = false,
  onRowClick,
  maxHeight,
  minusHeight = 350,
  onChangePage,
  getActionButtons
}) {
  const renderComponent = (render, item, name, formatter) => {
    const format = formatter ? formatter(item[name]) : item[name];
    const nameFormat =
      item?.[name] !== undefined && item?.[name] !== null ? format : '';
    return render ? render(item) : nameFormat;
  };
  const [loading, setLoading] = useState(false);
  const isSm = useResponsive('down', 1000);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const scrollToRow = () => {
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        id="table-container"
        component={BorderBox}
        sx={{
          mt: hasToolBar ? '0px' : '20px',
          mb: '20px'
        }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
        }}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: dense ? '40px' : '50px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.label}
                  align={col.align}
                  sx={{ minWidth: col.minWidth, whiteSpace: 'pre-line' }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: dense ? 1 : 1.5,
                  fontSize: dense ? '12px' : '13px',
                  height: dense ? '40px' : '54px',
                  lineHeight: '22px'
                },
                position: 'relative',
                zIndex: 0
              }}
            >
              {data.map((item, index) => (
                <TableRow
                  onClick={() => (onRowClick ? onRowClick(item) : () => {})}
                  key={`${item?.id}${item?.index}`}
                  sx={{
                    'td, th': {
                      borderRight: '1px solid #EEEEEE'
                    },
                    ':hover': hover && {
                      bgcolor: 'secondary.lighter',
                      '.MuiTableCell-root': {
                        color: 'primary.dark'
                      },
                      cursor: onRowClick ? 'pointer' : 'auto'
                    }
                  }}
                >
                  {columns.map(
                    ({
                      name,
                      label,
                      render,
                      cellProps,
                      onClick,
                      isGroup,
                      dataKey,
                      groupBy,
                      formatter,
                      type,
                      actionType
                    }) =>
                      isGroup ? (
                        <GroupCell
                          render={render}
                          groupBy={groupBy}
                          align="left"
                          name={name}
                          item={item}
                          data={data}
                          formatter={formatter}
                          dataKey={dataKey}
                          key={`${item?.id}${name}${label}`}
                          onClick={onClick ? () => onClick(item) : () => {}}
                          pagination={pagination}
                          {...cellProps}
                        />
                      ) : (
                        <TableCell
                          align="left"
                          {...cellProps}
                          key={`${item?.id}${label}`}
                          onClick={onClick ? () => onClick(item) : () => {}}
                          sx={{
                            ':hover': hover && {
                              bgcolor: 'secondary.lighter',
                              '.MuiTableCell-root': {
                                color: 'primary.dark'
                              },
                              cursor: onClick ? 'pointer' : 'auto'
                            }
                          }}
                        >
                          {name === '#'
                            ? renderIndex(index + 1, pagination)
                            : renderComponent(render, item, name, formatter)}
                          {type ? getActionButtons(item, actionType) : null}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data?.length > 0 && pagination && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {loading && (
            <CircularProgress
              size={22}
              sx={{ position: 'absolute', left: '50%', right: '50%' }}
            />
          )}
          <Pagination
            disabled={loading}
            count={pagination?.count || 1}
            variant="outlined"
            shape="rounded"
            color="primary"
            size={isSm ? 'small' : 'medium'}
            showFirstButton
            showLastButton
            page={pagination.page + 1}
            onChange={(e, page) => {
              if (page !== pagination.page + 1) {
                setLoading(true);
                setTimeout(() => {
                  scrollToRow();
                }, 500);
                onChangePage(e, page - 1);
              }
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
