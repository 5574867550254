import { tableTypes } from '@constants/utils';
import i18n from '@utils/i18n';

const welfareT = i18n.getFixedT(null, null, 'pages.welfare-points');

export const welfarePointsColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center'
  },
  {
    name: 'memberCode',
    label: welfareT('member-code'),
    minWidth: 90,
    align: 'center'
  },
  {
    name: 'name',
    label: welfareT('name'),
    minWidth: 120,
    align: 'center'
  },
  {
    name: 'phone',
    label: welfareT('phone-number'),
    minWidth: 150,
    align: 'center'
  },
  {
    name: 'email',
    label: welfareT('email'),
    minWidth: 220,
    align: 'center'
  },
  {
    name: 'welfarePoint',
    label: welfareT('wp'),
    minWidth: 120,
    align: 'center',
    formatter: (value) => new Intl.NumberFormat('en-CA').format(value)
  },
  {
    label: welfareT('management'),
    minWidth: 350,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const conditionList = [
  {
    label: welfareT('name'),
    value: 'name'
  },
  {
    label: welfareT('phone-number'),
    value: 'phone'
  },
  {
    label: welfareT('prepaid-card'),
    value: 'idCardNo'
  }
];

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 80,
      whiteSpace: 'nowrap'
    },
    td: { borderRight: '1px solid #EEEEEE' }
  },
  '.MuiTableCell-root': { p: '10px 12px', borderBottom: 'none' }
};

export const initTypeList = [
  { label: welfareT('sum'), value: 'sum' },
  { label: welfareT('init'), value: 'init' }
];
