import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';
import GroupTableCustomize from '@components/MyTable/GroupTableCustomize';
import { Button, Stack, Typography } from '@mui/material';
import apkServices from '@services/apk';
import { onOpenConfirm, setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import APKForm from './components/APKForm';
import { apkConfigColumns } from './enhance';

export default function ApkComponent() {
  const [application, setApplication] = useState(undefined);
  const [items, setItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [param, setParam] = useState({ page: 0 });
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    totalRow: 0
  });

  const handleOnChangePage = (page) => {
    setParam({ ...param, page });
  };

  const selectApplication = (e) => {
    setApplication(e);
  };

  const getListApplication = async () => {
    const result = await apkServices.getListApk(param);
    if (!result.error) {
      const { items, totalPage, totalRow, page, pageSize } = result.data;
      setPagination({
        ...pagination,
        page,
        totalRow,
        pageSize,
        count: totalPage || 0
      });
      setItems(items || []);
    }
  };

  useEffect(() => {
    getListApplication();
  }, [param?.page]);

  const handleCloseAPKForm = () => {
    setApplication(undefined);
    setIsSubmitting(false);
  };

  const handleSubmitAPKForm = async (e) => {
    setIsSubmitting(true);
    const result =
      application != null
        ? await apkServices.updateApk(application, e)
        : await apkServices.createApk(e);
    if (result.error) {
      dispatch(setError(error2Text(result.error)));
      setIsSubmitting(false);
    } else {
      getListApplication();
      handleCloseAPKForm();
    }
  };

  return (
    <HelmetContainer title={t('pageTitle.apk')}>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <Button variant="outlined" onClick={() => setApplication(null)}>
          {t(`button.register`)}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <GroupTableCustomize
        hover
        columns={apkConfigColumns(selectApplication, t)}
        data={items}
        pagination={pagination}
        minusHeight={150}
        onChangePage={(e, page) => handleOnChangePage(page)}
      />
      <MyDialog
        open={application !== undefined}
        setOpen={!isSubmitting ? handleCloseAPKForm : () => {}}
        title={t(
          `pages.apk.dialog.title.${
            application == null ? 'registerApplication' : 'editApplication'
          }`
        )}
        hideDialogButton
        hasCloseButton={!isSubmitting}
        sx={{
          '.MuiDialogContent-root': { overflow: 'hidden', display: 'flex' }
        }}
      >
        <APKForm
          item={application}
          onClose={handleCloseAPKForm}
          onSubmit={(data) => {
            dispatch(
              onOpenConfirm({
                open: true,
                type: application == null ? 'save' : 'modify',
                action: () => handleSubmitAPKForm(data)
              })
            );
          }}
          cancelButtonProps={{
            disabled: isSubmitting,
            text: t('button.cancel')
          }}
          submitButtonProps={{
            loading: isSubmitting,
            text: t('button.register')
          }}
          hasCancelButton
          hasCloseButton
        />
      </MyDialog>
    </HelmetContainer>
  );
}
