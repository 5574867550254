import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';

import PrivateRoute from '@routes/PriviteRoute';
import PublicRoute from '@routes/PublicRoute';

import MainLayout from '@layouts/MainLayout';

import NotFound from '@pages/NotFound';
import Login from '@pages/Login';
import Components from '@pages/Components';
import Dashboard from '@pages/Dashboard';

import {
  ClientPage,
  EditMinibarPage,
  MinibarPage,
  MinibarGroupPage
} from '@pages/ClientManagement';

import {
  EquipmentByMinibarPage,
  EquipmentPage,
  EquipmentHistoryPage
} from '@pages/EquipmentManagement';

import {
  PerformanceRegisterPage,
  PerformanceGoalPage,
  PerformanceProfitAndLossPage,
  PerformanceSalesPage,
  PerformanceSalesDetailPage,
  TotalOrderHistoryPage,
  MemberOrderHistoryPage
} from '@pages/PerformanceManagement';

import {
  StatisticsByMemberPage,
  StatisticsByProductPage,
  StatisticsMemberPage,
  StatisticsSalesPage
} from '@pages/Statistics';

import { MemberPage, MemberWelfarePointsPage } from '@pages/MemberManagement';

import {
  ADPage,
  APKPage,
  AuthorityPage,
  // ConsultationInquiryDetailPage,
  // ConsultationInquiryPage,
  DevelopmentRequestDetailPage,
  DevelopmentRequestPage,
  KioskPage,
  LogPage,
  SystemLogPage,
  UsageHistoryPage
} from '@pages/Manager';

import {
  CurationDetailPage,
  CurationPage,
  CurationRegisterPage,
  ProductDetailPage,
  ProductPage,
  PricePolicyTypePage,
  CategoryPage,
  MinibarOrderPage,
  ClientOrderPage,
  ClientOrderDetailPage,
  DisplayManagerView,
  MinibarActionsHistory
} from '@pages/ProductManagement';

import {
  DisplayDetailPage,
  DisplayPage,
  LocationPage,
  LossPage,
  PickingDetailPage,
  PickingPage,
  PickingTypeDetailPage,
  PickingTypeListPage,
  ReleasePage,
  RestockHistoryPage,
  RestockPage,
  StockingPage,
  StocktakingPage,
  UnregisteredProductPage,
  VendorModifyPage,
  VendorPage,
  WarehousePage,
  ReturnedProductsPage
} from '@pages/InventoryManagement';

import {
  PICPage,
  ScheduleDetailPage,
  SchedulePage
} from '@pages/DisplayManagement';
import {
  VendorOrderHistoryPage,
  VendorOrderPage
} from '@pages/OrderManagement';
import { useSelector } from 'react-redux';
import { scopes } from '@constants/utils';
import WarehouseDisplay from '@pages/InventoryManagement/warehouseDisplay';
import {
  FAQDetailPage,
  FAQPage,
  MediaPage,
  NoticeDetailPage,
  NoticePage,
  ConsultationInquiry,
  ConsultationInquiryDetail,
  ServiceInquiryPage,
  ServiceInquiryDetailPage,
  CareersPage,
  CareerDetailPage
} from '@pages/Homepage';
import WorkingPage from '@pages/Working';
import {
  OrderDetailPage,
  OrderHistoryPage,
  PreOrderCategoryPage,
  PreOrderStorePage,
  StoreDetailPage,
  StoreProductDetailPage
} from '@pages/PreOrder';
import jwtDecode from 'jwt-decode';

function App() {
  const roles = useSelector((state) => state.auth.roles);
  const token = localStorage.getItem('token');
  const savedToken = token ? jwtDecode(token) : null;

  if (savedToken && savedToken?.scope?.includes(scopes.ADMIN_DISPLAY_MANAGER))
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="" element={<Login />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route path="" element={<MainLayout />}>
              <Route
                index
                element={
                  <Navigate to="/product-management/minibar-order/display" />
                }
              />

              <Route path="/product-management">
                <Route
                  index
                  path="minibar-order/display"
                  element={<DisplayManagerView />}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    );
  if (
    savedToken &&
    savedToken?.scope?.includes(scopes.ADMIN_WAREHOUSE_MASTER) &&
    roles.length === 2
  ) {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="" element={<Login />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route path="" element={<MainLayout />}>
              <Route
                index
                element={<Navigate to="/inventory-management/vendor" />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/inventory-management" element={<VendorPage />} />

              <Route path="/inventory-management">
                <Route index element={<Navigate to="vendor" />} />
                <Route path="vendor">
                  <Route path="" element={<VendorPage />} />
                  <Route path=":vendorId" element={<VendorModifyPage />} />
                </Route>
                <Route path="location" element={<LocationPage />} />
                <Route path="warehouse">
                  <Route path="" element={<WarehousePage />} />
                  <Route path="stocking" element={<StockingPage />} />
                  <Route
                    path="unregistered-product"
                    element={<UnregisteredProductPage />}
                  />
                  <Route
                    path="product-register"
                    element={<ProductDetailPage />}
                  />
                  <Route
                    path="product-modify/:productId"
                    element={<ProductDetailPage />}
                  />
                  <Route path="stocktaking" element={<StocktakingPage />} />
                  <Route path="loss" element={<LossPage />} />
                </Route>
                <Route path="picking">
                  <Route path="" element={<PickingPage />} />
                  <Route path=":id" element={<PickingDetailPage />} />
                </Route>
                <Route path="release">
                  <Route path="" element={<ReleasePage />} />
                  <Route path=":id" element={<DisplayDetailPage />} />
                </Route>
                <Route path="display" element={<DisplayPage />} />
                <Route path="restock">
                  <Route path="" element={<RestockPage />} />
                  <Route path="history" element={<RestockHistoryPage />} />
                </Route>
              </Route>

              <Route path="/display-management">
                <Route index element={<Navigate to="PIC" />} />
                <Route path="pic" element={<PICPage />} />
                <Route path="schedule">
                  <Route path="" element={<SchedulePage />} />
                  <Route path="detail" element={<ScheduleDetailPage />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          {process.env.NODE_ENV === 'development' && (
            <Route path="/components" element={<Components />} />
          )}
        </Routes>
      </Router>
    );
  }
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="" element={<Login />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<MainLayout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/client-management">
              <Route index element={<Navigate to="client" />} />
              <Route path="client" element={<ClientPage />} />
              <Route path="minibar">
                <Route path="" element={<MinibarPage />} />
                <Route path=":minibarCode" element={<EditMinibarPage />} />
              </Route>
              <Route path="minibar-group" element={<MinibarGroupPage />} />
            </Route>

            <Route path="/equipment-management">
              <Route index element={<Navigate to="equipment" />} />
              <Route path="equipment" element={<EquipmentPage />} />
              <Route path="history" element={<EquipmentHistoryPage />} />
              <Route path="by-minibar" element={<EquipmentByMinibarPage />} />
            </Route>

            <Route path="/performance-management">
              <Route index element={<Navigate to="goal" />} />
              <Route path="goal" element={<PerformanceGoalPage />} />
              <Route path="sales">
                <Route path="" element={<PerformanceSalesPage />} />
                <Route path="detail" element={<PerformanceSalesDetailPage />} />
              </Route>
              <Route
                path="profit-and-loss"
                element={<PerformanceProfitAndLossPage />}
              />
              <Route path="register" element={<PerformanceRegisterPage />} />
              <Route
                path="total-order-history"
                element={<TotalOrderHistoryPage />}
              />
              <Route
                path="member-order-history"
                element={<MemberOrderHistoryPage />}
              />
            </Route>

            <Route path="/statistics">
              <Route index element={<Navigate to="sales" />} />
              <Route path="sales" element={<StatisticsSalesPage />} />
              <Route path="by-member" element={<StatisticsByMemberPage />} />
              <Route path="by-product" element={<StatisticsByProductPage />} />
              <Route path="member" element={<StatisticsMemberPage />} />
            </Route>

            <Route path="/member-management">
              <Route index element={<Navigate to="member" />} />
              <Route path="member" element={<MemberPage />} />
              <Route
                path="welfare-points"
                element={<MemberWelfarePointsPage />}
              />
            </Route>

            <Route path="/product-management">
              <Route index element={<Navigate to="total-order-history" />} />

              <Route path="category" element={<CategoryPage />} />
              <Route path="product">
                <Route path="" element={<ProductPage />} />
                <Route path="register" element={<ProductDetailPage />} />
                <Route path=":productId" element={<ProductDetailPage />} />
              </Route>
              <Route
                path="price-policy-type"
                element={<PricePolicyTypePage />}
              />

              <Route path="curation">
                <Route path="" element={<CurationPage />} />
                <Route path="register" element={<CurationRegisterPage />} />
                <Route path=":curationId" element={<CurationDetailPage />} />
              </Route>
              <Route path="minibar-order" element={<MinibarOrderPage />} />

              <Route
                path="minibar-order/display"
                element={<DisplayManagerView />}
              />

              <Route
                index
                path="display-history"
                element={<MinibarActionsHistory />}
              />
              <Route path="client-order">
                <Route path="" element={<ClientOrderPage />} />
                <Route
                  path=":clientOrderId"
                  element={<ClientOrderDetailPage />}
                />
              </Route>
            </Route>

            <Route path="/inventory-management">
              <Route index element={<Navigate to="warehouse" />} />

              <Route path="location" element={<LocationPage />} />
              <Route path="warehouse">
                <Route path="" element={<WarehousePage />} />

                <Route
                  path="unregistered-product"
                  element={<UnregisteredProductPage />}
                />
                <Route
                  path="product-register"
                  element={<ProductDetailPage />}
                />
                <Route
                  path="product-modify/:productId"
                  element={<ProductDetailPage />}
                />

                <Route path="loss" element={<LossPage />} />
              </Route>
              <Route path="stocking" element={<StockingPage />} />
              <Route path="warehouse-display">
                <Route path="" element={<WarehouseDisplay />} />
                <Route
                  path="returned-products"
                  element={<ReturnedProductsPage />}
                />
              </Route>

              <Route path="picking">
                <Route path="" element={<PickingPage />} />
                <Route path="individual" />
                <Route path="individual">
                  <Route path="" element={<PickingTypeListPage />} />
                  <Route path=":code" element={<PickingTypeDetailPage />} />
                </Route>
                <Route path="total">
                  <Route path="" element={<PickingTypeListPage />} />
                  <Route path=":code" element={<PickingTypeDetailPage />} />
                </Route>

                <Route path=":id" element={<PickingDetailPage />} />
              </Route>
              <Route path="release">
                <Route path="" element={<ReleasePage />} />
                <Route path=":id" element={<DisplayDetailPage />} />
              </Route>
              <Route path="display" element={<DisplayPage />} />
              <Route path="restock">
                <Route path="" element={<RestockPage />} />
                <Route path="history" element={<RestockHistoryPage />} />
              </Route>
              <Route path="stockTaking" element={<StocktakingPage />} />
            </Route>

            <Route path="/display-management">
              <Route index element={<Navigate to="PIC" />} />
              <Route path="pic" element={<PICPage />} />
              <Route path="schedule">
                <Route path="" element={<SchedulePage />} />
                <Route path="detail" element={<ScheduleDetailPage />} />
              </Route>
            </Route>

            <Route path="/order-management">
              <Route index element={<Navigate to="vendor" />} />
              <Route path="vendor-management">
                <Route path="" element={<VendorPage />} />
                <Route path=":vendorId" element={<VendorModifyPage />} />
              </Route>
              <Route path="vendor">
                <Route path="" element={<VendorOrderPage />} />
                <Route path="history" element={<VendorOrderHistoryPage />} />
              </Route>
            </Route>

            <Route path="/manager">
              <Route index element={<Navigate to="development-request" />} />
              <Route path="development-request">
                <Route path="" element={<DevelopmentRequestPage />} />
                <Route path=":id" element={<DevelopmentRequestDetailPage />} />
              </Route>

              <Route path="authority" element={<AuthorityPage />} />
              <Route path="kiosk" element={<KioskPage />} />
              <Route path="APK" element={<APKPage />} />
              <Route path="AD" element={<ADPage />} />
              <Route path="usage-history" element={<UsageHistoryPage />} />
              <Route path="log" element={<LogPage />} />
              <Route path="system-log" element={<SystemLogPage />} />
            </Route>

            {/* homepage */}
            <Route path="/homepage">
              <Route index element={<Navigate to="notices" />} />
              <Route path="media">
                <Route path="" element={<MediaPage />} />
                <Route path="register" element={<NoticeDetailPage />} />
              </Route>
              <Route path="notices">
                <Route path="" element={<NoticePage />} />
                <Route path="register" element={<NoticeDetailPage />} />
                <Route path=":id" element={<NoticeDetailPage />} />
              </Route>
              <Route path="faq">
                <Route path="" element={<FAQPage />} />
                <Route path="register" element={<FAQDetailPage />} />
                <Route path=":id" element={<FAQDetailPage />} />
              </Route>
              <Route path="careers">
                <Route path="" element={<CareersPage />} />\
                <Route path="register" element={<CareerDetailPage />} />
                <Route path=":id" element={<CareerDetailPage />} />
              </Route>

              <Route path="consultation-inquiry">
                <Route path="" element={<ConsultationInquiry />} />
                <Route
                  path=":inquiryCode"
                  element={<ConsultationInquiryDetail />}
                />
              </Route>
              <Route path="service-introduction-inquiry">
                <Route path="" element={<ServiceInquiryPage />} />
                <Route
                  path=":introductionRequestCode"
                  element={<ServiceInquiryDetailPage />}
                />
              </Route>

              <Route
                path="service-introduction-inquiry"
                element={<WorkingPage />}
              />
            </Route>

            {/* pre-order */}
            <Route path="/pre-order">
              {/* <Route index element={<Navigate to="category" />} /> */}
              <Route path="category" element={<PreOrderCategoryPage />} />
              <Route path="store">
                <Route path="" element={<PreOrderStorePage />} />
                <Route path=":storeCode" element={<StoreDetailPage />} />
                <Route
                  path=":storeCode/product/:productCode"
                  element={<StoreProductDetailPage />}
                />
              </Route>
              <Route path="order-history">
                <Route index path="" element={<OrderHistoryPage />} />
                <Route path=":orderCode" element={<OrderDetailPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>

        {process.env.NODE_ENV === 'development' && (
          <Route path="/components" element={<Components />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
