import { tableTypes } from '@constants/utils';

export const columnClient = (t) => [
  {
    name: 'clientChannel',
    label: t('labels.clientChannel'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientName',
    label: t('labels.clientName'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'managerName',
    label: t('labels.managerName'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientContact',
    label: t('labels.clientContact'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'businessNumber',
    label: t('labels.businessNumber'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'ownerName',
    label: t('labels.ownerName'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'clientAddress1',
    label: t('labels.address'),
    minWidth: 160,
    align: 'center'
  },
  {
    name: 'groupName',
    label: t('labels.groupName'),
    minWidth: 160,
    align: 'center'
  },
  {
    label: t('labels.Management'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120
    },
    td: {
      p: '10px 12px',
      width: 280
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const PRICE_POLICY_TYPE = [
  { value: 'Type1', label: 'Type 1' },
  { value: 'Type2', label: 'Type 2' },
  { value: 'Type3', label: 'Type 3' },
  { value: 'Type4', label: 'Type 4' },
  { value: 'Type5', label: 'Type 5' },
  { value: 'Type6', label: 'Type 6' },
  { value: 'Type7', label: 'Type 7' },
  { value: 'Type8', label: 'Type 8' },
  { value: 'Type9', label: 'Type 9' },
  { value: 'Type10', label: 'Type 10' },
  { value: 'Type11', label: 'Type 11' },
  { value: 'Type12', label: 'Type 12' },
  { value: 'Type13', label: 'Type 13' },
  { value: 'Type14', label: 'Type 14' },
  { value: 'Type15', label: 'Type 15' },
  { value: 'Type16', label: 'Type 16' },
  { value: 'Type17', label: 'Type 17' },
  { value: 'Type18', label: 'Type 18' },
  { value: 'Type19', label: 'Type 19' },
  { value: 'Type20', label: 'Type 20' },
  { value: 'Type21', label: 'Type 21' }
];
