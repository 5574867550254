import { ClientAutoCompleteV2 } from '@components/MyAutocomplete';

import clientServices from '@services/client';
import React, { useEffect, useState } from 'react';
import { PRIVATE_CODE } from '@constants/utils';

export default function AggregationSetting({
  value = '',
  name = '',
  onChange
}) {
  const [parent, setParent] = useState({ clientCode: '', clientName: '' });

  const getAggregation = async () => {
    const result = await clientServices.getClient(value);
    if (!result.error) {
      const { clientCode, clientName } = result.data;
      setParent({ clientCode, clientName });
    }
  };

  useEffect(() => {
    if (value.startsWith(PRIVATE_CODE.CLIENT)) {
      getAggregation();
    }
  }, [value]);

  const handleChangeValue = (e) => {
    setParent(e);
    onChange({ target: { name, value: e.clientCode } });
  };

  return (
    <ClientAutoCompleteV2
      label={null}
      client={parent}
      setClient={handleChangeValue}
    />
  );
}
