import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.display');
const p = i18n.getFixedT(null, null, 'pages.product');

export const columns = [
  {
    name: 'managerName',
    label: t('pic'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'clientNames',
    label: p('client'),
    align: 'center',
    minWidth: 150
  },
  {
    name: 'dayOfWeek',
    label: t('dayOfWeek'),
    align: 'center',
    minWidth: 60
  },
  {
    name: 'latitude/longitude',
    label: `${t('latitude')} / ${t('longitude')}`,
    align: 'center',
    minWidth: 160
  },
  {
    name: 'displayStartTime',
    label: t('displayStartTime'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'displayCompleteTime',
    label: t('displayCompleteTime'),
    align: 'center',
    minWidth: 100
  },
  {
    name: 'displayRequiredTime',
    label: t('displayRequiredTime'),
    align: 'center',
    minWidth: 100
  },
  {
    label: t('photoBeforeDisplay'),
    align: 'center',
    minWidth: 130
  },
  {
    label: t('photoAfterDisplay'),
    align: 'center',
    minWidth: 130
  }
];

export const conditionList = [
  { label: i18n.t('common.client'), value: 'clientName' },
  { label: t('pic'), value: 'picName' }
  // { label: i18n.t('common.period'), value: 'period' } // ???
];

export const dummyData = {
  items: [
    {
      id: 1,
      pic: '박준혁',
      clientName: '롯데호텔',
      dayOfWeek: '월',
      latitude: '10.819099',
      longitude: '106.653608',
      displayStartTime: '09 : 24',
      displayCompleteTime: '10 : 25',
      displayRequiredTime: '59분',
      photoBeforeDisplayFileLink: 'dev-request/1678698534613-xxxxx-RIIT.png',
      photoBeforeDisplayFileName: '1678698535439-xxxxx-RIIT.png',
      photoAfterDisplayFileLink:
        'dev-request/1680776093511-MicrosoftTeams-image_(1).png',
      photoAfterDisplayFileName: '1680776095028-MicrosoftTeams-image_(1).png'
    },
    {
      id: 2,
      pic: '박준혁',
      clientName: '롯데호텔',
      dayOfWeek: '월',
      latitude: '10.782951',
      longitude: '106.696313',
      displayStartTime: '09 : 24',
      displayCompleteTime: '10 : 25',
      displayRequiredTime: '59분',
      photoBeforeDisplayFileLink: 'folder/item.png',
      photoBeforeDisplayFileName: 'item.png',
      photoAfterDisplayFileLink: 'folder/item.png',
      photoAfterDisplayFileName: 'item.png'
    },
    {
      id: 3,
      pic: '박준혁',
      clientName: '롯데호텔',
      dayOfWeek: '월',
      latitude: '10.819099',
      longitude: '106.653608',
      displayStartTime: '09 : 24',
      displayCompleteTime: '10 : 25',
      displayRequiredTime: '59분',
      photoBeforeDisplayFileLink: 'folder/item.png',
      photoBeforeDisplayFileName: 'item.png',
      photoAfterDisplayFileLink: 'folder/item.png',
      photoAfterDisplayFileName: 'item.png'
    },
    {
      id: 4,
      pic: '박준혁',
      clientName: '롯데호텔',
      dayOfWeek: '월',
      latitude: '10.782951',
      longitude: '106.696313',
      displayStartTime: '09 : 24',
      displayCompleteTime: '10 : 25',
      displayRequiredTime: '59분',
      photoBeforeDisplayFileLink: 'dev-request/1680776095029-celebration.svg',
      photoBeforeDisplayFileName: '1680776095299-celebration.svg',
      photoAfterDisplayFileLink:
        'dev-request/1680776095300-this-item-might-not-exist2.svg',
      photoAfterDisplayFileName: '1680776095580-this-item-might-not-exist2.svg'
    },
    {
      id: 5,
      pic: '박준혁',
      clientName: '롯데호텔',
      dayOfWeek: '월',
      latitude: '10.819099',
      longitude: '106.653608',
      displayStartTime: '09 : 24',
      displayCompleteTime: '10 : 25',
      displayRequiredTime: '59분',
      photoBeforeDisplayFileLink: 'folder/item.png',
      photoBeforeDisplayFileName: 'item.png',
      photoAfterDisplayFileLink: 'folder/item.png',
      photoAfterDisplayFileName: 'item.png'
    }
  ],
  page: 0,
  pageSize: 6,
  totalRow: 60,
  totalPage: 10
};
