/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, MenuItem, Stack, TextField } from '@mui/material';
import MyAutocomplete from '@components/MyAutocomplete';
import locationService from '@services/location';

const LocationAutoComplete = ({
  setLocation,
  location,
  disabled,
  setChannel,
  channel,
  sx,
  errMg,
  label = true,
  ...rest
}) => {
  const { t } = useTranslation();

  const [itemList, setItemList] = useState([]);
  const [itemInput, setItemInput] = useState('');
  const [locationState, setLocationState] = useState(null);

  const getItems = async () => {
    const { data } = await locationService.getLocationList({
      locationName: itemInput,
      page: 0,
      size: 20
    });
    if (!data?.items) return;

    setItemList(data?.items || []);
  };

  const onInputChange = (e, val, reason) => {
    return reason === 'input' && setItemInput(val);
  };

  const onChange = async (e, val) => {
    if (!val) setItemInput('');

    if (val && val.locationCode) {
      setLocation(val);
      setLocationState(val || null);
    }
  };
  const [loadMore, setLoadMore] = useState(false);
  const [endLoad, setEndLoad] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setLocationState(null);
    setItemList([]);
    setEndLoad(false);
    setPage(0);
    getItems();
  }, [itemInput, channel]);

  useEffect(() => {
    if (channel) {
      setItemInput('');
    }
  }, [channel]);

  const onLoadMore = async () => {
    if (!endLoad) {
      setLoadMore(true);

      let res = null;
      res = await locationService.getLocationList({
        locationName: itemInput,
        page: page + 1
      });
      setPage(page + 1);
      const { data } = res;
      if (data?.items && data?.items?.length > 0) {
        setItemList(itemList.concat(data?.items));
      }
      setEndLoad(!data?.items?.length);
      setLoadMore(false);
    }
    return true;
  };

  const observer = useRef();
  const lastOptionElementRef = (node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && onLoadMore) {
        setTimeout(() => {
          onLoadMore();
        }, 300);
      }
    });
    if (node) observer.current.observe(node);
  };
  return (
    <MyAutocomplete
      isOptionEqualToValue={(option, value) =>
        option.locationCode === value?.locationCode
      }
      getOptionLabel={(option) =>
        option.locationName ? option.locationName : ''
      }
      value={
        location ||
        (location?.locationName && location?.locationCode
          ? location
          : itemList?.find((i) => i?.locationCode === location?.clientCode) ||
            null)
      }
      onChange={onChange}
      onInputChange={onInputChange}
      options={itemList}
      disabled={disabled}
      onClose={() => {
        if (!location) {
          setItemInput('');
        }
      }}
      sx={{
        '.MuiInputBase-root': {
          bgcolor: !disabled ? 'common.white' : '#f2f2f2',
          fontSize: '10px !important'
        },
        width: '100%',
        ...sx
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('pages.inventory.location')}
          error={errMg}
          sx={{ fontSize: 8 }}
          helperText={errMg && `* ${errMg}`}
        />
      )}
      slotProps={{
        popupIndicator: { sx: { display: 'none' } }
      }}
      renderOption={(option, { ref }) => {
        const { key, id, ...restOption } = option;
        const isLastOption =
          Number(option?.['data-option-index']) + 1 === itemList.length;
        return (
          <>
            <MenuItem
              {...restOption}
              key={id}
              sx={{
                overflowX: 'hidden',
                fontSize: 10,
                justifyContent: 'center',
                alignItems: 'center'
              }}
              ref={isLastOption ? lastOptionElementRef : ref}
            >
              {key}
            </MenuItem>
            {isLastOption && !endLoad && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                visibility={loadMore && 'visible'}
              >
                <CircularProgress size={15} />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    letterSpacing: '1px',
                    margin: '0 5px'
                  }}
                >
                  Loading...
                </span>
              </Stack>
            )}
          </>
        );
      }}
      ListboxProps={{ style: { maxHeight: 200 } }}
      {...rest}
    />
  );
};

export default LocationAutoComplete;
