import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import { setError } from '@store/reducers/appReducer';

import MyDialog from '@components/MyDialog';
import MyTextField from '@components/MyTextField';

import minibarServices from '@services/minibar';
import { Formatter } from '@utils/formatter';
import { equipmentListTableBodyStyle } from './enhance';

const EditCompanyRateDialog = ({ open, setOpen, data, setSuccess }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [rateCompany, setRateCompany] = useState(data?.rateCompany || '0');
  const [isRate, setIsRate] = useState(data?.isRate === 'Y');
  const [rateUser, setRateUser] = useState(data?.rateUser || '100');
  const [loading, setLoading] = useState(false);

  const handleChangeRateCompany = (e) => {
    const value = Formatter.fStringToRangeNumber(e.target.value);
    setRateCompany(value);
  };

  const handleUpdateDiscountRate = async () => {
    setLoading(true);
    if (!data.minibarCode) return;

    const result = await minibarServices.updateDiscountRate(data.minibarCode, {
      minibarCode: data.minibarCode,
      isRate: isRate ? 'Y' : 'N',
      rateUser,
      rateCompany
    });

    setLoading(false);
    if (result.error) dispatch(setError({ title: 'Something went wrong' }));
    else setOpen(false);
    setSuccess(true);
  };

  useEffect(() => {
    if (rateCompany && parseInt(rateCompany, 10) <= 100) {
      setRateUser(100 - parseInt(rateCompany, 10));
    } else setRateUser(100);
  }, [rateCompany]);

  useEffect(() => {
    if (!isRate) {
      setRateCompany('0');
    }
  }, [isRate]);

  return (
    <MyDialog
      open={open}
      setOpen={setOpen}
      title={t('pages.minibar.minibar-discount-rate')}
      okTitle={t('button.edit')}
      cancelTitle={t('button.close')}
      hasCloseButton
      hasCancelButton
      onOk={handleUpdateDiscountRate}
      loading={loading}
      sx={{
        maxWidth: 360,
        '.MuiDialogContent-root': {
          outline: '1px solid #D3D3D3',
          borderRadius: '8px',
          overflow: 'overlay',
          position: 'relative'
        }
      }}
      disabled={parseInt(rateCompany, 10) > 100}
    >
      <TableContainer component={Box}>
        <Table>
          <TableBody
            sx={{
              ...equipmentListTableBodyStyle,
              th: {
                borderRight: '1px solid #EEEEEE',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                textAlign: 'center',
                color: '#6F869C',
                bgcolor: '#FCFCFC',
                height: '64px',
                width: 100
              }
            }}
          >
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.nickname')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={data?.minibarNickname}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.discount')}
              </TableCell>
              <TableCell align="center">
                <Checkbox
                  checked={isRate}
                  onChange={(e) => setIsRate(e.target.checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.discountRate2')}
              </TableCell>
              <TableCell>
                <MyTextField
                  value={rateCompany}
                  onChange={handleChangeRateCompany}
                  disabled={!isRate}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('pages.minibar.userDiscountRate')}
              </TableCell>
              <TableCell>
                <MyTextField
                  disabled
                  sx={{
                    '.MuiInputBase-root': {
                      bgcolor: '#f2f2f2'
                    }
                  }}
                  value={rateUser}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MyDialog>
  );
};

export default EditCompanyRateDialog;
