import RestService from '@services/RestService';

const orderService = {
  // Store

  async getStoreList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreTypeList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store-type')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/order')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getOrderDetail(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/order/${orderCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreProductList(storeCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}/product`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreProductDetail(storeCode, productCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}/product/${productCode}`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreOptions(storeTypeCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-info`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createNewStore(data) {
    try {
      const res = await new RestService().setPath('/preorder/store').post(data);
      console.log('headers', res?.headers);
      const location = res?.headers?.location || null;
      return { data: res?.data, error: null, location };
    } catch (err) {
      return { error: err?.response?.data || err, data: null, location: null };
    }
  },

  async createTemporaryProduct(storeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}/product`)
        .post(data);
      const location = res?.headers?.location || null;
      return { data: res?.data, error: null, location };
    } catch (err) {
      return { error: err?.response?.data || err, data: null, location: null };
    }
  },

  async uploadProductImages(storeCode, productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}/product/${productCode}/photo`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      const location = res?.headers?.location || null;
      return { data: res?.data, error: null, location };
    } catch (err) {
      return { error: err?.response?.data || err, data: null, location: null };
    }
  },

  async uploadProductMainImage(storeCode, productCode, data) {
    try {
      const res = await new RestService()
        .setPath(
          `/preorder/store/${storeCode}/product/${productCode}/main-photo`
        )
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      const location = res?.headers?.location || null;
      return { data: res?.data, error: null, location };
    } catch (err) {
      return { error: err?.response?.data || err, data: null, location: null };
    }
  },
  async createStoreOptions(storeTypeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-info`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStore(storeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStoreProductCategory(storeTypeCode, categoryCode, data) {
    try {
      const res = await new RestService()
        .setPath(
          `/preorder/store-type/${storeTypeCode}/product-category/${categoryCode}`
        )
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateStoreProduct(storeCode, productCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}/product/${productCode}`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteStore(storeCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store/${storeCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async deleteStoreProductCategory(storeTypeCode, categoryCode) {
    try {
      const res = await new RestService()
        .setPath(
          `/preorder/store-type/${storeTypeCode}/product-category/${categoryCode}`
        )
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteStoreTemporaryProduct(storeCode, productCode) {
    try {
      const res = await new RestService()
        .setPath(`preorder/store/${storeCode}/product/${productCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteStoreProduct(storeCode, productCode) {
    try {
      const res = await new RestService()
        .setPath(`preorder/store/${storeCode}/product/${productCode}`)
        .delete();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default orderService;
